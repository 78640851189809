import React, { FC, FocusEventHandler, useCallback, useEffect, useMemo, useRef } from 'react'
import { geocodeByAddress, geocodeByPlaceId, getLatLng } from 'react-places-autocomplete'
import { Grid, Typography } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import throttle from 'lodash/throttle'
import parse from 'autosuggest-highlight/parse'

import { Icons } from '../../Icon/types'
import useScript from '../../../hooks/useScript'
import TextField, { TextFieldProps } from '../TextField'

import * as SC from './styled'

type AutosuggestOption = {
  description: string
  place_id?: string
  structured_formatting?: any
}

export type AddressPosition = {
  lat: number
  lng: number
}

export type AddressValue = {
  address: string
  position?: AddressPosition
  components?: {
    city?: string
    zipcode?: string
    country?: string
    iso?: string
    region?: string
    department?: string
    route?: string
  }
}

export type AddressProps = {
  className?: string
  name: string
  label: string
  error?: string
  required?: boolean
  script: string
  value?: AddressValue
  onChange?: (name: string, value: AddressValue) => void
  onBlur?: FocusEventHandler
  textFieldProps?: TextFieldProps
  searchOptions?: any
}

const findComponent = (result: any, type: string) =>
  result?.address_components?.find((c: any) => c?.types?.includes(type))

const Address: FC<AddressProps> = ({
  className,
  name,
  label,
  onChange,
  error,
  required,
  script,
  value,
  textFieldProps,
  searchOptions,
  onBlur,
}) => {
  const googleScript = useScript(script)
  const [inputValue, setInputValue] = React.useState('')
  const [options, setOptions] = React.useState([])
  const autocompleteService = useRef(null as any)

  const handleChange = useCallback(
    (address, position?: AddressPosition, components?: any) => {
      onChange?.(name, {
        address,
        ...(position &&
          position?.lat && {
            position: {
              lat: position?.lat ?? 0,
              lng: position?.lng ?? 0,
            },
          }),
        components,
      })
    },
    [name, onChange]
  )

  const handleGeocode = useCallback(
    (address: string, placeId?: string) =>
      (placeId ? geocodeByPlaceId(placeId) : address ? geocodeByAddress(address) : null)
        ?.then(
          (results: any) =>
            new Promise((resolve) =>
              getLatLng(results[0]).then((p: AddressPosition) =>
                resolve({
                  address: results[0]?.formatted_address || address,
                  position: p,
                  components: {
                    city: findComponent(results[0], 'locality')?.long_name,
                    zipcode: findComponent(results[0], 'postal_code')?.long_name,
                    country: findComponent(results[0], 'country')?.long_name,
                    iso: findComponent(results[0], 'country')?.short_name,
                    region: findComponent(results[0], 'administrative_area_level_1')?.long_name,
                    department: findComponent(results[0], 'administrative_area_level_2')?.long_name,
                    route:
                      findComponent(results[0], 'street_number')?.long_name &&
                      findComponent(results[0], 'route')?.long_name
                        ? `${findComponent(results[0], 'street_number')?.long_name} ${
                            findComponent(results[0], 'route')?.long_name
                          }`
                        : findComponent(results[0], 'street_number')?.long_name ||
                          findComponent(results[0], 'route')?.long_name,
                  },
                })
              )
            )
        )
        .then(({ address: a, position: p, components: c }: any) => handleChange(a, p, c))
        .catch((error: any) => console.error('Error', error)),
    [handleChange]
  )

  const handleSelect = useCallback(
    (address: string, placeId?: string) => {
      handleChange(address)
      if (placeId || address) handleGeocode(address, placeId)
    },
    [handleChange, handleGeocode]
  )

  const handleBlur = useCallback(
    (e) => {
      if (!inputValue) {
        handleChange('')
      }
      if (!value?.position?.lat && (inputValue || value?.address)) {
        handleGeocode(inputValue ?? value?.address)
      }
      onBlur?.(e)
    },
    [handleGeocode, onBlur, value, handleChange, inputValue]
  )

  const fetch = useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback)
      }, 200),
    []
  )

  useEffect(() => {
    let active = true

    if (autocompleteService.current) {
      fetch({ input: inputValue, ...searchOptions }, (results: any) => {
        if (active) {
          setOptions(results ?? [])
        }
      })
    }

    return () => {
      active = false
    }
  }, [value, searchOptions, inputValue, fetch])

  useEffect(() => {
    if (googleScript === 'ready') {
      autocompleteService.current = new window.google.maps.places.AutocompleteService()
    }
  }, [googleScript])

  const localValue: AutosuggestOption = useMemo(
    () => ({
      description: value?.address ?? '',
    }),
    [value]
  )

  return (
    <SC.Wrapper className={className}>
      <Autocomplete
        getOptionLabel={(option: any) =>
          typeof option === 'string' ? option : option?.description ?? ''
        }
        filterOptions={(x) => x}
        options={options}
        autoComplete
        freeSolo
        filterSelectedOptions
        disableClearable
        value={localValue}
        onBlur={handleBlur}
        onChange={(event, newValue: any) => {
          setInputValue(newValue?.description ?? inputValue)
          handleSelect(newValue?.description ?? inputValue, newValue?.place_id)
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue)
        }}
        renderInput={(params) => (
          <TextField
            {...textFieldProps}
            {...params}
            name={name}
            label={label}
            icon={Icons.mapMarker}
            error={error}
            required={required}
          />
        )}
        getOptionSelected={(o, v) => o?.description === v?.description}
        renderOption={(option: AutosuggestOption) => {
          const matches = option?.structured_formatting?.main_text_matched_substrings
          const parts = parse(
            option?.structured_formatting?.main_text,
            matches.map((match: any) => [match.offset, match.offset + match.length])
          )

          return (
            <Grid container alignItems="center">
              <Grid item xs>
                {parts.map((part, index) => (
                  <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                    {part.text}
                  </span>
                ))}

                <Typography variant="body2" color="textSecondary">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          )
        }}
      />
    </SC.Wrapper>
  )
}

export default Address
