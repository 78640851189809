import React, { useEffect, useMemo, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { HeaderProps } from '../../components/Header'
import useHeader from '../../hooks/useHeader'
import PlanningTemplate, { PlanningTemplateProps } from '../../templates/Planning'
import { MenuProps } from '../../components/Menu'
import useMenu from '../../hooks/useMenu'
import { actions, selectors } from '../../redux'
import { UserType } from '../../generated/graphql'
import {
  courseRequestToCardProps,
  courseToCarouselCardProps,
  courseToFutureCardProps,
  courseToPastCardProps,
} from '../../transformers/courseTransformers'
import { router, routesPath } from '../../router'
import { Icons } from '../../components/Icon/types'
import { light } from '../../theme/palette'
import PreloadTemplate from '../../templates/Preload'
import useBackButton from '../../hooks/useBackButton'

const PlanningPage: React.FC<RouteComponentProps<{ tab?: string; child?: string }>> = (props) => {
  const headerProps: HeaderProps = useHeader()
  const menuProps: MenuProps = useMenu(props)
  const backButton = useBackButton()
  const user = useSelector(selectors.auth.user)
  const planningCourses = useSelector(selectors.planning.planningCourses)
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const student = props.match?.params?.child ?? null
  const child = useMemo(
    () => user?.guardian?.children?.find((c) => (student ? student === c?.id : true)),
    [user, student]
  )

  const [tab, setTab] = useState(props.match?.params?.tab ?? 'future')

  const planningProps: PlanningTemplateProps = {
    headerProps,
    menuProps,
    title: t('planning_title'),
    backButton,
    ...(user?.user_type === UserType.Teacher
      ? {
          carouselProps: {
            title: t('planning_teacher_future_title'),
            ...(planningCourses?.data?.future?.total && planningCourses?.data?.future?.total > 0
              ? {
                  cards:
                    planningCourses?.data?.future?.data?.map((c) =>
                      courseToCarouselCardProps(c, t, user)
                    ) ?? [],
                  planningCta: {
                    text: t('see_all_count', { count: planningCourses?.data?.future?.total ?? 0 }),
                    link: {
                      link: router(routesPath.planningFuture),
                    },
                  },
                }
              : {
                  text: t('planning_teacher_future_empty_text'),
                }),
          },
          imageTextListProps: {
            items: [
              /*{
                image: {
                  alt: '',
                  src: '/static/assets/images/chrono.png',
                },
                text1: t('planning_teacher_recurring_title'),
                //notificationBadge: 4,
              },*/
              {
                image: {
                  alt: '',
                  src: '/static/assets/images/watch.png',
                },
                text1: t('planning_teacher_availabilities_title'),
                link: {
                  href: router(routesPath.preferencesAvailabilities),
                },
              },
              {
                image: {
                  alt: '',
                  src: '/static/assets/images/historique.png',
                },
                text1: t('planning_teacher_history_title'),
                link: {
                  href: router(routesPath.planningHistory),
                },
              },
              {
                image: {
                  alt: '',
                  src: '/static/assets/images/icons/refused-students.png',
                },
                text1: t('planning_teacher_rating_title'),
                link: {
                  href: router(routesPath.planningReview),
                },
              },
            ],
          },
        }
      : {
          subtitle: t('planning_subtitle'),
          ...(student &&
            child && {
              requestForLabel: t('planning_for'),
              requestFor: {
                image: {
                  alt: child?.first_name ?? '',
                  src: child?.avatar ?? '',
                },
                isAvatar: true,
                text1: child?.first_name ?? '',
                text2: child?.grade?.name ?? '',
                value: child?.id,
                potIcon: {
                  icon: Icons.pot,
                  color: light.colors.kournikova,
                },
                potValue: t('wallet_amount_value', { amount: (user?.wallet?.amount ?? 0) / 100 }),
              },
            }),
          tabSetProps: {
            value: tab,
            onChange: setTab,
            tabs: [
              {
                label: t('planning_future_title'),
                value: 'future',
                cards:
                  planningCourses?.data?.future?.data?.map((c) =>
                    courseToFutureCardProps(c, t, user)
                  ) ?? [],
                total: planningCourses?.data?.future?.total ?? 0,
              },
              {
                label: t('planning_pending_title'),
                value: 'pending',
                cards:
                  planningCourses?.data?.pending?.data?.map((c) =>
                    courseToFutureCardProps(c, t, user)
                  ) ?? [],
                total: planningCourses?.data?.pending?.total ?? 0,
              },
              {
                label: t('planning_requests_title'),
                value: 'requests',
                cards:
                  planningCourses?.data?.requests?.data?.map((c) =>
                    courseRequestToCardProps(c, t, user)
                  ) ?? [],
                total: planningCourses?.data?.requests?.total ?? 0,
              },
              {
                label: t('planning_past_title'),
                value: 'past',
                cards:
                  planningCourses?.data?.past?.data?.map((c) =>
                    courseToPastCardProps(c, t, user)
                  ) ?? [],
                total: planningCourses?.data?.past?.total ?? 0,
              },
            ],
          },
        }),
  }

  const preloadProps = useMemo(
    () => ({
      headerProps,
      menuProps,
      text: t('preload_text'),
      backButton,
    }),
    [headerProps, menuProps, t, backButton]
  )

  useEffect(() => {
    dispatch(actions.planning.planningCoursesRequest({ student }))
  }, [dispatch, student])

  useEffect(
    () => () => {
      dispatch(actions.planning.planningCoursesReset(null))
    },
    [dispatch]
  )

  if (!planningCourses.complete) {
    return <PreloadTemplate {...preloadProps} />
  }

  return <PlanningTemplate {...planningProps} />
}

export default PlanningPage
