import { Container } from '@material-ui/core'
import styled from 'styled-components'
import { Form } from 'formik'

import ToggleButtonPickerC from '../form/ToggleButtonPicker'
import DatePickerC from '../form/DatePicker'
import FormSubmit from '../FormSubmit'

export const AvailabilityRecurrenceForm = styled.div``

export const Wrapper = styled(Container)`
  padding: 0;
  display: flex;
  align-items: center;
`

export const FormikForm = styled(Form)`
  ${(props) => props.theme.breakpoints.up('md')} {
    max-width: 50rem;
  }
`

export const ToggleButtonPickerDays = styled(ToggleButtonPickerC)`
  margin-top: 4rem;
  & .MuiToggleButton-root {
    width: 100%;
    max-width: 16rem;
  }
  & h2 {
    ${(props) => ({ ...props.theme.typography.h3 })};
  }
  ${(props) => props.theme.breakpoints.down('sm')} {
    & .MuiToggleButtonGroup-root {
      justify-content: flex-start;
      & button {
        margin-right: 0.5rem;
      }
    }
  }
  ${(props) => props.theme.breakpoints.up('md')} {
    & .MuiToggleButtonGroup-root {
      & button {
        margin-right: 0.8rem !important;
      }
    }
  }
`

export const ToggleButtonPickerHours = styled(ToggleButtonPickerC)`
  margin-top: 4rem;
  & .MuiToggleButton-root {
    width: 100%;
    max-width: 10rem;
  }
  & h2 {
    ${(props) => ({ ...props.theme.typography.h3 })};
  }
  ${(props) => props.theme.breakpoints.down('sm')} {
    & .MuiToggleButtonGroup-root {
      justify-content: flex-start;
      & button {
        margin-right: 0.8rem;
      }
    }
  }
`

export const RangeTitle = styled.h4`
  color: ${(props) => props.theme.palette.colors.midnightBlue};
  ${(props) => ({ ...props.theme.typography.h3 })}
  margin-bottom: 1.6rem;
`

export const RangeField = styled(DatePickerC)`
  margin-top: 1.6rem;
  & .MuiInputLabel-root {
    color: ${(props) => props.theme.palette.colors.freeSpeechBlue};
  }
  & .MuiInputLabel-asterisk {
    color: ${(props) => props.theme.palette.colors.freeSpeechBlue};
  }
`

export const Button = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

export const SubmitButton = styled(FormSubmit)`
  margin-top: 5rem;
  width: 100%;
`
