import styled from 'styled-components'

import PageTitle from '../../components/PageTitle'
import PageBackButton from '../../components/PageBackButton'
import TabContentC from '../../components/TabContent'
import AvailabilityRecurrenceFormC from '../../components/AvailabilityRecurrenceForm'
import ActionButtonC from '../../components/ActionButton'
import RecurrenceCardC from '../../components/RecurrenceCard'

export const Content = styled.div``

export const Title = styled(PageTitle)``
export const BackButton = styled(PageBackButton)``

export const TabContent = styled(TabContentC)`
  padding: 0 1.6rem;
`

export const AvailabilityRecurrenceForm = styled(AvailabilityRecurrenceFormC)`
  padding: 0 1.6rem;
  margin-top: -2rem;
`

export const RecurrencesList = styled.div`
  display: flex;
  flex-direction: column;
  ${(props) => props.theme.breakpoints.up('md')} {
    margin: -0.8rem;
    flex-direction: row;
    flex-wrap: wrap;
  }
`

export const RecurrenceCard = styled(RecurrenceCardC)`
  margin-bottom: 1.6rem;
  ${(props) => props.theme.breakpoints.up('md')} {
    width: 34.3rem;
    margin: 0.8rem;
  }
`

export const ActionButton = styled(ActionButtonC)`
  margin-top: 2rem;
  width: auto;
  & > * {
    width: auto;
  }
`
