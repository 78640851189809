import gql from 'graphql-tag'

import { Notification, PaginatorInfo } from '../../../../generated/graphql'
import PaginatorInfoFragment from '../../../fragments/PaginatorInfoFragment'
import NotificationFragment from '../../../fragments/NotificationFragment'

export type NotificationsResult = {
  data: Notification[]
  paginatorInfo: PaginatorInfo
}

const notifications = {
  query: gql`
    query notifications($first: Int!, $page: Int) {
      notifications(first: $first, page: $page) {
        data {
          ...NotificationFragment
        }
        paginatorInfo {
          ...PaginatorInfoFragment
        }
      }
    }
    ${NotificationFragment}
    ${PaginatorInfoFragment}
  `,
  transformer: (response: any): NotificationsResult => {
    return response.notifications
  },
}

export default notifications
