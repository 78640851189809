import React, { FC } from 'react'

import { ToggleButtonGroupOption } from '../form/ToggleButtonGroup'

import * as SC from './styled'

export type LevelPickerGroup = {
  label: string
  options: ToggleButtonGroupOption[]
}

export type LevelPickerProps = {
  className?: string
  name: string
  title: string
  isExpand?: boolean
  onHeaderClick: () => void
  groups?: LevelPickerGroup[]
  onChange?: (name: string, value: string[]) => void
  value?: string[]
}

const LevelPicker: FC<LevelPickerProps> = (props) => {
  const { className, title, isExpand, onHeaderClick, name, groups, onChange, value } = props

  return (
    <SC.LevelPicker className={className}>
      <SC.Wrapper maxWidth="xl" $isDeployed={isExpand}>
        <SC.Title onClick={onHeaderClick} $isDeployed={isExpand}>
          {title}
        </SC.Title>
        {isExpand &&
          groups?.map((group, index) => (
            <SC.Group key={index}>
              <SC.SchoolTitle>{group?.label}</SC.SchoolTitle>
              <SC.ToggleButtonGroup
                name={`${name}_${index}`}
                options={group?.options}
                onChange={(_n, values) => onChange?.(name, values)}
                value={value}
              />
            </SC.Group>
          ))}
      </SC.Wrapper>
    </SC.LevelPicker>
  )
}

export default LevelPicker
