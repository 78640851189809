import React, { FC, useRef } from 'react'
import { Formik, Form, FormikHelpers, FormikProps } from 'formik'

import { CreditCartCardProps } from '../CreditCartCard'
import { TextFieldProps } from '../form/TextField'
import { ActionButtonProps } from '../ActionButton'

import * as SC from './styled'

interface InitialValues {
  amount: string
}

export type WithdrawMoneyFormProps = {
  className?: string
  rib: CreditCartCardProps
  amountField: TextFieldProps
  text: string
  submitButton: ActionButtonProps
  initialValues?: InitialValues
}

const WithdrawMoneyFormSchema = undefined

const WithdrawMoneyForm: FC<WithdrawMoneyFormProps> = (props) => {
  const { className, rib, amountField, text, submitButton, initialValues } = props

  const formikRef = useRef<FormikProps<InitialValues>>(null)

  const handleAmount = (name: string, value: string) => {
    formikRef?.current?.setFieldValue(name, value)
  }

  return (
    <SC.WithdrawMoneyForm className={className}>
      <SC.Wrapper maxWidth="xl" disableGutters>
        <SC.Card {...rib} />
        <Formik
          initialValues={(initialValues || {}) as InitialValues}
          innerRef={formikRef}
          validationSchema={WithdrawMoneyFormSchema}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={(values: InitialValues, { setSubmitting }: FormikHelpers<InitialValues>) => {
            setTimeout(() => {
              alert(JSON.stringify(values, null, 2))
              setSubmitting(false)
            }, 500)
          }}
        >
          {({ values }) => (
            <Form>
              <SC.Field {...amountField} onChange={handleAmount} value={values.amount} />
              <SC.Text>{text}</SC.Text>
              <SC.SubmitButton {...submitButton} />
            </Form>
          )}
        </Formik>
      </SC.Wrapper>
    </SC.WithdrawMoneyForm>
  )
}

export default WithdrawMoneyForm
