import React, { FC, useCallback, useMemo, useState } from 'react'
import { SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation } from 'swiper'
import dayjs from 'dayjs'

import * as SC from './styled'

export type DateProps = {
  label: string
  count: string
}

export type DateCarouselProps = {
  className?: string
  value: string
  onChange?: (v: string) => void
  dates?: { [key: string]: DateProps }
  transformer?: (date: string) => DateProps
}

SwiperCore.use([Navigation])

const DateCarousel: FC<DateCarouselProps> = (props) => {
  const { className, value, onChange, dates, transformer } = props
  const prepended = useMemo(
    () =>
      dayjs(value, 'YYYY-MM-DD')
        .hour(0)
        .minute(0)
        .second(0)
        .millisecond(0)
        .diff(dayjs().hour(0).minute(0).second(0).millisecond(0), 'day'),
    [value]
  )
  const [currentSlide, setCurrentSlide] = useState(0)
  const [appended, setAppended] = useState(2)

  const generateSlide = useCallback(
    (index: number): DateProps => {
      const date = dayjs(value, 'YYYY-MM-DD').add(index, 'day')
      const formattedDate = date.format('YYYY-MM-DD')
      if (dates?.[formattedDate]) {
        return {
          label: dates?.[formattedDate].label,
          count: dates?.[formattedDate].count,
        }
      }
      if (transformer) {
        return transformer(formattedDate)
      }
      return {
        label: date.format('DD/MM/YYYY'),
        count: index + '',
      }
    },
    [dates, transformer, value]
  )

  const slides = useMemo(
    () =>
      Array.from({ length: prepended + appended + 1 }).map((_, index) =>
        generateSlide(index - prepended)
      ),
    [prepended, appended, generateSlide]
  )

  const handleSlideChange = useCallback(
    (index: number) => {
      const realIndex = index - prepended
      setCurrentSlide(realIndex)
      if (realIndex > currentSlide && realIndex >= appended) {
        setAppended((before) => before + 1)
      }
      onChange?.(dayjs(value, 'YYYY-MM-DD').add(realIndex, 'day').format('YYYY-MM-DD'))
    },
    [appended, prepended, currentSlide, value, onChange]
  )

  return (
    <SC.DateCarousel className={className}>
      <SC.Wrapper disableGutters maxWidth={false}>
        <SC.Slider
          className={''}
          navigation={true}
          slidesPerView={1}
          onSlideChange={(sw) => {
            handleSlideChange(sw.realIndex)
          }}
          initialSlide={prepended}
        >
          {slides.map((date, index) => (
            <SwiperSlide key={date.label} virtualIndex={index}>
              <SC.DateWrapper>
                <SC.Date>{date.label}</SC.Date>
                <SC.DateNumber>{date.count}</SC.DateNumber>
              </SC.DateWrapper>
            </SwiperSlide>
          ))}
        </SC.Slider>
      </SC.Wrapper>
    </SC.DateCarousel>
  )
}

export default DateCarousel
