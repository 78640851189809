import gql from 'graphql-tag'

import UserFragment from '../../../fragments/UserFragment'
import { User } from '../../../../generated/graphql'

const linkGuardian = {
  mutation: gql`
    mutation linkGuardian($sponsor_code: String!) {
      linkGuardian(sponsor_code: $sponsor_code) {
        ...UserFragment
      }
    }
    ${UserFragment}
  `,
  transformer: (response: any): User => {
    return response.linkGuardian
  },
}

export default linkGuardian
