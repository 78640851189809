import gql from 'graphql-tag'

import UserFragment from '../../../fragments/UserFragment'
import type { User } from '../../../../generated/graphql'

const updateStudent = {
  mutation: gql`
    mutation updateStudent(
      $first_name: String!
      $last_name: String!
      $email: String!
      $school: String!
      $grade: ID!
      $avatar: String
    ) {
      updateStudent(
        first_name: $first_name
        last_name: $last_name
        email: $email
        school: $school
        grade: $grade
        avatar: $avatar
      ) {
        ...UserFragment
      }
    }
    ${UserFragment}
  `,
  transformer: (response: any): User => {
    return response.updateStudent
  },
}

export default updateStudent
