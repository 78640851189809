import gql from 'graphql-tag'

import type { Grade } from '../../../../generated/graphql'
import GradeFragment from '../../../fragments/GradeFragment'

const grades = {
  query: gql`
    query grades {
      grades {
        ...GradeFragment
      }
    }
    ${GradeFragment}
  `,
  transformer: (response: any): Grade[] => {
    return response.grades
  },
}

export default grades
