import { Container, Divider } from '@material-ui/core'
import styled from 'styled-components'

import BasicLink from '../BasicLink'
import TextField from '../form/TextField'
import FormSubmit from '../FormSubmit'
import ThemeListC from '../ThemeList'

export const ThemePicker = styled.div``

export const Wrapper = styled(Container)``

export const FreeThemeField = styled(TextField)`
  & label {
    color: ${(props) => props.theme.palette.colors.midnightBlue};
    ${(props) => ({ ...props.theme.typography.h4 })}
  }
`

export const FreeThemeCta = styled(BasicLink)`
  color: ${(props) => props.theme.palette.colors.freeSpeechBlue};
  ${(props) => ({ ...props.theme.typography.smallBold })}
  margin: 1.3rem 0 0 0;
  & hover {
    text-decoration: none;
  }
`

export const MuiDivider = styled(Divider)`
  margin: 2rem 0 3.1rem 0;
`

export const Themes = styled.div``

export const ThemeList = styled(ThemeListC)`
  margin-bottom: 2.4rem;
`

export const SubmitButton = styled(FormSubmit)``
