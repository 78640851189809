import gql from 'graphql-tag'

export default gql`
  fragment GradeFragment on Grade {
    id
    name
    slug
    tag
  }
`
