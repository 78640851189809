import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'

import { HeaderProps } from '../../components/Header'
import useHeader from '../../hooks/useHeader'
import { MenuProps } from '../../components/Menu'
import useMenu from '../../hooks/useMenu'
import { actions, selectors } from '../../redux'
import { router, routesPath } from '../../router'
import NotFoundTemplate from '../../templates/NotFound'
import PreloadTemplate from '../../templates/Preload'
import { CourseRequest, UserType } from '../../generated/graphql'
import { CourseDetailsProps } from '../../components/CourseDetails'
import useBackButton from '../../hooks/useBackButton'
import { RestrictedReactFC } from '../../types/common'
import CourseRequestTemplate, { CourseRequestTemplateProps } from '../../templates/CourseRequest'
import { availabilitySlotTranslation } from '../../graphql/enums/AvailabilitySlot'
import { useFormSubmit } from '../../hooks/useFormSubmit'
import useNotFound from '../../hooks/useNotFound'
import useFeedbackModal from '../../hooks/useFeedbackModal'
import { ActionSubmitModalProps } from '../../components/modals/ActionSubmitModal'

const CourseRequestPage: RestrictedReactFC<RouteComponentProps<{ id: string }>> = (props) => {
  const headerProps: HeaderProps = useHeader()
  const menuProps: MenuProps = useMenu(props)
  const notFoundProps = useNotFound(headerProps)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const id = props?.match?.params?.id
  const courseQuery = useSelector(selectors.planning.courseRequest)
  const courseRequest: CourseRequest | null = courseQuery?.data
  const backButton = useBackButton()
  const history = useHistory()

  const [setFeedbackModalOpen, cancelCourseFeedbackModalProps] = useFeedbackModal({
    title: t('course_details_cancelPendingFeedback_title'),
    subtitle: t('course_details_cancelPendingFeedback_subtitle'),
    onClose: () => {
      history.push(router(routesPath.dashboard))
    },
  })
  const [cancelModalOpen, setCancelModalOpen] = useState(false)

  const onCancelComplete = useCallback(() => {
    setFeedbackModalOpen(true)
    setCancelModalOpen(false)
  }, [setFeedbackModalOpen])

  const [cancelQuery, handleCancelQuery] = useFormSubmit(
    selectors.planning.cancelCourseRequest,
    actions.planning.cancelCourseRequestRequest,
    actions.planning.cancelCourseRequestReset,
    onCancelComplete
  )

  const cancelPendingCourseModalProps: ActionSubmitModalProps = useMemo(
    () => ({
      title: t('course_details_cancelPending_title'),
      subtitle: t('course_details_cancelPending_text'),
      cancelButton: {
        text: t('cancel'),
        onClick: () => setCancelModalOpen(false),
      },
      confirmButton: {
        text: t('course_details_cancelPending_confirm'),
        onClick: () => handleCancelQuery({ request: courseRequest?.id }),
        isPending: cancelQuery?.pending,
      },
      submitErrors: cancelQuery?.errors,
      open: cancelModalOpen,
      onClose: () => setCancelModalOpen(false),
    }),
    [
      cancelModalOpen,
      cancelQuery?.errors,
      cancelQuery?.pending,
      courseRequest?.id,
      handleCancelQuery,
      t,
    ]
  )

  const courseDetails: CourseDetailsProps = useMemo(
    () => ({
      title: courseRequest?.lesson?.theme?.discipline?.name ?? '',
      details: courseRequest
        ? [
            {
              title: t('course_details_lesson_title'),
              description: courseRequest?.lesson?.name ?? '',
            },
            {
              title: t('course_details_lesson_description'),
              description: courseRequest?.lesson?.description ?? '',
            },
          ]
        : [],
      submitButtonProps: {
        text: t('courseRequest_details_cancel'),
        onClick: () => setCancelModalOpen(true),
      },
      cancelPendingCourseModalProps,
      cancelCourseFeedbackModalProps,
    }),
    [courseRequest, t, cancelPendingCourseModalProps, cancelCourseFeedbackModalProps]
  )

  const dates = useMemo(
    () =>
      Object.values(
        courseRequest?.availabilities
          ? (courseRequest?.availabilities?.reduce((arr, date) => {
              const d = dayjs(date.date, 'YYYY-MM-DD')
              const iso = d.toISOString()
              if (!arr[iso]) {
                arr[iso] = {
                  date: t('courseRequest_date', { date: d.toDate() }),
                  slots: [],
                }
              }
              arr[iso].slots.push(availabilitySlotTranslation(t, date.slot))
              return arr
            }, {} as any) as { [key: string]: { date: string; slots: string[] } })
          : {}
      ) ?? [],
    [courseRequest, t]
  )

  const templateProps: CourseRequestTemplateProps = useMemo(
    () => ({
      headerProps,
      menuProps,
      title: t('courseRequest_details_title'),
      price: t('courseRequest_details_price', { price: (courseRequest?.maximum_price ?? 0) / 100 }),
      participants: t('courseRequest_details_participants', {
        count: courseRequest?.maximum_participants ?? 4,
      }),
      delay: t('courseRequest_details_delay', { delay: courseRequest?.notice_period }),
      dates,
      courseDetails,
      backButton,
    }),
    [courseRequest, courseDetails, headerProps, menuProps, backButton, dates, t]
  )

  const preloadProps = useMemo(
    () => ({
      headerProps,
      menuProps,
      text: t('preload_text'),
      backButton,
    }),
    [headerProps, menuProps, t, backButton]
  )

  useEffect(
    () => () => {
      dispatch(actions.planning.courseRequestReset(null))
    },
    [dispatch]
  )

  useEffect(() => {
    dispatch(actions.planning.courseRequestRequest(id))
  }, [dispatch, id])

  if (!courseQuery.complete) {
    return <PreloadTemplate {...preloadProps} />
  }

  if (courseQuery.errors || (courseQuery.complete && !courseQuery.data)) {
    return <NotFoundTemplate {...notFoundProps} />
  }

  return <CourseRequestTemplate {...templateProps} />
}

CourseRequestPage.restrictedUserTypes = [UserType.Guardian, UserType.Student]

export default CourseRequestPage
