import gql from 'graphql-tag'

import { User } from '../../../../generated/graphql'
import UserFragment from '../../../fragments/UserFragment'

const resetPassword = {
  mutation: gql`
    mutation resetPassword(
      $token: String!
      $email: String!
      $password: String!
      $passwordConfirmation: String!
    ) {
      resetPassword(
        token: $token
        email: $email
        password: $password
        password_confirmation: $passwordConfirmation
      ) {
        ...UserFragment
      }
    }
    ${UserFragment}
  `,
  transformer: (response: any): User => {
    return response.resetPassword
  },
}

export default resetPassword
