import React, { FC, ReactNode } from 'react'

import { ImgProps } from '../../types/image'
import { ActionButtonProps } from '../../components/ActionButton'
import { Icons } from '../../components/Icon/types'

import * as SC from './styled'

export type ReasonProps = {
  text: string
}

export type NotFoundProps = {
  title?: string
  body?: ReactNode
  cta?: ActionButtonProps
  image?: ImgProps
  backgroundImage?: ImgProps
  backgroundColor: string
}

const NotFound: FC<NotFoundProps> = (props) => {
  const { title, body, image, cta, backgroundColor, backgroundImage } = props

  return (
    <SC.NotFound backgroundColor={backgroundColor}>
      <SC.Wrapper maxWidth="xl" disableGutters>
        <SC.Content>
          <SC.Title>{title}</SC.Title>
          <SC.Body>{body}</SC.Body>
          {cta && <SC.Cta {...cta} outlined icon={Icons.longArrowRight} iconSide={'right'} />}
        </SC.Content>
        <SC.BoxImage>
          <SC.StyledImage {...image} />
        </SC.BoxImage>
      </SC.Wrapper>
      {backgroundImage && (
        <SC.BoxBackgroundImage>
          <SC.BackgroundImage src={backgroundImage.src} alt={backgroundImage.alt} />
        </SC.BoxBackgroundImage>
      )}
    </SC.NotFound>
  )
}

export default NotFound
