import gql from 'graphql-tag'

import CourseRequestFragment from '../../../fragments/CourseRequestFragment'

const cancelCourseRequest = {
  mutation: gql`
    mutation cancelCourseRequest($request: ID!) {
      cancelCourseRequest(request: $request) {
        ...CourseRequestFragment
      }
    }
    ${CourseRequestFragment}
  `,
  transformer: (response: any): any => {
    return response.cancelCourseRequest
  },
}

export default cancelCourseRequest
