import React, { FC, ReactNode, useCallback, useEffect } from 'react'

import * as SC from './styled'

export type TabProps = {
  label?: string
  children?: ReactNode
  value?: string
  numberOfCards?: string
}

export type TabContentProps = {
  className?: string
  name: string
  tabs: TabProps[]
  value: string
  onChange: (name: string, value: string) => void
}

interface TabPanelProps {
  children?: React.ReactNode
  index: any
  value: any
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  )
}

const TabContent: FC<TabContentProps> = (props) => {
  const { className, tabs, name, value, onChange } = props

  // Make sure that the Tab Indicator is resized when the window is resized
  useEffect(() => {
    window.dispatchEvent(new CustomEvent('resize'))
  }, [])

  const tabWrapperProps = (index: any) => {
    return {
      id: `tab-${index}`,
      'aria-controls': `tabpanel-${index}`,
    }
  }

  const handleChange = useCallback(
    (_event, newValue) => {
      onChange?.(name, tabs?.[newValue]?.value ?? '')
    },
    [name, tabs, onChange]
  )

  const selectedTab = tabs?.findIndex((t) => t.value === value) ?? 0

  return (
    <SC.TabContent className={className}>
      <SC.Wrapper maxWidth={false} disableGutters>
        <SC.TabsWrapper value={selectedTab} onChange={handleChange}>
          {tabs.map((tab, index) => (
            <SC.MuiTab
              key={index}
              label={tab.label}
              {...tabWrapperProps(index)}
              count={tab?.numberOfCards || ''}
            />
          ))}
        </SC.TabsWrapper>
        <SC.TabPanels>
          {tabs.map((tab, index) => (
            <TabPanel key={index} value={selectedTab} index={index}>
              {tab.children}
            </TabPanel>
          ))}
        </SC.TabPanels>
      </SC.Wrapper>
    </SC.TabContent>
  )
}

export default TabContent
