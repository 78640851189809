import React, { FC, useMemo } from 'react'
import { Form, Formik, FormikHelpers } from 'formik'
import * as Yup from 'yup'

import { Icons } from '../Icon/types'
import { light } from '../../theme/palette'
import { getValidationSchema, renderField } from '../../helpers/FormHelpers'
import { FormSubmitProps } from '../FormSubmit'
import FormSubmitErrors, { FormSubmitErrorsProps } from '../FormSubmitErrors'
import { FormFieldProps } from '../../types/form'

import * as SC from './styled'

export type MarketCourseFormValues = {
  type: string
  date: string
  price: string
}

export type MarketCourseFormProps = {
  className?: string
  submitButton: FormSubmitProps
  initialValues?: MarketCourseFormValues
  errorTexts?: {
    required: string
  }
  fieldsProps?: {
    type: FormFieldProps
    date: FormFieldProps
    price: FormFieldProps
  }
  onSubmit?: (
    values: MarketCourseFormValues,
    helpers: FormikHelpers<MarketCourseFormValues>
  ) => void
  submitErrors?: FormSubmitErrorsProps['errors']
}

const MarketCourseForm: FC<MarketCourseFormProps> = (props) => {
  const {
    className,
    submitButton,
    initialValues = { type: '', date: '', price: '' },
    errorTexts,
    onSubmit = (_v, _h) => null,
    submitErrors,
    fieldsProps,
  } = props

  const fields = useMemo(
    () => [
      {
        name: 'type',
        Component: SC.ToggleButtonGroup,
        validation: Yup.string().required(errorTexts?.required),
        required: true,
        isRadioMode: true,
      },
      {
        name: 'date',
        Component: SC.DatePicker,
        validation: Yup.string().required(errorTexts?.required),
        required: true,
        isRadioMode: true,
      },
      {
        name: 'price',
        Component: SC.PriceField,
        validation: Yup.string().required(errorTexts?.required),
        required: true,
        icon: Icons.euro,
        iconColor: light.colors.cornflowerBlue,
      },
    ],
    [errorTexts?.required]
  )

  const validationSchema = useMemo(() => getValidationSchema(fields), [fields])

  return (
    <SC.MarketCourseForm className={className}>
      <SC.Wrapper maxWidth="xl" disableGutters>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnChange={true}
          validateOnBlur={true}
          onSubmit={onSubmit}
        >
          {(formikProps) => (
            <Form noValidate>
              {fields?.map((field, index) => renderField(field, formikProps, fieldsProps, index))}
              <FormSubmitErrors errors={submitErrors} />
              <SC.SubmitButton {...submitButton} autoIcon />
            </Form>
          )}
        </Formik>
      </SC.Wrapper>
    </SC.MarketCourseForm>
  )
}

export default MarketCourseForm
