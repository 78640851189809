import { Container, Divider } from '@material-ui/core'
import styled from 'styled-components'

import Link from '../BasicLink'
import Icon from '../Icon'

export const RecurrenceCard = styled.div`
  ${(props) => props.theme.breakpoints.up('md')} {
    max-width: 40rem;
  }
`
export const Wrapper = styled(Container)`
  padding: 1.6rem;
  background-color: ${(props) => props.theme.palette.colors.pureWhite};
  border-radius: 0.8rem;
  border: 1px solid ${(props) => props.theme.palette.colors.ghostWhite};
  position: relative;
  ${(props) => props.theme.breakpoints.up('md')} {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
`

export const Content = styled.div`
  flex-grow: 1;
  width: 100%;
`

export const Muidivider = styled(Divider)`
  width: 100%;
  margin: 1.2rem 0rem;
`

export const Weekdays = styled.h4`
  ${(props) => ({ ...props.theme.typography.h4 })}
  color: ${(props) => props.theme.palette.colors.midnightBlue};
  margin: 0;
`

export const Hours = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`

export const Hour = styled.span`
  ${(props) => ({ ...props.theme.typography.smallRegular })}
  color: ${(props) => props.theme.palette.colors.cornflowerBlue};
`

export const Point = styled.p`
  margin: 0;
  ${(props) => ({ ...props.theme.typography.h2 })}
  color: ${(props) => props.theme.palette.colors.grey};
  line-height: 0.1;
  margin: 0 0.5rem;
  transform: translateY(-0.8rem);
`

export const Dates = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1rem;
`

export const DateChrevron = styled(Icon)`
  width: 1.5rem;
  height: 1.3rem;
`

export const Date = styled.span`
  ${(props) => ({ ...props.theme.typography.smallRegular })}
  color: ${(props) => props.theme.palette.colors.chambray};
`

export const Cta = styled(Link)`
  margin-top: 2rem;
  display: flex;
  ${(props) => ({ ...props.theme.typography.callToAction })}
  text-decoration: none;
  color: ${(props) => props.theme.palette.colors.cornflowerBlue};
  cursor: pointer;
`

export const TrashIcon = styled(Icon)`
  width: 3.4rem;
  height: 3.4rem;
  position: absolute;
  right: 1rem;
  bottom: 1rem;
`
