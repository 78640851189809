import styled from 'styled-components'
import { Container } from '@material-ui/core'

import TextField from '../form/TextField'

export const Search = styled.div`
  display: flex;
  justify-content: center;
  ${(props) => props.theme.breakpoints.up('md')} {
    justify-content: flex-start;
  }
`

export const Wrapper = styled(Container)`
  ${(props) => props.theme.breakpoints.up('md')} {
    margin: 0;
    width: 70%;
  }
`

export const Content = styled.div`
  background-color: ${(props) => props.theme.palette.colors.ghostWhite};
  padding: 1rem 1.6rem 0.1rem 1.6rem;
`

export const Title = styled.h4`
  color: ${(props) => props.theme.palette.colors.midnightBlue};
  ${(props) => ({ ...props.theme.typography.h4 })}
  margin: 0 0 1rem 0;
`

export const SearchInput = styled(TextField)`
  margin: 0 0 1rem 0;
`

export const Results = styled.div`
  padding: 1rem 1.6rem;
  width: 100%;
  & > div > div {
    max-width: 100%;
  }
`

export const Subtitle = styled.h4`
  color: ${(props) => props.theme.palette.colors.midnightBlue};
  ${(props) => ({ ...props.theme.typography.h4 })}
  padding: 0 1.6rem;
  margin: 1.6rem 0;
`

export const SelectLevel = styled(TextField)`
  padding: 0 1.6rem;
`
