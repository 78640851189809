import React, { FC } from 'react'
import { IMaskInput } from 'react-imask'

import TextField, { TextFieldProps } from '../TextField'

export type NumberProps = TextFieldProps & {
  className?: string
  maxValue?: number
}

const CustomIbanFormat: FC<any> = (props) => {
  const { inputRef, onChange, ...other } = props

  return (
    <IMaskInput
      {...other}
      mask={'**** **** **** **** **** **** ***'}
      unmask
      inputRef={inputRef}
      onAccept={(value: any) => {
        onChange({
          target: {
            name: props.name,
            value: value,
          },
        })
      }}
    />
  )
}

const Iban: FC<NumberProps> = (props) => {
  return (
    <TextField
      {...props}
      InputProps={{
        inputComponent: CustomIbanFormat as any,
        ...props.InputProps,
      }}
      inputProps={{
        ...props?.inputProps,
        ...(props.maxValue && { maxValue: props?.maxValue }),
      }}
    />
  )
}

export default Iban
