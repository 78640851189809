import React, { FC } from 'react'

import { ActionButtonProps } from '../ActionButton'
import { BasicTextAndImageProps } from '../BasicTextAndImage'
import PageBackButton, { PageBackButtonProps } from '../PageBackButton'
import { FinishedCourseMetaProps } from '../FinishedCourseMeta'
import ReportingModal, { ReportingModalProps } from '../modals/ReportingModal'
import SimpleFeedbackModal, { SimpleFeedbackModalProps } from '../modals/SimpleFeedbackModal'

import * as SC from './styled'

export type CallEndedProps = {
  backButtonProps?: PageBackButtonProps
  textAndImageProps: BasicTextAndImageProps
  cta?: ActionButtonProps
  errorText?: string
  errorCta?: ActionButtonProps
  courseMeta?: FinishedCourseMetaProps
  reportCta?: ActionButtonProps
  reportingModalProps?: ReportingModalProps
  reportingFeedbackModalProps?: SimpleFeedbackModalProps
}

const CallEnded: FC<CallEndedProps> = (props) => {
  const {
    backButtonProps,
    textAndImageProps,
    cta,
    errorText,
    errorCta,
    courseMeta,
    reportCta,
    reportingModalProps,
    reportingFeedbackModalProps,
  } = props

  return (
    <SC.CallEnded>
      <SC.Wrapper>
        <PageBackButton {...backButtonProps} />
        <SC.TextAndImage {...textAndImageProps} />
        {cta && <SC.Action {...cta} />}
        {errorText && <SC.Text>{errorText}</SC.Text>}
        {errorCta && <SC.Action {...errorCta} outlined />}
        {reportCta && <SC.ReportAction {...reportCta} />}
        {courseMeta && <SC.CourseMeta {...courseMeta} />}
      </SC.Wrapper>
      {reportingModalProps && <ReportingModal {...reportingModalProps} />}
      {reportingFeedbackModalProps && <SimpleFeedbackModal {...reportingFeedbackModalProps} />}
    </SC.CallEnded>
  )
}

export default CallEnded
