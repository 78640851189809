import React, { FC } from 'react'

import { RatingModalitiesProps } from '../RatingModalities'
import { BasicProfilProps } from '../BasicProfil'
import { CheckboxProps } from '../form/Checkbox'
import { TextFieldLimitedCharsProps } from '../form/TextFieldLimitedChars'

import * as SC from './styled'

export type RatingWithFieldsProps = {
  className?: string
  rateModalities: RatingModalitiesProps
  commentaryFieldTitle?: string
  commentaryField?: TextFieldLimitedCharsProps
  nonDesiredCheckbox?: CheckboxProps
  profils?: BasicProfilProps[]
  recurrentCheckBox?: CheckboxProps
  recurrentText?: string
}

const RatingWithFields: FC<RatingWithFieldsProps> = (props) => {
  const {
    className,
    rateModalities,
    commentaryFieldTitle,
    commentaryField,
    nonDesiredCheckbox,
    profils,
    recurrentCheckBox,
    recurrentText,
  } = props

  return (
    <SC.RatingWithFields className={className}>
      <SC.Wrapper disableGutters>
        <SC.Box>
          <SC.RatingModalities {...rateModalities} />
          {commentaryFieldTitle && commentaryField && (
            <>
              <SC.CommentaryTitle>{commentaryFieldTitle}</SC.CommentaryTitle>
              <SC.CommentaryField {...commentaryField} rows={5} />
            </>
          )}
          {nonDesiredCheckbox && (
            <>
              <SC.Muidivider />
              <SC.CheckboxWrapper>
                <SC.Checkbox {...nonDesiredCheckbox} />
              </SC.CheckboxWrapper>
            </>
          )}
          {profils &&
            profils.map((profil, index) => (
              <>
                <SC.BasicProfil {...profil} key={index} />
                {index < profils.length - 1 && <SC.Muidivider />}
              </>
            ))}
        </SC.Box>
        {recurrentCheckBox && (
          <SC.Box>
            <SC.RecurrentCheckbox>
              <SC.Checkbox {...recurrentCheckBox} />
            </SC.RecurrentCheckbox>
            <SC.Muidivider />
            {recurrentText && <SC.CommentaryText>{recurrentText}</SC.CommentaryText>}
          </SC.Box>
        )}
      </SC.Wrapper>
    </SC.RatingWithFields>
  )
}

export default RatingWithFields
