import gql from 'graphql-tag'

const registerFamily = {
  mutation: gql`
    mutation registerFamily($members: [FamilyMemberInput!]) {
      registerFamily(members: $members) {
        status
        guardian {
          children {
            school
            grade {
              id
            }
            first_name
            last_name
            email
            status
            avatar
          }
        }
      }
    }
  `,
  transformer: (response: any): any => {
    return response.registerFamily
  },
}

export default registerFamily
