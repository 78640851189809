import gql from 'graphql-tag'

import GuardianFragment from '../../../fragments/GuardianFragment'
import { User } from '../../../../generated/graphql'

const deleteFamilyMember = {
  mutation: gql`
    mutation deleteFamilyMember($student: ID!) {
      deleteFamilyMember(student: $student) {
        guardian {
          ...GuardianFragment
        }
      }
    }
    ${GuardianFragment}
  `,
  transformer: (response: any): User => {
    return response.deleteFamilyMember
  },
}

export default deleteFamilyMember
