import React, { useMemo } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { HeaderProps } from '../../components/Header'
import useHeader from '../../hooks/useHeader'
import { MenuProps } from '../../components/Menu'
import useMenu from '../../hooks/useMenu'
import { UserType } from '../../generated/graphql'
import { RestrictedReactFC } from '../../types/common'
import { router, routesPath } from '../../router'
import { actions, selectors } from '../../redux'
import PreferencesDisciplinesTemplate, {
  PreferencesDisciplinesTemplateProps,
} from '../../templates/PreferencesDisciplines'
import { useFormSubmit } from '../../hooks/useFormSubmit'
import { ToggleButtonGroupOption } from '../../components/form/ToggleButtonGroup'
import useBackButton from '../../hooks/useBackButton'

const PreferencesDisciplinesPage: RestrictedReactFC<RouteComponentProps> = (props) => {
  const headerProps: HeaderProps = useHeader()
  const menuProps: MenuProps = useMenu(props)
  const { t } = useTranslation()
  const user = useSelector(selectors.auth.user)
  const grades = useSelector(selectors.app.grades)
  const disciplines = useSelector(selectors.app.disciplines)
  const backButton = useBackButton(router(routesPath.preferences))

  const [submit, handleSubmit] = useFormSubmit(
    selectors.preferences.updateTeacherDisciplines,
    actions.preferences.updateTeacherDisciplinesRequest,
    actions.preferences.updateTeacherDisciplinesReset
  )

  const gradesByTag = useMemo(
    () =>
      Object.values(
        grades?.reduce(
          (arr, grade) => {
            const tag = arr[grade?.tag || ''] || {
              label: grade?.tag,
              options: [],
            }
            tag.options.push({
              label: grade.name,
              value: grade.id,
            })
            arr[grade?.tag || ''] = tag
            return arr
          },
          {} as {
            [key: string]: {
              label: string
              options: ToggleButtonGroupOption[]
            }
          }
        )
      ),
    [grades]
  )

  const levels = useMemo(
    () =>
      disciplines?.map((discipline) => ({
        onHeaderClick: () => null,
        name: discipline.id,
        title: discipline.name,
        groups: gradesByTag,
      })),
    [disciplines, gradesByTag]
  )

  const userDisciplines = useMemo(
    () =>
      user?.teacher?.disciplines?.reduce((arr, couple) => {
        const discipline = couple.discipline.id
        if (!arr[discipline]) {
          arr[discipline] = []
        }
        arr[discipline].push(couple.grade.id)
        return arr
      }, {} as { [key: string]: string[] }),
    [user?.teacher?.disciplines]
  )

  const initialValues = useMemo(
    () =>
      disciplines.reduce(
        (arr, discipline) => ({ ...arr, [discipline.id]: userDisciplines?.[discipline.id] || [] }),
        {}
      ),
    [disciplines, userDisciplines]
  )

  const preferencesProps: PreferencesDisciplinesTemplateProps = {
    menuProps,
    headerProps,
    title: t('preferences_disciplines_title'),
    levelPickersProps: {
      text: t('preferences_disciplines_text'),
      levelForSubject: levels,
      initialValues,
      submitButton: {
        text: t('preferences_disciplines_submit'),
        pendingText: t('preferences_disciplines_submit_pending'),
        successText: t('preferences_disciplines_submit_success'),
        isPending: submit.pending,
        isSuccess: submit.success,
      },
      submitErrors: submit.errors,
      onSubmit: (values) => {
        handleSubmit(values)
      },
    },
    backButton,
  }

  return <PreferencesDisciplinesTemplate {...preferencesProps} />
}

PreferencesDisciplinesPage.restrictedUserTypes = [UserType.Teacher]

export default PreferencesDisciplinesPage
