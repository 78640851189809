// noinspection GraphQLUnresolvedReference

import gql from 'graphql-tag'

import type { Configuration } from '../../../../generated/graphql-contentful'

const me = {
  query: gql`
    query configurationByLanguage($locale: String) {
      configurationCollection(preview: false, locale: $locale, limit: 1) {
        items {
          translations
          mainLinksCollection(limit: 100) {
            items {
              url
              icon
              label
              target
              special
            }
          }
          mobileRegisterLinksCollection(limit: 100) {
            items {
              url
              icon
              label
              target
            }
          }
          mobileExtraLinksCollection(limit: 100) {
            items {
              url
              icon
              label
              target
            }
          }
          mobileHeadIcon {
            url
            icon
            label
            target
          }
          socialLinksCollection(limit: 100) {
            items {
              url
              icon
              label
              target
            }
          }
          footerSectionsCollection(limit: 100) {
            items {
              topLink {
                url
                icon
                label
                target
              }
              linksCollection(limit: 100) {
                items {
                  url
                  icon
                  label
                  target
                }
              }
            }
          }
          footerLegalsCollection(limit: 100) {
            items {
              url
              icon
              label
              target
            }
          }
          languagesCollection(limit: 20) {
            items {
              title
              iso
            }
          }
          notFound {
            title
            image {
              url(transform: { width: 1440 })
            }
            cta {
              url
              icon
              label
              target
            }
            body {
              json
            }
          }
        }
      }
    }
  `,
  transformer: (response: any): Partial<Configuration> => {
    return response?.configurationCollection?.items?.[0]
  },
}

export default me
