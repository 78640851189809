import React, { FC } from 'react'

import { BasicLinkProps } from '../BasicLink'
import { Icons } from '../Icon/types'
import { light } from '../../theme/palette'

import * as SC from './styled'

export type MenuItemProps = BasicLinkProps & {
  icon: Icons
  label: string
  selected?: boolean
}

export type MenuProps = {
  className?: string
  sections: MenuItemProps[]
  disconnect: MenuItemProps
}

const Menu: FC<MenuProps> = (props) => {
  const { className, sections, disconnect } = props
  return (
    <SC.Menu className={className}>
      <SC.MenuContent>
        <SC.TabBarCardContent>
          <SC.Items disablePadding>
            {sections.map((section, idx) => (
              <SC.ItemLink
                href={section?.link?.href || section?.link?.link}
                key={`tabBar-item-${section.link}-${idx}`}
              >
                <SC.Item button selected={!!section.selected}>
                  <SC.Icon icon={section.icon} />
                </SC.Item>
              </SC.ItemLink>
            ))}
          </SC.Items>
        </SC.TabBarCardContent>
        <SC.MenuTop>
          {sections.map((section, index) => (
            <SC.MenuItem
              key={index}
              link={section.link}
              iconColor={section.selected ? light.colors.midnightBlue : light.colors.echoBlue}
              iconSide="left"
              selected={section.selected}
            >
              <SC.MenuIcon icon={section.icon} selected={section.selected} />
              <SC.MenuLabel>{section.label}</SC.MenuLabel>
            </SC.MenuItem>
          ))}
        </SC.MenuTop>
        <SC.MenuBottom>
          <SC.MenuItemDisconnect
            text={disconnect.label}
            link={disconnect.link}
            icon={disconnect.icon}
            iconSide="left"
          />
        </SC.MenuBottom>
      </SC.MenuContent>
    </SC.Menu>
  )
}

export default Menu
