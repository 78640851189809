import styled from 'styled-components'
import { Container } from '@material-ui/core'

import ImageTextList from '../ImageTextList'
import FormSubmit from '../FormSubmit'
import ImageTextItem from '../ImageTextItem'
import ToggleButtonGroup from '../form/ToggleButtonGroup'
import Link from '../Link'

export const SearchSubjectForm = styled.div`
  display: flex;
  justify-content: center;
  ${(props) => props.theme.breakpoints.up('md')} {
    justify-content: flex-start;
  }
`

export const Wrapper = styled(Container)`
  ${(props) => props.theme.breakpoints.up('md')} {
    margin: 0;
    width: 70%;
  }
`

export const List = styled(ImageTextList)``

export const Field = styled.div``

export const RequestForLabel = styled.p`
  color: ${(props) => props.theme.palette.colors.midnightBlue};
  ${(props) => ({ ...props.theme.typography.h4 })}
  margin: 0 0 0.3rem 0;
`

export const RequestFor = styled(ImageTextItem)`
  margin-bottom: 2.4rem;
  cursor: default;
`

export const RequestSubjectLabel = styled.p`
  color: ${(props) => props.theme.palette.colors.midnightBlue};
  ${(props) => ({ ...props.theme.typography.h4 })}
  margin: 0 0 1.6rem 0;
`

export const Subject = styled(Link)`
  margin: 0 0 1.6rem;
  text-decoration: none;
  display: flex;
  max-width: 47rem;
  &:hover {
    & > * {
      border-color: ${(props) => props.theme.palette.colors.cornflowerBlue};
    }
  }
`

export const SubjectWrapper = styled(Container)<{ $isDeployed?: boolean }>`
  cursor: pointer;
  border: 1px solid
    ${(props) =>
      props.$isDeployed
        ? props.theme.palette.colors.cornflowerBlue
        : props.theme.palette.colors.gainsboro};
  border-radius: 0.8rem;
`

export const SubjectTitle = styled.h2<{ $isDeployed?: boolean }>`
  width: 100%;
  color: ${(props) =>
    props.$isDeployed
      ? props.theme.palette.colors.cornflowerBlue
      : props.theme.palette.colors.freeSpeechBlue};
  ${(props) =>
    props.$isDeployed ? { ...props.theme.typography.h3 } : { ...props.theme.typography.h4 }}
  transition: all 0.2s ease-in-out;
`

export const TypeOfRequest = styled(ToggleButtonGroup)`
  margin: 0 0 1rem 0;
  & .MuiToggleButton-root {
    padding: 1rem 1.2rem;
  }
`

export const SubmitButton = styled(FormSubmit)`
  margin: 0 0 2rem 0;
  max-width: 47rem;
`
