import styled from 'styled-components'

import ActionButton from '../ActionButton'

export const SocialConnect = styled.div`
  padding: 0;
`

export const GoogleCta = styled(ActionButton)`
  width: 100%;
  margin-top: 1rem;
  background-color: ${(props) => props.theme.palette.colors.pureWhite};
  border: 1px solid ${(props) => props.theme.palette.colors.gainsboro};
  border-radius: 1rem;
  padding: 2rem;
  & > span {
    ${(props) => ({ ...props.theme.typography.h5 })}
    color: ${(props) => props.theme.palette.colors.chambray};
  }
  &:hover {
    border: 1px solid ${(props) => props.theme.palette.colors.gainsboro};
    background-color: ${(props) => props.theme.palette.colors.gainsboro};
    opacity: 0.8;
  }
`

export const AppleCta = styled(ActionButton)`
  width: 100%;
  margin-top: 1rem;
  background-color: ${(props) => props.theme.palette.colors.pureBlack};
  border: 1px solid ${(props) => props.theme.palette.colors.pureWhite};
  border-radius: 1rem;
  padding: 2rem;
  & > span {
    ${(props) => ({ ...props.theme.typography.h5 })}
    color: ${(props) => props.theme.palette.colors.pureWhite};
  }
  &:hover {
    border: 1px solid ${(props) => props.theme.palette.colors.pureBlack};
    background-color: ${(props) => props.theme.palette.colors.pureBlack};
    opacity: 0.8;
  }
`
