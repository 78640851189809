import styled, { css } from 'styled-components'
import { Link } from '@material-ui/core'

import Icon from '../Icon'
import LinkC from '../Link'

const styles = css<{ backgroundColor?: string; color?: string }>`
  width: max-content;
  color: ${(props) => props.color || 'inherit'};
  background-color: ${(props) => props.backgroundColor || 'transparent'};
  padding: ${(props) => (props.backgroundColor ? '1rem' : 0)};
  border-radius: ${(props) => (props.backgroundColor ? '0.5rem' : 0)};
  text-transform: ${(props) => (props.backgroundColor ? 'uppercase' : 'none')};
  display: flex;
  cursor: pointer;
  align-items: center;
`

export const Box = styled.div`
  display: flex;
  align-items: center;
`

export const LinkA = styled(LinkC)<{ backgroundColor?: string; color?: string }>`
  ${styles}
`

export const MuiLink = styled(Link)<{ backgroundColor?: string; color?: string }>`
  ${styles}
`

export const IconIn = styled(Icon)<{ iconSide?: string }>`
  width: 1.8rem;
  height: 1.8rem;
  margin-left: ${(props) => (props.iconSide === 'left' ? '0' : '1rem')};
  margin-right: ${(props) => (props.iconSide === 'right' ? '0' : '1rem')};
`
