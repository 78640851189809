import gql from 'graphql-tag'

import ThemeFragment from './ThemeFragment'

export default gql`
  fragment LessonFragment on Lesson {
    id
    name
    slug
    description
    theme {
      ...ThemeFragment
    }
    supports {
      file_name
      human_readable_size
      mime_type
      url
    }
    type
  }
  ${ThemeFragment}
`
