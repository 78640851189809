import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { HeaderProps } from '../../components/Header'
import useHeader from '../../hooks/useHeader'
import { MenuProps } from '../../components/Menu'
import useMenu from '../../hooks/useMenu'
import { CourseType, Lesson, LessonType, Theme, UserType } from '../../generated/graphql'
import { RestrictedReactFC } from '../../types/common'
import { actions, selectors } from '../../redux'
import { router, routesPath } from '../../router'
import { courseTypeBySlug, courseTypeTranslation } from '../../graphql/enums/CourseType'
import { SearchPriceTemplate, SearchPriceTemplateProps } from '../../templates/SearchPrice'
import { SearchSteps } from '../../redux/search/types/state'
import useBackButton from '../../hooks/useBackButton'

enum SizeEnum {
  's1' = '1',
  's2' = '2',
  's3' = '3',
  's4' = '4',
}

const SearchPricePage: RestrictedReactFC<
  RouteComponentProps<{ type: string; discipline: string; theme: string; child?: string }>
> = (props) => {
  const headerProps: HeaderProps = useHeader()
  const menuProps: MenuProps = useMenu(props)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const user = useSelector(selectors.auth.user)
  const disciplines = useSelector(selectors.app.disciplines)
  const themes = useSelector(selectors.search.themes)
  const storeLesson = useSelector(selectors.search.lesson)
  const typeSlug = props?.match?.params?.type
  const type: CourseType = courseTypeBySlug(t, typeSlug)
  const child = props?.match?.params?.child
  const disciplineSlug = props?.match?.params?.discipline
  const discipline = useMemo(
    () => disciplines?.find((discipline) => discipline.slug === disciplineSlug),
    [disciplines, disciplineSlug]
  )
  const student =
    user?.user_type === UserType.Student
      ? user?.student
      : user?.guardian?.children?.find((c) => c.id === child) ?? user?.guardian?.children?.[0]

  const lessonSlug = props?.match?.params?.theme
  const lesson: Lesson = useMemo(() => {
    return (
      themes?.data
        ?.reduce((arr: Lesson[], th: Theme) => [...arr, ...(th?.lessons ? th.lessons : [])], [])
        ?.find((l: Lesson) => l.slug === lessonSlug) ?? storeLesson
    )
  }, [themes?.data, lessonSlug, storeLesson])

  const theme = useMemo(
    () =>
      themes?.data?.find(
        (th: Theme) => th?.lessons && th?.lessons?.find((l) => l.slug === lesson?.slug)
      ),
    [themes, lesson]
  )

  const price = useSelector(selectors.search.price)
  const size = useSelector(selectors.search.size)

  const [sizeState, setSizeState] = useState<SizeEnum>((size as SizeEnum) ?? SizeEnum.s4)

  const backButton = useBackButton(
    router(routesPath.searchTheme, {
      type: typeSlug,
      discipline: disciplineSlug,
      ...(child && { child }),
    })
  )

  const handleSubmit = useCallback(
    (values) => {
      dispatch(
        actions.search.saveStep({
          step: SearchSteps.STEP_3_PRICE,
          price: values?.price,
          student,
          discipline,
          lesson: lesson,
          type,
          size: values?.size,
        })
      )
      history.push(
        router(routesPath.searchAvailabilities, {
          type: typeSlug,
          discipline: disciplineSlug,
          theme: lessonSlug,
          price: values.price,
          size: values.size,
          ...(child && { child }),
        })
      )
    },
    [
      student,
      lesson,
      child,
      disciplineSlug,
      history,
      lessonSlug,
      typeSlug,
      type,
      discipline,
      dispatch,
    ]
  )

  const searchProps: SearchPriceTemplateProps = {
    headerProps: headerProps,
    menuProps: menuProps,
    title: t('search_title'),
    subTitle: courseTypeTranslation(t, type),
    text: t('searchPrice_text'),
    courseFormProps: {
      themeCard: {
        subject: discipline?.name ?? '',
        themeName:
          lesson?.type === LessonType.Custom
            ? t('searchPrice_card_custom_theme_label')
            : theme?.name ?? '',
        lessonName: lesson?.name ?? '',
        lessonDescription: lesson?.description ?? '',
      },
      priceLabel: t('searchPrice_form_price_label'),
      priceRecommandationLabel: t('searchPrice_form_suggested_label'),
      priceRecommandation: t('searchPrice_form_suggested_value', {
        price: t(`searchPrice_form_price_${sizeState ?? SizeEnum.s4}`),
      }),
      fieldsProps: {
        size: {
          label: t('searchPrice_form_participants_label'),
          options: [
            {
              label: '1',
              value: '1',
            },
            {
              label: '2',
              value: '2',
              disabled: lesson?.type === LessonType.Custom,
            },
            {
              label: '3',
              value: '3',
              disabled: lesson?.type === LessonType.Custom,
            },
            {
              label: '4',
              value: '4',
              disabled: lesson?.type === LessonType.Custom,
            },
          ],
          onChange: (_n: string, v: string) => setSizeState(v as SizeEnum),
        },
        price: {
          placeholder: t(`searchPrice_form_price_${sizeState ?? SizeEnum.s4}`),
          helperText: t('searchPrice_form_price_help'),
          helperTextSide: 'left',
          maxValue: 65,
        },
      },
      errorTexts: {
        required: t('error_required'),
      },
      initialValues: {
        size: lesson?.type === LessonType.Custom ? '1' : size ?? '4',
        price: price ?? '',
      },
      submitButton: {
        text: t('next'),
      },
      onSubmit: handleSubmit,
    },
    backButton,
  }

  useEffect(() => {
    if (discipline?.id && student?.grade?.id) {
      if (
        themes?.params?.grade !== student?.grade?.id ||
        themes?.params?.discipline !== discipline?.id
      ) {
        dispatch(
          actions.search.themesRequest({
            discipline: discipline?.id,
            grade: student?.grade?.id,
          })
        )
      }
    }
  }, [discipline?.id, dispatch, student?.grade?.id, themes?.params])

  return <SearchPriceTemplate {...searchProps} />
}

SearchPricePage.restrictedUserTypes = [UserType.Guardian, UserType.Student]

export default SearchPricePage
