import gql from 'graphql-tag'

import UserFragment from '../../../fragments/UserFragment'
import { User } from '../../../../generated/graphql'

const linkGuardianLater = {
  mutation: gql`
    mutation linkGuardianLater {
      linkGuardianLater {
        ...UserFragment
      }
    }
    ${UserFragment}
  `,
  transformer: (response: any): User => {
    return response.linkGuardianLater
  },
}

export default linkGuardianLater
