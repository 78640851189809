import gql from 'graphql-tag'

import UserFragment from '../../../fragments/UserFragment'
import type { User } from '../../../../generated/graphql'

const updateTeacher = {
  mutation: gql`
    mutation updateTeacher(
      $first_name: String!
      $last_name: String!
      $email: String!
      $phone: String!
      $display_address: String!
      $address: String
      $city: String
      $zip_code: String
      $country: String
      $cv: Upload
      $diploma: Upload
      $company_certificate: Upload
      $introduction: String
      $avatar: String
    ) {
      updateTeacher(
        first_name: $first_name
        last_name: $last_name
        email: $email
        phone: $phone
        display_address: $display_address
        address: $address
        city: $city
        zip_code: $zip_code
        country: $country
        cv: $cv
        diploma: $diploma
        company_certificate: $company_certificate
        introduction: $introduction
        avatar: $avatar
      ) {
        ...UserFragment
      }
    }
    ${UserFragment}
  `,
  transformer: (response: any): User => {
    return response.updateTeacher
  },
}

export default updateTeacher
