import React, { FC } from 'react'
import { Formik } from 'formik'

import { TextFieldProps } from '../form/TextField'
import { PaymentFieldProps } from '../form/PaymentField'

import * as SC from './styled'

export type CreditCartCardProps = {
  className?: string
  title: string
  nameField: TextFieldProps
  paymentField: PaymentFieldProps
}

const validationSchema = undefined

const CreditCartCard: FC<CreditCartCardProps> = (props) => {
  const { className, title, nameField, paymentField } = props

  return (
    <SC.CreditCartCard className={className}>
      <SC.Wrapper disableGutters>
        <SC.Title>{title}</SC.Title>
        <Formik
          initialValues={{
            cardNumber: '',
            expiryDate: '',
            cvc: '',
            name: '',
          }}
          onSubmit={(data) => console.log(data)}
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue }) => (
            <form>
              <SC.Field
                {...nameField}
                value={values.name}
                onChange={(name, value) => setFieldValue('name', value)}
              />
              <SC.PaymentField
                cardInputPlaceholder={paymentField.cardInputPlaceholder}
                errorMessages={paymentField.errorMessages}
                cardNumberField={{
                  name: 'cardNumber',
                  onChange: (name, value) => setFieldValue('cardNumber', value),
                  value: values.cardNumber,
                }}
                cardExpiryField={{
                  name: 'expiryDate',
                  onChange: (name, value) => setFieldValue('expiryDate', value),
                  value: values.expiryDate,
                }}
                cardCVCField={{
                  name: 'cvc',
                  onChange: (name, value) => setFieldValue('cvc', value),
                  value: values.cvc,
                }}
              />
            </form>
          )}
        </Formik>
      </SC.Wrapper>
    </SC.CreditCartCard>
  )
}

export default CreditCartCard
