import { Container } from '@material-ui/core'
import styled from 'styled-components'
import { Swiper } from 'swiper/react'

import BasicLink from '../BasicLink'
import Icon from '../Icon'

export const CourseCardCarousel = styled.div`
  width: 100%;
`

export const Wrapper = styled(Container)``

export const Title = styled.h3`
  ${(props) => ({ ...props.theme.typography.h4 })}
  color: ${(props) => props.theme.palette.colors.midnightBlue};
  margin: 0 0 1.6rem 0;
`
export const Text = styled.div`
  ${(props) => ({ ...props.theme.typography.mediumRegular })}
  color: ${(props) => props.theme.palette.colors.midnightBlue};
  margin: 1.6rem 0 1.6rem 0;
`

export const Slider = styled(Swiper)`
  &,
  & * {
    -webkit-tap-highlight-color: transparent;
  }
  &.swiper-container {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: hidden;
    list-style: none;
    padding: 0 0 3rem;
    width: 100%;
    /* Fix of Webkit flickering */
    z-index: 1;
  }
  &.swiper-container-vertical > .swiper-wrapper {
    flex-direction: column;
  }
  & .swiper-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    align-items: stretch;
    transition-property: transform;
    box-sizing: content-box;
    max-width: 100%;
  }
  &.swiper-container-android .swiper-slide,
  & .swiper-wrapper {
    transform: translate3d(0px, 0, 0);
  }
  &.swiper-container-multirow > .swiper-wrapper {
    flex-wrap: wrap;
  }
  &.swiper-container-multirow-column > .swiper-wrapper {
    flex-wrap: wrap;
    flex-direction: column;
  }
  &.swiper-container-free-mode > .swiper-wrapper {
    transition-timing-function: ease-out;
    margin: 0 auto;
  }
  &.swiper-container-pointer-events {
    touch-action: pan-y;
    &.swiper-container-vertical {
      touch-action: pan-x;
    }
  }
  & .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: stretch;
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    height: auto; // dealt with by "stretch in wrapper"
    position: relative;
    transition-property: transform;
    ${(props) => props.theme.breakpoints.up('md')} {
      margin: 0 1.6rem 0 0;
      max-width: 29rem;
    }
  }
  & .swiper-slide-invisible-blank {
    visibility: hidden;
  }
  /* Auto Height */
  &.swiper-container-autoheight {
    &,
    .swiper-slide {
      height: auto;
    }
    .swiper-wrapper {
      align-items: flex-start;
      transition-property: transform, height;
    }
  }
  /* 3D Effects */
  &.swiper-container-3d {
    perspective: 1200px;
    .swiper-wrapper,
    .swiper-slide,
    .swiper-slide-shadow-left,
    .swiper-slide-shadow-right,
    .swiper-slide-shadow-top,
    .swiper-slide-shadow-bottom,
    .swiper-cube-shadow {
      transform-style: preserve-3d;
    }
    .swiper-slide-shadow-left,
    .swiper-slide-shadow-right,
    .swiper-slide-shadow-top,
    .swiper-slide-shadow-bottom {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;
      z-index: 10;
    }
    .swiper-slide-shadow-left {
      background-image: linear-gradient(to left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    }
    .swiper-slide-shadow-right {
      background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    }
    .swiper-slide-shadow-top {
      background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    }
    .swiper-slide-shadow-bottom {
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    }
  }
  /* CSS Mode */
  &.swiper-container-css-mode {
    > .swiper-wrapper {
      overflow: auto;
      scrollbar-width: none; /* For Firefox */
      -ms-overflow-style: none; /* For Internet Explorer and Edge */
      &::-webkit-scrollbar {
        display: none;
      }
    }
    > .swiper-wrapper > .swiper-slide {
      scroll-snap-align: start start;
    }
  }
  &.swiper-container-horizontal.swiper-container-css-mode {
    > .swiper-wrapper {
      scroll-snap-type: x mandatory;
    }
  }
  &.swiper-container-vertical.swiper-container-css-mode {
    > .swiper-wrapper {
      scroll-snap-type: y mandatory;
    }
  }
  // Pagination
  & .swiper-pagination {
    display: flex;
    align-items: center;
    z-index: 5;
    max-width: max-content;
    position: absolute;
    bottom: 17px;
    left: 50%;
    transform: translateX(-50%);
  }
  & .swiper-pagination-bullet {
    width: 0.8rem;
    height: 0.8rem;
    background-color: ${(props) => props.theme.palette.colors.chambray};
    border-radius: 50%;
    margin: 1rem;
  }
  & .swiper-pagination-bullet-active {
    background-color: ${(props) => props.theme.palette.colors.midnightBlue};
  }
  ${(props) => props.theme.breakpoints.up('md')} {
    &.swiper-container {
      padding-bottom: 4rem;
      min-height: 10rem;
    }
  }
` as typeof Swiper

export const ArrowLeft = styled(Icon)`
  display: none;
  ${(props) => props.theme.breakpoints.up('md')} {
    display: block;
    width: 1.6rem;
    height: 1.6rem;
    cursor: pointer;
    position: absolute;
    bottom: 14px;
    left: 45%;
    transform: translateX(-65%);
    &.swiper-button-disabled {
      display: none;
    }
  }
`

export const ArrowRight = styled(Icon)`
  display: none;
  ${(props) => props.theme.breakpoints.up('md')} {
    display: block;
    width: 1.6rem;
    height: 1.6rem;
    cursor: pointer;
    position: absolute;
    bottom: 14px;
    left: 55%;
    transform: translateX(-45%);
    &.swiper-button-disabled {
      display: none;
    }
  }
`

export const CtaBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  ${(props) => props.theme.breakpoints.up('md')} {
    justify-content: flex-start;
  }
`

export const PlanningCta = styled(BasicLink)`
  display: flex;
  ${(props) => ({ ...props.theme.typography.h6 })}
  text-decoration: none;
  color: ${(props) => props.theme.palette.colors.freeSpeechBlue};
  cursor: pointer;
`
