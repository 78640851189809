import React, { FC } from 'react'

import Header, { HeaderProps } from '../../components/Header'
import Menu, { MenuProps } from '../../components/Menu'
import AuthLayout from '../../layouts/AuthLayout'
import { PageBackButtonProps } from '../../components/PageBackButton'
import { AvailabilityRecurrenceFormProps } from '../../components/AvailabilityRecurrenceForm'

import * as SC from './styled'

export type PreferencesAvailabilitiesRecurrenceFormTemplateProps = {
  className?: string
  headerProps: HeaderProps
  menuProps: MenuProps
  title: string
  backButton: PageBackButtonProps
  formProps: AvailabilityRecurrenceFormProps
}

const PreferencesAvailabilitiesRecurrenceFormTemplate: FC<PreferencesAvailabilitiesRecurrenceFormTemplateProps> = (
  props
) => {
  const { headerProps, menuProps, title, backButton, formProps } = props

  return (
    <AuthLayout header={<Header {...headerProps} withDivider />} menu={<Menu {...menuProps} />}>
      <SC.Content>
        <SC.BackButton {...backButton} />
        <SC.Title>{title}</SC.Title>
        <SC.Form {...formProps} />
      </SC.Content>
    </AuthLayout>
  )
}

export default PreferencesAvailabilitiesRecurrenceFormTemplate
