import styled from 'styled-components'

import ImageTextItemC from '../ImageTextItem'

export const ImageTextList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  ${(props) => props.theme.breakpoints.up('md')} {
    display: grid;
    gap: 0.8rem;
    grid-template-columns: repeat(auto-fill, 47rem);
    margin-bottom: 1.6rem;
  }
`

export const ImageTextItem = styled(ImageTextItemC)`
  margin-bottom: 1.6rem;
  height: 100%;
  ${(props) => props.theme.breakpoints.up('md')} {
    height: auto;
    margin: 0;
  }
`

export const Label = styled.h4`
  color: ${(props) => props.theme.palette.colors.midnightBlue};
  ${(props) => ({ ...props.theme.typography.h4 })}
  margin: 2.4rem 0;
`
