import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { HeaderProps } from '../../components/Header'
import useHeader from '../../hooks/useHeader'
import SignUpParentAddChildrenTemplate from '../../templates/SignUpParentAddChildren'
import { actions, selectors } from '../../redux'
import { resolveRegisterStepURL } from '../../helpers/RegisterHelpers'
import { useFormSubmit } from '../../hooks/useFormSubmit'
import { api } from '../../configuration'
import { AddChildrenProps } from '../../components/SignUpAddChildren'

const SignUpParentAddChildrenPage: React.FC = (_props) => {
  const headerProps: HeaderProps = useHeader()

  const { t } = useTranslation()
  const token = useSelector(selectors.auth.token)
  const user = useSelector(selectors.auth.user)
  const grades = useSelector(selectors.app.grades)
  const history = useHistory()
  const setting = useSelector(selectors.app.setting)
  const urlPrivacy = setting?.links?.privacy

  const onComplete = useCallback(() => {
    history.push(resolveRegisterStepURL(user))
  }, [user, history])

  const [submit, handleSubmit] = useFormSubmit(
    selectors.auth.signUpGuardianAddChildren,
    actions.auth.signUpGuardianAddChildrenRequest,
    actions.auth.signUpGuardianAddChildrenReset,
    onComplete
  )

  const skipAddChildren = useCallback(() => handleSubmit({}), [handleSubmit])

  const addChildForm: AddChildrenProps['addChildForm'] = useMemo(
    () => ({
      name: 'children',
      index: 0,
      title: t('signUpGuardianAddChild_title'),
      mandatoryText: t('signUp_required_text'),
    }),
    [t]
  )

  const addChildrenProps: AddChildrenProps = useMemo(() => {
    return {
      title: t('signUpGuardianAddChild_title'),
      legends: [
        t('signUpGuardianAddChild_legends_0'),
        t('signUpGuardianAddChild_legends_1'),
        t('signUpGuardianAddChild_legends_2'),
        <span
          key={2}
          dangerouslySetInnerHTML={{ __html: t('signUp_legends_2', { url: urlPrivacy }) }}
        />,
      ],
      step: t('signUpGuardianAddChild_step'),
      linkCodeText: t('signUpGuardianAddChild_code_text'),
      linkCode: user?.guardian?.code || '',
      fieldsProps: {
        firstName: {
          label: t('signUpGuardianAddChild_firstName_label'),
        },
        lastName: {
          label: t('signUpGuardianAddChild_lastName_label'),
        },
        school: {
          label: t('signUpGuardianAddChild_school_label'),
        },
        level: {
          label: t('signUpGuardianAddChild_select_level'),
          select: true,
          selectOptions: grades.map((grade) => ({ label: grade.name, value: grade.id })),
        },
        file: {
          image: {
            alt: 'LiberteClass - Upload',
            src: '/static/assets/images/upload.png',
          },
          config: {
            target: api.UPLOAD_ENDPOINT,
            headers: { Authorization: `Bearer ${token}` },
            fileType: ['jpg', 'png', 'jpeg', 'gif'],
          },
        },
        signupChild: {
          label: t('signUpGuardianAddChild_signup_child'),
        },
        email: {
          label: t('signUpGuardianAddChild_email_label'),
        },
        password: {
          label: t('signUpGuardianAddChild_password_label'),
        },
      },
      initialValues: {
        children: [
          {
            firstName: '',
            lastName: '',
            school: '',
            file: '',
            level: '',
            signupChild: false,
            email: '',
            password: '',
          },
        ],
      },
      skipButton: {
        text: t('signUpGuardianAddChild_skip'),
        onClick: () => {
          skipAddChildren()
        },
        type: 'button',
      },
      addAnotherChildButton: {
        text: t('signUpGuardianAddChild_add_extra_child'),
        onClick: () => {
          null
        },
      },
      submitButton: {
        text: t('signUpPersonalInfos_submit'),
        pendingText: t('signUpPersonalInfos_submit_pending'),
        isPending: submit.pending,
      },
      addChildForm,
      onSubmit: (values) => handleSubmit(values),
      submitErrors: submit.errors,
      errorTexts: {
        required: t('error_required'),
        email: t('error_email'),
      },
    }
  }, [
    t,
    user?.guardian?.code,
    grades,
    token,
    submit.pending,
    submit.errors,
    addChildForm,
    skipAddChildren,
    handleSubmit,
    urlPrivacy,
  ])

  return (
    <SignUpParentAddChildrenTemplate
      headerProps={headerProps}
      addChildrenProps={addChildrenProps}
    />
  )
}

export default SignUpParentAddChildrenPage
