import React, { FC } from 'react'

import Header, { HeaderProps } from '../../components/Header'
import Menu, { MenuProps } from '../../components/Menu'
import AuthLayout from '../../layouts/AuthLayout'
import { PageBackButtonProps } from '../../components/PageBackButton'
import { TabContentProps } from '../../components/TabContent'
import CardList, { CardListProps } from '../../components/CardList'
import { PotProps } from '../../components/Pot'
import { StripePaymentConfirmModalProps } from '../../components/modals/StripePaymentConfirmModal'
import StripePaymentConfirmModalWrapper from '../../components/modals/StripePaymentConfirmModal/wrapper'
import WalletWithdrawalModal, {
  WalletWithdrawalModalProps,
} from '../../components/modals/WalletWithdrawalModal'

import * as SC from './styled'

export enum PreferencesPotTabs {
  RESERVATIONS = 'RESERVATIONS',
  HISTORY = 'HISTORY',
}

export type PreferencesWalletTemplateProps = {
  className?: string
  headerProps: HeaderProps
  menuProps: MenuProps
  potProps: PotProps
  title: string
  backButton: PageBackButtonProps
  reservationsTitle?: string
  historyTitle?: string
  reservationsProps?: CardListProps
  historyProps?: CardListProps
  currentTab?: string
  onTabChange?: (tab: PreferencesPotTabs) => void
  stripeConfirmModalProps?: StripePaymentConfirmModalProps
  withdrawalModalProps?: WalletWithdrawalModalProps
  withTabs?: boolean
}

const PreferencesWalletTemplate: FC<PreferencesWalletTemplateProps> = (props) => {
  const {
    headerProps,
    menuProps,
    potProps,
    title,
    backButton,
    reservationsTitle,
    historyTitle,
    currentTab = PreferencesPotTabs.RESERVATIONS,
    reservationsProps,
    historyProps,
    onTabChange,
    stripeConfirmModalProps,
    withdrawalModalProps,
    withTabs,
  } = props

  const tabContentProps: TabContentProps = {
    name: '',
    tabs: [
      {
        label: reservationsTitle,
        value: PreferencesPotTabs.RESERVATIONS,
        children: <CardList {...reservationsProps} />,
      },
      {
        label: historyTitle,
        value: PreferencesPotTabs.HISTORY,
        children: <CardList {...historyProps} />,
      },
    ],
    value: currentTab,
    onChange: (_name, v) => {
      onTabChange?.(v as PreferencesPotTabs)
    },
  }

  return (
    <AuthLayout header={<Header {...headerProps} withDivider />} menu={<Menu {...menuProps} />}>
      <SC.Content>
        <SC.BackButton {...backButton} />
        <SC.Title>{title}</SC.Title>
        <SC.Pot {...potProps} />
        {withTabs && <SC.TabContent {...tabContentProps} />}
        {stripeConfirmModalProps && (
          <StripePaymentConfirmModalWrapper {...stripeConfirmModalProps} />
        )}
        {withdrawalModalProps && <WalletWithdrawalModal {...withdrawalModalProps} />}
      </SC.Content>
    </AuthLayout>
  )
}

export default PreferencesWalletTemplate
