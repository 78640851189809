import gql from 'graphql-tag'

import { Course } from '../../../../generated/graphql'
import CourseFragment from '../../../fragments/CourseFragment'

const createCourse = {
  mutation: gql`
    mutation createCourse(
      $lesson: ID!
      $student: ID
      $type: CourseType!
      $maximum_participants: Int!
      $teacher: ID!
      $date: Date!
      $slot: AvailabilitySlot!
    ) {
      createCourse(
        lesson: $lesson
        student: $student
        type: $type
        maximum_participants: $maximum_participants
        teacher: $teacher
        date: $date
        slot: $slot
      ) {
        ...CourseFragment
      }
    }
    ${CourseFragment}
  `,
  transformer: (response: any): Course[] => {
    return response.createCourse
  },
}

export default createCourse
