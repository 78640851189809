import styled from 'styled-components'
import { Container as MuiContainer } from '@material-ui/core'

export const Container = styled(MuiContainer)`
  min-height: 100vh;
  display: flex;
  flex-flow: column;
  align-items: stretch;
  background: bottom center / cover no-repeat;
  background-color: ${(props) => props.theme.palette.colors.whiteSmoke};
  padding-left: 0;
  padding-right: 0;
  ${(props) => props.theme.breakpoints.up('md')} {
    max-width: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    min-height: initial;
  }
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  flex-grow: 1;
  ${(props) => props.theme.breakpoints.up('md')} {
    flex-direction: row;
    overflow: hidden;
    height: 100%;
    flex-grow: initial;
  }
`

export const Left = styled.div`
  ${(props) => props.theme.breakpoints.up('md')} {
    position: relative;
    display: flex;
    flex-grow: 1;
    height: 100%;
  }
`

export const Right = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 2;
  ${(props) => props.theme.breakpoints.up('md')} {
    height: 100%;
    overflow-y: auto;
    -webkit-overflow-scroll: touch;
  }
`
