import styled from 'styled-components'

import PageTitle from '../../components/PageTitle'
import PageBackButton from '../../components/PageBackButton'
import PricesChoiceFormC from '../../components/PricesChoiceForm'

export const Content = styled.div``

export const Title = styled(PageTitle)``
export const BackButton = styled(PageBackButton)``
export const PricesChoiceForm = styled(PricesChoiceFormC)`
  padding: 0 1.6rem;
  max-width: 52rem;
`
