import { Container, Modal } from '@material-ui/core'
import styled from 'styled-components'

import WalletWithdrawalForm from '../../WalletWithdrawalForm'

export const AttachParentModal = styled(Modal)``

export const Wrapper = styled(Container)`
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 90%;
  height: max-content;
  inset: 50% 0 0 50%;
  transform: translate(-50%, -50%);
  background-color: ${(props) => props.theme.palette.colors.whiteSmoke};
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.12);
  border: 1px solid ${(props) => props.theme.palette.colors.ghostWhite};
  border-radius: 0.8rem;
  ${(props) => props.theme.breakpoints.up('md')} {
    width: 50%;
  }
`

export const HeaderText = styled.span`
  ${(props) => ({ ...props.theme.typography.h5 })}
  color: ${(props) => props.theme.palette.colors.neonCarrot};
  margin: 1.6rem 0;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

export const Form = styled(WalletWithdrawalForm)`
  ${(props) => ({ ...props.theme.typography.h4 })}
  color: ${(props) => props.theme.palette.colors.freeSpeechBlue};
  margin: 0 0 1.6rem;
  width: 100%;
`

export const Text = styled.p`
  ${(props) => ({ ...props.theme.typography.mediumRegular })}
  color: ${(props) => props.theme.palette.colors.chambray};
  margin: 0 0 1.6rem;
`
export const Options = styled.div`
  margin: 0 0 0.8rem;
  text-align: left;
  width: 100%;
`
