import styled from 'styled-components'

import CourseDetailsC from '../../components/CourseDetails'
import PageTitle from '../../components/PageTitle'
import PageBackButton from '../../components/PageBackButton'

export const Content = styled.div`
  flex-grow: 1;
  ${(props) => props.theme.breakpoints.up('md')} {
    flex-grow: initial;
  }
`

export const BackButton = styled(PageBackButton)``
export const Title = styled(PageTitle)`
  padding-bottom: 0.4rem;
`
export const Price = styled.p`
  ${(props) => ({ ...props.theme.typography.h6 })}
  color: ${(props) => props.theme.palette.colors.chambray};
  padding: 0 1.6rem;
`

export const DateGroup = styled.div`
  padding: 1.6rem 1.6rem 0;
`

export const Date = styled.p`
  margin: 0rem;
  padding: 0;
  ${(props) => ({ ...props.theme.typography.h5 })}
  color: ${(props) => props.theme.palette.colors.tomato};
`

export const Slots = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, min(86px));
  gap: 0.8rem;
  margin-top: 0.4rem;
`
export const Slot = styled.span`
  padding: 0.9rem 1rem;
  ${(props) => ({ ...props.theme.typography.smallBold })}
  color: ${(props) => props.theme.palette.colors.freeSpeechBlue};
  border: 1px solid ${(props) => props.theme.palette.colors.freeSpeechBlue};
  border-radius: 0.4rem;
  text-align: center;
`

export const Hr = styled.hr`
  margin: 1.6rem 1.6rem 0;
  border: 0;
  border-top: 1px solid ${(props) => props.theme.palette.colors.gainsboro};
  ${(props) => props.theme.breakpoints.up('md')} {
    max-width: 52rem;
  }
`

export const CourseDetails = styled(CourseDetailsC)`
  margin-top: 2rem;
  padding: 0 1.6rem;
  ${(props) => props.theme.breakpoints.up('md')} {
    max-width: 52rem;
  }
`
