import React from 'react'
import { Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { selectors } from '../redux'
import { router, routesPath } from '../router'
import { app } from '../configuration'
import { resolveRegisterStepURL } from '../helpers/RegisterHelpers'

interface AuthGuardType {
  children: React.ReactNode
}

// For routes that can only be accessed by authenticated users
function AuthGuard({ children }: AuthGuardType) {
  const isConnected = useSelector(selectors.auth.isConnected)
  const user = useSelector(selectors.auth.user)
  const url = (children as any)?.props?.match?.url
  const restrictedUserTypes = (children as any)?.type?.restrictedUserTypes
  const registerStepUrl = resolveRegisterStepURL(user)

  if (!isConnected) {
    return <Redirect to={router(routesPath.signIn)} />
  }

  // user is in register process, redirect to register step
  if (registerStepUrl && url !== router(routesPath.signOut)) {
    return <Redirect to={registerStepUrl} />
  }

  // do not allow other auth pages when preregister mode is on
  if (
    app.PRE_REGISTER &&
    url !== router(routesPath.dashboard) &&
    url !== router(routesPath.signOut)
  ) {
    return <Redirect to={routesPath.dashboard} />
  }

  if (restrictedUserTypes && !restrictedUserTypes.includes(user?.user_type)) {
    return <Redirect to={router(routesPath.dashboard)} />
  }

  return children
}

export default AuthGuard
