import { Container } from '@material-ui/core'
import styled from 'styled-components'

import TextField from '../../form/TextField'

export const PricePicker = styled.div``

export const Wrapper = styled(Container)`
  border: 1px solid ${(props) => props.theme.palette.colors.cornflowerBlue};
  border-radius: 0.8rem;
  background-color: ${(props) => props.theme.palette.colors.pureWhite};
`

export const Title = styled.h2`
  width: 100%;
  color: ${(props) => props.theme.palette.colors.cornflowerBlue};
  ${(props) => ({ ...props.theme.typography.h4 })}
`

export const PriceLevels = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

export const PriceLevel = styled.div`
  width: max-content;
  margin-right: 1rem;
`

export const PriceLevelLabel = styled.p`
  ${(props) => ({ ...props.theme.typography.h5 })}
  color: ${(props) => props.theme.palette.colors.midnightBlue};
  margin: 0 0 0.5rem 0;
  width: max-content;
`

export const PriceLevelField = styled(TextField)`
  margin-bottom: 1rem;
  width: 12rem;
  margin-right: 1rem;
  & .MuiFormHelperText-contained {
    background-color: transparent;
  }
`
