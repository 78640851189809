import React from 'react'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'

import { selectors } from '../redux'
import { router, routesPath } from '../router'
import { resolveRegisterStepURL } from '../helpers/RegisterHelpers'

interface GuestGuardType {
  children: React.ReactNode
}

// For routes that can only be accessed by unauthenticated users
function GuestGuard({ children }: GuestGuardType) {
  const isConnected = useSelector(selectors.auth.isConnected)
  const user = useSelector(selectors.auth.user)

  if (isConnected) {
    const registerStepUrl = resolveRegisterStepURL(user)
    if (registerStepUrl) {
      return <Redirect to={registerStepUrl} />
    }
    return <Redirect to={router(routesPath.dashboard)} />
  }

  return children
}

export default GuestGuard
