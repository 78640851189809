import { all, call, fork, put, SagaReturnType, select, takeLeading } from 'redux-saga/effects'

import { actions, selectors } from '..'
import { ApiResponse } from '../api/types/state'
import ApiSagas from '../api/sagas'
import configuration from '../../graphql/services/contenful/queries/configuration'
import AuthSagas from '../auth/sagas'
import disciplines from '../../graphql/services/discipline/queries/disciplines'
import grades from '../../graphql/services/grade/queries/grades'
import settings from '../../graphql/services/setting/queries/settings'

export default class AppSagas {
  static *init() {
    const isInit: SagaReturnType<typeof selectors.app.isInit> = yield select(selectors.app.isInit)
    yield call(AuthSagas.onInit) // must be first auth call to refresh token if needed
    yield fork(AppSagas.loadInitialData) // can be forked

    if (!isInit) {
      yield put(actions.app.setIsInit({ isInit: true }))
    }
  }

  static *loadInitialData() {
    yield all([
      call(AppSagas.onLoadMenu),
      call(AppSagas.onLoadDisciplines),
      call(AppSagas.onLoadGrades),
      call(AppSagas.onLoadSettings),
    ])
  }

  static *onLoadMenu() {
    // load menu
    const rs: ApiResponse<typeof configuration> = yield call(
      ApiSagas.contentfulQuery,
      configuration,
      { locale: 'fr' }
    )
    if (!rs?.errors) {
      yield put(actions.app.setContentfulConfiguration(rs?.data))
    }
  }

  static *onLoadDisciplines() {
    const rs: ApiResponse<typeof disciplines> = yield call(ApiSagas.persistQuery, disciplines)
    if (!rs?.errors) {
      yield put(actions.app.setDisciplines(rs?.data))
    }
  }

  static *onLoadGrades() {
    const rs: ApiResponse<typeof grades> = yield call(ApiSagas.persistQuery, grades)
    if (!rs?.errors) {
      yield put(actions.app.setGrades(rs?.data))
    }
  }

  static *onLoadSettings() {
    const rs: ApiResponse<typeof settings> = yield call(ApiSagas.persistQuery, settings)
    if (!rs?.errors) {
      yield put(actions.app.setSetting(rs?.data))
    }
  }

  static *loop() {
    yield all([takeLeading('persist/REHYDRATE', this.init)])
  }
}
