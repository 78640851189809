import gql from 'graphql-tag'

import { Course } from '../../../../generated/graphql'
import CourseFragment from '../../../fragments/CourseFragment'

const declineCourse = {
  mutation: gql`
    mutation declineCourse($course: ID!) {
      declineCourse(course: $course) {
        ...CourseFragment
      }
    }
    ${CourseFragment}
  `,
  transformer: (response: any): Course => {
    return response.declineCourse
  },
}

export default declineCourse
