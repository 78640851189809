import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { router, routesPath } from '../router'
import { BasicLinkProps } from '../components/BasicLink'

const useBackButton = (backLink?: string, force?: boolean): BasicLinkProps => {
  const { t } = useTranslation()
  const history = useHistory()

  return useMemo(() => {
    return {
      text: t('back'),
      link: {
        ...(history?.length > 0 && !force
          ? {
              onClick: () => history.goBack(),
            }
          : {
              href: router(backLink ?? routesPath.dashboard),
            }),
      },
    }
  }, [t, history, force, backLink])
}

export default useBackButton
