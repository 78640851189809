import React, { FC } from 'react'

import { ActionButtonProps } from '../../ActionButton'
import ModalContainer from '../ModalContainer'
import { FormSubmitProps } from '../../FormSubmit'
import FormSubmitErrors, { FormSubmitErrorsProps } from '../../FormSubmitErrors'

import * as SC from './styled'

export type AutoRegisterModalProps = {
  className?: string
  title?: string
  description?: string
  text?: string
  subjectLabel?: string
  subject?: string
  gradeLabel?: string
  grade?: string
  priceLabel?: string
  price?: string
  participantsLabel?: string
  participants?: string
  dayLabel?: string
  days?: string[]
  cancelButton?: ActionButtonProps
  confirmButton?: FormSubmitProps
  open: boolean
  handleClose: () => void
  onClose?: () => void
  lessonLabel?: string
  lesson?: string
  submitErrors?: FormSubmitErrorsProps['errors']
}

const AutoRegisterModal: FC<AutoRegisterModalProps> = (props) => {
  const {
    className,
    title,
    description,
    text,
    subjectLabel,
    subject,
    gradeLabel,
    grade,
    priceLabel,
    price,
    dayLabel,
    participantsLabel,
    participants,
    days,
    cancelButton,
    confirmButton,
    open,
    handleClose,
    onClose,
    lessonLabel,
    lesson,
    submitErrors,
  } = props

  return (
    <ModalContainer open={open} onClose={onClose} className={className}>
      <SC.Content>
        {title && <SC.Title>{title}</SC.Title>}
        {description && <SC.Description>{description}</SC.Description>}
        {text && <SC.Text>{text}</SC.Text>}
        <SC.Request>
          <SC.Label>{subjectLabel}</SC.Label>
          <SC.Value>{subject}</SC.Value>
        </SC.Request>
        <SC.Request>
          <SC.Label>{gradeLabel}</SC.Label>
          <SC.Value>{grade}</SC.Value>
        </SC.Request>
        <SC.Request>
          <SC.Label>{lessonLabel}</SC.Label>
          <SC.Value>{lesson}</SC.Value>
        </SC.Request>
        <SC.Request>
          <SC.Label>{priceLabel}</SC.Label>
          <SC.Value>{price}</SC.Value>
        </SC.Request>
        <SC.Request>
          <SC.Label>{participantsLabel}</SC.Label>
          <SC.Value>{participants}</SC.Value>
        </SC.Request>
        <SC.Request>
          <SC.Label>{dayLabel}</SC.Label>
          {days && days.map((day, index) => <SC.Value key={index}>{day}</SC.Value>)}
        </SC.Request>
      </SC.Content>
      <FormSubmitErrors errors={submitErrors} />
      <SC.Buttons>
        {cancelButton && confirmButton && (
          <>
            <SC.Button {...cancelButton} outlined onClick={handleClose} />
            <SC.Submit {...confirmButton} autoIcon />
          </>
        )}
      </SC.Buttons>
    </ModalContainer>
  )
}

export default AutoRegisterModal
