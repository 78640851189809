import styled, { css } from 'styled-components'
import { Checkbox, FormHelperText } from '@material-ui/core'

import InputLabel from '../InputLabel'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`
export const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
`

export const MuiCheckbox = styled(Checkbox)<{ $hasError?: boolean }>`
  padding: 0;
  & svg {
    width: 16px;
    height: 16px;
    margin-top: 0.5rem;
  }
  &.Mui-checked {
    color: ${(props) => props.theme.palette.colors.freeSpeechBlue};
  }
  ${(props) =>
    props?.$hasError &&
    css`
      color: ${props.theme.palette.colors.tomato};
    `}
`
export const Label = styled(InputLabel)`
  ${(props) => ({ ...props.theme.typography.big })};
  color: ${(props) => props.theme.palette.colors.chambray};
  padding-left: 7px;
`

export const Required = styled.span`
  ${(props) => ({ ...props.theme.typography.big })};
  color: ${(props) => props.theme.palette.colors.neonCarrot};
`

export const HelperText = styled(FormHelperText)`
  text-align: left;
  ${(props) => ({ ...props.theme.typography.smallRegular })}
  color: ${(props) => props.theme.palette.colors.grey};
  background-color: ${(props) => props.theme.palette.colors.whiteSmoke};
  margin: 3px 0 0 0;
  &.Mui-error {
    color: ${(props) => props.theme.palette.error.main};
  }
`
