import gql from 'graphql-tag'

import { WalletRefund } from '../../../../generated/graphql'

const requestRefund = {
  mutation: gql`
    mutation requestRefund($amount: Int!, $iban: String!) {
      requestRefund(amount: $amount, iban: $iban) {
        amount
        status
      }
    }
  `,
  transformer: (response: any): WalletRefund => {
    return response.requestRefund
  },
}

export default requestRefund
