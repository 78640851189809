import React, { FC } from 'react'

import { ImgProps } from '../../types/image'

import * as SC from './styled'

export type MoneyCardProps = {
  className?: string
  date: string
  title: string
  level?: string
  type?: string
  numberOfStudentText?: string
  iban?: string
  money: string
  image?: ImgProps
  name?: string
}

const MoneyCard: FC<MoneyCardProps> = (props) => {
  const {
    className,
    date,
    title,
    level,
    type,
    numberOfStudentText,
    iban,
    money,
    image,
    name,
  } = props

  return (
    <SC.MoneyCard className={className}>
      <SC.Wrapper>
        <SC.Date>{date}</SC.Date>
        <SC.Muidivider />
        <SC.Content>
          <SC.Title>{title}</SC.Title>
          <SC.Money>{money}</SC.Money>
        </SC.Content>
        {level && <SC.Level>{level}</SC.Level>}
        {type && (
          <SC.Type>
            {type}
            {numberOfStudentText && (
              <>
                <SC.Point>.</SC.Point>
                {numberOfStudentText}
              </>
            )}
          </SC.Type>
        )}
        {image && name && (
          <SC.Profile>
            <SC.Image src={image.src} alt={image.alt} />
            <SC.Name>{name}</SC.Name>
          </SC.Profile>
        )}
        {iban && <SC.Iban>{iban}</SC.Iban>}
      </SC.Wrapper>
    </SC.MoneyCard>
  )
}

export default MoneyCard
