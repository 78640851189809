import React, { FC, memo, ReactNode } from 'react'

import Header, { HeaderProps } from '../../components/Header'
import Menu, { MenuProps } from '../../components/Menu'
import AuthLayout from '../../layouts/AuthLayout'
import PageBackButton, { PageBackButtonProps } from '../../components/PageBackButton'
import { MarketCourseFormProps } from '../../components/MarketCourseForm'

import * as SC from './styled'

export type SearchMarketTemplateProps = {
  className?: string
  children?: ReactNode
  headerProps: HeaderProps
  menuProps: MenuProps
  backButton?: PageBackButtonProps
  title: string
  text?: string
  marketFormProps: MarketCourseFormProps
}

export const SearchMarketTemplate: FC<SearchMarketTemplateProps> = (props) => {
  const { headerProps, menuProps, title, text, backButton, marketFormProps } = props

  return (
    <AuthLayout header={<Header {...headerProps} withDivider />} menu={<Menu {...menuProps} />}>
      <SC.Content>
        {backButton && <PageBackButton {...backButton} />}
        <SC.Title>{title}</SC.Title>
        {text && <SC.Text>{text}</SC.Text>}
        <SC.MarketCourseForm {...marketFormProps} />
      </SC.Content>
    </AuthLayout>
  )
}

export default memo(SearchMarketTemplate)
