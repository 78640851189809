import styled from 'styled-components'

export const Wrapper = styled.div`
  padding: 0 1.6rem;
`

export const BoxImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  align-self: center;
`

export const Image = styled.img`
  display: none;
  ${(props) => props.theme.breakpoints.up('md')} {
    display: block;
    width: 100%;
    max-width: 51rem;
  }
`
