import gql from 'graphql-tag'

import { Dispute } from '../../../../generated/graphql'

const createDispute = {
  mutation: gql`
    mutation createDispute($course: ID!, $student: ID, $reason: DisputeReason!, $message: String) {
      createDispute(course: $course, student: $student, reason: $reason, message: $message) {
        id
      }
    }
  `,
  transformer: (response: any): Dispute => {
    return response.createDispute
  },
}

export default createDispute
