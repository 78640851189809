import styled from 'styled-components'

import ModalContainer from '../ModalContainer'
import CancelCourseForm from '../../CancelCourseForm'
import FormSubmit from '../../FormSubmit'
import Icon from '../../Icon'

export const Modal = styled(ModalContainer)`
  ${(props) => props.theme.breakpoints.up('md')} {
    & .MuiContainer-root {
      max-width: 52rem;
      margin: 0;
    }
  }
`

export const HeaderText = styled.span`
  ${(props) => ({ ...props.theme.typography.h5 })}
  color: ${(props) => props.theme.palette.colors.tomato};
  margin: 1.6rem 0 0;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

export const Form = styled(CancelCourseForm)`
  margin: 0 0 1.6rem;
  width: 100%;
`

export const Disclaimer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 1.6rem 0;
`

export const PebbleIcon = styled(Icon)`
  width: 7.6rem;
  height: 7.6rem;
  align-self: center;
  margin-bottom: 1.6rem;
`

export const Title = styled.h2`
  ${(props) => ({ ...props.theme.typography.h4 })}
  color: ${(props) => props.theme.palette.colors.freeSpeechBlue};
  margin: 0 0 1.6rem;
  text-align: center;
`

export const Text = styled.div`
  ${(props) => ({ ...props.theme.typography.mediumRegular })}
  color: ${(props) => props.theme.palette.colors.chambray};
  margin: 0 0 1.6rem;
  text-align: center;
  & a {
    color: ${(props) => props.theme.palette.colors.chambray};
  }
`

export const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 2rem;
`

export const SkipButton = styled(FormSubmit)`
  width: 40%;
  margin-right: 0.5rem;
`

export const SubmitButton = styled(FormSubmit)`
  width: 60%;
  margin-left: 0.5rem;
  background-color: ${(props) => props.theme.palette.colors.tomato};
  border-color: ${(props) => props.theme.palette.colors.tomato};
  &:only-child {
    width: 100%;
    margin: 0;
  }
`
