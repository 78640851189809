import React, { useCallback, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { HeaderProps } from '../../components/Header'
import useHeader from '../../hooks/useHeader'
import { MenuProps } from '../../components/Menu'
import useMenu from '../../hooks/useMenu'
import { Student, UserType } from '../../generated/graphql'
import { RestrictedReactFC } from '../../types/common'
import { router, routesPath } from '../../router'
import { actions, selectors } from '../../redux'
import PreferencesFamilyTemplate, {
  PreferencesFamilyTemplateProps,
} from '../../templates/PreferencesFamily'
import { Icons } from '../../components/Icon/types'
import { light } from '../../theme/palette'
import {
  globalRateTransformer,
  ratingModalityTransformer,
} from '../../transformers/ratingTransformers'
import { ParentSideScoreCardProps } from '../../components/ParentSideScoreCard'
import { useFormSubmit } from '../../hooks/useFormSubmit'
import { studentProfileTransformer } from '../../transformers/profileTransformers'
import useBackButton from '../../hooks/useBackButton'

const PreferencesFamilyPage: RestrictedReactFC<RouteComponentProps> = (props) => {
  const headerProps: HeaderProps = useHeader()
  const menuProps: MenuProps = useMenu(props)
  const { t } = useTranslation()
  const user = useSelector(selectors.auth.user)
  const backButton = useBackButton(router(routesPath.preferences))

  const [childToDelete, setChildToDelete] = useState<Student | null>(null)

  const onComplete = useCallback(() => {
    setChildToDelete(null)
  }, [])

  const [submit, handleSubmit] = useFormSubmit(
    selectors.preferences.removeChild,
    actions.preferences.removeChildRequest,
    actions.preferences.removeChildReset,
    onComplete
  )

  const preferencesProps: PreferencesFamilyTemplateProps = {
    menuProps,
    headerProps,
    title: t('preferences_family_title'),
    backButton,
    childrenProps: user?.guardian?.children?.map(
      (child): ParentSideScoreCardProps => {
        const rates = {
          punctuality: child?.punctuality ?? 0,
          behaviour: child?.behaviour ?? 0,
          interactivity: child?.interactivity ?? 0,
          understanding: child?.understanding ?? 0,
          working_conditions: child?.working_conditions ?? 0,
        }

        return {
          studentData: {
            id: child?.id ?? '',
            profile: studentProfileTransformer(child),
            modality: ratingModalityTransformer(t, rates),
            rate: globalRateTransformer(t, child?.score ?? 0),
            hasMinimalStyles: true,
            isExpanded: true,
          },
          historyLink: {
            link: {
              link: router(routesPath.planningTab, { child: child?.id, tab: 'past' }),
            },
            text: t('preferences_family_history'),
            icon: Icons.book2,
          },
          editLink: {
            link: {
              link: router(routesPath.preferencesFamilyChild, { child: child?.id }),
            },
            text: t('preferences_family_edit'),
            icon: Icons.edit,
            iconColor: light.colors.freeSpeechBlue,
          },
          ...(child?.deletable && {
            deleteLink: {
              onClick: () => setChildToDelete(child),
              text: t('preferences_family_delete'),
              icon: Icons.close,
              iconColor: light.colors.neonCarrot,
            },
          }),
        }
      }
    ),
    addChildProps: {
      text: t('preferences_family_add'),
      link: {
        href: router(routesPath.preferencesFamilyAddChild),
      },
    },
    deleteModalProps: {
      open: !!childToDelete,
      onClose: () => setChildToDelete(null),
      title: t('preferences_family_deleteModal_title'),
      description: t('preferences_family_deleteModal_text', { name: childToDelete?.first_name }),
      cancelButton: {
        text: t('cancel'),
        onClick: () => setChildToDelete(null),
      },
      confirmButton: {
        text: t('preferences_family_deleteModal_delete'),
        onClick: () => handleSubmit({ student: childToDelete?.id }),
        isPending: submit.pending,
        isSuccess: submit.success,
      },
    },
  }

  return <PreferencesFamilyTemplate {...preferencesProps} />
}

PreferencesFamilyPage.restrictedUserTypes = [UserType.Guardian]

export default PreferencesFamilyPage
