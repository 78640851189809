import React, { FC } from 'react'

import Header, { HeaderProps } from '../../components/Header'
import { Icons } from '../../components/Icon/types'
import { light } from '../../theme/palette'
import { ActionButtonProps } from '../../components/ActionButton'
import AuthLayout from '../../layouts/AuthLayout'
import Menu, { MenuProps } from '../../components/Menu'
import { ProfileFormProps } from '../../components/ProfilForm'
import { SponsorshipFormProps } from '../../components/SponsorshipForm'
import ChangePasswordModal, {
  ChangePasswordModalProps,
} from '../../components/modals/ChangePasswordModal'
import { ImageTextItemProps } from '../../components/ImageTextItem'
import PageBackButton, { PageBackButtonProps } from '../../components/PageBackButton'
import ActionSubmitModal, {
  ActionSubmitModalProps,
} from '../../components/modals/ActionSubmitModal'

import * as SC from './styled'

export type FamilyProps = {
  title: string
  children?: ImageTextItemProps[]
  addChildProps?: ActionButtonProps
  linkCodeTitle: string
  linkCodeText: string
  linkCode: string
}

export type AccountTemplateProps = {
  className?: string
  headerProps: HeaderProps
  menuProps: MenuProps
  profileForm: ProfileFormProps
  sponsorshipForm?: SponsorshipFormProps
  disconnectCta: ActionButtonProps
  deleteCta?: ActionButtonProps
  passwordModal?: ChangePasswordModalProps
  familyProps?: FamilyProps
  backButton?: PageBackButtonProps
  actionSubmitModal?: ActionSubmitModalProps
}

const AccountTemplate: FC<AccountTemplateProps> = (props) => {
  const {
    headerProps,
    menuProps,
    profileForm,
    sponsorshipForm,
    disconnectCta,
    deleteCta,
    passwordModal,
    familyProps,
    backButton,
    actionSubmitModal,
  } = props

  return (
    <AuthLayout header={<Header {...headerProps} withDivider />} menu={<Menu {...menuProps} />}>
      <SC.Wrapper>
        {backButton && <PageBackButton {...backButton} />}
        <SC.ProfileForm {...profileForm} />
        {sponsorshipForm && <SC.SponsorshipForm {...sponsorshipForm} />}
        {familyProps && (
          <SC.FamilyContainer>
            <SC.Title>{familyProps.title}</SC.Title>
            {familyProps.children && familyProps.children?.length > 0 && (
              <SC.ImageTextList items={familyProps.children} />
            )}
            <SC.AddChildButton icon={Icons.longArrowRight} {...familyProps.addChildProps} />
            <SC.LinkCodeBox>
              <SC.LinkCodeTitle>{familyProps.linkCodeTitle}</SC.LinkCodeTitle>
              <SC.LinkCodeText>{familyProps.linkCodeText}</SC.LinkCodeText>
              <SC.LinkCode>{familyProps.linkCode}</SC.LinkCode>
            </SC.LinkCodeBox>
          </SC.FamilyContainer>
        )}
        {disconnectCta && (
          <SC.DisconnectCta
            icon={Icons.signOut}
            iconSide={'left'}
            color={light.colors.neonCarrot}
            {...disconnectCta}
          />
        )}
        {deleteCta && (
          <SC.DeleteCta
            icon={Icons.close}
            iconSide={'left'}
            color={light.colors.tomato}
            {...deleteCta}
          />
        )}
        {passwordModal && <ChangePasswordModal {...passwordModal} />}
        {actionSubmitModal && <ActionSubmitModal {...actionSubmitModal} />}
      </SC.Wrapper>
    </AuthLayout>
  )
}

export default AccountTemplate
