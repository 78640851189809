import gql from 'graphql-tag'

import { AvailabilitySlot } from '../../../../generated/graphql'

export type StudentDisabledDatesResult = {
  date: string
  slot: AvailabilitySlot
}[]

const studentDisabledDates = {
  query: gql`
    query studentDisabledDates($student: ID) {
      futureCourses(first: 500, student: $student) {
        data {
          date
          slot
        }
      }
      pendingCourses(first: 500, student: $student) {
        data {
          date
          slot
        }
      }
    }
  `,
  transformer: (response: any): StudentDisabledDatesResult => {
    return response?.futureCourses?.data?.concat(response?.pendingCourses?.data ?? [])
  },
}

export default studentDisabledDates
