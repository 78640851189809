import gql from 'graphql-tag'

import UserFragment from '../../../fragments/UserFragment'
import { User } from '../../../../generated/graphql'

const inviteGuardian = {
  mutation: gql`
    mutation inviteGuardian($email: String!) {
      inviteGuardian(email: $email) {
        ...UserFragment
      }
    }
    ${UserFragment}
  `,
  transformer: (response: any): User => {
    return response.inviteGuardian
  },
}

export default inviteGuardian
