import styled from 'styled-components'
import { IconButton } from '@material-ui/core'

import Icon from '../../Icon'

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1.6rem 0;
`

export const Head = styled.div`
  display: flex;
  align-items: flex-start;
  margin: 0 0 1.8rem;
  width: 100%;
`

export const Title = styled.h4`
  ${(props) => ({ ...props.theme.typography.h5 })}
  color: ${(props) => props.theme.palette.colors.neonCarrot};
  margin: 0;
  padding: 0;
  align-self: flex-start;
  flex-grow: 1;
  word-break: break-word;
`

export const CloseButton = styled(IconButton)`
  margin: -1rem -1rem 0 0;
`
export const Close = styled(Icon)`
  width: 3rem;
  height: 3rem;
`

export const Text = styled.p<{ aligned?: boolean }>`
  ${(props) => ({ ...props.theme.typography.mediumRegular })}
  color: ${(props) => props.theme.palette.colors.grey};
  margin: 0;
  padding: 0;
  margin-bottom: 0;
  text-align: ${(props) => (props.aligned ? 'center' : 'left')};
`
