import gql from 'graphql-tag'

import StudentFragment from './StudentFragment'

export default gql`
  fragment GuardianFragment on Guardian {
    code
    id
    children {
      ...StudentFragment
    }
  }
  ${StudentFragment}
`
