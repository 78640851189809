import styled from 'styled-components'

import FormSubmit from '../FormSubmit'
import AddressC from '../form/Address'
import TextField from '../form/TextField'
import Checkbox from '../form/Checkbox'
import UploadImage from '../form/UploadImage'

export const AddChildren = styled.div`
  padding: 1.6rem;
`

export const LinkCodeBox = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.palette.colors.pureWhite};
  border-radius: 0.8rem;
  padding: 1.6rem;
  margin-top: 2rem;
`

export const LinkCodeText = styled.span`
  ${(props) => ({ ...props.theme.typography.smallRegular })}
  color: ${(props) => props.theme.palette.colors.midnightBlue};
`

export const LinkCode = styled.span`
  ${(props) => ({ ...props.theme.typography.h3 })}
  color: ${(props) => props.theme.palette.colors.cornflowerBlue};
  margin-top: 1rem;
`

export const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const SubmitButton = styled(FormSubmit)`
  margin: 2rem 0 0 0;
`

export const Address = styled(AddressC)``

export const Field = styled(TextField)<{ hasErrors?: boolean }>`
  margin-bottom: 1rem;
`
export const Avatar = styled(UploadImage)`
  margin: 2rem 0;
`

export const Check = styled(Checkbox)<{ hasErrors?: boolean }>``

export const EmailField = styled(TextField)<{ hasErrors?: boolean }>`
  margin-top: 1rem;
  margin-bottom: 1rem;
`

export const Fields = styled.div`
  width: 100%;
  ${(props) => props.theme.breakpoints.up('md')} {
    max-width: 47rem;
  }
`
