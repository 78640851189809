import styled from 'styled-components'
import { Divider } from '@material-ui/core'

import ImageTextItem from '../ImageTextItem'
import ImageTextList from '../ImageTextList'
import RatingWithFieldsC from '../RatingWithFields'

export const FinishedCourseMeta = styled.section``

export const Title = styled.h1`
  ${({ theme }) => ({ ...theme.typography.h3 })};
  color: ${({ theme }) => theme.palette.colors.neonCarrot};
  margin-bottom: 0;
`

export const Subtitle = styled.div`
  ${({ theme }) => ({ ...theme.typography.smallBold })};
  color: ${({ theme }) => theme.palette.colors.tomato};
  position: relative;
  margin-bottom: 1.6rem;
  padding-bottom: 1.6rem;
  &::after {
    content: '';
    height: 1px;
    width: 100%;
    left: 0;
    bottom: 0;
    position: absolute;
    background: ${({ theme }) => theme.palette.colors.gainsboro};
  }
`

export const Person = styled(ImageTextItem)``

export const Subject = styled.h2`
  ${({ theme }) => ({ ...theme.typography.h3 })};
  color: ${({ theme }) => theme.palette.colors.midnightBlue};
`

export const Description = styled.div`
  ${({ theme }) => ({ ...theme.typography.big })};
  color: ${({ theme }) => theme.palette.colors.chambray};
  h3 {
    ${({ theme }) => ({ ...theme.typography.h4 })};
    color: ${({ theme }) => theme.palette.colors.freeSpeechBlue};
  }
`

export const ItemsTitle = styled.h3`
  ${({ theme }) => ({ ...theme.typography.h4 })};
  color: ${({ theme }) => theme.palette.colors.midnightBlue};
  margin-top: 2.4rem;
`

export const Items = styled(ImageTextList)`
  margin: 1.6rem 0;
  & > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    ${(props) => props.theme.breakpoints.up('md')} {
      flex-direction: column;
      width: 100%;
      & > * {
        max-width: 100%;
        width: 100%;
        &:nth-child(2n + 1) {
          margin-right: 0;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
`

export const Muidivider = styled(Divider)`
  width: 100%;
  margin: 1.2rem 0rem;
`

export const RatingWithFields = styled(RatingWithFieldsC)`
  margin-bottom: 1.6rem;
`
