import { Container, IconButton } from '@material-ui/core'
import styled from 'styled-components'

import UploadImageC from '../form/UploadImage'
import Icon from '../Icon'

export const AddChildForm = styled.div``

export const Wrapper = styled(Container)`
  padding: 0;
`

export const Head = styled.div`
  display: flex;
`

export const Title = styled.h2`
  width: 100%;
  color: ${(props) => props.theme.palette.colors.midnightBlue};
  ${(props) => ({ ...props.theme.typography.h5 })}
`

export const CloseButton = styled(IconButton)`
  &:hover {
    color: ${(props) => props.theme.palette.colors.midnightBlue};
  }
`

export const Close = styled(Icon)`
  width: 2.4rem;
`

export const Fields = styled.div`
  width: 100%;
  ${(props) => props.theme.breakpoints.up('md')} {
    max-width: 64rem;
  }
`

export const FieldLabel = styled.label`
  display: none;
`

export const MandatoryText = styled.span`
  color: ${(props) => props.theme.palette.colors.grey};
  ${(props) => ({ ...props.theme.typography.smallRegular })}
  width: 100%;
  display: block;
  margin: 2.4rem 0 2.4rem 0;
`

export const UploadImage = styled(UploadImageC)`
  margin-top: 1rem;
`
export const ConditionalFields = styled.div<{ visible?: boolean }>`
  display: ${({ visible }) => (visible ? 'block' : 'none')};
`
