import gql from 'graphql-tag'

import UserFragment from '../../../fragments/UserFragment'
import { User } from '../../../../generated/graphql'

const login = {
  mutation: gql`
    mutation AuthLogin($email: String!, $password: String!) {
      login(email: $email, password: $password) {
        ...UserFragment
      }
    }
    ${UserFragment}
  `,
  transformer: (response: any): User => {
    return response.login
  },
}

export default login
