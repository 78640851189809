import gql from 'graphql-tag'

import TeacherAvailabilityFragment from '../../../fragments/TeacherAvailabilityFragment'
import { teacherAvailabilityTransform } from '../transformers/TeacherAvailabilityTransform'
import { TeacherAvailabilityDay } from '../../../types/TeacherAvailabilityDay'

const updateTeacherAvailabilityOfDay = {
  mutation: gql`
    mutation updateTeacherAvailabilityOfDay($date: Date!, $slots: [AvailabilitySlot!]) {
      updateTeacherAvailabilityOfDay(date: $date, slots: $slots) {
        ...TeacherAvailabilityFragment
      }
    }
    ${TeacherAvailabilityFragment}
  `,
  transformer: (response: any): TeacherAvailabilityDay[] => {
    return teacherAvailabilityTransform(response.updateTeacherAvailabilityOfDay)
  },
}

export default updateTeacherAvailabilityOfDay
