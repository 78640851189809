import React, { FC, useEffect, useState } from 'react'
import { Form, Formik, FormikHelpers } from 'formik'

import { LevelPickerProps } from '../LevelPicker'
import { FormFieldProps } from '../../types/form'
import FormSubmitErrors, { FormSubmitErrorsProps } from '../FormSubmitErrors'
import { FormSubmitProps } from '../FormSubmit'
import FormScrollToError from '../FormScrollToError'
import LegalLegends, { LegalLegendsProps } from '../LegalLegends'

import * as SC from './styled'

export type LevelPickersFormValues = {
  [key: string]: string[]
}

export type LevelPickersFormProps = {
  className?: string
  title?: string
  legends?: LegalLegendsProps['texts']
  text: string
  levelForSubject: LevelPickerProps[]
  initialValues?: LevelPickersFormValues
  fieldsProps?: {
    levels: FormFieldProps
  }
  errorTexts?: {
    required: string
  }
  submitErrors?: FormSubmitErrorsProps['errors']
  submitButton: FormSubmitProps
  onSubmit?: (
    values: LevelPickersFormValues,
    formikHelpers: FormikHelpers<LevelPickersFormValues>
  ) => void
}

const LevelPickersForm: FC<LevelPickersFormProps> = (props) => {
  const {
    className,
    title,
    text,
    levelForSubject,
    submitButton,
    initialValues,
    submitErrors,
    legends,
    onSubmit = (_v: LevelPickersFormValues) => null,
  } = props

  const [openState, setOpenState] = useState(
    Object.keys(initialValues || {})?.reduce((arr, key) => {
      return { ...arr, [key]: (initialValues || {})?.[key]?.length > 0 }
    }, {}) as { [key: string]: boolean }
  )

  useEffect(() => {
    setOpenState(
      Object.keys(initialValues || {})?.reduce((arr, key) => {
        return { ...arr, [key]: (initialValues || {})?.[key]?.length > 0 }
      }, {}) as { [key: string]: boolean }
    )
  }, [initialValues])

  return (
    <SC.LevelPickersForm className={className}>
      {title && <SC.Title>{title}</SC.Title>}
      <SC.Text>{text}</SC.Text>
      <Formik
        initialValues={initialValues || {}}
        validateOnChange={true}
        validateOnBlur={true}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {(formikProps) => (
          <Form noValidate>
            <FormScrollToError formikProps={formikProps} />
            <SC.Subjects>
              {levelForSubject.map((level, index) => (
                <SC.Subject
                  key={index}
                  {...level}
                  value={formikProps.values[level.name]}
                  onChange={(_name, v) => formikProps.setFieldValue(level.name, v)}
                  isExpand={openState[level.name]}
                  onHeaderClick={() => {
                    setOpenState((before) => {
                      if (before[level.name]) {
                        formikProps.setFieldValue(level.name, [])
                      }
                      return { ...before, [level.name]: !before[level.name] }
                    })
                  }}
                />
              ))}
            </SC.Subjects>
            <FormSubmitErrors errors={submitErrors} />
            <SC.SubmitButton
              {...submitButton}
              isSuccess={submitButton?.isSuccess && !formikProps.dirty}
              autoIcon
            />
            <LegalLegends texts={legends} />
          </Form>
        )}
      </Formik>
    </SC.LevelPickersForm>
  )
}

export default LevelPickersForm
