import React, { FC, useCallback } from 'react'
import { SwiperSlide } from 'swiper/react'

import { ImgProps } from '../../types/image'

import * as SC from './styled'

type UserSlideProps = {
  avatar: ImgProps
  label: string
  value: string
  className?: string
}

export type UsersSliderProps = {
  options: UserSlideProps[]
  onChange: (name: string, id: string) => void
  value: string
  className?: string
  name?: string
  label?: string
  error?: string
  help?: string
  required?: boolean
}

const UsersSlider: FC<UsersSliderProps> = (props) => {
  const { options, onChange, value, className = '', name, label, required, error, help } = props

  const handleClick = useCallback(
    (id) => {
      onChange(name ?? '', id)
    },
    [name, onChange]
  )

  return (
    <SC.UsersSlider className={className}>
      {label && (
        <SC.Label required={required} error={!!error}>
          {label}
        </SC.Label>
      )}
      <SC.Slider slidesPerView="auto" threshold={5}>
        {options.map((option) => (
          <SwiperSlide key={`users-slider${option.value}`}>
            <SC.SlideContent
              selected={value === option.value}
              onClick={() => handleClick(option.value)}
            >
              <SC.AvatarImg {...option.avatar} />
              <SC.Text selected={value === option.value}>{option.label}</SC.Text>
            </SC.SlideContent>
          </SwiperSlide>
        ))}
      </SC.Slider>
      {(error || help) && <SC.HelperText error={!!error}>{error || help}</SC.HelperText>}
    </SC.UsersSlider>
  )
}

export default UsersSlider
