import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { HeaderProps } from '../../components/Header'
import useHeader from '../../hooks/useHeader'
import SignInForgotTemplate from '../../templates/SignInForgot'
import { useFormSubmit } from '../../hooks/useFormSubmit'
import { actions, selectors } from '../../redux'
import { router, routesPath } from '../../router'
import { SignInForgotFormProps } from '../../components/SignInForgotForm'
import useBackButton from '../../hooks/useBackButton'

const SignInForgotPage: React.FC = (pageProps) => {
  const headerProps: HeaderProps = useHeader(pageProps)
  const { t } = useTranslation()

  const backButton = useBackButton(router(routesPath.signIn))

  const [submit, handleSubmit] = useFormSubmit(
    selectors.auth.signInForgot,
    actions.auth.signInForgotRequest,
    actions.auth.signInForgotReset
  )

  const signupProps: SignInForgotFormProps = useMemo(() => {
    return {
      title: t('signInForgot_title'),
      text: t('signInForgot_text'),
      backButtonProps: backButton,
      fieldsProps: {
        email: {
          label: t('signInForgot_form_email_label'),
        },
      },
      mandatoryText: t('signIn_required_text'),
      initialValues: {
        email: submit?.params?.email ?? '',
      },
      errorTexts: {
        required: t('error_required'),
        email: t('error_email'),
      },
      submitButton: {
        text: t('signInForgot_submit'),
        pendingText: t('signInForgot_submit_pending'),
        successText: t('signInForgot_submit_success'),
        isPending: submit.pending,
        isSuccess: submit.success,
      },
      submitErrors: submit.errors,
      onSubmit: (values) => handleSubmit(values),
    }
  }, [t, handleSubmit, submit, backButton])

  const imageProps = { alt: 'LiberteClass - Connexion', src: '/static/assets/images/signin.png' }
  return (
    <SignInForgotTemplate
      headerProps={headerProps}
      signupProps={signupProps}
      imageProps={imageProps}
    />
  )
}

export default SignInForgotPage
