import React, { FC } from 'react'

import { Icons } from '../Icon/types'
import { LinkProps } from '../Link'

import * as SC from './styled'

export type UserProps = {
  id?: string
  first_name?: string
  last_name?: string
  type?: string
}

export type ImpersonateBannerProps = {
  className?: string
  label?: string
  link?: LinkProps
  onCopy?: () => void
  user?: UserProps
}

const ImpersonateBanner: FC<ImpersonateBannerProps> = (props) => {
  const { className, label, user, link, onCopy } = props

  return (
    <SC.ImpersonateBanner className={className}>
      <SC.Wrapper>
        <SC.Label>{label}</SC.Label>
        <SC.UserInfos>
          <SC.UserId>{user?.id}</SC.UserId>
          <SC.UserName>
            {user?.first_name} {user?.last_name}
          </SC.UserName>
          <SC.Link {...link}>
            <SC.UserType>{user?.type}</SC.UserType>
          </SC.Link>
        </SC.UserInfos>
        <SC.Link {...link}>
          <SC.Icon icon={Icons.profileUp} />
        </SC.Link>
        <SC.Link>
          <SC.Icon icon={Icons.key} onClick={onCopy} />
        </SC.Link>
      </SC.Wrapper>
    </SC.ImpersonateBanner>
  )
}

export default ImpersonateBanner
