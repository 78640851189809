import { FormikProps } from 'formik'
import React, { FC, useMemo } from 'react'
import { IconButtonProps } from '@material-ui/core'

import { renderFieldArrayField } from '../../helpers/FormHelpers'
import { FormFieldConfig, FormFieldsProps } from '../../types/form'
import { Icons } from '../Icon/types'

import * as SC from './styled'

export type SignUpAddChildFieldArrayProps = {
  className?: string
  title: string
  fieldsProps?: FormFieldsProps
  mandatoryText: string
  formikProps?: FormikProps<any>
  errorTexts?: { [key: string]: string }
  fields?: FormFieldConfig[]
  name: string
  index: number
  closeButtonProps?: IconButtonProps
}

const SignUpAddChildFieldArray: FC<SignUpAddChildFieldArrayProps> = (props) => {
  const {
    className,
    title,
    fieldsProps,
    mandatoryText,
    formikProps,
    fields,
    name,
    index,
    closeButtonProps,
  } = props

  const sortedFields = useMemo(
    () =>
      fields?.reduce(
        (acc, curr) => {
          curr.conditional ? acc.conditional.push(curr) : acc.permanent.push(curr)
          return acc
        },
        { permanent: [], conditional: [] } as {
          permanent: FormFieldConfig[]
          conditional: FormFieldConfig[]
        }
      ),
    [fields]
  )

  const showConditionalFields: boolean = useMemo(() => {
    return formikProps?.values?.children?.[index].signupChild
  }, [formikProps?.values?.children, index])

  return (
    <SC.AddChildForm className={className}>
      <SC.Wrapper>
        <SC.Head>
          <SC.Title>{title}</SC.Title>
          {closeButtonProps && (
            <SC.CloseButton {...closeButtonProps}>
              <SC.Close icon={Icons.trash} />
            </SC.CloseButton>
          )}
        </SC.Head>
        <SC.Fields>
          {sortedFields?.permanent &&
            sortedFields?.permanent
              .filter((field) => field.name !== 'file')
              ?.map((field, idx) => {
                return (
                  field &&
                  formikProps &&
                  renderFieldArrayField([{ name, index }], field, formikProps, fieldsProps, idx)
                )
              })}
          <SC.ConditionalFields visible={showConditionalFields}>
            {sortedFields?.conditional
              ?.filter((field) => field.name !== 'file')
              ?.map((field, idx) => {
                return (
                  field &&
                  formikProps &&
                  renderFieldArrayField([{ name, index }], field, formikProps, fieldsProps, idx)
                )
              })}
          </SC.ConditionalFields>
        </SC.Fields>
        <SC.MandatoryText>{mandatoryText}</SC.MandatoryText>
        {fields &&
          fields
            .filter((field) => field.name === 'file')
            ?.map((field, idx) => {
              return (
                field &&
                formikProps &&
                renderFieldArrayField([{ name, index }], field, formikProps, fieldsProps, idx)
              )
            })}
      </SC.Wrapper>
    </SC.AddChildForm>
  )
}

export default SignUpAddChildFieldArray
