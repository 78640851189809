import styled from 'styled-components'

import NumberC from '../form/Number'
import TextFieldC from '../form/TextField'
import IbanC from '../form/Iban'
import FormSubmit from '../FormSubmit'

export const Attach = styled.div``

export const Step = styled.p`
  ${(props) => ({ ...props.theme.typography.h6 })}
  color: ${(props) => props.theme.palette.colors.neonCarrot};
  text-transform: uppercase;
`

export const Text = styled.p`
  ${(props) => ({ ...props.theme.typography.tinySmall })}
  color: ${(props) => props.theme.palette.colors.chambray};
  margin: 0 0 1rem 0;
`

export const Fields = styled.div`
  margin-top: 2rem;
`

export const Number = styled(NumberC)`
  margin-bottom: 1rem;
  width: 100%;
  margin-right: 1rem;
`

export const TextField = styled(TextFieldC)`
  margin-bottom: 1rem;
  width: 100%;
  margin-right: 1rem;
`

export const Iban = styled(IbanC)`
  margin-bottom: 1rem;
  width: 100%;
  margin-right: 1rem;
`

export const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 2rem;
`

export const SkipButton = styled(FormSubmit)`
  width: 40%;
  margin-right: 0.5rem;
`

export const SubmitButton = styled(FormSubmit)`
  width: 60%;
  margin-left: 0.5rem;
  &:only-child {
    width: 100%;
    margin: 0;
  }
`
