import styled from 'styled-components'

import BasicLink from '../BasicLink'
import ActionButton from '../ActionButton'
import TextFieldC from '../form/TextField'
import DocumentUploadC from '../form/UploadFile'
import FormSubmit from '../FormSubmit'
import TextFieldLimitedChars from '../form/TextFieldLimitedChars'

export const ProfessionalInfos = styled.div`
  padding: 0 1.6rem;
`

export const Step = styled.p`
  ${(props) => ({ ...props.theme.typography.h6 })}
  color: ${(props) => props.theme.palette.colors.neonCarrot};
  text-transform: uppercase;
`

export const Title = styled.h1`
  ${(props) => ({ ...props.theme.typography.h1 })}
  color: ${(props) => props.theme.palette.colors.midnightBlue};
  font-size: 3.2rem;
  margin: 0 0 2rem 0;
  ${(props) => props.theme.breakpoints.up('md')} {
    font-size: 4rem;
  }
`

export const Text = styled.p`
  ${(props) => ({ ...props.theme.typography.h6 })}
  color: ${(props) => props.theme.palette.colors.midnightBlue};
  margin: 0 0 1rem 0;
`

export const Error = styled.p`
  color: ${(props) => props.theme.palette.colors.tomato};
  ${(props) => ({ ...props.theme.typography.smallRegular })}
  margin: -0.2rem 0 0.5rem 0;
`

export const Siret = styled.div``

export const SiretText = styled.p`
  ${(props) => ({ ...props.theme.typography.smallRegular })}
  color: ${(props) => props.theme.palette.colors.chambray};
`

export const TextField = styled(TextFieldC)`
  margin-bottom: 1rem;
  width: 100%;
  margin-right: 1rem;
`

export const TextFieldLimited = styled(TextFieldLimitedChars)`
  margin-bottom: 1rem;
  width: 100%;
  margin-right: 1rem;
`

export const NoStatut = styled.div<{ smallMargin?: boolean }>`
  padding: 1rem;
  border: 1px solid ${(props) => props.theme.palette.colors.kournikova};
  background-color: ${(props) => props.theme.palette.colors.pureWhite};
  border-radius: 0.6rem;
  margin-top: ${({ smallMargin }) => (smallMargin ? '0' : '2rem')};
`

export const NoStatutTitle = styled.h3`
  ${(props) => ({ ...props.theme.typography.h6 })};
  color: ${(props) => props.theme.palette.colors.tomato};
  margin: 0;
`

export const NoStatutText = styled.p`
  ${(props) => ({ ...props.theme.typography.smallRegular })};
  color: ${(props) => props.theme.palette.colors.tomato};
`

export const NoStatutCta = styled(BasicLink)`
  align-items: center;
  display: flex;
  text-decoration: none;
  text-transform: uppercase;
  margin: 0;
  color: ${(props) => props.theme.palette.colors.freeSpeechBlue};
  max-width: max-content;
  border-bottom: 2px solid transparent;
  ${(props) => ({ ...props.theme.typography.h6 })};
  &:hover {
    border-bottom: 2px solid ${(props) => props.theme.palette.colors.freeSpeechBlue};
    text-decoration: none;
  }
`

export const Document = styled.div`
  margin-top: 3rem;
`

export const DocumentTitle = styled.h3`
  ${(props) => ({ ...props.theme.typography.h5 })};
  color: ${(props) => props.theme.palette.colors.freeSpeechBlue};
`

export const DocumentText = styled.p`
  ${(props) => ({ ...props.theme.typography.smallRegular })};
  color: ${(props) => props.theme.palette.colors.chambray};
`

export const DocumentUploads = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 1rem;
  flex-direction: column;
`

export const UploadFile = styled(DocumentUploadC)`
  width: 100%;
  margin-bottom: 1.6rem;
`

export const Presentation = styled.div`
  margin-top: 3rem;
`

export const PresentationTitle = styled.h3`
  ${(props) => ({ ...props.theme.typography.h5 })};
  color: ${(props) => props.theme.palette.colors.freeSpeechBlue};
`

export const MandatoryText = styled.p`
  ${(props) => ({ ...props.theme.typography.smallRegular })}
  color: ${(props) => props.theme.palette.colors.grey};
  margin: 2rem 0 1rem 0;
`

export const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const ReturnButton = styled(ActionButton)`
  flex: 1;
  margin: 2rem 1rem 0 0;
`

export const SubmitButton = styled(FormSubmit)`
  flex: 2;
  margin: 2rem 0 0 1rem;
`
