export type Maybe<T> = T | null
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  /** A date string with format `Y-m-d`, e.g. `2011-05-23`. */
  Date: any
  /** A datetime string with format `Y-m-d H:i:s`, e.g. `2018-05-23 13:43:32`. */
  DateTime: any
  /** Arbitrary data encoded in JavaScript Object Notation. See https://www.json.org/. */
  JSON: any
}

export type Availability = {
  __typename?: 'Availability'
  date: Scalars['Date']
  slot: AvailabilitySlot
}

export type AvailabilityInput = {
  date: Scalars['Date']
  slots: Array<AvailabilitySlot>
}

export enum AvailabilitySlot {
  /** 8h-9h */
  H8 = 'H8',
  /** 9h-10h */
  H9 = 'H9',
  /** 10h-11h */
  H10 = 'H10',
  /** 11h-12h */
  H11 = 'H11',
  /** 12h-13h */
  H12 = 'H12',
  /** 13h-14h */
  H13 = 'H13',
  /** 14h-15h */
  H14 = 'H14',
  /** 15h-16h */
  H15 = 'H15',
  /** 16h-17h */
  H16 = 'H16',
  /** 17h-18h */
  H17 = 'H17',
  /** 18h-19h */
  H18 = 'H18',
  /** 19h-20h */
  H19 = 'H19',
  /** 20h-21h */
  H20 = 'H20',
  /** 21h-22h */
  H21 = 'H21',
}

export type Block = {
  order: Scalars['Int']
}

export type ClassMarketSlot = {
  __typename?: 'ClassMarketSlot'
  slot: AvailabilitySlot
  count: Scalars['Int']
  available: Scalars['Boolean']
}

export type ContactRequest = {
  __typename?: 'ContactRequest'
  name: Scalars['String']
  email: Scalars['String']
  subject: Scalars['String']
  message: Scalars['String']
  requested_at: Scalars['DateTime']
}

export type Course = {
  __typename?: 'Course'
  id: Scalars['ID']
  type: CourseType
  status: CourseStatus
  source?: Maybe<CourseSource>
  maximum_price?: Maybe<Scalars['Int']>
  price_per_participant?: Maybe<Scalars['Int']>
  maximum_participants: Scalars['Int']
  minimum_score?: Maybe<Scalars['Float']>
  date?: Maybe<Scalars['Date']>
  slot?: Maybe<AvailabilitySlot>
  lesson?: Maybe<Lesson>
  teacher?: Maybe<PublicTeacher>
  teacher_price?: Maybe<Scalars['Int']>
  requests?: Maybe<Array<CourseRequest>>
  students?: Maybe<PublicStudent>
  participants: Scalars['Int']
  room?: Maybe<Room>
  studentReviews?: Maybe<Array<StudentReview>>
  cancel_reason?: Maybe<CourseCancelReason>
  cancel_text?: Maybe<Scalars['String']>
  cancel_file?: Maybe<Scalars['String']>
}

export enum CourseCancelReason {
  /** Cas de force majeure */
  ForceMajeure = 'FORCE_MAJEURE',
  /** Panne de connexion internet */
  InternetFailure = 'INTERNET_FAILURE',
  /** Autre */
  Other = 'OTHER',
}

/** A paginated list of Course items. */
export type CoursePaginator = {
  __typename?: 'CoursePaginator'
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo
  /** A list of Course items. */
  data: Array<Course>
}

export type CourseRequest = {
  __typename?: 'CourseRequest'
  id: Scalars['ID']
  type: CourseType
  maximum_price: Scalars['Int']
  maximum_participants: Scalars['Int']
  minimum_score?: Maybe<Scalars['Float']>
  notice_period?: Maybe<Scalars['Int']>
  lesson: Lesson
  availabilities?: Maybe<Array<Availability>>
  student: PublicStudent
  created_at: Scalars['DateTime']
  updated_at: Scalars['DateTime']
}

export type CourseRequestGroup = {
  __typename?: 'CourseRequestGroup'
  id: Scalars['ID']
  type: CourseType
  maximum_price: Scalars['Int']
  minimum_score?: Maybe<Scalars['Float']>
  lesson: Lesson
  created_at: Scalars['DateTime']
  updated_at: Scalars['DateTime']
}

/** A paginated list of CourseRequest items. */
export type CourseRequestPaginator = {
  __typename?: 'CourseRequestPaginator'
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo
  /** A list of CourseRequest items. */
  data: Array<CourseRequest>
}

export enum CourseSource {
  /** Recherche */
  Search = 'SEARCH',
  /** Marché des classes */
  Market = 'MARKET',
}

export enum CourseStatus {
  /** Accepté */
  Accepted = 'ACCEPTED',
  /** Incomplet */
  Incomplete = 'INCOMPLETE',
  /** En attente */
  Pending = 'PENDING',
  /** labels.course_status.probability */
  Probability = 'PROBABILITY',
  /** Annulé */
  Cancelled = 'CANCELLED',
  /** Refusé */
  Declined = 'DECLINED',
}

export enum CourseType {
  /** Cours ponctuel */
  OneOff = 'ONE_OFF',
  /** Cours récurrent */
  Recurrent = 'RECURRENT',
  /** Aide d'urgence */
  Emergency = 'EMERGENCY',
}

export type Discipline = {
  __typename?: 'Discipline'
  id: Scalars['ID']
  name: Scalars['String']
  slug: Scalars['String']
}

export type Dispute = {
  __typename?: 'Dispute'
  id: Scalars['ID']
  reason: DisputeReason
  status: DisputeStatus
  message?: Maybe<Scalars['String']>
  course: Course
  student?: Maybe<PublicStudent>
}

export enum DisputeReason {
  /** Incident suite à un cas de force majeure (définies dans les CGU) */
  ForceMajeure = 'FORCE_MAJEURE',
  /** Autre */
  Other = 'OTHER',
  /** Incident technique avec la plateforme */
  PlatformFailure = 'PLATFORM_FAILURE',
  /** Comportement inapproprié d'un ou plusieurs élève(s). */
  StudentBehavior = 'STUDENT_BEHAVIOR',
  /** L'enseignant avait une attitude incorrecte pendant le cours */
  TeacherBehaviour = 'TEACHER_BEHAVIOUR',
  /** L'enseignant ne s'est pas présenté au cours ou s'est présenté en retard */
  TeacherMissingOrLate = 'TEACHER_MISSING_OR_LATE',
  /** L'enseignant n'a pas abordé la leçon prévue pour le cours */
  TeacherWrongContent = 'TEACHER_WRONG_CONTENT',
}

export enum DisputeStatus {
  /** En attente */
  Pending = 'PENDING',
  /** Résolu */
  Resolved = 'RESOLVED',
}

export type FamilyMemberInput = {
  first_name: Scalars['String']
  last_name: Scalars['String']
  email?: Maybe<Scalars['String']>
  password?: Maybe<Scalars['String']>
  school?: Maybe<Scalars['String']>
  grade: Scalars['ID']
  avatar?: Maybe<Scalars['String']>
}

export type Grade = {
  __typename?: 'Grade'
  id: Scalars['ID']
  name: Scalars['String']
  slug: Scalars['String']
  tag?: Maybe<Scalars['String']>
}

export type Guardian = {
  __typename?: 'Guardian'
  id: Scalars['ID']
  code?: Maybe<Scalars['String']>
  children?: Maybe<Array<Student>>
}

export type Invitation = {
  __typename?: 'Invitation'
  id: Scalars['ID']
  email: Scalars['String']
  code: Scalars['String']
  teacher?: Maybe<PublicTeacher>
}

export enum InvitationStatus {
  /** En attente */
  Pending = 'PENDING',
  /** Acceptée */
  Accepted = 'ACCEPTED',
}

/** Nova-Translation Label type. */
export type Label = {
  __typename?: 'Label'
  /** Label type */
  type: Scalars['String']
  /** Label key */
  key: Scalars['String']
  /** Label value */
  value?: Maybe<Scalars['String']>
  /** Creation datetime */
  createdAt: Scalars['DateTime']
  /** Last update datetime */
  updatedAt: Scalars['DateTime']
  /** Locale ISO */
  locale: Scalars['String']
  /** Item translation ID */
  translationId: Scalars['Int']
}

export type Lesson = {
  __typename?: 'Lesson'
  id: Scalars['ID']
  name: Scalars['String']
  slug: Scalars['String']
  description?: Maybe<Scalars['String']>
  theme?: Maybe<Theme>
  supports?: Maybe<Array<Media>>
  type: LessonType
}

export enum LessonType {
  /** Personnalisée */
  Custom = 'CUSTOM',
  /** Système */
  System = 'SYSTEM',
}

/** Nova-Translation Locale type. */
export type Locale = {
  __typename?: 'Locale'
  /** Locale ID */
  id: Scalars['ID']
  /** Locale ISO */
  iso: Scalars['String']
  /** Locale label */
  label: Scalars['String']
  /** Locale fallback ID */
  fallbackId?: Maybe<Scalars['ID']>
  /** Locale fallback */
  fallback?: Maybe<Locale>
  /** Locale is available in API? */
  isAvailableInApi: Scalars['Boolean']
  /** Creation datetime */
  createdAt: Scalars['DateTime']
  /** Last update datetime */
  updatedAt: Scalars['DateTime']
}

/** Locales default input (nothing to fetch all locales) */
export type LocaleFilters = {
  /** Locale ISO */
  locale?: Maybe<Scalars['String']>
  /** A list of valid ISOs to fetch */
  locales?: Maybe<Array<Scalars['String']>>
}

export type Media = {
  __typename?: 'Media'
  file_name: Scalars['String']
  mime_type?: Maybe<Scalars['String']>
  human_readable_size?: Maybe<Scalars['String']>
  url?: Maybe<Scalars['String']>
}

export type Mutation = {
  __typename?: 'Mutation'
  register: User
  registerPersonalInformation: User
  registerProfessionalInformation: User
  registerFamily: User
  linkGuardian: User
  linkGuardianLater: User
  inviteGuardian: User
  login: User
  socialLogin: User
  confirmSocialRegister: User
  refreshToken?: Maybe<Scalars['String']>
  invalidateToken: Scalars['Boolean']
  forgotPassword: Scalars['String']
  resetPassword?: Maybe<User>
  resendVerificationMail: Scalars['String']
  requestContact: ContactRequest
  requestCourse: CourseRequest
  applyToCourse: Course
  createCourse: Course
  acceptCourse: Course
  pickCourseOfMarket: Course
  evaluateStudents: Course
  cancelCourseRequest: CourseRequest
  cancelPendingCourse: Course
  declineCourse: Course
  cancelCourse: Course
  createDispute: Dispute
  updateGuardian: User
  addFamilyMember: User
  updateFamilyMember: User
  deleteFamilyMember: User
  createLesson: Lesson
  createCreditPaymentIntent?: Maybe<Payment>
  updateStudent: User
  updateTeacherDisciplines: User
  updateTeacherPrice: User
  updateTeacher: User
  updateTeacherMaximumParticipants: User
  updatePayoutScheduleInterval: User
  updateTeacherAvailabilityOfDay?: Maybe<Array<TeacherAvailability>>
  createTeacherAvailabilityRecurrence: TeacherAvailabilityRecurrence
  updateTeacherAvailabilityRecurrence: TeacherAvailabilityRecurrence
  deleteTeacherAvailabilityRecurrence?: Maybe<TeacherAvailabilityRecurrence>
  updatePassword: User
  requestRefund: WalletRefund
}

export type MutationRegisterArgs = {
  user_type: UserType
  first_name: Scalars['String']
  last_name: Scalars['String']
  email: Scalars['String']
  password: Scalars['String']
  password_confirmation: Scalars['String']
  sponsor_code?: Maybe<Scalars['String']>
  promo_code?: Maybe<Scalars['String']>
}

export type MutationRegisterPersonalInformationArgs = {
  phone?: Maybe<Scalars['String']>
  display_address?: Maybe<Scalars['String']>
  address?: Maybe<Scalars['String']>
  city?: Maybe<Scalars['String']>
  zip_code?: Maybe<Scalars['String']>
  country?: Maybe<Scalars['String']>
  school?: Maybe<Scalars['String']>
  grade?: Maybe<Scalars['ID']>
  avatar?: Maybe<Scalars['String']>
}

export type MutationRegisterProfessionalInformationArgs = {
  siret: Scalars['String']
  cv?: Maybe<Upload>
  diploma?: Maybe<Upload>
  company_certificate?: Maybe<Upload>
  introduction?: Maybe<Scalars['String']>
}

export type MutationRegisterFamilyArgs = {
  members?: Maybe<Array<FamilyMemberInput>>
}

export type MutationLinkGuardianArgs = {
  sponsor_code: Scalars['String']
}

export type MutationInviteGuardianArgs = {
  email: Scalars['String']
}

export type MutationLoginArgs = {
  email: Scalars['String']
  password: Scalars['String']
}

export type MutationSocialLoginArgs = {
  code: Scalars['String']
  type: SocialProviderType
  user_type?: Maybe<UserType>
  first_name?: Maybe<Scalars['String']>
  last_name?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  sponsor_code?: Maybe<Scalars['String']>
}

export type MutationConfirmSocialRegisterArgs = {
  user_type: UserType
  first_name: Scalars['String']
  last_name: Scalars['String']
  email: Scalars['String']
  sponsor_code?: Maybe<Scalars['String']>
}

export type MutationForgotPasswordArgs = {
  email: Scalars['String']
}

export type MutationResetPasswordArgs = {
  token: Scalars['String']
  email: Scalars['String']
  password: Scalars['String']
  password_confirmation: Scalars['String']
}

export type MutationRequestContactArgs = {
  name: Scalars['String']
  email: Scalars['String']
  subject: Scalars['String']
  message: Scalars['String']
}

export type MutationRequestCourseArgs = {
  lesson: Scalars['ID']
  student?: Maybe<Scalars['ID']>
  type: CourseType
  maximum_price: Scalars['Int']
  maximum_participants: Scalars['Int']
  minimum_score?: Maybe<Scalars['Float']>
  notice_period: Scalars['Int']
  availabilities: Array<AvailabilityInput>
}

export type MutationApplyToCourseArgs = {
  course: Scalars['ID']
  maximum_participants: Scalars['Int']
  minimum_score?: Maybe<Scalars['Float']>
  student?: Maybe<Scalars['ID']>
  teacher?: Maybe<Scalars['ID']>
  date?: Maybe<Scalars['Date']>
  slot?: Maybe<AvailabilitySlot>
}

export type MutationCreateCourseArgs = {
  lesson: Scalars['ID']
  student?: Maybe<Scalars['ID']>
  type: CourseType
  maximum_participants: Scalars['Int']
  teacher: Scalars['ID']
  date: Scalars['Date']
  slot: AvailabilitySlot
}

export type MutationAcceptCourseArgs = {
  course: Scalars['ID']
}

export type MutationPickCourseOfMarketArgs = {
  course: Scalars['ID']
  date: Scalars['Date']
  slot: AvailabilitySlot
}

export type MutationEvaluateStudentsArgs = {
  course: Scalars['ID']
  evaluations: Array<StudentEvaluationInput>
}

export type MutationCancelCourseRequestArgs = {
  request: Scalars['ID']
}

export type MutationCancelPendingCourseArgs = {
  course: Scalars['ID']
}

export type MutationDeclineCourseArgs = {
  course: Scalars['ID']
}

export type MutationCancelCourseArgs = {
  course: Scalars['ID']
  reason: CourseCancelReason
  text: Scalars['String']
  file?: Maybe<Upload>
}

export type MutationCreateDisputeArgs = {
  course: Scalars['ID']
  student?: Maybe<Scalars['ID']>
  reason: DisputeReason
  message?: Maybe<Scalars['String']>
}

export type MutationUpdateGuardianArgs = {
  first_name: Scalars['String']
  last_name: Scalars['String']
  email: Scalars['String']
  phone: Scalars['String']
  display_address: Scalars['String']
  address?: Maybe<Scalars['String']>
  city?: Maybe<Scalars['String']>
  zip_code?: Maybe<Scalars['String']>
  country?: Maybe<Scalars['String']>
  avatar?: Maybe<Scalars['String']>
}

export type MutationAddFamilyMemberArgs = {
  member: FamilyMemberInput
}

export type MutationUpdateFamilyMemberArgs = {
  student: Scalars['ID']
  member: FamilyMemberInput
}

export type MutationDeleteFamilyMemberArgs = {
  student: Scalars['ID']
}

export type MutationCreateLessonArgs = {
  name: Scalars['String']
  file?: Maybe<Upload>
  student?: Maybe<Scalars['ID']>
  discipline: Scalars['ID']
}

export type MutationCreateCreditPaymentIntentArgs = {
  amount: Scalars['Int']
}

export type MutationUpdateStudentArgs = {
  first_name: Scalars['String']
  last_name: Scalars['String']
  email: Scalars['String']
  school?: Maybe<Scalars['String']>
  grade: Scalars['ID']
  avatar?: Maybe<Scalars['String']>
}

export type MutationUpdateTeacherDisciplinesArgs = {
  disciplines?: Maybe<Array<TeacherDisciplineInput>>
}

export type MutationUpdateTeacherPriceArgs = {
  price: Scalars['Int']
}

export type MutationUpdateTeacherArgs = {
  first_name: Scalars['String']
  last_name: Scalars['String']
  email: Scalars['String']
  phone: Scalars['String']
  display_address: Scalars['String']
  address?: Maybe<Scalars['String']>
  city?: Maybe<Scalars['String']>
  zip_code?: Maybe<Scalars['String']>
  country?: Maybe<Scalars['String']>
  cv?: Maybe<Upload>
  diploma?: Maybe<Upload>
  company_certificate?: Maybe<Upload>
  introduction?: Maybe<Scalars['String']>
  avatar?: Maybe<Scalars['String']>
}

export type MutationUpdateTeacherMaximumParticipantsArgs = {
  maximum_participants: Scalars['Int']
}

export type MutationUpdatePayoutScheduleIntervalArgs = {
  interval: PayoutScheduleInterval
}

export type MutationUpdateTeacherAvailabilityOfDayArgs = {
  date: Scalars['Date']
  slots?: Maybe<Array<AvailabilitySlot>>
}

export type MutationCreateTeacherAvailabilityRecurrenceArgs = {
  start_at: Scalars['Date']
  end_at: Scalars['Date']
  days?: Maybe<Array<RecurrenceDay>>
  slots?: Maybe<Array<AvailabilitySlot>>
}

export type MutationUpdateTeacherAvailabilityRecurrenceArgs = {
  id: Scalars['ID']
  start_at: Scalars['Date']
  end_at: Scalars['Date']
  days?: Maybe<Array<RecurrenceDay>>
  slots?: Maybe<Array<AvailabilitySlot>>
}

export type MutationDeleteTeacherAvailabilityRecurrenceArgs = {
  id: Scalars['ID']
}

export type MutationUpdatePasswordArgs = {
  current: Scalars['String']
  password: Scalars['String']
  password_confirmation: Scalars['String']
}

export type MutationRequestRefundArgs = {
  amount: Scalars['Int']
  iban: Scalars['String']
}

export type Notification = {
  __typename?: 'Notification'
  id: Scalars['ID']
  type: NotificationType
  course?: Maybe<Course>
  student?: Maybe<Student>
  payload?: Maybe<Scalars['JSON']>
  read_at?: Maybe<Scalars['DateTime']>
  created_at: Scalars['DateTime']
}

/** A paginated list of Notification items. */
export type NotificationPaginator = {
  __typename?: 'NotificationPaginator'
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo
  /** A list of Notification items. */
  data: Array<Notification>
}

export enum NotificationType {
  /** labels.notification_type.course_cancelled */
  CourseCancelled = 'COURSE_CANCELLED',
  /** Cours confirmé */
  CourseConfirmed = 'COURSE_CONFIRMED',
  /** Demande de cours refusée */
  CourseDeclined = 'COURSE_DECLINED',
  /** Cours payé */
  CoursePaid = 'COURSE_PAID',
  /** Rappel cours à venir */
  CourseReminder = 'COURSE_REMINDER',
  /** Avoir créé */
  CreditNoteReceived = 'CREDIT_NOTE_RECEIVED',
  /** labels.notification_type.pending_course */
  PendingCourse = 'PENDING_COURSE',
  /** labels.notification_type.student_review */
  StudentReview = 'STUDENT_REVIEW',
  /** Évaluer l'enseignant */
  TeacherReview = 'TEACHER_REVIEW',
  /** labels.notification_type.transfer_executed */
  TransferExecuted = 'TRANSFER_EXECUTED',
}

/** Allows ordering a list of records. */
export type OrderByClause = {
  /** The column that is used for ordering. */
  column: Scalars['String']
  /** The direction that is used for ordering. */
  order: SortOrder
}

/** Aggregate functions when ordering by a relation without specifying a column. */
export enum OrderByRelationAggregateFunction {
  /** Amount of items. */
  Count = 'COUNT',
}

/** Aggregate functions when ordering by a relation that may specify a column. */
export enum OrderByRelationWithColumnAggregateFunction {
  /** Average. */
  Avg = 'AVG',
  /** Minimum. */
  Min = 'MIN',
  /** Maximum. */
  Max = 'MAX',
  /** Sum. */
  Sum = 'SUM',
  /** Amount of items. */
  Count = 'COUNT',
}

/** Information about pagination using a Relay style cursor connection. */
export type PageInfo = {
  __typename?: 'PageInfo'
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']
  /** The cursor to continue paginating backwards. */
  startCursor?: Maybe<Scalars['String']>
  /** The cursor to continue paginating forwards. */
  endCursor?: Maybe<Scalars['String']>
  /** Total number of nodes in the paginated connection. */
  total: Scalars['Int']
  /** Number of nodes in the current page. */
  count: Scalars['Int']
  /** Index of the current page. */
  currentPage: Scalars['Int']
  /** Index of the last available page. */
  lastPage: Scalars['Int']
}

/** Information about pagination using a fully featured paginator. */
export type PaginatorInfo = {
  __typename?: 'PaginatorInfo'
  /** Number of items in the current page. */
  count: Scalars['Int']
  /** Index of the current page. */
  currentPage: Scalars['Int']
  /** Index of the first item in the current page. */
  firstItem?: Maybe<Scalars['Int']>
  /** Are there more pages after this one? */
  hasMorePages: Scalars['Boolean']
  /** Index of the last item in the current page. */
  lastItem?: Maybe<Scalars['Int']>
  /** Index of the last available page. */
  lastPage: Scalars['Int']
  /** Number of items per page. */
  perPage: Scalars['Int']
  /** Number of total available items. */
  total: Scalars['Int']
}

export type Payment = {
  __typename?: 'Payment'
  id: Scalars['ID']
  status?: Maybe<PaymentStatus>
  client_secret?: Maybe<Scalars['String']>
}

export enum PaymentStatus {
  Canceled = 'CANCELED',
  Processing = 'PROCESSING',
  RequiresAction = 'REQUIRES_ACTION',
  RequiresCapture = 'REQUIRES_CAPTURE',
  RequiresConfirmation = 'REQUIRES_CONFIRMATION',
  RequiresPaymentMethod = 'REQUIRES_PAYMENT_METHOD',
  Succeeded = 'SUCCEEDED',
}

export enum PayoutScheduleInterval {
  /** Quotidien */
  Daily = 'DAILY',
  /** Manuel */
  Manual = 'MANUAL',
  /** Mensuel */
  Monthly = 'MONTHLY',
  /** Hebdomadaire */
  Weekly = 'WEEKLY',
}

export type PublicGuardian = {
  __typename?: 'PublicGuardian'
  id: Scalars['ID']
  email: Scalars['String']
  full_name?: Maybe<Scalars['String']>
  avatar?: Maybe<Scalars['String']>
}

export type PublicStudent = {
  __typename?: 'PublicStudent'
  id: Scalars['ID']
  full_name?: Maybe<Scalars['String']>
  avatar?: Maybe<Scalars['String']>
}

export type PublicTeacher = {
  __typename?: 'PublicTeacher'
  id: Scalars['ID']
  full_name?: Maybe<Scalars['String']>
  avatar?: Maybe<Scalars['String']>
  disciplines?: Maybe<Array<PublicTeacherDiscipline>>
  introduction?: Maybe<Scalars['String']>
}

export type PublicTeacherDiscipline = {
  __typename?: 'PublicTeacherDiscipline'
  discipline: Discipline
  grade: Grade
}

export type Query = {
  __typename?: 'Query'
  me?: Maybe<User>
  courseById?: Maybe<Course>
  courseRequestById?: Maybe<CourseRequest>
  todayCourse?: Maybe<Course>
  todayCourses?: Maybe<Array<Course>>
  classMarketByDate?: Maybe<Array<ClassMarketSlot>>
  disciplines?: Maybe<Array<Discipline>>
  grades?: Maybe<Array<Grade>>
  /** Fetch labels by locale ISO */
  labels: Array<Label>
  /** Fetch all locales */
  locales: Array<Locale>
  /** Locale by ID */
  localeById?: Maybe<Locale>
  /** Locale by ISO */
  localeByIso?: Maybe<Locale>
  joinRoom?: Maybe<Room>
  settings?: Maybe<Setting>
  stripeAccountLink?: Maybe<Scalars['String']>
  stripeLoginLink?: Maybe<Scalars['String']>
  teacherById?: Maybe<PublicTeacher>
  teacherAvailabilitiesOfMonth?: Maybe<Array<TeacherAvailability>>
  teacherRecurrenceById?: Maybe<TeacherAvailabilityRecurrence>
  teacherPendingCourses?: Maybe<CoursePaginator>
  futureCourses?: Maybe<CoursePaginator>
  pastCourses?: Maybe<CoursePaginator>
  pendingCourses?: Maybe<CoursePaginator>
  coursesWithMaterial?: Maybe<CoursePaginator>
  pendingCourseRequests?: Maybe<CourseRequestPaginator>
  pendingReviewCourses?: Maybe<CoursePaginator>
  searchCourse?: Maybe<CoursePaginator>
  classMarketByDateAndSlot?: Maybe<CoursePaginator>
  notifications?: Maybe<NotificationPaginator>
  teacherRecurrences?: Maybe<TeacherAvailabilityRecurrencePaginator>
  themes?: Maybe<ThemePaginator>
  bookingTransactions?: Maybe<WalletTransactionPaginator>
  walletTransactions?: Maybe<WalletTransactionPaginator>
}

export type QueryCourseByIdArgs = {
  id: Scalars['ID']
}

export type QueryCourseRequestByIdArgs = {
  id: Scalars['ID']
}

export type QueryTodayCourseArgs = {
  student?: Maybe<Scalars['ID']>
}

export type QueryTodayCoursesArgs = {
  student?: Maybe<Scalars['ID']>
}

export type QueryClassMarketByDateArgs = {
  type: CourseType
  date: Scalars['Date']
  minimum_price: Scalars['Int']
}

export type QueryLabelsArgs = {
  localeFilters?: Maybe<LocaleFilters>
}

export type QueryLocaleByIdArgs = {
  id: Scalars['ID']
}

export type QueryLocaleByIsoArgs = {
  iso: Scalars['String']
}

export type QueryJoinRoomArgs = {
  room: Scalars['String']
  student?: Maybe<Scalars['ID']>
}

export type QueryStripeAccountLinkArgs = {
  return_url?: Maybe<Scalars['String']>
  refresh_url?: Maybe<Scalars['String']>
}

export type QueryStripeLoginLinkArgs = {
  redirect_url?: Maybe<Scalars['String']>
}

export type QueryTeacherByIdArgs = {
  id: Scalars['ID']
}

export type QueryTeacherAvailabilitiesOfMonthArgs = {
  month: Scalars['Int']
  year: Scalars['Int']
}

export type QueryTeacherRecurrenceByIdArgs = {
  id: Scalars['ID']
}

export type QueryTeacherPendingCoursesArgs = {
  type: CourseType
  first: Scalars['Int']
  page?: Maybe<Scalars['Int']>
}

export type QueryFutureCoursesArgs = {
  student?: Maybe<Scalars['ID']>
  first: Scalars['Int']
  page?: Maybe<Scalars['Int']>
}

export type QueryPastCoursesArgs = {
  exclude_non_evaluated?: Maybe<Scalars['Boolean']>
  student?: Maybe<Scalars['ID']>
  discipline?: Maybe<Scalars['ID']>
  orderBy?: Maybe<Array<QueryPastCoursesOrderByOrderByClause>>
  first: Scalars['Int']
  page?: Maybe<Scalars['Int']>
}

export type QueryPendingCoursesArgs = {
  student?: Maybe<Scalars['ID']>
  first: Scalars['Int']
  page?: Maybe<Scalars['Int']>
}

export type QueryCoursesWithMaterialArgs = {
  student?: Maybe<Scalars['ID']>
  discipline?: Maybe<Scalars['ID']>
  grade?: Maybe<Scalars['ID']>
  orderBy?: Maybe<Array<QueryCoursesWithMaterialOrderByOrderByClause>>
  first: Scalars['Int']
  page?: Maybe<Scalars['Int']>
}

export type QueryPendingCourseRequestsArgs = {
  student?: Maybe<Scalars['ID']>
  first: Scalars['Int']
  page?: Maybe<Scalars['Int']>
}

export type QueryPendingReviewCoursesArgs = {
  first: Scalars['Int']
  page?: Maybe<Scalars['Int']>
}

export type QuerySearchCourseArgs = {
  lesson: Scalars['ID']
  student?: Maybe<Scalars['ID']>
  teacher?: Maybe<Scalars['ID']>
  type: CourseType
  maximum_price: Scalars['Int']
  maximum_participants: Scalars['Int']
  minimum_score?: Maybe<Scalars['Float']>
  availabilities?: Maybe<Array<AvailabilityInput>>
  first: Scalars['Int']
  page?: Maybe<Scalars['Int']>
}

export type QueryClassMarketByDateAndSlotArgs = {
  type: CourseType
  date: Scalars['Date']
  slot: AvailabilitySlot
  minimum_price: Scalars['Int']
  first: Scalars['Int']
  page?: Maybe<Scalars['Int']>
}

export type QueryNotificationsArgs = {
  first: Scalars['Int']
  page?: Maybe<Scalars['Int']>
}

export type QueryTeacherRecurrencesArgs = {
  first: Scalars['Int']
  page?: Maybe<Scalars['Int']>
}

export type QueryThemesArgs = {
  discipline: Scalars['ID']
  grade: Scalars['ID']
  first: Scalars['Int']
  page?: Maybe<Scalars['Int']>
}

export type QueryBookingTransactionsArgs = {
  limit?: Maybe<TransactionLimitFilter>
  first: Scalars['Int']
  page?: Maybe<Scalars['Int']>
}

export type QueryWalletTransactionsArgs = {
  limit?: Maybe<TransactionLimitFilter>
  first: Scalars['Int']
  page?: Maybe<Scalars['Int']>
}

/** Allowed column names for Query.coursesWithMaterial.orderBy. */
export enum QueryCoursesWithMaterialOrderByColumn {
  StartAt = 'START_AT',
}

/** Order by clause for Query.coursesWithMaterial.orderBy. */
export type QueryCoursesWithMaterialOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: QueryCoursesWithMaterialOrderByColumn
  /** The direction that is used for ordering. */
  order: SortOrder
}

/** Allowed column names for Query.pastCourses.orderBy. */
export enum QueryPastCoursesOrderByColumn {
  StartAt = 'START_AT',
}

/** Order by clause for Query.pastCourses.orderBy. */
export type QueryPastCoursesOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: QueryPastCoursesOrderByColumn
  /** The direction that is used for ordering. */
  order: SortOrder
}

export enum RecurrenceDay {
  /** Dimanche */
  Sunday = 'SUNDAY',
  /** Lundi */
  Monday = 'MONDAY',
  /** Mardi */
  Tuesday = 'TUESDAY',
  /** Mercredi */
  Wednesday = 'WEDNESDAY',
  /** Jeudi */
  Thursday = 'THURSDAY',
  /** Vendredi */
  Friday = 'FRIDAY',
  /** Samedi */
  Saturday = 'SATURDAY',
}

export type Room = {
  __typename?: 'Room'
  name: Scalars['String']
  hash: Scalars['String']
  token?: Maybe<Scalars['String']>
  settings?: Maybe<SettingVisio>
  model?: Maybe<RoomMorph>
  available_at: Scalars['DateTime']
  expires_at: Scalars['DateTime']
  available: Scalars['Boolean']
  expired: Scalars['Boolean']
}

export type RoomMorph = Course

export type Setting = {
  __typename?: 'Setting'
  contact?: Maybe<SettingContact>
  links?: Maybe<SettingLinks>
  visio?: Maybe<SettingVisio>
}

export type SettingContact = {
  __typename?: 'SettingContact'
  email?: Maybe<Scalars['String']>
}

export type SettingLinks = {
  __typename?: 'SettingLinks'
  cgu?: Maybe<Scalars['String']>
  privacy?: Maybe<Scalars['String']>
}

export type SettingVisio = {
  __typename?: 'SettingVisio'
  config?: Maybe<Scalars['JSON']>
  interface_config?: Maybe<Scalars['JSON']>
  recommendation?: Maybe<Scalars['String']>
}

/** Information about pagination using a simple paginator. */
export type SimplePaginatorInfo = {
  __typename?: 'SimplePaginatorInfo'
  /** Number of items in the current page. */
  count: Scalars['Int']
  /** Index of the current page. */
  currentPage: Scalars['Int']
  /** Index of the first item in the current page. */
  firstItem?: Maybe<Scalars['Int']>
  /** Index of the last item in the current page. */
  lastItem?: Maybe<Scalars['Int']>
  /** Number of items per page. */
  perPage: Scalars['Int']
  /** Are there more pages after this one? */
  hasMorePages: Scalars['Boolean']
}

export type SocialProvider = {
  __typename?: 'SocialProvider'
  provider_type: SocialProviderType
  provider_id?: Maybe<Scalars['String']>
}

export enum SocialProviderType {
  /** Apple */
  Apple = 'APPLE',
  /** Google */
  Google = 'GOOGLE',
}

/** Directions for ordering a list of records. */
export enum SortOrder {
  /** Sort records in ascending order. */
  Asc = 'ASC',
  /** Sort records in descending order. */
  Desc = 'DESC',
}

export type Student = {
  __typename?: 'Student'
  id: Scalars['ID']
  school?: Maybe<Scalars['String']>
  grade?: Maybe<Grade>
  guardian?: Maybe<PublicGuardian>
  /** User attributes */
  first_name?: Maybe<Scalars['String']>
  last_name?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  status?: Maybe<UserStatus>
  avatar?: Maybe<Scalars['String']>
  score?: Maybe<Scalars['Float']>
  punctuality?: Maybe<Scalars['Float']>
  behaviour?: Maybe<Scalars['Float']>
  interactivity?: Maybe<Scalars['Float']>
  understanding?: Maybe<Scalars['Float']>
  working_conditions?: Maybe<Scalars['Float']>
  deletable?: Maybe<Scalars['Boolean']>
}

export type StudentEvaluationInput = {
  student: Scalars['ID']
  punctuality: Scalars['Int']
  behaviour: Scalars['Int']
  interactivity: Scalars['Int']
  understanding: Scalars['Int']
  working_conditions: Scalars['Int']
  comment?: Maybe<Scalars['String']>
  mark_as_unwanted?: Maybe<Scalars['Boolean']>
}

export type StudentReview = {
  __typename?: 'StudentReview'
  id: Scalars['ID']
  student: PublicStudent
  punctuality: Scalars['Int']
  behaviour: Scalars['Int']
  interactivity: Scalars['Int']
  understanding: Scalars['Int']
  working_conditions: Scalars['Int']
  score: Scalars['Float']
  comment?: Maybe<Scalars['String']>
  mark_as_unwanted?: Maybe<Scalars['Boolean']>
}

export enum TagType {
  /** Niveau */
  Grade = 'GRADE',
}

export type Teacher = {
  __typename?: 'Teacher'
  id: Scalars['ID']
  siret?: Maybe<Scalars['String']>
  cv?: Maybe<TeacherDocument>
  diploma?: Maybe<TeacherDocument>
  company_certificate?: Maybe<TeacherDocument>
  introduction?: Maybe<Scalars['String']>
  maximum_participants: Scalars['Int']
  price?: Maybe<Scalars['Int']>
  stripe_details_submitted?: Maybe<Scalars['Boolean']>
  stripe_charges_activated?: Maybe<Scalars['Boolean']>
  stripe_payouts_activated?: Maybe<Scalars['Boolean']>
  payouts_schedule_interval: PayoutScheduleInterval
  card_brand?: Maybe<Scalars['Int']>
  card_last_four?: Maybe<Scalars['Int']>
  disciplines?: Maybe<Array<TeacherDiscipline>>
}

export type TeacherAvailability = {
  __typename?: 'TeacherAvailability'
  id: Scalars['ID']
  date: Scalars['Date']
  slot: AvailabilitySlot
  recurrence?: Maybe<TeacherAvailabilityRecurrence>
}

export type TeacherAvailabilityRecurrence = {
  __typename?: 'TeacherAvailabilityRecurrence'
  id: Scalars['ID']
  start_at: Scalars['Date']
  end_at: Scalars['Date']
  days: Array<RecurrenceDay>
  slots?: Maybe<Array<AvailabilitySlot>>
  availabilities?: Maybe<Array<TeacherAvailability>>
}

/** A paginated list of TeacherAvailabilityRecurrence items. */
export type TeacherAvailabilityRecurrencePaginator = {
  __typename?: 'TeacherAvailabilityRecurrencePaginator'
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo
  /** A list of TeacherAvailabilityRecurrence items. */
  data: Array<TeacherAvailabilityRecurrence>
}

export type TeacherDiscipline = {
  __typename?: 'TeacherDiscipline'
  id: Scalars['ID']
  price?: Maybe<Scalars['Int']>
  discipline: Discipline
  grade: Grade
}

export type TeacherDisciplineInput = {
  price?: Maybe<Scalars['Int']>
  discipline: Scalars['ID']
  grade: Scalars['ID']
}

export type TeacherDocument = {
  __typename?: 'TeacherDocument'
  id: Scalars['ID']
  type?: Maybe<TeacherDocumentType>
  name: Scalars['String']
  file: Scalars['String']
  status?: Maybe<TeacherDocumentStatus>
}

export enum TeacherDocumentStatus {
  /** Accepté */
  Accepted = 'ACCEPTED',
  /** En attente */
  Pending = 'PENDING',
  /** Refusé */
  Refused = 'REFUSED',
}

export enum TeacherDocumentType {
  /** Curriculum vitæ */
  Cv = 'CV',
  /** Diplôme */
  Diploma = 'DIPLOMA',
  /** Certificat d'immatriculation de micro entreprise */
  CompanyCertificate = 'COMPANY_CERTIFICATE',
}

export type Theme = {
  __typename?: 'Theme'
  id: Scalars['ID']
  name: Scalars['String']
  slug: Scalars['String']
  discipline: Discipline
  grade: Grade
  lessons?: Maybe<Array<Lesson>>
}

/** A paginated list of Theme items. */
export type ThemePaginator = {
  __typename?: 'ThemePaginator'
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo
  /** A list of Theme items. */
  data: Array<Theme>
}

export type TimesStamps = {
  created_at?: Maybe<Scalars['DateTime']>
  updated_at?: Maybe<Scalars['DateTime']>
}

export enum TransactionLimitFilter {
  /** labels.transaction_limit_filter.month */
  Month = 'MONTH',
  /** labels.transaction_limit_filter.quarter */
  Quarter = 'QUARTER',
}

/** Specify if you want to include or exclude trashed results from a query. */
export enum Trashed {
  /** Only return trashed results. */
  Only = 'ONLY',
  /** Return both trashed and non-trashed results. */
  With = 'WITH',
  /** Only return non-trashed results. */
  Without = 'WITHOUT',
}

export type Upload = {
  file: Scalars['String']
  name: Scalars['String']
}

export type User = {
  __typename?: 'User'
  id: Scalars['ID']
  token?: Maybe<Scalars['String']>
  user_type?: Maybe<UserType>
  first_name?: Maybe<Scalars['String']>
  last_name?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  phone?: Maybe<Scalars['String']>
  display_address?: Maybe<Scalars['String']>
  address?: Maybe<Scalars['String']>
  city?: Maybe<Scalars['String']>
  zip_code?: Maybe<Scalars['String']>
  country?: Maybe<Scalars['String']>
  status: UserStatus
  socialProviders?: Maybe<Array<SocialProvider>>
  avatar?: Maybe<Scalars['String']>
  guardian?: Maybe<Guardian>
  student?: Maybe<Student>
  teacher?: Maybe<Teacher>
  wallet?: Maybe<Wallet>
  unread_notifications: Scalars['Int']
}

export enum UserStatus {
  /** Actif */
  Active = 'ACTIVE',
  /** Pré-inscription */
  PreRegistered = 'PRE_REGISTERED',
  /** Inscription */
  Registered = 'REGISTERED',
  /** Inscription social connect */
  RegisteredSocial = 'REGISTERED_SOCIAL',
  /** Inscription étape 1 */
  RegisteredStep_1 = 'REGISTERED_STEP_1',
  /** Inscription étape 2 */
  RegisteredStep_2 = 'REGISTERED_STEP_2',
  /** Inscription confirmée */
  RegisteredTeacherConfirmed = 'REGISTERED_TEACHER_CONFIRMED',
  /** Inscription en attente */
  RegisteredTeacherPending = 'REGISTERED_TEACHER_PENDING',
  /** Inscription refusée */
  RegisteredTeacherRefused = 'REGISTERED_TEACHER_REFUSED',
  /** Élève sans accès */
  StudentNoAccess = 'STUDENT_NO_ACCESS',
  /** Compte vérifié */
  Verified = 'VERIFIED',
  /** Enseignant prix renseignés */
  VerifiedTeacherPrice = 'VERIFIED_TEACHER_PRICE',
}

export enum UserType {
  /** Parent */
  Guardian = 'GUARDIAN',
  /** Élève */
  Student = 'STUDENT',
  /** Enseignant */
  Teacher = 'TEACHER',
}

export type Wallet = {
  __typename?: 'Wallet'
  amount: Scalars['Int']
  reserved: Scalars['Int']
  refund: Scalars['Int']
}

export type WalletModel = Course | CourseRequest | Payment

export type WalletRefund = {
  __typename?: 'WalletRefund'
  amount: Scalars['Int']
  status: WalletRefundStatus
}

export enum WalletRefundStatus {
  /** Annulé */
  Cancelled = 'CANCELLED',
  /** Exécuté */
  Executed = 'EXECUTED',
  /** En attente */
  Pending = 'PENDING',
}

export type WalletTransaction = {
  __typename?: 'WalletTransaction'
  type: WalletTransactionType
  amount: Scalars['Int']
  model?: Maybe<WalletModel>
  student?: Maybe<PublicStudent>
  created_at: Scalars['DateTime']
}

/** A paginated list of WalletTransaction items. */
export type WalletTransactionPaginator = {
  __typename?: 'WalletTransactionPaginator'
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo
  /** A list of WalletTransaction items. */
  data: Array<WalletTransaction>
}

export enum WalletTransactionType {
  /** Ajout de crédits */
  AddCredits = 'ADD_CREDITS',
  /** Annulation demande/cours */
  CancelRequest = 'CANCEL_REQUEST',
  /** Cours annulé */
  CourseCancelled = 'COURSE_CANCELLED',
  /** Transfert paiement */
  CoursePayment = 'COURSE_PAYMENT',
  /** Paiement d'un cours */
  PayCourse = 'PAY_COURSE',
  /** Retrait annulé */
  RefundCancelled = 'REFUND_CANCELLED',
  /** Retrait exécuté */
  RefundExecuted = 'REFUND_EXECUTED',
  /** Ajustement fonds bloqués */
  RefundRest = 'REFUND_REST',
  /** Demande de cours */
  RequestCourse = 'REQUEST_COURSE',
  /** Demande de retrait */
  RequestRefund = 'REQUEST_REFUND',
  /** Cours en attente de paiement */
  UpcomingCourse = 'UPCOMING_COURSE',
  /** Avoir après litige */
  CreditNote = 'CREDIT_NOTE',
}

export type AvailabilityFragmentFragment = { __typename?: 'Availability' } & Pick<
  Availability,
  'date' | 'slot'
>

export type ClassMarketSlotFragmentFragment = { __typename?: 'ClassMarketSlot' } & Pick<
  ClassMarketSlot,
  'available' | 'count' | 'slot'
>

export type CourseFragmentFragment = { __typename?: 'Course' } & Pick<
  Course,
  | 'id'
  | 'type'
  | 'status'
  | 'date'
  | 'slot'
  | 'maximum_price'
  | 'maximum_participants'
  | 'price_per_participant'
  | 'participants'
  | 'teacher_price'
> & {
    room?: Maybe<
      { __typename?: 'Room' } & Pick<
        Room,
        'available_at' | 'available' | 'expired' | 'name' | 'hash'
      >
    >
    lesson?: Maybe<{ __typename?: 'Lesson' } & LessonFragmentFragment>
    teacher?: Maybe<{ __typename?: 'PublicTeacher' } & PublicTeacherFragmentFragment>
    requests?: Maybe<Array<{ __typename?: 'CourseRequest' } & CourseRequestFragmentFragment>>
    studentReviews?: Maybe<Array<{ __typename?: 'StudentReview' } & StudentReviewFragmentFragment>>
  }

export type CourseRequestFragmentFragment = { __typename?: 'CourseRequest' } & Pick<
  CourseRequest,
  | 'id'
  | 'type'
  | 'maximum_price'
  | 'maximum_participants'
  | 'minimum_score'
  | 'notice_period'
  | 'created_at'
  | 'updated_at'
> & {
    lesson: { __typename?: 'Lesson' } & LessonFragmentFragment
    availabilities?: Maybe<Array<{ __typename?: 'Availability' } & AvailabilityFragmentFragment>>
    student: { __typename?: 'PublicStudent' } & Pick<PublicStudent, 'id' | 'full_name' | 'avatar'>
  }

export type DisciplineFragmentFragment = { __typename?: 'Discipline' } & Pick<
  Discipline,
  'id' | 'name' | 'slug'
>

export type GradeFragmentFragment = { __typename?: 'Grade' } & Pick<
  Grade,
  'id' | 'name' | 'slug' | 'tag'
>

export type GuardianFragmentFragment = { __typename?: 'Guardian' } & Pick<
  Guardian,
  'code' | 'id'
> & { children?: Maybe<Array<{ __typename?: 'Student' } & StudentFragmentFragment>> }

export type LessonFragmentFragment = { __typename?: 'Lesson' } & Pick<
  Lesson,
  'id' | 'name' | 'slug' | 'description' | 'type'
> & {
    theme?: Maybe<{ __typename?: 'Theme' } & ThemeFragmentFragment>
    supports?: Maybe<
      Array<
        { __typename?: 'Media' } & Pick<
          Media,
          'file_name' | 'human_readable_size' | 'mime_type' | 'url'
        >
      >
    >
  }

export type NotificationFragmentFragment = { __typename?: 'Notification' } & Pick<
  Notification,
  'created_at' | 'id' | 'payload' | 'read_at' | 'type'
> & {
    course?: Maybe<
      { __typename?: 'Course' } & Pick<Course, 'id' | 'date' | 'slot'> & {
          lesson?: Maybe<{ __typename?: 'Lesson' } & LessonFragmentFragment>
          teacher?: Maybe<{ __typename?: 'PublicTeacher' } & Pick<PublicTeacher, 'full_name'>>
        }
    >
    student?: Maybe<{ __typename?: 'Student' } & Pick<Student, 'id' | 'first_name' | 'avatar'>>
  }

export type PaginatorInfoFragmentFragment = { __typename?: 'PaginatorInfo' } & Pick<
  PaginatorInfo,
  | 'count'
  | 'currentPage'
  | 'firstItem'
  | 'hasMorePages'
  | 'lastItem'
  | 'lastPage'
  | 'perPage'
  | 'total'
>

export type PublicStudentFragmentFragment = { __typename?: 'PublicStudent' } & Pick<
  PublicStudent,
  'id' | 'full_name' | 'avatar'
>

export type PublicTeacherDisciplineFragmentFragment = { __typename?: 'PublicTeacherDiscipline' } & {
  discipline: { __typename?: 'Discipline' } & DisciplineFragmentFragment
  grade: { __typename?: 'Grade' } & GradeFragmentFragment
}

export type PublicTeacherFragmentFragment = { __typename?: 'PublicTeacher' } & Pick<
  PublicTeacher,
  'id' | 'full_name' | 'avatar'
>

export type StudentFragmentFragment = { __typename?: 'Student' } & Pick<
  Student,
  | 'avatar'
  | 'email'
  | 'first_name'
  | 'id'
  | 'last_name'
  | 'school'
  | 'status'
  | 'behaviour'
  | 'interactivity'
  | 'punctuality'
  | 'score'
  | 'understanding'
  | 'working_conditions'
  | 'deletable'
> & {
    grade?: Maybe<{ __typename?: 'Grade' } & Pick<Grade, 'id' | 'name'>>
    guardian?: Maybe<
      { __typename?: 'PublicGuardian' } & Pick<
        PublicGuardian,
        'id' | 'full_name' | 'avatar' | 'email'
      >
    >
  }

export type StudentReviewFragmentFragment = { __typename?: 'StudentReview' } & Pick<
  StudentReview,
  | 'id'
  | 'punctuality'
  | 'behaviour'
  | 'interactivity'
  | 'understanding'
  | 'working_conditions'
  | 'score'
  | 'comment'
  | 'mark_as_unwanted'
> & { student: { __typename?: 'PublicStudent' } & PublicStudentFragmentFragment }

export type TeacherAvailabilityFragmentFragment = { __typename?: 'TeacherAvailability' } & Pick<
  TeacherAvailability,
  'date' | 'slot'
> & {
    recurrence?: Maybe<
      { __typename?: 'TeacherAvailabilityRecurrence' } & Pick<TeacherAvailabilityRecurrence, 'id'>
    >
  }

export type TeacherAvailabilityRecurrenceFragmentFragment = {
  __typename?: 'TeacherAvailabilityRecurrence'
} & Pick<TeacherAvailabilityRecurrence, 'id' | 'slots' | 'start_at' | 'end_at' | 'days'> & {
    availabilities?: Maybe<
      Array<{ __typename?: 'TeacherAvailability' } & TeacherAvailabilityFragmentFragment>
    >
  }

export type TeacherDisciplineFragmentFragment = { __typename?: 'TeacherDiscipline' } & Pick<
  TeacherDiscipline,
  'price'
> & {
    discipline: { __typename?: 'Discipline' } & DisciplineFragmentFragment
    grade: { __typename?: 'Grade' } & GradeFragmentFragment
  }

export type TeacherDocumentFragmentFragment = { __typename?: 'TeacherDocument' } & Pick<
  TeacherDocument,
  'name' | 'file' | 'status'
>

export type TeacherFragmentFragment = { __typename?: 'Teacher' } & Pick<
  Teacher,
  | 'id'
  | 'siret'
  | 'introduction'
  | 'price'
  | 'maximum_participants'
  | 'stripe_details_submitted'
  | 'stripe_charges_activated'
  | 'stripe_payouts_activated'
  | 'card_brand'
  | 'card_last_four'
  | 'payouts_schedule_interval'
> & {
    cv?: Maybe<{ __typename?: 'TeacherDocument' } & TeacherDocumentFragmentFragment>
    diploma?: Maybe<{ __typename?: 'TeacherDocument' } & TeacherDocumentFragmentFragment>
    company_certificate?: Maybe<
      { __typename?: 'TeacherDocument' } & TeacherDocumentFragmentFragment
    >
    disciplines?: Maybe<
      Array<{ __typename?: 'TeacherDiscipline' } & TeacherDisciplineFragmentFragment>
    >
  }

export type ThemeFragmentFragment = { __typename?: 'Theme' } & Pick<
  Theme,
  'id' | 'name' | 'slug'
> & {
    discipline: { __typename?: 'Discipline' } & DisciplineFragmentFragment
    grade: { __typename?: 'Grade' } & GradeFragmentFragment
  }

export type UserFragmentFragment = { __typename?: 'User' } & Pick<
  User,
  | 'id'
  | 'token'
  | 'user_type'
  | 'first_name'
  | 'last_name'
  | 'email'
  | 'phone'
  | 'display_address'
  | 'address'
  | 'city'
  | 'zip_code'
  | 'status'
  | 'avatar'
  | 'unread_notifications'
> & {
    teacher?: Maybe<{ __typename?: 'Teacher' } & TeacherFragmentFragment>
    guardian?: Maybe<{ __typename?: 'Guardian' } & GuardianFragmentFragment>
    student?: Maybe<{ __typename?: 'Student' } & StudentFragmentFragment>
    wallet?: Maybe<{ __typename?: 'Wallet' } & WalletFragmentFragment>
  }

export type WalletFragmentFragment = { __typename?: 'Wallet' } & Pick<
  Wallet,
  'amount' | 'reserved' | 'refund'
>

export type WalletTransactionFragmentFragment = { __typename?: 'WalletTransaction' } & Pick<
  WalletTransaction,
  'type' | 'amount' | 'created_at'
> & {
    student?: Maybe<
      { __typename?: 'PublicStudent' } & Pick<PublicStudent, 'id' | 'avatar' | 'full_name'>
    >
    model?: Maybe<
      | ({ __typename?: 'Course' } & Pick<
          Course,
          'id' | 'date' | 'slot' | 'type' | 'participants'
        > & {
            lesson?: Maybe<
              { __typename?: 'Lesson' } & {
                theme?: Maybe<
                  { __typename?: 'Theme' } & {
                    discipline: { __typename?: 'Discipline' } & Pick<Discipline, 'name'>
                    grade: { __typename?: 'Grade' } & Pick<Grade, 'name'>
                  }
                >
              }
            >
            requests?: Maybe<
              Array<
                { __typename?: 'CourseRequest' } & {
                  student: { __typename?: 'PublicStudent' } & Pick<PublicStudent, 'id'>
                }
              >
            >
          })
      | ({ __typename?: 'CourseRequest' } & Pick<
          CourseRequest,
          'id' | 'created_at' | 'maximum_price' | 'type' | 'maximum_participants'
        > & {
            requestLesson: { __typename?: 'Lesson' } & {
              theme?: Maybe<
                { __typename?: 'Theme' } & {
                  discipline: { __typename?: 'Discipline' } & Pick<Discipline, 'name'>
                  grade: { __typename?: 'Grade' } & Pick<Grade, 'name'>
                }
              >
            }
            student: { __typename?: 'PublicStudent' } & Pick<PublicStudent, 'id'>
          })
      | ({ __typename?: 'Payment' } & Pick<Payment, 'id' | 'status'>)
    >
  }

export type AcceptCourseMutationVariables = Exact<{
  course: Scalars['ID']
}>

export type AcceptCourseMutation = { __typename?: 'Mutation' } & {
  acceptCourse: { __typename?: 'Course' } & CourseFragmentFragment
}

export type ApplyToCourseMutationVariables = Exact<{
  course: Scalars['ID']
  maximum_participants: Scalars['Int']
  student?: Maybe<Scalars['ID']>
  teacher?: Maybe<Scalars['ID']>
  date?: Maybe<Scalars['Date']>
  slot?: Maybe<AvailabilitySlot>
}>

export type ApplyToCourseMutation = { __typename?: 'Mutation' } & {
  applyToCourse: { __typename?: 'Course' } & CourseFragmentFragment
}

export type CancelCourseMutationVariables = Exact<{
  course: Scalars['ID']
  reason: CourseCancelReason
  text: Scalars['String']
  file?: Maybe<Upload>
}>

export type CancelCourseMutation = { __typename?: 'Mutation' } & {
  cancelCourse: { __typename?: 'Course' } & CourseFragmentFragment
}

export type CancelCourseRequestMutationVariables = Exact<{
  request: Scalars['ID']
}>

export type CancelCourseRequestMutation = { __typename?: 'Mutation' } & {
  cancelCourseRequest: { __typename?: 'CourseRequest' } & CourseRequestFragmentFragment
}

export type CancelPendingCourseMutationVariables = Exact<{
  course: Scalars['ID']
}>

export type CancelPendingCourseMutation = { __typename?: 'Mutation' } & {
  cancelPendingCourse: { __typename?: 'Course' } & CourseFragmentFragment
}

export type CreateCourseMutationVariables = Exact<{
  lesson: Scalars['ID']
  student?: Maybe<Scalars['ID']>
  type: CourseType
  maximum_participants: Scalars['Int']
  teacher: Scalars['ID']
  date: Scalars['Date']
  slot: AvailabilitySlot
}>

export type CreateCourseMutation = { __typename?: 'Mutation' } & {
  createCourse: { __typename?: 'Course' } & CourseFragmentFragment
}

export type DeclineCourseMutationVariables = Exact<{
  course: Scalars['ID']
}>

export type DeclineCourseMutation = { __typename?: 'Mutation' } & {
  declineCourse: { __typename?: 'Course' } & CourseFragmentFragment
}

export type EvaluateStudentsMutationVariables = Exact<{
  course: Scalars['ID']
  evaluations: Array<StudentEvaluationInput> | StudentEvaluationInput
}>

export type EvaluateStudentsMutation = { __typename?: 'Mutation' } & {
  evaluateStudents: { __typename?: 'Course' } & CourseFragmentFragment
}

export type PickCourseOfMarketMutationVariables = Exact<{
  course: Scalars['ID']
  date: Scalars['Date']
  slot: AvailabilitySlot
}>

export type PickCourseOfMarketMutation = { __typename?: 'Mutation' } & {
  pickCourseOfMarket: { __typename?: 'Course' } & CourseFragmentFragment
}

export type RequestCourseMutationVariables = Exact<{
  lesson: Scalars['ID']
  student?: Maybe<Scalars['ID']>
  type: CourseType
  maximum_price: Scalars['Int']
  maximum_participants: Scalars['Int']
  minimum_score?: Maybe<Scalars['Float']>
  notice_period: Scalars['Int']
  availabilities: Array<AvailabilityInput> | AvailabilityInput
}>

export type RequestCourseMutation = { __typename?: 'Mutation' } & {
  requestCourse: { __typename?: 'CourseRequest' } & CourseRequestFragmentFragment
}

export type ClassMarketByDateQueryVariables = Exact<{
  type: CourseType
  date: Scalars['Date']
  minimum_price: Scalars['Int']
}>

export type ClassMarketByDateQuery = { __typename?: 'Query' } & {
  classMarketByDate?: Maybe<
    Array<{ __typename?: 'ClassMarketSlot' } & ClassMarketSlotFragmentFragment>
  >
}

export type ClassMarketByDateAndSlotQueryVariables = Exact<{
  type: CourseType
  date: Scalars['Date']
  slot: AvailabilitySlot
  minimum_price: Scalars['Int']
}>

export type ClassMarketByDateAndSlotQuery = { __typename?: 'Query' } & {
  classMarketByDateAndSlot?: Maybe<
    { __typename?: 'CoursePaginator' } & {
      data: Array<{ __typename?: 'Course' } & CourseFragmentFragment>
      paginatorInfo: { __typename?: 'PaginatorInfo' } & PaginatorInfoFragmentFragment
    }
  >
}

export type CourseByIdQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type CourseByIdQuery = { __typename?: 'Query' } & {
  courseById?: Maybe<{ __typename?: 'Course' } & CourseFragmentFragment>
}

export type CourseRequestByIdQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type CourseRequestByIdQuery = { __typename?: 'Query' } & {
  courseRequestById?: Maybe<{ __typename?: 'CourseRequest' } & CourseRequestFragmentFragment>
}

export type CoursesWithMaterialQueryVariables = Exact<{
  first: Scalars['Int']
  page?: Maybe<Scalars['Int']>
  student?: Maybe<Scalars['ID']>
  discipline?: Maybe<Scalars['ID']>
  grade?: Maybe<Scalars['ID']>
  orderBy?: Maybe<
    | Array<QueryCoursesWithMaterialOrderByOrderByClause>
    | QueryCoursesWithMaterialOrderByOrderByClause
  >
}>

export type CoursesWithMaterialQuery = { __typename?: 'Query' } & {
  coursesWithMaterial?: Maybe<
    { __typename?: 'CoursePaginator' } & {
      data: Array<{ __typename?: 'Course' } & CourseFragmentFragment>
      paginatorInfo: { __typename?: 'PaginatorInfo' } & PaginatorInfoFragmentFragment
    }
  >
}

export type DashboardCoursesQueryVariables = Exact<{
  student?: Maybe<Scalars['ID']>
}>

export type DashboardCoursesQuery = { __typename?: 'Query' } & {
  todayCourse?: Maybe<{ __typename?: 'Course' } & CourseFragmentFragment>
  futureCourses?: Maybe<
    { __typename?: 'CoursePaginator' } & {
      data: Array<{ __typename?: 'Course' } & CourseFragmentFragment>
      paginatorInfo: { __typename?: 'PaginatorInfo' } & Pick<PaginatorInfo, 'total'>
    }
  >
  pendingCourses?: Maybe<
    { __typename?: 'CoursePaginator' } & {
      data: Array<{ __typename?: 'Course' } & CourseFragmentFragment>
      paginatorInfo: { __typename?: 'PaginatorInfo' } & Pick<PaginatorInfo, 'total'>
    }
  >
  pendingCourseRequests?: Maybe<
    { __typename?: 'CourseRequestPaginator' } & {
      data: Array<{ __typename?: 'CourseRequest' } & CourseRequestFragmentFragment>
      paginatorInfo: { __typename?: 'PaginatorInfo' } & Pick<PaginatorInfo, 'total'>
    }
  >
  pastCourses?: Maybe<
    { __typename?: 'CoursePaginator' } & {
      data: Array<{ __typename?: 'Course' } & CourseFragmentFragment>
      paginatorInfo: { __typename?: 'PaginatorInfo' } & Pick<PaginatorInfo, 'total'>
    }
  >
}

export type PendingReviewCoursesQueryVariables = Exact<{ [key: string]: never }>

export type PendingReviewCoursesQuery = { __typename?: 'Query' } & {
  pendingReviewCourses?: Maybe<
    { __typename?: 'CoursePaginator' } & {
      data: Array<{ __typename?: 'Course' } & CourseFragmentFragment>
      paginatorInfo: { __typename?: 'PaginatorInfo' } & PaginatorInfoFragmentFragment
    }
  >
}

export type PlanningCoursesQueryVariables = Exact<{
  student?: Maybe<Scalars['ID']>
}>

export type PlanningCoursesQuery = { __typename?: 'Query' } & {
  futureCourses?: Maybe<
    { __typename?: 'CoursePaginator' } & {
      data: Array<{ __typename?: 'Course' } & CourseFragmentFragment>
      paginatorInfo: { __typename?: 'PaginatorInfo' } & Pick<PaginatorInfo, 'total'>
    }
  >
  pastCourses?: Maybe<
    { __typename?: 'CoursePaginator' } & {
      data: Array<{ __typename?: 'Course' } & CourseFragmentFragment>
      paginatorInfo: { __typename?: 'PaginatorInfo' } & Pick<PaginatorInfo, 'total'>
    }
  >
  pendingCourses?: Maybe<
    { __typename?: 'CoursePaginator' } & {
      data: Array<{ __typename?: 'Course' } & CourseFragmentFragment>
      paginatorInfo: { __typename?: 'PaginatorInfo' } & Pick<PaginatorInfo, 'total'>
    }
  >
  pendingCourseRequests?: Maybe<
    { __typename?: 'CourseRequestPaginator' } & {
      data: Array<{ __typename?: 'CourseRequest' } & CourseRequestFragmentFragment>
      paginatorInfo: { __typename?: 'PaginatorInfo' } & Pick<PaginatorInfo, 'total'>
    }
  >
}

export type SearchCourseQueryVariables = Exact<{
  first: Scalars['Int']
  page?: Maybe<Scalars['Int']>
  lesson: Scalars['ID']
  student?: Maybe<Scalars['ID']>
  teacher?: Maybe<Scalars['ID']>
  type: CourseType
  maximum_price: Scalars['Int']
  maximum_participants: Scalars['Int']
  minimum_score?: Maybe<Scalars['Float']>
  availabilities?: Maybe<Array<AvailabilityInput> | AvailabilityInput>
}>

export type SearchCourseQuery = { __typename?: 'Query' } & {
  searchCourse?: Maybe<
    { __typename?: 'CoursePaginator' } & {
      data: Array<{ __typename?: 'Course' } & CourseFragmentFragment>
      paginatorInfo: { __typename?: 'PaginatorInfo' } & PaginatorInfoFragmentFragment
    }
  >
}

export type StudentDisabledDatesQueryVariables = Exact<{
  student?: Maybe<Scalars['ID']>
}>

export type StudentDisabledDatesQuery = { __typename?: 'Query' } & {
  futureCourses?: Maybe<
    { __typename?: 'CoursePaginator' } & {
      data: Array<{ __typename?: 'Course' } & Pick<Course, 'date' | 'slot'>>
    }
  >
  pendingCourses?: Maybe<
    { __typename?: 'CoursePaginator' } & {
      data: Array<{ __typename?: 'Course' } & Pick<Course, 'date' | 'slot'>>
    }
  >
}

export type TeacherDashboardCoursesQueryVariables = Exact<{ [key: string]: never }>

export type TeacherDashboardCoursesQuery = { __typename?: 'Query' } & {
  todayCourse?: Maybe<{ __typename?: 'Course' } & CourseFragmentFragment>
  futureCourses?: Maybe<
    { __typename?: 'CoursePaginator' } & {
      data: Array<{ __typename?: 'Course' } & CourseFragmentFragment>
      paginatorInfo: { __typename?: 'PaginatorInfo' } & Pick<PaginatorInfo, 'total'>
    }
  >
  pastCourses?: Maybe<
    { __typename?: 'CoursePaginator' } & {
      data: Array<{ __typename?: 'Course' } & CourseFragmentFragment>
      paginatorInfo: { __typename?: 'PaginatorInfo' } & Pick<PaginatorInfo, 'total'>
    }
  >
  pendingReviewCourses?: Maybe<
    { __typename?: 'CoursePaginator' } & {
      data: Array<{ __typename?: 'Course' } & CourseFragmentFragment>
      paginatorInfo: { __typename?: 'PaginatorInfo' } & Pick<PaginatorInfo, 'total'>
    }
  >
  teacherPendingCourses?: Maybe<
    { __typename?: 'CoursePaginator' } & {
      data: Array<{ __typename?: 'Course' } & CourseFragmentFragment>
      paginatorInfo: { __typename?: 'PaginatorInfo' } & Pick<PaginatorInfo, 'total'>
    }
  >
}

export type TeacherFutureCoursesQueryVariables = Exact<{ [key: string]: never }>

export type TeacherFutureCoursesQuery = { __typename?: 'Query' } & {
  todayCourses?: Maybe<Array<{ __typename?: 'Course' } & CourseFragmentFragment>>
  futureCourses?: Maybe<
    { __typename?: 'CoursePaginator' } & {
      data: Array<{ __typename?: 'Course' } & CourseFragmentFragment>
      paginatorInfo: { __typename?: 'PaginatorInfo' } & Pick<PaginatorInfo, 'total'>
    }
  >
}

export type TeacherPastCoursesQueryVariables = Exact<{
  first: Scalars['Int']
  page?: Maybe<Scalars['Int']>
  discipline?: Maybe<Scalars['ID']>
  orderBy?: Maybe<
    Array<QueryPastCoursesOrderByOrderByClause> | QueryPastCoursesOrderByOrderByClause
  >
}>

export type TeacherPastCoursesQuery = { __typename?: 'Query' } & {
  pastCourses?: Maybe<
    { __typename?: 'CoursePaginator' } & {
      data: Array<{ __typename?: 'Course' } & CourseFragmentFragment>
      paginatorInfo: { __typename?: 'PaginatorInfo' } & PaginatorInfoFragmentFragment
    }
  >
}

export type TeacherPendingCoursesQueryVariables = Exact<{
  type: CourseType
}>

export type TeacherPendingCoursesQuery = { __typename?: 'Query' } & {
  ONE_OFF?: Maybe<
    { __typename?: 'CoursePaginator' } & {
      paginatorInfo: { __typename?: 'PaginatorInfo' } & Pick<PaginatorInfo, 'total'>
    }
  >
  RECURRENT?: Maybe<
    { __typename?: 'CoursePaginator' } & {
      paginatorInfo: { __typename?: 'PaginatorInfo' } & Pick<PaginatorInfo, 'total'>
    }
  >
  EMERGENCY?: Maybe<
    { __typename?: 'CoursePaginator' } & {
      paginatorInfo: { __typename?: 'PaginatorInfo' } & Pick<PaginatorInfo, 'total'>
    }
  >
  teacherPendingCourses?: Maybe<
    { __typename?: 'CoursePaginator' } & {
      data: Array<{ __typename?: 'Course' } & CourseFragmentFragment>
      paginatorInfo: { __typename?: 'PaginatorInfo' } & PaginatorInfoFragmentFragment
    }
  >
}

export type TeacherPlanningCoursesQueryVariables = Exact<{ [key: string]: never }>

export type TeacherPlanningCoursesQuery = { __typename?: 'Query' } & {
  futureCourses?: Maybe<
    { __typename?: 'CoursePaginator' } & {
      data: Array<{ __typename?: 'Course' } & CourseFragmentFragment>
      paginatorInfo: { __typename?: 'PaginatorInfo' } & Pick<PaginatorInfo, 'total'>
    }
  >
}

export type TodayCourseQueryVariables = Exact<{ [key: string]: never }>

export type TodayCourseQuery = { __typename?: 'Query' } & {
  todayCourse?: Maybe<{ __typename?: 'Course' } & CourseFragmentFragment>
}

export type DisciplinesQueryVariables = Exact<{ [key: string]: never }>

export type DisciplinesQuery = { __typename?: 'Query' } & {
  disciplines?: Maybe<Array<{ __typename?: 'Discipline' } & DisciplineFragmentFragment>>
}

export type CreateDisputeMutationVariables = Exact<{
  course: Scalars['ID']
  student?: Maybe<Scalars['ID']>
  reason: DisputeReason
  message?: Maybe<Scalars['String']>
}>

export type CreateDisputeMutation = { __typename?: 'Mutation' } & {
  createDispute: { __typename?: 'Dispute' } & Pick<Dispute, 'id'>
}

export type GradesQueryVariables = Exact<{ [key: string]: never }>

export type GradesQuery = { __typename?: 'Query' } & {
  grades?: Maybe<Array<{ __typename?: 'Grade' } & GradeFragmentFragment>>
}

export type AddFamilyMemberMutationVariables = Exact<{
  member: FamilyMemberInput
}>

export type AddFamilyMemberMutation = { __typename?: 'Mutation' } & {
  addFamilyMember: { __typename?: 'User' } & {
    guardian?: Maybe<{ __typename?: 'Guardian' } & GuardianFragmentFragment>
  }
}

export type DeleteFamilyMemberMutationVariables = Exact<{
  student: Scalars['ID']
}>

export type DeleteFamilyMemberMutation = { __typename?: 'Mutation' } & {
  deleteFamilyMember: { __typename?: 'User' } & {
    guardian?: Maybe<{ __typename?: 'Guardian' } & GuardianFragmentFragment>
  }
}

export type RegisterFamilyMutationVariables = Exact<{
  members?: Maybe<Array<FamilyMemberInput> | FamilyMemberInput>
}>

export type RegisterFamilyMutation = { __typename?: 'Mutation' } & {
  registerFamily: { __typename?: 'User' } & Pick<User, 'status'> & {
      guardian?: Maybe<
        { __typename?: 'Guardian' } & {
          children?: Maybe<
            Array<
              { __typename?: 'Student' } & Pick<
                Student,
                'school' | 'first_name' | 'last_name' | 'email' | 'status' | 'avatar'
              > & { grade?: Maybe<{ __typename?: 'Grade' } & Pick<Grade, 'id'>> }
            >
          >
        }
      >
    }
}

export type UpdateFamilyMemberMutationVariables = Exact<{
  student: Scalars['ID']
  member: FamilyMemberInput
}>

export type UpdateFamilyMemberMutation = { __typename?: 'Mutation' } & {
  updateFamilyMember: { __typename?: 'User' } & {
    guardian?: Maybe<{ __typename?: 'Guardian' } & GuardianFragmentFragment>
  }
}

export type UpdateGuardianMutationVariables = Exact<{
  first_name: Scalars['String']
  last_name: Scalars['String']
  email: Scalars['String']
  phone: Scalars['String']
  display_address: Scalars['String']
  address?: Maybe<Scalars['String']>
  city?: Maybe<Scalars['String']>
  zip_code?: Maybe<Scalars['String']>
  country?: Maybe<Scalars['String']>
  avatar?: Maybe<Scalars['String']>
}>

export type UpdateGuardianMutation = { __typename?: 'Mutation' } & {
  updateGuardian: { __typename?: 'User' } & UserFragmentFragment
}

export type CreateLessonMutationVariables = Exact<{
  name: Scalars['String']
  file?: Maybe<Upload>
  student?: Maybe<Scalars['ID']>
  discipline: Scalars['ID']
}>

export type CreateLessonMutation = { __typename?: 'Mutation' } & {
  createLesson: { __typename?: 'Lesson' } & LessonFragmentFragment
}

export type NotificationsQueryVariables = Exact<{
  first: Scalars['Int']
  page?: Maybe<Scalars['Int']>
}>

export type NotificationsQuery = { __typename?: 'Query' } & {
  notifications?: Maybe<
    { __typename?: 'NotificationPaginator' } & {
      data: Array<{ __typename?: 'Notification' } & NotificationFragmentFragment>
      paginatorInfo: { __typename?: 'PaginatorInfo' } & PaginatorInfoFragmentFragment
    }
  >
}

export type CreateCreditPaymentIntentMutationVariables = Exact<{
  amount: Scalars['Int']
}>

export type CreateCreditPaymentIntentMutation = { __typename?: 'Mutation' } & {
  createCreditPaymentIntent?: Maybe<
    { __typename?: 'Payment' } & Pick<Payment, 'id' | 'status' | 'client_secret'>
  >
}

export type JoinRoomQueryVariables = Exact<{
  room: Scalars['String']
  student?: Maybe<Scalars['ID']>
}>

export type JoinRoomQuery = { __typename?: 'Query' } & {
  joinRoom?: Maybe<
    { __typename?: 'Room' } & Pick<Room, 'name' | 'token' | 'hash'> & {
        settings?: Maybe<
          { __typename?: 'SettingVisio' } & Pick<SettingVisio, 'config' | 'interface_config'>
        >
      }
  >
}

export type SettingsQueryVariables = Exact<{ [key: string]: never }>

export type SettingsQuery = { __typename?: 'Query' } & {
  settings?: Maybe<
    { __typename?: 'Setting' } & {
      links?: Maybe<{ __typename?: 'SettingLinks' } & Pick<SettingLinks, 'cgu' | 'privacy'>>
    }
  >
}

export type UpdateStudentMutationVariables = Exact<{
  first_name: Scalars['String']
  last_name: Scalars['String']
  email: Scalars['String']
  school: Scalars['String']
  grade: Scalars['ID']
  avatar?: Maybe<Scalars['String']>
}>

export type UpdateStudentMutation = { __typename?: 'Mutation' } & {
  updateStudent: { __typename?: 'User' } & UserFragmentFragment
}

export type RegisterProfessionalInformationMutationVariables = Exact<{
  siret: Scalars['String']
  cv?: Maybe<Upload>
  diploma?: Maybe<Upload>
  company_certificate?: Maybe<Upload>
  introduction?: Maybe<Scalars['String']>
}>

export type RegisterProfessionalInformationMutation = { __typename?: 'Mutation' } & {
  registerProfessionalInformation: { __typename?: 'User' } & UserFragmentFragment
}

export type UpdatePayoutScheduleIntervalMutationVariables = Exact<{
  interval: PayoutScheduleInterval
}>

export type UpdatePayoutScheduleIntervalMutation = { __typename?: 'Mutation' } & {
  updatePayoutScheduleInterval: { __typename?: 'User' } & {
    teacher?: Maybe<{ __typename?: 'Teacher' } & Pick<Teacher, 'payouts_schedule_interval'>>
  }
}

export type UpdateTeacherMutationVariables = Exact<{
  first_name: Scalars['String']
  last_name: Scalars['String']
  email: Scalars['String']
  phone: Scalars['String']
  display_address: Scalars['String']
  address?: Maybe<Scalars['String']>
  city?: Maybe<Scalars['String']>
  zip_code?: Maybe<Scalars['String']>
  country?: Maybe<Scalars['String']>
  cv?: Maybe<Upload>
  diploma?: Maybe<Upload>
  company_certificate?: Maybe<Upload>
  introduction?: Maybe<Scalars['String']>
  avatar?: Maybe<Scalars['String']>
}>

export type UpdateTeacherMutation = { __typename?: 'Mutation' } & {
  updateTeacher: { __typename?: 'User' } & UserFragmentFragment
}

export type UpdateTeacherDisciplinesMutationVariables = Exact<{
  disciplines?: Maybe<Array<TeacherDisciplineInput> | TeacherDisciplineInput>
}>

export type UpdateTeacherDisciplinesMutation = { __typename?: 'Mutation' } & {
  updateTeacherDisciplines: { __typename?: 'User' } & UserFragmentFragment
}

export type UpdateTeacherMaximumParticipantsMutationVariables = Exact<{
  maximum_participants: Scalars['Int']
}>

export type UpdateTeacherMaximumParticipantsMutation = { __typename?: 'Mutation' } & {
  updateTeacherMaximumParticipants: { __typename?: 'User' } & UserFragmentFragment
}

export type UpdateTeacherPriceMutationVariables = Exact<{
  price: Scalars['Int']
}>

export type UpdateTeacherPriceMutation = { __typename?: 'Mutation' } & {
  updateTeacherPrice: { __typename?: 'User' } & UserFragmentFragment
}

export type StripeAccountLinkQueryVariables = Exact<{
  return_url?: Maybe<Scalars['String']>
  refresh_url?: Maybe<Scalars['String']>
}>

export type StripeAccountLinkQuery = { __typename?: 'Query' } & Pick<Query, 'stripeAccountLink'>

export type StripeLoginLinkQueryVariables = Exact<{
  redirect_url?: Maybe<Scalars['String']>
}>

export type StripeLoginLinkQuery = { __typename?: 'Query' } & Pick<Query, 'stripeLoginLink'>

export type TeacherByIdQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type TeacherByIdQuery = { __typename?: 'Query' } & {
  teacherById?: Maybe<
    { __typename?: 'PublicTeacher' } & Pick<PublicTeacher, 'introduction'> & {
        disciplines?: Maybe<
          Array<
            { __typename?: 'PublicTeacherDiscipline' } & PublicTeacherDisciplineFragmentFragment
          >
        >
      } & PublicTeacherFragmentFragment
  >
}

export type CreateTeacherAvailabilityRecurrenceMutationVariables = Exact<{
  start_at: Scalars['Date']
  end_at: Scalars['Date']
  days: Array<RecurrenceDay> | RecurrenceDay
  slots?: Maybe<Array<AvailabilitySlot> | AvailabilitySlot>
}>

export type CreateTeacherAvailabilityRecurrenceMutation = { __typename?: 'Mutation' } & {
  createTeacherAvailabilityRecurrence: {
    __typename?: 'TeacherAvailabilityRecurrence'
  } & TeacherAvailabilityRecurrenceFragmentFragment
}

export type DeleteTeacherAvailabilityRecurrenceMutationVariables = Exact<{
  id: Scalars['ID']
}>

export type DeleteTeacherAvailabilityRecurrenceMutation = { __typename?: 'Mutation' } & {
  deleteTeacherAvailabilityRecurrence?: Maybe<
    { __typename?: 'TeacherAvailabilityRecurrence' } & Pick<TeacherAvailabilityRecurrence, 'id'>
  >
}

export type UpdateTeacherAvailabilityOfDayMutationVariables = Exact<{
  date: Scalars['Date']
  slots?: Maybe<Array<AvailabilitySlot> | AvailabilitySlot>
}>

export type UpdateTeacherAvailabilityOfDayMutation = { __typename?: 'Mutation' } & {
  updateTeacherAvailabilityOfDay?: Maybe<
    Array<{ __typename?: 'TeacherAvailability' } & TeacherAvailabilityFragmentFragment>
  >
}

export type UpdateTeacherAvailabilityRecurrenceMutationVariables = Exact<{
  id: Scalars['ID']
  start_at: Scalars['Date']
  end_at: Scalars['Date']
  days: Array<RecurrenceDay> | RecurrenceDay
  slots?: Maybe<Array<AvailabilitySlot> | AvailabilitySlot>
}>

export type UpdateTeacherAvailabilityRecurrenceMutation = { __typename?: 'Mutation' } & {
  updateTeacherAvailabilityRecurrence: {
    __typename?: 'TeacherAvailabilityRecurrence'
  } & TeacherAvailabilityRecurrenceFragmentFragment
}

export type TeacherAvailabilitiesOfMonthQueryVariables = Exact<{
  month: Scalars['Int']
  year: Scalars['Int']
}>

export type TeacherAvailabilitiesOfMonthQuery = { __typename?: 'Query' } & {
  teacherAvailabilitiesOfMonth?: Maybe<
    Array<{ __typename?: 'TeacherAvailability' } & TeacherAvailabilityFragmentFragment>
  >
}

export type TeacherRecurrencesQueryVariables = Exact<{ [key: string]: never }>

export type TeacherRecurrencesQuery = { __typename?: 'Query' } & {
  teacherRecurrences?: Maybe<
    { __typename?: 'TeacherAvailabilityRecurrencePaginator' } & {
      data: Array<
        {
          __typename?: 'TeacherAvailabilityRecurrence'
        } & TeacherAvailabilityRecurrenceFragmentFragment
      >
    }
  >
}

export type TeacherRecurrenceByIdQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type TeacherRecurrenceByIdQuery = { __typename?: 'Query' } & {
  teacherRecurrenceById?: Maybe<
    { __typename?: 'TeacherAvailabilityRecurrence' } & TeacherAvailabilityRecurrenceFragmentFragment
  >
}

export type ThemesQueryVariables = Exact<{
  discipline: Scalars['ID']
  grade: Scalars['ID']
}>

export type ThemesQuery = { __typename?: 'Query' } & {
  themes?: Maybe<
    { __typename?: 'ThemePaginator' } & {
      data: Array<
        { __typename?: 'Theme' } & Pick<Theme, 'id' | 'name' | 'slug'> & {
            lessons?: Maybe<
              Array<
                { __typename?: 'Lesson' } & Pick<Lesson, 'id' | 'name' | 'slug' | 'description'>
              >
            >
          }
      >
    }
  >
}

export type ConfirmSocialRegisterMutationVariables = Exact<{
  type: UserType
  firstName: Scalars['String']
  lastName: Scalars['String']
  email: Scalars['String']
  sponsor?: Maybe<Scalars['String']>
}>

export type ConfirmSocialRegisterMutation = { __typename?: 'Mutation' } & {
  confirmSocialRegister: { __typename?: 'User' } & UserFragmentFragment
}

export type ForgotPasswordMutationVariables = Exact<{
  email: Scalars['String']
}>

export type ForgotPasswordMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'forgotPassword'>

export type InviteGuardianMutationVariables = Exact<{
  email: Scalars['String']
}>

export type InviteGuardianMutation = { __typename?: 'Mutation' } & {
  inviteGuardian: { __typename?: 'User' } & UserFragmentFragment
}

export type LinkGuardianMutationVariables = Exact<{
  sponsor_code: Scalars['String']
}>

export type LinkGuardianMutation = { __typename?: 'Mutation' } & {
  linkGuardian: { __typename?: 'User' } & UserFragmentFragment
}

export type LinkGuardianLaterMutationVariables = Exact<{ [key: string]: never }>

export type LinkGuardianLaterMutation = { __typename?: 'Mutation' } & {
  linkGuardianLater: { __typename?: 'User' } & UserFragmentFragment
}

export type AuthLoginMutationVariables = Exact<{
  email: Scalars['String']
  password: Scalars['String']
}>

export type AuthLoginMutation = { __typename?: 'Mutation' } & {
  login: { __typename?: 'User' } & UserFragmentFragment
}

export type InvalidateTokenMutationVariables = Exact<{ [key: string]: never }>

export type InvalidateTokenMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'invalidateToken'
>

export type AuthRefreshTokenMutationVariables = Exact<{ [key: string]: never }>

export type AuthRefreshTokenMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'refreshToken'>

export type RegisterMutationVariables = Exact<{
  type: UserType
  firstName: Scalars['String']
  lastName: Scalars['String']
  email: Scalars['String']
  password: Scalars['String']
  passwordConfirmation: Scalars['String']
  sponsor?: Maybe<Scalars['String']>
}>

export type RegisterMutation = { __typename?: 'Mutation' } & {
  register: { __typename?: 'User' } & UserFragmentFragment
}

export type RegisterPersonalInformationMutationVariables = Exact<{
  phone?: Maybe<Scalars['String']>
  display_address?: Maybe<Scalars['String']>
  address?: Maybe<Scalars['String']>
  city?: Maybe<Scalars['String']>
  zip_code?: Maybe<Scalars['String']>
  school?: Maybe<Scalars['String']>
  grade?: Maybe<Scalars['ID']>
  avatar?: Maybe<Scalars['String']>
}>

export type RegisterPersonalInformationMutation = { __typename?: 'Mutation' } & {
  registerPersonalInformation: { __typename?: 'User' } & UserFragmentFragment
}

export type ResendVerificationMailMutationVariables = Exact<{ [key: string]: never }>

export type ResendVerificationMailMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'resendVerificationMail'
>

export type ResetPasswordMutationVariables = Exact<{
  token: Scalars['String']
  email: Scalars['String']
  password: Scalars['String']
  passwordConfirmation: Scalars['String']
}>

export type ResetPasswordMutation = { __typename?: 'Mutation' } & {
  resetPassword?: Maybe<{ __typename?: 'User' } & UserFragmentFragment>
}

export type SocialLoginMutationVariables = Exact<{
  code: Scalars['String']
  type: SocialProviderType
  first_name?: Maybe<Scalars['String']>
  last_name?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
}>

export type SocialLoginMutation = { __typename?: 'Mutation' } & {
  socialLogin: { __typename?: 'User' } & UserFragmentFragment
}

export type UpdatePasswordMutationVariables = Exact<{
  current: Scalars['String']
  password: Scalars['String']
  passwordConfirmation: Scalars['String']
}>

export type UpdatePasswordMutation = { __typename?: 'Mutation' } & {
  updatePassword: { __typename?: 'User' } & Pick<User, 'id'>
}

export type MeQueryVariables = Exact<{ [key: string]: never }>

export type MeQuery = { __typename?: 'Query' } & {
  me?: Maybe<{ __typename?: 'User' } & UserFragmentFragment>
}

export type ReloadNotificationsCountQueryVariables = Exact<{ [key: string]: never }>

export type ReloadNotificationsCountQuery = { __typename?: 'Query' } & {
  me?: Maybe<{ __typename?: 'User' } & Pick<User, 'unread_notifications'>>
}

export type ReloadWalletQueryVariables = Exact<{ [key: string]: never }>

export type ReloadWalletQuery = { __typename?: 'Query' } & {
  me?: Maybe<
    { __typename?: 'User' } & { wallet?: Maybe<{ __typename?: 'Wallet' } & WalletFragmentFragment> }
  >
}

export type RequestRefundMutationVariables = Exact<{
  amount: Scalars['Int']
  iban: Scalars['String']
}>

export type RequestRefundMutation = { __typename?: 'Mutation' } & {
  requestRefund: { __typename?: 'WalletRefund' } & Pick<WalletRefund, 'amount' | 'status'>
}

export type BookingTransactionsQueryVariables = Exact<{
  first: Scalars['Int']
  page?: Maybe<Scalars['Int']>
  limit?: Maybe<TransactionLimitFilter>
}>

export type BookingTransactionsQuery = { __typename?: 'Query' } & {
  bookingTransactions?: Maybe<
    { __typename?: 'WalletTransactionPaginator' } & {
      data: Array<{ __typename?: 'WalletTransaction' } & WalletTransactionFragmentFragment>
      paginatorInfo: { __typename?: 'PaginatorInfo' } & PaginatorInfoFragmentFragment
    }
  >
}

export type WalletTransactionsQueryVariables = Exact<{
  first: Scalars['Int']
  page?: Maybe<Scalars['Int']>
  limit?: Maybe<TransactionLimitFilter>
}>

export type WalletTransactionsQuery = { __typename?: 'Query' } & {
  walletTransactions?: Maybe<
    { __typename?: 'WalletTransactionPaginator' } & {
      data: Array<{ __typename?: 'WalletTransaction' } & WalletTransactionFragmentFragment>
      paginatorInfo: { __typename?: 'PaginatorInfo' } & PaginatorInfoFragmentFragment
    }
  >
}
