import gql from 'graphql-tag'

import { User } from '../../../../generated/graphql'
import WalletFragment from '../../../fragments/WalletFragment'

const reloadWallet = {
  query: gql`
    query reloadWallet {
      me {
        wallet {
          ...WalletFragment
        }
      }
    }
    ${WalletFragment}
  `,
  transformer: (response: any): User => {
    return response.me
  },
}

export default reloadWallet
