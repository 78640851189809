import gql from 'graphql-tag'

import { User } from '../../../../generated/graphql'

const reloadNotificationsCount = {
  query: gql`
    query reloadNotificationsCount {
      me {
        unread_notifications
      }
    }
  `,
  transformer: (response: any): User => {
    return response.me
  },
}

export default reloadNotificationsCount
