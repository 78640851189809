import React, { FC } from 'react'

import { Icons } from '../../Icon/types'

import * as SC from './styled'

export type SimpleFeedbackModalProps = {
  className?: string
  title?: string
  icon?: Icons
  subtitle?: string
  text?: string
  open: boolean
  isWarning?: boolean
  onClose?: () => void
  closeLabel?: string
}

const SimpleFeedbackModal: FC<SimpleFeedbackModalProps> = (props) => {
  const { className, title, icon, subtitle, text, open, onClose, closeLabel, isWarning } = props

  return (
    <SC.Modal open={open} onClose={onClose} className={className}>
      <SC.Content>
        {title && <SC.Title $isWarning={isWarning}>{title}</SC.Title>}
        {icon && <SC.MyIcon icon={icon} />}
        {subtitle && <SC.Subtitle>{subtitle}</SC.Subtitle>}
        {text && <SC.Text>{text}</SC.Text>}
        {closeLabel && <SC.Button text={closeLabel} onClick={onClose} />}
      </SC.Content>
    </SC.Modal>
  )
}

export default SimpleFeedbackModal
