import React, { FC } from 'react'
import { Formik, FormikHelpers } from 'formik'
import { format } from 'date-fns'
import { fr } from 'date-fns/locale'

import { CalendarProps } from '../form/Calendar'
import { ToggleButtonPickerProps } from '../form/ToggleButtonPicker'
import { FormSubmitProps } from '../FormSubmit'
import { AvailabilitiesObject } from '../form/AvailabilitiesPicker'

import * as SC from './styled'

export type AvailabilityPerDayValues = {
  date?: Date
  slots: string[]
}

export type AvailabilityPerDayFormProps = {
  className?: string
  calendar: CalendarProps
  slot: ToggleButtonPickerProps
  submitButton: FormSubmitProps
  initialValues?: AvailabilityPerDayValues
  onSubmit?: (
    values: AvailabilityPerDayValues,
    formikHelpers: FormikHelpers<AvailabilityPerDayValues>
  ) => void
  disabledSlots?: AvailabilitiesObject
}

const AvailabilityPerDayFormSchema = undefined

const AvailabilityPerDayForm: FC<AvailabilityPerDayFormProps> = (props) => {
  const {
    className,
    calendar,
    slot,
    submitButton,
    initialValues = { date: new Date(), slots: [] },
    onSubmit = (_values, _helpers) => null,
    disabledSlots,
  } = props

  return (
    <SC.AvailabilityPerDayForm className={className}>
      <SC.Wrapper disableGutters maxWidth={false}>
        <Formik
          initialValues={initialValues}
          validationSchema={AvailabilityPerDayFormSchema}
          validateOnChange={false}
          validateOnBlur={false}
          enableReinitialize
          onSubmit={onSubmit}
        >
          {(formikProps) => (
            <SC.FormikForm>
              <SC.Calendar
                {...calendar}
                selectedDate={formikProps.values?.date}
                onDayChange={(day: Date, modifiers, e) => {
                  formikProps.setFieldValue('date', day)
                  calendar.onDayChange?.(day, modifiers, e)
                }}
              />
              {formikProps.values?.date && (
                <>
                  <SC.SlotPicker
                    {...slot}
                    fields={
                      slot.fields?.map((field) => ({
                        ...field,
                        disabled:
                          field.value &&
                          disabledSlots?.[formikProps.values?.date?.toISOString() ?? '']?.includes(
                            field.value
                          ),
                      })) as ToggleButtonPickerProps['fields']
                    }
                    label={
                      formikProps.values?.date
                        ? format(formikProps.values?.date, 'eeee d LLLL y', {
                            locale: fr,
                          })
                        : ''
                    }
                    value={formikProps.values?.slots}
                    onChange={(_name: string, value: string[]) => {
                      formikProps.setFieldValue('slots', value)
                    }}
                  />
                  <SC.Button>
                    <SC.SubmitButton {...submitButton} isDisabled={!formikProps.dirty} />
                  </SC.Button>
                </>
              )}
            </SC.FormikForm>
          )}
        </Formik>
      </SC.Wrapper>
    </SC.AvailabilityPerDayForm>
  )
}

export default AvailabilityPerDayForm
