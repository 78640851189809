import React, { useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { HeaderProps } from '../../components/Header'
import useHeader from '../../hooks/useHeader'
import { MenuProps } from '../../components/Menu'
import useMenu from '../../hooks/useMenu'
import { Course, SortOrder, UserType } from '../../generated/graphql'
import { RestrictedReactFC } from '../../types/common'
import { actions, selectors } from '../../redux'
import PlanningListTemplate, { PlanningListTemplateProps } from '../../templates/PlanningList'
import { courseToPastCardProps } from '../../transformers/courseTransformers'
import useBackButton from '../../hooks/useBackButton'
import { FiltersProps } from '../../components/Filters'

const PlanningHistoryPage: RestrictedReactFC<RouteComponentProps> = (props) => {
  const headerProps: HeaderProps = useHeader()
  const menuProps: MenuProps = useMenu(props)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const user = useSelector(selectors.auth.user)
  const teacherPastCourses = useSelector(selectors.planning.teacherPastCourses)
  const discilpines = useSelector(selectors.app.disciplines)
  const backButton = useBackButton()

  const [state, setState] = useState({
    page: 1,
    discipline: 'all',
    sort: SortOrder.Desc as string,
  })

  const lists = []
  if (teacherPastCourses?.data?.data && teacherPastCourses?.data?.data?.length > 0) {
    lists.push({
      cards: teacherPastCourses?.data?.data?.map((c: Course) => courseToPastCardProps(c, t, user)),
      ...(teacherPastCourses?.data?.paginatorInfo?.hasMorePages && {
        buttonProps: {
          text: t('load_more'),
          pendingText: t('load_more_pending'),
          isPending: teacherPastCourses.pending,
          onClick: () => setState((before) => ({ ...before, page: before.page + 1 })),
        },
      }),
    })
  }

  const [filterOpen, setFilterOpen] = useState(false)

  const filtersProps: FiltersProps = {
    title: t('filters_title'),
    isOpen: filterOpen,
    fields: [
      {
        name: 'discipline',
        label: t('filters_discipline'),
        isLabelExternal: true,
        fullWidth: true,
        select: true,
        selectOptions: [
          {
            label: t('filters_all'),
            value: 'all',
          },
        ].concat(
          discilpines?.map((discipline) => ({
            label: discipline.name,
            value: discipline.id,
          })) ?? []
        ),
      },
      {
        name: 'sort',
        label: t('filters_order'),
        isLabelExternal: true,
        fullWidth: true,
        select: true,
        selectOptions: [
          {
            label: t('filters_order_date_desc'),
            value: SortOrder.Desc,
          },
          {
            label: t('filters_order_date_asc'),
            value: SortOrder.Asc,
          },
        ],
      },
    ],
    onChange: (value) =>
      setState({
        page: 1,
        discipline: value.discipline as string,
        sort: value.sort as string,
      }),
    setIsOpen: setFilterOpen,
    value: {
      discipline: state.discipline,
      sort: state.sort,
    },
  }

  const planningProps: PlanningListTemplateProps = {
    headerProps,
    menuProps,
    backButton,
    title: t('planningHistory_title'),
    filtersProps,
    lists,
  }

  //
  // EFFECTS
  //
  useEffect(() => {
    return () => {
      dispatch(actions.planning.teacherPastCoursesReset(undefined))
    }
  }, [dispatch])

  useEffect(() => {
    dispatch(
      actions.planning.teacherPastCoursesRequest({
        first: 100,
        page: state.page,
        filters: {
          ...(state.discipline !== 'all' && { discipline: state.discipline }),
          sort: state.sort,
        },
      })
    )
  }, [dispatch, state])

  return <PlanningListTemplate {...planningProps} />
}

PlanningHistoryPage.restrictedUserTypes = [UserType.Teacher]

export default PlanningHistoryPage
