import React, { FC } from 'react'

import { ImageTextItemProps } from '../ImageTextItem'

import * as SC from './styled'

export type ImageTextListProps = {
  className?: string
  items?: ImageTextItemProps[]
  onClick?: (i: number) => void
  selected?: number
  name?: string
  label?: string
  value?: string
  onChange?: (name: string, value: string) => void
  hoverable?: boolean
}

const ImageTextList: FC<ImageTextListProps> = (props) => {
  const {
    className,
    items,
    onClick,
    selected,
    label,
    name = '',
    value,
    onChange,
    hoverable,
  } = props

  return (
    <SC.ImageTextList className={className}>
      {label && <SC.Label>{label}</SC.Label>}
      <SC.Content>
        {items &&
          items.map((item, i) => {
            return (
              <SC.ImageTextItem
                key={i}
                hoverable={hoverable}
                {...item}
                onClick={() => {
                  onClick?.(i)
                  onChange?.(name, item?.value ?? '')
                }}
                isSelected={i === selected || (value !== undefined && value === item?.value)}
              />
            )
          })}
      </SC.Content>
    </SC.ImageTextList>
  )
}

export default ImageTextList
