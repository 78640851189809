import React, { FC } from 'react'

import Header, { HeaderProps } from '../../components/Header'
import SignUpLayout from '../../layouts/SignupLayout'
import { ImgProps } from '../../types/image'
import SignUpSocialForm, { SignUpSocialFormProps } from '../../components/SignUpSocialForm'

import * as SC from './styled'

export type SignUpSocialTemplateProps = {
  headerProps: HeaderProps
  signupProps: SignUpSocialFormProps
  imageProps: ImgProps
}

const SignUpSocialTemplate: FC<SignUpSocialTemplateProps> = (props) => {
  const { headerProps, signupProps, imageProps } = props

  return (
    <SignUpLayout
      header={<Header {...headerProps} />}
      side={
        <SC.BoxImage>
          <SC.Image {...imageProps} />
        </SC.BoxImage>
      }
    >
      <SignUpSocialForm {...signupProps} />
    </SignUpLayout>
  )
}

export default SignUpSocialTemplate
