import { Form, Formik, FormikHelpers, FormikProps } from 'formik'
import React, { FC, useRef } from 'react'

import { ToggleButtonGroupProps } from '../form/ToggleButtonGroup'
import FormScrollToError from '../FormScrollToError'
import { FormSubmitProps } from '../FormSubmit'
import FormSubmitErrors, { FormSubmitErrorsProps } from '../FormSubmitErrors'
import { ImageTextItemProps } from '../ImageTextItem'
import { SubjectWithThemePickerProps } from '../SubjectWithThemePicker'

import * as SC from './styled'

export type SearchSubjectFormValues = {
  subject: string
  theme: string
  typeOfRequest?: string
}

export type SearchSubjectFormProps = {
  className?: string
  requestForLabel?: string
  requestFor?: ImageTextItemProps
  requestSubjectLabel: string
  requestSubject: SubjectWithThemePickerProps[]
  typeOfRequest?: ToggleButtonGroupProps
  initialValues: SearchSubjectFormValues
  submitButton: FormSubmitProps
  submitErrors?: FormSubmitErrorsProps['errors']
  onSubmit: (
    values: SearchSubjectFormValues,
    formikHelpers: FormikHelpers<SearchSubjectFormValues>
  ) => void
}

const validationSchema = undefined

const SearchSubjectForm: FC<SearchSubjectFormProps> = ({
  className,
  requestForLabel,
  requestFor,
  requestSubjectLabel,
  requestSubject,
  typeOfRequest,
  initialValues,
  submitButton,
  submitErrors,
  onSubmit,
}) => {
  const formikRef = useRef<FormikProps<SearchSubjectFormValues>>(null)

  const handleClick = (name: string) => {
    formikRef.current?.setFieldValue('subject', name)
  }

  const handleTypeChange = (name: string, value: string[]) => {
    formikRef.current?.setFieldValue('typeOfRequest', value)
  }

  return (
    <SC.SearchSubjectForm className={className}>
      <SC.Wrapper disableGutters>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnChange={true}
          validateOnBlur={true}
          onSubmit={onSubmit}
          enableReinitialize
          innerRef={formikRef}
        >
          {(formikProps) => (
            <Form noValidate>
              <FormScrollToError formikProps={formikProps} />
              {!typeOfRequest && <SC.RequestForLabel>{requestForLabel}</SC.RequestForLabel>}
              {typeOfRequest && (
                <SC.TypeOfRequest
                  {...typeOfRequest}
                  onChange={handleTypeChange}
                  value={[formikProps.values.typeOfRequest]}
                />
              )}
              {requestFor && <SC.RequestFor {...requestFor} />}
              <SC.Field>
                <SC.RequestSubjectLabel>{requestSubjectLabel}</SC.RequestSubjectLabel>
                {requestSubject.map((subject, i) => (
                  <SC.SubjectWithThemePicker
                    {...subject}
                    key={i}
                    onHeaderClick={handleClick}
                    isExpand={formikProps.values.subject === subject.name}
                  />
                ))}
              </SC.Field>
              <FormSubmitErrors errors={submitErrors} />
              <SC.SubmitButton {...submitButton} isDisabled={!formikProps.dirty} />
            </Form>
          )}
        </Formik>
      </SC.Wrapper>
    </SC.SearchSubjectForm>
  )
}

export default SearchSubjectForm
