import gql from 'graphql-tag'

import { TeacherAvailabilityRecurrence } from '../../../../generated/graphql'
import TeacherAvailabilityRecurrenceFragment from '../../../fragments/TeacherAvailabilityRecurrenceFragment'

const teacherRecurrenceById = {
  query: gql`
    query teacherRecurrenceById($id: ID!) {
      teacherRecurrenceById(id: $id) {
        ...TeacherAvailabilityRecurrenceFragment
      }
    }
    ${TeacherAvailabilityRecurrenceFragment}
  `,
  transformer: (response: {
    teacherRecurrenceById: TeacherAvailabilityRecurrence
  }): TeacherAvailabilityRecurrence => {
    return response.teacherRecurrenceById
  },
}

export default teacherRecurrenceById
