import { Form, Formik, FormikHelpers } from 'formik'
import React, { FC, useMemo } from 'react'
import * as Yup from 'yup'

import FormScrollToError from '../FormScrollToError'
import { FormSubmitProps } from '../FormSubmit'
import FormSubmitErrors, { FormSubmitErrorsProps } from '../FormSubmitErrors'
import { UploadFileTinyValue } from '../form/UploadFileTiny'
import { FormFieldConfig, FormFieldProps } from '../../types/form'
import { getValidationSchema, renderField } from '../../helpers/FormHelpers'

import * as SC from './styled'

export type CustomThemeFormValues = {
  name: string
  file?: UploadFileTinyValue
}

export type CustomThemeFormProps = {
  className?: string
  fieldsProps?: {
    name?: FormFieldProps
    file?: FormFieldProps
  }
  errorTexts?: {
    required: string
  }
  initialValues: CustomThemeFormValues
  submitButton: FormSubmitProps
  submitErrors?: FormSubmitErrorsProps['errors']
  onSubmit: (
    values: CustomThemeFormValues,
    formikHelpers: FormikHelpers<CustomThemeFormValues>
  ) => void
}

const CustomThemeForm: FC<CustomThemeFormProps> = (props) => {
  const {
    className,
    errorTexts,
    initialValues,
    submitButton,
    submitErrors,
    onSubmit,
    fieldsProps,
  } = props

  const nameField = useMemo(
    () => ({
      name: 'name',
      Component: SC.TextField,
      validation: Yup.string().required(errorTexts?.required),
    }),
    [errorTexts]
  )

  const uploadField = useMemo(
    () => ({
      name: 'file',
      Component: SC.Upload,
    }),
    []
  )

  const fields: FormFieldConfig[] = useMemo(() => [nameField, uploadField], [
    nameField,
    uploadField,
  ])

  const validationSchema = getValidationSchema(fields)

  return (
    <SC.CustomThemeForm className={className}>
      <SC.Wrapper>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnChange={true}
          validateOnBlur={true}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {(formikProps) => (
            <Form noValidate>
              <FormScrollToError formikProps={formikProps} />
              <SC.Fields>
                {fields.map((field, index) => renderField(field, formikProps, fieldsProps, index))}
              </SC.Fields>
              <FormSubmitErrors errors={submitErrors} />
              <SC.SubmitButton {...submitButton} isDisabled={!formikProps.dirty} autoIcon />
              <SC.MuiDivider />
            </Form>
          )}
        </Formik>
      </SC.Wrapper>
    </SC.CustomThemeForm>
  )
}

export default CustomThemeForm
