import styled from 'styled-components'

import PageBackButton from '../../components/PageBackButton'
import CardList from '../../components/CardList'

export const Content = styled.div``
export const BackButton = styled(PageBackButton)``
export const Title = styled.h1`
  ${(props) => ({ ...props.theme.typography.h3 })}
  color: ${(props) => props.theme.palette.colors.neonCarrot};
  padding: 3rem 1.6rem 0rem 1.6rem;
  margin: 0;
`

export const Subtitle = styled.h2`
  ${(props) => ({ ...props.theme.typography.h4 })}
  color: ${(props) => props.theme.palette.colors.midnightBlue};
  padding: 1rem 1.6rem 0rem 1.6rem;
  margin: 1rem 0 0 0;
`

export const MyCourseCardList = styled(CardList)`
  padding: 2rem 1.6rem 0rem;
`
