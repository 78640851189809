import gql from 'graphql-tag'

import { Course, CourseRequest } from '../../../../generated/graphql'
import CourseFragment from '../../../fragments/CourseFragment'

export type PlanningCoursesResult = {
  future: { data: Course[]; total: number }
  past: { data: Course[]; total: number }
  pending: { data: Course[]; total: number }
  requests: { data: CourseRequest[]; total: number }
}

const planningCourses = {
  query: gql`
    query planningCourses($student: ID) {
      futureCourses(first: 100, student: $student) {
        data {
          ...CourseFragment
        }
        paginatorInfo {
          total
        }
      }
      pastCourses(first: 100, student: $student) {
        data {
          ...CourseFragment
        }
        paginatorInfo {
          total
        }
      }
      pendingCourses(first: 100, student: $student) {
        data {
          ...CourseFragment
        }
        paginatorInfo {
          total
        }
      }
      pendingCourseRequests(first: 100, student: $student) {
        data {
          ...CourseRequestFragment
        }
        paginatorInfo {
          total
        }
      }
    }
    ${CourseFragment}
  `,
  transformer: (response: any): PlanningCoursesResult => {
    return {
      future: {
        data: response?.futureCourses?.data,
        total: response?.futureCourses?.paginatorInfo?.total,
      },
      past: {
        data: response?.pastCourses?.data,
        total: response?.pastCourses?.paginatorInfo?.total,
      },
      pending: {
        data: response?.pendingCourses?.data,
        total: response?.pendingCourses?.paginatorInfo?.total,
      },
      requests: {
        data: response?.pendingCourseRequests?.data,
        total: response?.pendingCourseRequests?.paginatorInfo?.total,
      },
    }
  },
}

export default planningCourses
