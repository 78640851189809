import React, { FC } from 'react'

import { ImgProps } from '../../types/image'
import { IconProps, Icons } from '../Icon/types'
import { light } from '../../theme/palette'
import { RatingCustomProps } from '../RatingCustom'
import { LinkProps } from '../Link'

import * as SC from './styled'

type RatingProps = RatingCustomProps & {
  text: string
}

export type ImageTextItemProps = {
  image: ImgProps
  text1?: string
  text2?: string
  text3?: string
  isAvatar?: boolean
  notificationBadge?: number | string
  hasDeleteOption?: boolean
  onDelete?: () => void
  isNotDesired?: boolean
  isSelected?: boolean
  className?: string
  rating?: RatingProps
  largeIcon?: Icons
  onClick?: () => void
  potIcon?: IconProps
  potValue?: string
  onAdd?: () => void
  link?: LinkProps
  isDisable?: boolean
  comingNextText?: string
  value?: string
  hoverable?: boolean
}

const ImageTextItem: FC<ImageTextItemProps> = (props) => {
  const {
    image,
    text1,
    text2,
    text3,
    isAvatar = false,
    notificationBadge,
    hasDeleteOption,
    onDelete,
    isNotDesired,
    isSelected,
    className = '',
    rating,
    largeIcon,
    onClick,
    potIcon,
    potValue,
    onAdd,
    link,
    isDisable,
    comingNextText,
    hoverable,
  } = props

  const handleClick = (a: any) => {
    link?.onClick?.(a)
    onClick?.()
  }

  return (
    <SC.ImageTextItem
      className={className}
      {...link}
      isDisable={isDisable}
      onClick={isDisable ? undefined : handleClick}
    >
      <SC.ImageTextItemContent
        $actionLink={!!onDelete}
        $isNotDesired={isNotDesired}
        $isSelected={isSelected}
        $hasRating={!!rating}
        $largeIcon={!!largeIcon}
        $hoverable={hoverable}
      >
        {isAvatar ? <SC.AvatarImg {...image} /> : <SC.Image {...image} />}
        {notificationBadge && <SC.NotificationBadge>{notificationBadge}</SC.NotificationBadge>}
        {isDisable && comingNextText && <SC.ComingNextText>{comingNextText}</SC.ComingNextText>}
        <SC.Text>
          <SC.Texts>
            {text1 && <SC.Text1 isDisable={isDisable}>{text1}</SC.Text1>}
            {text2 && <SC.Text2>{text2}</SC.Text2>}
            {text3 && <SC.Text3>{text3}</SC.Text3>}
          </SC.Texts>
          {potIcon && potValue && (
            <SC.Pot>
              <SC.PotIcon {...potIcon} />
              <SC.PotValue>{potValue}</SC.PotValue>
            </SC.Pot>
          )}
        </SC.Text>
        {largeIcon && <SC.LargeIcon icon={largeIcon} color={light.colors.neonCarrot} />}
        {onDelete && hasDeleteOption && (
          <SC.AbsoluteIcon icon={Icons.trash} color={light.colors.tomato} onClick={onDelete} />
        )}
        {onAdd && <SC.AbsoluteIcon icon={Icons.plus} color={light.colors.tomato} onClick={onAdd} />}
        {rating && (
          <SC.Rating>
            <SC.Stars
              size="small"
              value={rating.value}
              isNotEditable={rating?.isNotEditable}
              max={rating?.max}
            />
            <SC.RatingText>{rating.text}</SC.RatingText>
          </SC.Rating>
        )}
      </SC.ImageTextItemContent>
    </SC.ImageTextItem>
  )
}

export default ImageTextItem
