import gql from 'graphql-tag'

import PaginatorInfoFragment from '../../../fragments/PaginatorInfoFragment'
import { PaginatorInfo, WalletTransaction } from '../../../../generated/graphql'
import WalletTransactionFragment from '../../../fragments/WalletTransactionFragment'

export type WalletTransactionsQueryResult = {
  data: WalletTransaction[]
  paginatorInfo: PaginatorInfo
}

const walletTransactions = {
  query: gql`
    query walletTransactions($first: Int!, $page: Int, $limit: TransactionLimitFilter) {
      walletTransactions(first: $first, page: $page, limit: $limit) {
        data {
          ...WalletTransactionFragment
        }
        paginatorInfo {
          ...PaginatorInfoFragment
        }
      }
    }
    ${PaginatorInfoFragment}
    ${WalletTransactionFragment}
  `,
  transformer: (response: any): WalletTransactionsQueryResult => {
    return {
      data: response.walletTransactions?.data?.map((transaction: WalletTransaction) => ({
        ...transaction,
        model: {
          ...transaction.model,
          ...((transaction.model as any)?.requestLesson && {
            lesson: (transaction.model as any).requestLesson,
          }),
        },
      })),
      paginatorInfo: response.walletTransactions?.paginatorInfo,
    }
  },
}

export default walletTransactions
