import styled from 'styled-components'

import PageTitle from '../../components/PageTitle'
import PageBackButton from '../../components/PageBackButton'
import AvailabilityRecurrenceFormC from '../../components/AvailabilityRecurrenceForm'

export const Content = styled.div``

export const Title = styled(PageTitle)``
export const BackButton = styled(PageBackButton)``

export const Form = styled(AvailabilityRecurrenceFormC)`
  padding: 0 1.6rem 1.6rem;
`
