import styled, { css } from 'styled-components'

import ActionButton from '../ActionButton'

export const Pot = styled.div``

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 1.6rem;
  ${(props) => props.theme.breakpoints.up('md')} {
    max-width: 47rem;
    width: 100%;
    align-items: flex-start;
  }
`

export const RightSide = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  ${(props) => props.theme.breakpoints.up('md')} {
    text-align: left;
    align-items: flex-start;
  }
`

export const Content = styled.div<{ isRow?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  ${(props) => props.theme.breakpoints.up('md')} {
    flex-direction: ${({ isRow }) => (isRow ? 'row' : 'column')};
    justify-content: flex-start;
    align-items: flex-start;
    text-align: center;
    ${({ isRow }) =>
      isRow &&
      css`
        ${RightSide} & {
          margin-left: 3.2rem;
        }
      `}
  }
`

export const BoxImage = styled.div`
  display: flex;
  justify-content: center;
`

export const Image = styled.img`
  width: 14rem;
  height: auto;
  ${(props) => props.theme.breakpoints.up('md')} {
    width: 22rem;
  }
`

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${(props) => props.theme.breakpoints.up('md')} {
    align-items: flex-start;
  }
`

export const Text = styled.p`
  ${(props) => ({ ...props.theme.typography.h6 })};
  color: ${(props) => props.theme.palette.colors.neonCarrot};
  text-align: center;
  ${(props) => props.theme.breakpoints.up('md')} {
    text-align: left;
  }
`

export const CreditsWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  ${(props) => props.theme.breakpoints.up('md')} {
    flex-direction: row;
    justify-content: center;
  }
`

export const Credits = styled.span`
  ${(props) => ({ ...props.theme.typography.h1 })};
  color: ${(props) => props.theme.palette.colors.tomato};
  font-size: 4rem;
`

export const Available = styled.span`
  ${(props) => ({ ...props.theme.typography.tinyRegular })};
  color: ${(props) => props.theme.palette.colors.tomato};
  ${(props) => props.theme.breakpoints.up('md')} {
    padding-left: 0.5rem;
  }
`

export const OtherCredits = styled.span<{ isRed?: boolean }>`
  ${(props) =>
    props.isRed
      ? { ...props.theme.typography.smallBold }
      : { ...props.theme.typography.smallRegular }};
  color: ${(props) =>
    props.isRed ? props.theme.palette.colors.tomato : props.theme.palette.colors.grey};
  margin-top: 1rem;
`

export const ParentsText = styled.span`
  ${(props) => ({ ...props.theme.typography.tinyStrong })};
  color: ${(props) => props.theme.palette.colors.grey};
  margin-top: 0.4rem;
`

export const HelperText = styled.p`
  ${(props) => ({ ...props.theme.typography.smallRegular })};
  color: ${(props) => props.theme.palette.colors.midnightBlue};
  max-width: 28rem;
  margin-bottom: 0;
`

export const Button = styled(ActionButton)`
  margin: 1.6rem 0 0 0;
  width: auto;
`

export const SubButton = styled(ActionButton)`
  margin: 1.6rem 0 0 0;
  width: auto;
`
