import gql from 'graphql-tag'

import { CourseRequest } from '../../../../generated/graphql'
import CourseRequestFragment from '../../../fragments/CourseRequestFragment'

const requestCourse = {
  mutation: gql`
    mutation requestCourse(
      $lesson: ID!
      $student: ID
      $type: CourseType!
      $maximum_price: Int!
      $maximum_participants: Int!
      $minimum_score: Float
      $notice_period: Int!
      $availabilities: [AvailabilityInput!]!
    ) {
      requestCourse(
        lesson: $lesson
        student: $student
        type: $type
        maximum_price: $maximum_price
        maximum_participants: $maximum_participants
        minimum_score: $minimum_score
        notice_period: $notice_period
        availabilities: $availabilities
      ) {
        ...CourseRequestFragment
      }
    }
    ${CourseRequestFragment}
  `,
  transformer: (response: any): CourseRequest => {
    return response.requestCourse
  },
}

export default requestCourse
