import React, { FC } from 'react'

import Header, { HeaderProps } from '../../components/Header'
import SignUpLayout from '../../layouts/SignupLayout'
import LevelPickersForm, { LevelPickersFormProps } from '../../components/LevelPickersForm'
import { ImgProps } from '../../types/image'

import * as SC from './styled'

export type SignUpTeacherDisciplinesTemplateProps = {
  headerProps: HeaderProps
  signupProps: LevelPickersFormProps
  imageProps: ImgProps
}

const SignUpTeacherDisciplinesTemplate: FC<SignUpTeacherDisciplinesTemplateProps> = (props) => {
  const { headerProps, signupProps, imageProps } = props

  return (
    <SignUpLayout
      header={<Header {...headerProps} />}
      side={
        <SC.BoxImage>
          <SC.Image {...imageProps} />
        </SC.BoxImage>
      }
    >
      <SC.Wrapper>
        <LevelPickersForm {...signupProps} />
      </SC.Wrapper>
    </SignUpLayout>
  )
}

export default SignUpTeacherDisciplinesTemplate
