import { Container, Modal } from '@material-ui/core'
import styled from 'styled-components'

import CourseCard from '../../CourseCard'
import ActionButton from '../../ActionButton'
import Icon from '../../Icon'
import FormSubmit from '../../FormSubmit'

export const RequestCourseModal = styled(Modal)``

export const Wrapper = styled(Container)`
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 90%;
  height: max-content;
  inset: 50% 0 0 50%;
  transform: translate(-50%, -50%);
  background-color: ${(props) => props.theme.palette.colors.whiteSmoke};
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.12);
  border: 1px solid ${(props) => props.theme.palette.colors.ghostWhite};
  border-radius: 0.8rem;
  ${(props) => props.theme.breakpoints.up('md')} {
    width: 50%;
  }
`

export const HeaderText = styled.span`
  ${(props) => ({ ...props.theme.typography.h5 })}
  color: ${(props) => props.theme.palette.colors.neonCarrot};
  margin: 1.6rem 0;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Title = styled.h4`
  ${(props) => ({ ...props.theme.typography.h4 })}
  color: ${(props) => props.theme.palette.colors.freeSpeechBlue};
  margin: 0;
`

export const MyIcon = styled(Icon)`
  width: 6.4rem;
  height: 6.4rem;
`

export const Text = styled.p<{ aligned?: boolean }>`
  ${(props) => ({ ...props.theme.typography.smallRegular })}
  color: ${(props) => props.theme.palette.colors.chambray};
  margin-bottom: 0;
  text-align: ${(props) => (props.aligned ? 'center' : 'left')};
`

export const Card = styled(CourseCard)`
  margin-top: 2rem;
  width: 100%;
`

export const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  margin-bottom: 2rem;
  & > button {
    &:first-child {
      margin-right: 1rem;
    }
    &:last-child {
      margin-left: 1rem;
    }
  }
`

export const Button = styled(ActionButton)``
export const Submit = styled(FormSubmit)``
