import gql from 'graphql-tag'

const forgotPassword = {
  mutation: gql`
    mutation forgotPassword($email: String!) {
      forgotPassword(email: $email)
    }
  `,
  transformer: (response: any): string => {
    return response.forgotPassword
  },
}

export default forgotPassword
