import styled from 'styled-components'
import { FormHelperText } from '@material-ui/core'

import InputLabel from '../InputLabel'
import CalendarC from '../Calendar'
import ToggleButtonPicker from '../ToggleButtonPicker'

export const DatePicker = styled.div`
  width: 100%;
`

export const Label = styled(InputLabel)`
  color: ${({ theme }) => theme.palette.colors.midnightBlue};
  margin-bottom: 0.8rem;
`

export const Wrapper = styled.div``

export const Calendar = styled(CalendarC)``

export const SlotPicker = styled(ToggleButtonPicker)`
  margin-top: 2rem;
`

export const HelperText = styled(FormHelperText)`
  text-align: left;
  ${(props) => ({ ...props.theme.typography.smallRegular })}
  color: ${(props) => props.theme.palette.colors.grey};
  background-color: ${(props) => props.theme.palette.colors.whiteSmoke};
  margin: 3px 0 0 0;
  &.Mui-error {
    color: ${(props) => props.theme.palette.error.main};
  }
`
