import styled, { css } from 'styled-components'
import { Avatar, Card } from '@material-ui/core'

import ActionButton from '../ActionButton'
import Icon from '../Icon'
import RatingCustom from '../RatingCustom'
import Link from '../Link'

export const ImageTextItem = styled(Link)<{ isDisable?: boolean }>`
  width: 100%;
  max-width: 59rem;
  padding: 0rem;
  cursor: ${({ isDisable }) => (isDisable ? 'default' : 'pointer')};
  display: flex;
  text-decoration: none;
  ${(props) => props.theme.breakpoints.up('md')} {
    max-width: 47rem;
  }
  &:hover {
    text-decoration: none;
  }
`

export const ImageTextItemContent = styled(Card)<{
  $actionLink: boolean
  $isNotDesired?: boolean
  $isSelected?: boolean
  $hasRating: boolean
  $largeIcon?: boolean
  $hoverable?: boolean
}>`
  min-height: ${({ $actionLink, $hasRating }) =>
    $hasRating ? '13.4rem' : $actionLink ? '12.4rem' : '11.2rem'};
  padding: 0 1.6rem 0;
  display: grid;
  grid-template-columns: ${({ $largeIcon }) => ($largeIcon ? '8rem 1fr 3rem' : '8rem 1fr')};
  grid-column-gap: 0.8rem;
  align-items: center;
  width: 100%;
  position: relative;
  box-shadow: none;
  border: ${(props) =>
    `1px solid ${
      props.$isNotDesired
        ? props.theme.palette.colors.kournikova
        : props.$isSelected
        ? props.theme.palette.colors.cornflowerBlue
        : props.theme.palette.colors.ghostWhite
    }`};
  border-radius: 0.8rem;
  ${(props) =>
    props.$hoverable &&
    css`
      &:hover {
        cursor: pointer;
        border-color: ${props.theme.palette.colors.cornflowerBlue};
      }
    `}
`

export const Image = styled.img`
  height: 8rem;
  width: 8rem;
  display: block;
`

export const AvatarImg = styled(Avatar)`
  height: 6.4rem;
  width: 6.4rem;
`

export const Text = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const Texts = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const Text1 = styled.div<{ isDisable?: boolean }>`
  ${(props) => ({ ...props.theme.typography.h5 })}
  word-break: break-word;
  color: ${(props) =>
    props.isDisable
      ? props.theme.palette.colors.echoBlue
      : props.theme.palette.colors.freeSpeechBlue};
  & + div {
    margin-top: 0.5rem;
  }
`

export const Text2 = styled.div`
  ${(props) => ({ ...props.theme.typography.h7 })}
  color: ${(props) => props.theme.palette.colors.cornflowerBlue};
`

export const Text3 = styled.div`
  ${(props) => ({ ...props.theme.typography.tinySmall })}
  color: ${(props) => props.theme.palette.colors.grey};
`

export const Pot = styled.div`
  display: flex;
  flex-direction: column;
`

export const PotIcon = styled(Icon)`
  width: 2.2rem;
  height: 2rem;
`

export const PotValue = styled.div`
  ${(props) => ({ ...props.theme.typography.h6 })}
  color: ${(props) => props.theme.palette.colors.neonCarrot};
`

export const NotificationBadge = styled.div`
  ${(props) => ({ ...props.theme.typography.h6 })}
  position: absolute;
  background: ${(props) => props.theme.palette.colors.neonCarrot};
  padding: 0.2rem 0.7rem;
  border-radius: 0.8rem;
  color: ${(props) => props.theme.palette.colors.pureWhite};
  top: 8px;
  right: 8px;
`

export const ComingNextText = styled.div`
  ${(props) => ({ ...props.theme.typography.h6 })}
  position: absolute;
  background: ${(props) => props.theme.palette.colors.kournikova};
  padding: 0.2rem 0.7rem;
  border-radius: 0.8rem;
  color: ${(props) => props.theme.palette.colors.pureWhite};
  top: 8px;
  right: 8px;
`

export const ActionLink = styled(ActionButton)`
  position: absolute;
  inset: auto 1.6rem 1.6rem auto;
  margin: 0;
  color: ${(props) => props.theme.palette.colors.neonCarrot};
`

export const AbsoluteIcon = styled(Icon)`
  width: 3.4rem;
  height: 3.4rem;
  position: absolute;
  right: 1rem;
  bottom: 1rem;
`

export const LargeIcon = styled(Icon)`
  color: ${({ theme }) => theme.palette.colors.neonCarrot};
  width: 2rem;
  height: 2rem;
`

export const Rating = styled.div`
  display: grid;
  grid-template-columns: 1fr 5rem;
  align-items: center;
  width: 100%;
  grid-column: 1 / 3;
`

export const RatingText = styled.div`
  ${({ theme }) => ({ ...theme.typography.h4 })}
  color: ${({ theme }) => theme.palette.colors.neonCarrot};
`

export const Stars = styled(RatingCustom)``
