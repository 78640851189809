import React, { FC } from 'react'
import StarBorderIcon from '@material-ui/icons/StarBorder'
import { RatingProps } from '@material-ui/lab'

import * as SC from './styled'

export type RatingCustomProps = {
  className?: string
  name?: string
  value?: number
  handleRatingClick?: (v: number) => void
  onChange?: (name: string, v: number) => void
  isNotEditable?: boolean
  size?: RatingProps['size']
  max?: number
  error?: string
  help?: string
}

const RatingCustom: FC<RatingCustomProps> = (props) => {
  const {
    className,
    name,
    value = 0,
    handleRatingClick,
    isNotEditable,
    size = 'large',
    max,
    onChange,
    error,
    help,
  } = props

  return (
    <SC.RatingCustom className={className}>
      <SC.Wrapper disableGutters maxWidth={false}>
        <SC.MuiRating
          name={name}
          {...(!isNotEditable ? { value } : { defaultValue: value })}
          precision={isNotEditable ? 0.1 : 1}
          size={size}
          emptyIcon={<StarBorderIcon fontSize="inherit" />}
          onChange={(_e, v) => {
            handleRatingClick?.(v ?? 0)
            onChange?.(name ?? '', v ?? 0)
          }}
          max={max || (value && Math.round(value))}
          readOnly={isNotEditable}
        />
      </SC.Wrapper>
      {(error || help) && <SC.HelperText error={!!error}>{error || help}</SC.HelperText>}
    </SC.RatingCustom>
  )
}

export default RatingCustom
