import styled from 'styled-components'
import { InputLabel } from '@material-ui/core'

export const MuiInputLabel = styled(InputLabel)`
  ${(props) => ({ ...props.theme.typography.h5 })};
  color: ${(props) => props.theme.palette.colors.midnightBlue};

  & .MuiInputLabel-asterisk {
    color: ${(props) => props.theme.palette.colors.neonCarrot};
  }

  & a {
    color: ${(props) => props.theme.palette.colors.freeSpeechBlue};
  }
`
