import React, { FC } from 'react'

import Header, { HeaderProps } from '../../components/Header'
import SignUpLayout from '../../layouts/SignupLayout'
import SignUpPersonalInfos, { SignUpPersonalInfosProps } from '../../components/SignUpPersonalInfos'
import { ImgProps } from '../../types/image'

export type SignUpPersonalInfosTemplateProps = {
  className?: string
  headerProps: HeaderProps
  signupProps: SignUpPersonalInfosProps
  imageProps: ImgProps
}

const SignUpPersonalInfosTemplate: FC<SignUpPersonalInfosTemplateProps> = (props) => {
  const { headerProps, signupProps } = props

  return (
    <SignUpLayout header={<Header {...headerProps} />}>
      <SignUpPersonalInfos {...signupProps} />
    </SignUpLayout>
  )
}

export default SignUpPersonalInfosTemplate
