import React from 'react'
import { useTranslation } from 'react-i18next'

import { HeaderProps } from '../../components/Header'
import useHeader from '../../hooks/useHeader'
import SignUpTeacherPendingTemplate from '../../templates/SignUpTeacherPending'
import { app } from '../../configuration'
import { ActionButtonProps } from '../../components/ActionButton'
import { BasicTextAndImageProps } from '../../components/BasicTextAndImage'
import { router, routesPath } from '../../router'

const SignUpTeacherPendingPage: React.FC = (_props) => {
  const headerProps: HeaderProps = useHeader()

  const { t } = useTranslation()

  const textProps: BasicTextAndImageProps = {
    title: t('signUpTeacherPending_title'),
    subTitle: t('signUpTeacherPending_subtitle'),
    text: t('signUpTeacherPending_text'),
    image: { alt: 'LiberteClass - Merci', src: '/static/assets/images/completeInfoStep3.png' },
  }

  const cta: ActionButtonProps = {
    text: t('signUpTeacherPending_back'),
    link: {
      link: app.PUBLIC_URL,
    },
    type: 'button',
  }

  const logoutButtonProps: ActionButtonProps = {
    text: t('logout'),
    link: {
      link: router(routesPath.signOut),
    },
  }

  return (
    <SignUpTeacherPendingTemplate
      headerProps={headerProps}
      textProps={textProps}
      cta={cta}
      logoutButtonProps={logoutButtonProps}
    />
  )
}

export default SignUpTeacherPendingPage
