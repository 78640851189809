import React, { useCallback, useEffect, useMemo } from 'react'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'

import { HeaderProps } from '../../components/Header'
import useHeader from '../../hooks/useHeader'
import { MenuProps } from '../../components/Menu'
import useMenu from '../../hooks/useMenu'
import { AvailabilitySlot, CourseType, Lesson, Theme, UserType } from '../../generated/graphql'
import { RestrictedReactFC } from '../../types/common'
import { actions, selectors } from '../../redux'
import { router, routesPath } from '../../router'
import { courseTypeBySlug } from '../../graphql/enums/CourseType'
import { useFormSubmit } from '../../hooks/useFormSubmit'
import SearchAvailabilitiesTemplate, {
  SearchAvailabilitiesTemplateProps,
} from '../../templates/SearchAvailabilities'
import { availabilitySlotOptions, hourToSlot } from '../../graphql/enums/AvailabilitySlot'
import { SearchSteps } from '../../redux/search/types/state'
import useBackButton from '../../hooks/useBackButton'
import { getISODateString } from '../../transformers/courseTransformers'

const SearchAvailabilitiesPage: RestrictedReactFC<
  RouteComponentProps<{
    type: string
    discipline: string
    theme: string
    price: string
    size: string
    child?: string
  }>
> = (props) => {
  const headerProps: HeaderProps = useHeader()
  const menuProps: MenuProps = useMenu(props)
  const { t, i18n } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const user = useSelector(selectors.auth.user)
  const disciplines = useSelector(selectors.app.disciplines)
  const themes = useSelector(selectors.search.themes)
  const lesson = useSelector(selectors.search.lesson)
  const typeSlug = props?.match?.params?.type
  const child = props?.match?.params?.child
  const size = props?.match?.params?.size
  const type: CourseType = courseTypeBySlug(t, typeSlug)
  const disciplineSlug = props?.match?.params?.discipline
  const discipline = useMemo(
    () => disciplines?.find((discipline) => discipline.slug === disciplineSlug),
    [disciplines, disciplineSlug]
  )
  const student =
    user?.user_type === UserType.Student
      ? user?.student
      : user?.guardian?.children?.find((c) => c.id === child) ?? user?.guardian?.children?.[0]

  const themeSlug = props?.match?.params?.theme
  const theme = useMemo(() => {
    return (
      themes?.data
        ?.reduce((arr: Lesson[], th: Theme) => [...arr, ...(th?.lessons ? th.lessons : [])], [])
        ?.find((l: Lesson) => l.slug === themeSlug) ?? lesson
    )
  }, [themes?.data, themeSlug, lesson])
  const price = props?.match?.params?.price
  const availabilities = useSelector(selectors.search.availabilities)

  const studentDisabledDates = useSelector(selectors.search.studentDisabledDates)
  const disabledSlots = useMemo(() => {
    const slots =
      studentDisabledDates?.data?.reduce((arr, date) => {
        const key = getISODateString(date.date)
        if (!arr?.[key]) {
          arr[key] = []
        }
        arr[key].push(date.slot)

        return arr
      }, {} as { [key: string]: string[] }) ?? {}

    // remove passed slots

    const today = dayjs()
    const passedSlots = []
    let hour = today.hour()
    let slot
    while ((slot = hourToSlot(hour--))) {
      passedSlots.unshift(slot)
    }
    const todayIsoString = getISODateString()
    if (!slots?.[todayIsoString]) {
      slots[todayIsoString] = passedSlots
    } else {
      slots[todayIsoString] = slots[todayIsoString].concat(passedSlots)
    }

    return slots
  }, [studentDisabledDates?.data])

  const availabilitiesFiltered:
    | {
        date: string
        slots: AvailabilitySlot[]
      }[]
    | undefined = useMemo(() => {
    if (!disabledSlots) {
      return availabilities
    }
    const flatAvailabilities = availabilities?.reduce((arr, av) => {
      return arr.concat(
        av.slots
          ?.filter((slot) => !disabledSlots?.[av.date]?.includes(slot))
          ?.map((slot) => ({
            date: av.date,
            slot,
          })) ?? []
      )
    }, [] as { date: string; slot: AvailabilitySlot }[])
    return (
      Object.values(
        flatAvailabilities?.reduce((arr, av) => {
          if (!arr?.[av.date]) {
            arr[av.date] = { date: av.date, slots: [] }
          }
          arr[av.date].slots.push(av.slot)
          return arr
        }, {} as { [key: string]: { date: string; slots: AvailabilitySlot[] } }) ?? {}
      ) ?? []
    )
  }, [availabilities, disabledSlots])

  const backButton = useBackButton(
    router(routesPath.searchPrice, {
      type: typeSlug,
      discipline: disciplineSlug,
      theme: themeSlug,
      ...(child && { child }),
    })
  )

  const onComplete = useCallback(() => {
    history.push(router(routesPath.searchResults))
  }, [history])

  const [search, handleSearch] = useFormSubmit(
    selectors.search.search,
    actions.search.searchRequest,
    null,
    onComplete
  )

  const handleSubmit = useCallback(
    (values) => {
      dispatch(
        actions.search.saveStep({
          step: SearchSteps.STEP_4_AVAILABILITIES,
          availabilities: values?.availabilities,
          price,
          student,
          discipline,
          lesson: theme,
          type,
          size,
        })
      )
      handleSearch({
        first: 25,
        page: 1,
        student: student?.id,
        type,
        theme: theme?.id,
        price,
        availabilities: values.availabilities,
        size,
      })
    },
    [handleSearch, price, student, theme, discipline, type, dispatch, size]
  )

  const weekDays = useMemo(
    () =>
      dayjs
        .localeData()
        .weekdays()
        ?.map((d) => d.substr(0, 1).toUpperCase()),
    []
  )

  const months = useMemo(
    () =>
      dayjs
        .localeData()
        .months()
        ?.map((d) => d.substr(0, 1).toUpperCase().concat(d.substr(1))),
    []
  )

  const searchProps: SearchAvailabilitiesTemplateProps = {
    headerProps: headerProps,
    menuProps: menuProps,
    title: t('search_title'),
    subTitle: t('searchAvailabilities_title'),
    text: t('searchAvailabilities_text'),
    availabilityFormProps: {
      initialValues: {
        availabilities: availabilitiesFiltered ?? [],
      },
      calendar: {
        dateDisableBefore: new Date(),
        today: new Date(),
        locale: {
          lang: i18n.language,
          months: months,
          weekdaysS: weekDays,
          weekdaysL: weekDays,
        },
      },
      slot: {
        name: '',
        fields: availabilitySlotOptions(t),
      },
      disabledSlots,
      submitButton: {
        text: t('searchAvailabilities_submit'),
        isPending: search?.params?.availabilities && search?.pending,
      },
      submitErrors: search?.errors,
      onSubmit: handleSubmit,
      errorTexts: {
        required: t('error_availabilities'),
      },
      cancelButton: {
        text: t('searchAvailabilities_submit_alt'),
        isPending: !search?.params?.availabilities && search?.pending,
        onClick: () => {
          dispatch(
            actions.search.saveStep({
              step: SearchSteps.STEP_4_AVAILABILITIES,
              price,
              student,
              discipline,
              lesson: theme,
              availabilities: [],
              type,
              size,
            })
          )
          handleSearch({
            first: 25,
            page: 1,
            student: student?.id,
            type,
            theme: theme?.id,
            price,
            size,
            availabilities: [],
          })
        },
      },
    },
    backButton,
  }

  useEffect(() => {
    if (discipline?.id && student?.grade?.id) {
      if (
        themes?.params?.grade !== student?.grade?.id ||
        themes?.params?.discipline !== discipline?.id
      ) {
        dispatch(
          actions.search.themesRequest({
            discipline: discipline?.id,
            grade: student?.grade?.id,
          })
        )
      }
    }
  }, [discipline?.id, dispatch, student?.grade?.id, themes?.params])

  useEffect(() => {
    dispatch(actions.search.studentDisabledDatesRequest(child ? { student: child } : undefined))
  }, [dispatch, child])

  return <SearchAvailabilitiesTemplate {...searchProps} />
}

SearchAvailabilitiesPage.restrictedUserTypes = [UserType.Guardian, UserType.Student]

export default SearchAvailabilitiesPage
