import React, { FC } from 'react'
import NumberFormat from 'react-number-format'

import TextField, { TextFieldProps } from '../TextField'

export type NumberProps = TextFieldProps & {
  className?: string
  maxValue?: number
}

const CustomNumberFormat: FC<any> = (props) => {
  const { inputRef, onChange, ...other } = props

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }}
      onBlur={(e) => {
        other?.onBlur?.(e)
        if (other?.maxValue && parseFloat(other?.value) > other?.maxValue) {
          onChange({
            target: {
              name: props.name,
              value: other?.maxValue,
            },
          })
        }
      }}
      thousandSeparator={' '}
      decimalSeparator={','}
      inputMode={'numeric'}
      allowNegative={false}
      isNumericString
    />
  )
}

const Number: FC<NumberProps> = (props) => {
  return (
    <TextField
      {...props}
      InputProps={{
        inputComponent: CustomNumberFormat as any,
        ...props.InputProps,
      }}
      inputProps={{
        ...props?.inputProps,
        ...(props.maxValue && { maxValue: props?.maxValue }),
      }}
    />
  )
}

export default Number
