import React, { FC } from 'react'
import { FieldArray, Formik, FormikHelpers } from 'formik'
import * as Yup from 'yup'

import { getValidationSchema, renderField } from '../../helpers/FormHelpers'
import { FormFieldConfig, FormFieldProps } from '../../types/form'
import FormSubmitErrors, { FormSubmitErrorsProps } from '../FormSubmitErrors'
import { FormSubmitProps } from '../FormSubmit'
import FormScrollToError from '../FormScrollToError'

import PricePicker from './PricePicker'
import * as SC from './styled'

export enum PricingType {
  UNIQUE = 'unique',
  CUSTOM = 'custom',
}

export type PricesChoiceFormValues = {
  type: string
  unique: string
  custom: { [key: string]: string }[]
}

export type PricesChoiceFormProps = {
  className?: string
  title?: string
  submitButton?: FormSubmitProps
  initialValues: PricesChoiceFormValues
  uniqueLabel?: string
  uniqueText?: string
  customLabel?: string
  customText?: string
  fieldsProps?: {
    type: FormFieldProps
    unique: FormFieldProps
    custom: FormFieldProps
  }
  errorTexts?: {
    required: string
  }
  submitErrors?: FormSubmitErrorsProps['errors']
  onSubmit?: (
    values: PricesChoiceFormValues,
    formikHelpers: FormikHelpers<PricesChoiceFormValues>
  ) => void
}

const PricesChoiceForm: FC<PricesChoiceFormProps> = (props) => {
  const {
    className,
    title,
    uniqueLabel,
    uniqueText,
    customLabel,
    customText,
    submitButton,
    initialValues,
    errorTexts,
    fieldsProps,
    submitErrors,
    onSubmit = (_v: PricesChoiceFormValues) => null,
  } = props

  const typeField: FormFieldConfig = {
    name: 'type',
    validation: Yup.string(),
  }
  const uniqueField: FormFieldConfig = {
    name: 'unique',
    Component: SC.TextField,
    validation: Yup.string().when('type', {
      is: PricingType.UNIQUE,
      then: Yup.string().required(errorTexts?.required),
    }),
    required: true,
  }
  const customField: FormFieldConfig = {
    name: 'custom',
    Component: SC.TextField,
    validation: Yup.array().when('type', {
      is: PricingType.CUSTOM,
      then: Yup.array(
        Yup.lazy((value, opts) => {
          const index = (opts as any)?.index
          const group = fieldsProps?.custom?.groups?.[index]
          return Yup.object(
            group?.levels?.reduce(
              (arr: { [key: string]: any }, level: any) => ({
                ...arr,
                [level?.name]: Yup.string().required(errorTexts?.required),
              }),
              {}
            )
          )
        })
      ),
    }),
    required: true,
  }

  const fields = [typeField, uniqueField, customField]

  const validationSchema = getValidationSchema(fields)

  return (
    <SC.PricesChoiceForm className={className}>
      <SC.Wrapper maxWidth="xl" disableGutters>
        {title && <SC.Title>{title}</SC.Title>}
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnChange={true}
          validateOnBlur={true}
          enableReinitialize
          onSubmit={onSubmit}
        >
          {(formikProps) => (
            <SC.MyForm noValidate>
              <FormScrollToError formikProps={formikProps} />
              <SC.TabContent
                name="type"
                tabs={[
                  {
                    label: uniqueLabel,
                    value: PricingType.UNIQUE,
                    children: (
                      <>
                        {uniqueText && <SC.Text>{uniqueText}</SC.Text>}
                        {renderField(uniqueField, formikProps, fieldsProps)}
                      </>
                    ),
                  },
                  {
                    label: customLabel,
                    value: PricingType.CUSTOM,
                    children: (
                      <>
                        {customText && <SC.Text>{customText}</SC.Text>}
                        <SC.PricesWrapper>
                          <FieldArray
                            name={customField.name!}
                            render={() =>
                              formikProps.values?.custom?.map((custom, customIndex) => {
                                const group = fieldsProps?.custom?.groups?.[customIndex]
                                return (
                                  <PricePicker
                                    label={group?.label}
                                    name={'custom.' + customIndex}
                                    options={group?.levels}
                                    values={custom}
                                    key={customIndex}
                                    errors={formikProps.errors?.custom?.[customIndex]}
                                    touched={formikProps.touched?.custom?.[customIndex]}
                                    onBlur={formikProps.setFieldTouched}
                                    onChange={formikProps.setFieldValue}
                                  />
                                )
                              })
                            }
                          />
                        </SC.PricesWrapper>
                      </>
                    ),
                  },
                ]}
                value={formikProps.values['type']}
                onChange={formikProps.setFieldValue}
              />
              <FormSubmitErrors errors={submitErrors} />
              <SC.Buttons>
                {submitButton && (
                  <SC.SubmitButton
                    {...submitButton}
                    isSuccess={submitButton?.isSuccess && !formikProps.dirty}
                    autoIcon
                  />
                )}
              </SC.Buttons>
            </SC.MyForm>
          )}
        </Formik>
      </SC.Wrapper>
    </SC.PricesChoiceForm>
  )
}

export default PricesChoiceForm
