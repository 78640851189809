import gql from 'graphql-tag'

const themes = {
  query: gql`
    query themes($discipline: ID!, $grade: ID!) {
      themes(first: 500, discipline: $discipline, grade: $grade) {
        data {
          id
          name
          slug
          lessons {
            id
            name
            slug
            description
          }
        }
      }
    }
  `,
  transformer: (response: any): string => {
    return response.themes?.data
  },
}

export default themes
