import React, { FC } from 'react'

import ModalContainer from '../ModalContainer'
import { WalletPeriodicityFormProps } from '../../WalletPeriodicityForm'

import * as SC from './styled'

export type WalletPeriodicityModalProps = {
  className?: string
  headerText: string
  text?: string
  options?: { label: string; description: string }[]
  open: boolean
  formProps: WalletPeriodicityFormProps
  onClose?: () => void
}

const WalletPeriodicityModal: FC<WalletPeriodicityModalProps> = (props) => {
  const { className, headerText, open, onClose, formProps, text, options } = props

  return (
    <ModalContainer open={open} onClose={onClose} className={className}>
      <SC.HeaderText>{headerText}</SC.HeaderText>
      <SC.Content>
        {text && <SC.Text>{text}</SC.Text>}
        {options?.map((option, key) => (
          <SC.Option key={key}>
            <SC.OptionLabel>{option.label}</SC.OptionLabel>
            <SC.OptionDescription>{option.description}</SC.OptionDescription>
          </SC.Option>
        ))}
        <SC.Form {...formProps} />
      </SC.Content>
    </ModalContainer>
  )
}

export default WalletPeriodicityModal
