import React, { FC } from 'react'

import TextField, { TextFieldProps } from '../TextField'

export type TextFieldLimitedCharsProps = TextFieldProps & {
  maxChar?: number
  maxCharLabelResolver?: (maxChar: number, count: number) => string
}

const TextFieldLimitedChars: FC<TextFieldLimitedCharsProps> = ({
  maxChar,
  maxCharLabelResolver,
  value,
  helperText,
  ...props
}) => {
  const help =
    maxChar && maxCharLabelResolver
      ? maxCharLabelResolver?.(maxChar, (value as string)?.length ?? 0)
      : helperText

  return <TextField {...props} value={value} helperText={help} />
}

export default TextFieldLimitedChars
