import React, { FC, useMemo } from 'react'
import { Formik, FormikHelpers } from 'formik'
import * as Yup from 'yup'

import { ActionButtonProps } from '../../ActionButton'
import ModalContainer from '../ModalContainer'
import { FormSubmitProps } from '../../FormSubmit'
import FormSubmitErrors, { FormSubmitErrorsProps } from '../../FormSubmitErrors'
import { FormFieldProps } from '../../../types/form'
import { getValidationSchema, renderField } from '../../../helpers/FormHelpers'

import * as SC from './styled'

export type ChangePasswordValues = {
  current: string
  password: string
  passwordConfirmation: string
}

export type ChangePasswordModalProps = {
  className?: string
  title?: string
  text?: string
  cancelButton?: ActionButtonProps
  submitButton?: FormSubmitProps
  open: boolean
  handleClose: () => void
  onClose?: () => void
  fieldsProps?: {
    [key: string]: FormFieldProps
  }
  errorTexts?: {
    required: string
    password: string
    confirmation: string
  }
  initialValues: ChangePasswordValues
  submitErrors?: FormSubmitErrorsProps['errors']
  onSubmit?: (values: ChangePasswordValues, helpers: FormikHelpers<ChangePasswordValues>) => void
}

const ChangePasswordModal: FC<ChangePasswordModalProps> = (props) => {
  const {
    className,
    title,
    text,
    cancelButton,
    submitButton,
    open,
    handleClose,
    onClose,
    initialValues,
    fieldsProps,
    errorTexts,
    submitErrors,
    onSubmit = (_v, _h) => null,
  } = props

  const fields = useMemo(
    () => [
      {
        name: 'current',
        Component: SC.TextField,
        validation: Yup.string().required(errorTexts?.required),
        required: true,
        type: 'password',
      },
      {
        name: 'password',
        Component: SC.TextField,
        validation: Yup.string()
          .required(errorTexts?.required)
          .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*)[^ ]{8,}$/, errorTexts?.password),
        required: true,
        type: 'password',
      },
      {
        name: 'passwordConfirmation',
        Component: SC.TextField,
        validation: Yup.string()
          .required(errorTexts?.required)
          .oneOf([Yup.ref('password'), null], errorTexts?.confirmation),
        required: true,
        type: 'password',
      },
    ],
    [errorTexts]
  )

  const validationSchema = getValidationSchema(fields)

  return (
    <ModalContainer open={open} onClose={onClose} className={className}>
      <SC.Content>
        {title && <SC.Title>{title}</SC.Title>}
        {text && <SC.Text>{text}</SC.Text>}
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnChange={true}
          validateOnBlur={true}
          onSubmit={onSubmit}
        >
          {(formikProps) => (
            <SC.Form noValidate>
              {fields.map((field, index) => renderField(field, formikProps, fieldsProps, index))}
              <FormSubmitErrors errors={submitErrors} />
              <SC.Buttons>
                {cancelButton && submitButton && (
                  <>
                    <SC.Button {...cancelButton} outlined onClick={handleClose} />
                    <SC.Submit {...submitButton} autoIcon />
                  </>
                )}
              </SC.Buttons>
            </SC.Form>
          )}
        </Formik>
      </SC.Content>
    </ModalContainer>
  )
}

export default ChangePasswordModal
