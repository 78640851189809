import styled from 'styled-components'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'

import InputLabel from '../InputLabel'

export const DatePicker = styled.div`
  width: 100%;
`

export const Label = styled(InputLabel)`
  color: ${({ theme }) => theme.palette.colors.midnightBlue};
  margin-bottom: 0.8rem;
`

export const MuiPickersProvider = styled(MuiPickersUtilsProvider)``

export const MuiKeyboardDatePicker = styled(KeyboardDatePicker)`
  width: 100%;
  margin: 0;
  background-color: ${(props) => props.theme.palette.colors.pureWhite};
  & .MuiSvgIcon-root {
    color: ${({ theme }) => theme.palette.colors.cornflowerBlue};
  }
`
