import gql from 'graphql-tag'

import TeacherFragment from './TeacherFragment'
import GuardianFragment from './GuardianFragment'
import StudentFragment from './StudentFragment'
import WalletFragment from './WalletFragment'

export default gql`
  fragment UserFragment on User {
    id
    token
    user_type
    first_name
    last_name
    email
    phone
    display_address
    address
    city
    zip_code
    status
    avatar
    teacher {
      ...TeacherFragment
    }
    guardian {
      ...GuardianFragment
    }
    student {
      ...StudentFragment
    }
    wallet {
      ...WalletFragment
    }
    unread_notifications
  }
  ${TeacherFragment}
  ${GuardianFragment}
  ${StudentFragment}
  ${WalletFragment}
`
