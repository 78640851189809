import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { HeaderProps } from '../../components/Header'
import useHeader from '../../hooks/useHeader'
import { MenuProps } from '../../components/Menu'
import useMenu from '../../hooks/useMenu'
import { actions, selectors } from '../../redux'
import { dateAndSlotToDate } from '../../graphql/enums/AvailabilitySlot'
import { courseTypeTranslation } from '../../graphql/enums/CourseType'
import CourseTemplate, { CourseTemplateProps, RatingGroup } from '../../templates/Course'
import { Icons } from '../../components/Icon/types'
import { router, routesPath } from '../../router'
import { ImageTextItemProps } from '../../components/ImageTextItem'
import NotFoundTemplate from '../../templates/NotFound'
import PreloadTemplate from '../../templates/Preload'
import { Course, CourseStatus, UserType } from '../../generated/graphql'
import { light } from '../../theme/palette'
import { CourseDetailsProps } from '../../components/CourseDetails'
import { RatingsFormProps } from '../../components/RatingsForm'
import {
  ratingModalityTransformer,
  ratingWithFieldsTransformer,
} from '../../transformers/ratingTransformers'
import { publicStudentProfileTransformer } from '../../transformers/profileTransformers'
import { useFormSubmit } from '../../hooks/useFormSubmit'
import { RatingWithFieldsProps } from '../../components/RatingWithFields'
import {
  checkCourseBegan,
  checkCourseEvaluationDoneByTeacher,
  checkCourseReportable,
  checkCourseReportableExpired,
  getCourseStatusColor,
  getCourseStatusIcon,
  getCourseStatusText,
} from '../../transformers/courseTransformers'
import useBackButton from '../../hooks/useBackButton'
import useNotFound from '../../hooks/useNotFound'
import { CancelCourseModalProps } from '../../components/modals/CancelCourseModal'
import { api, app } from '../../configuration'
import useFeedbackModal from '../../hooks/useFeedbackModal'
import useReportingModal from '../../hooks/useReportingModal'
import { courseCancelReasonOptions } from '../../graphql/enums/CourseCancelReason'
import { ActionSubmitModalProps } from '../../components/modals/ActionSubmitModal'
import { SearchSteps } from '../../redux/search/types/state'

const CoursePage: React.FC<RouteComponentProps<{ id: string }>> = (props) => {
  const headerProps: HeaderProps = useHeader()
  const menuProps: MenuProps = useMenu(props)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const id = props?.match?.params?.id
  const notFoundProps = useNotFound(headerProps)
  const courseQuery = useSelector(selectors.planning.course)
  const user = useSelector(selectors.auth.user)
  const token = useSelector(selectors.auth.token)
  const course: Course | null = courseQuery?.data
  const isCourseBegan = useMemo(() => checkCourseBegan(course), [course])
  const isEvaluationDoneByTeacher = useMemo(() => checkCourseEvaluationDoneByTeacher(course), [
    course,
  ])
  const isEvaluationDueByTeacher = isCourseBegan && !isEvaluationDoneByTeacher
  const isClassAvailable = course?.room?.available
  const isClassExpired = course?.room?.expired
  const isCancellable = !isCourseBegan && course?.status !== CourseStatus.Cancelled
  const isReportable = useMemo(() => !app.DISPUTE_DISABLED && checkCourseReportable(course), [
    course,
  ])
  const isReportExpired = useMemo(() => checkCourseReportableExpired(course), [course])
  const backButton = useBackButton()
  const settings = useSelector(selectors.app.setting)

  const videoFile = useMemo(
    () =>
      settings?.visio?.recommendation && {
        file_name: t('course_details_videoFile_label'),
        human_readable_size: t('course_details_videoFile_weight'),
        url: settings?.visio?.recommendation,
      },
    [settings?.visio?.recommendation, t]
  )

  const history = useHistory()

  const students = useMemo(() => {
    const studentIdsFromCourse = course?.requests?.map((r) => r.student?.id) ?? []
    return user?.guardian?.children?.filter((c) => studentIdsFromCourse?.includes(c.id)) ?? []
  }, [course, user])

  //
  // Cancel pending course (Guardian / Student)
  //
  const [setCancelPendingFeedbackModalOpen, cancelPendingFeedbackModalProps] = useFeedbackModal({
    title: t('course_details_cancelPendingFeedback_title'),
    subtitle: t('course_details_cancelPendingFeedback_subtitle'),
    onClose: () => {
      history.push(router(routesPath.dashboard))
    },
  })

  const [cancelPendingModalOpen, setCancelPendingModalOpen] = useState(false)
  const onCancelPendingComplete = useCallback(() => {
    setCancelPendingFeedbackModalOpen(true)
    setCancelPendingModalOpen(false)
  }, [setCancelPendingFeedbackModalOpen])

  const [cancelPendingQuery, handleCancelPendingQuery] = useFormSubmit(
    selectors.planning.cancelPendingCourse,
    actions.planning.cancelPendingCourseRequest,
    actions.planning.cancelPendingCourseReset,
    onCancelPendingComplete
  )

  const cancelPendingCourseModalProps: ActionSubmitModalProps = useMemo(
    () => ({
      title: t('course_details_cancelPending_title'),
      subtitle: t('course_details_cancelPending_text'),
      cancelButton: {
        text: t('cancel'),
        onClick: () => setCancelPendingModalOpen(false),
      },
      confirmButton: {
        text: t('course_details_cancelPending_confirm'),
        onClick: () => handleCancelPendingQuery({ course: course?.id }),
        isPending: cancelPendingQuery?.pending,
      },
      submitErrors: cancelPendingQuery?.errors,
      open: cancelPendingModalOpen,
      onClose: () => setCancelPendingModalOpen(false),
    }),
    [
      cancelPendingModalOpen,
      cancelPendingQuery?.errors,
      cancelPendingQuery?.pending,
      course?.id,
      handleCancelPendingQuery,
      t,
    ]
  )

  //
  // Cancel confirmed course (Teacher)
  //
  const [cancelConfirmedModalOpen, setCancelConfirmedModalOpen] = useState(false)
  const [setCancelConfirmedFeedbackModalOpen, cancelCourseFeedbackModalProps] = useFeedbackModal({
    title: t('course_details_cancelConfirmed_title'),
    subtitle: t('course_details_cancelConfirmed_subtitle'),
    text: t('course_details_cancelConfirmed_feedback_text'),
    isWarning: true,
    onClose: () => history.push(router(routesPath.dashboard)),
  })

  const onCancelConfirmedComplete = useCallback(() => {
    setCancelConfirmedModalOpen(false)
    setCancelConfirmedFeedbackModalOpen(true)
  }, [setCancelConfirmedFeedbackModalOpen])

  const [cancelConfirmedQuery, handleCancelConfirmedQuery] = useFormSubmit(
    selectors.planning.cancelConfirmedCourse,
    actions.planning.cancelConfirmedCourseRequest,
    actions.planning.cancelConfirmedCourseReset,
    onCancelConfirmedComplete
  )

  const cancelCourseModalProps: CancelCourseModalProps = useMemo(
    () => ({
      headerText: t('course_details_cancelConfirmed_title'),
      open: cancelConfirmedModalOpen,
      onClose: () => setCancelConfirmedModalOpen(false),
      disclaimerProps: {
        title: t('course_details_cancelConfirmed_disclaimer_title'),
        text: (
          <span
            dangerouslySetInnerHTML={{
              __html: t('course_details_cancelConfirmed_disclaimer_text'),
            }}
          />
        ),
        cancelProps: {
          text: t('cancel'),
        },
        confirmProps: {
          text: t('course_details_cancelConfirmed_disclaimer_confirm'),
        },
      },
      formProps: {
        fieldsProps: {
          type: {
            label: t('course_details_cancelConfirmed_form_type_label'),
            placeholder: t('course_details_cancelConfirmed_form_type_placeholder'),
            isLabelExternal: true,
            fullWidth: true,
            select: true,
            selectOptions: courseCancelReasonOptions(t),
          },
          details: {
            label: t('course_details_cancelConfirmed_form_details_label'),
            isLabelExternal: true,
            placeholder: t('course_details_cancelConfirmed_form_details_placeholder'),
            rows: 4,
            multiline: true,
          },
          file: {
            label: t('course_details_cancelConfirmed_form_file_label'),
            isLabelExternal: true,
            placeholder: t('course_details_cancelConfirmed_form_file_placeholder'),
            text: t('course_details_cancelConfirmed_form_file_text'),
            config: {
              target: api.UPLOAD_ENDPOINT,
              headers: { Authorization: `Bearer ${token}` },
            },
          },
        },
        initialValues: {
          type: '',
          details: '',
          file: { name: '', file: '' },
        },
        errorTexts: {
          required: t('error_required'),
        },
        skipButton: {
          text: t('cancel'),
          onClick: () => setCancelConfirmedModalOpen(false),
        },
        submitButton: {
          text: t('course_details_cancelConfirmed_form_submit'),
          isPending: cancelConfirmedQuery.pending,
          isSuccess: cancelConfirmedQuery.success,
        },
        submitErrors: cancelConfirmedQuery.errors,
        onSubmit: (values) =>
          handleCancelConfirmedQuery({
            course: course?.id,
            reason: values?.type,
            text: values?.details,
            ...(values?.file?.file && { file: values?.file }),
          }),
      },
    }),
    [
      cancelConfirmedQuery.errors,
      cancelConfirmedQuery.pending,
      cancelConfirmedQuery.success,
      cancelConfirmedModalOpen,
      course?.id,
      handleCancelConfirmedQuery,
      t,
      token,
    ]
  )

  const [setReportingFeedbackModalOpen, reportingFeedbackModalProps] = useFeedbackModal({
    title: t('reporting_feedback_title'),
    subtitle: t('reporting_feedback_subtitle'),
    text: t('reporting_feedback_text'),
  })
  const onReportingComplete = useCallback(() => setReportingFeedbackModalOpen(true), [
    setReportingFeedbackModalOpen,
  ])
  const [setReportingOpen, reportingModalProps] = useReportingModal(course, onReportingComplete)

  const courseDetails: CourseDetailsProps = useMemo(
    () => ({
      ...(!isClassExpired &&
        course?.status === CourseStatus.Accepted && {
          virtualClassButton: {
            text: t('access_virtual_class'),
            disabled: !isClassAvailable,
            ...(isClassAvailable && {
              link: {
                href: router(routesPath.room, {
                  id: course?.id ?? 'room',
                }),
              },
            }),
          },
        }),
      title: course?.lesson?.theme?.discipline?.name ?? '',
      details: course
        ? [
            {
              title: t('course_details_lesson_title'),
              description: course?.lesson?.name ?? '',
            },
            {
              title: t('course_details_lesson_description'),
              description: course?.lesson?.description ?? '',
            },
            ...(course?.lesson?.supports && course?.lesson?.supports?.length
              ? [
                  {
                    title: t('course_details_files_title'),
                    description: t('course_details_files_description'),
                    documents: {
                      items:
                        course?.lesson?.supports?.map(
                          (support): ImageTextItemProps => ({
                            text1: support.file_name,
                            text3: support.human_readable_size ?? '',
                            image: { src: '/static/assets/images/file.png', alt: 'Fichier' },
                            isAvatar: true,
                            largeIcon: Icons.download,
                            link: {
                              href: support.url ?? '',
                              target: '_blank',
                            },
                          })
                        ) ?? [],
                    },
                  },
                ]
              : []),
            ...(videoFile
              ? [
                  {
                    title: t('course_details_videoFile_title'),
                    description: t('course_details_videoFile_description'),
                    documents: {
                      items: [
                        {
                          text1: videoFile.file_name,
                          text3: videoFile.human_readable_size ?? '',
                          image: { src: '/static/assets/images/file.png', alt: 'Fichier' },
                          isAvatar: true,
                          largeIcon: Icons.download,
                          link: {
                            href: videoFile.url ?? '',
                            target: '_blank',
                          },
                        },
                      ],
                    },
                  },
                ]
              : []),
          ]
        : [],
      ...(isEvaluationDueByTeacher && {
        level: course?.lesson?.theme?.grade?.name ?? '',
        price:
          user?.user_type === UserType.Teacher
            ? t('course_details_teacher_price', { price: (course?.teacher_price ?? 0) / 100 })
            : '',
      }),
      ...(user?.user_type === UserType.Teacher
        ? {
            // Cancel confirmed class
            ...(isCancellable && {
              redSubmitButtonProps: {
                text: t('course_details_cancelConfirmed'),
                onClick: () => setCancelConfirmedModalOpen(true),
              },
            }),
            cancelCourseModalProps,
            cancelCourseFeedbackModalProps,
          }
        : {
            // Cancel pending class
            ...(course?.status === CourseStatus.Pending && {
              submitButtonProps: {
                text: t('course_details_cancelPending'),
                onClick: () => setCancelPendingModalOpen(true),
              },
            }),
            cancelPendingCourseModalProps,
            cancelCourseFeedbackModalProps: cancelPendingFeedbackModalProps,

            // Book same class button
            ...(course?.status === CourseStatus.Accepted &&
              isCourseBegan && {
                sameClassButton: {
                  text: t('course_details_sameClassCta'),
                  onClick: () => {
                    dispatch(
                      actions.search.saveStep({
                        step: SearchSteps.STEP_2_THEME,
                        lesson: course?.lesson,
                      })
                    )
                  },
                  link: {
                    href: router(routesPath.searchTeacherDiscipline, {
                      teacher: course?.teacher?.id ?? 'xx',
                      ...(students && {
                        child: students[0]?.id,
                      }),
                    }),
                  },
                },
              }),
          }),
      // Report incident
      ...(isReportable && {
        reportingButtonProps: {
          text: t('course_details_reportingCta'),
          isDisabled: isReportExpired,
          onClick: () => !isReportExpired && setReportingOpen(true),
        },
        reportingModalProps,
        reportingFeedbackModalProps,
      }),
    }),
    [
      isClassExpired,
      course,
      t,
      isClassAvailable,
      isEvaluationDueByTeacher,
      user?.user_type,
      isCancellable,
      cancelCourseModalProps,
      cancelCourseFeedbackModalProps,
      cancelPendingCourseModalProps,
      cancelPendingFeedbackModalProps,
      isCourseBegan,
      isReportable,
      isReportExpired,
      reportingModalProps,
      reportingFeedbackModalProps,
      setReportingOpen,
      students,
      dispatch,
      videoFile,
    ]
  )

  // teacher rating students
  const [submitEvaluateStudents, handleSubmitEvaluateStudents] = useFormSubmit(
    selectors.planning.evaluateStudents,
    actions.planning.evaluateStudentsRequest,
    actions.planning.evaluateStudentsReset
  )

  // TES : teacher evaluates students
  // SET : student evaluates teacher
  // SR : Student Rating
  // TR : Teacher Rating
  const evaluateStudentsProps: RatingsFormProps = useMemo(
    () => ({
      title: t('course_details_rating_TES_attending_students', { count: course?.participants }),
      students:
        course?.requests?.map((request) => ({
          rateModalities: {
            id: request?.student?.id ?? '',
            profile: publicStudentProfileTransformer(request?.student),
            modality: ratingModalityTransformer(
              t,
              {
                punctuality: 0,
                behaviour: 0,
                interactivity: 0,
                understanding: 0,
                working_conditions: 0,
              },
              true
            ),
            isExpanded: true,
          },
          commentaryFieldTitle: t('course_details_rating_TES_commentary_label'),
          commentaryField: {
            id: '1',
            multiline: true,
            fullWidth: true,
            name: 'commentaryField',
            placeholder: t('course_details_rating_TES_commentary_help'),
            maxChar: 160,
            maxCharLabelResolver: (total, count) => t('remaining_chars', { count: total - count }),
          },
        })) ?? [],
      initialValues: {
        students:
          course?.requests?.map((request) => ({
            student: request?.student?.id,
            punctuality: 0,
            behaviour: 0,
            interactivity: 0,
            understanding: 0,
            working_conditions: 0,
            comment: '',
          })) ?? [],
      },
      errorTexts: {
        required: t('error_required'),
        min: t('error_rating'),
      },
      submitButton: {
        text: t('course_details_rating_TES_submit_label'),
        isPending: submitEvaluateStudents.pending,
        isSuccess: submitEvaluateStudents.success,
      },
      onSubmit: (values) =>
        handleSubmitEvaluateStudents({ course: course?.id, evaluations: values?.students }),
    }),
    [
      course?.id,
      course?.participants,
      course?.requests,
      handleSubmitEvaluateStudents,
      submitEvaluateStudents.pending,
      submitEvaluateStudents.success,
      t,
    ]
  )

  const [expanded, setExpanded] = useState<string[]>([])
  const ratingGroups: RatingGroup[] | undefined = useMemo(() => {
    if (user?.user_type === UserType.Teacher) {
      return isEvaluationDoneByTeacher
        ? [
            {
              title: t('course_details_rating_TES_ratings_title'),
              ratings:
                course?.studentReviews?.map(
                  (review): RatingWithFieldsProps =>
                    ratingWithFieldsTransformer(review, t, user?.user_type, expanded, setExpanded)
                ) ?? [],
            },
          ]
        : undefined
    }

    return isEvaluationDoneByTeacher
      ? [
          {
            title: t('course_details_rating_SR_my_rating_title'),
            ratings:
              course?.studentReviews
                ?.filter((review) =>
                  user?.user_type === UserType.Guardian
                    ? user?.guardian?.children
                        ?.map((child) => child?.id)
                        ?.includes(review?.student?.id)
                    : user?.student?.id === review?.student?.id
                )
                ?.map(
                  (review): RatingWithFieldsProps =>
                    ratingWithFieldsTransformer(review, t, user?.user_type, expanded, setExpanded)
                ) ?? [],
          },
        ]
      : isEvaluationDueByTeacher
      ? [
          {
            title: t('course_details_rating_SR_my_rating_title'),
            text: t('course_details_rating_SR_my_rating_empty_text'),
          },
        ]
      : undefined
  }, [
    course?.studentReviews,
    expanded,
    isEvaluationDoneByTeacher,
    isEvaluationDueByTeacher,
    t,
    user?.guardian?.children,
    user?.student?.id,
    user?.user_type,
  ])

  const templateProps: CourseTemplateProps = useMemo(
    () => ({
      headerProps,
      menuProps,
      title:
        isEvaluationDueByTeacher && user?.user_type === UserType.Teacher
          ? t('course_details_rating_TES_title')
          : course?.type
          ? courseTypeTranslation(t, course?.type)
          : '',
      date: course
        ? t('course_card_date', {
            date: dateAndSlotToDate(course?.date, course?.slot ?? undefined),
          })
        : '',
      courseStatus: course
        ? {
            statusText: getCourseStatusText(course, t),
            statusColor: getCourseStatusColor(course),
            icon: getCourseStatusIcon(course),
            bottomBadgeLabel: t('course_card_participants', { count: course?.participants ?? 0 }),
            bottomBadgeContent: `${course?.participants ?? 0} / ${
              course?.maximum_participants ?? 0
            }`,
            ...(user?.user_type !== UserType.Teacher &&
              (course?.price_per_participant ?? 0) > 0 && {
                price: t('course_details_price', {
                  price: (course?.price_per_participant ?? 0) / 100,
                }),
              }),
          }
        : undefined,
      ...(isEvaluationDueByTeacher &&
        user?.user_type === UserType.Teacher && {
          evaluateStudentsProps: evaluateStudentsProps,
        }),
      ...(user?.user_type !== UserType.Teacher && {
        basicProfile: {
          name: course?.teacher?.full_name ?? '',
          profilePicture: {
            alt: 'LiberteClass - profil',
            src: course?.teacher?.avatar ?? '',
          },
          nameColor: light.colors.midnightBlue,
          ...(course?.teacher?.id && {
            linkProps: {
              href: router(routesPath.teacher, { id: course?.teacher?.id ?? 'x' }),
            },
          }),
        },
      }),
      courseDetails,
      backButton,
      ratingGroups,
    }),
    [
      course,
      courseDetails,
      evaluateStudentsProps,
      headerProps,
      isEvaluationDueByTeacher,
      menuProps,
      ratingGroups,
      backButton,
      t,
      user?.user_type,
    ]
  )

  const preloadProps = useMemo(
    () => ({
      headerProps,
      menuProps,
      text: t('preload_text'),
      backButton,
    }),
    [headerProps, menuProps, t, backButton]
  )

  useEffect(
    () => () => {
      dispatch(actions.planning.courseReset(null))
    },
    [dispatch]
  )

  useEffect(() => {
    dispatch(actions.planning.courseRequest(id))
  }, [dispatch, id])

  if (!courseQuery.complete) {
    return <PreloadTemplate {...preloadProps} />
  }

  if (courseQuery.errors || (courseQuery.complete && !courseQuery.data)) {
    return <NotFoundTemplate {...notFoundProps} />
  }

  return <CourseTemplate {...templateProps} />
}

export default CoursePage
