import gql from 'graphql-tag'

import PaginatorInfoFragment from '../../../fragments/PaginatorInfoFragment'
import { WalletTransaction } from '../../../../generated/graphql'
import WalletTransactionFragment from '../../../fragments/WalletTransactionFragment'

import { WalletTransactionsQueryResult } from './walletTransactions'

const bookingTransactions = {
  query: gql`
    query bookingTransactions($first: Int!, $page: Int, $limit: TransactionLimitFilter) {
      bookingTransactions(first: $first, page: $page, limit: $limit) {
        data {
          ...WalletTransactionFragment
        }
        paginatorInfo {
          ...PaginatorInfoFragment
        }
      }
    }
    ${PaginatorInfoFragment}
    ${WalletTransactionFragment}
  `,
  transformer: (response: any): WalletTransactionsQueryResult => {
    return {
      data: response.bookingTransactions?.data?.map((transaction: WalletTransaction) => ({
        ...transaction,
        model: {
          ...transaction.model,
          ...((transaction.model as any)?.requestLesson && {
            lesson: (transaction.model as any).requestLesson,
          }),
        },
      })),
      paginatorInfo: response.bookingTransactions?.paginatorInfo,
    }
  },
}

export default bookingTransactions
