import styled from 'styled-components'

import ActionButton from '../../components/ActionButton'
import IconC from '../../components/Icon'
import FormSubmit from '../../components/FormSubmit'

export const Actions = styled.div`
  padding: 0 1.6rem;
  ${(props) => props.theme.breakpoints.up('md')} {
    width: 60%;
    margin: auto;
  }
`

export const ReturnButton = styled(ActionButton)`
  margin-top: 5rem;
`

export const Action = styled(ActionButton)`
  margin: 1rem 0;
  width: 100%;
  display: block;
`

export const Content = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  ${(props) => props.theme.breakpoints.up('md')} {
    flex-grow: initial;
  }
`

export const Text = styled.p`
  ${(props) => ({ ...props.theme.typography.smallRegular })}
  color: ${(props) => props.theme.palette.colors.midnightBlue};
  margin: 1rem 1.6rem 1.6rem;
  text-align: center;
`

export const Icon = styled(IconC)`
  width: 8rem;
  align-self: center;
`

export const ResendCta = styled(FormSubmit)`
  margin: 1rem 0 4rem;
  display: flex;
  ${(props) => ({ ...props.theme.typography.callToAction })}
  color: ${(props) => props.theme.palette.colors.freeSpeechBlue};
  background-color: ${(props) => props.theme.palette.colors.pureWhite};
  text-decoration: none;
  cursor: pointer;
  width: auto;
  align-self: center;
  padding: 0.5rem 1rem;
  border: none;
  &:hover {
    background-color: ${(props) => props.theme.palette.colors.cornflowerBlue};
    color: ${(props) => props.theme.palette.colors.pureWhite};
  }
`
