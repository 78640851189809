import React, { FC } from 'react'
import { ToggleButtonProps } from '@material-ui/lab'

import * as SC from './styled'

export type ToggleButtonPickerProps = {
  className?: string
  id?: string
  name: string
  label?: string
  text?: string
  fields: ToggleButtonProps[]
  onChange?: (name: string, value: string[]) => void
  value?: string[]
  help?: string
  error?: string
}

const ToggleButtonPicker: FC<ToggleButtonPickerProps> = (props) => {
  const {
    className,
    id,
    name,
    label,
    text,
    fields,
    onChange = (_name, _value) => null,
    value,
    help,
    error,
  } = props

  return (
    <SC.ToggleButtonPicker className={className}>
      <SC.Wrapper disableGutters>
        {label && <SC.Title>{label}</SC.Title>}
        <SC.Text>{text}</SC.Text>
        <SC.ToggleButtonGroup
          id={id}
          name={name}
          fields={fields}
          onChange={onChange}
          value={value}
        />
      </SC.Wrapper>
      {(error || help) && <SC.HelperText error={!!error}>{error || help}</SC.HelperText>}
    </SC.ToggleButtonPicker>
  )
}

export default ToggleButtonPicker
