import gql from 'graphql-tag'

import { Course, PaginatorInfo } from '../../../../generated/graphql'
import CourseFragment from '../../../fragments/CourseFragment'
import PaginatorInfoFragment from '../../../fragments/PaginatorInfoFragment'

export type SearchCourseResult = {
  data: Course[]
  paginatorInfo: PaginatorInfo
}

const searchCourse = {
  query: gql`
    query searchCourse(
      $first: Int!
      $page: Int
      $lesson: ID!
      $student: ID
      $teacher: ID
      $type: CourseType!
      $maximum_price: Int!
      $maximum_participants: Int!
      $minimum_score: Float
      $availabilities: [AvailabilityInput!]
    ) {
      searchCourse(
        first: $first
        page: $page
        lesson: $lesson
        student: $student
        teacher: $teacher
        type: $type
        maximum_price: $maximum_price
        maximum_participants: $maximum_participants
        minimum_score: $minimum_score
        availabilities: $availabilities
      ) {
        data {
          ...CourseFragment
        }
        paginatorInfo {
          ...PaginatorInfoFragment
        }
      }
    }
    ${CourseFragment}
    ${PaginatorInfoFragment}
  `,
  transformer: (response: any): SearchCourseResult => {
    return response.searchCourse
  },
}

export default searchCourse
