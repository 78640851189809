import React from 'react'

import useHeader from '../hooks/useHeader'
import NotFoundTemplate from '../templates/NotFound'
import useNotFound from '../hooks/useNotFound'

function Page404(props: any) {
  const headerProps = useHeader(props)
  const notFoundProps = useNotFound(headerProps)

  return <NotFoundTemplate {...notFoundProps} />
}

export default Page404
