import gql from 'graphql-tag'

import UserFragment from '../../../fragments/UserFragment'
import type { User } from '../../../../generated/graphql'

const updateTeacherMaximumParticipants = {
  mutation: gql`
    mutation updateTeacherMaximumParticipants($maximum_participants: Int!) {
      updateTeacherMaximumParticipants(maximum_participants: $maximum_participants) {
        ...UserFragment
      }
    }
    ${UserFragment}
  `,
  transformer: (response: any): User => {
    return response.updateTeacherMaximumParticipants
  },
}

export default updateTeacherMaximumParticipants
