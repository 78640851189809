import { createSlice } from '@reduxjs/toolkit'

import type { RootState } from '../store'
import { THEMES } from '../../constants'
import type { Configuration } from '../../generated/graphql-contentful'
import type { Discipline, Grade, Setting } from '../../generated/graphql'

import { actionTypes } from './types'

type AppState = {
  isInit: boolean
  theme: string
  contentfulConfiguration: Partial<Configuration> | null
  disciplines: Discipline[]
  grades: Grade[]
  setting: Setting
}

//
// Initial state
//

const initialState: AppState = {
  isInit: false,
  theme: THEMES.DEFAULT,
  contentfulConfiguration: null,
  disciplines: [],
  grades: [],
  setting: {},
}

//
// Slice (Actions & Reducers)
//

const slice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    init: () => undefined,
    setContentfulConfiguration: (state, action: actionTypes.setContenfulConfiguration) => {
      state.contentfulConfiguration = action.payload
    },
    setDisciplines: (state, action: actionTypes.setDisciplines) => {
      state.disciplines = action.payload
    },
    setGrades: (state, action: actionTypes.setGrades) => {
      state.grades = action.payload
    },
    setSetting: (state, action: actionTypes.setSetting) => {
      state.setting = action.payload
    },
    setIsInit: (state, action: actionTypes.setIsInit) => {
      const { isInit } = action.payload
      state.isInit = isInit
    },
    setTheme: (state, { payload }: actionTypes.setTheme) => {
      const { theme } = payload
      state.theme = theme
    },
  },
})

export const { reducer, actions } = slice

//
// Selectors
//

const root = (state: RootState) => state[slice.name]
const isInit = (state: RootState) => root(state).isInit
const theme = (state: RootState) => root(state).theme
const contentful = (state: RootState) => root(state).contentfulConfiguration
const disciplines = (state: RootState) => root(state).disciplines
const grades = (state: RootState) => root(state).grades
const setting = (state: RootState) => root(state).setting

export const selectors = {
  isInit,
  theme,
  contentful,
  disciplines,
  grades,
  setting,
}
