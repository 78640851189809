import React, { FC } from 'react'

import { ImageTextListProps } from '../ImageTextList'
import { BasicLinkProps } from '../BasicLink'
import { ActionButtonProps } from '../ActionButton'
import { FormSubmitProps } from '../FormSubmit'
import FormSubmitErrors, { FormSubmitErrorsProps } from '../FormSubmitErrors'
import CancelCourseModal, { CancelCourseModalProps } from '../modals/CancelCourseModal'
import SimpleFeedbackModal, { SimpleFeedbackModalProps } from '../modals/SimpleFeedbackModal'
import ReportingModal, { ReportingModalProps } from '../modals/ReportingModal'
import ActionSubmitModal, { ActionSubmitModalProps } from '../modals/ActionSubmitModal'

import * as SC from './styled'

export type DetailsProps = {
  title: string
  description: string
  documents?: ImageTextListProps
  cta?: BasicLinkProps
}

export type CourseDetailsProps = {
  className?: string
  title: string
  details: DetailsProps[]
  virtualClassButton?: ActionButtonProps
  sameClassButton?: ActionButtonProps
  submitButtonProps?: FormSubmitProps
  redSubmitButtonProps?: FormSubmitProps
  level?: string
  price?: string
  submitErrors?: FormSubmitErrorsProps['errors']
  cancelCourseModalProps?: CancelCourseModalProps
  cancelCourseFeedbackModalProps?: SimpleFeedbackModalProps
  reportingButtonProps?: FormSubmitProps
  reportingModalProps?: ReportingModalProps
  reportingFeedbackModalProps?: SimpleFeedbackModalProps
  cancelPendingCourseModalProps?: ActionSubmitModalProps
}

const CourseDetails: FC<CourseDetailsProps> = (props) => {
  const {
    className,
    title,
    details,
    virtualClassButton,
    sameClassButton,
    level,
    price,
    submitButtonProps,
    redSubmitButtonProps,
    submitErrors,
    reportingButtonProps,
    cancelCourseModalProps,
    cancelCourseFeedbackModalProps,
    reportingModalProps,
    reportingFeedbackModalProps,
    cancelPendingCourseModalProps,
  } = props

  return (
    <SC.CourseDetails className={className}>
      <SC.Wrapper>
        <SC.Title>{title}</SC.Title>
        {details
          ?.filter((d) => d.description || d.documents || d.cta)
          ?.map((detail, index) => (
            <SC.Detail key={index}>
              <SC.DetailTitle>{detail.title}</SC.DetailTitle>
              <SC.DetailDescription>{detail.description}</SC.DetailDescription>
              {detail.documents && <SC.DetailDocuments {...detail.documents} />}
              {detail.cta && <SC.DetailCTA {...detail.cta} />}
            </SC.Detail>
          ))}
        {level && price && (
          <SC.Level>
            {level}
            <SC.Point>.</SC.Point>
            {price}
          </SC.Level>
        )}
        <SC.Actions>
          {virtualClassButton && <SC.VirtualClassButton {...virtualClassButton} />}
          {sameClassButton && <SC.VirtualClassButton {...sameClassButton} />}
          <FormSubmitErrors errors={submitErrors} />
          {submitButtonProps && <SC.SubmitButton {...submitButtonProps} autoIcon />}
          {redSubmitButtonProps && <SC.SubmitButtonRed {...redSubmitButtonProps} autoIcon />}
          {reportingButtonProps && <SC.SubmitButtonRed {...reportingButtonProps} />}
        </SC.Actions>
      </SC.Wrapper>
      {cancelCourseModalProps && <CancelCourseModal {...cancelCourseModalProps} />}
      {reportingModalProps && <ReportingModal {...reportingModalProps} />}
      {reportingFeedbackModalProps && <SimpleFeedbackModal {...reportingFeedbackModalProps} />}
      {cancelCourseFeedbackModalProps && (
        <SimpleFeedbackModal {...cancelCourseFeedbackModalProps} />
      )}
      {cancelPendingCourseModalProps && <ActionSubmitModal {...cancelPendingCourseModalProps} />}
    </SC.CourseDetails>
  )
}

export default CourseDetails
