import React, { FC, useCallback, useState } from 'react'
import { Form, Formik, FormikHelpers } from 'formik'
import * as Yup from 'yup'
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { ConfirmPaymentData, StripeElementsOptions } from '@stripe/stripe-js'

import { FormFieldConfig, FormFieldProps } from '../../types/form'
import FormSubmitErrors, { FormSubmitErrorsProps } from '../FormSubmitErrors'
import { getValidationSchema, renderField } from '../../helpers/FormHelpers'
import { FormSubmitProps } from '../FormSubmit'
import { ActionButtonProps } from '../ActionButton'

import * as SC from './styled'

export type StripePaymentFormValues = {
  amount: string
  firstName: string
  lastName: string
}

export type StripePaymentFormProps = {
  className?: string
  text?: string
  initialValues: StripePaymentFormValues
  fieldsProps?: {
    amount: FormFieldProps
    firstName: FormFieldProps
    lastName: FormFieldProps
  }
  errorTexts?: {
    required: string
    unexpected: string
  }
  cancelButton: ActionButtonProps
  submitButton: FormSubmitProps
  submitErrors?: FormSubmitErrorsProps['errors']
  stripeKey: string
  stripeOptions?: StripeElementsOptions
  stripeConfirmParams: ConfirmPaymentData
  onSubmit?: (
    values: StripePaymentFormValues,
    formikHelpers: FormikHelpers<StripePaymentFormValues>
  ) => void
}

const StripePaymentForm: FC<StripePaymentFormProps> = (props) => {
  const {
    className,
    text,
    submitButton,
    errorTexts,
    submitErrors,
    initialValues,
    fieldsProps,
    cancelButton,
    stripeConfirmParams,
  } = props

  const stripe = useStripe()
  const elements = useElements()

  const amountField = {
    name: 'amount',
    Component: SC.Amount,
    validation: Yup.string().required(errorTexts?.required),
    disabled: true,
    required: true,
  }

  const firstNameField = {
    name: 'firstName',
    Component: SC.Field,
    validation: Yup.string().required(errorTexts?.required),
    required: true,
  }

  const lastNameField = {
    name: 'lastName',
    Component: SC.Field,
    validation: Yup.string().required(errorTexts?.required),
    required: true,
  }

  const fields: FormFieldConfig[] = [firstNameField, lastNameField]

  const validationSchema = getValidationSchema(fields)

  const [isLoading, setIsLoading] = useState(false)
  const [message, setMessage] = useState('')

  const handleSubmit = useCallback(async () => {
    setIsLoading(true)
    setMessage('')

    if (!stripe || !elements) {
      return
    }

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: stripeConfirmParams,
    })

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === 'card_error' || error.type === 'validation_error') {
      setMessage(error.message ?? '')
    } else {
      setMessage(errorTexts?.unexpected ?? 'An unexpected error occured.')
    }

    setIsLoading(false)
  }, [elements, errorTexts?.unexpected, stripeConfirmParams, stripe])

  return (
    <SC.StripePaymentForm className={className}>
      <SC.Wrapper disableGutters>
        {text && <SC.Text>{text}</SC.Text>}
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {(formikProps) => (
            <Form noValidate>
              <SC.Fields>{renderField(amountField, formikProps, fieldsProps, 0)}</SC.Fields>
              <SC.Fields>
                {fields?.map((field, index) =>
                  renderField(field, formikProps, fieldsProps, index + 1)
                )}
              </SC.Fields>
              <PaymentElement id="payment-element" />
              <FormSubmitErrors errors={message ? [{ label: message }] : null} />
              <FormSubmitErrors errors={submitErrors} />
              <SC.Actions>
                <SC.CancelButton outlined {...cancelButton} />
                <SC.SubmitButton
                  {...submitButton}
                  isPending={submitButton.isPending || isLoading}
                  autoIcon
                />
              </SC.Actions>
            </Form>
          )}
        </Formik>
      </SC.Wrapper>
    </SC.StripePaymentForm>
  )
}

export default StripePaymentForm
