import React, { FC } from 'react'
import { Elements } from '@stripe/react-stripe-js'
import {
  Appearance,
  CssFontSource,
  CustomFontSource,
} from '@stripe/stripe-js/types/stripe-js/elements-group'

import useLoadStripe, { StripeLoadStatus } from '../../hooks/useLoadStripe'
import { Icons } from '../Icon/types'

import StripePaymentForm, { StripePaymentFormProps } from './index'
import * as SC from './styled'

const StripePaymentFormWrapper: FC<StripePaymentFormProps> = (props) => {
  const { stripeKey, stripeOptions } = props

  const [stripePromise, stripeLoadStatus] = useLoadStripe(stripeKey)

  const fonts: Array<CssFontSource | CustomFontSource> = []

  const appearance: Appearance = {
    theme: 'stripe',
  }

  return (
    <Elements stripe={stripePromise || null} options={{ appearance, fonts, ...stripeOptions }}>
      {stripeLoadStatus === StripeLoadStatus.LOADED ? (
        <StripePaymentForm {...props} />
      ) : (
        <SC.Loading icon={Icons.refresh} />
      )}
    </Elements>
  )
}

export default StripePaymentFormWrapper
