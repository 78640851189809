import { Divider } from '@material-ui/core'
import styled from 'styled-components'

import BasicLink from '../BasicLink'
import TextFieldC from '../form/TextField'
import FormSubmit from '../FormSubmit'

export const SignInForm = styled.div`
  padding: 0 1.6rem;
`

export const Title = styled.h1`
  ${(props) => ({ ...props.theme.typography.h1 })}
  color: ${(props) => props.theme.palette.colors.midnightBlue};
  font-size: 3.2rem;
  margin: 0 0 2rem 0;
  ${(props) => props.theme.breakpoints.up('md')} {
    font-size: 4rem;
  }
`

export const Text = styled.p`
  ${(props) => ({ ...props.theme.typography.h6 })}
  color: ${(props) => props.theme.palette.colors.freeSpeechBlue};
  margin: 1rem 0 0.5rem 0;
`

export const Fields = styled.div`
  margin-top: 2rem;
`

export const TextField = styled(TextFieldC)`
  margin-bottom: 1rem;
  width: 100%;
  margin-right: 1rem;
`

export const Error = styled.p`
  color: ${(props) => props.theme.palette.colors.tomato};
  ${(props) => ({ ...props.theme.typography.smallRegular })}
  margin: -0.2rem 0 0.5rem 0;
`

export const SubmitButton = styled(FormSubmit)``

export const MandatoryText = styled.p`
  ${(props) => ({ ...props.theme.typography.smallRegular })}
  color: ${(props) => props.theme.palette.colors.grey};
  margin: 2rem 0;
`

export const MuiDivider = styled(Divider)`
  margin: 1rem 0rem;
`

export const BackButton = styled(BasicLink)`
  align-items: center;
  display: flex;
  text-decoration: none;
  margin: 0;
  color: ${(props) => props.theme.palette.colors.cornflowerBlue};
  max-width: max-content;
  border-bottom: 2px solid transparent;
  ${(props) => ({ ...props.theme.typography.smallRegular })};
  &:hover {
    border-bottom: 2px solid ${(props) => props.theme.palette.colors.cornflowerBlue};
    text-decoration: none;
  }
`
