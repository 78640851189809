import gql from 'graphql-tag'

import { Setting } from '../../../../generated/graphql'

const settings = {
  query: gql`
    query settings {
      settings {
        links {
          cgu
          privacy
        }
        visio {
          recommendation
        }
      }
    }
  `,
  transformer: (response: any): Setting => {
    return response.settings
  },
}

export default settings
