import { Container, Divider } from '@material-ui/core'
import styled from 'styled-components'
import chroma from 'chroma-js'

import BasicLink from '../BasicLink'
import ActionButton from '../ActionButton'
import BasicProfilC from '../BasicProfil'
import Link from '../Link'

export const CourseCard = styled(Link)`
  text-decoration: none;
  display: flex;
  margin-bottom: 1.6rem;
  ${(props) => props.theme.breakpoints.up('md')} {
    max-width: 40rem;
    width: 100%;
  }
  &:hover {
    text-decoration: none;
  }
`

export const Wrapper = styled(Container)<{ $isNew?: boolean }>`
  padding: 1.6rem;
  background-color: ${(props) => props.theme.palette.colors.pureWhite};
  border-radius: 0.8rem;
  border: 2px solid
    ${(props) =>
      props.$isNew ? props.theme.palette.colors.neonCarrot : props.theme.palette.colors.ghostWhite};
  display: flex;
  flex-direction: column;
`

export const Date = styled.p`
  margin: 0rem;
  ${(props) => ({ ...props.theme.typography.callToAction })}
  color: ${(props) => props.theme.palette.colors.tomato};
`

export const Who = styled.p`
  margin: 0rem;
  ${(props) => ({ ...props.theme.typography.h6 })}
  color: ${(props) => props.theme.palette.colors.midnightBlue};
`

export const Muidivider = styled(Divider)`
  width: 100%;
  margin: 1.2rem 0rem;
`

export const Subject = styled.h3`
  ${(props) => ({ ...props.theme.typography.h4 })}
  color: ${(props) => props.theme.palette.colors.midnightBlue};
  margin: 1rem 0 0 0;
`

export const Level = styled.div`
  display: flex;
  margin: 0;
  ${(props) => ({ ...props.theme.typography.h7 })}
  color: ${(props) => props.theme.palette.colors.freeSpeechBlue};
`

export const Point = styled.p`
  margin: 0;
  ${(props) => ({ ...props.theme.typography.h2 })}
  color: ${(props) => props.theme.palette.colors.gainsboro};
  line-height: 0.1;
  margin: 0 0.3rem;
`

export const TeacherName = styled.p`
  ${(props) => ({ ...props.theme.typography.tinySmall })}
  color: ${(props) => props.theme.palette.colors.grey};
`

export const BasicProfil = styled(BasicProfilC)``

export const Description = styled.p`
  ${(props) => ({ ...props.theme.typography.smallRegular })}
  color: ${(props) => props.theme.palette.colors.chambray};
  flex-grow: 1;
  display: flex;
  align-items: flex-end;
  margin-bottom: 0.5rem;
`

export const Type = styled.div`
  display: flex;
  margin: 0.5rem 0 0 0;
  ${(props) => ({ ...props.theme.typography.h6 })}
  color: ${(props) => props.theme.palette.colors.grey};
  align-items: center;
`

export const PriceInFooter = styled(Type)`
  margin: 0;
  ${(props) => ({ ...props.theme.typography.tinySmall })}
`

export const NumberOfStudents = styled(Type)`
  margin: 0;
  ${(props) => ({ ...props.theme.typography.tinySmall })}
`

export const Point2 = styled(Point)`
  color: ${(props) => props.theme.palette.colors.grey};
  line-height: 0.3;
  margin: 0 0.5rem;
  padding-bottom: 1.4rem;
`

export const EvaluateCta = styled(BasicLink)`
  margin-top: 1rem;
  display: flex;
  ${(props) => ({ ...props.theme.typography.callToAction })}
  text-decoration: none;
  color: ${(props) => props.theme.palette.colors.cornflowerBlue};
  cursor: pointer;
`

export const AvailableSlotCta = styled(EvaluateCta)``

export const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
`

export const DeclineButton = styled(ActionButton)`
  margin-right: 1rem;
`

export const AcceptButton = styled(ActionButton)``

export const BottomBadges = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -0.8rem -0.8rem;
  & > * {
    margin: 0.8rem;
  }
`

export const BottomBadge = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const BottomBadgeContent = styled.div`
  ${(props) => ({ ...props.theme.typography.h6 })}
  background: rgba(255, 154, 46, 0.2);
  padding: 0.2rem 0.7rem;
  border-radius: 0.8rem;
  height: 2.4rem;
  align-items: center;
  display: flex;
  color: ${(props) => props.theme.palette.colors.neonCarrot};
`

export const BottomBadgeLabel = styled.div`
  ${(props) => ({ ...props.theme.typography.h6 })}
  color: ${(props) => props.theme.palette.colors.neonCarrot};
  margin: 0 0 0 0.8rem;
`

export const Confirmed = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const ConfirmedContent = styled.div`
  ${(props) => ({ ...props.theme.typography.h6 })}
  background: ${(props) => chroma(props.theme.palette.colors.cornflowerBlue).alpha(0.2).hex()};
  padding: 0.2rem 0.4rem;
  border-radius: 0.8rem;
  color: ${(props) => props.theme.palette.colors.cornflowerBlue};
  display: flex;
  height: 2.4rem;
  width: 2.4rem;
  align-items: center;
  & svg {
    color: ${(props) => props.theme.palette.colors.cornflowerBlue};
    width: 1.6rem;
    height: 1.6rem;
  }
`

export const ConfirmedLabel = styled.div`
  ${(props) => ({ ...props.theme.typography.h6 })}
  color: ${(props) => props.theme.palette.colors.cornflowerBlue};
  margin: 0 0 0 0.8rem;
`

export const File = styled(BasicLink)`
  margin-top: 1rem;
  display: flex;
  ${(props) => ({ ...props.theme.typography.callToAction })}
  text-decoration: none;
  color: ${(props) => props.theme.palette.colors.cornflowerBlue};
  cursor: pointer;
  max-width: 100%;
  overflow-wrap: anywhere;
  & svg {
    margin-right: 0.5rem;
    flex-shrink: 0;
  }
`
