import React, { FC } from 'react'

import { PricesChoiceFormProps } from '../../components/PricesChoiceForm'
import { BasicTextAndImageProps } from '../../components/BasicTextAndImage'
import Header, { HeaderProps } from '../../components/Header'
import SignUpLayout from '../../layouts/SignupLayout'

import * as SC from './styled'

export type SignUpTeacherPricingTemplateProps = {
  className?: string
  headerProps: HeaderProps
  textImageProps: BasicTextAndImageProps
  pricesChoiceFormProps: PricesChoiceFormProps
}

const SignUpTeacherPricingTemplate: FC<SignUpTeacherPricingTemplateProps> = (props) => {
  const { headerProps, textImageProps, pricesChoiceFormProps } = props

  return (
    <SignUpLayout header={<Header {...headerProps} />}>
      <SC.Wrapper>
        <SC.BasicTextAndImage {...textImageProps} />
        <SC.PricesChoiceForm {...pricesChoiceFormProps} />
      </SC.Wrapper>
    </SignUpLayout>
  )
}

export default SignUpTeacherPricingTemplate
