import React, { useEffect } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { HeaderProps } from '../../components/Header'
import useHeader from '../../hooks/useHeader'
import { MenuProps } from '../../components/Menu'
import useMenu from '../../hooks/useMenu'
import { Course, UserType } from '../../generated/graphql'
import { RestrictedReactFC } from '../../types/common'
import { actions, selectors } from '../../redux'
import PlanningListTemplate, { PlanningListTemplateProps } from '../../templates/PlanningList'
import { courseToCardProps } from '../../transformers/courseTransformers'
import useBackButton from '../../hooks/useBackButton'

const PlanningFuturePage: RestrictedReactFC<RouteComponentProps> = (props) => {
  const headerProps: HeaderProps = useHeader()
  const menuProps: MenuProps = useMenu(props)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const user = useSelector(selectors.auth.user)
  const teacherFutureCourses = useSelector(selectors.planning.teacherFutureCourses)
  const backButton = useBackButton()

  const lists = []
  if (teacherFutureCourses?.data?.today && teacherFutureCourses?.data?.today?.length > 0) {
    lists.push({
      title: t('planningFuture_today_title'),
      cards: teacherFutureCourses?.data?.today?.map((c: Course) => courseToCardProps(c, t, user)),
    })
  }

  if (
    teacherFutureCourses?.data?.future?.data &&
    teacherFutureCourses?.data?.future?.data?.length > 0
  ) {
    lists.push({
      title: t('planningFuture_future_title'),
      cards: teacherFutureCourses?.data?.future?.data?.map((c: Course) =>
        courseToCardProps(c, t, user)
      ),
    })
  }

  const planningProps: PlanningListTemplateProps = {
    headerProps,
    menuProps,
    backButton,
    title: t('planningFuture_title'),
    lists,
  }

  //
  // EFFECTS
  //
  useEffect(() => {
    dispatch(actions.planning.teacherFutureCoursesRequest(undefined))
  }, [dispatch])

  return <PlanningListTemplate {...planningProps} />
}

PlanningFuturePage.restrictedUserTypes = [UserType.Teacher]

export default PlanningFuturePage
