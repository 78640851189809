import React, { FC } from 'react'

import { BasicLinkProps } from '../BasicLink'
import { ActionButtonProps } from '../ActionButton'
import { BasicProfilProps } from '../BasicProfil'
import { Icons } from '../Icon/types'
import { LinkProps } from '../Link'
import Icon from '../Icon'

import * as SC from './styled'

export type CourseCardProps = {
  className?: string
  date?: string
  subject?: string
  level?: string
  price?: string
  teacherName?: string
  description?: string
  type?: string
  numberOfStudents?: number
  numberOfStudentsText?: string
  isPastCourse?: boolean
  evaluateCta?: BasicLinkProps
  availableSlotCta?: BasicLinkProps
  declineButton?: ActionButtonProps
  acceptButton?: ActionButtonProps
  isNew?: boolean
  teacherProfil?: BasicProfilProps
  priceInFooter?: boolean
  forWho?: string
  bottomBadgeContent?: string
  bottomBadgeLabel?: string
  link?: LinkProps
  confirmedLabel?: string
  files?: BasicLinkProps[]
}

const CourseCard: FC<CourseCardProps> = (props) => {
  const {
    className,
    date,
    subject,
    level,
    price,
    teacherName,
    description,
    type,
    numberOfStudentsText,
    isPastCourse,
    evaluateCta,
    availableSlotCta,
    declineButton,
    acceptButton,
    isNew,
    teacherProfil,
    priceInFooter,
    forWho,
    bottomBadgeContent,
    bottomBadgeLabel,
    link,
    confirmedLabel,
    files,
  } = props

  return (
    <SC.CourseCard className={className} {...link}>
      <SC.Wrapper $isNew={isNew}>
        {date && <SC.Date>{date}</SC.Date>}
        {forWho && <SC.Who>{forWho}</SC.Who>}
        {(date || forWho) && <SC.Muidivider />}
        {!teacherProfil && (
          <>
            {subject && <SC.Subject>{subject}</SC.Subject>}
            {level && (
              <SC.Level>
                {level}
                {price && !priceInFooter && (
                  <>
                    <SC.Point>.</SC.Point>
                    {price}
                  </>
                )}
              </SC.Level>
            )}
            {teacherName && <SC.TeacherName>{teacherName}</SC.TeacherName>}
          </>
        )}
        {teacherProfil && <SC.BasicProfil {...teacherProfil} />}
        {description && <SC.Description>{description}</SC.Description>}
        {files?.map((file, key) => (
          <SC.File {...file} key={key} />
        ))}
        {(type || isPastCourse || availableSlotCta) && <SC.Muidivider />}
        {type && (
          <SC.Type>
            {type}
            <SC.Point2>.</SC.Point2>
            {price && priceInFooter && (
              <>
                <SC.PriceInFooter>{price}</SC.PriceInFooter>
                {numberOfStudentsText && <SC.Point2>.</SC.Point2>}
              </>
            )}
            {numberOfStudentsText && (
              <SC.NumberOfStudents>{numberOfStudentsText}</SC.NumberOfStudents>
            )}
          </SC.Type>
        )}
        {((bottomBadgeContent && bottomBadgeLabel) || confirmedLabel) && (
          <SC.BottomBadges>
            {bottomBadgeContent && bottomBadgeLabel && (
              <SC.BottomBadge>
                <SC.BottomBadgeContent>{bottomBadgeContent}</SC.BottomBadgeContent>
                <SC.BottomBadgeLabel>{bottomBadgeLabel}</SC.BottomBadgeLabel>
              </SC.BottomBadge>
            )}
            {confirmedLabel && (
              <SC.Confirmed>
                <SC.ConfirmedContent>
                  <Icon icon={Icons.checkBasic} />
                </SC.ConfirmedContent>
                <SC.ConfirmedLabel>{confirmedLabel}</SC.ConfirmedLabel>
              </SC.Confirmed>
            )}
          </SC.BottomBadges>
        )}
        {isPastCourse && evaluateCta && (
          <SC.EvaluateCta {...evaluateCta} icon={Icons.longArrowRight} />
        )}
        {availableSlotCta && (
          <SC.AvailableSlotCta
            {...availableSlotCta}
            text={availableSlotCta.text}
            icon={Icons.longArrowRight}
          />
        )}
        {acceptButton && (
          <SC.Buttons>
            {declineButton && <SC.DeclineButton {...declineButton} outlined />}
            <SC.AcceptButton {...acceptButton} />
          </SC.Buttons>
        )}
      </SC.Wrapper>
    </SC.CourseCard>
  )
}

export default CourseCard
