import React, { FC } from 'react'

import { CourseCardProps } from '../CourseCard'
import { MoneyCardProps } from '../MoneyCard'
import { RecurrenceCardProps } from '../RecurrenceCard'
import { ScheduleResultCardProps } from '../ScheduleResultCard'
import { FormSubmitProps } from '../FormSubmit'
import { CourseMaterialsCardProps } from '../CourseMaterialsCard'

import * as SC from './styled'

export type UnionCardProps =
  | CourseCardProps
  | MoneyCardProps
  | RecurrenceCardProps
  | ScheduleResultCardProps
  | CourseMaterialsCardProps

export type CardListProps = {
  className?: string
  cards?: UnionCardProps[]
  buttonProps?: FormSubmitProps
}

const CardList: FC<CardListProps> = (props) => {
  const { className, cards, buttonProps } = props

  const isCourseCard = (obj: any): obj is CourseCardProps => {
    return obj.description !== undefined
  }

  const isCourseMaterialCard = (obj: any): obj is CourseMaterialsCardProps => {
    return obj.documents !== undefined
  }

  const isMoneyCard = (obj: any): obj is MoneyCardProps => {
    return obj.money !== undefined
  }

  const isRecurrenceCard = (obj: any): obj is RecurrenceCardProps => {
    return obj.weekdays !== undefined
  }

  const isScheduleResultCard = (obj: any): obj is ScheduleResultCardProps => {
    return obj.hour !== undefined
  }

  return (
    <SC.CardList className={className}>
      <SC.Wrapper maxWidth={false}>
        <SC.Cards>
          {cards?.map((card, i) => {
            if (isCourseMaterialCard(card)) return <SC.CourseMaterialsCard key={i} {...card} />
            if (isCourseCard(card)) return <SC.CourseCard key={i} {...card} />
            if (isMoneyCard(card)) return <SC.MoneyCard key={i} {...card} />
            if (isRecurrenceCard(card)) return <SC.RecurrenceCard key={i} {...card} />
            if (isScheduleResultCard(card)) return <SC.ScheduleResultCard key={i} {...card} />
          })}
        </SC.Cards>
        {buttonProps && (
          <SC.Actions>
            <SC.Button {...buttonProps} />
          </SC.Actions>
        )}
      </SC.Wrapper>
    </SC.CardList>
  )
}

export default CardList
