import React, { FC } from 'react'
import { SwiperSlide } from 'swiper/react'
import SwiperCore, { Pagination } from 'swiper/core'

import CourseCard, { CourseCardProps } from '../CourseCard'
import { BasicLinkProps } from '../BasicLink'
import { Icons } from '../Icon/types'
import { light } from '../../theme/palette'

import * as SC from './styled'

export type CourseCardCarouselProps = {
  className?: string
  title: string
  text?: string
  cards?: CourseCardProps[]
  planningCta?: BasicLinkProps
}

SwiperCore.use([Pagination])

const CourseCardCarousel: FC<CourseCardCarouselProps> = (props) => {
  const { className, title, text, cards, planningCta } = props

  return (
    <SC.CourseCardCarousel className={className}>
      <SC.Wrapper disableGutters maxWidth={false}>
        <SC.Title>{title}</SC.Title>
        {text && <SC.Text>{text}</SC.Text>}
        {cards?.length && cards?.length > 0 && (
          <SC.Slider
            className={''}
            pagination={{ clickable: true }}
            slidesPerView={'auto'}
            threshold={5}
            navigation={{
              nextEl: '.swiper-button-next-unique',
              prevEl: '.swiper-button-prev-unique',
            }}
          >
            {cards?.map((card, index) => (
              <SwiperSlide key={index}>
                <CourseCard {...card} />
              </SwiperSlide>
            ))}
            <SC.ArrowLeft
              className="swiper-button-prev-unique"
              icon={Icons.chevronLeft}
              color={light.colors.chambray}
            />
            <SC.ArrowRight
              className="swiper-button-next-unique"
              icon={Icons.chevronRight}
              color={light.colors.chambray}
            />
          </SC.Slider>
        )}
        {planningCta && (
          <SC.CtaBox>
            <SC.PlanningCta
              text={planningCta.text}
              link={planningCta.link}
              icon={Icons.calendar}
              iconColor={light.colors.freeSpeechBlue}
              iconSide={'left'}
              backgroundColor={light.colors.pureWhite}
            />
          </SC.CtaBox>
        )}
      </SC.Wrapper>
    </SC.CourseCardCarousel>
  )
}

export default CourseCardCarousel
