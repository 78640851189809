import React, { FC } from 'react'

import { IconProps } from '../Icon/types'
import { LinkProps } from '../Link'

import * as SC from './styled'

export type MessageProps = {
  className?: string
  backgroundColor?: string
  text: string
  icon: IconProps
  link?: LinkProps
}

const Message: FC<MessageProps> = (props) => {
  const { className, backgroundColor, text, icon, link } = props

  return (
    <SC.Message className={className} {...link}>
      <SC.Wrapper disableGutters $backgroundColor={backgroundColor}>
        <SC.Text>{text}</SC.Text>
        <SC.Icon {...icon} />
      </SC.Wrapper>
    </SC.Message>
  )
}

export default Message
