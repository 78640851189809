import gql from 'graphql-tag'

import { Course } from '../../../../generated/graphql'
import CourseFragment from '../../../fragments/CourseFragment'
import PaginatorInfoFragment from '../../../fragments/PaginatorInfoFragment'

const classMarketByDateAndSlot = {
  query: gql`
    query classMarketByDateAndSlot(
      $type: CourseType!
      $date: Date!
      $slot: AvailabilitySlot!
      $minimum_price: Int!
    ) {
      classMarketByDateAndSlot(
        first: 100
        type: $type
        date: $date
        slot: $slot
        minimum_price: $minimum_price
      ) {
        data {
          ...CourseFragment
        }
        paginatorInfo {
          ...PaginatorInfoFragment
        }
      }
    }
    ${CourseFragment}
    ${PaginatorInfoFragment}
  `,
  transformer: (response: any): Course => {
    return response.classMarketByDateAndSlot
  },
}

export default classMarketByDateAndSlot
