import React from 'react'
import { PropTypes } from '@material-ui/core'

import { Icons } from '../Icon/types'

import * as SC from './styled'

export type FormSubmitProps = {
  className?: string
  text?: string
  pendingText?: string
  successText?: string
  disabledText?: string
  type?: 'submit' | 'button'
  color?: PropTypes.Color
  onClick?: (e: any | undefined) => void
  isPending?: boolean
  isSuccess?: boolean
  isDisabled?: boolean
  icon?: Icons
  isFullSize?: boolean
  autoIcon?: boolean
  iconSide?: 'left' | 'right'
  outlined?: boolean
}

const FormSubmit: React.FC<FormSubmitProps> = ({
  className,
  text,
  children,
  type,
  color,
  onClick,
  isPending,
  isSuccess,
  isDisabled,
  successText,
  pendingText,
  disabledText,
  icon,
  isFullSize,
  autoIcon,
  iconSide,
  outlined,
}) => {
  const displayText =
    isDisabled && disabledText
      ? disabledText
      : isSuccess && successText
      ? successText
      : isPending && pendingText
      ? pendingText
      : text
  const enabled = !isDisabled && !isPending && !isSuccess
  const clickHandler = enabled ? onClick : undefined
  const buttonType = enabled ? type ?? 'submit' : 'button'
  const displayIcon = autoIcon
    ? isSuccess
      ? Icons.checkBasic
      : isPending
      ? Icons.refresh
      : icon
    : icon

  return (
    <>
      <>
        {isFullSize && <SC.Placeholder />}
        <SC.StyledFormSubmit
          className={className}
          onClick={clickHandler}
          type={buttonType}
          variant="contained"
          color={color}
          $isPending={isPending}
          $isSuccess={isSuccess}
          $isFullSize={isFullSize}
          disabled={isDisabled}
          outlined={outlined}
        >
          {displayIcon && iconSide !== 'right' && <SC.StyledIcon icon={displayIcon} />}
          {(children || displayText) && <SC.Label>{children || displayText}</SC.Label>}
          {displayIcon && iconSide === 'right' && <SC.StyledIcon icon={displayIcon} />}
        </SC.StyledFormSubmit>
      </>
    </>
  )
}
export default FormSubmit
