import React, { FC, useCallback, useEffect, useState } from 'react'
import { useStripe } from '@stripe/react-stripe-js'
import { TimeoutId } from '@reduxjs/toolkit/dist/query/core/buildMiddleware/types'

import { Icons } from '../../Icon/types'
import { ActionButtonProps } from '../../ActionButton'
import ModalContainer from '../ModalContainer'

import * as SC from './styled'

export enum PaymentIntentStatus {
  loading = 'loading',
  succeeded = 'succeeded',
  processing = 'processing',
  requires_payment_method = 'requires_payment_method',
  error = 'error',
}

export type StripePaymentConfirmModalProps = {
  className?: string
  title?: string
  closeButton?: ActionButtonProps
  stripeKey: string
  messages?: {
    loading?: string
    succeeded?: string
    processing?: string
    requires_payment_method?: string
    error?: string
  }
  onSucceed?: () => void
}

const StripePaymentConfirmModal: FC<StripePaymentConfirmModalProps> = (props) => {
  const { className, title, messages, closeButton, onSucceed } = props

  const stripe = useStripe()
  const [open, setOpen] = useState(false)
  const [polling, setPolling] = useState<TimeoutId | null>(null)
  const [status, setStatus] = useState<PaymentIntentStatus>(PaymentIntentStatus.loading)

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])

  const checkStripeStatus = useCallback(() => {
    if (!stripe) {
      return
    }
    const clientSecret = new URLSearchParams(window.location.search).get(
      'payment_intent_client_secret'
    )
    if (!clientSecret) {
      return
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent?.status) {
        case 'succeeded':
          setStatus(PaymentIntentStatus.succeeded)
          onSucceed?.()
          break
        case 'processing':
          setStatus(PaymentIntentStatus.processing)
          setPolling(setTimeout(checkStripeStatus, 5000))
          break
        case 'requires_payment_method':
          setStatus(PaymentIntentStatus.requires_payment_method)
          break
        default:
          setStatus(PaymentIntentStatus.error)
          break
      }
    })
  }, [stripe, onSucceed])

  useEffect(() => {
    if (!stripe) {
      return
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      'payment_intent_client_secret'
    )

    if (!clientSecret) {
      return
    }

    setOpen(true)
    checkStripeStatus()
  }, [stripe, checkStripeStatus])

  useEffect(() => {
    return () => {
      if (polling) {
        clearTimeout(polling)
      }
    }
  }, [polling])

  return (
    <ModalContainer open={open} onClose={handleClose} className={className}>
      <SC.Content>
        {title && <SC.Title>{title}</SC.Title>}
        <SC.MyIcon
          icon={
            status === PaymentIntentStatus.succeeded
              ? Icons.statusSuccess
              : status === PaymentIntentStatus.loading || status === PaymentIntentStatus.processing
              ? Icons.statusPending
              : Icons.statusError
          }
        />
        {status && <SC.Message>{messages?.[status] ?? status}</SC.Message>}
      </SC.Content>
      <SC.Buttons>{closeButton && <SC.Button {...closeButton} onClick={handleClose} />}</SC.Buttons>
    </ModalContainer>
  )
}

export default StripePaymentConfirmModal
