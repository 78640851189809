import React, { FC } from 'react'
import { Form, Formik, FormikHelpers } from 'formik'
import * as Yup from 'yup'

import { BasicLinkProps } from '../BasicLink'
import { light } from '../../theme/palette'
import { Icons } from '../Icon/types'
import FormSubmitErrors, { FormSubmitErrorsProps } from '../FormSubmitErrors'
import type { FormFieldConfig, FormFieldProps } from '../../types/form'
import { getValidationSchema, renderField } from '../../helpers/FormHelpers'
import { FormSubmitProps } from '../FormSubmit'
import FormScrollToError from '../FormScrollToError'
import SocialConnect, { SocialConnectProps } from '../SocialConnect'

import * as SC from './styled'

export type SignInFormValues = {
  email: string
  password: string
}

export type SignInFormProps = {
  className?: string
  title: string
  notAlreadySignedUpText: string
  notAlreadySignedUpCta: BasicLinkProps
  mandatoryText: string
  lostPasswordText: string
  lostPasswordCta: BasicLinkProps
  socialConnectProps: SocialConnectProps
  initialValues: SignInFormValues
  fieldsProps?: {
    email: FormFieldProps
    password: FormFieldProps
  }
  errorTexts?: {
    required: string
    email: string
  }
  submitErrors?: FormSubmitErrorsProps['errors']
  submitButton: FormSubmitProps
  onSubmit?: (values: SignInFormValues, formikHelpers: FormikHelpers<SignInFormValues>) => void
}

const SignInForm: FC<SignInFormProps> = (props) => {
  const {
    className,
    title,
    notAlreadySignedUpText,
    notAlreadySignedUpCta,
    submitButton,
    mandatoryText,
    lostPasswordText,
    lostPasswordCta,
    socialConnectProps,
    fieldsProps,
    initialValues,
    errorTexts,
    submitErrors,
    onSubmit = (_v: SignInFormValues) => null,
  } = props

  const fields: FormFieldConfig[] = [
    {
      name: 'email',
      Component: SC.TextField,
      validation: Yup.string().email(errorTexts?.email).required(errorTexts?.required),
      required: true,
    },
    {
      name: 'password',
      Component: SC.TextField,
      validation: Yup.string().required(errorTexts?.required),
      required: true,
      type: 'password',
    },
  ]

  const validationSchema = getValidationSchema(fields)

  return (
    <SC.SignInForm className={className}>
      <SC.Title>{title}</SC.Title>
      <SC.NotAlreadySignedUpText>{notAlreadySignedUpText}</SC.NotAlreadySignedUpText>
      <SC.NotAlreadySignedUpCta
        {...notAlreadySignedUpCta}
        icon={Icons.longArrowRight}
        iconColor={light.colors.freeSpeechBlue}
      />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnChange={true}
        validateOnBlur={true}
        onSubmit={onSubmit}
      >
        {(formikProps) => (
          <Form noValidate>
            <FormScrollToError formikProps={formikProps} />
            <SC.Fields>
              {fields
                .filter((f) => f.name !== 'type' && f.name !== 'sponsor')
                .map((field, index) => renderField(field, formikProps, fieldsProps, index))}
            </SC.Fields>
            <FormSubmitErrors errors={submitErrors} />
            <SC.SubmitButton {...submitButton} autoIcon />
            <SC.MandatoryText>{mandatoryText}</SC.MandatoryText>
          </Form>
        )}
      </Formik>
      <SC.LostPasswordText>{lostPasswordText}</SC.LostPasswordText>
      <SC.LostPasswordCta {...lostPasswordCta} />
      <SC.MuiDivider />
      <SocialConnect {...socialConnectProps} />
    </SC.SignInForm>
  )
}

export default SignInForm
