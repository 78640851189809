import React from 'react'

import * as SC from './styled'

export type ErrorType = {
  label?: string
}

export type FormSubmitErrorsProps = {
  errors?: ErrorType[] | null
}

const FormSubmitErrors: React.FC<FormSubmitErrorsProps> = ({ errors }) => {
  if (!errors || !(errors?.length > 0)) return null

  return (
    <SC.Container>
      {errors?.map((error, key) => (
        <SC.Error key={key}>{error?.label}</SC.Error>
      ))}
    </SC.Container>
  )
}

export default FormSubmitErrors
