import gql from 'graphql-tag'

const resendVerificationMail = {
  mutation: gql`
    mutation resendVerificationMail {
      resendVerificationMail
    }
  `,
  transformer: (response: any): string => {
    return response.resendVerificationMail
  },
}

export default resendVerificationMail
