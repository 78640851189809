import React, { FC, useCallback } from 'react'

import { Icons } from '../Icon/types'

import * as SC from './styled'

export type CollapseProps = {
  isOpen: boolean
  title: string
  className?: string
  onClick: () => void
  children: React.ReactNode
}

const Collapse: FC<CollapseProps> = (props) => {
  const { isOpen, title, children, className, onClick } = props

  const handleClick = useCallback(() => {
    onClick()
  }, [onClick])

  return (
    <SC.Collapse className={`${isOpen ? '-open' : ''} ${className || ''}`}>
      <SC.Wrapper>
        <SC.Top onClick={handleClick}>
          <SC.Title>{title}</SC.Title>
          <SC.SvgIcon icon={Icons.down} isOpen={isOpen} />
        </SC.Top>
        <SC.CollapseContent>{children}</SC.CollapseContent>
      </SC.Wrapper>
    </SC.Collapse>
  )
}

export default Collapse
