import { Container } from '@material-ui/core'
import styled from 'styled-components'
import ReactMarkdown from 'react-markdown'

export const SubjectWithThemeCard = styled.div`
  ${(props) => props.theme.breakpoints.up('md')} {
    max-width: 45rem;
  }
`

export const Wrapper = styled(Container)`
  background-color: ${(props) => props.theme.palette.colors.pureWhite};
  border-radius: 0.8rem;
  padding: 1.6rem;
`

export const Subject = styled.h3`
  ${(props) => ({ ...props.theme.typography.h3 })}
  color: ${(props) => props.theme.palette.colors.cornflowerBlue};
  margin: 0rem 0 1.6rem 0;
`

export const Theme = styled.h5`
  ${(props) => ({ ...props.theme.typography.h5 })}
  color: ${(props) => props.theme.palette.colors.freeSpeechBlue};
  margin: 0 0 1.6rem 0;
`

export const LessonName = styled.h6`
  ${(props) => ({ ...props.theme.typography.h6 })}
  color: ${(props) => props.theme.palette.colors.midnightBlue};
  margin: 0;
`

export const LessonDescription = styled(ReactMarkdown)`
  ${(props) => ({ ...props.theme.typography.tinySmall })}
  color: ${(props) => props.theme.palette.colors.midnightBlue};
  margin: 1.6rem 0 0;
  padding: 1rem 1.6rem;
  border: 1px solid ${(props) => props.theme.palette.colors.gainsboro};
  border-radius: 0.6rem;
  & p {
    margin-top: 0;
  }
`
