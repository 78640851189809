import { Form, Formik, FormikHelpers } from 'formik'
import React, { FC } from 'react'

import FormScrollToError from '../FormScrollToError'
import { FormSubmitProps } from '../FormSubmit'
import FormSubmitErrors, { FormSubmitErrorsProps } from '../FormSubmitErrors'
import { ThemeListProps } from '../ThemeList'

import * as SC from './styled'

export type ThemePickerFormValues = {
  freeTheme: string
  theme: number
}

export type ThemePickerFormProps = {
  className?: string
  themes: ThemeListProps[]
  initialValues: ThemePickerFormValues
  submitButton: FormSubmitProps
  submitErrors?: FormSubmitErrorsProps['errors']
  onSubmit: (
    values: ThemePickerFormValues,
    formikHelpers: FormikHelpers<ThemePickerFormValues>
  ) => void
}

const validationSchema = undefined

const ThemePickerForm: FC<ThemePickerFormProps> = (props) => {
  const { className, themes, initialValues, submitButton, submitErrors, onSubmit } = props

  return (
    <SC.ThemePicker className={className}>
      <SC.Wrapper maxWidth="xl" disableGutters>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnChange={true}
          validateOnBlur={true}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {(formikProps) => (
            <Form noValidate>
              <FormScrollToError formikProps={formikProps} />
              <SC.Themes>
                {themes.map((theme, item) => (
                  <SC.ThemeList
                    key={item}
                    {...theme}
                    clicked={formikProps.values.theme}
                    onClick={(value: number) => {
                      formikProps.setFieldValue('theme', value)
                    }}
                    isDisabled={formikProps.values.freeTheme.length > 1}
                  />
                ))}
              </SC.Themes>
              <FormSubmitErrors errors={submitErrors} />
              <SC.SubmitButton {...submitButton} isDisabled={!formikProps.dirty} />
            </Form>
          )}
        </Formik>
      </SC.Wrapper>
    </SC.ThemePicker>
  )
}

export default ThemePickerForm
