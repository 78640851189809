import React, { FC, ReactNode, useState } from 'react'

import { ActionButtonProps } from '../../ActionButton'
import { Icons } from '../../Icon/types'
import { CancelCourseFormProps } from '../../CancelCourseForm'

import * as SC from './styled'

export type DisclaimerProps = {
  title: string
  text: string | ReactNode
  confirmProps: ActionButtonProps
  cancelProps: ActionButtonProps
}

export type CancelCourseModalProps = {
  className?: string
  headerText: string
  text?: string
  open: boolean
  disclaimerProps?: DisclaimerProps
  formProps: CancelCourseFormProps
  onClose?: () => void
}

const CancelCourseModal: FC<CancelCourseModalProps> = (props) => {
  const { className, headerText, open, onClose, formProps, disclaimerProps } = props

  const [confirmed, setConfirmed] = useState(!disclaimerProps)

  return (
    <SC.Modal open={open} onClose={onClose} className={className}>
      <SC.HeaderText>{headerText}</SC.HeaderText>
      <SC.Content>
        {!confirmed && disclaimerProps ? (
          <SC.Disclaimer>
            <SC.PebbleIcon icon={Icons.pebbleWarning} />
            <SC.Title>{disclaimerProps?.title}</SC.Title>
            <SC.Text>{disclaimerProps?.text}</SC.Text>
            <SC.Buttons>
              <SC.SkipButton {...disclaimerProps.cancelProps} outlined onClick={onClose} />
              <SC.SubmitButton
                {...disclaimerProps.confirmProps}
                onClick={() => setConfirmed(true)}
              />
            </SC.Buttons>
          </SC.Disclaimer>
        ) : (
          <SC.Form {...formProps} />
        )}
      </SC.Content>
    </SC.Modal>
  )
}

export default CancelCourseModal
