import React, { FC } from 'react'

import { BasicProfilProps } from '../BasicProfil'
import { LinkProps } from '../Link'
import { ImageTextListProps } from '../ImageTextList'

import * as SC from './styled'

export type CourseMaterialsCardProps = {
  className?: string
  date?: string
  description?: string
  teacherProfil?: BasicProfilProps
  isNew?: boolean
  link?: LinkProps
  documents?: ImageTextListProps
}

const CourseMaterialsCard: FC<CourseMaterialsCardProps> = (props) => {
  const { className, date, description, isNew, teacherProfil, link, documents } = props

  return (
    <SC.CourseMaterialsCard className={className} {...link}>
      <SC.Wrapper $isNew={isNew}>
        {date && <SC.Date>{date}</SC.Date>}
        {date && <SC.Muidivider />}
        {teacherProfil && <SC.BasicProfil {...teacherProfil} />}
        {description && <SC.Description>{description}</SC.Description>}
        {documents && <SC.DetailDocuments {...documents} />}
      </SC.Wrapper>
    </SC.CourseMaterialsCard>
  )
}

export default CourseMaterialsCard
