import gql from 'graphql-tag'

import { User } from '../../../../generated/graphql'
import UserFragment from '../../../fragments/UserFragment'

const socialLogin = {
  mutation: gql`
    mutation socialLogin(
      $code: String!
      $type: SocialProviderType!
      $first_name: String
      $last_name: String
      $email: String
    ) {
      socialLogin(
        code: $code
        type: $type
        first_name: $first_name
        last_name: $last_name
        email: $email
      ) {
        ...UserFragment
      }
    }
    ${UserFragment}
  `,
  transformer: (response: any): User => {
    return response.socialLogin
  },
}

export default socialLogin
