import styled from 'styled-components'
import { Container as MuiContainer } from '@material-ui/core'

export const Container = styled(MuiContainer)`
  min-height: 100vh;
  display: flex;
  flex-flow: column;
  align-items: stretch;
  padding: 0 0 9rem;
  background: bottom center / cover no-repeat;
  background-color: ${(props) => props.theme.palette.colors.whiteSmoke};
  ${(props) => props.theme.breakpoints.up('md')} {
    max-width: none;
    background: bottom center / cover no-repeat;
  }
`
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${(props) => props.theme.breakpoints.up('md')} {
    padding-top: 3rem;
    width: 100%;
  }
`
