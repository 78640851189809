import gql from 'graphql-tag'

import { Course } from '../../../../generated/graphql'
import CourseFragment from '../../../fragments/CourseFragment'

const cancelCourse = {
  mutation: gql`
    mutation cancelCourse(
      $course: ID!
      $reason: CourseCancelReason!
      $text: String!
      $file: Upload
    ) {
      cancelCourse(course: $course, reason: $reason, text: $text, file: $file) {
        ...CourseFragment
      }
    }
    ${CourseFragment}
  `,
  transformer: (response: any): Course => {
    return response.cancelCourse
  },
}

export default cancelCourse
