import styled from 'styled-components'

import PageTitle from '../../components/PageTitle'
import PageBackButton from '../../components/PageBackButton'
import TabContentC from '../../components/TabContent'
import ActionButtonC from '../../components/ActionButton'
import PotC from '../../components/Pot'
import CardListC from '../../components/CardList'

export const Content = styled.div``

export const Title = styled(PageTitle)``
export const BackButton = styled(PageBackButton)``

export const TabContent = styled(TabContentC)`
  padding: 0 1.6rem;
  margin-top: 1.6rem;
`

export const ActionButton = styled(ActionButtonC)`
  margin-top: 2rem;
  width: auto;
  & > * {
    width: auto;
  }
`

export const Pot = styled(PotC)``

export const CardList = styled(CardListC)``
