import React, { FC } from 'react'

import Header, { HeaderProps } from '../../components/Header'
import Menu, { MenuProps } from '../../components/Menu'
import AuthLayout from '../../layouts/AuthLayout'
import { PageBackButtonProps } from '../../components/PageBackButton'
import { ParentSideScoreCardProps } from '../../components/ParentSideScoreCard'
import { ActionButtonProps } from '../../components/ActionButton'
import ActionSubmitModal, {
  ActionSubmitModalProps,
} from '../../components/modals/ActionSubmitModal'

import * as SC from './styled'

export type PreferencesFamilyTemplateProps = {
  className?: string
  headerProps: HeaderProps
  menuProps: MenuProps
  title: string
  backButton: PageBackButtonProps
  childrenProps?: ParentSideScoreCardProps[]
  addChildProps: ActionButtonProps
  deleteModalProps?: ActionSubmitModalProps
}

const PreferencesFamilyTemplate: FC<PreferencesFamilyTemplateProps> = (props) => {
  const {
    headerProps,
    menuProps,
    title,
    backButton,
    childrenProps,
    addChildProps,
    deleteModalProps,
  } = props

  return (
    <AuthLayout header={<Header {...headerProps} withDivider />} menu={<Menu {...menuProps} />}>
      <SC.Content>
        <SC.BackButton {...backButton} />
        <SC.Title>{title}</SC.Title>
        <SC.Children>
          {childrenProps?.map((child, i) => (
            <SC.ScoreCard {...child} key={i} />
          ))}
        </SC.Children>
        <SC.Action {...addChildProps} outlined />
        {deleteModalProps && <ActionSubmitModal {...deleteModalProps} />}
      </SC.Content>
    </AuthLayout>
  )
}

export default PreferencesFamilyTemplate
