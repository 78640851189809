import gql from 'graphql-tag'

const stripeLoginLink = {
  query: gql`
    query stripeLoginLink($redirect_url: String) {
      stripeLoginLink(redirect_url: $redirect_url)
    }
  `,
  transformer: (response: any): string => {
    return response.stripeLoginLink
  },
}

export default stripeLoginLink
