import styled from 'styled-components'
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab'

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
`

export const Label = styled.h4`
  ${(props) => ({ ...props.theme.typography.h5 })};
  color: ${({ theme }) => theme.palette.colors.midnightBlue};
  margin: 1rem 0;
`

export const MuiToggleButtonGroup = styled(ToggleButtonGroup)`
  flex-wrap: wrap;
  & > button {
    margin-right: 1rem;
  }
  & .MuiToggleButtonGroup-groupedHorizontal:not(:first-child) {
    margin-left: 0;
  }
`

export const MuiToggleButton = styled(ToggleButton)<{ $isFullWidth?: boolean }>`
  margin-bottom: 1.6rem;
  max-width: ${(props) => (props.$isFullWidth ? '100%' : '14rem')};
  min-width: ${(props) => (props.$isFullWidth ? '100%' : '10rem')};
  border: 1px solid ${(props) => props.theme.palette.colors.freeSpeechBlue} !important;
  border-radius: 0.5rem !important;
  & > span {
    color: ${(props) => props.theme.palette.colors.freeSpeechBlue};
    ${(props) => ({ ...props.theme.typography.h5 })}
    text-transform: capitalize;
  }
  &:hover {
    background-color: ${(props) => props.theme.palette.colors.cornflowerBlue};
    border-color: ${(props) => props.theme.palette.colors.cornflowerBlue};
    & > span {
      color: ${(props) => props.theme.palette.colors.pureWhite};
    }
  }
  &.Mui-selected {
    background-color: ${(props) => props.theme.palette.colors.cornflowerBlue};
    & > span {
      color: ${(props) => props.theme.palette.colors.pureWhite};
    }
    &:hover {
      background-color: ${(props) => props.theme.palette.colors.cornflowerBlue} !important;
      & > span {
        color: ${(props) => props.theme.palette.colors.pureWhite} !important;
      }
    }
  }
  &.Mui-disabled {
    border-color: ${(props) => props.theme.palette.colors.gainsboro} !important;
    & > span {
      color: ${(props) => props.theme.palette.colors.gainsboro};
    }
  }
  @media (hover: none) {
    &.MuiToggleButton-root:hover {
      background-color: transparent;
      & > span {
        color: ${(props) => props.theme.palette.colors.freeSpeechBlue};
      }
    }
  }
`
