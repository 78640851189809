import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { SocialConnectProps } from '../components/SocialConnect'
import { Icons } from '../components/Icon/types'
import social from '../configuration/social'
import { actions, selectors } from '../redux'

import { useFormSubmit } from './useFormSubmit'

const useSocialConnect = (): SocialConnectProps => {
  const { t } = useTranslation()

  const [submit, handleSubmit] = useFormSubmit(
    selectors.auth.socialLogin,
    actions.auth.socialLoginRequest,
    actions.auth.socialLoginReset
  )

  return useMemo(() => {
    return {
      redirectUri: social.CALLBACK_URL,
      onSubmit: handleSubmit,
      isPending: submit.pending,
      ...(social.GOOGLE_CLIENT_ID && {
        googleCta: {
          clientId: social.GOOGLE_CLIENT_ID,
          text: t('socialConnect_google'),
          icon: Icons.google,
          iconSide: 'left',
        },
      }),
      ...(social.APPLE_CLIENT_ID && {
        appleCta: {
          clientId: social.APPLE_CLIENT_ID,
          text: t('socialConnect_apple'),
          icon: Icons.apple,
          iconSide: 'left',
        },
      }),
    }
  }, [t, submit.pending, handleSubmit])
}

export default useSocialConnect
