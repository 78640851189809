import { Modal } from '@material-ui/core'
import styled from 'styled-components'

export const ModalContainer = styled(Modal)`
  align-items: center;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
`

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  padding: 1.6rem;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
`

export const ModalContainerContent = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: ${(props) => props.theme.palette.colors.whiteSmoke};
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.12);
  border: 1px solid ${(props) => props.theme.palette.colors.ghostWhite};
  border-radius: 0.8rem;
  width: 100%;
  padding: 0 1.6rem;
  ${(props) => props.theme.breakpoints.up('md')} {
    max-width: 52rem;
  }
  outline: 0;
  margin: auto;
`
