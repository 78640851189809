import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'

import { HeaderProps } from '../../components/Header'
import useHeader from '../../hooks/useHeader'
import DashboardTemplate, {
  AvailabilitiesProps,
  DashboardTemplateProps,
  FamilyProps,
} from '../../templates/Dashboard'
import { MenuProps } from '../../components/Menu'
import useMenu from '../../hooks/useMenu'
import { actions, selectors } from '../../redux'
import { UserType } from '../../generated/graphql'
import { ImageTextItemProps } from '../../components/ImageTextItem'
import { router, routesPath } from '../../router'
import { CourseCardInContextProps } from '../../components/CourseCardInContext'
import { Icons } from '../../components/Icon/types'
import { light } from '../../theme/palette'
import { dashboardCourseCards } from '../../transformers/courseTransformers'
import { TeacherDashboardCoursesResult } from '../../graphql/services/course/queries/teacherDashboardCourses'
import { DashboardCoursesResult } from '../../graphql/services/course/queries/dashboardCourses'
import { PotProps } from '../../components/Pot'
import { MessageProps } from '../../components/Message'
import { AttachParentModalProps } from '../../components/modals/AttachParentModal'
import { SignUpAttachParentType } from '../../components/SignUpAttachParent'
import { useFormSubmit } from '../../hooks/useFormSubmit'

const DashboardPage: React.FC<RouteComponentProps> = (props) => {
  const headerProps: HeaderProps = useHeader()
  const menuProps: MenuProps = useMenu(props)
  const user = useSelector(selectors.auth.user)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const dashboardCourses = useSelector(selectors.planning.dashboardCourses)
  const teacherDashboardCourses = useSelector(selectors.planning.teacherDashboardCourses)
  const dashboard: TeacherDashboardCoursesResult | DashboardCoursesResult | null =
    user?.user_type === UserType.Teacher ? teacherDashboardCourses?.data : dashboardCourses?.data
  const teacherAvailabilitiesOfMonth = useSelector(
    selectors.preferences.teacherAvailabilitiesOfMonth
  )
  const emptyWallet = !(
    (user?.wallet?.amount as number) > 0 || (user?.wallet?.reserved as number) > 0
  )

  const pendingReviewCourses = (dashboard as TeacherDashboardCoursesResult)?.pendingReview

  const courseCards: CourseCardInContextProps[] = useMemo(
    () => dashboardCourseCards(dashboard, t, user),
    [dashboard, t, user]
  )

  const [parentModalOpen, setParentModalOpen] = useState(false)

  const onAttachParentComplete = useCallback(() => {
    setParentModalOpen(false)
    dispatch(actions.auth.signUpChildAddGuardianReset(undefined))
  }, [setParentModalOpen, dispatch])

  const [attachParent, handleAttachParent] = useFormSubmit(
    selectors.auth.signUpChildAddGuardian,
    actions.auth.signUpChildAddGuardianRequest,
    actions.auth.signUpChildAddGuardianReset,
    onAttachParentComplete
  )

  const potProps: PotProps = useMemo(
    () => ({
      image: { alt: 'LiberteClass - Cagnotte', src: '/static/assets/images/pot.png' },
      text: t('wallet_title'),
      ...(user?.user_type === UserType.Teacher
        ? {
            ...(emptyWallet
              ? {
                  text: t('wallet_teacher_empty_title'),
                }
              : {
                  credits: t('wallet_amount_value', {
                    amount: (user?.wallet?.amount ?? 0) / 100,
                  }),
                  otherCredits:
                    (user?.wallet?.reserved ?? 0) > 0
                      ? t('wallet_teacher_locked_value', {
                          amount: (user?.wallet?.reserved ?? 0) / 100,
                        })
                      : '',
                }),
          }
        : {
            credits: t('wallet_amount_value', {
              amount: (user?.wallet?.amount ?? 0) / 100,
            }),
            availableLabel: t('wallet_amount_available', {
              count: (user?.wallet?.amount ?? 0) / 100,
            }),
            otherCredits: user?.wallet?.reserved
              ? t('wallet_locked_value', { amount: user?.wallet?.reserved / 100 })
              : undefined,
            ...(user?.user_type === UserType.Guardian
              ? {
                  transferringCredits: user?.wallet?.refund
                    ? t('wallet_withdrawal_value', { amount: user?.wallet?.refund / 100 })
                    : undefined,
                  helperText: emptyWallet ? t('wallet_add_text') : undefined,
                  button: {
                    text: t('wallet_add_label'),
                    link: {
                      href: router(routesPath.preferencesWalletDeposit),
                    },
                  },
                }
              : {
                  ...(emptyWallet &&
                    (user?.student?.guardian?.id
                      ? {
                          helperText: t('wallet_children_empty_title'),
                        }
                      : {
                          helperText: t('wallet_add_parent_title'),
                          button: {
                            text: t('wallet_add_parent_label'),
                            onClick: () => {
                              console.log('open')
                              setParentModalOpen(true)
                            },
                          },
                        })),
                }),
          }),
    }),
    [
      emptyWallet,
      t,
      user?.student?.guardian?.id,
      user?.user_type,
      user?.wallet?.amount,
      user?.wallet?.reserved,
      user?.wallet?.refund,
      setParentModalOpen,
    ]
  )

  const attachParentModalProps: AttachParentModalProps | undefined = useMemo(
    () => ({
      headerText: t('attachParentModal_title'),
      open: parentModalOpen,
      onClose: () => setParentModalOpen(false),
      attachParentFormProps: {
        text: t('attachParentModal_text'),
        codeTabLabel: t('attachParentModal_tabs_code'),
        mailTabLabel: t('attachParentModal_tabs_email'),
        fieldsProps: {
          code: {
            label: t('attachParentModal_fields_code_label'),
            isLabelExternal: true,
            required: false,
          },
          mail: {
            label: t('attachParentModal_fields_email_label'),
            isLabelExternal: true,
            required: false,
          },
        },
        initialValues: {
          type: SignUpAttachParentType.CODE,
          code: '',
          mail: '',
        },
        errorTexts: {
          required: t('error_required'),
          email: t('error_email'),
          code: t('error_code'),
          codeInvalid: t('error_code_invalid'),
        },
        skipButton: {
          text: t('attachParentModal_cancel_label'),
          onClick: () => setParentModalOpen(false),
          outlined: true,
        },
        submitButton: {
          text: t('attachParentModal_submit_label'),
          isPending: attachParent.pending,
          isSuccess: attachParent.success,
        },
        submitErrors: attachParent.errors,
        onSubmit: handleAttachParent,
      },
    }),
    [parentModalOpen, t, handleAttachParent, attachParent]
  )

  const missingEvalProps: MessageProps | undefined = useMemo(
    () =>
      pendingReviewCourses?.total > 0
        ? {
            backgroundColor: light.colors.tomato,
            text: t('dashboard_missingEval_text', { count: pendingReviewCourses?.total }),
            icon: {
              icon: Icons.longArrowRight,
            },
            link: {
              onClick: () => {
                document.querySelector('#pending-review')?.scrollIntoView({ behavior: 'smooth' })
              },
            },
          }
        : undefined,
    [pendingReviewCourses?.total, t]
  )

  const familyProps: FamilyProps | undefined = useMemo(
    () =>
      user?.user_type === UserType.Guardian
        ? {
            title: t('dashboard_family_title'),
            text: t('dashboard_family_empty_text'),
            addChildProps: {
              text: t('dashboard_family_add'),
              link: {
                href: router(routesPath.preferencesFamilyAddChild),
              },
            },
            children: user?.guardian?.children?.map(
              (child): ImageTextItemProps => ({
                image: {
                  alt: child.first_name ?? '',
                  src: child.avatar ?? '',
                },
                isAvatar: true,
                text1: child?.first_name ?? '',
                text2: child?.grade?.name ?? '',
                link: {
                  href: router(routesPath.dashboardChild, { child: child.id }),
                },
              })
            ),
            manageFamilyProps: {
              text: t('dashboard_family_manage'),
              link: {
                href: router(routesPath.preferencesFamily),
              },
            },
          }
        : undefined,
    [user?.guardian?.children, user?.user_type, t]
  )

  const availabilitiesProps: AvailabilitiesProps | undefined = useMemo(
    () =>
      user?.user_type === UserType.Teacher &&
      teacherAvailabilitiesOfMonth?.complete &&
      (!teacherAvailabilitiesOfMonth?.data || !teacherAvailabilitiesOfMonth?.data?.length)
        ? {
            title: t('dashboard_noAvailabilities_title'),
            text: t('dashboard_noAvailabilities_text'),
            cta: {
              text: t('dashboard_noAvailabilities_cta'),
              link: {
                href: router(routesPath.preferencesAvailabilities),
              },
            },
          }
        : undefined,
    [teacherAvailabilitiesOfMonth?.complete, teacherAvailabilitiesOfMonth?.data, user?.user_type, t]
  )

  const templateProps: DashboardTemplateProps = useMemo(
    () => ({
      headerProps,
      menuProps,
      courseCards,
      potProps,
      missingEvalProps,
      familyProps,
      availabilitiesProps,
      attachParentModalProps,
    }),
    [
      availabilitiesProps,
      courseCards,
      familyProps,
      headerProps,
      menuProps,
      missingEvalProps,
      potProps,
      attachParentModalProps,
    ]
  )

  useEffect(
    () => () => {
      dispatch(actions.planning.dashboardCoursesReset(undefined))
      dispatch(actions.planning.teacherDashboardCoursesReset(undefined))
    },
    [dispatch]
  )

  useEffect(() => {
    if (user?.user_type === UserType.Student) {
      dispatch(actions.planning.dashboardCoursesRequest(undefined))
    }
    if (user?.user_type === UserType.Teacher) {
      dispatch(actions.planning.teacherDashboardCoursesRequest(undefined))
      dispatch(
        actions.preferences.teacherAvailabilitiesOfMonthRequest({
          month: dayjs().month() + 1,
          year: dayjs().year(),
        })
      )
    }
  }, [user?.user_type, dispatch])

  return <DashboardTemplate {...templateProps} />
}

export default DashboardPage
