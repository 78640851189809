import gql from 'graphql-tag'

import type { User } from '../../../../generated/graphql'

const updatePayoutScheduleInterval = {
  mutation: gql`
    mutation updatePayoutScheduleInterval($interval: PayoutScheduleInterval!) {
      updatePayoutScheduleInterval(interval: $interval) {
        teacher {
          payouts_schedule_interval
        }
      }
    }
  `,
  transformer: (response: any): User => {
    return response.updatePayoutScheduleInterval
  },
}

export default updatePayoutScheduleInterval
