import { Container, FormHelperText } from '@material-ui/core'
import Rating from '@material-ui/lab/Rating'
import styled from 'styled-components'

export const RatingCustom = styled.div``

export const Wrapper = styled(Container)`
  display: flex;
  align-items: center;
`

export const MuiRating = styled(Rating)`
  font-size: ${({ size }) => (size === 'large' ? '4.5rem' : '3rem')};
  & .MuiRating-iconFilled {
    color: ${(props) => props.theme.palette.colors.neonCarrot};
  }
`

export const HelperText = styled(FormHelperText)`
  text-align: left;
  ${(props) => ({ ...props.theme.typography.smallRegular })}
  color: ${(props) => props.theme.palette.colors.grey};
  margin: 3px 0 0 0;
  &.Mui-error {
    color: ${(props) => props.theme.palette.error.main};
  }
`
