import React, { FC, useCallback, useEffect, useState } from 'react'
import { stringify } from 'query-string'

import { ActionButtonProps } from '../ActionButton'
import FormSubmitErrors, { FormSubmitErrorsProps } from '../FormSubmitErrors'

import * as SC from './styled'

export enum SocialConnectType {
  APPLE = 'APPLE',
  GOOGLE = 'GOOGLE',
}

export type SocialConnectValues = {
  type: string
  code: string
  user?: {
    first_name?: string
    last_name?: string
    email?: string
    name?: {
      firstName?: string
      lastName?: string
    }
  }
}

export type SocialConnectProps = {
  className?: string
  googleCta?: ActionButtonProps & { clientId: string }
  appleCta?: ActionButtonProps & { clientId: string }
  redirectUri: string
  isPending?: boolean
  submitErrors?: FormSubmitErrorsProps['errors']
  onSubmit?: (values: SocialConnectValues) => void
}

const SocialConnect: FC<SocialConnectProps> = (props) => {
  const {
    className,
    googleCta,
    appleCta,
    redirectUri,
    submitErrors,
    onSubmit = (_v: SocialConnectValues) => null,
  } = props

  const [currentType, setCurrentType] = useState('')
  const [isSafari, setIsSafari] = useState(false)

  const createPopup = useCallback((type, url, title, width, height) => {
    console.log('connect with', type)
    setCurrentType(type)
    const left = window.screenX + (window.outerWidth - width) / 2
    const top = window.screenY + (window.outerHeight - height) / 2.5
    window.open(url, title, `width=${width},height=${height},left=${left},top=${top}`)
  }, [])

  useEffect(() => {
    const ua = navigator.userAgent.toLowerCase()

    if (ua.indexOf('safari') !== -1) {
      if (ua.indexOf('chrome') === -1) {
        setIsSafari(true)
      }
    }
  }, [])

  const onMessage = useCallback(
    (e) => {
      //console.log('received message', currentType, e.data)
      if (e?.data?.type && e?.data?.type === 'code') {
        console.log('[SocialConnect] onChange', { currentType, data: e.data })
        if (e.data.code) {
          onSubmit?.({ type: currentType, code: e.data.code, user: e.data?.user })
          return
        }
      }

      if (currentType === SocialConnectType.APPLE && e.data) {
        let rs
        try {
          rs = JSON.parse(e.data)
          console.log(rs.data)
          if (rs?.data?.authorization) {
            onSubmit?.({
              type: currentType,
              code: rs?.data?.authorization.code,
              user: rs?.data?.user,
            })
          }
        } catch (err) {
          console.log(err)
        }
      }
    },
    [currentType, onSubmit]
  )

  useEffect(() => {
    const messageHandler = onMessage
    window.addEventListener('message', messageHandler)
    return () => {
      window.removeEventListener('message', messageHandler)
    }
  }, [onMessage])

  const handleApple = useCallback(
    () =>
      createPopup(
        SocialConnectType.APPLE,
        `https://appleid.apple.com/auth/authorize?${stringify({
          response_type: 'code',
          response_mode: isSafari ? 'web_message' : 'form_post',
          client_id: appleCta?.clientId,
          redirect_uri: redirectUri,
          state: '',
          nonce: '',
          scope: 'name email',
        })}`,
        'Apple login',
        650,
        740
      ),
    [createPopup, redirectUri, isSafari, appleCta?.clientId]
  )

  const handleGoogle = useCallback(
    () =>
      createPopup(
        SocialConnectType.GOOGLE,
        `https://accounts.google.com/o/oauth2/v2/auth?${stringify({
          client_id: googleCta?.clientId,
          redirect_uri: redirectUri,
          state: '',
          scope:
            'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile',
          access_type: 'offline',
          response_type: 'code',
        })}`,
        'Google login',
        530,
        740
      ),
    [createPopup, redirectUri, googleCta?.clientId]
  )

  return (
    <SC.SocialConnect className={className}>
      {googleCta && <SC.GoogleCta {...googleCta} onClick={handleGoogle} />}
      {appleCta && <SC.AppleCta {...appleCta} onClick={handleApple} />}
      <FormSubmitErrors errors={submitErrors} />
    </SC.SocialConnect>
  )
}

export default SocialConnect
