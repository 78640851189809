import React, { FC } from 'react'
import cx from 'classnames'

import * as SC from './styled'

export type SignupLayoutProps = {
  className?: string
  header?: React.ReactNode
  children?: React.ReactNode
  side?: React.ReactNode
}

const SignUpLayout: FC<SignupLayoutProps> = (props) => {
  const { children, className, header = null, side = null } = props

  return (
    <SC.Container className={cx(className)}>
      <>{header}</>
      <SC.Wrapper>
        <SC.Main>{children}</SC.Main>
        {side && <SC.Side>{side}</SC.Side>}
      </SC.Wrapper>
    </SC.Container>
  )
}

export default SignUpLayout
