import React, { FC, useMemo } from 'react'
import { Form, Formik, FormikHelpers } from 'formik'
import * as Yup from 'yup'

import { getValidationSchema, renderField } from '../../helpers/FormHelpers'
import { FormFieldConfig, FormFieldProps } from '../../types/form'
import { FormSubmitProps } from '../FormSubmit'
import FormSubmitErrors, { FormSubmitErrorsProps } from '../FormSubmitErrors'
import FormScrollToError from '../FormScrollToError'

import * as SC from './styled'

export type WalletPeriodicityFormValues = {
  periodicity: string
}

export type WalletPeriodicityFormProps = {
  className?: string
  text?: string
  fieldsProps?: {
    periodicity?: FormFieldProps
  }
  errorTexts?: {
    required: string
  }
  initialValues: WalletPeriodicityFormValues
  submitErrors?: FormSubmitErrorsProps['errors']
  skipButton: FormSubmitProps
  submitButton: FormSubmitProps
  onSubmit?: (
    values: WalletPeriodicityFormValues,
    formikHelpers: FormikHelpers<WalletPeriodicityFormValues>
  ) => void
}

const WalletPeriodicityForm: FC<WalletPeriodicityFormProps> = (props) => {
  const {
    className,
    text,
    fieldsProps,
    errorTexts,
    initialValues,
    submitErrors,
    skipButton,
    submitButton,
    onSubmit = (_values, _formikHelpers) => null,
  } = props

  const periodicityField = useMemo(
    () => ({
      name: 'periodicity',
      Component: SC.TextField,
      validation: Yup.string().required(errorTexts?.required),
      required: false,
    }),
    [errorTexts]
  )

  const fields: FormFieldConfig[] = [periodicityField]

  const validationSchema = getValidationSchema(fields)

  return (
    <SC.Attach className={className}>
      {text && <SC.Text>{text}</SC.Text>}
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnChange={true}
        validateOnBlur={true}
        onSubmit={onSubmit}
      >
        {(formikProps) => (
          <Form noValidate>
            <FormScrollToError formikProps={formikProps} />
            <SC.Fields>{renderField(periodicityField, formikProps, fieldsProps, 1)}</SC.Fields>
            <FormSubmitErrors errors={submitErrors} />
            <SC.Buttons>
              <SC.SkipButton {...skipButton} type={'button'} autoIcon outlined />
              <SC.SubmitButton {...submitButton} autoIcon />
            </SC.Buttons>
          </Form>
        )}
      </Formik>
    </SC.Attach>
  )
}

export default WalletPeriodicityForm
