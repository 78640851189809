import React, { FC, ReactNode, useCallback } from 'react'
import { InputAdornment, TextFieldProps as MuiTextFieldProps } from '@material-ui/core'

import { Icons } from '../../Icon/types'

import * as SC from './styled'

// omit "variant" to fix TS error
export type TextFieldProps = Omit<MuiTextFieldProps, 'variant' | 'onChange' | 'error'> & {
  className?: string
  name: string
  error?: string
  icon?: Icons
  iconColor?: string
  isLabelExternal?: boolean
  selectOptions?: { label: string; value: string }[]
  onChange?: (name: string, value: string) => void
  helperTextSide?: 'start' | 'end'
  children?: ReactNode
}

const TextField: FC<TextFieldProps> = ({
  className,
  name,
  icon,
  iconColor,
  isLabelExternal,
  selectOptions,
  onChange,
  helperTextSide = 'end',
  error,
  children,
  helperText,
  ...props
}) => {
  const handleOnChange: MuiTextFieldProps['onChange'] = useCallback(
    (e) => {
      onChange?.(name, e.target.value)
    },
    [onChange, name]
  )

  return (
    <SC.Wrapper className={className}>
      {isLabelExternal && props.label && (
        <SC.Label htmlFor={props?.id} required={props.required} error={!!error}>
          {props.label}
        </SC.Label>
      )}
      <SC.MuiTextField
        $empty={!props?.value}
        {...props}
        name={name}
        value={props.value ?? ''}
        onChange={handleOnChange}
        variant={'outlined' as any}
        label={isLabelExternal ? '' : props.label}
        placeholder={isLabelExternal || !props.label ? props.placeholder : ''}
        $helperTextSide={helperTextSide}
        error={!!error}
        helperText={error || helperText}
        InputProps={{
          ...props.InputProps,
          endAdornment: icon && (
            <InputAdornment position={'end'}>
              <SC.AdornmentIcon icon={icon} color={iconColor} />
            </InputAdornment>
          ),
        }}
        SelectProps={{
          ...props.SelectProps,
          displayEmpty: true,
          renderValue: !props?.value ? () => props.placeholder : undefined,
          // eslint-disable-next-line react/display-name
          IconComponent: (p) => <SC.SelectIcon {...p} icon={Icons.down} />,
        }}
      >
        {selectOptions?.map((selectOption) => (
          <SC.MuiMenuItem key={selectOption.value} value={selectOption.value}>
            {selectOption.label}
          </SC.MuiMenuItem>
        ))}
        {children}
      </SC.MuiTextField>
    </SC.Wrapper>
  )
}

export default TextField
