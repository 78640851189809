import { Container } from '@material-ui/core'
import styled from 'styled-components'

import TextField from '../form/TextField'
import PaymentFieldC from '../form/PaymentField'

export const CreditCartCard = styled.div`
  ${(props) => props.theme.breakpoints.up('md')} {
    max-width: 40rem;
  }
`

export const Wrapper = styled(Container)`
  padding: 1.6rem;
  background-color: ${(props) => props.theme.palette.colors.pureWhite};
  border-radius: 0.8rem;
  border: 1px solid ${(props) => props.theme.palette.colors.ghostWhite};
`

export const Title = styled.h4`
  ${(props) => ({ ...props.theme.typography.smallStrong })}
  color: ${(props) => props.theme.palette.colors.cornflowerBlue};
  margin: 0;
`

export const PaymentField = styled(PaymentFieldC)``

export const Field = styled(TextField)`
  margin: 1.2rem 0;
`
