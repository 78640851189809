import { Container } from '@material-ui/core'
import styled from 'styled-components'

import ToggleButtonGroupC from '../form/ToggleButtonGroup'

export const LevelPicker = styled.div``

export const Wrapper = styled(Container)<{ $isDeployed?: boolean }>`
  cursor: pointer;
  border: 1px solid
    ${(props) =>
      props.$isDeployed
        ? props.theme.palette.colors.cornflowerBlue
        : props.theme.palette.colors.gainsboro};
  border-radius: 0.8rem;
`

export const Title = styled.h2<{ $isDeployed?: boolean }>`
  width: 100%;
  color: ${(props) =>
    props.$isDeployed
      ? props.theme.palette.colors.cornflowerBlue
      : props.theme.palette.colors.freeSpeechBlue};
  ${(props) => ({ ...props.theme.typography.h4 })}
`

export const SchoolTitle = styled.p`
  ${(props) => ({ ...props.theme.typography.h5 })}
  color: ${(props) => props.theme.palette.colors.chambray};
`

export const Group = styled.div``

export const ToggleButtonGroup = styled(ToggleButtonGroupC)``
