import styled from 'styled-components'

import PageTitle from '../../components/PageTitle'
import PageBackButton from '../../components/PageBackButton'
import ParentSideScoreCard from '../../components/ParentSideScoreCard'
import ActionButton from '../../components/ActionButton'

export const Content = styled.div``

export const Title = styled(PageTitle)``
export const BackButton = styled(PageBackButton)``
export const Children = styled.div`
  display: flex;
  margin: -0.8rem;
  flex-direction: column;
  padding: 1.6rem;
  width: 100%;
  ${(props) => props.theme.breakpoints.up('md')} {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
  }
`
export const ScoreCard = styled(ParentSideScoreCard)`
  padding: 1.6rem 1.6rem 1.6rem 1.6rem;
  margin: 0.8rem;
  width: 100%;
  ${(props) => props.theme.breakpoints.up('md')} {
    max-width: 52rem;
  }
`
export const Action = styled(ActionButton)`
  margin: 1.6rem;
  width: calc(100% - 3.2rem);
  ${(props) => props.theme.breakpoints.up('md')} {
    max-width: 52rem;
    margin: 1.6rem;
  }
`
