import React from 'react'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { HeaderProps } from '../../components/Header'
import useHeader from '../../hooks/useHeader'
import { MenuProps } from '../../components/Menu'
import useMenu from '../../hooks/useMenu'
import { CourseType, Discipline, UserType } from '../../generated/graphql'
import { RestrictedReactFC } from '../../types/common'
import { actions, selectors } from '../../redux'
import {
  SearchDisciplineTemplate,
  SearchDisciplineTemplateProps,
} from '../../templates/SearchDiscipline'
import { ImageTextItemProps } from '../../components/ImageTextItem'
import { Icons } from '../../components/Icon/types'
import { light } from '../../theme/palette'
import { router, routesPath } from '../../router'
import { courseTypeBySlug, courseTypeTranslation } from '../../graphql/enums/CourseType'
import { SearchSteps } from '../../redux/search/types/state'
import useBackButton from '../../hooks/useBackButton'

const SearchDisciplinePage: RestrictedReactFC<
  RouteComponentProps<{ type: string; child?: string }>
> = (props) => {
  const headerProps: HeaderProps = useHeader()
  const menuProps: MenuProps = useMenu(props)
  const { t } = useTranslation()
  const user = useSelector(selectors.auth.user)
  const typeSlug = props?.match?.params?.type
  const child = props?.match?.params?.child
  const type: CourseType = courseTypeBySlug(t, typeSlug)
  const disciplines = useSelector(selectors.app.disciplines)
  const history = useHistory()
  const dispatch = useDispatch()
  const student =
    user?.user_type === UserType.Student
      ? user?.student
      : user?.guardian?.children?.find((c) => c.id === child) ?? user?.guardian?.children?.[0]

  const backButton = useBackButton(router(routesPath.search, child ? { child } : undefined))

  const handleSubmit = (discipline: Discipline) => {
    dispatch(
      actions.search.saveStep({
        step: SearchSteps.STEP_2_THEME,
        discipline,
        type,
        student,
      })
    )
    history.push(
      router(routesPath.searchTheme, {
        type: typeSlug,
        discipline: discipline?.slug,
        ...(child && { child }),
      })
    )
  }

  const searchTypeProps: SearchDisciplineTemplateProps = {
    headerProps: headerProps,
    menuProps: menuProps,
    title: t('search_title'),
    typeTitle: courseTypeTranslation(t, type),
    typeText: t('searchDiscipline_text'),
    searchSubjectProps: {
      ...(user?.user_type === UserType.Guardian && {
        requestForLabel: t('search_for_label'),
        requestFor: [user?.guardian?.children?.find((c) => (child ? child === c?.id : true))]?.map(
          (c): ImageTextItemProps => ({
            image: {
              alt: c?.first_name ?? '',
              src: c?.avatar ?? '',
            },
            isAvatar: true,
            text1: c?.first_name ?? '',
            text2: c?.grade?.name ?? '',
            value: c?.id,
            potIcon: {
              icon: Icons.pot,
              color: light.colors.kournikova,
            },
            potValue: t('wallet_amount_value', { amount: (user?.wallet?.amount ?? 0) / 100 }),
          })
        )?.[0],
      }),
      requestSubjectLabel: t('searchDiscipline_discipline_label'),
      requestSubject: disciplines?.map((discipline) => ({
        title: discipline.name,
        link: {
          onClick: () => handleSubmit(discipline),
        },
      })),
    },
    backButton,
  }
  return <SearchDisciplineTemplate {...searchTypeProps} />
}

SearchDisciplinePage.restrictedUserTypes = [UserType.Guardian, UserType.Student]

export default SearchDisciplinePage
