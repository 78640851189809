import React, { FC } from 'react'

import { ActionButtonProps } from '../ActionButton'
import { ImgProps } from '../../types/image'

import * as SC from './styled'

export type SimpleTextWithCtaProps = {
  className?: string
  title?: string
  text?: string
  image?: ImgProps
  cta?: ActionButtonProps
  ctaOutlined?: boolean
}

const SimpleTextWithCta: FC<SimpleTextWithCtaProps> = (props) => {
  const { className, title, text, image, cta, ctaOutlined } = props

  return (
    <SC.SimpleTextWithCta className={className}>
      <SC.Wrapper disableGutters>
        {title && <SC.Title>{title}</SC.Title>}
        {text && <SC.Text>{text}</SC.Text>}
        {image && (
          <SC.BoxImage>
            <SC.Image src={image.src} alt={image.alt} />
          </SC.BoxImage>
        )}
        {cta && <SC.Cta {...cta} outlined={ctaOutlined}></SC.Cta>}
      </SC.Wrapper>
    </SC.SimpleTextWithCta>
  )
}

export default SimpleTextWithCta
