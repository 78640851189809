import { Container } from '@material-ui/core'
import styled from 'styled-components'

import ToggleButtonGroup from '../form/ToggleButtonGroup'
import FormSubmit from '../FormSubmit'

export const MaxStudentsForm = styled.div``

export const Wrapper = styled(Container)``

export const QuantityField = styled(ToggleButtonGroup)`
  margin-bottom: 1.6rem;
  & div {
    width: 100%;
    flex-wrap: nowrap;
  }
  & button {
    width: 100%;
    max-width: 100%;
    min-width: 0;
    margin-bottom: 0;
    &:last-child {
      margin-right: 0;
    }
  }
`

export const SubmitButton = styled(FormSubmit)``
