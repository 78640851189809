import styled from 'styled-components'

import BasicProfil from '../../components/BasicProfil'

export const Content = styled.div`
  width: 100%;
  padding: 1.6rem;
`

export const TeacherLabel = styled.h4`
  ${(props) => ({ ...props.theme.typography.h4 })}
  color: ${(props) => props.theme.palette.colors.midnightBlue};
  margin: 0 0 1.6rem 0;
`

export const TeacherProfile = styled(BasicProfil)`
  padding: 1.6rem;
  border: 1px solid ${(props) => props.theme.palette.colors.gainsboro};
  border-radius: 0.8rem;
  max-width: 47rem;
`
