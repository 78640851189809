import React, { FC } from 'react'

import { Icons } from '../Icon/types'
import { LinkProps } from '../Link'

import * as SC from './styled'

export type NotificationCardProps = {
  className?: string
  date: string
  title: string
  subtitle?: string
  name?: string
  icon: Icons
  iconColor?: string
  legends?: string[]
  link?: LinkProps
  isRead?: boolean
  isImportant?: boolean
}

const NotificationCard: FC<NotificationCardProps> = (props) => {
  const {
    className,
    date,
    title,
    subtitle,
    name,
    icon,
    iconColor,
    legends,
    link,
    isRead,
    isImportant,
  } = props

  return (
    <SC.CourseCard
      className={className}
      {...link}
      $isImportant={isImportant}
      $isRead={isRead}
      $iconColor={iconColor}
    >
      <SC.Wrapper>
        <SC.Date>{date}</SC.Date>
        <SC.Columns>
          <SC.Icon icon={icon} color={iconColor} />
          <SC.Content>
            <SC.Title>{title}</SC.Title>
            {subtitle && <SC.Subtitle>{subtitle}</SC.Subtitle>}
            {name && <SC.Name>{name}</SC.Name>}
            {legends && (
              <SC.Legends>
                {legends?.map((legend, key) => (
                  <SC.Legend key={key}>{legend}</SC.Legend>
                ))}
              </SC.Legends>
            )}
          </SC.Content>
        </SC.Columns>
      </SC.Wrapper>
    </SC.CourseCard>
  )
}

export default NotificationCard
