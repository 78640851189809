import React, { FC } from 'react'
import { Form, Formik, FormikHelpers } from 'formik'
import * as Yup from 'yup'

import { FormSubmitProps } from '../FormSubmit'
import FormSubmitErrors, { FormSubmitErrorsProps } from '../FormSubmitErrors'
import type { FormFieldConfig, FormFieldProps } from '../../types/form'
import { getValidationSchema, renderField } from '../../helpers/FormHelpers'
import { UserType } from '../../generated/graphql'
import FormScrollToError from '../FormScrollToError'
import Checkbox from '../form/Checkbox'

import * as SC from './styled'

export type SignUpSocialFormValues = {
  type: string
  firstName: string
  lastName: string
  email: string
  cgu: boolean
  sponsor?: string
}

export type SignUpSocialFormProps = {
  className?: string
  title: string
  text?: string
  sponsorText: string
  submitButton: FormSubmitProps
  mandatoryText: string
  initialValues: SignUpSocialFormValues
  fieldsProps?: {
    type: FormFieldProps
    firstName: FormFieldProps
    lastName: FormFieldProps
    email: FormFieldProps
    sponsor: FormFieldProps
    cgu: FormFieldProps
  }
  errorTexts?: {
    required: string
    email: string
    profile: string
    cgu: string
  }
  submitErrors?: FormSubmitErrorsProps['errors']
  onSubmit?: (
    values: SignUpSocialFormValues,
    formikHelpers: FormikHelpers<SignUpSocialFormValues>
  ) => void
  isTeacher?: boolean
  onTypeChange?: (type: UserType) => void
}

const SignUpSocialForm: FC<SignUpSocialFormProps> = (props) => {
  const {
    className,
    title,
    text,
    sponsorText,
    fieldsProps,
    submitButton,
    mandatoryText,
    initialValues,
    errorTexts,
    submitErrors,
    onSubmit = (_v: SignUpSocialFormValues) => null,
    isTeacher = false,
    onTypeChange = (_v: string) => null,
  } = props

  const typeField = {
    name: 'type',
    Component: SC.MuiToggleButtonGroup,
    validation: Yup.string().required(errorTexts?.required),
    required: true,
    isRadioMode: true,
  }

  const sponsorField = {
    name: 'sponsor',
    Component: SC.MuiTextField,
    validation: Yup.string(),
    required: false,
  }

  const fields: FormFieldConfig[] = [
    typeField,
    {
      name: 'firstName',
      Component: SC.MuiTextField,
      validation: Yup.string().required(errorTexts?.required),
      required: true,
    },
    {
      name: 'lastName',
      Component: SC.MuiTextField,
      validation: Yup.string().required(errorTexts?.required),
      required: true,
    },
    {
      name: 'email',
      Component: SC.MuiTextField,
      validation: Yup.string().email(errorTexts?.email).required(errorTexts?.required),
      required: true,
    },
    {
      name: 'cgu',
      Component: Checkbox,
      validation: Yup.bool().required().oneOf([true], errorTexts?.cgu),
      required: true,
    },
    sponsorField,
  ]

  const validationSchema = getValidationSchema(fields)

  return (
    <SC.SignUpSocialForm className={className}>
      <SC.Title>{title}</SC.Title>
      {text && <SC.Text>{text}</SC.Text>}
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnChange={true}
        validateOnBlur={true}
        onSubmit={onSubmit}
      >
        {(formikProps) => (
          <Form noValidate>
            <FormScrollToError formikProps={formikProps} />
            {renderField(typeField, formikProps, fieldsProps, 0, (Component, p) => (
              <Component
                {...p}
                onChange={(n: string, v: UserType) => {
                  onTypeChange?.(v)
                  p.onChange(n, v)
                }}
              />
            ))}
            <SC.Fields>
              {fields
                .filter((f) => f.name !== 'type' && f.name !== 'sponsor')
                .map((field, index) => renderField(field, formikProps, fieldsProps, index))}

              {isTeacher && sponsorField && (
                <>
                  <SC.SponsorText>{sponsorText}</SC.SponsorText>
                  {renderField(sponsorField, formikProps, fieldsProps)}
                </>
              )}
            </SC.Fields>
            <FormSubmitErrors errors={submitErrors} />
            <SC.SubmitButton {...submitButton} autoIcon />
            <SC.MandatoryText>{mandatoryText}</SC.MandatoryText>
          </Form>
        )}
      </Formik>
    </SC.SignUpSocialForm>
  )
}

export default SignUpSocialForm
