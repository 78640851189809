import styled from 'styled-components'

import ActionButton from '../ActionButton'
import SignUpAddChildFieldArray from '../SignUpAddChildFieldArray'
import FormSubmit from '../FormSubmit'
import AddressC from '../form/Address'
import TextField from '../form/TextField'
import Checkbox from '../form/Checkbox'

export const AddChildren = styled.div`
  padding: 0 1.6rem;
`

export const Step = styled.p`
  ${(props) => ({ ...props.theme.typography.h6 })}
  color: ${(props) => props.theme.palette.colors.neonCarrot};
  text-transform: uppercase;
`

export const Title = styled.h1`
  ${(props) => ({ ...props.theme.typography.h1 })}
  color: ${(props) => props.theme.palette.colors.midnightBlue};
  font-size: 3.2rem;
  margin: 0 0 2rem 0;
  ${(props) => props.theme.breakpoints.up('md')} {
    font-size: 4rem;
  }
`

export const AddForms = styled.div``

export const ChildAddForm = styled(SignUpAddChildFieldArray)`
  margin-top: 2rem;
`

export const AddAnotherChildButton = styled(ActionButton)`
  margin: 2rem 0 0 0;
`

export const LinkCodeBox = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.palette.colors.pureWhite};
  border-radius: 0.8rem;
  padding: 1.6rem;
  margin-top: 2rem;
`

export const LinkCodeText = styled.span`
  ${(props) => ({ ...props.theme.typography.smallRegular })}
  color: ${(props) => props.theme.palette.colors.midnightBlue};
`

export const LinkCode = styled.span`
  ${(props) => ({ ...props.theme.typography.h3 })}
  color: ${(props) => props.theme.palette.colors.cornflowerBlue};
  margin-top: 1rem;
`

export const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const SkipButton = styled(ActionButton)`
  flex: 1;
  margin: 2rem 1rem 0 0;
`

export const SubmitButton = styled(FormSubmit)`
  flex: 2;
  margin: 2rem 0 0 1rem;
`

export const Address = styled(AddressC)``

export const Field = styled(TextField)<{ hasErrors?: boolean }>`
  margin-bottom: 1rem;
`

export const Check = styled(Checkbox)<{ hasErrors?: boolean }>`
  margin-bottom: 1rem;
`
