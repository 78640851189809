import { Icons } from '../components/Icon/types'
import { RatingModalityProps } from '../components/RatingModality'
import { RateProps } from '../components/RatingModalities'
import { RatingWithFieldsProps } from '../components/RatingWithFields'
import { Maybe, StudentReview, UserType } from '../generated/graphql'

import { publicStudentProfileTransformer } from './profileTransformers'

export type RatingValues = {
  [key: string]: number
}

export const roundValue = (value: number) => Math.round(value * 10) / 10

export const ratingModalityTransformer = (
  t: any,
  values: RatingValues,
  editable?: boolean
): RatingModalityProps[] => {
  const types = Object.keys(values)

  return types?.map((type) => ({
    rate: {
      value: roundValue((values as any)[type] as number) ?? 0,
      max: 5,
      isNotEditable: !editable,
      name: type,
    },
    title: t(`rating_title_${type}`),
    description: t(`rating_description_${type}`),
    icon: Icons.info,
  }))
}

export const globalRateTransformer = (t: any, score: number): RateProps => {
  const median = score
  return {
    title: t('rating_global'),
    value: roundValue(median),
    valueOutOf: t('rating_outof', { value: median, max: 5 }),
    max: 5,
  }
}

export const ratingWithFieldsTransformer = (
  review: StudentReview,
  t: any,
  userType?: UserType | Maybe<UserType>,
  expanded?: string[],
  setExpanded?: (before: (b: string[]) => string[]) => void
): RatingWithFieldsProps => {
  const studentId = review?.student?.id ?? ''
  const rates = {
    punctuality: review?.punctuality,
    behaviour: review?.behaviour,
    interactivity: review?.interactivity,
    understanding: review?.understanding,
    working_conditions: review?.working_conditions,
  }

  return {
    rateModalities: {
      id: studentId,
      profile:
        userType !== UserType.Student
          ? publicStudentProfileTransformer(review?.student)
          : undefined,
      modality: ratingModalityTransformer(t, rates),
      rate: globalRateTransformer(t, review?.score ?? 0),
      isExpanded: expanded ? expanded?.includes(studentId) : true,
      expand: setExpanded
        ? {
            title: t('rating_details_label'),
            icon: Icons.down,
            onClick: () =>
              setExpanded((before) => {
                const arr = Array.from(before)
                const idx = arr.indexOf(studentId)
                if (idx > -1) {
                  arr.splice(idx, 1)
                } else {
                  arr.push(studentId)
                }
                return arr
              }),
          }
        : undefined,
      commentaryTitle:
        userType === UserType.Teacher ? t('rating_my_comment_label') : t('rating_comment_label'),
      commentary: review?.comment ?? '',
    },
  }
}
