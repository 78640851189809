import styled from 'styled-components'

import PageTitle from '../../components/PageTitle'
import PageBackButton from '../../components/PageBackButton'
import RatingModalities from '../../components/RatingModalities'
import ImageTextListC from '../../components/ImageTextList'
import ActionButton from '../../components/ActionButton'
import CourseCardInContextC from '../../components/CourseCardInContext'

export const Content = styled.div`
  flex-grow: 1;
  ${(props) => props.theme.breakpoints.up('md')} {
    flex-grow: initial;
  }
`

export const Title = styled(PageTitle)``
export const BackButton = styled(PageBackButton)``
export const StudentCard = styled(RatingModalities)`
  background: ${(props) => props.theme.palette.colors.pureWhite};
  border-radius: 8px;
  padding: 1.6rem;
  margin: 1.6rem;
  max-width: 40rem;
`
export const ImageTextList = styled(ImageTextListC)`
  padding: 1.6rem;
`
export const Button = styled(ActionButton)`
  max-width: 47rem;
  width: calc(100% - 3.2rem);
  margin: 1.6rem;
`

export const CourseCardInContext = styled(CourseCardInContextC)`
  margin-top: 2rem;
`
