import React, { FC } from 'react'

import Header, { HeaderProps } from '../../components/Header'
import Menu, { MenuProps } from '../../components/Menu'
import AuthLayout from '../../layouts/AuthLayout'
import { PageBackButtonProps } from '../../components/PageBackButton'
import { CardListProps } from '../../components/CardList'
import Filters, { FiltersProps } from '../../components/Filters'
import { UsersSliderProps } from '../../components/UsersSlider'

import * as SC from './styled'

export type PreferencesCourseMaterialsTemplateProps = {
  className?: string
  headerProps: HeaderProps
  menuProps: MenuProps
  backButton: PageBackButtonProps
  title: string
  lists: (CardListProps & { title?: string })[]
  filtersProps?: FiltersProps
  usersSliderProps?: UsersSliderProps
}

const PreferencesCourseMaterialsTemplate: FC<PreferencesCourseMaterialsTemplateProps> = (props) => {
  const { headerProps, menuProps, backButton, title, lists, filtersProps, usersSliderProps } = props

  return (
    <AuthLayout header={<Header {...headerProps} withDivider />} menu={<Menu {...menuProps} />}>
      <SC.Content>
        <SC.BackButton {...backButton} />
        <SC.Title>{title}</SC.Title>
        {usersSliderProps && <SC.Student {...usersSliderProps} />}
        {filtersProps && <Filters {...filtersProps} />}
        {lists?.map((list, index) => (
          <React.Fragment key={index}>
            {list.title && <SC.Subtitle>{list.title}</SC.Subtitle>}
            <SC.MyCourseCardList {...list} />
          </React.Fragment>
        ))}
      </SC.Content>
    </AuthLayout>
  )
}

export default PreferencesCourseMaterialsTemplate
