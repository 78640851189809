import React, { FC } from 'react'

import Header, { HeaderProps } from '../../components/Header'
import Menu, { MenuProps } from '../../components/Menu'
import AuthLayout from '../../layouts/AuthLayout'
import { PageBackButtonProps } from '../../components/PageBackButton'

import * as SC from './styled'

export type PreloadTemplateProps = {
  className?: string
  headerProps: HeaderProps
  menuProps: MenuProps
  title?: string
  text?: string
  backButton?: PageBackButtonProps
}

const PreloadTemplate: FC<PreloadTemplateProps> = (props) => {
  const { headerProps, menuProps, title, text, backButton } = props

  return (
    <AuthLayout header={<Header {...headerProps} withDivider />} menu={<Menu {...menuProps} />}>
      <SC.Content>
        {backButton && <SC.BackButton {...backButton} />}
        {title && <SC.Title>{title}</SC.Title>}
        {text && <SC.Text>{text}</SC.Text>}
      </SC.Content>
    </AuthLayout>
  )
}

export default PreloadTemplate
