import React, { FC } from 'react'

import { ImgProps } from '../../types/image'
import RatingModality, { RatingModalityProps } from '../RatingModality'
import { Icons } from '../Icon/types'

import * as SC from './styled'

export type ProfileProps = {
  profilePicture: ImgProps
  firstName: string
  level: string
}

export type RateProps = {
  title?: string
  value: number
  valueOutOf: string
  max?: number
}

type ExpandProps = {
  title: string
  icon: Icons
  onClick: () => void
}

export type RatingModalitiesProps = {
  className?: string
  id: string
  profile?: ProfileProps
  rate?: RateProps
  modality?: RatingModalityProps[]
  hasMinimalStyles?: boolean
  isExpanded?: boolean
  expand?: ExpandProps
  commentaryTitle?: string
  commentary?: string
}

const RatingModalities: FC<RatingModalitiesProps> = (props) => {
  const {
    className,
    id,
    profile,
    rate,
    modality,
    hasMinimalStyles = false,
    isExpanded,
    expand,
    commentary,
    commentaryTitle,
  } = props

  return (
    <SC.RatingModalities className={className}>
      <SC.Wrapper disableGutters maxWidth={false}>
        {profile && (
          <SC.Profile $hasMinimalStyles={hasMinimalStyles}>
            <SC.Image src={profile.profilePicture.src} alt={profile.profilePicture.alt} />
            <SC.ProfileInfo>
              <SC.Name>{profile.firstName}</SC.Name>
              <SC.Level>{profile.level}</SC.Level>
            </SC.ProfileInfo>
          </SC.Profile>
        )}
        {rate && (
          <>
            {profile && <SC.Muidivider />}
            <SC.GlobalRate>
              {rate.title && <SC.Title>{rate.title}</SC.Title>}
              <SC.Rate>
                <SC.Rating {...rate} isNotEditable />
                <SC.Value>{rate.valueOutOf}</SC.Value>
              </SC.Rate>
            </SC.GlobalRate>
          </>
        )}
        {isExpanded && (
          <>
            {(profile || rate) && <SC.Muidivider />}
            <SC.Modality>
              {modality?.map((item, index) => (
                <SC.ModalityItem key={`${id}-${index}`}>
                  <RatingModality {...item} />
                  <SC.Muidivider
                    $hasMinimalStyles={hasMinimalStyles && index < modality?.length - 1}
                  />
                </SC.ModalityItem>
              ))}
            </SC.Modality>
            {commentary && (
              <SC.CommentaryBox>
                {commentaryTitle && <SC.CommentaryTitle>{commentaryTitle}</SC.CommentaryTitle>}
                <SC.CommentaryText>{commentary}</SC.CommentaryText>
              </SC.CommentaryBox>
            )}
          </>
        )}
        {expand && (
          <>
            {!isExpanded && <SC.Muidivider />}
            <SC.ExpandBox onClick={expand.onClick}>
              <SC.ExpandText>{expand.title}</SC.ExpandText>
              <SC.Icon icon={expand.icon} isExpanded={isExpanded} />
            </SC.ExpandBox>
          </>
        )}
      </SC.Wrapper>
    </SC.RatingModalities>
  )
}

export default RatingModalities
