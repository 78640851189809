import React, { useEffect, useMemo, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { HeaderProps } from '../../components/Header'
import useHeader from '../../hooks/useHeader'
import { MenuProps } from '../../components/Menu'
import useMenu from '../../hooks/useMenu'
import { Student, UserType } from '../../generated/graphql'
import { RestrictedReactFC } from '../../types/common'
import { router, routesPath } from '../../router'
import { actions, selectors } from '../../redux'
import DashboardChildTemplate, { DashboardChildTemplateProps } from '../../templates/DashboardChild'
import { studentProfileTransformer } from '../../transformers/profileTransformers'
import { Icons } from '../../components/Icon/types'
import {
  globalRateTransformer,
  ratingModalityTransformer,
} from '../../transformers/ratingTransformers'
import { DashboardCoursesResult } from '../../graphql/services/course/queries/dashboardCourses'
import { CourseCardInContextProps } from '../../components/CourseCardInContext'
import { dashboardCourseCards } from '../../transformers/courseTransformers'
import useBackButton from '../../hooks/useBackButton'

const DashboardChildPage: RestrictedReactFC<RouteComponentProps<{ child?: string }>> = (props) => {
  const headerProps: HeaderProps = useHeader()
  const menuProps: MenuProps = useMenu(props)
  const { t } = useTranslation()
  const user = useSelector(selectors.auth.user)
  const backButton = useBackButton()

  const child = props.match?.params?.child ?? ''
  const student: Student | undefined = useMemo(
    () => user?.guardian?.children?.find((c) => child === c?.id),
    [user, child]
  )

  const dispatch = useDispatch()
  const dashboardCourses = useSelector(selectors.planning.dashboardCourses)
  const dashboard: DashboardCoursesResult | null = dashboardCourses?.data

  const [isExpanded, setIsExpanded] = useState(false)

  const courseCards: CourseCardInContextProps[] = useMemo(
    () => dashboardCourseCards(dashboard, t, user, child),
    [dashboard, t, user, child]
  )

  const dashboardProps: DashboardChildTemplateProps = {
    menuProps,
    headerProps,
    title: student?.first_name ?? '',
    backButton,
    studentCardProps: {
      id: student?.id ?? '',
      profile: studentProfileTransformer(student),
      modality: ratingModalityTransformer(t, {
        punctuality: student?.punctuality ?? 0,
        behaviour: student?.behaviour ?? 0,
        interactivity: student?.interactivity ?? 0,
        understanding: student?.understanding ?? 0,
        working_conditions: student?.working_conditions ?? 0,
      }),
      rate: globalRateTransformer(t, student?.score ?? 0),
      isExpanded,
      expand: {
        icon: Icons.down,
        title: t('rating_details_label'),
        onClick: () => setIsExpanded((before) => !before),
      },
    },
    courseCards,
    list: {
      items: [
        {
          image: {
            alt: '',
            src: '/static/assets/images/icons/course.png',
          },
          text1: t('dashboardChild_search'),
          link: {
            href: router(routesPath.search, { child: child }),
          },
        },
        {
          image: {
            alt: '',
            src: '/static/assets/images/icons/folders.png',
          },
          text1: t('dashboardChild_files'),
          link: {
            href: router(routesPath.preferencesCourseMaterials, { child: child }),
          },
        },
      ],
    },
    /*searchButton: {
      text: 'Rechercher un cours',
      link: {
        href: router(routesPath.search, { child: child }),
      },
    },*/
  }

  useEffect(
    () => () => {
      dispatch(actions.planning.dashboardCoursesReset(null))
    },
    [dispatch]
  )

  useEffect(() => {
    dispatch(actions.planning.dashboardCoursesRequest({ student: child }))
  }, [dispatch, child])

  return <DashboardChildTemplate {...dashboardProps} />
}

DashboardChildPage.restrictedUserTypes = [UserType.Guardian]

export default DashboardChildPage
