import React, { FC } from 'react'

import Header, { HeaderProps } from '../../components/Header'
import Menu, { MenuProps } from '../../components/Menu'
import AuthLayout from '../../layouts/AuthLayout'
import { CourseDetailsProps } from '../../components/CourseDetails'
import { PageBackButtonProps } from '../../components/PageBackButton'

import * as SC from './styled'

export type CourseRequestTemplateProps = {
  className?: string
  headerProps: HeaderProps
  menuProps: MenuProps
  title: string
  price: string
  participants?: string
  delay?: string
  dates?: {
    date: string
    slots: string[]
  }[]
  courseDetails?: CourseDetailsProps
  backButton: PageBackButtonProps
}

const CourseRequestTemplate: FC<CourseRequestTemplateProps> = (props) => {
  const {
    headerProps,
    menuProps,
    title,
    price,
    dates,
    courseDetails,
    backButton,
    participants,
    delay,
  } = props

  return (
    <AuthLayout header={<Header {...headerProps} withDivider />} menu={<Menu {...menuProps} />}>
      <SC.Content>
        <SC.BackButton {...backButton} />
        <SC.Title>{title}</SC.Title>
        <SC.Price>{price}</SC.Price>
        {participants && <SC.Price>{participants}</SC.Price>}
        {delay && <SC.Price>{delay}</SC.Price>}
        {dates &&
          dates?.map((date, key) => (
            <SC.DateGroup key={key}>
              <SC.Date>{date.date}</SC.Date>
              <SC.Slots>
                {date.slots?.map((slot, slotIndex) => (
                  <SC.Slot key={slotIndex}>{slot}</SC.Slot>
                ))}
              </SC.Slots>
            </SC.DateGroup>
          ))}
        <SC.Hr />
        {courseDetails && <SC.CourseDetails {...courseDetails} />}
      </SC.Content>
    </AuthLayout>
  )
}

export default CourseRequestTemplate
