import gql from 'graphql-tag'

import CourseFragment from '../../../fragments/CourseFragment'

const cancelPendingCourse = {
  mutation: gql`
    mutation cancelPendingCourse($course: ID!) {
      cancelPendingCourse(course: $course) {
        ...CourseFragment
      }
    }
    ${CourseFragment}
  `,
  transformer: (response: any): any => {
    return response.cancelPendingCourse
  },
}

export default cancelPendingCourse
