import gql from 'graphql-tag'

import UserFragment from '../../../fragments/UserFragment'
import type { User } from '../../../../generated/graphql'

const registerPersonalInformation = {
  mutation: gql`
    mutation registerPersonalInformation(
      $phone: String
      $display_address: String
      $address: String
      $city: String
      $zip_code: String
      $school: String
      $grade: ID
      $avatar: String
    ) {
      registerPersonalInformation(
        phone: $phone
        display_address: $display_address
        address: $address
        city: $city
        zip_code: $zip_code
        school: $school
        grade: $grade
        avatar: $avatar
      ) {
        ...UserFragment
      }
    }
    ${UserFragment}
  `,
  transformer: (response: any): User => {
    return response.registerPersonalInformation
  },
}

export default registerPersonalInformation
