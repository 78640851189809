import { Container } from '@material-ui/core'
import styled from 'styled-components'

import ActionButton from '../ActionButton'

export const SimpleTextWithCta = styled.div``

export const Wrapper = styled(Container)`
  ${(props) => props.theme.breakpoints.up('md')} {
    display: flex;
    flex-direction: column;
  }
`

export const Title = styled.h3`
  ${(props) => ({ ...props.theme.typography.h4 })}
  color: ${(props) => props.theme.palette.colors.midnightBlue};
  margin: 0rem 0 0.5rem 0;
  text-transform: capitalize;
`

export const Text = styled.p`
  ${(props) => ({ ...props.theme.typography.smallRegular })}
  color: ${(props) => props.theme.palette.colors.chambray};
  margin: 0rem 0 1rem 0;
`

export const BoxImage = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 2rem;
`

export const Image = styled.img`
  width: 18rem;
  height: auto;
  ${(props) => props.theme.breakpoints.up('md')} {
    width: 30rem;
  }
`

export const Cta = styled(ActionButton)`
  margin: 1rem 0;
  width: 100%;
  display: block;
`
