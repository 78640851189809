import React, { FC } from 'react'

import Header, { HeaderProps } from '../../components/Header'
import Menu, { MenuProps } from '../../components/Menu'
import AuthLayout from '../../layouts/AuthLayout'
import { PageBackButtonProps } from '../../components/PageBackButton'
import { LevelPickersFormProps } from '../../components/LevelPickersForm'

import * as SC from './styled'

export type PreferencesDisciplinesTemplateProps = {
  className?: string
  headerProps: HeaderProps
  menuProps: MenuProps
  title: string
  backButton: PageBackButtonProps
  levelPickersProps: LevelPickersFormProps
}

const PreferencesDisciplinesTemplate: FC<PreferencesDisciplinesTemplateProps> = (props) => {
  const { headerProps, menuProps, title, backButton, levelPickersProps } = props

  return (
    <AuthLayout header={<Header {...headerProps} withDivider />} menu={<Menu {...menuProps} />}>
      <SC.Content>
        <SC.BackButton {...backButton} />
        <SC.Title>{title}</SC.Title>
        <SC.LevelPickers {...levelPickersProps} />
      </SC.Content>
    </AuthLayout>
  )
}

export default PreferencesDisciplinesTemplate
