import React, { useCallback, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { HeaderProps } from '../../components/Header'
import useHeader from '../../hooks/useHeader'
import { MenuProps } from '../../components/Menu'
import useMenu from '../../hooks/useMenu'
import { UserType } from '../../generated/graphql'
import { RestrictedReactFC } from '../../types/common'
import { router, routesPath } from '../../router'
import PreferencesWalletDepositTemplate, {
  PreferencesWalletDepositTemplateProps,
} from '../../templates/PreferencesWalletDeposit'
import { PotDepositFormProps } from '../../components/PotDepositForm'
import { StripePaymentFormProps } from '../../components/StripePaymentForm'
import { actions, selectors } from '../../redux'
import { useFormSubmit } from '../../hooks/useFormSubmit'
import { app, stripe } from '../../configuration'
import useBackButton from '../../hooks/useBackButton'

const PreferencesWalletDepositPage: RestrictedReactFC<RouteComponentProps> = (props) => {
  const headerProps: HeaderProps = useHeader()
  const menuProps: MenuProps = useMenu(props)
  const { t } = useTranslation()
  const [submitted, setSubmitted] = useState(false)
  const user = useSelector(selectors.auth.user)
  const [amount, setAmount] = useState<string>('')
  const backButton = useBackButton(router(routesPath.preferencesWallet))

  const onComplete = useCallback(() => {
    console.log('complete')
    setSubmitted(true)
  }, [])

  const [submitAmount, handleSubmitAmount] = useFormSubmit(
    selectors.preferences.createCreditPaymentIntent,
    actions.preferences.createCreditPaymentIntentRequest,
    actions.preferences.createCreditPaymentIntentReset,
    onComplete
  )

  const potDepositFormProps: PotDepositFormProps = {
    text: t('preferences_walletDeposit_text'),
    fieldsProps: {
      amount: {
        label: t('preferences_walletDeposit_form_amount_label'),
        placeholder: t('preferences_walletDeposit_form_amount_placeholder'),
        isLabelExternal: true,
      },
    },
    onSubmit: (values) => {
      console.log('values', values)
      setAmount(values?.amount)
      handleSubmitAmount(values?.amount)
    },
    initialValues: {
      amount: '',
    },
    submitButton: {
      text: t('submit_label'),
      isPending: submitAmount.pending,
    },
    submitErrors: submitAmount.errors,
  }

  const stripePaymentFormProps: StripePaymentFormProps = {
    text: t('preferences_walletDeposit_text'),
    fieldsProps: {
      amount: {
        label: t('preferences_walletDeposit_form_amount_label'),
        isLabelExternal: true,
      },
      firstName: {
        isLabelExternal: true,
        label: t('preferences_walletDeposit_form_firstName_label'),
        placeholder: t('preferences_walletDeposit_form_firstName_placeholder'),
      },
      lastName: {
        placeholder: t('preferences_walletDeposit_form_lastName_label'),
      },
    },
    stripeKey: stripe.STRIPE_KEY,
    stripeOptions: {
      clientSecret: submitAmount?.data?.client_secret,
    },
    stripeConfirmParams: {
      receipt_email: user?.email ?? '',
      return_url: app.APP_URL + router(routesPath.preferencesWallet),
    },
    initialValues: {
      amount,
      firstName: user?.first_name ?? '',
      lastName: user?.last_name ?? '',
    },
    submitButton: {
      text: t('preferences_walletDeposit_submit'),
    },
    cancelButton: {
      text: t('cancel'),
      onClick: () => setSubmitted(false),
    },
  }

  const preferencesProps: PreferencesWalletDepositTemplateProps = {
    menuProps,
    headerProps,
    title: t('preferences_walletDeposit_title'),
    potDepositFormProps,
    stripePaymentFormProps,
    submitted,
    backButton,
  }

  return <PreferencesWalletDepositTemplate {...preferencesProps} />
}

PreferencesWalletDepositPage.restrictedUserTypes = [UserType.Guardian]

export default PreferencesWalletDepositPage
