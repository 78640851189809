import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { router, routesPath } from '../router'
import { app } from '../configuration'
import { actions, selectors } from '../redux'
import { Link, Maybe } from '../generated/graphql-contentful'
import { BasicLinkProps } from '../components/BasicLink'
import { ActionButtonProps } from '../components/ActionButton'
import { UserStatus } from '../generated/graphql'
import { NotificationCardProps } from '../components/NotificationCard'
import { notificationToCardProps } from '../transformers/notificationsTransformers'
import { HeaderProps } from '../components/Header'

import useImpersonate from './useImpersonate'

const useHeader = (_pageProps?: any): HeaderProps => {
  const configuration = useSelector(selectors.app.contentful)
  const user = useSelector(selectors.auth.user)
  const isConnected = useSelector(selectors.auth.isConnected)
  const impersonateProps = useImpersonate()
  const [notificationsOpen, setNotificationsOpen] = useState(false)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [notificationsPage] = useState(1)
  const notifications = useSelector(selectors.auth.notifications)

  useEffect(() => {
    if (notificationsOpen) {
      dispatch(actions.auth.notificationsRequest({ first: 100, page: notificationsPage }))
    }
  }, [dispatch, notificationsOpen, notificationsPage])

  return useMemo(() => {
    const getUrl = (url?: string | null): string =>
      url?.indexOf('http') === 0 ? url : app.PUBLIC_URL + url
    const linkTransformer = (link: Maybe<Link>): BasicLinkProps => {
      return {
        text: link?.label ?? '',
        link: {
          link: getUrl(link?.url),
        },
        active: false,
        special: link?.special ?? false,
      }
    }

    const linkCTATransformer = (link: Maybe<Link>): ActionButtonProps => {
      return {
        text: link?.label ?? '',
        link: {
          link: getUrl(link?.url),
        },
      }
    }

    const notificationCards:
      | NotificationCardProps[]
      | undefined = notifications?.data?.data
      ?.map((notification) => notificationToCardProps(notification, t, user))
      ?.filter((n) => !!n?.date) // remove not supported notifications

    return {
      logo: {
        alt: 'LiberteClasse',
        src: '/static/assets/images/liberteclass-logo-v2.png',
      },
      menu: configuration?.mainLinksCollection?.items?.map(linkTransformer),
      menuMobile: {
        signup: configuration?.mobileRegisterLinksCollection?.items?.map(linkCTATransformer),
        others: configuration?.mobileExtraLinksCollection?.items?.map(linkTransformer),
      },
      profileButtonProps: {
        href: router(routesPath.account),
      },
      logoButtonProps: {
        href: isConnected ? router(routesPath.dashboard) : app.PUBLIC_URL,
      },
      backgroundColor: '#F8F8F8',
      isConnected,
      notificationCount: user?.unread_notifications ?? 0,
      hideNotifications: user?.status !== UserStatus.Active,
      profilePicture: user?.avatar ? { src: user?.avatar, alt: 'profile' } : undefined,
      ...(impersonateProps && {
        impersonateProps,
      }),
      onNotificationClick: () => setNotificationsOpen(true),
      notificationsModalProps: {
        title: t('notifications_title'),
        cards: notificationCards ?? [],
        open: notificationsOpen,
        onClose: () => setNotificationsOpen(false),
      },
    }
  }, [
    notifications?.data?.data,
    configuration?.mainLinksCollection?.items,
    configuration?.mobileRegisterLinksCollection?.items,
    configuration?.mobileExtraLinksCollection?.items,
    isConnected,
    user,
    impersonateProps,
    notificationsOpen,
    t,
  ])
}

export default useHeader
