import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { HeaderProps } from '../../components/Header'
import useHeader from '../../hooks/useHeader'
import { router, routesPath } from '../../router'
import { MenuProps } from '../../components/Menu'
import useMenu from '../../hooks/useMenu'
import PreferencesTemplate, { PreferencesTemplateProps } from '../../templates/Preferences'
import { selectors } from '../../redux'
import { UserType } from '../../generated/graphql'
import useBackButton from '../../hooks/useBackButton'

const PreferencesPage: React.FC<RouteComponentProps> = (props) => {
  const headerProps: HeaderProps = useHeader()
  const menuProps: MenuProps = useMenu(props)
  const user = useSelector(selectors.auth.user)
  const { t } = useTranslation()
  const backButton = useBackButton()

  const preferencesProps: PreferencesTemplateProps = {
    menuProps,
    headerProps,
    backButton,
    title: t('preferences_list_title'),
    list: {
      items:
        user?.user_type === UserType.Teacher
          ? [
              {
                image: {
                  alt: '',
                  src: '/static/assets/images/icons/watch.png',
                },
                text1: t('preferences_list_availabilities_label'),
                link: {
                  href: router(routesPath.preferencesAvailabilities),
                },
              },
              {
                image: {
                  alt: '',
                  src: '/static/assets/images/icons/refused-students.png',
                },
                text1: t('preferences_list_students_label'),
                link: {
                  href: router(routesPath.preferencesStudents),
                },
              },
              {
                image: {
                  alt: '',
                  src: '/static/assets/images/icons/folders.png',
                },
                text1: t('preferences_list_disciplines_label'),
                link: {
                  href: router(routesPath.preferencesDisciplines),
                },
              },
              {
                image: {
                  alt: '',
                  src: '/static/assets/images/icons/coin.png',
                },
                text1: t('preferences_list_pricing_label'),
                link: {
                  href: router(routesPath.preferencesPricing),
                },
              },
            ]
          : user?.user_type === UserType.Guardian
          ? [
              {
                image: {
                  alt: '',
                  src: '/static/assets/images/icons/coin.png',
                },
                text1: t('preferences_list_pot_label'),
                link: {
                  href: router(routesPath.preferencesWallet),
                },
              },
              {
                image: {
                  alt: '',
                  src: '/static/assets/images/icons/family.png',
                },
                text1: t('preferences_list_family_label'),
                link: {
                  href: router(routesPath.preferencesFamily),
                },
              },
              {
                image: {
                  alt: '',
                  src: '/static/assets/images/icons/folders.png',
                },
                text1: t('preferences_list_materials_label'),
                link: {
                  href: router(routesPath.preferencesCourseMaterials),
                },
              },
            ]
          : [
              {
                image: {
                  alt: '',
                  src: '/static/assets/images/icons/coin.png',
                },
                text1: t('preferences_list_pot_label'),
                link: {
                  href: router(routesPath.preferencesWallet),
                },
              },
              {
                image: {
                  alt: '',
                  src: '/static/assets/images/icons/refused-students.png',
                },
                text1: t('preferences_list_rating_label'),
                link: {
                  href: router(routesPath.preferencesRating),
                },
              },
              {
                image: {
                  alt: '',
                  src: '/static/assets/images/icons/folders.png',
                },
                text1: t('preferences_list_materials_label'),
                link: {
                  href: router(routesPath.preferencesCourseMaterials),
                },
              },
            ],
    },
  }

  return <PreferencesTemplate {...preferencesProps} />
}

export default PreferencesPage
