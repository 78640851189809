import React, { FC, useMemo } from 'react'
import { Form, Formik, FormikHelpers } from 'formik'
import * as Yup from 'yup'

import { FormFieldConfig, FormFieldsProps } from '../../types/form'
import { getValidationSchema, renderField } from '../../helpers/FormHelpers'
import FormSubmitErrors, { FormSubmitErrorsProps } from '../FormSubmitErrors'
import { FormSubmitProps } from '../FormSubmit'

import * as SC from './styled'

export type AddChildFormValues = {
  firstName: string
  lastName: string
  level: string
  school: string
  email?: string
  password?: string
  avatar?: string
  signupChild?: boolean
}

export type AddChildFormProps = {
  className?: string
  fieldsProps?: FormFieldsProps
  initialValues?: AddChildFormValues
  isEdit?: boolean
  forceSignupChild?: boolean
  linkCodeText: string
  linkCode: string
  submitButton: FormSubmitProps
  errorTexts?: { [key: string]: string }
  onSubmit?: (values: AddChildFormValues, formikHelpers: FormikHelpers<any>) => void
  submitErrors?: FormSubmitErrorsProps['errors']
}

const AddChildForm: FC<AddChildFormProps> = (props) => {
  const {
    className,
    fieldsProps,
    initialValues = {
      firstName: '',
      lastName: '',
      level: '',
      school: '',
      email: '',
      password: '',
      avatar: '',
      signupChild: false,
    },
    forceSignupChild,
    linkCodeText,
    linkCode,
    submitButton,
    errorTexts,
    onSubmit = (_values, _formikHelpers) => null,
    submitErrors,
    isEdit,
  } = props

  const avatarField = useMemo(
    () => ({
      name: 'avatar',
      Component: SC.Avatar,
      required: true,
    }),
    []
  )

  const fields: FormFieldConfig[] = useMemo(
    () => [
      {
        name: 'firstName',
        Component: SC.Field,
        validation: Yup.string().required(errorTexts?.required),
        required: true,
      },
      {
        name: 'lastName',
        Component: SC.Field,
        validation: Yup.string().required(errorTexts?.required),
        required: true,
      },
      {
        name: 'school',
        id: 'schoolName',
        Component: SC.Field,
        validation: Yup.string().required(errorTexts?.required),
        required: true,
      },
      {
        name: 'level',
        Component: SC.Field,
        validation: Yup.string().required(errorTexts?.required),
        required: true,
      },
      avatarField,
      ...(forceSignupChild
        ? []
        : [
            {
              name: 'signupChild',
              Component: SC.Check,
              defaultValue: false,
            },
          ]),
      {
        name: 'email',
        Component: SC.EmailField,
        validation: Yup.string().when('signupChild', {
          is: true,
          then: Yup.string().email(errorTexts?.email).required(errorTexts?.required),
        }),
        autoComplete: 'new-email',
        required: true,
        conditional: true,
      },
      {
        name: 'password',
        Component: SC.Field,
        validation: isEdit
          ? Yup.string().when('signupChild', {
              is: true,
              then: Yup.string().matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*)[^ ]{8,}$/,
                errorTexts?.password
              ),
            })
          : Yup.string().when('signupChild', {
              is: true,
              then: Yup.string()
                .required(errorTexts?.required)
                .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*)[^ ]{8,}$/, errorTexts?.password),
            }),
        autoComplete: 'new-password',
        type: 'password',
        conditional: true,
      },
    ],
    [
      avatarField,
      errorTexts?.email,
      errorTexts?.password,
      errorTexts?.required,
      forceSignupChild,
      isEdit,
    ]
  )

  const validationSchema = useMemo(() => getValidationSchema(fields), [fields])

  return (
    <SC.AddChildren className={className}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnChange={true}
        validateOnBlur={true}
        onSubmit={onSubmit}
      >
        {(formikProps) => (
          <Form noValidate autoComplete={'off'}>
            <SC.Fields>
              {fields
                ?.filter(
                  (field) =>
                    field.name !== 'avatar' &&
                    (!field?.conditional || formikProps.values.signupChild)
                )
                ?.map((field, idx) => {
                  return field && formikProps && renderField(field, formikProps, fieldsProps, idx)
                })}
              {avatarField && renderField(avatarField, formikProps, fieldsProps)}
            </SC.Fields>

            {!isEdit && linkCodeText && linkCode && (
              <SC.LinkCodeBox>
                <SC.LinkCodeText>{linkCodeText}</SC.LinkCodeText>
                <SC.LinkCode>{linkCode}</SC.LinkCode>
              </SC.LinkCodeBox>
            )}
            <FormSubmitErrors errors={submitErrors} />
            <SC.Buttons>
              <SC.SubmitButton {...submitButton} autoIcon />
            </SC.Buttons>
          </Form>
        )}
      </Formik>
    </SC.AddChildren>
  )
}

export default AddChildForm
