import React, { FC, memo, ReactNode } from 'react'

import Header, { HeaderProps } from '../../components/Header'
import Menu, { MenuProps } from '../../components/Menu'
import AuthLayout from '../../layouts/AuthLayout'
import { PageBackButtonProps } from '../../components/PageBackButton'
import { SearchSubjectProps } from '../../components/SearchSubject'
import { UsersSliderProps } from '../../components/UsersSlider'
import { SearchRecurrenceTeacherProfileProps } from '../../components/SearchRecurrenceTeacherProfile'

import * as SC from './styled'

export type SearchDisciplineTemplateProps = {
  className?: string
  children?: ReactNode
  headerProps: HeaderProps
  menuProps: MenuProps
  backButton: PageBackButtonProps
  title: string
  typeTitle: string
  typeText: string
  searchSubjectProps: SearchSubjectProps
  usersSliderProps?: UsersSliderProps
  teacherProfileProps?: SearchRecurrenceTeacherProfileProps
}

export const SearchDisciplineTemplate: FC<SearchDisciplineTemplateProps> = (props) => {
  const {
    headerProps,
    menuProps,
    title,
    backButton,
    typeTitle,
    typeText,
    searchSubjectProps,
    usersSliderProps,
    teacherProfileProps,
  } = props

  return (
    <AuthLayout header={<Header {...headerProps} withDivider />} menu={<Menu {...menuProps} />}>
      <SC.Content>
        <SC.BackButton {...backButton} />
        <SC.Subtitle>{title}</SC.Subtitle>
        <SC.Title>{typeTitle}</SC.Title>
        <SC.Text2>{typeText}</SC.Text2>
        {teacherProfileProps && <SC.TeacherProfile {...teacherProfileProps} />}
        {usersSliderProps && <SC.Student {...usersSliderProps} />}
        <SC.SearchSubject {...searchSubjectProps} />
      </SC.Content>
    </AuthLayout>
  )
}

export default memo(SearchDisciplineTemplate)
