import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { HeaderProps } from '../../components/Header'
import useHeader from '../../hooks/useHeader'
import SignUpTeacherPricingTemplate from '../../templates/SignUpTeacherPricing'
import { actions, selectors } from '../../redux'
import { resolveRegisterStepURL } from '../../helpers/RegisterHelpers'
import { useFormSubmit } from '../../hooks/useFormSubmit'
import {
  PricesChoiceFormProps,
  PricesChoiceFormValues,
  PricingType,
} from '../../components/PricesChoiceForm'

const SignUpTeacherPricingPage: React.FC = (_props) => {
  const headerProps: HeaderProps = useHeader()

  const { t } = useTranslation()
  const user = useSelector(selectors.auth.user)
  const userDisciplines = useSelector(selectors.auth.disciplinesWithGrades)

  const history = useHistory()

  const onComplete = useCallback(() => {
    history.push(resolveRegisterStepURL(user))
  }, [user, history])

  const [submit, handleSubmit] = useFormSubmit(
    selectors.auth.signUpTeacherPricing,
    actions.auth.signUpTeacherPricingRequest,
    actions.auth.signUpTeacherPricingReset,
    onComplete
  )

  const signupProps: PricesChoiceFormProps = useMemo(() => {
    let type = PricingType.UNIQUE
    const customValues = userDisciplines?.map((discipline) =>
      discipline.grades?.reduce((arr, g) => {
        if (g?.price && g?.price > 0) {
          type = PricingType.CUSTOM
        }
        return {
          ...arr,
          [g.id as string]: g?.price && g?.price > 0 ? g.price.toString() : '',
        }
      }, {})
    )

    const initialValues: PricesChoiceFormValues = {
      type,
      unique: user?.teacher?.price?.toString() || '',
      custom: customValues || [],
    }

    const groups = userDisciplines?.map((discipline) => ({
      label: discipline.name,
      levels: discipline.grades?.map((g) => ({
        name: g.id,
        label: g.name,
        placeholder: t('teacherPricingForm_custom_placeholder'),
      })),
    }))

    return {
      title: t('teacherPricingForm_title'),
      uniqueLabel: t('teacherPricingForm_type_unique_label'),
      uniqueText: t('teacherPricingForm_type_unique_text'),
      customLabel: t('teacherPricingForm_type_custom_label'),
      customText: t('teacherPricingForm_type_custom_text'),
      fieldsProps: {
        type: {},
        unique: {
          label: t('teacherPricingForm_unique_label'),
        },
        custom: {
          groups,
        },
      },
      errorTexts: {
        required: t('error_required'),
      },
      initialValues,
      submitButton: {
        text: submit.pending
          ? t('signUpTeacherPricing_submit_pending')
          : t('signUpTeacherPricing_submit'),
        isPending: submit.pending,
      },
      submitErrors: submit.errors,
      onSubmit: (values) => {
        handleSubmit(values)
      },
    }
  }, [userDisciplines, user?.teacher?.price, t, submit.pending, submit.errors, handleSubmit])

  const textImageProps = {
    title: t('signUpTeacherPricing_title'),
    subTitle: t('signUpTeacherPricing_subtitle'),
    text: t('signUpTeacherPricing_text'),
    image: {
      alt: 'LiberteClass - Félicitations',
      src: '/static/assets/images/congrats.png',
    },
  }

  return (
    <SignUpTeacherPricingTemplate
      headerProps={headerProps}
      textImageProps={textImageProps}
      pricesChoiceFormProps={signupProps}
    />
  )
}

export default SignUpTeacherPricingPage
