import gql from 'graphql-tag'

import UserFragment from '../../../fragments/UserFragment'
import type { User } from '../../../../generated/graphql'

const updateGuardian = {
  mutation: gql`
    mutation updateGuardian(
      $first_name: String!
      $last_name: String!
      $email: String!
      $phone: String!
      $display_address: String!
      $address: String
      $city: String
      $zip_code: String
      $country: String
      $avatar: String
    ) {
      updateGuardian(
        first_name: $first_name
        last_name: $last_name
        email: $email
        phone: $phone
        display_address: $display_address
        address: $address
        city: $city
        zip_code: $zip_code
        country: $country
        avatar: $avatar
      ) {
        ...UserFragment
      }
    }
    ${UserFragment}
  `,
  transformer: (response: any): User => {
    return response.updateGuardian
  },
}

export default updateGuardian
