import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { REHYDRATE } from 'redux-persist'
import jwtDecode, { JwtPayload } from 'jwt-decode'

import type { RootState } from '../store'
import {
  QueryNotificationsArgs,
  QueryStripeAccountLinkArgs,
  QueryStripeLoginLinkArgs,
  TeacherDiscipline,
  User,
  UserStatus,
} from '../../generated/graphql'
import { getServiceProps, getServiceReducers, ServiceProps } from '../../helpers/ReduxHelpers'
import { SignUpFormValues } from '../../components/SignUpForm'
import { SignInFormValues } from '../../components/SignInForm'
import { SignUpPersonalInfosFormValues } from '../../components/SignUpPersonalInfos'
import { LevelPickersFormValues } from '../../components/LevelPickersForm'
import { SignUpProfessionalInfosValues } from '../../components/SignUpProfessionalInfos'
import { PricesChoiceFormValues } from '../../components/PricesChoiceForm'
import { SignInForgotFormValues } from '../../components/SignInForgotForm'
import { SignInResetFormValues } from '../../components/SignInResetForm'
import { SocialConnectValues } from '../../components/SocialConnect'
import { SignUpSocialFormValues } from '../../components/SignUpSocialForm'
import { AddChildrenValues } from '../../components/SignUpAddChildren'
import { SignUpAttachParentFormValues } from '../../components/SignUpAttachParent'
import { ProfileFormValues } from '../../components/ProfilForm'
import { ChangePasswordValues } from '../../components/modals/ChangePasswordModal'
import { NotificationsResult } from '../../graphql/services/notifications/query/notifications'

import { actionTypes } from './types'
import { UserDisciplineWithGrades, UserGradeWithPrice } from './types/state'

export type AuthState = {
  user: User | null
  token: string | null
  impersonate?: boolean

  // services
  register: ServiceProps<User, SignUpFormValues>
  login: ServiceProps<User, SignInFormValues>
  signUpPersonalInfos: ServiceProps<User, SignUpPersonalInfosFormValues>
  signUpTeacherDisciplines: ServiceProps<User, LevelPickersFormValues>
  signUpProfessionalInfos: ServiceProps<User, SignUpProfessionalInfosValues>
  signUpTeacherPricing: ServiceProps<User, PricesChoiceFormValues>
  signUpGuardianAddChildren: ServiceProps<User, AddChildrenValues>
  signUpChildAddGuardian: ServiceProps<User, SignUpAttachParentFormValues>
  signInForgot: ServiceProps<string, SignInForgotFormValues>
  signInReset: ServiceProps<User, SignInResetFormValues>
  loadStripeLoginLink: ServiceProps<string, QueryStripeLoginLinkArgs>
  loadStripeAccountLink: ServiceProps<string, QueryStripeAccountLinkArgs>
  socialLogin: ServiceProps<string, SocialConnectValues>
  signUpSocial: ServiceProps<string, SignUpSocialFormValues>
  resendVerificationMail: ServiceProps<string, undefined>
  reloadWallet: ServiceProps<Partial<User>, undefined>
  reloadNotificationsCount: ServiceProps<Partial<User>, undefined>
  notifications: ServiceProps<NotificationsResult, QueryNotificationsArgs>
  updateProfile: ServiceProps<User, ProfileFormValues>
  updatePassword: ServiceProps<User, ChangePasswordValues>
}

//
// Initial state
//

const initialState: AuthState = {
  user: null,
  token: null,
  impersonate: false,
  // Services
  register: getServiceProps(),
  login: getServiceProps(),
  signUpPersonalInfos: getServiceProps(),
  signUpTeacherDisciplines: getServiceProps(),
  signUpProfessionalInfos: getServiceProps(),
  signUpTeacherPricing: getServiceProps(),
  signUpGuardianAddChildren: getServiceProps(),
  signUpChildAddGuardian: getServiceProps(),
  signInForgot: getServiceProps(),
  signInReset: getServiceProps(),
  loadStripeLoginLink: getServiceProps(),
  loadStripeAccountLink: getServiceProps(),
  socialLogin: getServiceProps(),
  signUpSocial: getServiceProps(),
  resendVerificationMail: getServiceProps(),
  reloadWallet: getServiceProps(),
  reloadNotificationsCount: getServiceProps(),
  notifications: getServiceProps(),
  updateProfile: getServiceProps(),
  updatePassword: getServiceProps(),
}

//
// Slice (Actions & Reducers)
//

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (state, action: actionTypes.setUser) => {
      state.user = { ...action.payload, token: null }
    },
    setToken: (state, action: actionTypes.setToken) => {
      state.token = action.payload
    },
    setImpersonate: (state, action: actionTypes.setImpersonate) => {
      state.impersonate = action.payload
    },
    resetAuth: () => initialState,
    // Services
    ...getServiceReducers('register'),
    ...getServiceReducers('login'),
    ...getServiceReducers('signUpPersonalInfos'),
    ...getServiceReducers('signUpTeacherDisciplines'),
    ...getServiceReducers('signUpProfessionalInfos'),
    ...getServiceReducers('signUpTeacherPricing'),
    ...getServiceReducers('signUpGuardianAddChildren'),
    ...getServiceReducers('signUpChildAddGuardian'),
    ...getServiceReducers('signInForgot'),
    ...getServiceReducers('signInReset'),
    ...getServiceReducers('loadStripeLoginLink'),
    ...getServiceReducers('loadStripeAccountLink'),
    ...getServiceReducers('socialLogin'),
    ...getServiceReducers('signUpSocial'),
    ...getServiceReducers('resendVerificationMail'),
    ...getServiceReducers('reloadWallet'),
    ...getServiceReducers('reloadNotificationsCount'),
    ...getServiceReducers('notifications'),
    ...getServiceReducers('updateProfile'),
    ...getServiceReducers('updatePassword'),
    logIn: (_state, _action: actionTypes.logIn) => undefined,
    logOut: (_state, _action: actionTypes.logOut) => undefined,
  },
  extraReducers: {
    [REHYDRATE]: (state, action: PayloadAction<any>) => {
      const { user = null } = action.payload?.auth || {}

      return {
        ...initialState,
        user,
      }
    },
  },
})

export const { reducer, actions } = slice

//
// Selectors
//

const root = (state: RootState) => state[slice.name]
const user = (state: RootState) => root(state).user
const impersonate = (state: RootState) => root(state).impersonate
const token = (state: RootState) => root(state).token
const jwt = createSelector([token], (tokenString): JwtPayload | null => {
  return tokenString ? jwtDecode<JwtPayload>(tokenString) : null
})
const isConnected = createSelector([token], (t): boolean => {
  return !!t && t !== ''
})
const isActive = createSelector([user], (u): boolean => {
  return u?.status === UserStatus.Active
})
const isExpired = createSelector([jwt], (j): boolean => {
  return !!j && !!j.exp && j.exp * 1000 <= new Date().getTime()
})

const disciplinesWithGrades = createSelector([user], (u): UserDisciplineWithGrades[] | null => {
  return Object.values(
    u?.teacher?.disciplines?.reduce(
      (arr: { [key: string]: UserDisciplineWithGrades }, couple: TeacherDiscipline) => {
        if (couple && couple?.discipline && couple?.discipline?.id && couple?.grade) {
          if (!arr?.[couple.discipline.id]) {
            arr[couple?.discipline?.id] = {
              ...couple.discipline,
              grades: [] as UserGradeWithPrice[],
            }
          }
          arr[couple.discipline.id].grades.push({
            ...couple.grade,
            price: couple.price ?? 0,
          })
        }
        return arr
      },
      {} as { [key: string]: UserDisciplineWithGrades }
    ) as { [key: string]: UserDisciplineWithGrades }
  )
})
// services
const register = (state: RootState) => root(state).register
const login = (state: RootState) => root(state).login
const signUpPersonalInfos = (state: RootState) => root(state).signUpPersonalInfos
const signUpTeacherDisciplines = (state: RootState) => root(state).signUpTeacherDisciplines
const signUpProfessionalInfos = (state: RootState) => root(state).signUpProfessionalInfos
const signUpGuardianAddChildren = (state: RootState) => root(state).signUpGuardianAddChildren
const signUpTeacherPricing = (state: RootState) => root(state).signUpTeacherPricing
const signUpChildAddGuardian = (state: RootState) => root(state).signUpChildAddGuardian
const signInForgot = (state: RootState) => root(state).signInForgot
const signInReset = (state: RootState) => root(state).signInReset
const loadStripeLoginLink = (state: RootState) => root(state).loadStripeLoginLink
const loadStripeAccountLink = (state: RootState) => root(state).loadStripeAccountLink
const socialLogin = (state: RootState) => root(state).socialLogin
const signUpSocial = (state: RootState) => root(state).signUpSocial
const resendVerificationMail = (state: RootState) => root(state).resendVerificationMail
const reloadWallet = (state: RootState) => root(state).reloadWallet
const reloadNotificationsCount = (state: RootState) => root(state).reloadNotificationsCount
const notifications = (state: RootState) => root(state).notifications
const updateProfile = (state: RootState) => root(state).updateProfile
const updatePassword = (state: RootState) => root(state).updatePassword

export const selectors = {
  user,
  impersonate,
  token,
  jwt,
  isConnected,
  isExpired,
  isActive,
  disciplinesWithGrades,
  // services
  register,
  login,
  signUpPersonalInfos,
  signUpTeacherDisciplines,
  signUpProfessionalInfos,
  signUpTeacherPricing,
  signUpGuardianAddChildren,
  signUpChildAddGuardian,
  signInForgot,
  signInReset,
  loadStripeLoginLink,
  loadStripeAccountLink,
  socialLogin,
  signUpSocial,
  resendVerificationMail,
  reloadWallet,
  reloadNotificationsCount,
  notifications,
  updateProfile,
  updatePassword,
}
