import React, { FC, useMemo } from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'

import { CalendarProps } from '../form/Calendar'
import { ToggleButtonPickerProps } from '../form/ToggleButtonPicker'
import { FormSubmitProps } from '../FormSubmit'
import FormSubmitErrors, { FormSubmitErrorsProps } from '../FormSubmitErrors'
import AvailabilitiesPicker, { AvailabilitiesObject } from '../form/AvailabilitiesPicker'
import { getValidationSchema, renderField } from '../../helpers/FormHelpers'

import * as SC from './styled'

export type AvailabilityValues = {
  availabilities: { date: string; slots: string[] }[]
}

export type AvailabilityFormProps = {
  className?: string
  calendar: CalendarProps
  slot: ToggleButtonPickerProps
  disabledSlots?: AvailabilitiesObject
  submitButton: FormSubmitProps
  cancelButton?: FormSubmitProps
  initialValues?: AvailabilityValues
  submitErrors?: FormSubmitErrorsProps['errors']
  onSubmit?: (values: AvailabilityValues) => void
  errorTexts?: {
    required: string
  }
}

const AvailabilityForm: FC<AvailabilityFormProps> = (props) => {
  const {
    className,
    calendar,
    slot,
    submitButton,
    cancelButton,
    initialValues = { availabilities: [] },
    submitErrors,
    onSubmit = (_values) => null,
    errorTexts,
    disabledSlots,
  } = props

  const field = useMemo(
    () => ({
      name: 'availabilities',
      Component: AvailabilitiesPicker,
      validation: Yup.array().min(1, errorTexts?.required),
      calendar,
      slot,
      disabledSlots,
    }),
    [calendar, slot, errorTexts?.required, disabledSlots]
  )

  const validationSchema = useMemo(() => getValidationSchema([field]), [field])

  return (
    <SC.AvailabilityPerDayForm className={className}>
      <SC.Wrapper disableGutters maxWidth={false}>
        <Formik
          onSubmit={onSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize
        >
          {(formikProps) => (
            <SC.Form noValidate>
              {renderField(field, formikProps, { name: {} })}
              <FormSubmitErrors errors={submitErrors} />
              <SC.Buttons>
                {cancelButton && <SC.Button {...cancelButton} autoIcon type={'button'} />}
                <SC.SubmitButton {...submitButton} autoIcon />
              </SC.Buttons>
            </SC.Form>
          )}
        </Formik>
      </SC.Wrapper>
    </SC.AvailabilityPerDayForm>
  )
}

export default AvailabilityForm
