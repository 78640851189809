import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { HeaderProps } from '../../components/Header'
import useHeader from '../../hooks/useHeader'
import SignUpSocialTemplate from '../../templates/SignUpSocial'
import { actions, selectors } from '../../redux'
import { UserType } from '../../generated/graphql'
import { useFormSubmit } from '../../hooks/useFormSubmit'
import { enumOption } from '../../helpers/GraphqlHelpers'
import { SignUpSocialFormProps } from '../../components/SignUpSocialForm'
import { resolveRegisterStepURL } from '../../helpers/RegisterHelpers'

const SignUpSocialPage: React.FC = (pageProps) => {
  const headerProps: HeaderProps = useHeader(pageProps)

  const { t } = useTranslation()
  const user = useSelector(selectors.auth.user)
  const history = useHistory()
  const setting = useSelector(selectors.app.setting)

  const [type, setType] = useState(UserType.Guardian)

  const onComplete = useCallback(() => {
    history.push(resolveRegisterStepURL(user))
  }, [user, history])

  const [submit, handleSubmit] = useFormSubmit(
    selectors.auth.signUpSocial,
    actions.auth.signUpSocialRequest,
    actions.auth.signUpSocialReset,
    onComplete
  )

  const urlCGU = setting?.links?.cgu

  const signupProps: SignUpSocialFormProps = useMemo(() => {
    return {
      title: t('signUp_title'),
      fieldsProps: {
        type: {
          label: t('signUp_form_type_label'),
          options: [
            enumOption(t, 'UserType', UserType.Guardian),
            enumOption(t, 'UserType', UserType.Student),
            enumOption(t, 'UserType', UserType.Teacher),
          ],
        },
        firstName: {
          label: t('signUp_form_firstName_label'),
        },
        lastName: {
          label: t('signUp_form_lastName_label'),
        },
        email: {
          label: t('signUp_form_email_label'),
        },
        sponsor: {
          label: t('signUp_form_sponsor_label'),
        },
        cgu: {
          label: (
            <span
              dangerouslySetInnerHTML={{ __html: t('signUp_form_cgu_label', { url: urlCGU }) }}
            />
          ),
        },
      },
      initialValues: {
        type,
        firstName: user?.first_name ?? '',
        lastName: user?.last_name ?? '',
        email: user?.email ?? '',
        cgu: false,
        sponsor: '',
      },
      errorTexts: {
        required: t('error_required'),
        email: t('error_email'),
        profile: t('error_user_type'),
        cgu: t('error_cgu'),
      },
      sponsorText: t('signUp_sponsor_text'),
      mandatoryText: t('signUp_required_text'),
      submitButton: {
        text: submit.pending ? t('signUp_submit_pending') : t('signUp_submit'),
        isPending: submit.pending,
      },
      submitErrors: submit.errors,
      onSubmit: (values) => handleSubmit(values),
      onTypeChange: setType,
      isTeacher: false, //type === UserType.Teacher,
    }
  }, [
    t,
    user?.first_name,
    user?.last_name,
    user?.email,
    submit.pending,
    submit.errors,
    type,
    handleSubmit,
    urlCGU,
  ])

  const imageProps =
    type === UserType.Teacher
      ? {
          alt: 'LiberteClass - Inscription',
          src: '/static/assets/images/signup-teacher.png',
        }
      : {
          alt: 'LiberteClass - Inscription',
          src: '/static/assets/images/signup.png',
        }

  return (
    <SignUpSocialTemplate
      headerProps={headerProps}
      signupProps={signupProps}
      imageProps={imageProps}
    />
  )
}

export default SignUpSocialPage
