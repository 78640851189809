import React, { FC, memo } from 'react'

import { BasicProfilProps } from '../../components/BasicProfil'

import * as SC from './styled'

export type SearchRecurrenceTeacherProfileProps = {
  className?: string
  label?: string
  profileProps?: BasicProfilProps
}

export const SearchRecurrenceTeacherProfile: FC<SearchRecurrenceTeacherProfileProps> = (props) => {
  const { className, label, profileProps } = props

  return (
    <SC.Content className={className}>
      {label && <SC.TeacherLabel>{label}</SC.TeacherLabel>}
      {profileProps && <SC.TeacherProfile {...profileProps} />}
    </SC.Content>
  )
}

export default memo(SearchRecurrenceTeacherProfile)
