import React, { FC } from 'react'

import { ImgProps } from '../../types/image'
import { RatingCustomProps } from '../RatingCustom'
import { LinkProps } from '../Link'

import * as SC from './styled'

type RatingProps = RatingCustomProps & {
  text: string
}

export type BasicProfilProps = {
  className?: string
  name: string
  linkProps?: LinkProps
  level?: string
  profilePicture: ImgProps
  commentary?: string
  nameColor?: string
  levelColor?: string
  rating?: RatingProps
}

const BasicProfil: FC<BasicProfilProps> = (props) => {
  const {
    className,
    linkProps,
    profilePicture,
    name,
    level,
    commentary,
    nameColor,
    levelColor,
    rating,
  } = props

  return (
    <SC.BasicProfil className={className} {...linkProps}>
      <SC.Wrapper disableGutters>
        <SC.Profile>
          <SC.Image src={profilePicture.src} alt={profilePicture.alt} />
          <SC.ProfileInfo>
            <SC.Name color={nameColor}>{name}</SC.Name>
            {level && <SC.Level color={levelColor}>{level}</SC.Level>}
          </SC.ProfileInfo>
        </SC.Profile>
        {rating && (
          <SC.Rating>
            <SC.Stars
              size="small"
              value={rating.value}
              isNotEditable={rating?.isNotEditable}
              max={rating?.max}
            />
            <SC.RatingText>{rating.text}</SC.RatingText>
          </SC.Rating>
        )}
        {commentary && <SC.Commentary>{commentary}</SC.Commentary>}
      </SC.Wrapper>
    </SC.BasicProfil>
  )
}

export default BasicProfil
