import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import useImpersonate from '../../hooks/useImpersonate'
import { JitsiProps } from '../../components/Jitsi'
import { actions, selectors } from '../../redux'
import { NotFoundProps } from '../../components/NotFound'
import useHeader from '../../hooks/useHeader'
import { RichTextTransformer } from '../../helpers/ContentfulHelpers'
import { app, jitsi } from '../../configuration'
import { Course, UserType } from '../../generated/graphql'
import { router, routesPath } from '../../router'
import { CallEndedProps } from '../../components/CallEnded'
import RoomTemplate, { CallStatus, RoomTemplateProps } from '../../templates/Room'
import useMenu from '../../hooks/useMenu'
import {
  checkCourseBegan,
  checkCourseEvaluationDoneByTeacher,
  checkCourseIsPast,
  checkCourseReportable,
  checkCourseReportableExpired,
  courseToMetaProps,
} from '../../transformers/courseTransformers'
import PreloadTemplate, { PreloadTemplateProps } from '../../templates/Preload'
import { CallNotYetProps } from '../../components/CallNotYet'
import { ImageTextItemProps } from '../../components/ImageTextItem'
import { CallSelectChildProps } from '../../components/CallSelectChild'
import useBackButton from '../../hooks/useBackButton'
import useReportingModal from '../../hooks/useReportingModal'
import useFeedbackModal from '../../hooks/useFeedbackModal'

export type RoomPageProps = RouteComponentProps<{ id: string }>

const RoomPage: React.FC<RoomPageProps> = (pageProps) => {
  const { match } = pageProps
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const headerProps = useHeader(pageProps)
  const menuProps = useMenu(pageProps)
  const backButton = useBackButton()
  const contentful = useSelector(selectors.app.contentful)
  const user = useSelector(selectors.auth.user)
  const isTeacher = user?.user_type === UserType.Teacher
  const join = useSelector(selectors.room.join)
  const impersonateProps = useImpersonate()
  const [status, setStatus] = useState<CallStatus>(CallStatus.PENDING)
  const courseId = match?.params?.id
  const courseQuery = useSelector(selectors.planning.course)
  const course: Course | null = courseQuery?.data
  const isEvaluationDue = !checkCourseEvaluationDoneByTeacher(course) && checkCourseBegan(course)
  const isReportable = useMemo(() => !app.DISPUTE_DISABLED && checkCourseReportable(course), [
    course,
  ])
  const isReportExpired = useMemo(() => checkCourseReportableExpired(course), [course])
  const isPast = checkCourseIsPast(course)
  const childrenIds: string[] | undefined = useMemo(
    () => user?.guardian?.children?.map((c) => c.id),
    [user?.guardian?.children]
  )
  const studentsIds = useMemo(
    () =>
      course?.requests
        ?.filter((r) => childrenIds?.includes(r.student?.id))
        ?.map((r) => r.student?.id) ?? [],
    [childrenIds, course?.requests]
  )

  const [student, setStudent] = useState<string | null>(null)

  const [setReportingFeedbackModalOpen, reportingFeedbackModalProps] = useFeedbackModal({
    title: t('reporting_feedback_title'),
    subtitle: t('reporting_feedback_subtitle'),
    text: t('reporting_feedback_text'),
  })
  const onReportingComplete = useCallback(() => setReportingFeedbackModalOpen(true), [
    setReportingFeedbackModalOpen,
  ])
  const [setReportingOpen, reportingModalProps] = useReportingModal(
    course,
    onReportingComplete,
    student
  )

  useEffect(() => {
    setStudent(
      childrenIds?.length === 1 ? childrenIds[0] : studentsIds.length === 1 ? studentsIds[0] : null
    )
  }, [studentsIds, childrenIds])

  useEffect(() => {
    if (student && status === CallStatus.CHOOSE_CHILD) {
      setStatus(CallStatus.JOINING)
      dispatch(actions.room.joinRequest({ room: course?.room?.hash, student }))
    }
  }, [course?.room?.hash, dispatch, status, student])

  useEffect(() => {
    if (status === CallStatus.PENDING && courseQuery.complete) {
      if (courseQuery.success && courseQuery.data) {
        if (course?.room?.expired || isPast) {
          setStatus(CallStatus.ENDED)
        } else if (!course?.room?.available) {
          setStatus(CallStatus.NOT_YET)
        } else {
          if (user?.user_type !== UserType.Guardian || student) {
            setStatus(CallStatus.JOINING)
            dispatch(actions.room.joinRequest({ room: course?.room?.hash, student }))
          } else {
            // ask which children should join
            setStatus(CallStatus.CHOOSE_CHILD)
          }
        }
      } else {
        console.log('CourseQuery error', courseQuery.errors)
        setStatus(CallStatus.NOT_FOUND)
      }
    }
  }, [
    courseQuery.complete,
    courseQuery.errors,
    courseQuery.success,
    courseQuery.data,
    dispatch,
    isPast,
    status,
    course,
    user?.user_type,
    student,
  ])

  useEffect(() => {
    if (status === CallStatus.JOINING && join.complete) {
      if (join.success) {
        setStatus(CallStatus.RUNNING)
      } else {
        console.log('joinQuery error', join.errors)
        setStatus(CallStatus.NOT_ALLOWED)
      }
    }
  }, [status, join])

  const selectChildProps: CallSelectChildProps = useMemo(
    () => ({
      title: t('room_childSelector_label'),
      backButtonProps: backButton,
      imageTextListProps: {
        items:
          user?.guardian?.children
            ?.filter((child) => studentsIds?.includes(child?.id))
            ?.map(
              (child): ImageTextItemProps => ({
                image: {
                  alt: child.first_name ?? '',
                  src: child.avatar ?? '',
                },
                isAvatar: true,
                text1: child?.first_name ?? '',
                text2: child?.grade?.name ?? '',
                link: {
                  onClick: () => setStudent(child?.id),
                },
              })
            ) ?? [],
      },
    }),
    [studentsIds, user?.guardian?.children, backButton, t]
  )

  const notFoundProps: NotFoundProps = useMemo(
    () => ({
      title: contentful?.notFound?.title ?? '',
      body: RichTextTransformer(contentful?.notFound?.body?.json),
      cta: {
        text: contentful?.notFound?.cta?.label ?? '',
        link: {
          link: app.APP_URL,
        },
      },
      image: {
        alt: 'LiberteClass - 404 - NotFound',
        src: '/static/assets/images/404.png',
      },
      backgroundColor: '#F1F3FF',
      backgroundImage: {
        alt: 'LiberteClass - Background - NotFound',
        src: '/static/assets/images/cloud.png',
      },
    }),
    [
      contentful?.notFound?.body?.json,
      contentful?.notFound?.cta?.label,
      contentful?.notFound?.title,
    ]
  )

  const notAllowedProps: NotFoundProps = useMemo(
    () => ({
      title: t('room_not_allowed_title'),
      body: t('room_not_allowed_body'),
      cta: {
        text: t('room_not_allowed_cta'),
        link: {
          link: router(routesPath.dashboard),
        },
      },
      image: {
        alt: 'LiberteClass - 404 - NotFound',
        src: '/static/assets/images/404.png',
      },
      backgroundColor: '#F1F3FF',
      backgroundImage: {
        alt: 'LiberteClass - Background - NotFound',
        src: '/static/assets/images/cloud.png',
      },
    }),
    [t]
  )

  const callEndedProps: CallEndedProps = useMemo(
    () => ({
      backButtonProps: backButton,
      textAndImageProps: {
        title: t('room_ended_title'),
        subTitle: t('room_ended_subtitle'),
        text: isEvaluationDue
          ? user?.user_type === UserType.Teacher
            ? t('room_ended_evaluate_students_text')
            : t('room_ended_evaluate_teacher_text')
          : t('room_ended_text'),
        image: {
          alt: 'LiberteClass - Félicitations',
          src: '/static/assets/images/congrats.png',
        },
      },
      ...(course && {
        cta: {
          text: isEvaluationDue
            ? user?.user_type === UserType.Teacher
              ? t('room_ended_evaluate_students_cta')
              : t('room_ended_evaluate_teacher_cta')
            : t('room_ended_cta'),
          link: {
            href: router(routesPath.course, { id: course?.id ?? '' }),
          },
        },
        ...(course?.room?.available &&
          !isPast && {
            errorText: t('room_ended_error_text'),
            errorCta: {
              text: t('room_ended_error_cta'),
              onClick: () => setStatus(CallStatus.RUNNING),
            },
          }),
        courseMeta: courseToMetaProps(course, t),
        ...(isReportable && {
          reportCta: {
            text: t('room_ended_report_cta'),
            onClick: () => !isReportExpired && setReportingOpen(true),
            disabled: isReportExpired,
          },
        }),
        reportingModalProps,
        reportingFeedbackModalProps,
      }),
    }),
    [
      backButton,
      t,
      isEvaluationDue,
      user?.user_type,
      course,
      isPast,
      isReportable,
      isReportExpired,
      reportingModalProps,
      reportingFeedbackModalProps,
      setReportingOpen,
    ]
  )

  const notYetProps: CallNotYetProps = useMemo(
    () => ({
      backButtonProps: backButton,
      title: t('room_notYet_title'),
      text: t('room_notYet_text'),
      ...(course && {
        cta: {
          text: t('room_notYet_cta'),
          link: {
            href: router(routesPath.course, { id: course?.id ?? '' }),
          },
        },
        courseMeta: courseToMetaProps(course, t),
      }),
    }),
    [course, t, backButton]
  )

  const jitsiProps: JitsiProps = useMemo(
    () => ({
      url: jitsi.url,
      domain: jitsi.domain,
      token: join?.data?.token || '',
      room: `${jitsi.appID}/${join?.data?.hash}` || '',
      config: { ...jitsi.config(isTeacher), ...join?.data?.config },
      interfaceConfig: { ...jitsi.interfaceConfig, ...join?.data?.interfaceConfig },
      isModerator: isTeacher,
      onClose: () => setStatus(CallStatus.ENDED),
    }),
    [
      isTeacher,
      join?.data?.config,
      join?.data?.hash,
      join?.data?.interfaceConfig,
      join?.data?.token,
    ]
  )

  const roomProps: RoomTemplateProps = useMemo(
    () => ({
      menuProps,
      headerProps,
      impersonateProps,
      status,
      jitsiProps,
      callEndedProps,
      notAllowedProps,
      notFoundProps,
      notYetProps,
      selectChildProps,
    }),
    [
      menuProps,
      callEndedProps,
      headerProps,
      impersonateProps,
      jitsiProps,
      notAllowedProps,
      notFoundProps,
      status,
      notYetProps,
      selectChildProps,
    ]
  )

  const preloadProps: PreloadTemplateProps = useMemo(
    () => ({
      headerProps,
      menuProps,
      text: t('preload_text'),
      backButton: backButton,
    }),
    [t, headerProps, menuProps, backButton]
  )

  useEffect(
    () => () => {
      dispatch(actions.planning.courseReset(null))
    },
    [dispatch]
  )

  useEffect(() => {
    dispatch(actions.planning.courseRequest(courseId))
  }, [dispatch, courseId])

  if (!courseQuery.complete) {
    return <PreloadTemplate {...preloadProps} />
  }

  return <RoomTemplate {...roomProps} />
}

export default RoomPage
