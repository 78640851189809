import styled from 'styled-components'
import ReactMarkdown from 'react-markdown'

import ImageTextList from '../ImageTextList'
import BasicLink from '../BasicLink'
import ActionButton from '../ActionButton'
import FormSubmit from '../FormSubmit'

export const CourseDetails = styled.div``

export const Wrapper = styled.div``

export const Title = styled.h3`
  ${(props) => ({ ...props.theme.typography.h3 })}
  color: ${(props) => props.theme.palette.colors.midnightBlue};
  margin: 0;
`

export const Detail = styled.div`
  display: flex;
  flex-direction: column;
`

export const DetailTitle = styled.h4`
  ${(props) => ({ ...props.theme.typography.h4 })}
  color: ${(props) => props.theme.palette.colors.freeSpeechBlue};
  margin: 2.4rem 0 0.8rem 0;
`

export const DetailDescription = styled(ReactMarkdown)`
  ${(props) => ({ ...props.theme.typography.big })}
  color: ${(props) => props.theme.palette.colors.chambray};
  margin: 0;
  & p {
    margin-top: 0;
  }
`

export const DetailDocuments = styled(ImageTextList)`
  margin-top: 1.6rem;
  margin-bottom: 0;
  & > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    ${(props) => props.theme.breakpoints.up('md')} {
      flex-direction: column;
      width: 100%;
      & > * {
        max-width: 100%;
        width: 100%;
        &:nth-child(2n + 1) {
          margin-right: 0;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
`

export const DetailCTA = styled(BasicLink)`
  ${(props) => ({ ...props.theme.typography.smallBold })}
  color: ${(props) => props.theme.palette.colors.freeSpeechBlue};
`

export const Actions = styled.div`
  margin: 1.6rem 0;
`

export const VirtualClassButton = styled(ActionButton)`
  margin-top: 1.6rem;
`

export const SubmitButton = styled(FormSubmit)`
  margin-top: 1.6rem;
`

export const SubmitButtonRed = styled(FormSubmit)`
  margin-top: 1.6rem;
  background-color: ${(props) => props.theme.palette.colors.tomato};
  border-color: ${(props) =>
    props?.isDisabled ? props.theme.palette.colors.gainsboro : props.theme.palette.colors.tomato};
`

export const Level = styled.div`
  display: flex;
  margin: 1.6rem 0 0;
  ${(props) => ({ ...props.theme.typography.h7 })}
  color: ${(props) => props.theme.palette.colors.freeSpeechBlue};
`

export const Point = styled.p`
  margin: 0;
  ${(props) => ({ ...props.theme.typography.h2 })}
  color: ${(props) => props.theme.palette.colors.gainsboro};
  line-height: 0.1;
  margin: 0 0.3rem;
`
