import React, { FC } from 'react'
import cx from 'classnames'

import * as SC from './styled'

export type LayoutProps = {
  className?: string
  header?: React.ReactNode
  footer?: React.ReactNode
  menu?: React.ReactNode
}

const AuthLayout: FC<LayoutProps> = (props) => {
  const { children, className, header = null, menu = null, footer = null } = props

  return (
    <SC.Container className={cx(className)}>
      <>{header}</>
      <SC.Wrapper>
        <SC.Left>{menu}</SC.Left>
        <SC.Right>{children}</SC.Right>
      </SC.Wrapper>
      <>{footer}</>
    </SC.Container>
  )
}

export default AuthLayout
