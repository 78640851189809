import { Maybe, PublicStudent, Student } from '../generated/graphql'
import { ProfileProps } from '../components/RatingModalities'

export const studentProfileTransformer = (student?: Student | Maybe<Student>): ProfileProps => ({
  profilePicture: {
    alt: 'LiberteClasse - Avatar',
    src: student?.avatar ?? '',
  },
  firstName: student?.first_name ?? '',
  level: student?.grade?.name ?? '',
})

export const publicStudentProfileTransformer = (student?: PublicStudent): ProfileProps => ({
  profilePicture: {
    alt: 'LiberteClasse - Avatar',
    src: student?.avatar ?? '',
  },
  firstName: student?.full_name ?? '',
  level: '',
})
