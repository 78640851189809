import styled from 'styled-components'
import { PaymentInputsWrapper } from 'react-payment-inputs'

export const Container = styled.div``

export const PaymentInputsWrapperC = styled(PaymentInputsWrapper)`
  width: 100%;
  & div:first-child {
    min-height: 5rem;
  }
  & input:nth-child(2) {
    flex: 3;
  }
`

export const CBCard = styled.svg`
  width: 3.1rem;
  height: 2rem;
`

export const Input = styled.input`
  ${(props) => ({ ...props.theme.typography.smallRegular })}
  font-size: 1.4rem !important;
  color: ${(props) => props.theme.palette.colors.grey};
`
