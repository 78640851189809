import styled from 'styled-components'

import Icon from '../Icon'

export const Collapse = styled.div``

export const Wrapper = styled.section`
  width: 100%;
  border: 1px solid #dadada;
  background: ${({ theme }) => theme.palette.colors.pureWhite};
  border-radius: 0.8rem;
  padding: 0 0.8rem 0 1.6rem;
`

export const Top = styled.div`
  display: grid;
  min-height: 5.6rem;
  grid-template-columns: 1fr 3.5rem;
  color: ${({ theme }) => theme.palette.colors.freeSpeechBlue};
  align-items: center;
  cursor: pointer;
`

export const Title = styled.div`
  ${(props) => ({ ...props.theme.typography.h4 })}
  color: ${(props) => props.theme.palette.colors.freeSpeechBlue};
  grid-area: 'title';
`

export const SvgIcon = styled(Icon)<{ isOpen?: boolean }>`
  grid-area: 'icon';
  width: 2.4rem;
  height: 1.8rem;
  justify-self: end;
  transform: ${(props) => (props.isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
`

export const CollapseContent = styled.div`
  grid-area: 'content';
  overflow: hidden;
  height: 0;
  ${Collapse}.-open & {
    height: auto;
  }
`
