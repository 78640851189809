import { Container } from '@material-ui/core'
import styled from 'styled-components'

import BasicLink from '../BasicLink'

export const ScheduleResultCard = styled.div`
  ${(props) => props.theme.breakpoints.up('md')} {
    max-width: 40rem;
  }
`

export const Wrapper = styled(Container)`
  padding: 1.6rem;
  background-color: ${(props) => props.theme.palette.colors.pureWhite};
  border-radius: 0.8rem;
  border: 1px solid ${(props) => props.theme.palette.colors.ghostWhite};
`

export const Hour = styled.div<{ isDisabled?: boolean }>`
  ${(props) => ({ ...props.theme.typography.h4 })}
  color: ${(props) =>
    props.isDisabled
      ? props.theme.palette.colors.gainsboro
      : props.theme.palette.colors.freeSpeechBlue};
  margin-bottom: 1.6rem;
`

export const Cta = styled(BasicLink)`
  ${(props) => ({ ...props.theme.typography.smallBold })}
  color: ${(props) => props.theme.palette.colors.cornflowerBlue};
`

export const AlreadyBookedText = styled.div`
  ${(props) => ({ ...props.theme.typography.smallRegular })}
  color: ${(props) => props.theme.palette.colors.midnightBlue};
`
