import * as apiRedux from './api/redux'
import * as appRedux from './app/redux'
import * as authRedux from './auth/redux'
import * as planningRedux from './planning/redux'
import * as preferencesRedux from './preferences/redux'
import * as roomRedux from './room/redux'
import * as searchRedux from './search/redux'

export const actions = {
  app: appRedux.actions,
  api: apiRedux.actions,
  auth: authRedux.actions,
  planning: planningRedux.actions,
  preferences: preferencesRedux.actions,
  room: roomRedux.actions,
  search: searchRedux.actions,
}

export const selectors = {
  app: appRedux.selectors,
  api: apiRedux.selectors,
  auth: authRedux.selectors,
  planning: planningRedux.selectors,
  preferences: preferencesRedux.selectors,
  room: roomRedux.selectors,
  search: searchRedux.selectors,
}

export const reducers = {
  app: appRedux.reducer,
  api: apiRedux.reducer,
  auth: authRedux.reducer,
  planning: planningRedux.reducer,
  preferences: preferencesRedux.reducer,
  room: roomRedux.reducer,
  search: searchRedux.reducer,
}
