import gql from 'graphql-tag'

import PublicStudentFragment from './PublicStudentFragment'

export default gql`
  fragment StudentReviewFragment on StudentReview {
    id
    student {
      ...PublicStudentFragment
    }
    punctuality
    behaviour
    interactivity
    understanding
    working_conditions
    score
    comment
    mark_as_unwanted
  }
  ${PublicStudentFragment}
`
