import styled from 'styled-components'

import Icon from '../../Icon'
import ModalContainer from '../ModalContainer'
import ActionButton from '../../ActionButton'

export const Modal = styled(ModalContainer)`
  ${(props) => props.theme.breakpoints.up('md')} {
    & .MuiContainer-root {
      max-width: 40rem;
      margin: 0;
    }
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.6rem 0;
`

export const Title = styled.h4<{ $isWarning?: boolean }>`
  ${(props) => ({ ...props.theme.typography.h5 })}
  color: ${(props) =>
    props?.$isWarning ? props.theme.palette.colors.tomato : props.theme.palette.colors.neonCarrot};
  margin: 0 0 1.8rem 0;
  align-self: flex-start;
`

export const MyIcon = styled(Icon)`
  width: 7.6rem;
  height: 7.6rem;
  margin: 0 0 1.6rem;
`

export const Subtitle = styled.h4`
  ${(props) => ({ ...props.theme.typography.h4 })}
  color: ${(props) => props.theme.palette.colors.freeSpeechBlue};
  margin: 0 0 1.6rem;
  text-align: center;
`

export const Text = styled.p`
  ${(props) => ({ ...props.theme.typography.smallRegular })}
  color: ${(props) => props.theme.palette.colors.chambray};
  margin-bottom: 0;
  text-align: center;
  margin: 0 0 1.6rem;
`

export const Button = styled(ActionButton)`
  margin-top: 0.8rem;
`
