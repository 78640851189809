import React, { FC, ReactNode, useMemo } from 'react'
import { CheckboxProps as MuiCheckboxProps } from '@material-ui/core'
import { uniqueId } from 'lodash'

import * as SC from './styled'

export type CheckboxProps = MuiCheckboxProps & {
  className?: string
  label?: string | ReactNode
  name: string
  error?: string
  help?: string
  value: boolean
  required?: boolean
  onChange?: (name: string, value: boolean) => void
}

const Checkbox: FC<CheckboxProps> = ({
  label,
  value,
  required,
  name,
  onChange,
  error,
  help,
  ...otherProps
}) => {
  const id = useMemo(() => otherProps?.id || name + '_' + uniqueId(), [otherProps?.id, name])
  return (
    <SC.Container>
      <SC.Wrapper>
        <SC.MuiCheckbox
          {...otherProps}
          id={id}
          required={required}
          checked={value}
          onChange={(e) => onChange?.(name, e.target.checked)}
          $hasError={!!error}
        />
        <SC.Label htmlFor={id} error={!!error}>
          {label}
        </SC.Label>
        {required && <SC.Required>{' *'}</SC.Required>}
      </SC.Wrapper>
      {(error || help) && <SC.HelperText error={!!error}>{error || help}</SC.HelperText>}
    </SC.Container>
  )
}

export default Checkbox
