import styled from 'styled-components'
import { Form as FormC } from 'formik'

import ActionButton from '../../ActionButton'
import FormSubmit from '../../FormSubmit'
import TextFieldC from '../../form/TextField'

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1.6rem 0;
`

export const Title = styled.h4`
  ${(props) => ({ ...props.theme.typography.h5 })}
  color: ${(props) => props.theme.palette.colors.neonCarrot};
  margin: 0;
  align-self: flex-start;
  margin: 0 0 1.6rem;
`

export const Text = styled.p`
  ${(props) => ({ ...props.theme.typography.mediumRegular })}
  color: ${(props) => props.theme.palette.colors.chambray};
  margin: 0 0 1.6rem;
`

export const Form = styled(FormC)`
  width: 100%;
`

export const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  & > button {
    &:first-child {
      margin-right: 1rem;
    }
    &:last-child {
      margin-left: 1rem;
    }
  }
`

export const Button = styled(ActionButton)``
export const Submit = styled(FormSubmit)``

export const TextField = styled(TextFieldC)`
  margin-bottom: 1.6rem;
`
