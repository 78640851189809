import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { HeaderProps } from '../../components/Header'
import useHeader from '../../hooks/useHeader'
import { MenuProps } from '../../components/Menu'
import useMenu from '../../hooks/useMenu'
import { UserType } from '../../generated/graphql'
import { RestrictedReactFC } from '../../types/common'
import { router, routesPath } from '../../router'
import PreferencesWalletTemplate, {
  PreferencesPotTabs,
  PreferencesWalletTemplateProps,
} from '../../templates/PreferencesWallet'
import { actions, selectors } from '../../redux'
import { StripePaymentConfirmModalProps } from '../../components/modals/StripePaymentConfirmModal'
import { stripe } from '../../configuration'
import { walletTransactionToCardProps } from '../../transformers/walletTransformers'
import useBackButton from '../../hooks/useBackButton'

const PreferencesWalletPage: RestrictedReactFC<RouteComponentProps> = (props) => {
  const headerProps: HeaderProps = useHeader()
  const menuProps: MenuProps = useMenu(props)
  const { t } = useTranslation()
  const [currentTab, setCurrentTab] = useState(PreferencesPotTabs.RESERVATIONS)
  const user = useSelector(selectors.auth.user)
  const dispatch = useDispatch()
  const [walletTransactionsPage, setWalletTransactionsPage] = useState(1)
  const [bookingTransactionsPage, setBookingTransactionsPage] = useState(1)
  const walletTransactions = useSelector(selectors.preferences.walletTransactions)
  const walletTransactionData = useSelector(selectors.preferences.walletTransactionData)
  const bookingTransactions = useSelector(selectors.preferences.bookingTransactions)
  const bookingTransactionData = useSelector(selectors.preferences.bookingTransactionData)
  const backButton = useBackButton(router(routesPath.preferences))

  const onStripeSuccess = useCallback(() => {
    dispatch(actions.auth.reloadWalletRequest(null))
    setTimeout(() => dispatch(actions.auth.reloadWalletRequest(null)), 4000)
  }, [dispatch])

  const stripeConfirmModalProps: StripePaymentConfirmModalProps = useMemo(
    () => ({
      title: t('preferences_wallet_stripeModal_title'),
      stripeKey: stripe.STRIPE_KEY,
      messages: {
        error: t('preferences_wallet_stripeModal_messages_error'),
        loading: t('preferences_wallet_stripeModal_messages_loading'),
        processing: t('preferences_wallet_stripeModal_messages_processing'),
        requires_payment_method: t(
          'preferences_wallet_stripeModal_messages_requires_payment_method'
        ),
        succeeded: t('preferences_wallet_stripeModal_messages_succeeded'),
      },
      closeButton: {
        text: t('preferences_wallet_stripeModal_close'),
      },
      onSucceed: onStripeSuccess,
    }),
    [onStripeSuccess, t]
  )

  const emptyWallet = !(
    (user?.wallet?.amount as number) > 0 || (user?.wallet?.reserved as number) > 0
  )

  const preferencesProps: PreferencesWalletTemplateProps = {
    menuProps,
    headerProps,
    potProps: {
      image: { alt: 'LiberteClass - Cagnotte', src: '/static/assets/images/pot.png' },
      isBelow: true,
      text: t('wallet_title'),
      credits: t('wallet_amount_value', {
        amount: (user?.wallet?.amount ?? 0) / 100,
      }),
      availableLabel: t('wallet_amount_available', { count: (user?.wallet?.amount ?? 0) / 100 }),
      otherCredits: user?.wallet?.reserved
        ? t('wallet_locked_value', { amount: user?.wallet?.reserved / 100 })
        : undefined,
      transferringCredits: user?.wallet?.refund
        ? t('wallet_withdrawal_value', { amount: user?.wallet?.refund / 100 })
        : undefined,
      helperText: emptyWallet ? t('wallet_add_text') : undefined,
      ...(user?.user_type === UserType.Guardian && {
        button: {
          text: t('wallet_add_label'),
          link: {
            href: router(routesPath.preferencesWalletDeposit),
          },
        },
        subButton: {
          text: t('wallet_withdraw_label'),
          link: {
            href: router(routesPath.preferencesWalletWithdrawal),
          },
          outlined: true,
        },
      }),
    },
    title: t('wallet_title'),
    reservationsTitle: t('preferences_wallet_reservations'),
    historyTitle: t('preferences_wallet_history'),
    reservationsProps: {
      cards: bookingTransactionData?.map((transaction) =>
        walletTransactionToCardProps(transaction, t, user ?? undefined)
      ),
      ...((bookingTransactions?.pending ||
        bookingTransactions?.data?.paginatorInfo?.hasMorePages) && {
        buttonProps: {
          text: t('load_more'),
          pendingText: t('load_more_pending'),
          autoIcon: true,
          outlined: true,
          isPending: bookingTransactions?.pending,
          onClick: () =>
            setBookingTransactionsPage(
              (bookingTransactions?.data?.paginatorInfo?.currentPage ?? 0) + 1
            ),
        },
      }),
    },
    historyProps: {
      cards: walletTransactionData?.map((transaction) =>
        walletTransactionToCardProps(transaction, t, user ?? undefined)
      ),
      ...((walletTransactions?.pending ||
        walletTransactions?.data?.paginatorInfo?.hasMorePages) && {
        buttonProps: {
          text: t('load_more'),
          pendingText: t('load_more_pending'),
          autoIcon: true,
          outlined: true,
          isPending: walletTransactions?.pending,
          onClick: () =>
            setWalletTransactionsPage(
              (walletTransactions?.data?.paginatorInfo?.currentPage ?? 0) + 1
            ),
        },
      }),
    },
    backButton,
    currentTab,
    onTabChange: setCurrentTab,
    stripeConfirmModalProps,
    withTabs: user?.user_type !== UserType.Student,
  }

  useEffect(() => {
    return () => {
      // Clear wallet transactions
      dispatch(actions.preferences.walletTransactionsReset(undefined))
      dispatch(actions.preferences.bookingTransactionsReset(undefined))
      dispatch(actions.preferences.setWalletTransactionData([]))
      dispatch(actions.preferences.setBookingTransactionData([]))
    }
  }, [dispatch])

  useEffect(() => {
    if (user?.user_type !== UserType.Student) {
      dispatch(
        actions.preferences.walletTransactionsRequest({
          first: 50,
          page: walletTransactionsPage,
        })
      )
    }
  }, [dispatch, walletTransactionsPage, user?.user_type])

  useEffect(() => {
    if (user?.user_type !== UserType.Student) {
      dispatch(
        actions.preferences.bookingTransactionsRequest({
          first: 50,
          page: bookingTransactionsPage,
        })
      )
    }
  }, [dispatch, bookingTransactionsPage, user?.user_type])

  useEffect(() => {
    dispatch(actions.auth.reloadWalletRequest(null))
  }, [dispatch])

  return <PreferencesWalletTemplate {...preferencesProps} />
}

PreferencesWalletPage.restrictedUserTypes = [UserType.Guardian, UserType.Student]

export default PreferencesWalletPage
