import { Form, Formik, FormikHelpers } from 'formik'
import React, { FC } from 'react'
import * as Yup from 'yup'

import FormScrollToError from '../FormScrollToError'
import { FormSubmitProps } from '../FormSubmit'
import FormSubmitErrors, { FormSubmitErrorsProps } from '../FormSubmitErrors'
import { FormFieldProps } from '../../types/form'
import { getValidationSchema, renderField } from '../../helpers/FormHelpers'

import * as SC from './styled'

export type MaxStudentsFormValues = {
  size: string
}

export type MaxStudentsFormProps = {
  className?: string
  fieldsProps?: {
    size: FormFieldProps
  }
  errorTexts?: {
    required: string
  }
  initialValues: MaxStudentsFormValues
  submitButton: FormSubmitProps
  submitErrors?: FormSubmitErrorsProps['errors']
  onSubmit: (values: MaxStudentsFormValues, helpers: FormikHelpers<MaxStudentsFormValues>) => void
}

const MaxStudentsForm: FC<MaxStudentsFormProps> = (props) => {
  const {
    className,
    initialValues,
    submitButton,
    submitErrors,
    onSubmit,
    fieldsProps,
    errorTexts,
  } = props

  const quantityField = {
    name: 'size',
    Component: SC.QuantityField,
    validation: Yup.string().required(errorTexts?.required),
    required: true,
    isRadioMode: true,
  }

  const validationSchema = getValidationSchema([quantityField])

  return (
    <SC.MaxStudentsForm className={className}>
      <SC.Wrapper maxWidth="xl" disableGutters>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnChange={true}
          validateOnBlur={true}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {(formikProps) => (
            <Form noValidate>
              <FormScrollToError formikProps={formikProps} />
              {renderField(quantityField, formikProps, fieldsProps, 0)}
              <FormSubmitErrors errors={submitErrors} />
              <SC.SubmitButton {...submitButton} autoIcon />
            </Form>
          )}
        </Formik>
      </SC.Wrapper>
    </SC.MaxStudentsForm>
  )
}

export default MaxStudentsForm
