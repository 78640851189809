import styled from 'styled-components'

import ProfileFormC from '../../components/ProfilForm'
import BasicLink from '../../components/BasicLink'
import SponsorshipFormC from '../../components/SponsorshipForm'
import ImageTextListC from '../../components/ImageTextList'

export const Container = styled.div`
  display: flex;
`

export const Wrapper = styled.div`
  padding-bottom: 2rem;
`

export const ProfileForm = styled(ProfileFormC)`
  margin: 2.4rem 0 0 0;
  padding: 0 1.6rem;
`

export const SponsorshipForm = styled(SponsorshipFormC)`
  ${(props) => props.theme.breakpoints.up('md')} {
    max-width: 48rem;
    margin: 1rem 1.6rem;
    padding: 1.6rem 1.6rem 3.2rem 1.6rem;
  }
`

export const DisconnectCta = styled(BasicLink)`
  display: flex;
  margin: 2rem 0 0 1.6rem;
  text-transform: uppercase;
  ${(props) => ({ ...props.theme.typography.smallStrong })}
  text-decoration: none;
  cursor: pointer;
  ${(props) => props.theme.breakpoints.up('md')} {
    display: none;
  }
`

export const DeleteCta = styled(BasicLink)`
  margin: 2rem 0 0 1.6rem;
  display: flex;
  text-transform: uppercase;
  ${(props) => ({ ...props.theme.typography.smallStrong })}
  text-decoration: none;
  cursor: pointer;
  & path {
    fill: ${(props) => props.theme.palette.colors.tomato} !important;
  }
  ${(props) => props.theme.breakpoints.up('md')} {
    margin: 2rem 0 0 3.2rem;
  }
`

export const AddChildButton = styled(BasicLink)`
  display: flex;
  ${(props) => ({ ...props.theme.typography.smallStrong })}
  text-decoration: none;
  color: ${(props) => props.theme.palette.colors.freeSpeechBlue};
  cursor: pointer;
  margin-bottom: 1.6rem;
  background-color: ${(props) => props.theme.palette.colors.pureWhite};
  border-radius: 0.4rem;
  padding: 1rem;
  transition: color 0.3s, background-color 0.3s;
  & svg {
    color: ${(props) => props.theme.palette.colors.freeSpeechBlue};
    transition: color 0.3s;
  }
  &:hover {
    background-color: ${(props) => props.theme.palette.colors.freeSpeechBlue};
    color: ${(props) => props.theme.palette.colors.pureWhite};
    & svg {
      color: ${(props) => props.theme.palette.colors.pureWhite};
    }
  }
`

export const FamilyContainer = styled.div`
  padding: 1.6rem;
`

export const ImageTextList = styled(ImageTextListC)`
  width: 100%;
  ${(props) => props.theme.breakpoints.up('md')} {
    font-size: 4rem;
    & > div > div {
      max-width: 35rem;
    }
  }
  & > div > span > div:hover {
    border-color: ${(props) => props.theme.palette.colors.freeSpeechBlue};
  }
`

export const Title = styled.h3`
  ${(props) => ({ ...props.theme.typography.h3 })}
  color: ${(props) => props.theme.palette.colors.midnightBlue};
  margin: 0rem 0 0.5rem 0;
`

export const LinkCodeBox = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.palette.colors.pureWhite};
  border-radius: 0.8rem;
  padding: 1.6rem;
  margin-top: 2rem;
`

export const LinkCodeTitle = styled.span`
  ${(props) => ({ ...props.theme.typography.h4 })}
  color: ${(props) => props.theme.palette.colors.midnightBlue};
  padding-bottom: 1rem;
`

export const LinkCodeText = styled.span`
  ${(props) => ({ ...props.theme.typography.smallRegular })}
  color: ${(props) => props.theme.palette.colors.midnightBlue};
`

export const LinkCode = styled.span`
  ${(props) => ({ ...props.theme.typography.h3 })}
  color: ${(props) => props.theme.palette.colors.cornflowerBlue};
  margin-top: 1rem;
`
