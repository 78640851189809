import styled from 'styled-components'

import PageTitle from '../../components/PageTitle'
import PageBackButton from '../../components/PageBackButton'
import MaxStudentsForm from '../../components/MaxStudentsForm'
import ImageTextListC from '../../components/ImageTextList'
import SearchC from '../../components/Search'

export const Content = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`

export const Title = styled(PageTitle)``
export const BackButton = styled(PageBackButton)``
export const MaxStudents = styled(MaxStudentsForm)`
  padding: 0 1.6rem 1.6rem;
  max-width: 47rem;
`

export const H2 = styled.div`
  ${(props) => ({ ...props.theme.typography.h5 })};
  color: ${(props) => props.theme.palette.colors.midnightBlue};
  padding: 1.6rem;
`

export const ImageTextList = styled(ImageTextListC)`
  padding: 0 1.6rem;
  flex-grow: 1;
`

export const Search = styled(SearchC)`
  margin-top: 1.6rem;
  padding: 1rem 0;
`
