import React, { FC, useState } from 'react'
import { KeyboardDatePickerProps } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { fr } from 'date-fns/locale'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'

import * as SC from './styled'

export type DatePickerProps = Omit<KeyboardDatePickerProps, 'onChange' | 'value'> & {
  className?: string
  value: string
  label: string
  placeholder?: string
  onChange: (name: string, value: string) => void
  isLabelExternal?: boolean
}

const DatePicker: FC<DatePickerProps> = ({
  className,
  label,
  value,
  placeholder,
  onChange,
  isLabelExternal,
  name,
  ...props
}) => {
  const [open, setOpen] = useState(false)

  const handleChange = (date: MaterialUiPickersDate | null) => {
    onChange?.(name ?? '', date?.toISOString() ?? '')
  }

  return (
    <SC.DatePicker className={className}>
      {isLabelExternal && label && (
        <SC.Label required={props.required} error={props.error}>
          {label}
        </SC.Label>
      )}
      <SC.MuiPickersProvider utils={DateFnsUtils} locale={fr}>
        <SC.MuiKeyboardDatePicker
          autoOk
          disableToolbar
          variant="inline"
          inputVariant="outlined"
          format="dd/MM/yyyy"
          margin="normal"
          id="date-picker-inline"
          label={isLabelExternal ? '' : label}
          value={value ? new Date(value) : null}
          onChange={handleChange}
          placeholder={placeholder}
          onClick={() => setOpen(true)}
          onClose={() => setOpen(false)}
          open={open}
          name={name}
          {...props}
        />
      </SC.MuiPickersProvider>
    </SC.DatePicker>
  )
}

export default DatePicker
