import dayjs from 'dayjs'

import {
  Course,
  CourseRequest,
  Student,
  User,
  UserType,
  WalletModel,
  WalletTransaction,
  WalletTransactionType,
} from '../generated/graphql'
import { WalletTransactionTypeTranslation } from '../graphql/enums/WalletTransactionType'
import { MoneyCardProps } from '../components/MoneyCard'
import { courseTypeTranslation } from '../graphql/enums/CourseType'

import { getCourseDate } from './courseTransformers'

export const walletTransactionToCardProps = (
  transaction: WalletTransaction,
  t: any,
  user?: User
): MoneyCardProps => {
  const student: Student | undefined =
    user?.user_type === UserType.Guardian
      ? user?.guardian?.children?.find((student) => student?.id === transaction?.student?.id) ??
        (transaction.type === WalletTransactionType.RequestCourse
          ? user?.guardian?.children?.find(
              (student) => student?.id === (transaction?.model as CourseRequest)?.student?.id
            )
          : user?.guardian?.children?.find((student) =>
              (transaction?.model as Course)?.requests
                ?.map((r) => r?.student?.id)
                .includes(student?.id)
            ))
      : undefined

  const courseModel = (model: Course) => ({
    level: model?.lesson?.theme?.discipline?.name,
    type: courseTypeTranslation(t, model?.type),
    numberOfStudentText: t('course_card_party', {
      count: model?.participants,
    }),
  })

  const courseRequestModel = (model: CourseRequest) => ({
    level: model?.lesson?.theme?.discipline?.name,
    type: courseTypeTranslation(t, model?.type),
    numberOfStudentText: t('course_card_max_participants_value', {
      count: model?.maximum_participants,
    }),
  })

  const transformModel = (model: WalletModel) =>
    model?.__typename === 'CourseRequest'
      ? courseRequestModel(model as CourseRequest)
      : model?.__typename === 'Course'
      ? courseModel(model as Course)
      : null

  return {
    date: t('wallet_transaction_date', {
      date: dayjs(transaction.created_at, 'YYYY-MM-DD HH:mm:ss').toDate(),
    }),
    title: WalletTransactionTypeTranslation(t, transaction.type),
    money: t('wallet_transaction_amount', { amount: transaction.amount / 100 }),
    ...(transaction.type === WalletTransactionType.AddCredits
      ? {
          type: t('wallet_transaction_card'),
        }
      : transaction.type === WalletTransactionType.RequestCourse
      ? {
          ...(student && {
            name: student?.first_name ?? '',
            image: {
              alt: 'LiberteClasse - profil',
              src: student?.avatar ?? '',
            },
          }),
          ...transformModel(transaction.model as WalletModel),
        }
      : transaction.type === WalletTransactionType.PayCourse
      ? {
          date: t('wallet_transaction_date', {
            date: getCourseDate(transaction.model as Course),
          }),
          ...(student && {
            name: student?.first_name ?? '',
            image: {
              alt: 'LiberteClasse - profil',
              src: student?.avatar ?? '',
            },
          }),
          ...transformModel(transaction.model as WalletModel),
        }
      : transaction.type === WalletTransactionType.CoursePayment
      ? {
          date: t('wallet_transaction_date', {
            date: getCourseDate(transaction.model as Course),
          }),
          ...transformModel(transaction.model as WalletModel),
        }
      : transaction.type === WalletTransactionType.CancelRequest
      ? {}
      : transaction.type === WalletTransactionType.RefundRest
      ? {}
      : {}),
  }
}
