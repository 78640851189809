import React, { FC, useMemo } from 'react'
import { Form, Formik, FormikHelpers } from 'formik'
import * as Yup from 'yup'

import { getValidationSchema, renderField } from '../../helpers/FormHelpers'
import { FormFieldConfig, FormFieldProps } from '../../types/form'
import { FormSubmitProps } from '../FormSubmit'
import FormSubmitErrors, { FormSubmitErrorsProps } from '../FormSubmitErrors'
import FormScrollToError from '../FormScrollToError'
import { Icons } from '../Icon/types'
import { light } from '../../theme/palette'

import * as SC from './styled'

export type WalletWithdrawalFormValues = {
  amount: string
  iban: string
}

export type WalletWithdrawalFormProps = {
  className?: string
  text?: string
  fieldsProps?: {
    amount?: FormFieldProps
    iban?: FormFieldProps
  }
  errorTexts?: {
    required: string
    iban: string
    max: string
  }
  initialValues: WalletWithdrawalFormValues
  submitErrors?: FormSubmitErrorsProps['errors']
  skipButton?: FormSubmitProps
  submitButton: FormSubmitProps
  onSubmit?: (
    values: WalletWithdrawalFormValues,
    formikHelpers: FormikHelpers<WalletWithdrawalFormValues>
  ) => void
}

const WalletWithdrawalForm: FC<WalletWithdrawalFormProps> = (props) => {
  const {
    className,
    text,
    fieldsProps,
    errorTexts,
    initialValues,
    submitErrors,
    skipButton,
    submitButton,
    onSubmit = (_values, _formikHelpers) => null,
  } = props

  const amountField = useMemo(
    () => ({
      name: 'amount',
      Component: SC.Number,
      validation: Yup.number()
        .max(fieldsProps?.amount?.maxValue ?? 0, errorTexts?.max)
        .required(errorTexts?.required),
      required: true,
      icon: Icons.euro,
      iconColor: light.colors.cornflowerBlue,
    }),
    [errorTexts?.max, errorTexts?.required, fieldsProps?.amount?.maxValue]
  )

  const ibanField = useMemo(
    () => ({
      name: 'iban',
      Component: SC.Iban,
      validation: Yup.string().iban(errorTexts?.iban).required(errorTexts?.required),
      required: true,
    }),
    [errorTexts]
  )

  const fields: FormFieldConfig[] = useMemo(() => [amountField, ibanField], [
    amountField,
    ibanField,
  ])

  const validationSchema = getValidationSchema(fields)

  return (
    <SC.Attach className={className}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnChange={true}
        validateOnBlur={true}
        onSubmit={onSubmit}
      >
        {(formikProps) => (
          <Form noValidate>
            <FormScrollToError formikProps={formikProps} />
            <SC.Fields>
              {fields?.map((field, key) => renderField(field, formikProps, fieldsProps, key))}
            </SC.Fields>
            {text && <SC.Text>{text}</SC.Text>}
            <FormSubmitErrors errors={submitErrors} />
            <SC.Buttons>
              {skipButton && <SC.SkipButton {...skipButton} type={'button'} autoIcon outlined />}
              <SC.SubmitButton {...submitButton} autoIcon />
            </SC.Buttons>
          </Form>
        )}
      </Formik>
    </SC.Attach>
  )
}

export default WalletWithdrawalForm
