import React, { FC, useState } from 'react'

import RatingCustom, { RatingCustomProps } from '../RatingCustom'
import { Icons } from '../Icon/types'
import InfoModal from '../modals/InfoModal'

import * as SC from './styled'

export type RatingModalityProps = {
  className?: string
  title: string
  description?: string
  icon: Icons
  rate: RatingCustomProps
}

const RatingModality: FC<RatingModalityProps> = (props) => {
  const { className, title, icon, rate, description } = props

  const [modalOpen, setModalOpen] = useState(false)

  return (
    <SC.RatingModality className={className}>
      <SC.Wrapper disableGutters maxWidth={false}>
        <SC.ModalityHeader>
          <SC.ModalityLabel>{title}</SC.ModalityLabel>
          {description && (
            <>
              <SC.ModalityInfo icon={icon} onClick={() => setModalOpen(true)} />
              <InfoModal
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                title={title}
                text={description}
              />
            </>
          )}
        </SC.ModalityHeader>
        <RatingCustom {...rate} />
      </SC.Wrapper>
    </SC.RatingModality>
  )
}

export default RatingModality
