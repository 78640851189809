import { Container } from '@material-ui/core'
import styled from 'styled-components'

import ToggleButtonGroupC from '../form/ToggleButtonGroup'
import DatePickerC from '../form/DatePicker'
import Number from '../form/Number'
import FormSubmit from '../FormSubmit'

export const MarketCourseForm = styled.div``

export const Wrapper = styled(Container)`
  width: 100%;
`

export const Text = styled.p`
  ${(props) => ({ ...props.theme.typography.smallRegular })}
  color: ${(props) => props.theme.palette.colors.chambray};
`

export const ToggleButtonGroup = styled(ToggleButtonGroupC)`
  display: none;
  & > div {
    width: auto;
    margin: -0.8rem;
    display: flex;
    & > button {
      flex-grow: 1;
      max-width: 100%;
      width: calc(50% - 1.6rem);
      margin: 0.8rem;
      & .MuiToggleButton-label {
        text-transform: initial;
      }
    }
  }
  margin-bottom: 2rem;
`

export const DatePicker = styled(DatePickerC)`
  margin-bottom: 2rem;
`

export const PriceField = styled(Number)`
  margin-bottom: 2rem;
`

export const SubmitButton = styled(FormSubmit)``
