import gql from 'graphql-tag'

import { Discipline } from '../../../../generated/graphql'
import DisciplineFragment from '../../../fragments/DisciplineFragment'

const disciplines = {
  query: gql`
    query disciplines {
      disciplines {
        ...DisciplineFragment
      }
    }
    ${DisciplineFragment}
  `,
  transformer: (response: any): Discipline[] => {
    return response.disciplines
  },
}

export default disciplines
