import React, { FC } from 'react'
import { Form, Formik, FormikHelpers } from 'formik'
import * as Yup from 'yup'

import { FormFieldConfig, FormFieldProps } from '../../types/form'
import FormSubmitErrors, { FormSubmitErrorsProps } from '../FormSubmitErrors'
import { FormSubmitProps } from '../FormSubmit'
import { getValidationSchema, renderField } from '../../helpers/FormHelpers'

import * as SC from './styled'

export type SponsorshipFormValues = {
  email: string
}

export type SponsorshipFormProps = {
  className?: string
  title: string
  text?: string
  initialValues: SponsorshipFormValues
  fieldsProps?: {
    email: FormFieldProps
  }
  errorTexts?: {
    required: string
    email: string
  }
  submitErrors?: FormSubmitErrorsProps['errors']
  submitButton: FormSubmitProps
  onSubmit?: (
    values: SponsorshipFormValues,
    formikHelpers: FormikHelpers<SponsorshipFormValues>
  ) => void
}

const SponsorshipForm: FC<SponsorshipFormProps> = (props) => {
  const {
    className,
    title,
    text,
    fieldsProps,
    initialValues,
    errorTexts,
    submitErrors,
    submitButton,
    onSubmit = (_v: SponsorshipFormValues) => null,
  } = props

  const fields: FormFieldConfig[] = [
    {
      name: 'email',
      Component: SC.TextField,
      validation: Yup.string().email(errorTexts?.email).required(errorTexts?.required),
      required: true,
    },
  ]

  const validationSchema = getValidationSchema(fields)

  return (
    <SC.Container className={className}>
      <SC.Wrapper>
        <SC.Title>{title}</SC.Title>
        {text && <SC.Text>{text}</SC.Text>}
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnChange={true}
          validateOnBlur={true}
          onSubmit={onSubmit}
        >
          {(formikProps) => (
            <Form noValidate>
              <SC.Fields>
                {fields.map((field, index) => renderField(field, formikProps, fieldsProps, index))}
              </SC.Fields>
              <FormSubmitErrors errors={submitErrors} />
              <SC.SubmitButton {...submitButton} autoIcon iconSide={'right'} />
            </Form>
          )}
        </Formik>
      </SC.Wrapper>
    </SC.Container>
  )
}

export default SponsorshipForm
