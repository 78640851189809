import styled from 'styled-components'

import PageTitle from '../../components/PageTitle'
import PageBackButton from '../../components/PageBackButton'
import BasicLink from '../../components/BasicLink'
import { Text } from '../OldTemplates/SearchParent/stories/styled'
import CardListC from '../../components/CardList'
import ActionButton from '../../components/ActionButton'
import Number from '../../components/form/Number'

export const Content = styled.div`
  width: 100%;
`

export const Subtitle = styled.h6`
  ${(props) => ({ ...props.theme.typography.h6 })}
  color: ${(props) => props.theme.palette.colors.neonCarrot};
  padding: 0 1.6rem;
  margin: 2rem 0 0.4rem 0;
`

export const Title = styled(PageTitle)`
  color: ${(props) => props.theme.palette.colors.midnightBlue};
  padding-bottom: 0;
  margin-top: 0;
  padding-top: 0;
`

export const BackButton = styled(PageBackButton)``

export const Title2 = styled.h4`
  ${(props) => ({ ...props.theme.typography.h4 })}
  color: ${(props) => props.theme.palette.colors.freeSpeechBlue};
  padding: 1.6rem;
  margin: 0;
`

export const EmptyText = styled.h4`
  ${(props) => ({ ...props.theme.typography.h4 })}
  color: ${(props) => props.theme.palette.colors.midnightBlue};
  padding: 1.6rem;
  margin: 0;
`

export const CardList = styled(CardListC)`
  margin: 1.6rem 1.6rem 0 1.6rem;
`
export const Text5 = styled(Text)`
  color: ${(props) => props.theme.palette.colors.chambray};
  padding: 0 1.6rem;
`

export const Button = styled(ActionButton)`
  width: 100%;
`

export const Submit = styled(ActionButton)`
  width: 100%;
  margin-bottom: 1.6rem;
`

export const Cta = styled(BasicLink)`
  margin: 0rem 0 3rem 1.6rem;
  display: flex;
  ${(props) => ({ ...props.theme.typography.smallBold })}
  text-decoration: none;
  color: ${(props) => props.theme.palette.colors.freeSpeechBlue};
  cursor: pointer;
  text-transform: uppercase;
`

export const ButtonBox = styled.div`
  margin: 3rem 1.6rem;
  max-width: 40rem;
`
export const BoxImage = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  ${(props) => props.theme.breakpoints.up('md')} {
    justify-content: flex-start;
  }
`

export const Image = styled.img`
  width: 30rem;
  height: auto;
  ${(props) => props.theme.breakpoints.up('md')} {
    width: 51rem;
  }
`

export const Select = styled(Number)`
  padding: 0 1.6rem;
  & .MuiFormControl-root {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  & .MuiInputBase-root {
    width: 8rem;
    margin-right: 1.6rem;
  }
`

export const Text6 = styled(Text)`
  ${(props) => ({ ...props.theme.typography.mediumRegular })}
  color: ${(props) => props.theme.palette.colors.grey};
  padding: 2.4rem 1.6rem;
`
