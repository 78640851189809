import gql from 'graphql-tag'

import { Course, CourseRequest } from '../../../../generated/graphql'
import CourseFragment from '../../../fragments/CourseFragment'
import CourseRequestFragment from '../../../fragments/CourseRequestFragment'

export type DashboardCoursesResult = {
  today?: Course
  future: { data: Course[]; total: number }
  past: { data: Course[]; total: number }
  pendingCourses: { data: Course[]; total: number }
  pendingCourseRequests: { data: CourseRequest[]; total: number }
}

const dashboardCourses = {
  query: gql`
    query dashboardCourses($student: ID) {
      todayCourse(student: $student) {
        ...CourseFragment
      }
      futureCourses(first: 2, student: $student) {
        data {
          ...CourseFragment
        }
        paginatorInfo {
          total
        }
      }
      pendingCourses(first: 1, student: $student) {
        data {
          ...CourseFragment
        }
        paginatorInfo {
          total
        }
      }
      pendingCourseRequests(first: 1, student: $student) {
        data {
          ...CourseRequestFragment
        }
        paginatorInfo {
          total
        }
      }
      pastCourses(first: 1, student: $student) {
        data {
          ...CourseFragment
        }
        paginatorInfo {
          total
        }
      }
    }
    ${CourseFragment}
    ${CourseRequestFragment}
  `,
  transformer: (response: any): DashboardCoursesResult => {
    return {
      today: response?.todayCourse,
      future: {
        data: response?.futureCourses?.data?.filter(
          (c: Course) => c.id !== response.todayCourse?.id
        ),
        total: response?.futureCourses?.paginatorInfo?.total,
      },
      past: {
        data: response?.pastCourses?.data,
        total: response?.pastCourses?.paginatorInfo?.total,
      },
      pendingCourses: {
        data: response?.pendingCourses?.data,
        total: response?.pendingCourses?.paginatorInfo?.total,
      },
      pendingCourseRequests: {
        data: response?.pendingCourseRequests?.data,
        total: response?.pendingCourseRequests?.paginatorInfo?.total,
      },
    }
  },
}

export default dashboardCourses
