import styled from 'styled-components'
import { Container } from '@material-ui/core'

import ImageTextList from '../ImageTextList'
import FormSubmit from '../FormSubmit'

export const SearchRequestForm = styled.div`
  display: flex;
  justify-content: center;
  ${(props) => props.theme.breakpoints.up('md')} {
    justify-content: flex-start;
  }
`

export const Wrapper = styled(Container)`
  ${(props) => props.theme.breakpoints.up('md')} {
    margin: 0;
  }
`

export const List = styled(ImageTextList)``

export const Field = styled.div``

export const FieldTitle = styled.h4`
  color: ${(props) => props.theme.palette.colors.midnightBlue};
  ${(props) => ({ ...props.theme.typography.h4 })}
  margin: 2.4rem 0;
`

export const SubmitButton = styled(FormSubmit)`
  margin: 0 0 2rem 0;
  max-width: 47rem;
`
