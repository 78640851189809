import gql from 'graphql-tag'

export default gql`
  fragment WalletTransactionFragment on WalletTransaction {
    type
    amount
    created_at
    student {
      id
      avatar
      full_name
    }
    model {
      ... on Course {
        id
        date
        slot
        type
        participants
        lesson {
          theme {
            discipline {
              name
            }
            grade {
              name
            }
          }
        }
        requests {
          student {
            id
          }
        }
      }
      ... on CourseRequest {
        id
        created_at
        maximum_price
        type
        maximum_participants
        requestLesson: lesson {
          theme {
            discipline {
              name
            }
            grade {
              name
            }
          }
        }
        student {
          id
        }
      }
      ... on Payment {
        id
        status
      }
    }
  }
`
