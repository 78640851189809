import { Container, Divider } from '@material-ui/core'
import styled from 'styled-components'

import RatingCustom from '../RatingCustom'
import BasicProfilC from '../BasicProfil'
import RatingModalitiesC from '../RatingModalities'
import CheckboxC from '../form/Checkbox'
import TextFieldLimitedChars from '../form/TextFieldLimitedChars'

export const RatingWithFields = styled.div``

export const Wrapper = styled(Container)``

export const Box = styled.div`
  position: relative;
  padding: 1rem;
  background: ${(props) => props.theme.palette.colors.pureWhite};
  border: 1px solid ${(props) => props.theme.palette.colors.ghostWhite};
  border-radius: 0.8rem;
  margin-bottom: 1.6rem;
`

export const Profile = styled.div`
  display: flex;
  align-items: center;
`

export const Image = styled.img`
  width: 6.4rem;
  height: auto;
  border-radius: 50%;
`

export const ProfileInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 2rem;
`

export const Name = styled.span`
  ${(props) => ({ ...props.theme.typography.h4 })}
  color: ${(props) => props.theme.palette.colors.freeSpeechBlue};
`

export const Level = styled.span`
  ${(props) => ({ ...props.theme.typography.h7 })}
  color: ${(props) => props.theme.palette.colors.cornflowerBlue};
`

export const GlobalRate = styled.div``

export const Title = styled.span`
  ${(props) => ({ ...props.theme.typography.h4 })}
  color: ${(props) => props.theme.palette.colors.midnightBlue};
  margin-bottom: 1rem;
`

export const Rate = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

export const Rating = styled(RatingCustom)`
  font-size: 4.5rem;
`

export const Value = styled.span`
  ${(props) => ({ ...props.theme.typography.h4 })}
  color: ${(props) => props.theme.palette.colors.neonCarrot};
`

export const Muidivider = styled(Divider)`
  margin-top: 2rem;
  width: 100%;
  margin-bottom: 1.8rem;
`

export const Modality = styled.div`
  display: flex;
  flex-direction: column;
`

export const ModalityItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const Commentary = styled.div``

export const CommentaryBox = styled.div`
  display: flex;
  flex-direction: column;
`

export const CommentaryTitle = styled.span`
  ${(props) => ({ ...props.theme.typography.h5 })}
  color: ${(props) => props.theme.palette.colors.freeSpeechBlue};
`

export const CommentaryField = styled(TextFieldLimitedChars)`
  margin-top: 1rem;
  & .MuiFormHelperText-contained {
    text-align: end;
    ${(props) => ({ ...props.theme.typography.smallRegular })}
    color: ${(props) => props.theme.palette.colors.grey};
    background-color: ${(props) => props.theme.palette.colors.pureWhite};
  }
`

export const CheckboxWrapper = styled.div`
  display: flex;
`

export const RecurrentCheckbox = styled.div`
  display: flex;
  background-color: ${(props) => props.theme.palette.colors.kournikovaLight};
  border-radius: 0.8rem;
  padding: 1rem 0;
`

export const Checkbox = styled(CheckboxC)``

export const CommentaryText = styled.span`
  ${(props) => ({ ...props.theme.typography.smallRegular })}
  color: ${(props) => props.theme.palette.colors.chambray};
`

export const BasicProfil = styled(BasicProfilC)``

export const RatingModalities = styled(RatingModalitiesC)`
  margin-bottom: 1.6rem;
`
