import { all, call, put, SagaReturnType, select, takeLatest, takeLeading } from 'redux-saga/effects'
import dayjs from 'dayjs'

import { actions, selectors } from '..'
import ApiSagas from '../api/sagas'
import { ApiResponse } from '../api/types/state'
import teacherAvailabilitiesOfMonth from '../../graphql/services/teacherAvailability/queries/teacherAvailabilitiesOfMonth'
import { ServiceRequestAction } from '../../helpers/ReduxHelpers'
import {
  AvailabilitySlot,
  CreateTeacherAvailabilityRecurrenceMutationVariables,
  MutationAddFamilyMemberArgs,
  MutationDeleteFamilyMemberArgs,
  MutationUpdateFamilyMemberArgs,
  MutationUpdatePayoutScheduleIntervalArgs,
  MutationUpdateTeacherAvailabilityOfDayArgs,
  QueryBookingTransactionsArgs,
  QueryCoursesWithMaterialArgs,
  QueryCoursesWithMaterialOrderByColumn,
  QueryTeacherAvailabilitiesOfMonthArgs,
  QueryWalletTransactionsArgs,
  RecurrenceDay,
  TeacherDisciplineInput,
  UpdateTeacherAvailabilityRecurrenceMutationVariables,
  User,
} from '../../generated/graphql'
import updateTeacherAvailabilityOfDay from '../../graphql/services/teacherAvailability/mutations/updateTeacherAvailabilityOfDay'
import { teacherAvailabilityDateToISOString } from '../../graphql/services/teacherAvailability/transformers/TeacherAvailabilityTransform'
import createTeacherAvailabilityRecurrence from '../../graphql/services/teacherAvailability/mutations/createTeacherAvailabilityRecurrence'
import teacherAvailabilitiesRecurrences from '../../graphql/services/teacherAvailability/queries/teacherAvailabilitiesRecurrences'
import { AvailabilityRecurrenceFormValues } from '../../components/AvailabilityRecurrenceForm'
import updateTeacherAvailabilityRecurrence from '../../graphql/services/teacherAvailability/mutations/updateTeacherAvailabilityRecurrence'
import deleteTeacherAvailabilityRecurrence from '../../graphql/services/teacherAvailability/mutations/deleteTeacherAvailabilityRecurrence'
import teacherRecurrenceById from '../../graphql/services/teacherAvailability/queries/teacherRecurrenceById'
import createCreditPaymentIntent from '../../graphql/services/payment/mutations/createCreditPaymentIntent'
import walletTransactions from '../../graphql/services/wallet/queries/walletTransactions'
import { MaxStudentsFormValues } from '../../components/MaxStudentsForm'
import updateTeacherMaximumParticipants from '../../graphql/services/teacher/mutations/updateTeacherMaximumParticipants'
import updateTeacherDisciplines from '../../graphql/services/teacher/mutations/updateTeacherDisciplines'
import { LevelPickersFormValues } from '../../components/LevelPickersForm'
import { PricesChoiceFormValues, PricingType } from '../../components/PricesChoiceForm'
import updateTeacherPrice from '../../graphql/services/teacher/mutations/updateTeacherPrice'
import { AddChildFormValues } from '../../components/AddChildForm'
import addFamilyMember from '../../graphql/services/guardian/mutations/addFamilyMember'
import updateFamilyMember from '../../graphql/services/guardian/mutations/updateFamilyMember'
import deleteFamilyMember from '../../graphql/services/guardian/mutations/deleteFamilyMember'
import bookingTransactions from '../../graphql/services/wallet/queries/bookingTransactions'
import updatePayoutScheduleInterval from '../../graphql/services/teacher/mutations/updatePayoutScheduleInterval'
import requestRefund from '../../graphql/services/wallet/mutations/requestRefund'
import { WalletWithdrawalFormValues } from '../../components/WalletWithdrawalForm'
import coursesWithMaterial from '../../graphql/services/course/queries/coursesWithMaterial'

export default class PreferencesSagas {
  static *onTeacherAvailabilitiesOfMonth({
    payload,
  }: ServiceRequestAction<QueryTeacherAvailabilitiesOfMonthArgs>) {
    const rs: ApiResponse<typeof teacherAvailabilitiesOfMonth> = yield call(
      ApiSagas.query,
      teacherAvailabilitiesOfMonth,
      payload
    )

    if (!rs?.errors) {
      yield put(actions.preferences.teacherAvailabilitiesOfMonthSuccess(rs?.data))
    } else {
      yield put(actions.preferences.teacherAvailabilitiesOfMonthError(rs.errors))
    }
  }

  static *onUpdateTeacherAvailabilitiesOfDay({
    payload,
  }: ServiceRequestAction<MutationUpdateTeacherAvailabilityOfDayArgs>) {
    const rs: ApiResponse<typeof updateTeacherAvailabilityOfDay> = yield call(
      ApiSagas.mutate,
      updateTeacherAvailabilityOfDay,
      payload
    )

    if (!rs?.errors) {
      const availabilitiesSelector: SagaReturnType<
        typeof selectors.preferences.teacherAvailabilitiesOfMonth
      > = yield select(selectors.preferences.teacherAvailabilitiesOfMonth)

      const availabilities = Array.from(availabilitiesSelector?.data || [])
      rs?.data?.forEach((availability) => {
        const index = availabilities.findIndex((e) => e.date === availability?.date)
        if (index !== -1) {
          availabilities.splice(index, 1, availability)
        } else {
          availabilities.push(availability)
        }
      })
      if (!payload?.slots?.length) {
        const dateString = teacherAvailabilityDateToISOString(payload?.date)
        const index = availabilities.findIndex((e) => e.date === dateString)
        if (index !== -1) {
          console.log('found index', index)
          availabilities.splice(index, 1)
        }
      }
      yield put(actions.preferences.teacherAvailabilitiesOfMonthSuccess(availabilities))

      yield put(actions.preferences.updateTeacherAvailabilityOfDaySuccess(rs?.data))
    } else {
      yield put(actions.preferences.updateTeacherAvailabilityOfDayError(rs.errors))
    }
  }

  static *onTeacherAvailabilityRecurrences() {
    const rs: ApiResponse<typeof teacherAvailabilitiesRecurrences> = yield call(
      ApiSagas.query,
      teacherAvailabilitiesRecurrences,
      {}
    )

    if (!rs?.errors) {
      yield put(actions.preferences.teacherAvailabilityRecurrencesSuccess(rs?.data))
    } else {
      yield put(actions.preferences.teacherAvailabilityRecurrencesError(rs.errors))
    }
  }

  static *onTeacherAvailabilityRecurrenceById({ payload }: ServiceRequestAction<string>) {
    const rs: ApiResponse<typeof teacherRecurrenceById> = yield call(
      ApiSagas.query,
      teacherRecurrenceById,
      { id: payload }
    )

    if (!rs?.errors) {
      yield put(actions.preferences.teacherAvailabilityRecurrenceSuccess(rs?.data))
    } else {
      yield put(actions.preferences.teacherAvailabilityRecurrenceError(rs.errors))
    }
  }

  static *onCreateTeacherAvailabilityRecurrence({
    payload,
  }: ServiceRequestAction<AvailabilityRecurrenceFormValues>) {
    const input: CreateTeacherAvailabilityRecurrenceMutationVariables = {
      start_at: dayjs(payload?.start).format('YYYY-MM-DD'),
      end_at: dayjs(payload?.end).format('YYYY-MM-DD'),
      slots: payload?.hours as AvailabilitySlot[],
      days: payload?.days as RecurrenceDay[],
    }
    const rs: ApiResponse<typeof createTeacherAvailabilityRecurrence> = yield call(
      ApiSagas.mutate,
      createTeacherAvailabilityRecurrence,
      input
    )

    if (!rs?.errors) {
      yield put(actions.preferences.createTeacherAvailabilityRecurrenceSuccess(rs?.data))
    } else {
      yield put(actions.preferences.createTeacherAvailabilityRecurrenceError(rs.errors))
    }
  }

  static *onUpdateTeacherAvailabilityRecurrence({
    payload,
  }: ServiceRequestAction<AvailabilityRecurrenceFormValues & { id: string }>) {
    const input: UpdateTeacherAvailabilityRecurrenceMutationVariables = {
      id: payload?.id ?? '',
      start_at: dayjs(payload?.start).format('YYYY-MM-DD'),
      end_at: dayjs(payload?.end).format('YYYY-MM-DD'),
      slots: payload?.hours as AvailabilitySlot[],
      days: payload?.days as RecurrenceDay[],
    }
    const rs: ApiResponse<typeof updateTeacherAvailabilityRecurrence> = yield call(
      ApiSagas.mutate,
      updateTeacherAvailabilityRecurrence,
      input
    )

    if (!rs?.errors) {
      yield put(actions.preferences.updateTeacherAvailabilityRecurrenceSuccess(rs?.data))
    } else {
      yield put(actions.preferences.updateTeacherAvailabilityRecurrenceError(rs.errors))
    }
  }

  static *onDeleteTeacherAvailabilityRecurrence({ payload }: ServiceRequestAction<string>) {
    const rs: ApiResponse<typeof deleteTeacherAvailabilityRecurrence> = yield call(
      ApiSagas.mutate,
      deleteTeacherAvailabilityRecurrence,
      { id: payload }
    )

    const recurrencesSelector: SagaReturnType<
      typeof selectors.preferences.teacherAvailabilityRecurrences
    > = yield select(selectors.preferences.teacherAvailabilityRecurrences)
    const recurrences = Array.from(recurrencesSelector?.data || [])
    const index = recurrences?.findIndex((rec) => rec.id === payload)
    if (index !== -1) {
      recurrences.splice(index, 1)
      yield put(actions.preferences.teacherAvailabilityRecurrencesSuccess(recurrences))
    }

    if (!rs?.errors) {
      yield put(actions.preferences.deleteTeacherAvailabilityRecurrenceSuccess(rs?.data))
    } else {
      yield put(actions.preferences.deleteTeacherAvailabilityRecurrenceError(rs.errors))
    }
  }

  static *onCreateCreditPaymentIntent({ payload }: ServiceRequestAction<string>) {
    const rs: ApiResponse<typeof createCreditPaymentIntent> = yield call(
      ApiSagas.mutate,
      createCreditPaymentIntent,
      { amount: parseFloat(payload ?? '') * 100 }
    )

    if (!rs?.errors) {
      yield put(actions.preferences.createCreditPaymentIntentSuccess(rs?.data))
    } else {
      yield put(actions.preferences.createCreditPaymentIntentError(rs.errors))
    }
  }

  static *onWithdrawalRequest({ payload }: ServiceRequestAction<WalletWithdrawalFormValues>) {
    const rs: ApiResponse<typeof requestRefund> = yield call(ApiSagas.mutate, requestRefund, {
      amount: parseFloat(payload?.amount ?? '') * 100,
      iban: payload?.iban ?? '',
    })

    if (!rs?.errors) {
      yield put(actions.preferences.withdrawalSuccess(rs?.data))
    } else {
      yield put(actions.preferences.withdrawalError(rs.errors))
    }
  }

  static *onWalletTransactionsRequest({
    payload,
  }: ServiceRequestAction<QueryWalletTransactionsArgs>) {
    const rs: ApiResponse<typeof walletTransactions> = yield call(
      ApiSagas.query,
      walletTransactions,
      payload
    )

    const transactions: SagaReturnType<
      typeof selectors.preferences.walletTransactionData
    > = yield select(selectors.preferences.walletTransactionData)

    if (!rs?.errors) {
      yield put(
        actions.preferences.setWalletTransactionData(
          (transactions ?? []).concat(rs?.data?.data ?? [])
        )
      )
      yield put(actions.preferences.walletTransactionsSuccess(rs?.data))
    } else {
      yield put(actions.preferences.walletTransactionsError(rs.errors))
    }
  }

  static *onBookingTransactionsRequest({
    payload,
  }: ServiceRequestAction<QueryBookingTransactionsArgs>) {
    const rs: ApiResponse<typeof bookingTransactions> = yield call(
      ApiSagas.query,
      bookingTransactions,
      payload
    )

    const transactions: SagaReturnType<
      typeof selectors.preferences.bookingTransactionData
    > = yield select(selectors.preferences.bookingTransactionData)

    if (!rs?.errors) {
      yield put(
        actions.preferences.setBookingTransactionData(
          (transactions ?? []).concat(rs?.data?.data ?? [])
        )
      )
      yield put(actions.preferences.bookingTransactionsSuccess(rs?.data))
    } else {
      yield put(actions.preferences.bookingTransactionsError(rs.errors))
    }
  }

  static *onUpdateTeacherParticipantsRequest({
    payload,
  }: ServiceRequestAction<MaxStudentsFormValues>) {
    const rs: ApiResponse<typeof updateTeacherMaximumParticipants> = yield call(
      ApiSagas.mutate,
      updateTeacherMaximumParticipants,
      {
        maximum_participants: parseInt(payload?.size ?? '4', 10),
      }
    )

    if (!rs?.errors) {
      yield put(actions.auth.setUser(rs?.data))
      yield put(actions.preferences.updateTeacherParticipantsSuccess(rs?.data))
    } else {
      yield put(actions.preferences.updateTeacherParticipantsError(rs.errors))
    }
  }

  static *onUpdateTeacherDisciplinesRequest({
    payload,
  }: ServiceRequestAction<LevelPickersFormValues>) {
    const data = payload || {}
    const disciplines: TeacherDisciplineInput[] = []
    Object.keys(data)?.forEach((discipline) => {
      data[discipline]?.forEach((grade) => {
        disciplines.push({
          discipline,
          grade,
        })
      })
    })
    const rs: ApiResponse<typeof updateTeacherDisciplines> = yield call(
      ApiSagas.mutate,
      updateTeacherDisciplines,
      { disciplines }
    )

    if (!rs?.errors) {
      yield put(actions.auth.setUser(rs?.data))
      yield put(actions.preferences.updateTeacherDisciplinesSuccess(rs?.data))
    } else {
      yield put(actions.preferences.updateTeacherDisciplinesError(rs.errors))
    }
  }

  static *onUpdateTeacherPricingRequest({ payload }: ServiceRequestAction<PricesChoiceFormValues>) {
    let rs: ApiResponse<typeof updateTeacherPrice> | ApiResponse<typeof updateTeacherDisciplines>
    if (payload?.type === PricingType.UNIQUE) {
      rs = yield call(ApiSagas.mutate, updateTeacherPrice, {
        price: parseFloat(payload?.unique?.replace(',', '.')) * 100,
      })
    } else {
      const disciplines = [] as TeacherDisciplineInput[]
      const userDisciplines: SagaReturnType<
        typeof selectors.auth.disciplinesWithGrades
      > = yield select(selectors.auth.disciplinesWithGrades)
      payload?.custom?.forEach((row, index) => {
        const discipline = userDisciplines?.[index]?.id
        if (discipline) {
          Object.keys(row)?.forEach((grade) => {
            disciplines.push({
              discipline,
              grade,
              price: (parseFloat(row[grade]?.replace(',', '.')) ?? 0) * 100,
            })
          })
        }
      })

      rs = yield call(ApiSagas.mutate, updateTeacherDisciplines, { disciplines })
    }

    if (!rs?.errors) {
      yield put(actions.auth.setUser(rs?.data))
      yield put(actions.preferences.updateTeacherPricingSuccess(rs?.data))
    } else {
      yield put(actions.preferences.updateTeacherPricingError(rs.errors))
    }
  }

  static *onAddChildRequest({ payload }: ServiceRequestAction<AddChildFormValues>) {
    const input: MutationAddFamilyMemberArgs = {
      member: {
        avatar: payload?.avatar,
        email: payload?.email,
        password: payload?.password,
        first_name: payload?.firstName ?? '',
        last_name: payload?.lastName ?? '',
        school: payload?.school ?? '',
        grade: payload?.level ?? '',
      },
    }
    const rs: ApiResponse<typeof addFamilyMember> = yield call(
      ApiSagas.mutate,
      addFamilyMember,
      input
    )

    if (!rs?.errors) {
      const user: SagaReturnType<typeof selectors.auth.user> = yield select(selectors.auth.user)
      yield put(
        actions.auth.setUser({ ...user, guardian: rs?.data?.guardian ?? user?.guardian } as User)
      )
      yield put(actions.preferences.addChildSuccess(rs?.data))
    } else {
      yield put(actions.preferences.addChildError(rs.errors))
    }
  }

  static *onEditChildRequest({
    payload,
  }: ServiceRequestAction<AddChildFormValues & { student: string }>) {
    const input: MutationUpdateFamilyMemberArgs = {
      student: payload?.student ?? '',
      member: {
        avatar: payload?.avatar,
        email: payload?.email,
        password: payload?.password,
        first_name: payload?.firstName ?? '',
        last_name: payload?.lastName ?? '',
        school: payload?.school ?? '',
        grade: payload?.level ?? '',
      },
    }
    const rs: ApiResponse<typeof updateFamilyMember> = yield call(
      ApiSagas.mutate,
      updateFamilyMember,
      input
    )

    if (!rs?.errors) {
      const user: SagaReturnType<typeof selectors.auth.user> = yield select(selectors.auth.user)
      yield put(
        actions.auth.setUser({ ...user, guardian: rs?.data?.guardian ?? user?.guardian } as User)
      )
      yield put(actions.preferences.editChildSuccess(rs?.data))
    } else {
      yield put(actions.preferences.editChildError(rs.errors))
    }
  }

  static *onRemoveChildRequest({ payload }: ServiceRequestAction<{ student: string }>) {
    const input: MutationDeleteFamilyMemberArgs = {
      student: payload?.student ?? '',
    }
    const rs: ApiResponse<typeof deleteFamilyMember> = yield call(
      ApiSagas.mutate,
      deleteFamilyMember,
      input
    )

    if (!rs?.errors) {
      const user: SagaReturnType<typeof selectors.auth.user> = yield select(selectors.auth.user)
      yield put(
        actions.auth.setUser({ ...user, guardian: rs?.data?.guardian ?? user?.guardian } as User)
      )
      yield put(actions.preferences.removeChildSuccess(rs?.data))
    } else {
      yield put(actions.preferences.removeChildError(rs.errors))
    }
  }

  static *onUpdatePayoutScheduleRequest({
    payload,
  }: ServiceRequestAction<MutationUpdatePayoutScheduleIntervalArgs>) {
    const rs: ApiResponse<typeof updatePayoutScheduleInterval> = yield call(
      ApiSagas.mutate,
      updatePayoutScheduleInterval,
      payload
    )

    if (!rs?.errors) {
      const user: SagaReturnType<typeof selectors.auth.user> = yield select(selectors.auth.user)
      yield put(
        actions.auth.setUser({
          ...user,
          teacher: {
            ...user?.teacher,
            payouts_schedule_interval:
              rs?.data?.teacher?.payouts_schedule_interval ??
              user?.teacher?.payouts_schedule_interval,
          },
        } as User)
      )
      yield put(actions.preferences.updatePayoutScheduleSuccess(rs?.data))
    } else {
      yield put(actions.preferences.updatePayoutScheduleError(rs.errors))
    }
  }

  static *onCoursesWithMaterialRequest({
    payload,
  }: ServiceRequestAction<{ first: number; page: number; filters: any }>) {
    const input: QueryCoursesWithMaterialArgs = {
      first: payload?.first ?? 10,
      page: payload?.page ?? 1,
      ...(payload?.filters?.student && { student: payload?.filters?.student }),
      ...(payload?.filters?.grade && { grade: payload?.filters?.grade }),
      ...(payload?.filters?.discipline && { discipline: payload?.filters?.discipline }),
      orderBy: [
        {
          column: QueryCoursesWithMaterialOrderByColumn.StartAt,
          order: payload?.filters?.sort,
        },
      ],
    }
    const rs: ApiResponse<typeof coursesWithMaterial> = yield call(
      ApiSagas.query,
      coursesWithMaterial,
      input
    )

    if (!rs?.errors) {
      yield put(actions.preferences.coursesWithMaterialSuccess(rs?.data))
    } else {
      yield put(actions.preferences.coursesWithMaterialError(rs.errors))
    }
  }

  static *loop() {
    yield all([
      takeLeading(
        actions.preferences.teacherAvailabilitiesOfMonthRequest,
        this.onTeacherAvailabilitiesOfMonth
      ),
      takeLeading(
        actions.preferences.updateTeacherAvailabilityOfDayRequest,
        this.onUpdateTeacherAvailabilitiesOfDay
      ),
      takeLeading(
        actions.preferences.teacherAvailabilityRecurrencesRequest,
        this.onTeacherAvailabilityRecurrences
      ),
      takeLeading(
        actions.preferences.teacherAvailabilityRecurrenceRequest,
        this.onTeacherAvailabilityRecurrenceById
      ),
      takeLeading(
        actions.preferences.createTeacherAvailabilityRecurrenceRequest,
        this.onCreateTeacherAvailabilityRecurrence
      ),
      takeLeading(
        actions.preferences.updateTeacherAvailabilityRecurrenceRequest,
        this.onUpdateTeacherAvailabilityRecurrence
      ),
      takeLeading(
        actions.preferences.deleteTeacherAvailabilityRecurrenceRequest,
        this.onDeleteTeacherAvailabilityRecurrence
      ),
      takeLeading(
        actions.preferences.createCreditPaymentIntentRequest,
        this.onCreateCreditPaymentIntent
      ),
      takeLeading(actions.preferences.withdrawalRequest, this.onWithdrawalRequest),
      takeLatest(actions.preferences.walletTransactionsRequest, this.onWalletTransactionsRequest),
      takeLatest(actions.preferences.bookingTransactionsRequest, this.onBookingTransactionsRequest),
      takeLatest(
        actions.preferences.updateTeacherParticipantsRequest,
        this.onUpdateTeacherParticipantsRequest
      ),
      takeLatest(
        actions.preferences.updateTeacherDisciplinesRequest,
        this.onUpdateTeacherDisciplinesRequest
      ),
      takeLatest(
        actions.preferences.updateTeacherPricingRequest,
        this.onUpdateTeacherPricingRequest
      ),
      takeLatest(actions.preferences.addChildRequest, this.onAddChildRequest),
      takeLatest(actions.preferences.editChildRequest, this.onEditChildRequest),
      takeLatest(actions.preferences.removeChildRequest, this.onRemoveChildRequest),
      takeLatest(
        actions.preferences.updatePayoutScheduleRequest,
        this.onUpdatePayoutScheduleRequest
      ),
      takeLatest(actions.preferences.coursesWithMaterialRequest, this.onCoursesWithMaterialRequest),
    ])
  }
}
