import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { ReportingModalProps } from '../components/modals/ReportingModal'
import { actions, selectors } from '../redux'
import { disputeReasonOptions } from '../graphql/enums/DisputeReason'
import { Course } from '../generated/graphql'

import { useFormSubmit } from './useFormSubmit'

const useReportingModal = (
  course?: Course | null,
  callback?: () => void,
  studentId?: string | null
): [(v: boolean) => void, ReportingModalProps] => {
  const [reportingOpen, setReportingOpen] = useState(false)
  const { t } = useTranslation()
  const user = useSelector(selectors.auth.user)

  const onComplete = useCallback(() => {
    setReportingOpen(false)
    callback?.()
  }, [callback])

  const [submitReporting, handleSubmitReporting] = useFormSubmit(
    selectors.planning.report,
    actions.planning.reportRequest,
    actions.planning.reportReset,
    onComplete
  )

  return useMemo(() => {
    const studentIdsFromCourse = course?.requests?.map((r) => r.student?.id) ?? []
    const students =
      user?.guardian?.children?.filter((c) => studentIdsFromCourse?.includes(c.id)) ?? []

    return [
      setReportingOpen,
      {
        headerText: t('reporting_title'),
        open: reportingOpen,
        formProps: {
          withStudentField: students.length > 1,
          fieldsProps: {
            student: {
              label: t('reporting_form_student_label'),
              options: students?.map((student) => ({
                avatar: {
                  alt: '',
                  src: student?.avatar,
                },
                label: student?.first_name ?? '',
                value: student?.id ?? '',
              })),
            },
            type: {
              label: t('reporting_form_type_label'),
              placeholder: t('reporting_form_type_placeholder'),
              isLabelExternal: true,
              fullWidth: true,
              select: true,
              selectOptions: disputeReasonOptions(t, user?.user_type),
            },
            details: {
              label: t('reporting_form_details_label'),
              isLabelExternal: true,
              placeholder: t('reporting_form_details_placeholder'),
              rows: 4,
              multiline: true,
            },
          },
          initialValues: {
            student:
              studentId && studentIdsFromCourse?.includes(studentId)
                ? studentId
                : students?.[0]?.id ?? '',
            type: '',
            details: '',
          },
          errorTexts: {
            required: t('error_required'),
          },
          skipButton: {
            text: t('cancel'),
            onClick: () => setReportingOpen(false),
          },
          submitButton: {
            text: t('reporting_form_submit'),
            isPending: submitReporting.pending,
            isSuccess: submitReporting.success,
          },
          submitErrors: submitReporting.errors,
          onSubmit: (values) =>
            handleSubmitReporting({
              reason: values?.type,
              message: values?.details,
              course: course?.id,
              student: values?.student,
            }),
        },
        onClose: () => {
          setReportingOpen(false)
        },
      },
    ]
  }, [
    course?.requests,
    course?.id,
    user?.guardian?.children,
    user?.user_type,
    t,
    reportingOpen,
    submitReporting.pending,
    submitReporting.success,
    submitReporting.errors,
    handleSubmitReporting,
    studentId,
  ])
}

export default useReportingModal
