import React, { FC } from 'react'

import { BasicLinkProps } from '../BasicLink'
import { RatingModalitiesProps } from '../RatingModalities'

import * as SC from './styled'

export type ParentSideScoreCardProps = {
  studentData: RatingModalitiesProps
  historyLink: BasicLinkProps
  editLink: BasicLinkProps
  deleteLink?: BasicLinkProps
  className?: string
}

const ParentSideScoreCard: FC<ParentSideScoreCardProps> = (props) => {
  const { studentData, historyLink, deleteLink, editLink, className } = props
  return (
    <SC.ParentSideScoreCard className={className}>
      <SC.ParentSideScoreCardContent>
        <SC.ScoreCard {...studentData} />
        <SC.Action {...historyLink} iconSide="left" />
        <SC.Muidivider />
        <SC.Action2 {...editLink} iconSide="left" />
        {deleteLink && <SC.Muidivider />}
        {deleteLink && <SC.Action3 {...deleteLink} iconSide="left" />}
      </SC.ParentSideScoreCardContent>
    </SC.ParentSideScoreCard>
  )
}

export default ParentSideScoreCard
