import React, { FC } from 'react'

import { CourseCardProps } from '../../CourseCard'
import { Icons } from '../../Icon/types'
import { ActionButtonProps } from '../../ActionButton'
import ModalContainer from '../ModalContainer'
import { FormSubmitProps } from '../../FormSubmit'
import FormSubmitErrors, { FormSubmitErrorsProps } from '../../FormSubmitErrors'

import * as SC from './styled'

export type RequestCourseModalProps = {
  className?: string
  headerText: string
  title?: string
  text?: string
  card?: CourseCardProps
  icon?: Icons
  cancelButton?: ActionButtonProps
  confirmButton?: FormSubmitProps
  closeButton?: ActionButtonProps
  open: boolean
  handleClose: () => void
  onClose?: () => void
  submitErrors?: FormSubmitErrorsProps['errors']
}

const RequestCourseModal: FC<RequestCourseModalProps> = (props) => {
  const {
    className,
    headerText,
    title,
    text,
    card,
    icon,
    cancelButton,
    confirmButton,
    closeButton,
    open,
    handleClose,
    onClose,
    submitErrors,
  } = props

  return (
    <ModalContainer open={open} onClose={onClose} className={className}>
      <SC.HeaderText>{headerText}</SC.HeaderText>
      <SC.Content>
        {title && <SC.Title>{title}</SC.Title>}
        {icon && <SC.MyIcon icon={icon} />}
        {text && <SC.Text aligned={!!icon}>{text}</SC.Text>}
        {card && <SC.Card {...card} />}
      </SC.Content>
      <FormSubmitErrors errors={submitErrors} />
      <SC.Buttons>
        {cancelButton && confirmButton && (
          <>
            <SC.Button {...cancelButton} outlined onClick={handleClose} />
            <SC.Submit {...confirmButton} autoIcon />
          </>
        )}
        {closeButton && <SC.Button {...closeButton} onClick={handleClose} />}
      </SC.Buttons>
    </ModalContainer>
  )
}

export default RequestCourseModal
