import gql from 'graphql-tag'

import GuardianFragment from '../../../fragments/GuardianFragment'
import { User } from '../../../../generated/graphql'

const addFamilyMember = {
  mutation: gql`
    mutation addFamilyMember($member: FamilyMemberInput!) {
      addFamilyMember(member: $member) {
        guardian {
          ...GuardianFragment
        }
      }
    }
    ${GuardianFragment}
  `,
  transformer: (response: any): User => {
    return response.addFamilyMember
  },
}

export default addFamilyMember
