import React, { FC } from 'react'
import { InputLabelProps as MuiInputLabelProps } from '@material-ui/core'

import * as SC from './styled'

export type InputLabelProps = MuiInputLabelProps & {
  className?: string
}

const InputLabel: FC<InputLabelProps> = (props) => {
  return <SC.MuiInputLabel {...props} />
}

export default InputLabel
