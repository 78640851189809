import styled from 'styled-components'

import TextFieldC from '../form/TextField'
import FormSubmit from '../FormSubmit'
import TabContentC from '../TabContent'

export const Attach = styled.div``

export const Step = styled.p`
  ${(props) => ({ ...props.theme.typography.h6 })}
  color: ${(props) => props.theme.palette.colors.neonCarrot};
  text-transform: uppercase;
`

export const Title = styled.h1`
  ${(props) => ({ ...props.theme.typography.h1 })}
  color: ${(props) => props.theme.palette.colors.midnightBlue};
  font-size: 3.2rem;
  margin: 0 0 2rem 0;
  ${(props) => props.theme.breakpoints.up('md')} {
    font-size: 4rem;
  }
`

export const MessageBox = styled.div`
  padding: 1rem;
  border: 1px solid ${(props) => props.theme.palette.colors.kournikova};
  background-color: ${(props) => props.theme.palette.colors.pureWhite};
  border-radius: 0.6rem;
  margin: 3.4rem 0;
`

export const MessageTitle = styled.h3`
  ${(props) => ({ ...props.theme.typography.h4 })};
  color: ${(props) => props.theme.palette.colors.tomato};
  margin: 0;
`

export const MessageText = styled.p`
  ${(props) => ({ ...props.theme.typography.smallRegular })};
  color: ${(props) => props.theme.palette.colors.tomato};
  margin: 0;
`

export const Text = styled.p`
  ${(props) => ({ ...props.theme.typography.big })}
  color: ${(props) => props.theme.palette.colors.midnightBlue};
  margin: 0 0 1rem 0;
`

export const Fields = styled.div`
  margin-top: 2rem;
`

export const Tab = styled(TabContentC)``

export const TextField = styled(TextFieldC)`
  margin-bottom: 1rem;
  width: 100%;
  margin-right: 1rem;
`

export const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 2rem;
`

export const SkipButton = styled(FormSubmit)`
  width: 40%;
  margin-right: 0.5rem;
`

export const SubmitButton = styled(FormSubmit)`
  width: 60%;
  margin-left: 0.5rem;
`
