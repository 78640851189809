import { all, fork } from 'redux-saga/effects'

import ApiSagas from './api/sagas'
import AppSagas from './app/sagas'
import AuthSagas from './auth/sagas'
import PlanningSagas from './planning/sagas'
import PreferencesSagas from './preferences/sagas'
import RoomSagas from './room/sagas'
import SearchSagas from './search/sagas'

function* loop() {
  yield all([
    ApiSagas.loop(),
    AppSagas.loop(),
    AuthSagas.loop(),
    PlanningSagas.loop(),
    PreferencesSagas.loop(),
    RoomSagas.loop(),
    SearchSagas.loop(),
  ])
}

export default function* rootSaga() {
  yield fork(loop)
}
