import gql from 'graphql-tag'

const updatePassword = {
  mutation: gql`
    mutation updatePassword($current: String!, $password: String!, $passwordConfirmation: String!) {
      updatePassword(
        current: $current
        password: $password
        password_confirmation: $passwordConfirmation
      ) {
        id
      }
    }
  `,
  transformer: (response: any): string => {
    return response.updatePassword
  },
}

export default updatePassword
