import styled from 'styled-components'
import ReactMarkdown from 'react-markdown'

import PageBackButton from '../../components/PageBackButton'
import BasicProfil from '../../components/BasicProfil'
import Link from '../../components/Link'
import ActionButton from '../../components/ActionButton'

export const Content = styled.div`
  flex-grow: 1;
  ${(props) => props.theme.breakpoints.up('md')} {
    flex-grow: initial;
  }
`

export const BackButton = styled(PageBackButton)``

export const BasicProfile = styled(BasicProfil)`
  margin-top: 2rem;
  margin: 1.6rem;
  padding: 0.8rem 0 1.6rem;
  width: auto;
  max-width: 48.2rem;
  border-bottom: 1px solid ${(props) => props.theme.palette.colors.gainsboro};
`

export const DetailTitle = styled.h3`
  ${(props) => ({ ...props.theme.typography.h3 })}
  color: ${(props) => props.theme.palette.colors.midnightBlue};
  margin: 2.4rem 1.6rem 0.8rem;
`

export const DetailDescription = styled(ReactMarkdown)`
  ${(props) => ({ ...props.theme.typography.big })}
  color: ${(props) => props.theme.palette.colors.chambray};
  margin: 0 1.6rem;
  & p {
    margin-top: 0;
  }
`

export const Disciplines = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 1.6rem;
`

export const Discipline = styled(Link)`
  ${(props) => ({ ...props.theme.typography.h4 })}
  color: ${(props) => props.theme.palette.colors.freeSpeechBlue};
  padding: 1.6rem;
  margin: 0 0 0.8rem;
  text-decoration: none;
  display: flex;
  max-width: 47rem;
  border: 1px solid ${(props) => props.theme.palette.colors.gainsboro};
  background-color: ${(props) => props.theme.palette.colors.pureWhite};
  border-radius: 0.8rem;
`

export const Button = styled(ActionButton)`
  margin: 1.6rem;
  max-width: 47rem;
`
