import styled from 'styled-components'

import PageTitle from '../../components/PageTitle'
import PageBackButton from '../../components/PageBackButton'
import TabContentC from '../../components/TabContent'
import WalletWithdrawalForm from '../../components/WalletWithdrawalForm'

export const Content = styled.div``

export const Title = styled(PageTitle)``
export const BackButton = styled(PageBackButton)``

export const TabContent = styled(TabContentC)`
  padding: 0 1.6rem;
`

export const Form = styled(WalletWithdrawalForm)`
  ${(props) => ({ ...props.theme.typography.h4 })}
  color: ${(props) => props.theme.palette.colors.freeSpeechBlue};
  margin: 0 1.6rem 1.6rem;
  width: 100%;
  ${(props) => props.theme.breakpoints.up('md')} {
    max-width: 52rem;
  }
`
