import gql from 'graphql-tag'

import { ServiceMutation } from '../../../../redux/api/types/state'

const logout: ServiceMutation = {
  mutation: gql`
    mutation invalidateToken {
      invalidateToken
    }
  `,
  transformer: (response: any) => {
    return response.invalidateToken
  },
}

export default logout
