import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 100%;

  & .MuiInputAdornment-root {
    height: 1.7rem;
    width: 1.7rem;
    margin-right: 0.25rem;
    color: ${({ theme }) => theme.palette.colors.cornflowerBlue};
  }
`
