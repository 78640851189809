import React, { FC } from 'react'

import Header, { HeaderProps } from '../../components/Header'
import Menu, { MenuProps } from '../../components/Menu'
import AuthLayout from '../../layouts/AuthLayout'
import { PageBackButtonProps } from '../../components/PageBackButton'
import { RatingModalitiesProps } from '../../components/RatingModalities'
import { ImageTextListProps } from '../../components/ImageTextList'
import { ActionButtonProps } from '../../components/ActionButton'
import { CourseCardInContextProps } from '../../components/CourseCardInContext'

import * as SC from './styled'

export type DashboardChildTemplateProps = {
  className?: string
  headerProps: HeaderProps
  menuProps: MenuProps
  title: string
  backButton: PageBackButtonProps
  studentCardProps: RatingModalitiesProps
  list: ImageTextListProps
  searchButton?: ActionButtonProps
  courseCards?: CourseCardInContextProps[]
}

const DashboardChildTemplate: FC<DashboardChildTemplateProps> = (props) => {
  const {
    headerProps,
    menuProps,
    title,
    backButton,
    studentCardProps,
    list,
    searchButton,
    courseCards,
  } = props

  return (
    <AuthLayout header={<Header {...headerProps} withDivider />} menu={<Menu {...menuProps} />}>
      <SC.Content>
        <SC.BackButton {...backButton} />
        <SC.Title>{title}</SC.Title>
        <SC.StudentCard {...studentCardProps} />
        {courseCards &&
          courseCards?.map((courseCard, index) => (
            <SC.CourseCardInContext {...courseCard} key={index} />
          ))}
        <SC.ImageTextList {...list} />
        {searchButton && <SC.Button {...searchButton} />}
      </SC.Content>
    </AuthLayout>
  )
}

export default DashboardChildTemplate
