import React from 'react'
import { useTranslation } from 'react-i18next'

import { HeaderProps } from '../../components/Header'
import useHeader from '../../hooks/useHeader'
import SignUpConfirmTemplate from '../../templates/SignUpConfirm'
import { app } from '../../configuration'
import { ActionButtonProps } from '../../components/ActionButton'
import { BasicTextAndImageProps } from '../../components/BasicTextAndImage'
import { router, routesPath } from '../../router'
import { FormSubmitProps } from '../../components/FormSubmit'
import { useFormSubmit } from '../../hooks/useFormSubmit'
import { actions, selectors } from '../../redux'

const SignUpConfirmPage: React.FC = (_props) => {
  const headerProps: HeaderProps = useHeader()

  const { t } = useTranslation()

  const [submit, handleSubmit] = useFormSubmit(
    selectors.auth.resendVerificationMail,
    actions.auth.resendVerificationMailRequest,
    actions.auth.resendVerificationMailReset
  )

  const textProps: BasicTextAndImageProps = {
    title: t('signUpConfirm_title'),
    subTitle: t('signUpConfirm_subtitle'),
    text: t('signUpConfirm_text'),
    image: { alt: 'LiberteClass - Merci', src: '/static/assets/images/completeInfoStep3.png' },
  }

  const cta: ActionButtonProps = {
    text: t('signUpConfirm_back'),
    link: {
      link: app.PUBLIC_URL,
    },
    type: 'button',
  }

  const logoutButtonProps: ActionButtonProps = {
    text: t('logout'),
    link: {
      link: router(routesPath.signOut),
    },
  }

  const resendCta: FormSubmitProps = {
    text: t('signUpConfirm_submit'),
    pendingText: t('signUpConfirm_submit_pending'),
    successText: t('signUpConfirm_submit_success'),
    type: 'button',
    isSuccess: submit?.success,
    isPending: submit?.pending,
    onClick: () => handleSubmit(undefined),
  }

  return (
    <SignUpConfirmTemplate
      headerProps={headerProps}
      textProps={textProps}
      cta={cta}
      logoutButtonProps={logoutButtonProps}
      text2={t('signUpConfirm_text2')}
      text3={t('signUpConfirm_text3')}
      submitProps={resendCta}
      submitErrors={submit?.errors}
    />
  )
}

export default SignUpConfirmPage
