import styled from 'styled-components'

import PageTitle from '../../components/PageTitle'
import DateCarouselC from '../../components/DateCarousel'
import CardList from '../../components/CardList'

export const Content = styled.div`
  width: 100%;
`

export const Title = styled(PageTitle)``

export const Text = styled.p`
  ${(props) => ({ ...props.theme.typography.tinySmall })}
  color: ${(props) => props.theme.palette.colors.midnightBlue};
  padding: 1.6rem 1.6rem 1.6rem 1.6rem;
  margin: 0;
  max-width: 47rem;
`

export const DateCarousel = styled(DateCarouselC)`
  padding: 0rem 1.6rem;
  margin-top: 2.4rem;
  margin-bottom: 1.6rem;
`

export const MyCourseCardList = styled(CardList)`
  padding: 0rem 1.6rem;
  ${(props) => props.theme.breakpoints.up('md')} {
    & > div > div {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
      & > div {
        display: flex;
      }
    }
  }
`
