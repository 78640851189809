import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { SimpleFeedbackModalProps } from '../components/modals/SimpleFeedbackModal'
import { Icons } from '../components/Icon/types'

const useFeedbackModal = (
  props: Omit<SimpleFeedbackModalProps, 'open'>
): [(v: boolean) => void, SimpleFeedbackModalProps] => {
  const [confirmationOpen, setConfirmationOpen] = useState(false)
  const { t } = useTranslation()

  return useMemo(
    () => [
      setConfirmationOpen,
      {
        icon: Icons.pebbleValidate,
        closeLabel: t('close'),
        open: confirmationOpen,
        ...props,
        onClose: () => {
          setConfirmationOpen(false)
          props?.onClose?.()
        },
      },
    ],
    [confirmationOpen, props, t]
  )
}

export default useFeedbackModal
