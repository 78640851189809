import gql from 'graphql-tag'

import { Lesson } from '../../../../generated/graphql'
import LessonFragment from '../../../fragments/LessonFragment'

const createLesson = {
  mutation: gql`
    mutation createLesson($name: String!, $file: Upload, $student: ID, $discipline: ID!) {
      createLesson(name: $name, file: $file, student: $student, discipline: $discipline) {
        ...LessonFragment
      }
    }
    ${LessonFragment}
  `,
  transformer: (response: any): Lesson => {
    return response.createLesson
  },
}

export default createLesson
