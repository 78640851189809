import React, { FC, memo, ReactNode } from 'react'

import Header, { HeaderProps } from '../../components/Header'
import Menu, { MenuProps } from '../../components/Menu'
import AuthLayout from '../../layouts/AuthLayout'
import PageBackButton, { PageBackButtonProps } from '../../components/PageBackButton'
import { CardListProps } from '../../components/CardList'
import RequestCourseModal, {
  RequestCourseModalProps,
} from '../../components/modals/RequestCourseModal'
import SimpleFeedbackModal, {
  SimpleFeedbackModalProps,
} from '../../components/modals/SimpleFeedbackModal'

import * as SC from './styled'

export type SearchMarketResultsTemplateProps = {
  className?: string
  children?: ReactNode
  headerProps: HeaderProps
  menuProps: MenuProps
  backButton?: PageBackButtonProps
  title: string
  text?: string
  subtitle: string
  numberOfResults: string
  cardListProps: CardListProps
  requestCourseModal?: RequestCourseModalProps
  feedbackModalProps?: SimpleFeedbackModalProps
}

export const SearchMarketResultsTemplate: FC<SearchMarketResultsTemplateProps> = (props) => {
  const {
    headerProps,
    menuProps,
    title,
    text,
    subtitle,
    numberOfResults,
    backButton,
    cardListProps,
    requestCourseModal,
    feedbackModalProps,
  } = props

  return (
    <AuthLayout header={<Header {...headerProps} withDivider />} menu={<Menu {...menuProps} />}>
      <SC.Content>
        {backButton && <PageBackButton {...backButton} />}
        <SC.Title>{title}</SC.Title>
        <SC.Subtitle2>{subtitle}</SC.Subtitle2>
        <SC.NumberOfResults>{numberOfResults}</SC.NumberOfResults>
        {text && <SC.Text>{text}</SC.Text>}
        <SC.MyCourseCardList {...cardListProps} />
        {requestCourseModal && <RequestCourseModal {...requestCourseModal} />}
      </SC.Content>
      {feedbackModalProps && <SimpleFeedbackModal {...feedbackModalProps} />}
    </AuthLayout>
  )
}

export default memo(SearchMarketResultsTemplate)
