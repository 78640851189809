import gql from 'graphql-tag'

import LessonFragment from './LessonFragment'

export default gql`
  fragment NotificationFragment on Notification {
    course {
      id
      date
      slot
      lesson {
        ...LessonFragment
      }
      teacher {
        full_name
      }
    }
    created_at
    id
    payload
    read_at
    student {
      id
      first_name
      avatar
    }
    type
  }
  ${LessonFragment}
`
