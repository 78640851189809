import React, { FC } from 'react'
import { css } from 'styled-components'
import { usePaymentInputs } from 'react-payment-inputs'
import images from 'react-payment-inputs/images'

import Icon from '../../Icon'
import { Icons } from '../../Icon/types'
import { light } from '../../../theme/palette'

import * as SC from './styled'

type PaymentSubFieldProps = {
  name: string
  value?: string
  onChange: (name: string, value: string) => void
}

export type PaymentFieldProps = {
  className?: string
  cardNumberField: PaymentSubFieldProps
  cardExpiryField: PaymentSubFieldProps
  cardCVCField: PaymentSubFieldProps
  cardInputPlaceholder: string
  errorMessages: {
    [key: string]: string
  }
}

const PaymentField: FC<PaymentFieldProps> = (props) => {
  const {
    className,
    cardNumberField,
    cardExpiryField,
    cardCVCField,
    cardInputPlaceholder,
    errorMessages,
  } = props

  const {
    wrapperProps,
    getCardImageProps,
    getCardNumberProps,
    getExpiryDateProps,
    getCVCProps,
  } = usePaymentInputs({ errorMessages })

  const cardImage = {
    ...images,
    placeholder: <Icon icon={Icons.cb} />,
  }

  return (
    <SC.Container className={className}>
      <SC.PaymentInputsWrapperC
        {...wrapperProps}
        styles={{
          inputWrapper: {
            base: css`
              border: 1px solid ${light.colors.gainsboro};
              box-shadow: none;
              border-radius: 0.6rem;
            `,
            errored: css`
              border: 1px solid ${light.colors.tomato};
              box-shadow: none;
              border-radius: 0.6rem;
            `,
            focused: css`
              border: 1px solid ${light.colors.gainsboro};
              box-shadow: none;
              border-radius: 0.6rem;
            `,
          },
          errorText: {
            base: css`
              ${(props) => ({ ...props.theme.typography.tinyRegular })}
              color: ${light.colors.tomato};
            `,
          },
        }}
      >
        <SC.CBCard {...getCardImageProps({ images: cardImage })} />
        <SC.Input
          {...getCardNumberProps({
            name: cardNumberField.name,
            onChange: cardNumberField.onChange,
            value: cardNumberField.value,
          })}
          placeholder={cardInputPlaceholder}
        />
        <SC.Input
          {...getExpiryDateProps({
            name: cardExpiryField.name,
            onChange: cardExpiryField.onChange,
            value: cardExpiryField.value,
          })}
        />
        <SC.Input
          {...getCVCProps({
            name: cardCVCField.name,
            onChange: cardCVCField.onChange,
            value: cardCVCField.value,
          })}
        />
      </SC.PaymentInputsWrapperC>
    </SC.Container>
  )
}

export default PaymentField
