import React, { FC } from 'react'

import Header, { HeaderProps } from '../../components/Header'
import SignUpLayout from '../../layouts/SignupLayout'
import { ImgProps } from '../../types/image'
import SignInForgotForm, { SignInForgotFormProps } from '../../components/SignInForgotForm'

import * as SC from './styled'

export type SignInForgotTemplateProps = {
  headerProps: HeaderProps
  signupProps: SignInForgotFormProps
  imageProps: ImgProps
}

const SignInForgotTemplate: FC<SignInForgotTemplateProps> = (props) => {
  const { headerProps, signupProps, imageProps } = props

  return (
    <SignUpLayout
      header={<Header {...headerProps} />}
      side={
        <SC.BoxImage>
          <SC.Image {...imageProps} />
        </SC.BoxImage>
      }
    >
      <SignInForgotForm {...signupProps} />
    </SignUpLayout>
  )
}

export default SignInForgotTemplate
