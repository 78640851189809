import gql from 'graphql-tag'

import { TeacherAvailabilityRecurrence } from '../../../../generated/graphql'
import TeacherAvailabilityRecurrenceFragment from '../../../fragments/TeacherAvailabilityRecurrenceFragment'

const updateTeacherAvailabilityRecurrence = {
  mutation: gql`
    mutation updateTeacherAvailabilityRecurrence(
      $id: ID!
      $start_at: Date!
      $end_at: Date!
      $days: [RecurrenceDay!]!
      $slots: [AvailabilitySlot!]
    ) {
      updateTeacherAvailabilityRecurrence(
        id: $id
        start_at: $start_at
        end_at: $end_at
        days: $days
        slots: $slots
      ) {
        ...TeacherAvailabilityRecurrenceFragment
      }
    }
    ${TeacherAvailabilityRecurrenceFragment}
  `,
  transformer: (response: {
    updateTeacherAvailabilityRecurrence: TeacherAvailabilityRecurrence
  }): any => {
    return response.updateTeacherAvailabilityRecurrence
  },
}

export default updateTeacherAvailabilityRecurrence
