import React, { FC } from 'react'

import { BasicLinkProps } from '../BasicLink'
import { Icons } from '../Icon/types'

import * as SC from './styled'

export type PageBackButtonProps = BasicLinkProps

const PageBackButton: FC<PageBackButtonProps> = (props) => {
  return <SC.BackButton icon={Icons.longArrowLeft} iconSide={'left'} {...props} />
}

export default PageBackButton
