import React, { FC, memo, ReactNode } from 'react'

import Header, { HeaderProps } from '../../components/Header'
import Menu, { MenuProps } from '../../components/Menu'
import AuthLayout from '../../layouts/AuthLayout'
import { SearchRequestFormProps } from '../../components/SearchRequestForm'
import { ImageTextListProps } from '../../components/ImageTextList'
import PageBackButton, { PageBackButtonProps } from '../../components/PageBackButton'

import * as SC from './styled'

export type SearchTemplateProps = {
  className?: string
  children?: ReactNode
  headerProps: HeaderProps
  menuProps: MenuProps
  title: string
  text?: string
  searchRequestFormProps?: SearchRequestFormProps
  imageTextListProps?: ImageTextListProps
  backButton?: PageBackButtonProps
}

export const SearchTemplate: FC<SearchTemplateProps> = (props) => {
  const {
    headerProps,
    menuProps,
    title,
    text,
    searchRequestFormProps,
    imageTextListProps,
    backButton,
  } = props

  return (
    <AuthLayout header={<Header {...headerProps} withDivider />} menu={<Menu {...menuProps} />}>
      <SC.Content>
        {backButton && <PageBackButton {...backButton} />}
        <SC.Title>{title}</SC.Title>
        {text && <SC.Text>{text}</SC.Text>}
        {searchRequestFormProps && <SC.SearchRequestForm {...searchRequestFormProps} />}
        {imageTextListProps && <SC.ImageTextList hoverable {...imageTextListProps} />}
      </SC.Content>
    </AuthLayout>
  )
}

export default memo(SearchTemplate)
