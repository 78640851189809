import React, { FC, useMemo } from 'react'
import { FieldArray, Formik, FormikHelpers } from 'formik'
import * as Yup from 'yup'

import { RatingWithFieldsProps } from '../RatingWithFields'
import { FormSubmitProps } from '../FormSubmit'
import { fieldArrayProps, getValidationSchema } from '../../helpers/FormHelpers'
import { FormFieldConfig } from '../../types/form'
import { TextFieldProps } from '../form/TextField'
import { FormSubmitErrorsProps } from '../FormSubmitErrors'
import FormScrollToError from '../FormScrollToError'

import * as SC from './styled'

export type RatingsFormValues = {
  students: {
    punctuality: number
    behaviour: number
    interactivity: number
    understanding: number
    working_conditions: number
    comment: string
  }[]
}

export type RatingsFormProps = {
  className?: string
  title: string
  students: RatingWithFieldsProps[]
  submitButton: FormSubmitProps
  initialValues?: RatingsFormValues
  errorTexts?: {
    required: string
    min: string
  }
  submitErrors?: FormSubmitErrorsProps['errors']
  onSubmit?: (values: RatingsFormValues, formikHelpers: FormikHelpers<RatingsFormValues>) => void
}

const RatingsForm: FC<RatingsFormProps> = (props) => {
  const {
    className,
    title,
    students,
    submitButton,
    initialValues = { students: [] },
    errorTexts,
    onSubmit = (_v, _f) => null,
  } = props

  const fieldArray = {
    name: 'students',
  }

  const commentaryField: FormFieldConfig = useMemo(
    () => ({
      name: 'comment',
      validation: Yup.string().required(errorTexts?.required),
      required: true,
    }),
    [errorTexts?.required]
  )

  const fieldsProps = {}

  const fields: FormFieldConfig[] = useMemo(
    () => [
      ...((students[0]?.rateModalities.modality?.map((modality) => ({
        name: modality?.rate?.name,
        validation: Yup.number().required(errorTexts?.required).min(1, errorTexts?.min),
      })) ?? []) as FormFieldConfig[]),
      commentaryField,
    ],
    [commentaryField, errorTexts?.min, errorTexts?.required, students]
  )

  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      [fieldArray.name]: Yup.array(getValidationSchema(fields)),
    })
  }, [fieldArray.name, fields])

  return (
    <SC.RatingsForm className={className}>
      <SC.Wrapper maxWidth="xl">
        <SC.Title>{title}</SC.Title>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnChange={true}
          validateOnBlur={true}
          onSubmit={onSubmit}
        >
          {(formikProps) => (
            <SC.MyForm noValidate>
              <FormScrollToError formikProps={formikProps} />
              <FieldArray
                name={fieldArray.name}
                render={() => (
                  <SC.Students>
                    {formikProps.values?.students?.map((student, index) => {
                      const ratingProps: RatingWithFieldsProps = {
                        ...students[index],
                        rateModalities: {
                          ...students[index]?.rateModalities,
                          modality: students[index]?.rateModalities.modality?.map(
                            (modality, i) => ({
                              ...modality,
                              rate: {
                                ...modality.rate,
                                ...fieldArrayProps(
                                  [{ name: fieldArray.name ?? '', index }],
                                  { name: modality?.rate?.name ?? '' },
                                  formikProps,
                                  fieldsProps,
                                  i
                                ),
                              },
                            })
                          ),
                        },
                        commentaryField: {
                          ...students[index]?.commentaryField,
                          ...fieldArrayProps(
                            [{ name: fieldArray.name, index }],
                            commentaryField,
                            formikProps,
                            fieldsProps,
                            10
                          ),
                        } as TextFieldProps,
                      }

                      return <SC.Rating key={index} {...ratingProps} />
                    })}
                  </SC.Students>
                )}
              />
              <SC.SubmitButtonWrapper>
                <SC.SubmitButton {...submitButton} autoIcon />
              </SC.SubmitButtonWrapper>
            </SC.MyForm>
          )}
        </Formik>
      </SC.Wrapper>
    </SC.RatingsForm>
  )
}

export default RatingsForm
