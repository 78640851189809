import React, { FC } from 'react'

import PageBackButton, { PageBackButtonProps } from '../PageBackButton'
import { ImageTextListProps } from '../ImageTextList'

import * as SC from './styled'

export type CallSelectChildProps = {
  backButtonProps?: PageBackButtonProps
  title?: string
  text?: string
  imageTextListProps?: ImageTextListProps
}

const CallSelectChild: FC<CallSelectChildProps> = (props) => {
  const { backButtonProps, title, text, imageTextListProps } = props

  return (
    <SC.CallSelectChild>
      <SC.Wrapper>
        <PageBackButton {...backButtonProps} />
        {title && <SC.Title>{title}</SC.Title>}
        {text && <SC.Text>{text}</SC.Text>}
        {imageTextListProps && <SC.ImageTextList {...imageTextListProps} />}
      </SC.Wrapper>
    </SC.CallSelectChild>
  )
}

export default CallSelectChild
