import React, { FC, useMemo } from 'react'
import { Form, Formik, FormikHelpers } from 'formik'
import * as Yup from 'yup'

import { getValidationSchema, renderField } from '../../helpers/FormHelpers'
import { FormFieldConfig, FormFieldProps } from '../../types/form'
import { FormSubmitProps } from '../FormSubmit'
import FormSubmitErrors, { FormSubmitErrorsProps } from '../FormSubmitErrors'
import FormScrollToError from '../FormScrollToError'
import { UploadFileValue } from '../form/UploadFile'

import * as SC from './styled'

export type CancelCourseFormValues = {
  type: string
  details: string
  file?: UploadFileValue
}

export type CancelCourseFormProps = {
  className?: string
  text?: string
  fieldsProps?: {
    type?: FormFieldProps
    details?: FormFieldProps
    file?: FormFieldProps
  }
  errorTexts?: {
    required: string
  }
  initialValues: CancelCourseFormValues
  submitErrors?: FormSubmitErrorsProps['errors']
  skipButton?: FormSubmitProps
  submitButton: FormSubmitProps
  onSubmit?: (
    values: CancelCourseFormValues,
    formikHelpers: FormikHelpers<CancelCourseFormValues>
  ) => void
}

const CancelCourseForm: FC<CancelCourseFormProps> = (props) => {
  const {
    className,
    text,
    fieldsProps,
    errorTexts,
    initialValues,
    submitErrors,
    skipButton,
    submitButton,
    onSubmit = (_values, _formikHelpers) => null,
  } = props

  const typeField = useMemo(
    () => ({
      name: 'type',
      Component: SC.TextField,
      validation: Yup.string().required(errorTexts?.required),
      required: true,
    }),
    [errorTexts?.required]
  )

  const detailsField = useMemo(
    () => ({
      name: 'details',
      Component: SC.TextField,
      validation: Yup.string().required(errorTexts?.required),
      required: true,
    }),
    [errorTexts]
  )

  const fileField = useMemo(
    () => ({
      name: 'file',
      Component: SC.Upload,
    }),
    []
  )

  const fields: FormFieldConfig[] = useMemo(() => [typeField, detailsField, fileField], [
    typeField,
    detailsField,
    fileField,
  ])

  const validationSchema = getValidationSchema(fields)

  return (
    <SC.Attach className={className}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnChange={true}
        validateOnBlur={true}
        onSubmit={onSubmit}
      >
        {(formikProps) => (
          <Form noValidate>
            <FormScrollToError formikProps={formikProps} />
            <SC.Fields>
              {fields?.map((field, key) => renderField(field, formikProps, fieldsProps, key))}
            </SC.Fields>
            {text && <SC.Text>{text}</SC.Text>}
            <FormSubmitErrors errors={submitErrors} />
            <SC.Buttons>
              {skipButton && <SC.SkipButton {...skipButton} type={'button'} autoIcon outlined />}
              <SC.SubmitButton {...submitButton} autoIcon />
            </SC.Buttons>
          </Form>
        )}
      </Formik>
    </SC.Attach>
  )
}

export default CancelCourseForm
