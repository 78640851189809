import styled from 'styled-components'
import { Avatar, Card, FormHelperText } from '@material-ui/core'
import { Swiper } from 'swiper/react'

import 'swiper/swiper-bundle.min.css'
import InputLabel from '../form/InputLabel'

export const UsersSlider = styled.section``
export const Slider = styled(Swiper)`
  padding-bottom: 1rem !important;

  .swiper-slide {
    width: auto;
    margin-right: 2rem;
    [dir='rtl'] & {
      margin-left: 2rem;
      margin-right: 0;
    }
  }
`

export const SlideContent = styled(Card)<{ selected?: boolean }>`
  width: 14rem;
  height: 11.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 2px solid
    ${({ selected, theme }) =>
      selected ? theme.palette.colors.cornflowerBlue : theme.palette.colors.ghostWhite};
  border-radius: 6px;
  cursor: pointer;
  box-shadow: none;
`

export const AvatarImg = styled(Avatar)`
  height: 6.4rem;
  width: 6.4rem;
`

export const Text = styled.div<{ selected?: boolean }>`
  ${(props) => ({ ...props.theme.typography.h6 })}
  color: ${({ theme, selected }) =>
    selected ? theme.palette.colors.freeSpeechBlue : theme.palette.colors.grey};
  margin-top: 1.6rem;
`

export const Label = styled(InputLabel)`
  margin-bottom: 0.8rem;
`

export const HelperText = styled(FormHelperText)`
  text-align: left;
  ${(props) => ({ ...props.theme.typography.smallRegular })}
  color: ${(props) => props.theme.palette.colors.grey};
  background-color: ${(props) => props.theme.palette.colors.whiteSmoke};
  margin: 3px 0 0 0;
  &.Mui-error {
    color: ${(props) => props.theme.palette.error.main};
  }
`
