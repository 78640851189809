import React, { FC } from 'react'

import * as SC from './styled'

export type PageTitleProps = {
  className?: string
  children?: React.ReactNode
  title?: string
}

const PageTitle: FC<PageTitleProps> = (props) => {
  const { className, title, children } = props

  return <SC.Title className={className}>{title || children}</SC.Title>
}

export default PageTitle
