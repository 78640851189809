import React, { FC } from 'react'

import { ActionButtonProps } from '../../ActionButton'
import { FormSubmitProps } from '../../FormSubmit'
import FormSubmitErrors, { FormSubmitErrorsProps } from '../../FormSubmitErrors'

import * as SC from './styled'

export type ActionSubmitModalProps = {
  className?: string
  title?: string
  subtitle?: string
  description?: string
  text?: string
  cancelButton?: ActionButtonProps
  confirmButton?: FormSubmitProps
  open: boolean
  onClose?: () => void
  submitErrors?: FormSubmitErrorsProps['errors']
}

const ActionSubmitModal: FC<ActionSubmitModalProps> = (props) => {
  const {
    className,
    title,
    subtitle,
    description,
    text,
    cancelButton,
    confirmButton,
    open,
    onClose,
    submitErrors,
  } = props

  return (
    <SC.Modal open={open} onClose={onClose} className={className}>
      <SC.Content>
        {title && <SC.Title>{title}</SC.Title>}
        {subtitle && <SC.Subtitle>{subtitle}</SC.Subtitle>}
        {description && <SC.Description>{description}</SC.Description>}
        {text && <SC.Text>{text}</SC.Text>}
      </SC.Content>
      <FormSubmitErrors errors={submitErrors} />
      <SC.Buttons>
        {cancelButton && confirmButton && (
          <>
            <SC.Button {...cancelButton} outlined onClick={onClose} />
            <SC.Submit {...confirmButton} autoIcon />
          </>
        )}
      </SC.Buttons>
    </SC.Modal>
  )
}

export default ActionSubmitModal
