import React, { FC, memo } from 'react'

import Header, { HeaderProps } from '../../components/Header'
import Menu, { MenuProps } from '../../components/Menu'
import AuthLayout from '../../layouts/AuthLayout'
import { PageBackButtonProps } from '../../components/PageBackButton'
import CardList from '../../components/CardList'
import { TabContentProps } from '../../components/TabContent'
import { CourseCardProps } from '../../components/CourseCard'
import RequestCourseModal, {
  RequestCourseModalProps,
} from '../../components/modals/RequestCourseModal'
import SimpleFeedbackModal, {
  SimpleFeedbackModalProps,
} from '../../components/modals/SimpleFeedbackModal'

import * as SC from './styled'

export type SearchClassRequestsTemplateProps = {
  className?: string
  headerProps: HeaderProps
  menuProps: MenuProps
  backButton: PageBackButtonProps
  title: string
  tabs: { label: string; total: number; cards: CourseCardProps[]; value: string }[]
  currentTab: string
  onTabChange: (tab: string) => void
  requestCourseModal: RequestCourseModalProps
  feedbackModalProps?: SimpleFeedbackModalProps
}

export const SearchClassRequestsTemplate: FC<SearchClassRequestsTemplateProps> = (props) => {
  const {
    headerProps,
    menuProps,
    title,
    backButton,
    tabs,
    currentTab,
    onTabChange,
    requestCourseModal,
    feedbackModalProps,
  } = props

  const tabContentProps: TabContentProps = {
    name: '',
    tabs: tabs?.map((tab) => ({
      label: tab.label,
      children: <CardList cards={tab.cards} />,
      numberOfCards: tab.total + '',
      value: tab.value,
    })),
    value: currentTab,
    onChange: (_name, value) => onTabChange?.(value),
  }

  return (
    <AuthLayout header={<Header {...headerProps} withDivider />} menu={<Menu {...menuProps} />}>
      <SC.Content>
        <SC.BackButton {...backButton} />
        <SC.Title>{title}</SC.Title>
        <SC.MyTabContent {...tabContentProps} />
        <RequestCourseModal {...requestCourseModal} />
      </SC.Content>
      {feedbackModalProps && <SimpleFeedbackModal {...feedbackModalProps} />}
    </AuthLayout>
  )
}

export default memo(SearchClassRequestsTemplate)
