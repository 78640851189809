import { Container } from '@material-ui/core'
import styled from 'styled-components'
import { Form as FormC } from 'formik'

import CalendarC from '../form/Calendar'
import ToggleButtonPicker from '../form/ToggleButtonPicker'
import FormSubmit from '../FormSubmit'

export const AvailabilityPerDayForm = styled.div``

export const Wrapper = styled(Container)`
  padding: 0;
  display: flex;
  align-items: center;
`

export const Form = styled(FormC)`
  width: 100%;
`

export const Calendar = styled(CalendarC)``

export const SlotPicker = styled(ToggleButtonPicker)`
  margin-top: 2rem;
`

export const Buttons = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column-reverse;
  margin: 4rem 0 1.6rem;
  ${(props) => props.theme.breakpoints.up('md')} {
    justify-content: flex-start;
  }
`

export const Button = styled(FormSubmit)`
  margin-top: 1.6rem;
  width: auto;
  flex-shrink: 0;
  border-color: ${(props) => props.theme.palette.colors.freeSpeechBlue};
  background-color: ${(props) => props.theme.palette.colors.pureWhite};
  color: ${(props) => props.theme.palette.colors.freeSpeechBlue};
  &:hover {
    border-color: ${(props) => props.theme.palette.colors.freeSpeechBlue};
    background-color: ${(props) => props.theme.palette.colors.freeSpeechBlue};
    color: ${(props) => props.theme.palette.colors.pureWhite};
  }
`

export const SubmitButton = styled(FormSubmit)`
  width: 100%;
`
