import styled from 'styled-components'

import ActionButton from '../../components/ActionButton'
import SimpleTextWithCtaC from '../../components/SimpleTextWithCta'
import FormSubmit from '../../components/FormSubmit'
import SignUpLayout from '../../layouts/SignupLayout'
import BasicTextAndImageC from '../../components/BasicTextAndImage'

export const SignupTeacherStripeTemplate = styled(SignUpLayout)``

export const Title = styled.h1`
  ${(props) => ({ ...props.theme.typography.h3 })}
  color: ${(props) => props.theme.palette.colors.neonCarrot};
  padding: 0 1.6rem;
  margin: 0;
`

export const ReturnButton = styled(ActionButton)`
  margin-top: 5rem;
  ${(props) => props.theme.breakpoints.up('md')} {
    max-width: 20rem;
  }
`

export const SimpleTextWithCta = styled(SimpleTextWithCtaC)`
  padding: 0 1.6rem;
`

export const Actions = styled.div`
  padding: 0 1.6rem;
  width: 100%;
  ${(props) => props.theme.breakpoints.up('md')} {
    width: 60%;
    margin: auto;
  }
`

export const Submit = styled(FormSubmit)`
  margin: 1rem 0 0;
  width: 100%;
  display: block;
`
export const BasicTextAndImage = styled(BasicTextAndImageC)`
  padding-bottom: 1rem;
  & img {
    width: 30rem;
  }
`

export const Action = styled(ActionButton)`
  width: 100%;
  display: block;
  margin: 1rem 0 0;
`

export const Content = styled.div`
  flex-grow: 1;
  ${(props) => props.theme.breakpoints.up('md')} {
    flex-grow: initial;
  }
`
