import React, { FC } from 'react'
import { Form, Formik, FormikHelpers } from 'formik'
import * as Yup from 'yup'

import { FormFieldConfig, FormFieldProps } from '../../types/form'
import FormSubmitErrors, { FormSubmitErrorsProps } from '../FormSubmitErrors'
import { getValidationSchema, renderField } from '../../helpers/FormHelpers'
import { FormSubmitProps } from '../FormSubmit'
import { Icons } from '../Icon/types'
import { light } from '../../theme/palette'

import * as SC from './styled'

export type PotDepositFormValues = {
  amount: string
}

export type PotDepositFormProps = {
  className?: string
  text?: string
  initialValues: PotDepositFormValues
  fieldsProps?: {
    amount: FormFieldProps
  }
  textBelow?: string
  errorTexts?: {
    required: string
    unnecessaryBalance: string
  }
  submitButton: FormSubmitProps
  submitErrors?: FormSubmitErrorsProps['errors']
  onSubmit?: (
    values: PotDepositFormValues,
    formikHelpers: FormikHelpers<PotDepositFormValues>
  ) => void
}

const PotDepositForm: FC<PotDepositFormProps> = (props) => {
  const {
    className,
    text,
    textBelow,
    submitButton,
    errorTexts,
    onSubmit = (_a, _b) => null,
    submitErrors,
    initialValues,
    fieldsProps,
  } = props

  const amountField = {
    name: 'amount',
    Component: SC.Amount,
    validation: Yup.string().required(errorTexts?.required),
    required: true,
    icon: Icons.euro,
    iconColor: light.colors.cornflowerBlue,
  }

  const fields: FormFieldConfig[] = [amountField]

  const validationSchema = getValidationSchema(fields)

  return (
    <SC.PotDepositForm className={className}>
      <SC.Wrapper disableGutters>
        {text && <SC.Text>{text}</SC.Text>}
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {(formikProps) => (
            <Form noValidate>
              <SC.Fields>{renderField(amountField, formikProps, fieldsProps, 0)}</SC.Fields>
              {textBelow && <SC.TextBelow>{textBelow}</SC.TextBelow>}
              <FormSubmitErrors errors={submitErrors} />
              <SC.SubmitButton {...submitButton} autoIcon />
            </Form>
          )}
        </Formik>
      </SC.Wrapper>
    </SC.PotDepositForm>
  )
}

export default PotDepositForm
