import React, { FC } from 'react'

import { ActionButtonProps } from '../ActionButton'
import PageBackButton, { PageBackButtonProps } from '../PageBackButton'
import { FinishedCourseMetaProps } from '../FinishedCourseMeta'

import * as SC from './styled'

export type CallNotYetProps = {
  backButtonProps?: PageBackButtonProps
  title?: string
  text?: string
  cta?: ActionButtonProps
  courseMeta?: FinishedCourseMetaProps
}

const CallNotYet: FC<CallNotYetProps> = (props) => {
  const { backButtonProps, title, text, cta, courseMeta } = props

  return (
    <SC.CallEnded>
      <SC.Wrapper>
        <PageBackButton {...backButtonProps} />
        {title && <SC.Title>{title}</SC.Title>}
        {text && <SC.Text>{text}</SC.Text>}
        {cta && <SC.Action {...cta} />}
        {courseMeta && <SC.CourseMeta {...courseMeta} />}
      </SC.Wrapper>
    </SC.CallEnded>
  )
}

export default CallNotYet
