import gql from 'graphql-tag'

import { Course } from '../../../../generated/graphql'
import CourseFragment from '../../../fragments/CourseFragment'

const applyToCourse = {
  mutation: gql`
    mutation applyToCourse(
      $course: ID!
      $maximum_participants: Int!
      $student: ID
      $teacher: ID
      $date: Date
      $slot: AvailabilitySlot
    ) {
      applyToCourse(
        course: $course
        maximum_participants: $maximum_participants
        student: $student
        teacher: $teacher
        date: $date
        slot: $slot
      ) {
        ...CourseFragment
      }
    }
    ${CourseFragment}
  `,
  transformer: (response: any): Course[] => {
    return response.applyToCourse
  },
}

export default applyToCourse
