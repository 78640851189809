import React, { FC } from 'react'

import { Icons } from '../../Icon/types'
import { NotificationCardProps } from '../../NotificationCard'
import { useNoOverflow } from '../../../hooks/useNoOverflow'

import * as SC from './styled'

export type NotificationsModalProps = {
  className?: string
  title?: string
  cards?: NotificationCardProps[]
  open: boolean
  onClose?: () => void
}

const NotificationsModal: FC<NotificationsModalProps> = (props) => {
  const { className, title, cards, open, onClose } = props

  useNoOverflow(open)

  if (!open) return null

  return (
    <SC.Modal className={className}>
      <SC.Background onClick={onClose} />
      <SC.Content>
        <SC.Head>
          {title && <SC.Title>{title}</SC.Title>}
          <SC.CloseButton onClick={onClose} size={'small'}>
            <SC.Close icon={Icons.close} />
          </SC.CloseButton>
        </SC.Head>
        <SC.Cards>
          {cards?.map((card, key) => (
            <SC.Card {...card} key={key} />
          ))}
        </SC.Cards>
      </SC.Content>
    </SC.Modal>
  )
}

export default NotificationsModal
