import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { parse } from 'query-string'

import { HeaderProps } from '../../components/Header'
import useHeader from '../../hooks/useHeader'
import SignInResetTemplate from '../../templates/SignInReset'
import { actions, selectors } from '../../redux'
import { useFormSubmit } from '../../hooks/useFormSubmit'
import { SignInResetFormProps } from '../../components/SignInResetForm'

const SignInResetPage: React.FC = (pageProps) => {
  const headerProps: HeaderProps = useHeader(pageProps)

  const { t } = useTranslation()

  const qs = (pageProps as any)?.location?.search
  const props: { email?: string; token?: string } = useMemo(() => parse(qs), [qs])

  const [submit, handleSubmit] = useFormSubmit(
    selectors.auth.signInReset,
    actions.auth.signInResetRequest,
    actions.auth.signInResetReset
  )

  const handleSubmitWithProps = useCallback(
    (values) => handleSubmit({ ...values, token: props?.token, email: props?.email }),
    [props, handleSubmit]
  )

  const signupProps: SignInResetFormProps = useMemo(() => {
    return {
      title: t('signInReset_title'),
      text: t('signInReset_text'),
      fieldsProps: {
        email: {
          label: t('signInReset_form_email_label'),
        },
        password: {
          label: t('signInReset_form_password_label'),
        },
        passwordConfirmation: {
          label: t('signInReset_form_passwordConfirmation_label'),
        },
      },
      initialValues: {
        email: props?.email ?? '',
        password: '',
        passwordConfirmation: '',
      },
      errorTexts: {
        required: t('error_required'),
        email: t('error_email'),
        password: t('error_password'),
        passwordConfirmation: t('error_password_confirm'),
      },
      submitButton: {
        text: t('signInReset_submit'),
        pendingText: t('signInReset_submit_pending'),
        successText: t('signInReset_submit_pending'),
        isPending: submit.pending,
        isSuccess: submit.success,
      },
      mandatoryText: t('signInReset_required_text'),
      submitErrors: submit.errors,
      onSubmit: (values) => handleSubmitWithProps(values),
    }
  }, [t, handleSubmitWithProps, submit, props])

  const imageProps = { alt: 'LiberteClass - Connexion', src: '/static/assets/images/signin.png' }

  return (
    <SignInResetTemplate
      headerProps={headerProps}
      signupProps={signupProps}
      imageProps={imageProps}
    />
  )
}

export default SignInResetPage
