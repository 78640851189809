import styled from 'styled-components'
import { Container as MuiContainer } from '@material-ui/core'

export const Container = styled(MuiContainer)`
  min-height: 100vh;
  display: flex;
  flex-flow: column;
  align-items: stretch;
  background: bottom center / cover no-repeat;
  background-color: ${(props) => props.theme.palette.colors.whiteSmoke};
  padding-left: 0;
  padding-right: 0;
  flex-grow: 1;
  ${(props) => props.theme.breakpoints.up('md')} {
    max-width: none;
  }
`
export const Wrapper = styled.div`
  width: 100%;
  flex-grow: 1;
  max-width: ${(props) => props.theme.breakpoints.values.xl}px;
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1.6rem 0;
  ${(props) => props.theme.breakpoints.up('md')} {
    flex-direction: row;
    padding: 5rem 15rem;
    align-items: flex-start;
    justify-content: center;
  }
`
export const Main = styled.div`
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  ${(props) => props.theme.breakpoints.up('md')} {
    max-width: 40rem;
    flex-shrink: 0;
    flex-grow: 0;
    &:last-child {
      max-width: 64rem;
    }
  }
`
export const Side = styled.div`
  width: 100%;
  display: flex;
  ${(props) => props.theme.breakpoints.up('md')} {
    padding-top: 5rem;
  }
`
