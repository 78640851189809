import styled from 'styled-components'

import BasicLink from '../BasicLink'

export const BackButton = styled(BasicLink)`
  margin: 1rem 0 0 1.6rem;
  display: flex;
  ${(props) => ({ ...props.theme.typography.callToAction })}
  text-decoration: none;
  color: ${(props) => props.theme.palette.colors.freeSpeechBlue};
  cursor: pointer;
`
