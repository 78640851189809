import gql from 'graphql-tag'

import DisciplineFragment from './DisciplineFragment'
import GradeFragment from './GradeFragment'

export default gql`
  fragment ThemeFragment on Theme {
    id
    name
    slug
    discipline {
      ...DisciplineFragment
    }
    grade {
      ...GradeFragment
    }
  }
  ${DisciplineFragment}
  ${GradeFragment}
`
