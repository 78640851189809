import styled from 'styled-components'
import { Button as MuiButton } from '@material-ui/core'

import Icon from '../Icon'
import LinkC from '../Link'

export const Link = styled(LinkC)`
  display: inline-flex;
  width: 100%;
  &.MuiLink-underlineHover {
    text-decoration: none;
  }
  &.MuiLink-underlineHover:hover {
    text-decoration: none;
  }
`

export const Button = styled(MuiButton)<{ disabled?: boolean; $outlined?: boolean }>`
  padding: 1.2rem 1.6rem;
  border-radius: 0.5rem;
  width: 100%;
  color: ${(props) =>
    props.$outlined
      ? props.theme.palette.colors.freeSpeechBlue
      : props.theme.palette.colors.pureWhite};
  border: 1px solid
    ${(props) =>
      props.disabled
        ? props.theme.palette.colors.gainsboro
        : props.$outlined
        ? props.theme.palette.colors.freeSpeechBlue
        : props.theme.palette.colors.neonCarrot};
  background-color: ${(props) =>
    props.disabled
      ? props.theme.palette.colors.gainsboro
      : props.$outlined
      ? props.theme.palette.colors.pureWhite
      : props.theme.palette.colors.neonCarrot};

  & > span {
    ${(props) => ({ ...props.theme.typography.h5 })};
    text-transform: none;

    & img {
      margin-right: 1rem;
    }
  }

  &:hover {
    background-color: ${(props) =>
      props.$outlined
        ? props.theme.palette.colors.cornflowerBlue
        : props.theme.palette.colors.kournikova};
    border-color: ${(props) =>
      props.$outlined
        ? props.theme.palette.colors.cornflowerBlue
        : props.theme.palette.colors.kournikova};

    & > span {
      color: ${(props) => props.theme.palette.colors.pureWhite};
      text-decoration: none;
    }
  }
`

export const Span = styled.span``

export const IconIn = styled(Icon)<{ iconSide?: string }>`
  width: 1.8rem;
  height: 1.8rem;
  margin-left: ${(props) => (props.iconSide === 'right' ? '1rem' : '0')};
  margin-right: ${(props) => (props.iconSide === 'left' ? '1rem' : '0')};
`
