import React, { ReactNode } from 'react'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import styled from 'styled-components'

import Link from '../components/Link'
import { Scalars } from '../generated/graphql-contentful'

export enum RichTextTheme {
  Regular = 'Regular',
  WithLegend = 'WithLegend',
}

const isSpacer = (item: string): boolean => {
  return item === '' || item === '\n' || item === ' '
}

function renderText(text: string): string | ReactNode {
  return text?.indexOf('#onetrust-cookies') >= 0 ? <div id="ot-sdk-cookie-policy" /> : text
}

const renderNode = {
  // eslint-disable-next-line react/display-name
  [INLINES.HYPERLINK]: (node: any, children: any) => {
    const InlineLink = styled(Link)`
      color: ${({ theme }) => theme.colors?.primary};
      text-decoration: underline;
    `
    if (node.data.uri === '#cookie-preferences-open') {
      return (
        <InlineLink
          className="js-tc-cookie-preferences"
          onClick={() => {
            typeof window !== 'undefined' &&
              (window as { tC?: any })?.tC?.privacyCenter?.showPrivacyCenter()
          }}
        >
          {children}
        </InlineLink>
      )
    }
    return <InlineLink link={node.data.uri}>{children}</InlineLink>
  },
  // eslint-disable-next-line react/display-name
  [BLOCKS.PARAGRAPH]: (node: any, children: any) => {
    return (
      <>
        {children && (children.length > 1 || !isSpacer(children[0])) && <p>{children}</p>}
        {children.length <= 1 && isSpacer(children[0]) && <p>&nbsp;</p>}
      </>
    )
  },
}

export function RichTextTransformer(data: Scalars['JSON']): React.ReactNode {
  return documentToReactComponents(data, {
    renderText,
    renderNode,
  })
}
