import styled from 'styled-components'

import ActionButton from '../ActionButton'
import FormSubmit from '../FormSubmit'

export const Pot = styled.div``

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 1.6rem;
`

export const Title = styled.h4`
  ${(props) => ({ ...props.theme.typography.h3 })}
  color: ${(props) => props.theme.palette.colors.midnightBlue};
  padding: 3rem 0 0 0;
  margin: 0;
`

export const Periodicity = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  & p {
    margin-bottom: 0.5rem;
  }
`
export const PeriodicityLabel = styled.span`
  ${(props) => ({ ...props.theme.typography.mediumRegular })}
  color: ${(props) => props.theme.palette.colors.chambray};
  padding-right: 0.5rem;
`
export const PeriodicityValue = styled.span`
  ${(props) => ({ ...props.theme.typography.mediumStrong })}
  color: ${(props) => props.theme.palette.colors.chambray};
`

export const PeriodicityButton = styled(ActionButton)`
  padding: 0.5rem 1rem;
  width: auto;
`

export const Button = styled(FormSubmit)`
  margin: 1.6rem 0 0 0;
  width: 100%;
  max-width: 40rem;
`
