import { Container, Divider } from '@material-ui/core'
import styled from 'styled-components'

import Link from '../BasicLink'
import TextFieldC from '../form/TextField'
import ToggleButtonGroupC from '../form/ToggleButtonGroup'
import FormSubmit from '../FormSubmit'
import CheckboxC from '../form/Checkbox'

export const SignUpForm = styled.div`
  padding: 0 1.6rem;
`

export const Wrapper = styled(Container)`
  padding: 1rem;
  ${(props) => props.theme.breakpoints.up('md')} {
    padding: 5rem 15rem;
    display: flex;
    flex-direction: row;
  }
`

export const Content = styled.div`
  width: 100%;
  ${(props) => props.theme.breakpoints.up('md')} {
    max-width: 40rem;
  }
`

export const Title = styled.h1`
  ${(props) => ({ ...props.theme.typography.h1 })}
  color: ${(props) => props.theme.palette.colors.midnightBlue};
  font-size: 3.2rem;
  margin: 0 0 2rem 0;
  ${(props) => props.theme.breakpoints.up('md')} {
    font-size: 4rem;
  }
`

export const Text = styled.p`
  ${(props) => ({ ...props.theme.typography.h6 })}
  color: ${(props) => props.theme.palette.colors.midnightBlue};
  margin: 0 0 1rem 0;
`

export const MuiToggleButtonGroup = styled(ToggleButtonGroupC)``

export const Fields = styled.div`
  margin-top: 2rem;
`

export const MuiTextField = styled(TextFieldC)`
  margin-bottom: 1rem;
  & .MuiFormHelperText-contained {
    text-align: left;
  }
`

export const Checkbox = styled(CheckboxC)``

export const PromoText = styled.p`
  ${(props) => ({ ...props.theme.typography.h4 })}
  color: ${(props) => props.theme.palette.colors.midnightBlue};
  margin: 1.6rem 0 0.8rem 0;
`

export const SponsorText = styled.p`
  ${(props) => ({ ...props.theme.typography.h6 })}
  color: ${(props) => props.theme.palette.colors.midnightBlue};
  margin: 1.5rem 0 0.5rem 0;
`

export const SubmitButton = styled(FormSubmit)`
  margin-top: 2rem;
`

export const MandatoryText = styled.p`
  ${(props) => ({ ...props.theme.typography.smallRegular })}
  color: ${(props) => props.theme.palette.colors.grey};
  margin: 1rem 0;
`

export const MuiDivider = styled(Divider)`
  margin: 1rem 0rem;
`

export const AlreadySignedUpText = styled.p`
  ${(props) => ({ ...props.theme.typography.h6 })}
  color: ${(props) => props.theme.palette.colors.freeSpeechBlue};
  margin: 1rem 0 0 0;
`

export const AlreadySignedUpCta = styled(Link)`
  align-items: center;
  display: flex;
  text-decoration: none;
  text-transform: uppercase;
  margin: 0;
  color: ${(props) => props.theme.palette.colors.freeSpeechBlue};
  max-width: max-content;
  border-bottom: 2px solid transparent;
  ${(props) => ({ ...props.theme.typography.h6 })};
  &:hover {
    border-bottom: 2px solid ${(props) => props.theme.palette.colors.freeSpeechBlue};
    text-decoration: none;
  }
`

export const BoxImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`

export const Image = styled.img`
  width: 25rem;
  height: auto;
  ${(props) => props.theme.breakpoints.up('md')} {
    width: 100%;
    max-width: 51rem;
  }
`
