import gql from 'graphql-tag'

import { Course } from '../../../../generated/graphql'
import CourseFragment from '../../../fragments/CourseFragment'

const pickCourseOfMarket = {
  mutation: gql`
    mutation pickCourseOfMarket($course: ID!, $date: Date!, $slot: AvailabilitySlot!) {
      pickCourseOfMarket(course: $course, date: $date, slot: $slot) {
        ...CourseFragment
      }
    }
    ${CourseFragment}
  `,
  transformer: (response: any): Course => {
    return response.pickCourseOfMarket
  },
}

export default pickCourseOfMarket
