import { Container } from '@material-ui/core'
import styled from 'styled-components'
import { Form } from 'formik'

import CalendarC from '../form/Calendar'
import ToggleButtonPicker from '../form/ToggleButtonPicker'
import FormSubmit from '../FormSubmit'

export const AvailabilityPerDayForm = styled.div``

export const Wrapper = styled(Container)`
  padding: 0;
  display: flex;
  align-items: center;
`

export const FormikForm = styled(Form)`
  ${(props) => props.theme.breakpoints.up('md')} {
    max-width: 50rem;
  }
`

export const Calendar = styled(CalendarC)``

export const SlotPicker = styled(ToggleButtonPicker)`
  margin-top: 2rem;
`

export const Button = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  ${(props) => props.theme.breakpoints.up('md')} {
    justify-content: flex-start;
  }
`

export const SubmitButton = styled(FormSubmit)`
  margin-top: 5rem;
  width: 100%;
  ${(props) => props.theme.breakpoints.up('md')} {
    width: auto;
  }
`
