import styled from 'styled-components'

import ActionButton from '../ActionButton'
import PageTitle from '../PageTitle'
import ImageTextListC from '../ImageTextList'

export const CallSelectChild = styled.div`
  position: relative;
  overflow: hidden;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const Wrapper = styled.div`
  z-index: 5;
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`

export const Action = styled(ActionButton)`
  max-width: calc(100% - 3.2rem);
  margin: 0 1.6rem;
  ${(props) => props.theme.breakpoints.up('md')} {
    max-width: 52rem;
  }
`

export const Title = styled(PageTitle)`
  color: ${(props) => props.theme.palette.colors.midnightBlue};
  max-width: calc(100% - 3.2rem);
  margin: 0 1.6rem;
  padding-left: 0;
  ${(props) => props.theme.breakpoints.up('md')} {
    max-width: 52rem;
  }
`

export const Text = styled.p`
  ${(props) => ({ ...props.theme.typography.smallRegular })}
  color: ${(props) => props.theme.palette.colors.midnightBlue};
  margin: 1.6rem 0;
  text-align: center;
  ${(props) => props.theme.breakpoints.up('md')} {
    max-width: 52rem;
  }
`

export const ImageTextList = styled(ImageTextListC)`
  max-width: calc(100% - 3.2rem);
  margin: 1.6rem;
  ${(props) => props.theme.breakpoints.up('md')} {
    font-size: 4rem;
    & > div > div {
      max-width: 35rem;
    }
  }
`
