import { Container } from '@material-ui/core'
import styled from 'styled-components'

import IconC from '../Icon'
import Link from '../Link'

export const Message = styled(Link)`
  color: ${({ theme }) => theme.palette.colors.pureWhite};
  width: auto;
  display: flex;
  ${(props) => props.theme.breakpoints.up('md')} {
    max-width: 43rem;
  }
`

export const Wrapper = styled(Container)<{ $backgroundColor?: string }>`
  display: flex;
  background-color: ${({ theme, $backgroundColor }) =>
    $backgroundColor || theme.palette.colors.pureWhite};
  border-radius: 0.8rem;
  padding: 0.8rem 1.6rem;
  justify-content: space-between;
  align-items: center;
`

export const Text = styled.p`
  ${(props) => ({ ...props.theme.typography.h6 })}
  color: ${(props) => props.theme.palette.colors.pureWhite};
  margin: 0;
  max-width: 25rem;
`

export const Icon = styled(IconC)`
  width: 2rem;
  height: 2rem;
  color: ${(props) => props.theme.palette.colors.pureWhite};
  & svg {
    width: 1.6rem;
    height: 1.6rem;
  }
`
