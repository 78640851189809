import React, { FC } from 'react'

import Header, { HeaderProps } from '../../components/Header'
import Menu, { MenuProps } from '../../components/Menu'
import Layout from '../../layouts/Layout'
import ImpersonateBanner, { ImpersonateBannerProps } from '../../components/ImpersonateBanner'
import Jitsi, { JitsiProps } from '../../components/Jitsi'
import CallEnded, { CallEndedProps } from '../../components/CallEnded'
import NotFound, { NotFoundProps } from '../../components/NotFound'
import AuthLayout from '../../layouts/AuthLayout'
import CallNotYet, { CallNotYetProps } from '../../components/CallNotYet'
import CallSelectChild, { CallSelectChildProps } from '../../components/CallSelectChild'

import * as SC from './styled'

export enum CallStatus {
  PENDING,
  JOINING,
  CHOOSE_CHILD,
  RUNNING,
  ENDED,
  NOT_YET,
  NOT_ALLOWED,
  NOT_FOUND,
}

export type RoomTemplateProps = {
  className?: string
  headerProps: HeaderProps
  menuProps: MenuProps
  status: CallStatus
  notFoundProps?: NotFoundProps
  impersonateProps?: ImpersonateBannerProps | null
  jitsiProps?: JitsiProps
  callEndedProps?: CallEndedProps
  notAllowedProps?: NotFoundProps
  notYetProps?: CallNotYetProps
  selectChildProps?: CallSelectChildProps
}

const RoomTemplate: FC<RoomTemplateProps> = (props) => {
  const {
    headerProps,
    menuProps,
    status,
    notFoundProps,
    impersonateProps,
    jitsiProps,
    callEndedProps,
    notAllowedProps,
    notYetProps,
    selectChildProps,
  } = props

  if (status === CallStatus.ENDED) {
    return (
      <AuthLayout header={<Header {...headerProps} withDivider />} menu={<Menu {...menuProps} />}>
        <SC.Content>{callEndedProps && <CallEnded {...callEndedProps} />}</SC.Content>
      </AuthLayout>
    )
  }

  if (status === CallStatus.NOT_YET) {
    return (
      <AuthLayout header={<Header {...headerProps} withDivider />} menu={<Menu {...menuProps} />}>
        <SC.Content>{notYetProps && <CallNotYet {...notYetProps} />}</SC.Content>
      </AuthLayout>
    )
  }

  if (status === CallStatus.CHOOSE_CHILD) {
    return (
      <AuthLayout header={<Header {...headerProps} withDivider />} menu={<Menu {...menuProps} />}>
        <SC.Content>{selectChildProps && <CallSelectChild {...selectChildProps} />}</SC.Content>
      </AuthLayout>
    )
  }

  return (
    <Layout
      header={
        status === CallStatus.NOT_FOUND || status === CallStatus.NOT_ALLOWED ? (
          <Header {...headerProps} backgroundColor={notFoundProps?.backgroundColor ?? ''} />
        ) : impersonateProps ? (
          <ImpersonateBanner {...impersonateProps} />
        ) : null
      }
    >
      {status === CallStatus.RUNNING && jitsiProps && <Jitsi {...jitsiProps} />}
      {status === CallStatus.NOT_ALLOWED && notAllowedProps && <NotFound {...notAllowedProps} />}
      {status === CallStatus.NOT_FOUND && notFoundProps && <NotFound {...notFoundProps} />}
    </Layout>
  )
}

export default RoomTemplate
