import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import Resumable from 'resumablejs'

import { Icons } from '../../Icon/types'
import { light } from '../../../theme/palette'

import * as SC from './styled'

export enum UploadFileErrors {
  FILE_ERROR = 'FILE_ERROR',
}

export type UploadFileError = {
  type: string
  params?: {
    [key: string]: any
  }
}

export type UploadFileValue = {
  name: string
  file: string
}

export type UploadFileProps = {
  className?: string
  config: Resumable.ConfigurationHash
  icon?: Icons
  label: string
  color?: string
  name: string
  value?: UploadFileValue
  onChange?: (name: string, value?: UploadFileValue) => void
  error?: string
  help?: string
  translateError?: (error: UploadFileError) => string
  removable?: boolean
  isLabelExternal?: boolean
  required?: boolean
  placeholder?: string
  text?: string
}

const UploadFile: FC<UploadFileProps> = (props) => {
  const {
    className,
    icon,
    label,
    color,
    name,
    value,
    onChange,
    config,
    error,
    help,
    translateError,
    removable,
    isLabelExternal,
    required,
    placeholder,
    text,
  } = props

  const [progress, setProgress] = useState(0)

  const dropNode = useRef((null as unknown) as HTMLDivElement)
  const browseNode = useRef((null as unknown) as HTMLDivElement)
  const [localError, setLocalError] = useState(null as UploadFileError | null)

  const handleChange = useCallback((val?: UploadFileValue) => onChange?.(name, val), [
    name,
    onChange,
  ])
  const onRemoveIconClick = useCallback(() => {
    handleChange({ file: '', name: '' })
  }, [handleChange])

  const resumable = useMemo(() => new Resumable({}), [])

  useEffect(() => {
    const maxFilesErrorCallback = () => null
    resumable.opts = {
      uploadMethod: 'POST',
      testMethod: 'POST',
      method: 'multipart',
      chunkSize: 1024 * 512,
      testChunks: false,
      ...config,
      simultaneousUploads: 1,
      maxFiles: 1,
      maxFilesErrorCallback,
    }
  }, [config, resumable])

  // assign browse
  useEffect(() => {
    if (resumable && browseNode.current) {
      resumable.assignBrowse(browseNode.current, false)
    }
    if (resumable && dropNode.current) {
      resumable.assignDrop(dropNode.current)
    }
    return () => {
      if (resumable) {
        resumable.cancel()
      }
    }
  }, [resumable])

  useEffect(() => {
    let mounted = true
    resumable.on('fileAdded', (_f: Resumable.ResumableFile, _event: DragEvent) => {
      setProgress(0)
      resumable.upload()
    })

    resumable.on('fileSuccess', (f: Resumable.ResumableFile, event: any) => {
      let result

      try {
        result = JSON.parse(event)
      } catch (e) {
        result = {}
      }
      setLocalError(null)

      console.log('result', result, f, event)

      if (mounted) {
        handleChange({ file: result.file, name: f.fileName })
      }
    })

    resumable.on('fileError', (f, event) => {
      setLocalError({
        type: UploadFileErrors.FILE_ERROR,
        params: {
          file: f,
          event,
        },
      })
    })

    resumable.on('progress', () => {
      setProgress(resumable.progress())
    })

    return () => {
      mounted = false
    }
  }, [handleChange, resumable])

  const style = value?.name
    ? {
        icon: Icons.pebbleValidate,
        color: light.colors.cornflowerBlue,
      }
    : {
        icon: Icons.uploadFile,
        color: light.colors.neonCarrot,
      }

  return (
    <SC.DocumentUpload className={className}>
      <SC.Wrapper disableGutters>
        {isLabelExternal && label && (
          <SC.Label required={required} error={!!error}>
            {props.label}
          </SC.Label>
        )}
        {text && <SC.Text>{text}</SC.Text>}
        <SC.UploadBox hasErrors={!!error} ref={dropNode}>
          <SC.Browse ref={browseNode} />
          <SC.DocumentUploadIcon icon={icon || style.icon} color={color} />
          <SC.Texts>
            {label && !isLabelExternal && (
              <SC.DocumentUploadText color={color || style.color}>{label}</SC.DocumentUploadText>
            )}
            {placeholder && !value?.name && (
              <SC.DocumentUploadText color={color || style.color}>
                {placeholder}
              </SC.DocumentUploadText>
            )}
            {value?.name && (
              <SC.DocumentUploadFile color={color || style.color}>
                {value.name}
              </SC.DocumentUploadFile>
            )}
          </SC.Texts>
          <SC.Actions>
            <SC.CircularProgress
              value={progress * 100}
              strokeWidth={4}
              $visible={progress !== 0 && progress !== 1}
            />
          </SC.Actions>
          {value?.name && removable && (
            <SC.RemoveIcon
              icon={Icons.trash}
              color={light.colors.tomato}
              onClick={onRemoveIconClick}
            />
          )}
        </SC.UploadBox>
        {localError && translateError && (
          <SC.HelperText error>{translateError(localError)}</SC.HelperText>
        )}
        {(error || help) && <SC.HelperText error={!!error}>{error || help}</SC.HelperText>}
      </SC.Wrapper>
    </SC.DocumentUpload>
  )
}

export default UploadFile
