import React, { FC } from 'react'

import { ImgProps } from '../../types/image'
import { ActionButtonProps } from '../ActionButton'

import * as SC from './styled'

export type BasicTextAndImageProps = {
  className?: string
  title: string
  subTitle: string
  text: string
  image: ImgProps
  text2?: string
  cta?: ActionButtonProps
}

const BasicTextAndImage: FC<BasicTextAndImageProps> = (props) => {
  const { className, title, subTitle, text, image, text2, cta } = props

  return (
    <SC.BasicTextAndImage className={className}>
      <SC.Wrapper>
        <SC.Title>{title}</SC.Title>
        <SC.Subtitle>{subTitle}</SC.Subtitle>
        <SC.BoxImage>
          <SC.Image src={image.src} alt={image.alt} />
        </SC.BoxImage>
        <SC.Text>{text}</SC.Text>
        {text2 && <SC.Text2>{text2}</SC.Text2>}
        {cta && <SC.CTA {...cta} />}
      </SC.Wrapper>
    </SC.BasicTextAndImage>
  )
}

export default BasicTextAndImage
