import React, { FC, useCallback } from 'react'

import TextField from '../form/TextField'

import * as SC from './styled'

export type FilterValue = { [key: string]: string | string[] }

export type FilterComponent = FC<{
  value?: string | string[]
  onChange?: (name: string, value: string | string[]) => void
}>

export type FilterFieldConfig = {
  name: string
  Component?: FilterComponent
  required?: boolean
  [key: string]: any
}

export type FiltersProps = {
  className?: string
  title: string
  fields: FilterFieldConfig[]
  value: FilterValue
  onChange: (value: FilterValue) => void
  isOpen: boolean
  setIsOpen?: (open: boolean) => void
}

const Filters: FC<FiltersProps> = (props) => {
  const { className, title, fields, value, onChange, isOpen, setIsOpen } = props

  const handleChange = useCallback(
    (n, v) => {
      onChange?.({ ...value, [n]: v })
    },
    [onChange, value]
  )

  return (
    <SC.Filters className={className}>
      <SC.Collapse title={title} isOpen={isOpen} onClick={() => setIsOpen?.(!isOpen)}>
        <SC.Muidivider />
        <SC.Fields>
          {fields?.map(
            ({ Component = TextField as FilterComponent, ...fieldProps }, key) =>
              Component && (
                <Component
                  {...fieldProps}
                  key={key}
                  value={value?.[fieldProps?.name]}
                  onChange={handleChange}
                />
              )
          )}
        </SC.Fields>
      </SC.Collapse>
    </SC.Filters>
  )
}

export default Filters
