import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { isEqual } from 'lodash'

import { HeaderProps } from '../../components/Header'
import useHeader from '../../hooks/useHeader'
import { MenuProps } from '../../components/Menu'
import useMenu from '../../hooks/useMenu'
import {
  AvailabilitySlot,
  Course,
  CourseStatus,
  CourseType,
  UserType,
} from '../../generated/graphql'
import { RestrictedReactFC } from '../../types/common'
import { actions, selectors } from '../../redux'
import { router, routesPath } from '../../router'
import { SearchResultsTemplate, SearchResultsTemplateProps } from '../../templates/SearchResults'
import { CourseCardProps } from '../../components/CourseCard'
import { useFormSubmit } from '../../hooks/useFormSubmit'
import { dateAndSlotToDate } from '../../graphql/enums/AvailabilitySlot'
import { light } from '../../theme/palette'
import { CourseDetailsModalProps } from '../../components/modals/CourseDetailsModal'
import {
  ApplyToCoursePayloadProps,
  CreateCoursePayloadProps,
} from '../../redux/search/types/actions'
import useBackButton from '../../hooks/useBackButton'
import { courseToFutureCardProps } from '../../transformers/courseTransformers'
import { courseTypeTranslation } from '../../graphql/enums/CourseType'
import useFeedbackModal from '../../hooks/useFeedbackModal'
import PreloadTemplate, { PreloadTemplateProps } from '../../templates/Preload'

const SearchTeacherResultsPage: RestrictedReactFC<
  RouteComponentProps<{
    teacher: string
    theme: string
    child?: string
  }>
> = (props) => {
  const headerProps: HeaderProps = useHeader()
  const menuProps: MenuProps = useMenu(props)
  const { t } = useTranslation()
  const type = CourseType.OneOff
  const size = '1'
  const user = useSelector(selectors.auth.user)
  const teacher = props?.match?.params?.teacher
  const child = props?.match?.params?.child
  const theme = props?.match?.params?.theme
  const history = useHistory()

  const [page, setPage] = useState(1)

  const [search, handleSearch] = useFormSubmit(
    selectors.search.search,
    actions.search.searchRequest,
    actions.search.searchReset
  )

  const [detailsModalState, setDetailsModalState] = useState<{ open: boolean; data?: Course }>({
    open: false,
  })

  const backButton = useBackButton()

  const dispatch = useDispatch()
  const onComplete = useCallback(() => {
    history.push(router(routesPath.dashboard))
    dispatch(actions.search.clearSearch())
  }, [history, dispatch])

  const [setFeedbackModalOpen, feedbackModalProps] = useFeedbackModal({
    title: t('searchResults_applyFeedback_title'),
    subtitle: t('searchResults_applyFeedback_subtitle'),
    onClose: onComplete,
  })

  const handleComplete = useCallback(() => {
    setFeedbackModalOpen(true)
    setDetailsModalState({ open: false })
  }, [setFeedbackModalOpen])

  const [applyToCourse, handleApplyToCourse] = useFormSubmit(
    selectors.search.applyToCourse,
    actions.search.applyToCourseRequest,
    actions.search.applyToCourseReset,
    handleComplete
  )

  const [createCourse, handleCreateCourse] = useFormSubmit(
    selectors.search.createCourse,
    actions.search.createCourseRequest,
    actions.search.createCourseReset,
    handleComplete
  )

  const detailsModalProps: CourseDetailsModalProps = useMemo(() => {
    const course = detailsModalState?.data
    return {
      title: courseTypeTranslation(t, course?.type ?? CourseType.OneOff),
      date: t('course_card_date', {
        date: dateAndSlotToDate(course?.date, course?.slot ?? undefined),
      }),
      teacherProfile: {
        name: course?.lesson?.theme?.discipline?.name ?? '',
        level: course?.teacher?.full_name ?? '',
        profilePicture: {
          alt: 'LiberteClass - profil',
          src: course?.teacher?.avatar ?? '/static/assets/images/frank.png',
        },
        nameColor: light.colors.midnightBlue,
        levelColor: light.colors.freeSpeechBlue,
        ...(course?.teacher?.id && {
          linkProps: {
            href: router(routesPath.teacher, { id: course?.teacher?.id }),
          },
        }),
      },
      subjectLabel: t('searchResults_detailsModal_discipline'),
      subject: course?.lesson?.theme?.discipline?.name ?? '',
      lessonLabel: t('searchResults_detailsModal_lesson'),
      lesson: course?.lesson?.name ?? '',
      descriptionLabel: t('searchResults_detailsModal_description'),
      description: course?.lesson?.description ?? '',
      priceLabel: t('searchResults_detailsModal_price'),
      price: t('course_card_price', {
        price: (course?.price_per_participant ?? course?.maximum_price ?? 0) / 100,
      }),
      cancelButton: {
        text: t('back'),
        onClick: () => setDetailsModalState({ open: false }),
      },
      confirmButton: {
        text: t('searchResults_detailsModal_apply'),
        isPending: applyToCourse?.pending || createCourse?.pending,
        onClick: () => {
          if (course?.status !== CourseStatus.Probability) {
            const input: ApplyToCoursePayloadProps = {
              id: course?.id ?? '',
              date: course?.date,
              slot: course?.slot ?? undefined,
              student: child,
              teacher: course?.teacher?.id,
              size: size ?? '0',
            }
            handleApplyToCourse(input)
          } else {
            const input: CreateCoursePayloadProps = {
              type: type ?? CourseType.OneOff,
              lesson: theme ?? '',
              date: course?.date,
              slot: course?.slot ?? AvailabilitySlot.H12,
              student: child,
              teacher: course?.teacher?.id ?? '',
              size: size ?? '0',
            }
            handleCreateCourse(input)
          }
        },
      },
      submitErrors: applyToCourse?.errors || createCourse?.errors,
      open: detailsModalState?.open,
      handleClose: () => setDetailsModalState({ open: false }),
      onClose: () => setDetailsModalState({ open: false }),
    }
  }, [
    detailsModalState?.data,
    detailsModalState?.open,
    t,
    applyToCourse?.pending,
    applyToCourse?.errors,
    createCourse?.pending,
    createCourse?.errors,
    child,
    size,
    handleApplyToCourse,
    type,
    theme,
    handleCreateCourse,
  ])

  const searchProps: SearchResultsTemplateProps = {
    title: t('searchRecurrence_title'),
    subTitle: t('searchRecurrenceResults_subtitle'),
    text: t('searchRecurrenceResults_text'),
    emptyText: t('searchRecurrenceResults_empty'),
    cards: search?.data?.data?.map(
      (course: Course): CourseCardProps => ({
        ...courseToFutureCardProps(course, t, user, true),
        link: {
          onClick: () => setDetailsModalState({ open: true, data: course }),
        },
      })
    ),
    ...(search?.data?.paginatorInfo?.hasMorePages && {
      loadMoreProps: {
        text: t('load_more'),
        pendingText: t('load_more_pending'),
        isPending: search.pending,
        onClick: () => setPage((before) => before + 1),
        autoIcon: true,
      },
    }),
    submitErrors: search.errors,
    autoRegisterAllowed: false,
    hasAvailabilities: false,
    detailsModalProps,
    headerProps: headerProps,
    menuProps: menuProps,
    backButton,
    feedbackModalProps,
  }

  useEffect(() => {
    return () => {
      dispatch(actions.search.searchReset(null))
    }
  }, [dispatch])

  useEffect(() => {
    //
    const input = {
      first: 25,
      page,
      teacher,
      type,
      theme,
      student: child,
      price: '65',
      size: 1,
    }
    if (!isEqual(search?.params, input)) {
      handleSearch(input)
    }
  }, [teacher, type, child, theme, search?.params, page, handleSearch])

  const preloadProps: PreloadTemplateProps = useMemo(
    () => ({
      headerProps,
      menuProps,
      backButton,
      text: t('preload_text'),
    }),
    [headerProps, menuProps, backButton, t]
  )

  // first load
  if (search.pending && !search.data) {
    return <PreloadTemplate {...preloadProps} />
  }

  return <SearchResultsTemplate {...searchProps} />
}

SearchTeacherResultsPage.restrictedUserTypes = [UserType.Guardian, UserType.Student]

export default SearchTeacherResultsPage
