import { FormikProps } from 'formik'
import React, { ReactNode } from 'react'
import { Omit } from '@material-ui/core'
import * as Yup from 'yup'

import type { FormFieldConfig, FormFieldProps, FormFieldsProps } from '../types/form'
import { FormSubmitErrorsProps } from '../components/FormSubmitErrors'

export type renderFieldProps = Omit<FormFieldConfig, 'Component'> &
  FormFieldProps & {
    key: number
  }

export const renderField = (
  fieldConfig: FormFieldConfig,
  formikProps: FormikProps<any>,
  fieldsProps?: FormFieldsProps,
  index?: number | string,
  renderer?: (Component: any, p: renderFieldProps) => ReactNode,
  submitErrors?: FormSubmitErrorsProps['errors']
): ReactNode => {
  const { Component, ...otherConfigs } = fieldConfig
  const fieldProps = (fieldsProps as any)?.[fieldConfig?.name]
  const submitError = submitErrors?.[0]?.label
  const error = (formikProps.touched as any)?.[fieldConfig?.name]
    ? ((formikProps.errors as any)?.[fieldConfig?.name] as string)
    : fieldProps?.error
    ? fieldProps.error
    : ''
  const value = (formikProps.values as any)?.[fieldConfig?.name]
  const onChange =
    otherConfigs?.onChange || fieldProps?.onChange
      ? (n: string, v: string) => {
          otherConfigs?.onChange?.(n, v)
          fieldProps?.onChange?.(n, v)
          formikProps.setFieldValue(n, v)
        }
      : formikProps.setFieldValue

  const props: renderFieldProps = {
    key: index ?? 0,
    ...otherConfigs,
    ...fieldProps,
    error: error || submitError,
    value,
    onChange,
  }

  return renderer ? renderer(Component, props) : Component ? <Component {...props} /> : null
}

export const fieldArrayProps = (
  parents: [
    {
      name: string
      index: number
    }
  ],
  fieldConfig: FormFieldConfig,
  formikProps: FormikProps<any>,
  fieldsProps?: FormFieldsProps,
  index?: number | string,
  submitErrors?: FormSubmitErrorsProps['errors']
): renderFieldProps => {
  // eslint-disable-next-line
  const { Component, ...otherConfigs } = fieldConfig
  const fieldProps = (fieldsProps as any)?.[fieldConfig?.name]
  const submitError = submitErrors?.[0]?.label

  let touched = formikProps.touched as any
  let errors = formikProps.errors as any
  let values = formikProps.values as any
  let names = ''
  parents.forEach((p) => {
    touched = touched?.[p?.name]?.[p?.index]
    errors = errors?.[p?.name]?.[p?.index]
    values = values?.[p?.name]?.[p?.index]
    names += `${p?.name}.${p?.index}`
  })
  const error = touched?.[fieldConfig?.name]
    ? (errors?.[fieldConfig?.name] as string)
    : fieldProps?.error
    ? fieldProps.error
    : ''
  const value = values?.[fieldConfig?.name]

  const props: renderFieldProps = {
    key: index ?? 0,
    ...otherConfigs,
    ...fieldProps,
    error: error || submitError,
    value,
    onChange: formikProps.setFieldValue,
    name: `${names}.${fieldConfig?.name}`,
  }
  if (typeof props.conditional !== 'string') {
    delete props.conditional
  }

  return props
}

export const renderFieldArrayField = (
  parents: [
    {
      name: string
      index: number
    }
  ],
  fieldConfig: FormFieldConfig,
  formikProps: FormikProps<any>,
  fieldsProps?: FormFieldsProps,
  index?: number | string,
  renderer?: (Component: any, p: renderFieldProps) => ReactNode,
  submitErrors?: FormSubmitErrorsProps['errors']
): ReactNode => {
  const { Component } = fieldConfig

  const props: renderFieldProps = fieldArrayProps(
    parents,
    fieldConfig,
    formikProps,
    fieldsProps,
    index,
    submitErrors
  )

  return renderer ? renderer(Component, props) : Component ? <Component {...props} /> : null
}

export const getValidationSchema = (fieldsConfig: FormFieldConfig[]) =>
  Yup.object().shape(
    fieldsConfig?.reduce((arr, field) => ({ ...arr, [field.name as string]: field.validation }), {})
  )

export const compileInitialState = (fields: FormFieldConfig[]) =>
  fields?.reduce(
    (arr, field) => ({
      ...arr,
      [field.name]: field?.defaultValue !== undefined ? field.defaultValue : '',
    }),
    {}
  )
