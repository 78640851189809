import gql from 'graphql-tag'

import TeacherDisciplineFragment from './TeacherDisciplineFragment'
import TeacherDocumentFragment from './TeacherDocumentFragment'

export default gql`
  fragment TeacherFragment on Teacher {
    id
    siret
    cv {
      ...TeacherDocumentFragment
    }
    diploma {
      ...TeacherDocumentFragment
    }
    company_certificate {
      ...TeacherDocumentFragment
    }
    introduction
    price
    maximum_participants
    stripe_details_submitted
    stripe_charges_activated
    stripe_payouts_activated
    card_brand
    card_last_four
    payouts_schedule_interval
    disciplines {
      ...TeacherDisciplineFragment
    }
  }
  ${TeacherDisciplineFragment}
  ${TeacherDocumentFragment}
`
