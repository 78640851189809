import React, { useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { HeaderProps } from '../../components/Header'
import useHeader from '../../hooks/useHeader'
import { MenuProps } from '../../components/Menu'
import useMenu from '../../hooks/useMenu'
import { Course, SortOrder, UserType } from '../../generated/graphql'
import { RestrictedReactFC } from '../../types/common'
import { actions, selectors } from '../../redux'
import { courseToMaterialCardProps } from '../../transformers/courseTransformers'
import useBackButton from '../../hooks/useBackButton'
import { FiltersProps } from '../../components/Filters'
import PreferencesCourseMaterialsTemplate, {
  PreferencesCourseMaterialsTemplateProps,
} from '../../templates/PreferencesCourseMaterials'
import { router, routesPath } from '../../router'
import { UsersSliderProps } from '../../components/UsersSlider'

const PreferencesCourseMaterialsPage: RestrictedReactFC<RouteComponentProps> = (props) => {
  const headerProps: HeaderProps = useHeader()
  const menuProps: MenuProps = useMenu(props)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const user = useSelector(selectors.auth.user)
  const coursesWithMaterial = useSelector(selectors.preferences.coursesWithMaterial)
  const discilpines = useSelector(selectors.app.disciplines)
  const grades = useSelector(selectors.app.grades)
  const backButton = useBackButton(router(routesPath.preferences))

  const students = user?.guardian?.children

  const [state, setState] = useState({
    page: 1,
    student: students?.[0]?.id ?? '',
    discipline: 'all',
    grade: 'all',
    sort: SortOrder.Desc as string,
  })

  const lists = []
  if (coursesWithMaterial?.data?.data && coursesWithMaterial?.data?.data?.length > 0) {
    lists.push({
      cards: coursesWithMaterial?.data?.data?.map((c: Course) => courseToMaterialCardProps(c, t)),
      ...(coursesWithMaterial?.data?.paginatorInfo?.hasMorePages && {
        buttonProps: {
          text: t('load_more'),
          pendingText: t('load_more_pending'),
          isPending: coursesWithMaterial.pending,
          onClick: () => setState((before) => ({ ...before, page: before.page + 1 })),
        },
      }),
    })
  }

  const usersSliderProps: UsersSliderProps | undefined =
    students && students?.length > 1
      ? {
          label: t('reporting_form_student_label'),
          options: students?.map((student) => ({
            avatar: {
              alt: '',
              src: student?.avatar ?? '',
            },
            label: student?.first_name ?? '',
            value: student?.id ?? '',
          })),
          value: state.student,
          onChange: (_name, student) => setState((before) => ({ ...before, student })),
        }
      : undefined

  const [filterOpen, setFilterOpen] = useState(false)

  const filtersProps: FiltersProps = {
    title: t('filters_title'),
    isOpen: filterOpen,
    fields: [
      {
        name: 'discipline',
        label: t('filters_discipline'),
        isLabelExternal: true,
        fullWidth: true,
        select: true,
        selectOptions: [
          {
            label: t('filters_all'),
            value: 'all',
          },
        ].concat(
          discilpines?.map((discipline) => ({
            label: discipline.name,
            value: discipline.id,
          })) ?? []
        ),
      },
      {
        name: 'grade',
        label: t('filters_grade'),
        isLabelExternal: true,
        fullWidth: true,
        select: true,
        selectOptions: [
          {
            label: t('filters_all'),
            value: 'all',
          },
        ].concat(
          grades?.map((grade) => ({
            label: grade.name,
            value: grade.id,
          })) ?? []
        ),
      },
      {
        name: 'sort',
        label: t('filters_order'),
        isLabelExternal: true,
        fullWidth: true,
        select: true,
        selectOptions: [
          {
            label: t('filters_order_date_desc'),
            value: SortOrder.Desc,
          },
          {
            label: t('filters_order_date_asc'),
            value: SortOrder.Asc,
          },
        ],
      },
    ],
    onChange: (value) =>
      setState({
        student: state.student,
        page: 1,
        grade: value.grade as string,
        discipline: value.discipline as string,
        sort: value.sort as string,
      }),
    setIsOpen: setFilterOpen,
    value: {
      grade: state.grade,
      discipline: state.discipline,
      sort: state.sort,
    },
  }

  const planningProps: PreferencesCourseMaterialsTemplateProps = {
    headerProps,
    menuProps,
    backButton,
    title: t('preferencesCoursesMaterial_title'),
    filtersProps,
    lists,
    usersSliderProps,
  }

  //
  // EFFECTS
  //
  useEffect(() => {
    return () => {
      dispatch(actions.preferences.coursesWithMaterialReset(undefined))
    }
  }, [dispatch])

  useEffect(() => {
    dispatch(
      actions.preferences.coursesWithMaterialRequest({
        first: 100,
        page: state.page,
        filters: {
          ...(state.student && { student: state.student }),
          ...(state.grade !== 'all' && { grade: state.grade }),
          ...(state.discipline !== 'all' && { discipline: state.discipline }),
          sort: state.sort,
        },
      })
    )
  }, [dispatch, state])

  return <PreferencesCourseMaterialsTemplate {...planningProps} />
}

PreferencesCourseMaterialsPage.restrictedUserTypes = [UserType.Guardian, UserType.Student]

export default PreferencesCourseMaterialsPage
