import React, { FC } from 'react'

import { TextFieldProps } from '../form/TextField'
import { BasicLinkProps } from '../BasicLink'
import { DocumentUploadWrapperProps } from '../DocumentUploadWrapper'
import { AddressProps } from '../form/Address'

import * as SC from './styled'

export type MultipleDifferentFieldProps = {
  className?: string
  title: string
  text?: string
  fields: TextFieldProps[]
  address?: AddressProps
  cta?: BasicLinkProps
  document?: DocumentUploadWrapperProps
  onChange?: (name: string, value: string) => void
  values?: { [key: string]: string }
}

const MultipleDifferentField: FC<MultipleDifferentFieldProps> = (props) => {
  const { className, title, text, fields, address, cta, document, onChange, values } = props

  if (values) {
    fields.forEach((field) => {
      if (typeof values[field.name] === 'string') {
        field.value = values[field.name]
      }
    })
  }

  return (
    <SC.MultipleDifferentField className={className}>
      <SC.Wrapper disableGutters maxWidth={false}>
        <SC.Title>{title}</SC.Title>
        {text && <SC.Text>{text}</SC.Text>}
        <SC.Fields>
          {fields.map((field, index) => (
            <React.Fragment key={index}>
              {document && index !== 0 && <SC.DocumentField {...document} />}
              <SC.Field
                {...field}
                onChange={(name, value) => onChange?.(field?.name, value)}
                value={field?.value}
              />
              {address && <SC.Address {...address} />}
            </React.Fragment>
          ))}
        </SC.Fields>
        {cta && <SC.Cta {...cta} />}
      </SC.Wrapper>
    </SC.MultipleDifferentField>
  )
}

export default MultipleDifferentField
