import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { router, routesPath } from '../router'
import { selectors } from '../redux'
import { NotFoundTemplateProps } from '../templates/NotFound'
import { RichTextTransformer } from '../helpers/ContentfulHelpers'
import { HeaderProps } from '../components/Header'

const useNotFound = (headerProps: HeaderProps): NotFoundTemplateProps => {
  const contentful = useSelector(selectors.app.contentful)

  return useMemo(
    () => ({
      headerProps,
      title: contentful?.notFound?.title ?? '',
      body: RichTextTransformer(contentful?.notFound?.body?.json),
      cta: {
        text: contentful?.notFound?.cta?.label ?? '',
        link: {
          link: router(routesPath.dashboard),
        },
      },
      image: {
        alt: 'LiberteClasse - 404 - NotFound',
        src: '/static/assets/images/404.png',
      },
      backgroundColor: '#F1F3FF',
      backgroundImage: {
        alt: 'LiberteClasse - Background - NotFound',
        src: '/static/assets/images/cloud.png',
      },
    }),
    [
      contentful?.notFound?.body?.json,
      contentful?.notFound?.cta?.label,
      contentful?.notFound?.title,
      headerProps,
    ]
  )
}

export default useNotFound
