import React, { useCallback, useEffect } from 'react'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { HeaderProps } from '../../components/Header'
import useHeader from '../../hooks/useHeader'
import { MenuProps } from '../../components/Menu'
import useMenu from '../../hooks/useMenu'
import { UserType } from '../../generated/graphql'
import { RestrictedReactFC } from '../../types/common'
import { router, routesPath } from '../../router'
import { actions, selectors } from '../../redux'
import useBackButton from '../../hooks/useBackButton'
import { useFormSubmit } from '../../hooks/useFormSubmit'
import PreferencesWalletWithdrawalTemplate, {
  PreferencesWalletWithdrawalTemplateProps,
} from '../../templates/PreferencesWalletWithdrawal'
import useFeedbackModal from '../../hooks/useFeedbackModal'

const PreferencesWalletWithdrawalPage: RestrictedReactFC<RouteComponentProps> = (props) => {
  const headerProps: HeaderProps = useHeader()
  const menuProps: MenuProps = useMenu(props)
  const { t } = useTranslation()
  const user = useSelector(selectors.auth.user)
  const backButton = useBackButton(router(routesPath.preferencesWallet))
  const dispatch = useDispatch()
  const history = useHistory()

  const [setConfirmationOpen, feedbackModalProps] = useFeedbackModal({
    title: t('preferences_wallet_withdrawal_title'),
    subtitle: t('preferences_wallet_withdrawal_confirm'),
    closeLabel: t('close'),
    onClose: () => history.push(router(routesPath.preferencesWallet)),
  })

  const onComplete = useCallback(() => {
    dispatch(actions.auth.reloadWalletRequest(null))
    setConfirmationOpen(true)
  }, [dispatch, setConfirmationOpen])

  const [submitWithdrawal, handleSubmitWithdrawal] = useFormSubmit(
    selectors.preferences.withdrawal,
    actions.preferences.withdrawalRequest,
    actions.preferences.withdrawalReset,
    onComplete
  )

  const preferencesProps: PreferencesWalletWithdrawalTemplateProps = {
    menuProps,
    headerProps,
    title: t('preferences_wallet_withdrawal_title'),
    withdrawalFormProps: {
      text: t('preferences_wallet_withdrawal_text'),
      fieldsProps: {
        amount: {
          label: t('preferences_wallet_withdrawal_form_amount_label'),
          isLabelExternal: true,
          maxValue: (user?.wallet?.amount ?? 0) / 100,
          helperText: t('preferences_wallet_withdrawal_form_amount_help', {
            max: (user?.wallet?.amount ?? 0) / 100,
          }),
        },
        iban: {
          label: t('preferences_wallet_withdrawal_form_iban_label'),
          isLabelExternal: true,
        },
      },
      initialValues: {
        amount: '',
        iban: '',
      },
      errorTexts: {
        required: t('error_required'),
        iban: t('error_iban'),
        max: t('error_maxWithdrawal'),
      },
      submitButton: {
        text: t('submit_label'),
        isPending: submitWithdrawal.pending,
        isSuccess: submitWithdrawal.success,
      },
      submitErrors: submitWithdrawal.errors,
      onSubmit: (values) => handleSubmitWithdrawal(values),
    },
    feedbackModalProps,
    backButton,
  }

  useEffect(() => {
    dispatch(actions.auth.reloadWalletRequest(null))
  }, [dispatch])

  return <PreferencesWalletWithdrawalTemplate {...preferencesProps} />
}

PreferencesWalletWithdrawalPage.restrictedUserTypes = [UserType.Guardian]

export default PreferencesWalletWithdrawalPage
