import { Container } from '@material-ui/core'
import styled from 'styled-components'

import Icon from '../Icon'

export const RatingModality = styled.div``

export const Wrapper = styled(Container)``

export const ModalityHeader = styled.div`
  display: flex;
  justify-content: space-between;
`

export const ModalityInfo = styled(Icon)`
  margin-right: 1rem;
  width: 3rem;
  height: 3rem;
`

export const ModalityLabel = styled.span`
  ${(props) => ({ ...props.theme.typography.h5 })}
  color: ${(props) => props.theme.palette.colors.freeSpeechBlue};
`
