import React from 'react'
import { useTranslation } from 'react-i18next'

import { HeaderProps } from '../../components/Header'
import useHeader from '../../hooks/useHeader'
import SignUpStandByTemplate from '../../templates/SignUpStandBy'
import { app } from '../../configuration'
import { ActionButtonProps } from '../../components/ActionButton'
import { router, routesPath } from '../../router'

const SignUpStandByPage: React.FC = (_props) => {
  const headerProps: HeaderProps = useHeader()

  const { t } = useTranslation()

  const textAndImageProps = {
    title: t('signUpStandBy_title'),
    subTitle: t('signUpStandBy_subtitle'),
    text: t('signUpStandBy_text'),
    text2: t('signUpStandBy_text2'),
    image: { alt: 'LiberteClass - Merci', src: '/static/assets/images/standby.png' },
  }

  const cta: ActionButtonProps = {
    text: t('signUpStandBy_back'),
    link: {
      link: app.PUBLIC_URL,
    },
    type: 'button',
  }

  const logoutButtonProps: ActionButtonProps = {
    text: t('logout'),
    link: {
      link: router(routesPath.signOut),
    },
  }

  return (
    <SignUpStandByTemplate
      headerProps={headerProps}
      textImageProps={textAndImageProps}
      cta={cta}
      logoutButtonProps={logoutButtonProps}
    />
  )
}

export default SignUpStandByPage
