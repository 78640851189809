import React, { FC } from 'react'

import { UploadFileProps } from '../form/UploadFile'

import * as SC from './styled'

export type DocumentUploadWrapperProps = {
  className?: string
  title: string
  text?: string
  documents: UploadFileProps[]
}

const DocumentUploadWrapper: FC<DocumentUploadWrapperProps> = (props) => {
  const { className, title, text, documents } = props

  return (
    <SC.DocumentUploadWrapper className={className}>
      <SC.Wrapper disableGutters>
        <SC.Title>{title}</SC.Title>
        {text && <SC.Text>{text}</SC.Text>}
        <SC.Documents>
          {documents.map((document, index) => (
            <SC.DocumentUpload key={index} {...document} />
          ))}
        </SC.Documents>
      </SC.Wrapper>
    </SC.DocumentUploadWrapper>
  )
}

export default DocumentUploadWrapper
