import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { HeaderProps } from '../../components/Header'
import useHeader from '../../hooks/useHeader'
import SignUpTeacherPaymentsTemplate from '../../templates/SignUpTeacherPayments'
import { SimpleTextWithCtaProps } from '../../components/SimpleTextWithCta'
import { FormSubmitProps } from '../../components/FormSubmit'
import { actions, selectors } from '../../redux'
import { router, routesPath } from '../../router'
import { app } from '../../configuration'
import { BasicTextAndImageProps } from '../../components/BasicTextAndImage'
import { ActionButtonProps } from '../../components/ActionButton'

const SignUpTeacherPaymentsPage: React.FC = (_props) => {
  const headerProps: HeaderProps = useHeader()
  const { t } = useTranslation()

  const dispatch = useDispatch()
  const loadStripeAccountLink = useSelector(selectors.auth.loadStripeAccountLink)
  const user = useSelector(selectors.auth.user)

  const title = t('signUpTeacherPayments_title')

  const textWithCtaProps: SimpleTextWithCtaProps = {
    text: t('signUpTeacherPayments_text'),
    image: {
      src: '/static/assets/images/stripeLanding.png',
      alt: 'LiberteClass - Stripe Landing',
    },
    ...(loadStripeAccountLink?.data && {
      cta: {
        text: t('signUpTeacherPayments_cta'),
        link: {
          link: loadStripeAccountLink?.data,
        },
      },
    }),
  }

  const submitProps: FormSubmitProps | undefined = !loadStripeAccountLink?.success
    ? {
        type: 'button',
        isPending: true,
        text: t('signUpTeacherPayments_cta_pending'),
      }
    : undefined

  useEffect(() => {
    dispatch(
      actions.auth.loadStripeAccountLinkRequest({
        return_url: app.APP_URL + router(routesPath.signUpTeacherPayments),
      })
    )
  }, [dispatch])

  const hasSubmitted: boolean = user?.teacher?.stripe_details_submitted ?? false

  const confirmProps: BasicTextAndImageProps | undefined = hasSubmitted
    ? {
        title: t('signUpTeacherPayments_confirm_title'),
        subTitle: t('signUpTeacherPayments_confirm_subtitle'),
        text: t('signUpTeacherPayments_confirm_text'),
        image: {
          alt: 'LiberteClass - Waiting for Stripe',
          src: '/static/assets/images/stripeLanding.png',
        },
      }
    : undefined

  const logoutButtonProps: ActionButtonProps = {
    text: t('logout'),
    link: {
      link: router(routesPath.signOut),
    },
  }

  return (
    <SignUpTeacherPaymentsTemplate
      headerProps={headerProps}
      title={title}
      textWithCtaProps={textWithCtaProps}
      submitProps={submitProps}
      confirmProps={confirmProps}
      logoutButtonProps={logoutButtonProps}
    />
  )
}

export default SignUpTeacherPaymentsPage
