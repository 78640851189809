import styled from 'styled-components'

import ImageTextListC from '../../components/ImageTextList'
import PageTitle from '../../components/PageTitle'

export const Content = styled.div``

export const Title = styled(PageTitle)``

export const ImageTextList = styled(ImageTextListC)`
  padding: 1.6rem;
`
