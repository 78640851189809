import React, { FC } from 'react'

import ModalContainer from '../ModalContainer'
import { WalletWithdrawalFormProps } from '../../WalletWithdrawalForm'

import * as SC from './styled'

export type WalletWithdrawalModalProps = {
  className?: string
  headerText: string
  text?: string
  open: boolean
  formProps: WalletWithdrawalFormProps
  onClose?: () => void
}

const WalletWithdrawalModal: FC<WalletWithdrawalModalProps> = (props) => {
  const { className, headerText, open, onClose, formProps, text } = props

  return (
    <ModalContainer open={open} onClose={onClose} className={className}>
      <SC.HeaderText>{headerText}</SC.HeaderText>
      <SC.Content>
        {text && <SC.Text>{text}</SC.Text>}
        <SC.Form {...formProps} />
      </SC.Content>
    </ModalContainer>
  )
}

export default WalletWithdrawalModal
