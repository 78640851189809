import React, { useCallback, useMemo } from 'react'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { HeaderProps } from '../../components/Header'
import useHeader from '../../hooks/useHeader'
import { MenuProps } from '../../components/Menu'
import useMenu from '../../hooks/useMenu'
import { selectors } from '../../redux'
import { CourseType, UserType } from '../../generated/graphql'
import { SearchTemplate, SearchTemplateProps } from '../../templates/Search'
import { ImageTextItemProps } from '../../components/ImageTextItem'
import { Icons } from '../../components/Icon/types'
import { light } from '../../theme/palette'
import { router, routesPath } from '../../router'
import { courseTypeSlug, courseTypeTranslation } from '../../graphql/enums/CourseType'
import useBackButton from '../../hooks/useBackButton'

const SearchPage: React.FC<RouteComponentProps<{ child?: string }>> = (props) => {
  const headerProps: HeaderProps = useHeader()
  const menuProps: MenuProps = useMenu(props)
  const user = useSelector(selectors.auth.user)
  const { t } = useTranslation()
  const history = useHistory()
  const backButton = useBackButton()
  const selectedChild = props.match?.params?.child ?? user?.guardian?.children?.[0]?.id

  const handleSubmit = useCallback(
    (values) => {
      history.push(
        router(routesPath.searchDiscipline, {
          type: courseTypeSlug(t, values?.type ?? values?.request),
          ...(values.child && {
            child: values.child,
          }),
        })
      )
    },
    [history, t]
  )

  const sections: {
    label: string
    image: string
    type: CourseType
    disabled?: boolean
  }[] = useMemo(
    () => [
      {
        label: courseTypeTranslation(t, CourseType.OneOff),
        image: '/static/assets/images/group.png',
        type: CourseType.OneOff,
      },
      /*{
        label: 'Un cours récurrent',
        image: '/static/assets/images/chrono.png',
        type: CourseType.Recurrent,
      },
      {
      label: 'Une aide d\'urgence',
      image: '/static/assets/images/idea.png',
      type: CourseType.Emergency,
      disabled: true
    },
    {
      label: 'Un stage',
      image: '/static/assets/images/course.png',
      type: CourseType.Recurrent,
      disabled: true
    },*/
    ],
    [t]
  )

  const templateProps: SearchTemplateProps = useMemo(
    () => ({
      headerProps,
      menuProps,
      backButton,
      ...(user?.user_type === UserType.Teacher
        ? {
            title: t('search_teacher_title'),
            imageTextListProps: {
              items: [
                {
                  image: {
                    alt: '',
                    src: '/static/assets/images/icons/course.png',
                  },
                  text1: t('search_teacher_requests_title'),
                  link: {
                    href: router(routesPath.searchClassRequests),
                  },
                },
                {
                  image: {
                    alt: '',
                    src: '/static/assets/images/icons/books.png',
                  },
                  text1: t('search_teacher_market_title'),
                  link: {
                    href: router(routesPath.searchMarket),
                  },
                },
                /*{
                image: {
                  alt: '',
                  src: '/static/assets/images/course.png',
                },
                text1: 'Un stage',
                isDisable: true,
                comingNextText: 'Prochainement !',
              },*/
              ],
            },
          }
        : user?.user_type === UserType.Guardian
        ? {
            title: t('search_title'),
            text: t('search_guardian_text'),
            searchRequestFormProps: {
              whichChildTitle: t('search_whichChild_label'),
              childList: {
                items: user?.guardian?.children?.map(
                  (child): ImageTextItemProps => ({
                    image: {
                      alt: child.first_name ?? '',
                      src: child.avatar ?? '',
                    },
                    isAvatar: true,
                    text1: child?.first_name ?? '',
                    text2: child?.grade?.name ?? '',
                    value: child?.id,
                    potIcon: {
                      icon: Icons.pot,
                      color: light.colors.kournikova,
                    },
                    potValue: t('wallet_amount_value', {
                      amount: (user?.wallet?.amount ?? 0) / 100,
                    }),
                  })
                ),
              },
              whichTypeOfRequest: t('search_type_label'),
              typeOfRequestList: {
                items: sections?.map((item) => ({
                  image: {
                    alt: '',
                    src: item?.image,
                  },
                  text1: item?.label,
                  value: item?.type,
                  ...(item?.disabled && {
                    isDisable: true,
                    comingNextText: t('coming_soon'),
                  }),
                })),
              },
              submitButton: {
                text: t('search_submit'),
              },
              onSubmit: handleSubmit,
              initialValues: {
                child: selectedChild ?? '',
                request: CourseType.OneOff,
              },
            },
          }
        : {
            title: t('search_title'),
            text: t('search_student_text'),
            imageTextListProps: {
              items: sections?.map((item) => ({
                image: {
                  alt: '',
                  src: item?.image,
                },
                text1: item?.label,
                link: {
                  onClick: () => {
                    handleSubmit({ type: item.type })
                  },
                },
                ...(item?.disabled && {
                  isDisable: true,
                  comingNextText: t('coming_soon'),
                }),
              })),
            },
          }),
    }),
    [
      backButton,
      handleSubmit,
      headerProps,
      menuProps,
      sections,
      selectedChild,
      t,
      user?.guardian?.children,
      user?.user_type,
      user?.wallet?.amount,
    ]
  )

  return <SearchTemplate {...templateProps} />
}

export default SearchPage
