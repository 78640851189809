import { Container } from '@material-ui/core'
import styled from 'styled-components'

import ActionButton from '../ActionButton'

export const BasicTextAndImage = styled.div`
  padding: 0 1.6rem;
`

export const Wrapper = styled(Container)`
  text-align: center;
  padding: 0;
`

export const Title = styled.h1`
  ${(props) => ({ ...props.theme.typography.h2 })}
  color: ${(props) => props.theme.palette.colors.neonCarrot};
  margin: 1rem 0 2rem 0;
  text-transform: capitalize;
`

export const Subtitle = styled.h2`
  ${(props) => ({ ...props.theme.typography.h4 })}
  color: ${(props) => props.theme.palette.colors.freeSpeechBlue};
`

export const Text = styled.p`
  ${(props) => ({ ...props.theme.typography.smallRegular })}
  color: ${(props) => props.theme.palette.colors.midnightBlue};
  margin: 3rem 0 1rem 0;
  text-align: center;
`

export const BoxImage = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`

export const Image = styled.img`
  width: 30rem;
  height: auto;
  ${(props) => props.theme.breakpoints.up('md')} {
    width: 51rem;
  }
`

export const Text2 = styled.p`
  ${(props) => ({ ...props.theme.typography.h4 })}
  color: ${(props) => props.theme.palette.colors.neonCarrot};
  margin: 3rem 0 1rem 0;
  text-align: center;
`

export const CTA = styled(ActionButton)`
  margin-top: 1.6rem;
`
