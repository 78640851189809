import styled from 'styled-components'
import DayPickerC from 'react-day-picker'
import chroma from 'chroma-js'

export const Wrapper = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.palette.colors.pureWhite};
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.12);
  border-radius: 1.6rem;
`

export const DayPicker = styled(DayPickerC)<{ chevron: any }>`
  width: 100%;
  .DayPicker-Caption {
    color: ${(props) => props.theme.palette.colors.freeSpeechBlue};
    ${(props) => ({ ...props.theme.typography.h4 })};
    text-align: center;
    margin-bottom: 2.5rem;
    margin-top: 1.2rem;
  }

  .DayPicker-Month {
    width: 100%;
    margin: 0 1rem;
  }

  .DayPicker-Weekday {
    color: ${(props) => props.theme.palette.colors.freeSpeechBlue};
    ${(props) => ({ ...props.theme.typography.h7 })};
  }

  .DayPicker-Day {
    color: ${(props) => props.theme.palette.colors.chambray};
    ${(props) => ({ ...props.theme.typography.h6 })};
    background-color: transparent !important;
    padding: 0.8em 0.5em;
    position: relative;
    &:before {
      content: '';
      position: absolute;
      width: 100%;
      max-width: 5rem;
      height: 3rem;
      border-radius: 999px;
      z-index: 0;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
    }
    & .value {
      position: relative;
    }
  }

  .DayPicker-Day--today {
    color: ${(props) => props.theme.palette.colors.freeSpeechBlue};
  }

  .DayPicker-Day--alreadyDays {
    &:before {
      background-color: ${(props) =>
        chroma(props.theme.palette.colors.cornflowerBlue).alpha(0.3).hex()};
    }
    color: ${(props) => props.theme.palette.colors.freeSpeechBlue};
  }
  .DayPicker-Day--alreadyDays:hover {
    &:before {
      background-color: ${(props) => props.theme.palette.colors.transparentBlue} !important;
    }
    color: ${(props) => props.theme.palette.colors.chambray};
  }

  .DayPicker-Day--recurringDays {
    &:before {
      background-color: ${(props) => props.theme.palette.colors.kournikovaLight};
    }
    color: ${(props) => props.theme.palette.colors.tomato};
  }
  .DayPicker-Day--recurringDays:hover {
    &:before {
      background-color: ${(props) => props.theme.palette.colors.transparentBlue} !important;
    }
    color: ${(props) => props.theme.palette.colors.chambray};
  }

  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    &:before {
      background-color: ${(props) => props.theme.palette.colors.cornflowerBlue};
    }
  }

  .DayPicker-Day--outside {
    &:before {
      background-color: transparent;
    }
  }

  .DayPicker-Day--disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  .DayPicker-NavButton--prev {
    background-image: ${(props) => `url(${props.chevron})`};
    left: 1.6rem;
    width: 3rem;
    height: 3rem;
  }

  .DayPicker-NavButton--next {
    background-image: ${(props) => `url(${props.chevron})`};
    transform: rotate(180deg);
    right: 1.6rem;
    width: 3rem;
    height: 3rem;
  }
`
