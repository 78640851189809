import React, { FC, ReactNode } from 'react'

import { ImgProps } from '../../types/image'
import { ActionButtonProps } from '../../components/ActionButton'
import Layout from '../../layouts/Layout'
import Header, { HeaderProps } from '../../components/Header'

import * as SC from './styled'

export type ReasonProps = {
  text: string
}

export type NotFoundTemplateProps = {
  headerProps: HeaderProps
  title?: string
  body?: ReactNode
  cta?: ActionButtonProps
  image?: ImgProps
  backgroundImage?: ImgProps
  backgroundColor: string
}

const NotFoundTemplate: FC<NotFoundTemplateProps> = (props) => {
  const { headerProps, ...otherProps } = props

  return (
    <Layout header={<Header {...headerProps} backgroundColor={otherProps.backgroundColor} />}>
      <SC.NotFound {...otherProps} />
    </Layout>
  )
}

export default NotFoundTemplate
