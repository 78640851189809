import { Container, FormHelperText } from '@material-ui/core'
import styled from 'styled-components'
import { CircularProgressbar } from 'react-circular-progressbar'

import Icon from '../../Icon'
import 'react-circular-progressbar/dist/styles.css'

export const UploadImage = styled.div``

export const Wrapper = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const UploadBox = styled.div`
  cursor: pointer;
  position: relative;
  width: 16rem;
  height: 16rem;
`

export const AvatarImage = styled.img`
  width: 16rem;
  height: 16rem;
  border-radius: 50%;
`

export const AvatarIcon = styled(Icon)`
  width: 4.8rem;
  height: 4.8rem;
  position: absolute;
  right: 0;
  pointer-events: none;
`

export const CircularProgress = styled(CircularProgressbar)<{ $visible: boolean }>`
  position: absolute;
  width: 16rem;
  height: 16rem;
  top: 0;
  left: 0;
  transition: opacity 0.3s;
  opacity: ${({ $visible }) => ($visible ? 1 : 0)};
  & .CircularProgressbar-path {
    stroke: ${({ theme }) => theme.palette.colors.cornflowerBlue};
  }
`

export const HelperText = styled(FormHelperText)`
  text-align: left;
  ${(props) => ({ ...props.theme.typography.smallRegular })}
  color: ${(props) => props.theme.palette.colors.grey};
  background-color: ${(props) => props.theme.palette.colors.whiteSmoke};
  margin: 3px 0 0 0;
  &.Mui-error {
    color: ${(props) => props.theme.palette.error.main};
  }
`
