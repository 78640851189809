import gql from 'graphql-tag'

import { PublicTeacher } from '../../../../generated/graphql'
import PublicTeacherFragment from '../../../fragments/PublicTeacherFragment'
import PublicTeacherDisciplineFragment from '../../../fragments/PublicTeacherDisciplineFragment'

const teacherById = {
  query: gql`
    query teacherById($id: ID!) {
      teacherById(id: $id) {
        ...PublicTeacherFragment
        disciplines {
          ...PublicTeacherDisciplineFragment
        }
        introduction
      }
    }
    ${PublicTeacherFragment}
    ${PublicTeacherDisciplineFragment}
  `,
  transformer: (response: any): PublicTeacher => {
    return response.teacherById
  },
}

export default teacherById
