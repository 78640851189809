import styled from 'styled-components'

import IconB from '../Icon'
import LinkC from '../Link'

export const ImpersonateBanner = styled.div``
export const Wrapper = styled.div`
  background-color: #ffd56d;
  position: relative;
  display: flex;
  align-items: center;
  padding: 0.5rem;
  justify-content: flex-end;
`

export const Label = styled.div`
  display: none;
  ${(props) => ({ ...props.theme.typography.tinyStrong })}
  color: ${(props) => props.theme.palette.colors.chambray};
  padding-right: 1rem;
  ${(props) => props.theme.breakpoints.up('md')} {
    display: flex;
  }
`
export const UserInfos = styled.div`
  ${(props) => ({ ...props.theme.typography.smallStrong })}
  font-size: 1.2rem;
  color: ${(props) => props.theme.palette.colors.chambray};
  display: flex;
  align-items: center;
`
export const UserId = styled.div`
  padding-right: 0.5rem;
`
export const UserName = styled.div`
  padding-right: 0.5rem;
`

export const UserType = styled.div`
  padding-right: 0.5rem;
`

export const Icon = styled(IconB)`
  width: 3rem;
  height: 3rem;
  padding: 0;
  color: ${(props) => props.theme.palette.colors.chambray};
  & svg {
    width: 3rem;
    height: 3rem;
  }
`

export const Link = styled(LinkC)`
  padding: 0;
  color: ${(props) => props.theme.palette.colors.chambray};
  display: flex;
`
