import gql from 'graphql-tag'

import { Course, PaginatorInfo } from '../../../../generated/graphql'
import CourseFragment from '../../../fragments/CourseFragment'
import PaginatorInfoFragment from '../../../fragments/PaginatorInfoFragment'

export type TeacherPastCoursesResult = {
  data: Course[]
  paginatorInfo: PaginatorInfo
}

const teacherPastCourses = {
  query: gql`
    query teacherPastCourses(
      $first: Int!
      $page: Int
      $discipline: ID
      $orderBy: [QueryPastCoursesOrderByOrderByClause!]
    ) {
      pastCourses(first: $first, page: $page, discipline: $discipline, orderBy: $orderBy) {
        data {
          ...CourseFragment
        }
        paginatorInfo {
          ...PaginatorInfoFragment
        }
      }
    }
    ${CourseFragment}
    ${PaginatorInfoFragment}
  `,
  transformer: (response: any): TeacherPastCoursesResult => {
    return {
      data: response?.pastCourses?.data,
      paginatorInfo: response?.pastCourses?.paginatorInfo,
    }
  },
}

export default teacherPastCourses
