import { Container, FormHelperText } from '@material-ui/core'
import styled from 'styled-components'
import { CircularProgressbar } from 'react-circular-progressbar'

import Icon from '../../Icon'
import InputLabel from '../InputLabel'
import BasicLink from '../../BasicLink'

export const DocumentUpload = styled.div`
  width: 100%;
`

export const Wrapper = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

export const Label = styled(InputLabel)`
  margin-bottom: 0.8rem;
`

export const Text = styled.p`
  ${(props) => ({ ...props.theme.typography.smallRegular })};
  color: ${(props) => props.theme.palette.colors.chambray};
  width: 100%;
  margin: 0 0 1.6rem;
`

export const UploadBox = styled.div`
  padding: 0;
  border-radius: 0.8rem;
  cursor: pointer;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  ${(props) => props.theme.breakpoints.up('md')} {
    width: 100%;
    align-items: flex-start;
  }
`

export const Texts = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
`

export const DocumentUploadText = styled(BasicLink)`
  color: ${(props) => props.theme.palette.colors.freeSpeechBlue};
  ${(props) => ({ ...props.theme.typography.smallBold })}
  margin: 1.3rem 0 0 0;
  & hover {
    text-decoration: none;
  }
  width: 100%;
  margin: 0;
`

export const DocumentUploadFile = styled.p`
  ${(props) => ({ ...props.theme.typography.h6 })};
  color: ${(props) => props.theme.palette.colors.freeSpeechBlue};
  font-size: 1rem;
  width: 100%;
  margin: 0;
  &:only-child {
    font-size: 1.4rem;
    padding-top: 0.5rem;
  }
`

export const Browse = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

export const Actions = styled.div`
  width: 4rem;
  position: relative;
  display: flex;
  align-items: center;
`

export const RemoveIcon = styled(Icon)`
  width: 3rem;
  height: 3rem;
  margin: auto;
  flex-shrink: 0;
  position: relative;
`

export const CircularProgress = styled(CircularProgressbar)<{ $visible: boolean }>`
  position: absolute;
  width: 3rem;
  height: 3rem;
  top: 0rem;
  right: 0rem;
  bottom: 0rem;
  margin: auto;
  transition: opacity 0.3s;
  opacity: ${({ $visible }) => ($visible ? 1 : 0)};
  & .CircularProgressbar-path {
    stroke: ${({ theme }) => theme.palette.colors.cornflowerBlue};
  }
`

export const HelperText = styled(FormHelperText)`
  text-align: left;
  ${(props) => ({ ...props.theme.typography.smallRegular })}
  color: ${(props) => props.theme.palette.colors.grey};
  background-color: ${(props) => props.theme.palette.colors.whiteSmoke};
  margin: 3px 0 0 0;
  &.Mui-error {
    color: ${(props) => props.theme.palette.error.main};
  }
`
