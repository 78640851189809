import styled from 'styled-components'

import TextFieldC from '../form/TextField'
import BasicLink from '../BasicLink'
import FormSubmit from '../FormSubmit'

export const Container = styled.div`
  background-color: ${(props) => props.theme.palette.colors.ghostWhite};
  padding: 1rem 1.6rem;
  & .MuiFormHelperText-contained {
    background-color: transparent;
  }
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const Title = styled.h3`
  ${(props) => ({ ...props.theme.typography.h3 })}
  color: ${(props) => props.theme.palette.colors.midnightBlue};
  margin: 0 0 1rem 0;
`

export const Text = styled.p`
  ${(props) => ({ ...props.theme.typography.smallRegular })}
  color: ${(props) => props.theme.palette.colors.chambray};
  margin: 0 0 1rem 0;
`

export const Fields = styled.div``

export const TextField = styled(TextFieldC)`
  margin-bottom: 1rem;
`

export const Error = styled.p`
  color: ${(props) => props.theme.palette.colors.tomato};
  ${(props) => ({ ...props.theme.typography.smallRegular })}
  margin: -0.2rem 0 0.5rem 0;
`

export const SubmitButton = styled(FormSubmit)`
  margin: 1rem 0 0;
  display: flex;
  ${(props) => ({ ...props.theme.typography.callToAction })}
  color: ${(props) => props.theme.palette.colors.freeSpeechBlue};
  background-color: ${(props) => props.theme.palette.colors.pureWhite};
  text-decoration: none;
  cursor: pointer;
  width: auto;
  align-self: center;
  padding: 0.5rem 1rem;
  border: none;
  &:hover {
    background-color: ${(props) => props.theme.palette.colors.cornflowerBlue};
    color: ${(props) => props.theme.palette.colors.pureWhite};
  }
`

export const Cta = styled(BasicLink)`
  margin-top: 2rem;
  display: flex;
  ${(props) => ({ ...props.theme.typography.smallStrong })}
  text-decoration: none;
  color: ${(props) => props.theme.palette.colors.freeSpeechBlue};
  cursor: pointer;
`
