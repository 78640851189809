import React, { FC } from 'react'
import { Form, Formik, FormikHelpers } from 'formik'
import * as Yup from 'yup'

import { Icons } from '../Icon/types'
import { BasicTextAndImageProps } from '../BasicTextAndImage'
import { getValidationSchema, renderField } from '../../helpers/FormHelpers'
import { FormFieldConfig, FormFieldProps } from '../../types/form'
import { FormSubmitProps } from '../FormSubmit'
import FormSubmitErrors, { FormSubmitErrorsProps } from '../FormSubmitErrors'
import { AddressValue } from '../form/Address'
import FormScrollToError from '../FormScrollToError'
import { light } from '../../theme/palette'
import LegalLegends, { LegalLegendsProps } from '../LegalLegends'

import * as SC from './styled'

export type SignUpPersonalInfosFormValues = {
  phone?: string
  level?: string
  address?: AddressValue
  avatar: string
  firstName?: string
  lastName?: string
  mail?: string
  school?: string
}

export type SignUpPersonalInfosProps = {
  className?: string
  userType?: 'teacher' | 'student' | 'parent'
  parentIntro?: BasicTextAndImageProps
  legends?: LegalLegendsProps['texts']
  sponsorText?: string
  sponsorName?: string
  step: string
  title: string
  avatarTitle: string
  avatarText: string
  mandatoryText: string
  fieldsProps?: {
    phone?: FormFieldProps
    level?: FormFieldProps
    address?: FormFieldProps
    avatar?: FormFieldProps
    firstName?: FormFieldProps
    lastName?: FormFieldProps
    mail?: FormFieldProps
    school?: FormFieldProps
  }
  errorTexts?: {
    required: string
    phone: string
    address: string
  }
  initialValues: SignUpPersonalInfosFormValues
  submitErrors?: FormSubmitErrorsProps['errors']
  submitButton: FormSubmitProps
  onSubmit?: (
    values: SignUpPersonalInfosFormValues,
    formikHelpers: FormikHelpers<SignUpPersonalInfosFormValues>
  ) => void
}

const SignUpPersonalInfos: FC<SignUpPersonalInfosProps> = (props) => {
  const {
    className,
    userType,
    parentIntro,
    title,
    sponsorText,
    sponsorName,
    step,
    avatarTitle,
    avatarText,
    mandatoryText,
    fieldsProps,
    errorTexts,
    initialValues,
    submitErrors,
    submitButton,
    legends,
    onSubmit = (_values, _formikHelpers) => null,
  } = props

  const firstField =
    userType === 'parent' || userType === 'teacher'
      ? {
          name: 'phone',
          Component: SC.TextField,
          validation: Yup.string()
            .required(errorTexts?.required)
            .matches(/^((\+)33|0|0033)[1-9](\d{2}){4}$/g, errorTexts?.phone),
          required: true,
        }
      : {
          name: 'school',
          Component: SC.TextField,
          validation: Yup.string().required(errorTexts?.required),
          required: true,
        }

  const secondField =
    userType === 'parent' || userType === 'teacher'
      ? {
          name: 'address',
          Component: SC.Address,
          validation: Yup.mixed().test('address', errorTexts?.address ?? '', function (value) {
            return !!value.address
          }),
          required: true,
        }
      : {
          name: 'level',
          Component: SC.TextField,
          validation: Yup.string().required(errorTexts?.required),
          required: true,
        }

  const fields: FormFieldConfig[] = [
    firstField,
    secondField,
    {
      name: 'avatar',
      Component: SC.UploadImage,
      validation: Yup.string(),
      required: true,
    },
  ]

  const avatarField = fields.find((f) => f.name === 'avatar')
  const otherFields = fields.filter((f) => f.name !== 'avatar')

  const validationSchema = getValidationSchema(fields)

  return (
    <SC.PersonalInfos className={className}>
      {(userType === 'parent' || 'student') && parentIntro && <SC.ParentIntro {...parentIntro} />}
      {sponsorName && sponsorText && (
        <SC.Sponsor>
          <SC.SponsorIcon icon={Icons.profileCheck} color={light.colors.cornflowerBlue} />
          <SC.SponsorText>{sponsorText}</SC.SponsorText>
          <SC.SponsorName>{sponsorName}</SC.SponsorName>
        </SC.Sponsor>
      )}
      <SC.Step>{step}</SC.Step>
      <SC.Title>{title}</SC.Title>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnChange={true}
        validateOnBlur={true}
        onSubmit={onSubmit}
      >
        {(formikProps) => (
          <Form noValidate>
            <FormScrollToError formikProps={formikProps} />
            <SC.Fields>
              {otherFields.map((f, index) => renderField(f, formikProps, fieldsProps, index))}
            </SC.Fields>
            <SC.Avatar>
              <SC.AvatarTitle>{avatarTitle}</SC.AvatarTitle>
              <SC.AvatarText>{avatarText}</SC.AvatarText>
              {avatarField && renderField(avatarField, formikProps, fieldsProps)}
            </SC.Avatar>
            <SC.MandatoryText>{mandatoryText}</SC.MandatoryText>
            <FormSubmitErrors errors={submitErrors} />
            <SC.SubmitButton {...submitButton} autoIcon />
            <LegalLegends texts={legends} />
          </Form>
        )}
      </Formik>
    </SC.PersonalInfos>
  )
}

export default SignUpPersonalInfos
