import styled from 'styled-components'
import { Grid as MuiGrid } from '@material-ui/core'

export const Container = styled(MuiGrid)``

export const Error = styled(MuiGrid)`
  color: ${(props) => props.theme.palette.error.main};
  ${(props) => ({ ...props.theme.typography.smallStrong })}
  text-align: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
`
