import gql from 'graphql-tag'

import UserFragment from '../../../fragments/UserFragment'
import type { User } from '../../../../generated/graphql'

const updateTeacherPrice = {
  mutation: gql`
    mutation updateTeacherPrice($price: Int!) {
      updateTeacherPrice(price: $price) {
        ...UserFragment
      }
    }
    ${UserFragment}
  `,
  transformer: (response: any): User => {
    return response.updateTeacherPrice
  },
}

export default updateTeacherPrice
