import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { HeaderProps } from '../../components/Header'
import useHeader from '../../hooks/useHeader'
import { LevelPickersFormProps } from '../../components/LevelPickersForm'
import SignUpTeacherDisciplinesTemplate from '../../templates/SignUpTeacherDisciplines'
import { actions, selectors } from '../../redux'
import { resolveRegisterStepURL } from '../../helpers/RegisterHelpers'
import { useFormSubmit } from '../../hooks/useFormSubmit'
import { ToggleButtonGroupOption } from '../../components/form/ToggleButtonGroup'

const SignUpTeacherDisciplinesPage: React.FC = (_props) => {
  const headerProps: HeaderProps = useHeader()

  const { t } = useTranslation()
  const user = useSelector(selectors.auth.user)
  const grades = useSelector(selectors.app.grades)
  const disciplines = useSelector(selectors.app.disciplines)
  const setting = useSelector(selectors.app.setting)
  const urlPrivacy = setting?.links?.privacy

  const userDisciplines = useMemo(
    () =>
      user?.teacher?.disciplines?.reduce((arr, couple) => {
        const discipline = couple.discipline.id
        if (!arr[discipline]) {
          arr[discipline] = []
        }
        arr[discipline].push(couple.grade.id)
        return arr
      }, {} as { [key: string]: string[] }),
    [user?.teacher?.disciplines]
  )

  const history = useHistory()

  const onComplete = useCallback(() => {
    history.push(resolveRegisterStepURL(user))
  }, [user, history])

  const [submit, handleSubmit] = useFormSubmit(
    selectors.auth.signUpTeacherDisciplines,
    actions.auth.signUpTeacherDisciplinesRequest,
    actions.auth.signUpTeacherDisciplinesReset,
    onComplete
  )

  const signupProps: LevelPickersFormProps = useMemo(() => {
    const gradesByTag = Object.values(
      grades?.reduce(
        (arr, grade) => {
          const tag = arr[grade?.tag || ''] || {
            label: grade?.tag,
            options: [],
          }
          tag.options.push({
            label: grade.name,
            value: grade.id,
          })
          arr[grade?.tag || ''] = tag
          return arr
        },
        {} as {
          [key: string]: {
            label: string
            options: ToggleButtonGroupOption[]
          }
        }
      )
    )

    const initialValues = disciplines.reduce(
      (arr, discipline) => ({ ...arr, [discipline.id]: userDisciplines?.[discipline.id] || [] }),
      {}
    )

    const levels = disciplines?.map((discipline) => ({
      onHeaderClick: () => null,
      name: discipline.id,
      title: discipline.name,
      groups: gradesByTag,
    }))

    return {
      title: t('signUpTeacherDisciplines_title'),
      text: t('signUpTeacherDisciplines_text'),
      legends: [
        t('signUpTeacherDisciplines_legend'),
        t('signUp_legends_1'),
        <span
          key={2}
          dangerouslySetInnerHTML={{ __html: t('signUp_legends_2', { url: urlPrivacy }) }}
        />,
      ],
      levelForSubject: levels,
      initialValues,
      submitButton: {
        text: submit.pending
          ? t('signUpTeacherDisciplines_submit_pending')
          : t('signUpTeacherDisciplines_submit'),
        isPending: submit.pending,
      },
      submitErrors: submit.errors,
      onSubmit: (values) => {
        console.log('submit', values)
        handleSubmit(values)
      },
    }
  }, [
    grades,
    disciplines,
    t,
    submit.pending,
    submit.errors,
    userDisciplines,
    handleSubmit,
    urlPrivacy,
  ])

  const imageProps = {
    alt: 'LiberteClass - Inscription',
    src: '/static/assets/images/signup-teacher.png',
  }

  return (
    <SignUpTeacherDisciplinesTemplate
      headerProps={headerProps}
      signupProps={signupProps}
      imageProps={imageProps}
    />
  )
}

export default SignUpTeacherDisciplinesPage
