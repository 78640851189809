import React, { FC } from 'react'

import { SimpleTextWithCtaProps } from '../../components/SimpleTextWithCta'
import Header, { HeaderProps } from '../../components/Header'
import { FormSubmitProps } from '../../components/FormSubmit'
import SignUpLayout from '../../layouts/SignupLayout'
import { BasicTextAndImageProps } from '../../components/BasicTextAndImage'
import { ActionButtonProps } from '../../components/ActionButton'

import * as SC from './styled'

export type SignUpTeacherPaymentsTemplateProps = {
  className?: string
  headerProps: HeaderProps
  title: string
  textWithCtaProps: SimpleTextWithCtaProps
  submitProps?: FormSubmitProps
  confirmProps?: BasicTextAndImageProps
  logoutButtonProps?: ActionButtonProps
}

const SignUpTeacherPaymentsTemplate: FC<SignUpTeacherPaymentsTemplateProps> = ({
  headerProps,
  title,
  textWithCtaProps,
  submitProps,
  confirmProps,
  logoutButtonProps,
}) => {
  return (
    <SignUpLayout header={<Header {...headerProps} />}>
      <SC.Content>
        {confirmProps ? (
          <>
            <SC.BasicTextAndImage {...confirmProps} />
          </>
        ) : (
          <>
            <SC.Title>{title}</SC.Title>
            <SC.SimpleTextWithCta {...textWithCtaProps} cta={undefined} />
            <SC.Actions>
              {textWithCtaProps?.cta && <SC.Action {...textWithCtaProps?.cta} />}
              {submitProps && <SC.Submit {...submitProps} />}
            </SC.Actions>
          </>
        )}
      </SC.Content>
      <SC.Actions>
        <SC.Action {...logoutButtonProps} outlined />
      </SC.Actions>
    </SignUpLayout>
  )
}

export default SignUpTeacherPaymentsTemplate
