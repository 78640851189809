import { all, call, put, takeLatest } from 'redux-saga/effects'

import { actions } from '..'
import ApiSagas from '../api/sagas'
import { ApiResponse } from '../api/types/state'
import { ServiceRequestAction } from '../../helpers/ReduxHelpers'
import joinRoom from '../../graphql/services/room/queries/joinRoom'
import { QueryJoinRoomArgs } from '../../generated/graphql'

export default class RoomSagas {
  static *onJoinRequest({ payload }: ServiceRequestAction<QueryJoinRoomArgs>) {
    const rs: ApiResponse<typeof joinRoom> = yield call(ApiSagas.query, joinRoom, payload)

    if (!rs?.errors) {
      yield put(actions.room.joinSuccess(rs?.data))
    } else {
      yield put(actions.room.joinError(rs.errors))
    }
  }

  static *loop() {
    yield all([takeLatest(actions.room.joinRequest, this.onJoinRequest)])
  }
}
