import { all, call, put, SagaReturnType, select, takeLatest } from 'redux-saga/effects'

import { ApiResponse } from '../api/types/state'
import ApiSagas from '../api/sagas'
import { actions, selectors } from '../index'
import todayCourse from '../../graphql/services/course/queries/todayCourse'
import courseById from '../../graphql/services/course/queries/courseById'
import { ServiceRequestAction } from '../../helpers/ReduxHelpers'
import dashboardCourses from '../../graphql/services/course/queries/dashboardCourses'
import planningCourses from '../../graphql/services/course/queries/planningCourses'
import teacherFutureCourses from '../../graphql/services/course/queries/teacherFutureCourses'
import {
  MutationCancelCourseArgs,
  MutationCancelCourseRequestArgs,
  MutationCancelPendingCourseArgs,
  MutationCreateDisputeArgs,
  MutationEvaluateStudentsArgs,
  QueryPastCoursesArgs,
  QueryPastCoursesOrderByColumn,
  UserType,
} from '../../generated/graphql'
import evaluateStudents from '../../graphql/services/course/mutations/evaluateStudents'
import teacherDashboardCourses from '../../graphql/services/course/queries/teacherDashboardCourses'
import pendingReviewCourses from '../../graphql/services/course/queries/pendingReviewCourses'
import teacherPastCourses from '../../graphql/services/course/queries/teacherPastCourses'
import courseRequestById from '../../graphql/services/course/queries/courseRequestById'
import cancelCourseRequest from '../../graphql/services/course/mutations/cancelCourseRequest'
import cancelPendingCourse from '../../graphql/services/course/mutations/cancelPendingCourse'
import createDispute from '../../graphql/services/dispute/mutations/createDispute'
import cancelCourse from '../../graphql/services/course/mutations/cancelCourse'
import teacherPlanningCourses from '../../graphql/services/course/queries/teacherPlanningCourses'

export default class PlanningSagas {
  static *onTodayCourseRequest() {
    const rs: ApiResponse<typeof todayCourse> = yield call(ApiSagas.query, todayCourse)

    if (!rs?.errors) {
      yield put(actions.planning.todayCourseSuccess(rs?.data))
    } else {
      yield put(actions.planning.todayCourseError(rs.errors))
    }
  }

  static *onDashboardCoursesRequest({ payload }: ServiceRequestAction<{ student?: string }>) {
    const rs: ApiResponse<typeof dashboardCourses> = yield call(
      ApiSagas.query,
      dashboardCourses,
      payload
    )

    if (!rs?.errors) {
      yield put(actions.planning.dashboardCoursesSuccess(rs?.data))
    } else {
      yield put(actions.planning.dashboardCoursesError(rs.errors))
    }
  }

  static *onTeacherDashboardCoursesRequest() {
    const rs: ApiResponse<typeof teacherDashboardCourses> = yield call(
      ApiSagas.query,
      teacherDashboardCourses
    )

    if (!rs?.errors) {
      yield put(actions.planning.teacherDashboardCoursesSuccess(rs?.data))
    } else {
      yield put(actions.planning.teacherDashboardCoursesError(rs.errors))
    }
  }

  static *onCourseRequest({ payload }: ServiceRequestAction<string>) {
    const rs: ApiResponse<typeof courseById> = yield call(ApiSagas.query, courseById, {
      id: payload,
    })

    if (!rs?.errors) {
      yield put(actions.planning.courseSuccess(rs?.data))
    } else {
      yield put(actions.planning.courseError(rs.errors))
    }
  }

  static *onCourseRequestRequest({ payload }: ServiceRequestAction<string>) {
    const rs: ApiResponse<typeof courseRequestById> = yield call(
      ApiSagas.query,
      courseRequestById,
      {
        id: payload,
      }
    )

    if (!rs?.errors) {
      yield put(actions.planning.courseRequestSuccess(rs?.data))
    } else {
      yield put(actions.planning.courseRequestError(rs.errors))
    }
  }

  static *onPlanningCoursesRequest({ payload }: ServiceRequestAction<{ student?: string }>) {
    const user: SagaReturnType<typeof selectors.auth.user> = yield select(selectors.auth.user)

    const rs: ApiResponse<typeof planningCourses | typeof teacherPlanningCourses> = yield call(
      ApiSagas.query,
      user?.user_type === UserType.Teacher ? teacherPlanningCourses : planningCourses,
      payload
    )

    if (!rs?.errors) {
      yield put(actions.planning.planningCoursesSuccess(rs?.data))
    } else {
      yield put(actions.planning.planningCoursesError(rs.errors))
    }
  }

  static *onTeacherFutureCoursesRequest() {
    const rs: ApiResponse<typeof teacherFutureCourses> = yield call(
      ApiSagas.query,
      teacherFutureCourses
    )

    if (!rs?.errors) {
      yield put(actions.planning.teacherFutureCoursesSuccess(rs?.data))
    } else {
      yield put(actions.planning.teacherFutureCoursesError(rs.errors))
    }
  }

  static *onPendingReviewCoursesRequest() {
    const rs: ApiResponse<typeof pendingReviewCourses> = yield call(
      ApiSagas.query,
      pendingReviewCourses
    )

    if (!rs?.errors) {
      yield put(actions.planning.pendingReviewCoursesSuccess(rs?.data))
    } else {
      yield put(actions.planning.pendingReviewCoursesError(rs.errors))
    }
  }

  static *onTeacherPastCoursesRequest({
    payload,
  }: ServiceRequestAction<{ first: number; page: number; filters: any }>) {
    const input: QueryPastCoursesArgs = {
      first: payload?.first ?? 10,
      page: payload?.page ?? 1,
      ...(payload?.filters?.discipline && { discipline: payload?.filters?.discipline }),
      orderBy: [
        {
          column: QueryPastCoursesOrderByColumn.StartAt,
          order: payload?.filters?.sort,
        },
      ],
    }
    const rs: ApiResponse<typeof teacherPastCourses> = yield call(
      ApiSagas.query,
      teacherPastCourses,
      input
    )

    if (!rs?.errors) {
      yield put(actions.planning.teacherPastCoursesSuccess(rs?.data))
    } else {
      yield put(actions.planning.teacherPastCoursesError(rs.errors))
    }
  }

  static *onEvaluateStudentsRequest({
    payload,
  }: ServiceRequestAction<MutationEvaluateStudentsArgs>) {
    const rs: ApiResponse<typeof evaluateStudents> = yield call(
      ApiSagas.mutate,
      evaluateStudents,
      payload
    )

    if (!rs?.errors) {
      yield put(actions.planning.courseSuccess(rs?.data))
      yield put(actions.planning.evaluateStudentsSuccess(rs?.data))
    } else {
      yield put(actions.planning.evaluateStudentsError(rs.errors))
    }
  }

  static *onCancelCourseRequest({
    payload,
  }: ServiceRequestAction<MutationCancelCourseRequestArgs>) {
    const rs: ApiResponse<typeof cancelCourseRequest> = yield call(
      ApiSagas.mutate,
      cancelCourseRequest,
      payload
    )

    if (!rs?.errors) {
      yield put(actions.planning.courseSuccess(rs?.data))
      yield put(actions.planning.cancelCourseRequestSuccess(rs?.data))
    } else {
      yield put(actions.planning.cancelCourseRequestError(rs.errors))
    }
  }

  static *onCancelPendingCourse({
    payload,
  }: ServiceRequestAction<MutationCancelPendingCourseArgs>) {
    const rs: ApiResponse<typeof cancelPendingCourse> = yield call(
      ApiSagas.mutate,
      cancelPendingCourse,
      payload
    )

    if (!rs?.errors) {
      yield put(actions.planning.cancelPendingCourseSuccess(rs?.data))
    } else {
      yield put(actions.planning.cancelPendingCourseError(rs.errors))
    }
  }

  static *onCancelConfirmedCourse({ payload }: ServiceRequestAction<MutationCancelCourseArgs>) {
    const rs: ApiResponse<typeof cancelCourse> = yield call(ApiSagas.mutate, cancelCourse, payload)

    if (!rs?.errors) {
      yield put(actions.auth.reloadWalletRequest(null))
      yield put(actions.planning.courseSuccess(rs?.data))
      yield put(actions.planning.cancelConfirmedCourseSuccess(rs?.data))
    } else {
      yield put(actions.planning.cancelConfirmedCourseError(rs.errors))
    }
  }

  static *onReportRequest({ payload }: ServiceRequestAction<MutationCreateDisputeArgs>) {
    const rs: ApiResponse<typeof createDispute> = yield call(
      ApiSagas.mutate,
      createDispute,
      payload
    )

    if (!rs?.errors) {
      yield put(actions.planning.reportSuccess(rs?.data))
    } else {
      yield put(actions.planning.reportError(rs.errors))
    }
  }

  static *loop() {
    yield all([
      takeLatest(actions.planning.todayCourseRequest, this.onTodayCourseRequest),
      takeLatest(actions.planning.dashboardCoursesRequest, this.onDashboardCoursesRequest),
      takeLatest(
        actions.planning.teacherDashboardCoursesRequest,
        this.onTeacherDashboardCoursesRequest
      ),
      takeLatest(actions.planning.courseRequest, this.onCourseRequest),
      takeLatest(actions.planning.courseRequestRequest, this.onCourseRequestRequest),
      takeLatest(actions.planning.planningCoursesRequest, this.onPlanningCoursesRequest),
      takeLatest(actions.planning.teacherFutureCoursesRequest, this.onTeacherFutureCoursesRequest),
      takeLatest(actions.planning.teacherPastCoursesRequest, this.onTeacherPastCoursesRequest),
      takeLatest(actions.planning.pendingReviewCoursesRequest, this.onPendingReviewCoursesRequest),
      takeLatest(actions.planning.evaluateStudentsRequest, this.onEvaluateStudentsRequest),
      takeLatest(actions.planning.cancelCourseRequestRequest, this.onCancelCourseRequest),
      takeLatest(actions.planning.cancelPendingCourseRequest, this.onCancelPendingCourse),
      takeLatest(actions.planning.cancelConfirmedCourseRequest, this.onCancelConfirmedCourse),
      takeLatest(actions.planning.reportRequest, this.onReportRequest),
    ])
  }
}
