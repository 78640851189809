import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import copy from 'copy-to-clipboard'

import { app } from '../configuration'
import { selectors } from '../redux'
import { UserType } from '../generated/graphql'
import { ImpersonateBannerProps } from '../components/ImpersonateBanner'

const backendPaths = {
  [UserType.Guardian]: 'guardians',
  [UserType.Teacher]: 'teachers',
  [UserType.Student]: 'students',
  '': '',
}

const useImpersonate = (_pageProps?: any): ImpersonateBannerProps | null => {
  const user = useSelector(selectors.auth.user)
  const impersonate = useSelector(selectors.auth.impersonate)
  const token = useSelector(selectors.auth.token)

  return useMemo(() => {
    const impersonateProps =
      impersonate &&
      token &&
      user &&
      ({
        label: 'CONNECTÉ EN TANT QUE : ',
        user: {
          id: '#' + user?.id,
          first_name: user?.first_name,
          last_name: user?.last_name,
          type:
            user?.user_type + '#' + (user?.teacher?.id || user?.guardian?.id || user?.student?.id),
        },
        link: {
          href:
            app.BACKEND_URL +
            '/resources/' +
            backendPaths?.[user?.user_type ?? ''] +
            '/' +
            (user?.teacher?.id || user?.guardian?.id || user?.student?.id),
          target: '_blank',
        },
        onCopy: () => {
          copy(token)
        },
      } as ImpersonateBannerProps)

    return impersonateProps || null
  }, [token, impersonate, user])
}

export default useImpersonate
