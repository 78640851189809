import React, { FC } from 'react'

import { ActionButtonProps } from '../../components/ActionButton'
import BasicTextAndImage, { BasicTextAndImageProps } from '../../components/BasicTextAndImage'
import Header, { HeaderProps } from '../../components/Header'
import SignUpLayout from '../../layouts/SignupLayout'

import * as SC from './styled'

export type SignUpStandByTemplateProps = {
  className?: string
  headerProps: HeaderProps
  textImageProps: BasicTextAndImageProps
  cta: ActionButtonProps
  logoutButtonProps: ActionButtonProps
}

const SignUpStandByTemplate: FC<SignUpStandByTemplateProps> = (props) => {
  const { headerProps, textImageProps, cta, logoutButtonProps } = props

  return (
    <SignUpLayout header={<Header {...headerProps} />}>
      <SC.Content>
        <BasicTextAndImage {...textImageProps} />
      </SC.Content>
      <SC.Actions>
        <SC.ReturnButton {...cta} outlined />
        <SC.Action {...logoutButtonProps} outlined />
      </SC.Actions>
    </SignUpLayout>
  )
}

export default SignUpStandByTemplate
