import React, { FC } from 'react'

import { ActionButtonProps } from '../ActionButton'
import WalletPeriodicityModal, {
  WalletPeriodicityModalProps,
} from '../modals/WalletPeriodicityModal'
import { FormSubmitProps } from '../FormSubmit'

import * as SC from './styled'

export type WalletConfigurationProps = {
  className?: string
  title: string
  periodicityLabel: string
  periodicityValue: string
  periodicityButton: ActionButtonProps
  dashboardButton?: FormSubmitProps
  modalProps?: WalletPeriodicityModalProps
}

const WalletConfiguration: FC<WalletConfigurationProps> = (props) => {
  const {
    className,
    title,
    dashboardButton,
    periodicityLabel,
    periodicityValue,
    periodicityButton,
    modalProps,
  } = props

  return (
    <SC.Pot className={className}>
      <SC.Content>
        <SC.Title>{title}</SC.Title>
        <SC.Periodicity>
          <p>
            <SC.PeriodicityLabel>{periodicityLabel}</SC.PeriodicityLabel>
            <SC.PeriodicityValue>{periodicityValue}</SC.PeriodicityValue>
          </p>
          <SC.PeriodicityButton {...periodicityButton} outlined />
        </SC.Periodicity>
        {dashboardButton && <SC.Button {...dashboardButton} autoIcon />}
        {modalProps && <WalletPeriodicityModal {...modalProps} />}
      </SC.Content>
    </SC.Pot>
  )
}

export default WalletConfiguration
