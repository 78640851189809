import React, { FC, ReactNode } from 'react'

import * as SC from './styled'

export type LegalLegendsProps = {
  className?: string
  texts?: (string | ReactNode)[]
}

const LegalLegends: FC<LegalLegendsProps> = (props) => {
  const { className, texts } = props

  return (
    <SC.Legends className={className}>
      {texts?.map((legend, key) => (
        <p key={key}>{legend}</p>
      ))}
    </SC.Legends>
  )
}

export default LegalLegends
