import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { SignUpFormProps } from '../../components/SignUpForm'
import { HeaderProps } from '../../components/Header'
import useHeader from '../../hooks/useHeader'
import SignUpTemplate from '../../templates/SignUp'
import { actions, selectors } from '../../redux'
import { UserType } from '../../generated/graphql'
import { resolveRegisterStepURL } from '../../helpers/RegisterHelpers'
import { useFormSubmit } from '../../hooks/useFormSubmit'
import { enumOption } from '../../helpers/GraphqlHelpers'
import { router, routesPath } from '../../router'
import useSocialConnect from '../../hooks/useSocialConnect'

const SignUpPage: React.FC = (props) => {
  const headerProps: HeaderProps = useHeader()
  const socialConnectProps = useSocialConnect()

  const { t } = useTranslation()
  const user = useSelector(selectors.auth.user)
  const history = useHistory()
  const setting = useSelector(selectors.app.setting)

  const matchType = (props as any)?.match?.params?.type

  const [type, setType] = useState(
    matchType === 'enseignant'
      ? UserType.Teacher
      : matchType === 'eleve'
      ? UserType.Student
      : UserType.Guardian
  )

  const onComplete = useCallback(() => {
    history.push(resolveRegisterStepURL(user))
  }, [user, history])

  const [submit, handleSubmit] = useFormSubmit(
    selectors.auth.register,
    actions.auth.registerRequest,
    actions.auth.registerReset,
    onComplete
  )

  const urlCGU = setting?.links?.cgu
  const urlPrivacy = setting?.links?.privacy

  const signupProps: SignUpFormProps = useMemo(() => {
    return {
      title: t('signUp_title'),
      legends: [
        t('signUp_legends_0'),
        t('signUp_legends_1'),
        <span
          key={2}
          dangerouslySetInnerHTML={{ __html: t('signUp_legends_2', { url: urlPrivacy }) }}
        />,
      ],
      fieldsProps: {
        type: {
          label: t('signUp_form_type_label'),
          options: [
            enumOption(t, 'UserType', UserType.Guardian),
            enumOption(t, 'UserType', UserType.Student),
            enumOption(t, 'UserType', UserType.Teacher),
          ],
        },
        firstName: {
          label: t('signUp_form_firstName_label'),
        },
        lastName: {
          label: t('signUp_form_lastName_label'),
        },
        email: {
          label: t('signUp_form_email_label'),
        },
        password: {
          label: t('signUp_form_password_label'),
        },
        passwordConfirmation: {
          label: t('signUp_form_passwordConfirmation_label'),
        },
        sponsor: {
          label: t('signUp_form_sponsor_label'),
        },
        cgu: {
          label: (
            <span
              dangerouslySetInnerHTML={{ __html: t('signUp_form_cgu_label', { url: urlCGU }) }}
            />
          ),
        },
        age: {
          label: t('signUp_form_age_label'),
        },
        promo: {
          label: t('signUp_form_promo_label'),
        },
      },
      initialValues: {
        type,
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        passwordConfirmation: '',
        cgu: false,
        sponsor: '',
        age: false,
        promo: '',
      },
      errorTexts: {
        required: t('error_required'),
        email: t('error_email'),
        password: t('error_password'),
        passwordConfirmation: t('error_password_confirm'),
        profile: t('error_user_type'),
        cgu: t('error_cgu'),
        sponsorNotFound: t('error_sponsor_not_found'),
        sponsorAlreadyAssociated: t('error_sponsor_already_associated'),
      },
      sponsorText: t('signUp_sponsor_text'),
      promoText: t('signUp_promo_text'),
      mandatoryText: t('signUp_required_text'),
      socialConnectProps,
      alreadySignedUpText: t('signUp_signIn_title'),
      alreadySignedUpCta: {
        text: t('signUp_signIn_cta'),
        link: {
          link: router(routesPath.signIn),
        },
      },
      submitButton: {
        text: submit.pending ? t('signUp_submit_pending') : t('signUp_submit'),
        isPending: submit.pending,
      },
      submitErrors: submit.errors,
      onSubmit: (values) => handleSubmit(values),
      onTypeChange: setType,
      isTeacher: false, //type === UserType.Teacher,
      isStudent: type === UserType.Student,
    }
  }, [t, handleSubmit, submit, type, urlCGU, socialConnectProps, urlPrivacy])

  const imageProps =
    type === UserType.Teacher
      ? {
          alt: 'LiberteClass - Inscription',
          src: '/static/assets/images/signup-teacher.png',
        }
      : {
          alt: 'LiberteClass - Inscription',
          src: '/static/assets/images/signup.png',
        }

  return (
    <SignUpTemplate headerProps={headerProps} signupProps={signupProps} imageProps={imageProps} />
  )
}

export default SignUpPage
