import React, { FC } from 'react'

import Header, { HeaderProps } from '../../components/Header'
import Menu, { MenuProps } from '../../components/Menu'
import AuthLayout from '../../layouts/AuthLayout'
import { PageBackButtonProps } from '../../components/PageBackButton'
import { TabContentProps } from '../../components/TabContent'
import AvailabilityPerDayForm, {
  AvailabilityPerDayFormProps,
} from '../../components/AvailabilityPerDayForm'
import { RecurrenceCardProps } from '../../components/RecurrenceCard'
import { ActionButtonProps } from '../../components/ActionButton'

import * as SC from './styled'

export enum PreferencesAvailabilitiesTabs {
  PER_DAY = 'PER_DAY',
  RECURRING = 'RECURRING',
}

export type PreferencesAvailabilitiesTemplateProps = {
  className?: string
  headerProps: HeaderProps
  menuProps: MenuProps
  title: string
  backButton: PageBackButtonProps
  perDayTitle: string
  recurringTitle: string
  perDayForm: AvailabilityPerDayFormProps
  currentTab?: string
  onTabChange?: (tab: PreferencesAvailabilitiesTabs) => void
  recurrences?: RecurrenceCardProps[]
  addRecurrenceProps?: ActionButtonProps
}

const PreferencesAvailabilitiesTemplate: FC<PreferencesAvailabilitiesTemplateProps> = (props) => {
  const {
    headerProps,
    menuProps,
    title,
    backButton,
    perDayTitle,
    recurringTitle,
    perDayForm,
    currentTab = PreferencesAvailabilitiesTabs.PER_DAY,
    onTabChange,
    recurrences,
    addRecurrenceProps,
  } = props

  const tabContentProps: TabContentProps = {
    name: '',
    tabs: [
      {
        label: perDayTitle,
        value: PreferencesAvailabilitiesTabs.PER_DAY,
        children: <AvailabilityPerDayForm {...perDayForm} />,
      },
      {
        label: recurringTitle,
        value: PreferencesAvailabilitiesTabs.RECURRING,
        children: (
          <>
            <SC.RecurrencesList>
              {recurrences?.map((recurrence, index) => (
                <SC.RecurrenceCard key={index} {...recurrence} />
              ))}
            </SC.RecurrencesList>
            {addRecurrenceProps && <SC.ActionButton outlined {...addRecurrenceProps} />}
          </>
        ),
      },
    ],
    value: currentTab,
    onChange: (_name, v) => {
      onTabChange?.(v as PreferencesAvailabilitiesTabs)
    },
  }

  return (
    <AuthLayout header={<Header {...headerProps} withDivider />} menu={<Menu {...menuProps} />}>
      <SC.Content>
        <SC.BackButton {...backButton} />
        <SC.Title>{title}</SC.Title>
        <SC.TabContent {...tabContentProps} />
      </SC.Content>
    </AuthLayout>
  )
}

export default PreferencesAvailabilitiesTemplate
