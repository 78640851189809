import styled from 'styled-components'

import ActionButton from '../../ActionButton'
import Icon from '../../Icon'
import FormSubmit from '../../FormSubmit'

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1.6rem 0;
`

export const Title = styled.h4`
  ${(props) => ({ ...props.theme.typography.h5 })}
  color: ${(props) => props.theme.palette.colors.neonCarrot};
  margin: 0;
  align-self: flex-start;
`

export const MyIcon = styled(Icon)`
  width: 6.4rem;
  height: 6.4rem;
`

export const Text = styled.p<{ aligned?: boolean }>`
  ${(props) => ({ ...props.theme.typography.h4 })}
  color: ${(props) => props.theme.palette.colors.freeSpeechBlue};
  margin-bottom: 0;
  text-align: ${(props) => (props.aligned ? 'center' : 'left')};
`

export const Description = styled.p`
  ${(props) => ({ ...props.theme.typography.mediumRegular })}
  color: ${(props) => props.theme.palette.colors.chambray};
  margin: 1.6rem 0 0 0;
`

export const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  margin-bottom: 2rem;
  & > button {
    &:first-child {
      margin-right: 1rem;
    }
    &:last-child {
      margin-left: 1rem;
    }
  }
`

export const Button = styled(ActionButton)``
export const Submit = styled(FormSubmit)``

export const Request = styled.div`
  margin: 1.6rem 0 0 0;
`

export const Label = styled.div`
  ${(props) => ({ ...props.theme.typography.h5 })}
  color: ${(props) => props.theme.palette.colors.midnightBlue};
`

export const Value = styled.div`
  ${(props) => ({ ...props.theme.typography.mediumRegular })}
  color: ${(props) => props.theme.palette.colors.chambray};
  &:first-letter {
    text-transform: uppercase;
  }
`
