import React, { FC } from 'react'

import Header, { HeaderProps } from '../../components/Header'
import Menu, { MenuProps } from '../../components/Menu'
import AuthLayout from '../../layouts/AuthLayout'
import { PageBackButtonProps } from '../../components/PageBackButton'
import { WalletWithdrawalFormProps } from '../../components/WalletWithdrawalForm'
import SimpleFeedbackModal, {
  SimpleFeedbackModalProps,
} from '../../components/modals/SimpleFeedbackModal'

import * as SC from './styled'

export type PreferencesWalletWithdrawalTemplateProps = {
  className?: string
  headerProps: HeaderProps
  menuProps: MenuProps
  title: string
  backButton: PageBackButtonProps
  withdrawalFormProps: WalletWithdrawalFormProps
  feedbackModalProps?: SimpleFeedbackModalProps
}

const PreferencesWalletWithdrawalTemplate: FC<PreferencesWalletWithdrawalTemplateProps> = (
  props
) => {
  const {
    headerProps,
    menuProps,
    title,
    backButton,
    withdrawalFormProps,
    feedbackModalProps,
  } = props

  return (
    <AuthLayout header={<Header {...headerProps} withDivider />} menu={<Menu {...menuProps} />}>
      <SC.Content>
        <SC.BackButton {...backButton} />
        <SC.Title>{title}</SC.Title>
        <SC.Form {...withdrawalFormProps} />
      </SC.Content>
      {feedbackModalProps && <SimpleFeedbackModal {...feedbackModalProps} />}
    </AuthLayout>
  )
}

export default PreferencesWalletWithdrawalTemplate
