import styled from 'styled-components'

import SearchSubjectC from '../../components/SearchSubject'
import PageTitle from '../../components/PageTitle'
import ImageTextListC from '../../components/ImageTextList'
import PageBackButton from '../../components/PageBackButton'
import UsersSlider from '../../components/UsersSlider'
import { SearchRecurrenceTeacherProfile } from '../../components/SearchRecurrenceTeacherProfile'

export const Content = styled.div`
  width: 100%;
`

export const Title = styled(PageTitle)`
  color: ${(props) => props.theme.palette.colors.midnightBlue};
  padding-top: 0;
`
export const BackButton = styled(PageBackButton)``

export const ImageTextList = styled(ImageTextListC)`
  padding: 1.6rem;
  width: 100%;
`

export const Title2 = styled.h3`
  ${(props) => ({ ...props.theme.typography.h3 })}
  color: ${(props) => props.theme.palette.colors.neonCarrot};
  padding: 3rem 1.6rem 0.2rem 1.6rem;
  margin: 0;
`

export const Subtitle = styled.h6`
  ${(props) => ({ ...props.theme.typography.h6 })}
  color: ${(props) => props.theme.palette.colors.neonCarrot};
  padding: 0 1.6rem;
  margin: 2rem 0 0.4rem 0;
`

export const Text = styled.p`
  ${(props) => ({ ...props.theme.typography.tinySmall })}
  color: ${(props) => props.theme.palette.colors.midnightBlue};
  padding: 1.6rem 1.6rem 0rem 1.6rem;
  margin: 0;
`

export const Text2 = styled(Text)`
  padding: 0.4rem 1.6rem 0rem 1.6rem;
`

export const SearchSubject = styled(SearchSubjectC)`
  padding: 1.6rem;
`

export const Student = styled(UsersSlider)`
  margin: 1.6rem 1.6rem 0rem 1.6rem;
`

export const TeacherProfile = styled(SearchRecurrenceTeacherProfile)``
