import React, { useCallback, useState } from 'react'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'

import { HeaderProps } from '../../components/Header'
import useHeader from '../../hooks/useHeader'
import { MenuProps } from '../../components/Menu'
import useMenu from '../../hooks/useMenu'
import { CourseType, UserType } from '../../generated/graphql'
import { RestrictedReactFC } from '../../types/common'
import { actions, selectors } from '../../redux'
import { router, routesPath } from '../../router'
import { useFormSubmit } from '../../hooks/useFormSubmit'
import useBackButton from '../../hooks/useBackButton'
import { SearchMarketTemplate, SearchMarketTemplateProps } from '../../templates/SearchMarket'
import { courseTypeTranslation } from '../../graphql/enums/CourseType'

const SearchMarketPage: RestrictedReactFC<RouteComponentProps> = (props) => {
  const headerProps: HeaderProps = useHeader()
  const menuProps: MenuProps = useMenu(props)
  const { t } = useTranslation()
  const history = useHistory()
  const [values, setValues] = useState({ type: '', price: '', date: '' })

  const backButton = useBackButton()

  const onComplete = useCallback(() => {
    history.push(
      router(routesPath.searchMarketSlots, {
        type: values?.type ?? '',
        price: values?.price ?? '',
        date: values?.date ?? '',
      })
    )
  }, [history, values])

  const [search, handleSearch] = useFormSubmit(
    selectors.search.searchMarket,
    actions.search.searchMarketRequest,
    null,
    onComplete
  )

  const handleSubmit = useCallback(
    (v) => {
      const transformed = {
        ...v,
        date: dayjs(v?.date ?? '').format('YYYY-MM-DD'),
      }
      setValues(transformed)
      handleSearch(transformed)
    },
    [handleSearch]
  )

  const searchProps: SearchMarketTemplateProps = {
    headerProps: headerProps,
    menuProps: menuProps,
    title: t('searchMarket_title'),
    text: t('searchMarket_text'),
    marketFormProps: {
      fieldsProps: {
        type: {
          label: t('searchMarket_form_type_label'),
          options: [
            {
              label: courseTypeTranslation(t, CourseType.OneOff),
              value: CourseType.OneOff,
            },
          ],
        },
        date: {
          label: t('searchMarket_form_date_label'),
          placeholder: t('searchMarket_form_date_placeholder'),
          isLabelExternal: true,
        },
        price: {
          label: t('searchMarket_form_price_label'),
          placeholder: t('searchMarket_form_price_placeholder'),
          helperTextSide: 'left',
          maxValue: 65,
        },
      },
      errorTexts: {
        required: t('error_required'),
      },
      initialValues: {
        type: search?.params?.type ?? CourseType.OneOff,
        date: search?.params?.date ?? dayjs().format('YYYY-MM-DD'),
        price: search?.params?.price ?? '',
      },
      submitButton: {
        text: t('search_submit'),
        isPending: search?.pending,
      },
      submitErrors: search?.errors,
      onSubmit: handleSubmit,
    },
    backButton,
  }

  return <SearchMarketTemplate {...searchProps} />
}

SearchMarketPage.restrictedUserTypes = [UserType.Teacher]

export default SearchMarketPage
