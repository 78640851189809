import gql from 'graphql-tag'

import { ServiceMutation } from '../../../../redux/api/types/state'

const refreshToken: ServiceMutation = {
  mutation: gql`
    mutation AuthRefreshToken {
      refreshToken
    }
  `,
  transformer: (response: any) => {
    return response.refreshToken
  },
}

export default refreshToken
