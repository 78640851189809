import React, { FC } from 'react'
import { Form, Formik, FormikHelpers } from 'formik'
import * as Yup from 'yup'

import { ActionButtonProps } from '../ActionButton'
import { BasicLinkProps } from '../BasicLink'
import { light } from '../../theme/palette'
import { Icons } from '../Icon/types'
import { FormFieldConfig, FormFieldProps } from '../../types/form'
import FormSubmitErrors, { FormSubmitErrorsProps } from '../FormSubmitErrors'
import { FormSubmitProps } from '../FormSubmit'
import { getValidationSchema, renderField } from '../../helpers/FormHelpers'
import { UploadFileProps, UploadFileValue } from '../form/UploadFile'
import { TextFieldProps } from '../form/TextField'
import { TextFieldLimitedCharsProps } from '../form/TextFieldLimitedChars'
import FormScrollToError from '../FormScrollToError'
import '../../helpers/ValidationHelpers'
import LegalLegends, { LegalLegendsProps } from '../LegalLegends'

import * as SC from './styled'

export type DocumentButtonProps = {
  label: string
  icon: Icons
  color: string
}

export type SignUpProfessionalInfosValues = {
  siret: string
  cv: UploadFileValue
  diploma: UploadFileValue
  certificate: UploadFileValue
  introduction: string
}

export type SignUpProfessionalInfosProps = {
  className?: string
  step: string
  title: string
  legends?: LegalLegendsProps['texts']
  siretText: string
  noStatutTitle: string
  noStatutText: string
  noStatutCta: BasicLinkProps
  noCertificateTitle?: string
  noCertificateText: string
  noCertificateCta: BasicLinkProps
  documentTitle: string
  documentText: string
  presentationTitle: string
  mandatoryText: string
  returnButton: ActionButtonProps
  fieldsProps?: {
    siret: FormFieldProps
    cv: FormFieldProps
    diploma: FormFieldProps
    certificate: FormFieldProps
    introduction: FormFieldProps
  }
  errorTexts?: {
    required: string
    siret: string
    maxLength: string | ((a: any) => string)
  }
  initialValues: SignUpProfessionalInfosValues
  submitErrors?: FormSubmitErrorsProps['errors']
  submitButton: FormSubmitProps
  onSubmit?: (
    values: SignUpProfessionalInfosValues,
    formikHelpers: FormikHelpers<SignUpProfessionalInfosValues>
  ) => void
}

const SignUpProfessionalInfos: FC<SignUpProfessionalInfosProps> = (props) => {
  const {
    className,
    step,
    title,
    siretText,
    noStatutTitle,
    noStatutText,
    noStatutCta,
    noCertificateTitle,
    noCertificateText,
    noCertificateCta,
    documentTitle,
    documentText,
    presentationTitle,
    mandatoryText,
    returnButton,
    fieldsProps,
    errorTexts,
    initialValues,
    submitErrors,
    submitButton,
    legends,
    onSubmit = (_values, _formikHelpers) => null,
  } = props

  const fields: FormFieldConfig[] = [
    {
      name: 'siret',
      Component: SC.TextField,
      validation: Yup.string().siret(errorTexts?.siret).required(errorTexts?.required),
      required: true,
      helperTextSide: 'start',
    } as Partial<TextFieldProps> & FormFieldConfig,
    {
      name: 'cv',
      Component: SC.UploadFile,
      validation: Yup.mixed().test('file', errorTexts?.required ?? '', function (value) {
        return !!value.file
      }),
      required: true,
    } as Partial<UploadFileProps> & FormFieldConfig,
    {
      name: 'diploma',
      Component: SC.UploadFile,
      validation: Yup.mixed().test('file', errorTexts?.required ?? '', function (value) {
        return !!value.file
      }),
      required: true,
    } as Partial<UploadFileProps> & FormFieldConfig,
    {
      name: 'certificate',
      Component: SC.UploadFile,
      validation: Yup.mixed().test('file', errorTexts?.required ?? '', function (value) {
        return !!value.file
      }),
      required: true,
    } as Partial<UploadFileProps> & FormFieldConfig,
    {
      name: 'introduction',
      Component: SC.TextFieldLimited,
      validation: Yup.string().max(320, errorTexts?.maxLength),
      required: true,
      multiline: true,
      rows: 4,
      maxChar: 320,
    } as Partial<TextFieldLimitedCharsProps> & FormFieldConfig,
  ]

  const siretField = fields.find((f) => f.name === 'siret')
  const files = fields.filter((f) => f.name !== 'siret' && f.name !== 'introduction')
  const introductionField = fields.find((f) => f.name === 'introduction')

  const validationSchema = getValidationSchema(fields)

  return (
    <SC.ProfessionalInfos className={className}>
      <SC.Step>{step}</SC.Step>
      <SC.Title>{title}</SC.Title>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnChange={true}
        validateOnBlur={true}
        onSubmit={onSubmit}
      >
        {(formikProps) => (
          <Form noValidate>
            <FormScrollToError formikProps={formikProps} />
            <SC.Siret>
              <SC.SiretText>{siretText}</SC.SiretText>
              {siretField &&
                renderField(
                  siretField,
                  formikProps,
                  fieldsProps,
                  undefined,
                  undefined,
                  submitErrors
                )}
            </SC.Siret>
            <SC.NoStatut>
              <SC.NoStatutTitle>{noStatutTitle}</SC.NoStatutTitle>
              <SC.NoStatutText>{noStatutText}</SC.NoStatutText>
              <SC.NoStatutCta
                {...noStatutCta}
                icon={Icons.longArrowRight}
                iconColor={light.colors.freeSpeechBlue}
              />
            </SC.NoStatut>
            <SC.Document>
              <SC.DocumentTitle>{documentTitle}</SC.DocumentTitle>
              <SC.DocumentText>{documentText}</SC.DocumentText>
              <SC.DocumentUploads>
                {files?.map((f, index) => renderField(f, formikProps, fieldsProps, index))}
                <SC.NoStatut smallMargin>
                  <SC.NoStatutTitle>{noCertificateTitle}</SC.NoStatutTitle>
                  <SC.NoStatutText>{noCertificateText}</SC.NoStatutText>
                  <SC.NoStatutCta
                    {...noCertificateCta}
                    icon={Icons.longArrowRight}
                    iconColor={light.colors.freeSpeechBlue}
                  />
                </SC.NoStatut>
              </SC.DocumentUploads>
            </SC.Document>
            <SC.Presentation>
              <SC.PresentationTitle>{presentationTitle}</SC.PresentationTitle>
              {introductionField && renderField(introductionField, formikProps, fieldsProps)}
            </SC.Presentation>
            <SC.MandatoryText>{mandatoryText}</SC.MandatoryText>
            <FormSubmitErrors errors={submitErrors} />
            <SC.Buttons>
              <SC.ReturnButton {...returnButton} outlined />
              <SC.SubmitButton {...submitButton} autoIcon />
            </SC.Buttons>
            <LegalLegends texts={legends} />
          </Form>
        )}
      </Formik>
    </SC.ProfessionalInfos>
  )
}

export default SignUpProfessionalInfos
