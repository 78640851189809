import { all, call, put, SagaReturnType, select, takeLeading } from 'redux-saga/effects'

import { actions, selectors } from '..'
import ApiSagas from '../api/sagas'
import { ApiResponse } from '../api/types/state'
import { ServiceRequestAction } from '../../helpers/ReduxHelpers'
import register from '../../graphql/services/user/mutations/register'
import login from '../../graphql/services/user/mutations/login'
import logout from '../../graphql/services/user/mutations/logout'
import registerPersonalInformation from '../../graphql/services/user/mutations/registerPersonalInformation'
import me from '../../graphql/services/user/queries/me'
import {
  MutationRegisterPersonalInformationArgs,
  MutationRegisterProfessionalInformationArgs,
  MutationSocialLoginArgs,
  QueryNotificationsArgs,
  QueryStripeAccountLinkArgs,
  QueryStripeLoginLinkArgs,
  SocialProviderType,
  TeacherDisciplineInput,
  User,
  UserType,
} from '../../generated/graphql'
import { SignUpFormValues } from '../../components/SignUpForm'
import { SignUpPersonalInfosFormValues } from '../../components/SignUpPersonalInfos'
import { SignInFormValues } from '../../components/SignInForm'
import { LevelPickersFormValues } from '../../components/LevelPickersForm'
import updateTeacherDisciplines from '../../graphql/services/teacher/mutations/updateTeacherDisciplines'
import { SignUpProfessionalInfosValues } from '../../components/SignUpProfessionalInfos'
import registerProfessionalInformation from '../../graphql/services/teacher/mutations/registerProfessionalInformation'
import { getAuthCookie, removeAuthCookie, setAuthCookie } from '../../helpers/CookieHelpers'
import { PricesChoiceFormValues, PricingType } from '../../components/PricesChoiceForm'
import updateTeacherPrice from '../../graphql/services/teacher/mutations/updateTeacherPrice'
import stripeLoginLink from '../../graphql/services/teacher/queries/stripeLoginLink'
import { router, routesPath } from '../../router'
import { app } from '../../configuration'
import stripeAccountLink from '../../graphql/services/teacher/queries/stripeAccountLink'
import { SignInForgotFormValues } from '../../components/SignInForgotForm'
import forgotPassword from '../../graphql/services/user/mutations/forgotPassword'
import resetPassword from '../../graphql/services/user/mutations/resetPassword'
import { SignInResetFormValues } from '../../components/SignInResetForm'
import { SocialConnectType, SocialConnectValues } from '../../components/SocialConnect'
import socialLogin from '../../graphql/services/user/mutations/socialLogin'
import { SignUpSocialFormValues } from '../../components/SignUpSocialForm'
import confirmSocialRegister from '../../graphql/services/user/mutations/confirmSocialregister'
import resendVerificationMail from '../../graphql/services/user/mutations/resendVerificationMail'
import { AddChildrenValues } from '../../components/SignUpAddChildren'
import registerFamily from '../../graphql/services/guardian/mutations/registerFamily'
import reloadWallet from '../../graphql/services/user/queries/reloadWallet'
import {
  SignUpAttachParentFormValues,
  SignUpAttachParentType,
} from '../../components/SignUpAttachParent'
import linkGuardian from '../../graphql/services/user/mutations/linkGuardian'
import linkGuardianLater from '../../graphql/services/user/mutations/linkGuardianLater'
import inviteGuardian from '../../graphql/services/user/mutations/inviteGuardian'
import { ProfileFormValues } from '../../components/ProfilForm'
import updateTeacher from '../../graphql/services/teacher/mutations/updateTeacher'
import updateStudent from '../../graphql/services/student/mutations/updateStudent'
import updateGuardian from '../../graphql/services/guardian/mutations/updateGuardian'
import { ChangePasswordValues } from '../../components/modals/ChangePasswordModal'
import updatePassword from '../../graphql/services/user/mutations/updatePassword'
import reloadNotificationsCount from '../../graphql/services/user/queries/reloadNotificationsCount'
import notifications from '../../graphql/services/notifications/query/notifications'

export default class AuthSagas {
  static *onInit() {
    const cookie = getAuthCookie()
    console.log('AUTH : init with cookie', cookie)
    if (cookie) {
      yield put(actions.auth.setImpersonate(cookie?.impersonate ?? false))
      yield put(actions.auth.setToken(cookie?.token))
    }

    const isConnected: SagaReturnType<typeof selectors.auth.isConnected> = yield select(
      selectors.auth.isConnected
    )
    console.log('AUTH : is connected', isConnected)
    if (isConnected) {
      const rs: ApiResponse<typeof me> = yield call(ApiSagas.query, me)
      console.log('AUTH : get me', rs)
      if (!rs?.errors) {
        yield put(actions.auth.setUser(rs?.data))
      } else {
        yield put(actions.auth.resetAuth())
      }
    }
  }

  static *onRegister({ payload }: ServiceRequestAction<SignUpFormValues>) {
    const rs: ApiResponse<typeof register> = yield call(ApiSagas.mutate, register, payload)

    if (!rs?.errors) {
      if (rs?.data?.token) {
        yield put(actions.auth.setToken(rs?.data?.token))
      }
      yield put(actions.auth.setUser(rs?.data))
      yield put(actions.auth.registerSuccess(rs?.data))
    } else {
      yield put(actions.auth.registerError(rs.errors))
    }
  }

  static *onSignUpPersonalInfos({ payload }: ServiceRequestAction<SignUpPersonalInfosFormValues>) {
    const variables: MutationRegisterPersonalInformationArgs = {
      display_address: payload?.address?.address,
      address: payload?.address?.components?.route,
      city: payload?.address?.components?.city,
      zip_code: payload?.address?.components?.zipcode,
      phone: payload?.phone,
      avatar: payload?.avatar,
      grade: payload?.level,
      school: payload?.school,
    }

    const rs: ApiResponse<typeof registerPersonalInformation> = yield call(
      ApiSagas.mutate,
      registerPersonalInformation,
      variables
    )

    if (!rs?.errors) {
      yield put(actions.auth.setUser(rs?.data))
      yield put(actions.auth.signUpPersonalInfosSuccess(rs?.data))
    } else {
      yield put(actions.auth.signUpPersonalInfosError(rs.errors))
    }
  }

  static *onSignUpTeacherDisciplines({ payload }: ServiceRequestAction<LevelPickersFormValues>) {
    const data = payload || {}
    const entries: TeacherDisciplineInput[] = []
    Object.keys(data)?.forEach((discipline) => {
      data[discipline]?.forEach((grade) => {
        entries.push({
          discipline,
          grade,
        })
      })
    })

    const rs: ApiResponse<typeof updateTeacherDisciplines> = yield call(
      ApiSagas.mutate,
      updateTeacherDisciplines,
      { disciplines: entries }
    )

    if (!rs?.errors) {
      yield put(actions.auth.setUser(rs?.data))
      yield put(actions.auth.signUpTeacherDisciplinesSuccess(rs?.data))
    } else {
      yield put(actions.auth.signUpTeacherDisciplinesError(rs.errors))
    }
  }

  static *onSignUpProfessionalInfos({
    payload,
  }: ServiceRequestAction<SignUpProfessionalInfosValues>) {
    const user: SagaReturnType<typeof selectors.auth.user> = yield select(selectors.auth.user)
    const variables: MutationRegisterProfessionalInformationArgs = {
      siret: payload?.siret ?? '',
      ...(payload?.cv?.file !== user?.teacher?.cv?.file && {
        cv: payload?.cv ?? { file: '', name: '' },
      }),
      ...(payload?.diploma?.file !== user?.teacher?.diploma?.file && {
        diploma: payload?.diploma ?? { file: '', name: '' },
      }),
      ...(payload?.certificate?.file !== user?.teacher?.company_certificate?.file && {
        company_certificate: payload?.certificate ?? { file: '', name: '' },
      }),
      introduction: payload?.introduction,
    }

    const rs: ApiResponse<typeof registerProfessionalInformation> = yield call(
      ApiSagas.mutate,
      registerProfessionalInformation,
      variables
    )

    if (!rs?.errors) {
      yield put(actions.auth.setUser(rs?.data))
      yield put(actions.auth.signUpProfessionalInfosSuccess(rs?.data))
    } else {
      yield put(actions.auth.signUpProfessionalInfosError(rs.errors))
    }
  }

  static *onSignUpGuardianAddChildren({ payload }: ServiceRequestAction<AddChildrenValues>) {
    const variables = {
      members: payload?.children?.map((child) => ({
        first_name: child.firstName,
        last_name: child.lastName,
        avatar: child.file,
        grade: child.level,
        school: child.school,
        email: child.email,
        password: child.password,
      })),
    }

    const rs: ApiResponse<typeof registerFamily> = yield call(
      ApiSagas.mutate,
      registerFamily,
      variables
    )

    if (!rs?.errors) {
      const user: SagaReturnType<typeof selectors.auth.user> = yield select(selectors.auth.user)

      yield put(
        actions.auth.setUser({
          ...user,
          ...rs?.data,
        } as User)
      )
      yield put(actions.auth.signUpGuardianAddChildrenSuccess(rs?.data))
    } else {
      yield put(actions.auth.signUpGuardianAddChildrenError(rs.errors))
    }
  }

  static *onSignUpChildAddGuardian({
    payload,
  }: ServiceRequestAction<SignUpAttachParentFormValues>) {
    let rs: ApiResponse<typeof linkGuardian | typeof linkGuardianLater | typeof inviteGuardian>

    if (!payload?.code && !payload?.mail) {
      // skip
      rs = yield call(ApiSagas.mutate, linkGuardianLater)
    } else if (payload?.type === SignUpAttachParentType.EMAIL) {
      //email
      rs = yield call(ApiSagas.mutate, inviteGuardian, {
        email: payload?.mail ?? '',
      })
    } else {
      //code
      rs = yield call(ApiSagas.mutate, linkGuardian, {
        sponsor_code: payload?.code ?? '',
      })
    }

    if (!rs?.errors) {
      const user: SagaReturnType<typeof selectors.auth.user> = yield select(selectors.auth.user)

      yield put(
        actions.auth.setUser({
          ...user,
          ...rs?.data,
        } as User)
      )
      yield put(actions.auth.signUpChildAddGuardianSuccess(rs?.data))
    } else {
      yield put(actions.auth.signUpChildAddGuardianError(rs.errors))
    }
  }

  static *onSignUpTeacherPricing({ payload }: ServiceRequestAction<PricesChoiceFormValues>) {
    let rs: ApiResponse<typeof updateTeacherPrice> | ApiResponse<typeof updateTeacherDisciplines>
    if (payload?.type === PricingType.UNIQUE) {
      rs = yield call(ApiSagas.mutate, updateTeacherPrice, {
        price: parseFloat(payload?.unique?.replace(',', '.')) * 100,
      })
    } else {
      const disciplines = [] as TeacherDisciplineInput[]
      const userDisciplines: SagaReturnType<
        typeof selectors.auth.disciplinesWithGrades
      > = yield select(selectors.auth.disciplinesWithGrades)
      payload?.custom?.forEach((row, index) => {
        const discipline = userDisciplines?.[index]?.id
        if (discipline) {
          Object.keys(row)?.forEach((grade) => {
            disciplines.push({
              discipline,
              grade,
              price: (parseFloat(row[grade]?.replace(',', '.')) ?? 0) * 100,
            })
          })
        }
      })

      rs = yield call(ApiSagas.mutate, updateTeacherDisciplines, { disciplines })
    }

    if (!rs?.errors) {
      yield put(actions.auth.setUser(rs?.data))
      yield put(actions.auth.signUpTeacherPricingSuccess(rs?.data))
    } else {
      yield put(actions.auth.signUpTeacherPricingError(rs.errors))
    }
  }

  static *onLogin({ payload }: ServiceRequestAction<SignInFormValues>) {
    const rs: ApiResponse<typeof login> = yield call(ApiSagas.mutate, login, payload)

    if (!rs?.errors) {
      if (rs?.data?.token) {
        yield put(actions.auth.setToken(rs?.data?.token))
      }
      yield put(actions.auth.setUser(rs?.data))
      yield put(actions.auth.loginSuccess(rs?.data))
    } else {
      yield put(actions.auth.loginError(rs.errors))
    }
  }

  static *logout() {
    yield call(ApiSagas.mutate, logout)
    yield put(actions.auth.resetAuth())
  }

  static *onResetAuth() {
    yield put(actions.search.reset())
    removeAuthCookie()
  }

  static *onSetToken() {
    const token: SagaReturnType<typeof selectors.auth.token> = yield select(selectors.auth.token)
    const impersonate: SagaReturnType<typeof selectors.auth.impersonate> = yield select(
      selectors.auth.impersonate
    )

    if (token) {
      setAuthCookie({ token, impersonate })
    }
  }

  static *onSignInForgot({ payload }: ServiceRequestAction<SignInForgotFormValues>) {
    const rs: ApiResponse<typeof forgotPassword> = yield call(
      ApiSagas.mutate,
      forgotPassword,
      payload
    )

    if (!rs?.errors) {
      yield put(actions.auth.signInForgotSuccess(rs?.data))
    } else {
      yield put(actions.auth.signInForgotError(rs.errors))
    }
  }

  static *onSignInReset({ payload }: ServiceRequestAction<SignInResetFormValues>) {
    const rs: ApiResponse<typeof resetPassword> = yield call(
      ApiSagas.mutate,
      resetPassword,
      payload
    )

    if (!rs?.errors) {
      if (rs?.data?.token) {
        yield put(actions.auth.setToken(rs?.data?.token))
      }
      yield put(actions.auth.setUser(rs?.data))
      yield put(actions.auth.signInResetSuccess(rs?.data))
    } else {
      yield put(actions.auth.signInResetError(rs.errors))
    }
  }

  static *onLoadStripeLoginLink({ payload }: ServiceRequestAction<QueryStripeLoginLinkArgs>) {
    const rs: ApiResponse<typeof stripeLoginLink> = yield call(ApiSagas.query, stripeLoginLink, {
      redirect_url: payload || router(routesPath.signUp),
    })

    if (!rs?.errors) {
      yield put(actions.auth.loadStripeLoginLinkSuccess(rs?.data))
    } else {
      yield put(actions.auth.loadStripeLoginLinkError(rs.errors))
    }
  }

  static *onLoadStripeAccountLink({ payload }: ServiceRequestAction<QueryStripeAccountLinkArgs>) {
    const rs: ApiResponse<typeof stripeLoginLink> = yield call(ApiSagas.query, stripeAccountLink, {
      return_url: payload?.return_url || app.APP_URL + router(routesPath.signUp),
      refresh_url: payload?.refresh_url || app.APP_URL + router(routesPath.signUp),
    })

    if (!rs?.errors) {
      yield put(actions.auth.loadStripeAccountLinkSuccess(rs?.data))
    } else {
      yield put(actions.auth.loadStripeAccountLinkError(rs.errors))
    }
  }

  static *onSocialLogin({ payload }: ServiceRequestAction<SocialConnectValues>) {
    const input: MutationSocialLoginArgs = {
      type:
        payload?.type === SocialConnectType.APPLE
          ? SocialProviderType.Apple
          : SocialProviderType.Google ?? '',
      code: payload?.code ?? '',
      ...(payload?.user?.first_name && { first_name: payload?.user?.first_name ?? '' }),
      ...(payload?.user?.last_name && { last_name: payload?.user?.last_name ?? '' }),
      ...(payload?.user?.name?.firstName && { first_name: payload?.user?.name?.firstName ?? '' }),
      ...(payload?.user?.name?.lastName && { last_name: payload?.user?.name?.lastName ?? '' }),
      ...(payload?.user?.email && { email: payload?.user?.email ?? '' }),
    }
    const rs: ApiResponse<typeof socialLogin> = yield call(ApiSagas.mutate, socialLogin, input)

    if (!rs?.errors) {
      if (rs?.data?.token) {
        yield put(actions.auth.setToken(rs?.data?.token))
      }
      yield put(actions.auth.setUser(rs?.data))
      yield put(actions.auth.socialLoginSuccess(rs?.data))
    } else {
      yield put(actions.auth.socialLoginError(rs.errors))
    }
  }

  static *onSignUpSocial({ payload }: ServiceRequestAction<SignUpSocialFormValues>) {
    const rs: ApiResponse<typeof confirmSocialRegister> = yield call(
      ApiSagas.mutate,
      confirmSocialRegister,
      payload
    )

    if (!rs?.errors) {
      yield put(actions.auth.setUser(rs?.data))
      yield put(actions.auth.signUpSocialSuccess(rs?.data))
    } else {
      yield put(actions.auth.signUpSocialError(rs.errors))
    }
  }

  static *onResendVerificationMail() {
    const rs: ApiResponse<typeof resendVerificationMail> = yield call(
      ApiSagas.mutate,
      resendVerificationMail
    )

    if (!rs?.errors) {
      yield put(actions.auth.resendVerificationMailSuccess(rs?.data))
    } else {
      yield put(actions.auth.resendVerificationMailError(rs.errors))
    }
  }

  static *onReloadWallet() {
    const rs: ApiResponse<typeof reloadWallet> = yield call(ApiSagas.query, reloadWallet)

    if (!rs?.errors) {
      const user: SagaReturnType<typeof selectors.auth.user> = yield select(selectors.auth.user)
      yield put(actions.auth.setUser({ ...(user as User), wallet: rs?.data?.wallet }))
      yield put(actions.auth.reloadWalletSuccess(rs?.data))
    } else {
      yield put(actions.auth.reloadWalletError(rs.errors))
    }
  }
  static *onReloadNotificationsCount() {
    const rs: ApiResponse<typeof reloadNotificationsCount> = yield call(
      ApiSagas.query,
      reloadNotificationsCount
    )

    if (!rs?.errors) {
      const user: SagaReturnType<typeof selectors.auth.user> = yield select(selectors.auth.user)
      yield put(
        actions.auth.setUser({
          ...(user as User),
          ...rs?.data,
        })
      )
      yield put(actions.auth.reloadNotificationsCountSuccess(rs?.data))
    } else {
      yield put(actions.auth.reloadNotificationsCountError(rs.errors))
    }
  }

  static *onUpdateProfile({ payload }: ServiceRequestAction<ProfileFormValues>) {
    const user: SagaReturnType<typeof selectors.auth.user> = yield select(selectors.auth.user)
    const input: any = {
      first_name: payload?.first_name ?? '',
      last_name: payload?.last_name ?? '',
      email: payload?.email ?? '',
      avatar: payload?.avatar,
      ...(user?.user_type === UserType.Student && {
        grade: payload?.grade,
        school: payload?.school,
      }),
      ...((user?.user_type === UserType.Teacher || user?.user_type === UserType.Guardian) && {
        display_address: payload?.address?.address ?? '',
        address: payload?.address?.components?.route,
        city: payload?.address?.components?.city,
        zip_code: payload?.address?.components?.zipcode,
        phone: payload?.phone ?? '',
      }),
      ...(user?.user_type === UserType.Teacher && {
        ...(payload?.cv?.file !== user?.teacher?.cv?.file && {
          cv: payload?.cv ?? { file: '', name: '' },
        }),
        ...(payload?.diploma?.file !== user?.teacher?.diploma?.file && {
          diploma: payload?.diploma ?? { file: '', name: '' },
        }),
        ...(payload?.certificate?.file !== user?.teacher?.company_certificate?.file && {
          company_certificate: payload?.certificate ?? { file: '', name: '' },
        }),
        introduction: payload?.introduction,
      }),
    }

    const func: any =
      user?.user_type === UserType.Student
        ? updateStudent
        : user?.user_type === UserType.Guardian
        ? updateGuardian
        : updateTeacher

    const rs: ApiResponse<typeof func> = yield call(ApiSagas.mutate, func, input)

    if (!rs?.errors) {
      yield put(actions.auth.setUser(rs?.data))
      yield put(actions.auth.updateProfileSuccess(rs?.data))
    } else {
      yield put(actions.auth.updateProfileError(rs.errors))
    }
  }

  static *onUpdatePassword({ payload }: ServiceRequestAction<ChangePasswordValues>) {
    const rs: ApiResponse<typeof updatePassword> = yield call(
      ApiSagas.mutate,
      updatePassword,
      payload
    )

    if (!rs?.errors) {
      yield put(actions.auth.updatePasswordSuccess(rs?.data))
    } else {
      yield put(actions.auth.updatePasswordError(rs.errors))
    }
  }

  static *onNotificationsRequest({ payload }: ServiceRequestAction<QueryNotificationsArgs>) {
    const rs: ApiResponse<typeof notifications> = yield call(ApiSagas.query, notifications, payload)

    if (!rs?.errors) {
      const user: SagaReturnType<typeof selectors.auth.user> = yield select(selectors.auth.user)
      yield put(
        actions.auth.setUser({
          ...(user as User),
          unread_notifications: 0,
        })
      )
      yield put(actions.auth.notificationsSuccess(rs?.data))
    } else {
      yield put(actions.auth.notificationsError(rs.errors))
    }
  }

  static *loop() {
    yield all([
      takeLeading(actions.auth.signUpPersonalInfosRequest, this.onSignUpPersonalInfos),
      takeLeading(actions.auth.signUpTeacherPricingRequest, this.onSignUpTeacherPricing),
      takeLeading(actions.auth.signUpGuardianAddChildrenRequest, this.onSignUpGuardianAddChildren),
      takeLeading(actions.auth.signUpTeacherDisciplinesRequest, this.onSignUpTeacherDisciplines),
      takeLeading(actions.auth.signUpProfessionalInfosRequest, this.onSignUpProfessionalInfos),
      takeLeading(actions.auth.signUpChildAddGuardianRequest, this.onSignUpChildAddGuardian),
      takeLeading(actions.auth.registerRequest, this.onRegister),
      takeLeading(actions.auth.loginRequest, this.onLogin),
      takeLeading(actions.auth.logOut, this.logout),
      takeLeading(actions.auth.resetAuth, this.onResetAuth),
      takeLeading(actions.auth.setToken, this.onSetToken),
      takeLeading(actions.auth.signInForgotRequest, this.onSignInForgot),
      takeLeading(actions.auth.signInResetRequest, this.onSignInReset),
      takeLeading(actions.auth.loadStripeLoginLinkRequest, this.onLoadStripeLoginLink),
      takeLeading(actions.auth.loadStripeAccountLinkRequest, this.onLoadStripeAccountLink),
      takeLeading(actions.auth.socialLoginRequest, this.onSocialLogin),
      takeLeading(actions.auth.signUpSocialRequest, this.onSignUpSocial),
      takeLeading(actions.auth.resendVerificationMailRequest, this.onResendVerificationMail),
      takeLeading(actions.auth.reloadWalletRequest, this.onReloadWallet),
      takeLeading(actions.auth.reloadNotificationsCountRequest, this.onReloadNotificationsCount),
      takeLeading(actions.auth.notificationsRequest, this.onNotificationsRequest),
      takeLeading(actions.auth.updateProfileRequest, this.onUpdateProfile),
      takeLeading(actions.auth.updatePasswordRequest, this.onUpdatePassword),
    ])
  }
}
