import { Container } from '@material-ui/core'
import styled from 'styled-components'

import TextField from '../form/TextField'
import ToggleButtonGroup from '../form/ToggleButtonGroup'
import FormSubmit from '../FormSubmit'
import RatingCustom from '../RatingCustom'
import SubjectWithThemeCard from '../SubjectWithThemeCard'
import Number from '../form/Number'

export const ThemePicker = styled.div``

export const Wrapper = styled(Container)``

export const ThemeCard = styled(SubjectWithThemeCard)``

export const QuantityField = styled(ToggleButtonGroup)`
  padding-top: 1.6rem;
  & div {
    width: 100%;
    flex-wrap: nowrap;
  }
  & button {
    width: 100%;
    max-width: 100%;
    min-width: 0;
    margin-bottom: 0;
    &:last-child {
      margin-right: 0;
    }
  }
`

export const ForWhoField = styled(ToggleButtonGroup)`
  & div {
    width: 100%;
    flex-wrap: nowrap;
  }
  & button {
    width: 100%;
    max-width: 100%;
    &:last-child {
      margin-right: 0;
    }
  }
`

export const GroupField = styled(TextField)``

export const RatingLabel = styled.p`
  ${(props) => ({ ...props.theme.typography.h5 })}
  color: ${(props) => props.theme.palette.colors.midnightBlue};
  margin: 2.4rem 0 1.2rem 0;
`

export const RatingField = styled(RatingCustom)``

export const PriceLabel = styled.p`
  ${(props) => ({ ...props.theme.typography.h5 })}
  color: ${(props) => props.theme.palette.colors.midnightBlue};
  margin: 2.4rem 0 1.1rem 0;
`

export const PriceRecommandationBox = styled.div`
  display: flex;
  margin: 0 0 1.1rem 0;
`

export const PriceRecommandationLabel = styled.p`
  ${(props) => ({ ...props.theme.typography.tinySmall })}
  color: ${(props) => props.theme.palette.colors.grey};
  margin: 0 1rem 0rem 0;
`

export const PriceRecommandation = styled.p`
  ${(props) => ({ ...props.theme.typography.mediumRegular })}
  color: ${(props) => props.theme.palette.colors.cornflowerBlue};
  margin: -0.4rem 0 0rem 0;
`

export const PriceField = styled(Number)`
  max-width: 25rem;
`

export const MandatoryText = styled.p`
  ${(props) => ({ ...props.theme.typography.tinySmall })}
  color: ${(props) => props.theme.palette.colors.grey};
  margin: 2.4rem 0;
`

export const DefineDisponibilities = styled(FormSubmit)`
  margin: 2.7rem 0 0 0;
`

export const HiddenSubmitButton = styled(FormSubmit)`
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
`
export const SubmitButton = styled(FormSubmit)`
  margin: 2.7rem 0 0 0;
`
