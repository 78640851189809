import React, { FC } from 'react'

import Header, { HeaderProps } from '../../components/Header'
import Menu, { MenuProps } from '../../components/Menu'
import AuthLayout from '../../layouts/AuthLayout'
import { PageBackButtonProps } from '../../components/PageBackButton'
import { PotDepositFormProps } from '../../components/PotDepositForm'
import { StripePaymentFormProps } from '../../components/StripePaymentForm'

import * as SC from './styled'

export type PreferencesWalletDepositTemplateProps = {
  className?: string
  headerProps: HeaderProps
  menuProps: MenuProps
  title: string
  backButton: PageBackButtonProps
  potDepositFormProps: PotDepositFormProps
  stripePaymentFormProps: StripePaymentFormProps
  submitted?: boolean
}

const PreferencesWalletDepositTemplate: FC<PreferencesWalletDepositTemplateProps> = (props) => {
  const {
    headerProps,
    menuProps,
    title,
    backButton,
    potDepositFormProps,
    stripePaymentFormProps,
    submitted,
  } = props

  return (
    <AuthLayout header={<Header {...headerProps} withDivider />} menu={<Menu {...menuProps} />}>
      <SC.Content>
        <SC.BackButton {...backButton} />
        <SC.Title>{title}</SC.Title>
        {!submitted ? (
          <SC.PotDeposit {...potDepositFormProps} />
        ) : (
          <SC.PaymentForm {...stripePaymentFormProps} />
        )}
      </SC.Content>
    </AuthLayout>
  )
}

export default PreferencesWalletDepositTemplate
