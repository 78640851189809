import { Container } from '@material-ui/core'
import styled from 'styled-components'
import { Form } from 'formik'

import RatingWithFields from '../RatingWithFields'
import FormSubmit from '../FormSubmit'

export const RatingsForm = styled.div``

export const Wrapper = styled(Container)`
  width: 100%;
`

export const Title = styled.h2`
  ${(props) => ({ ...props.theme.typography.h4 })}
  color: ${(props) => props.theme.palette.colors.midnightBlue};
`

export const MyForm = styled(Form)`
  display: flex;
  flex-direction: column;
`

export const Students = styled.div`
  margin: -0.8rem;
  ${(props) => props.theme.breakpoints.up('md')} {
    display: flex;
    flex-direction: row;
  }
`

export const Rating = styled(RatingWithFields)`
  margin: 0.8rem;
`

export const SubmitButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

export const SubmitButton = styled(FormSubmit)`
  flex: 2;
  margin: 2rem 0 2rem 0;
  ${(props) => props.theme.breakpoints.up('md')} {
    max-width: 20rem;
  }
`
