import styled from 'styled-components'

import PageTitle from '../../components/PageTitle'
import PageBackButton from '../../components/PageBackButton'
import RatingModalities from '../../components/RatingModalities'

export const Content = styled.div``

export const Title = styled(PageTitle)``
export const BackButton = styled(PageBackButton)``
export const ScoreCard = styled(RatingModalities)`
  width: 100%;
  padding: 1.6rem;
  ${(props) => props.theme.breakpoints.up('md')} {
    max-width: 52rem;
  }
`
