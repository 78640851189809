import React, { FC } from 'react'

import { LinkProps } from '../Link'

import * as SC from './styled'

export type ThemeListProps = {
  className?: string
  title: string
  themes: {
    id?: number
    title: string
    link?: LinkProps
    value?: string
  }[]
  clicked?: number
  onClick?: (id: number) => void
  isDisabled?: boolean
  name?: string
  value?: string
  onChange?: (name: string, value: string) => void
}

const ThemeList: FC<ThemeListProps> = (props) => {
  const {
    className,
    title,
    themes,
    clicked,
    onClick,
    isDisabled,
    name = '',
    value,
    onChange,
  } = props

  return (
    <SC.ThemeList className={className}>
      <SC.Wrapper disableGutters>
        <SC.Title>{title}</SC.Title>
        <SC.Themes>
          {themes.map((theme, key) => (
            <SC.ThemeItem
              key={theme.id || theme.value || key}
              isClicked={
                (clicked !== undefined && clicked === theme.id) ||
                (value !== undefined && value === theme?.value)
              }
              {...theme.link}
              onClick={() => {
                onClick?.(theme.id ?? 0)
                onChange?.(name, theme?.value ?? '')
              }}
              isDisabled={isDisabled}
            >
              {theme.title}
            </SC.ThemeItem>
          ))}
        </SC.Themes>
      </SC.Wrapper>
    </SC.ThemeList>
  )
}

export default ThemeList
