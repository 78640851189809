import gql from 'graphql-tag'

const createCreditPaymentIntent = {
  mutation: gql`
    mutation createCreditPaymentIntent($amount: Int!) {
      createCreditPaymentIntent(amount: $amount) {
        id
        status
        client_secret
      }
    }
  `,
  transformer: (response: any): any => {
    return response.createCreditPaymentIntent
  },
}

export default createCreditPaymentIntent
