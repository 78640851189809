import { Container } from '@material-ui/core'
import styled from 'styled-components'

import DocumentUploadC from '../form/UploadFile'

export const DocumentUploadWrapper = styled.div`
  ${(props) => props.theme.breakpoints.up('md')} {
    max-width: 80rem;
  }
`

export const Wrapper = styled(Container)``

export const Title = styled.h3`
  ${(props) => ({ ...props.theme.typography.h6 })}
  color: ${(props) => props.theme.palette.colors.midnightBlue};
  margin: 0 0 1rem 0;
`

export const Text = styled.p`
  ${(props) => ({ ...props.theme.typography.smallRegular })}
  color: ${(props) => props.theme.palette.colors.chambray};
  margin: 0 0 1rem 0;
`

export const Documents = styled.div`
  display: flex;
  flex-direction: column;
  ${(props) => props.theme.breakpoints.up('md')} {
    flex-direction: row;
    flex-wrap: wrap;
    & div {
      margin-right: 1rem;
    }
  }
`

export const DocumentUpload = styled(DocumentUploadC)`
  margin-bottom: 1rem;
`
