import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { HeaderProps } from '../../components/Header'
import useHeader from '../../hooks/useHeader'
import { SignUpPersonalInfosProps } from '../../components/SignUpPersonalInfos'
import SignUpPersonalInfosTemplate from '../../templates/SignUpPersonalInfos'
import { actions, selectors } from '../../redux'
import { resolveRegisterStepURL } from '../../helpers/RegisterHelpers'
import { useFormSubmit } from '../../hooks/useFormSubmit'
import { api, google } from '../../configuration'
import { UserType } from '../../generated/graphql'

const SignUpPersonalInfosPage: React.FC = (_props) => {
  const headerProps: HeaderProps = useHeader()

  const { t } = useTranslation()
  const token = useSelector(selectors.auth.token)
  const user = useSelector(selectors.auth.user)
  const grades = useSelector(selectors.app.grades)
  const history = useHistory()
  const setting = useSelector(selectors.app.setting)
  const urlPrivacy = setting?.links?.privacy

  const onComplete = useCallback(() => {
    history.push(resolveRegisterStepURL(user))
  }, [user, history])

  const [submit, handleSubmit] = useFormSubmit(
    selectors.auth.signUpPersonalInfos,
    actions.auth.signUpPersonalInfosRequest,
    actions.auth.signUpPersonalInfosReset,
    onComplete
  )

  const guardianTeacherFields = useMemo(
    () => ({
      phone: {
        label: t('signUpPersonalInfos_form_phone_label'),
      },
      address: {
        label: t('signUpPersonalInfos_form_address_label'),
        ...google.addressConfig,
      },
      avatar: {
        label: '',
        image: {
          alt: 'LiberteClass - Upload',
          src: user?.avatar ?? '/static/assets/images/upload.png',
        },
        config: {
          target: api.UPLOAD_ENDPOINT,
          headers: { Authorization: `Bearer ${token}` },
          fileType: ['jpg', 'png', 'jpeg', 'gif'],
        },
      },
    }),
    [t, token, user?.avatar]
  )
  const guardianTeacherInitialValues = useMemo(
    () => ({
      phone: user?.phone ?? '',
      address: {
        address: user?.display_address ?? '',
      },
      avatar: '', // keep empty
    }),
    [user?.display_address, user?.phone]
  )

  const studentFields = useMemo(
    () => ({
      school: {
        label: t('signUpGuardianAddChild_school_label'),
      },
      level: {
        label: t('signUpGuardianAddChild_select_level'),
        select: true,
        selectOptions: grades.map((grade) => ({ label: grade.name, value: grade.id })),
      },
      avatar: {
        label: '',
        image: {
          alt: 'LiberteClass - Upload',
          src: user?.avatar ?? '/static/assets/images/upload.png',
        },
        config: {
          target: api.UPLOAD_ENDPOINT,
          headers: { Authorization: `Bearer ${token}` },
          fileType: ['jpg', 'png', 'jpeg', 'gif'],
        },
      },
    }),
    [grades, user?.avatar, t, token]
  )

  const studentInitialValues = useMemo(
    () => ({
      school: user?.student?.school ?? '',
      level: user?.student?.grade?.id ?? '',
      avatar: '', // keep empty
    }),
    [user?.student?.grade?.id, user?.student?.school]
  )

  const fieldsProps = useMemo(
    () => (user?.user_type === UserType.Student ? studentFields : guardianTeacherFields),
    [guardianTeacherFields, studentFields, user?.user_type]
  )

  const initialValues = useMemo(
    () =>
      user?.user_type === UserType.Student ? studentInitialValues : guardianTeacherInitialValues,
    [guardianTeacherInitialValues, studentInitialValues, user?.user_type]
  )

  const signupProps: SignUpPersonalInfosProps = useMemo(() => {
    return {
      title: t('signUpPersonalInfos_title'),
      legends:
        user?.user_type === UserType.Student
          ? [
              t('signUpPersonalInfos_legends_student_0'),
              t('signUp_legends_1'),
              <span
                key={2}
                dangerouslySetInnerHTML={{ __html: t('signUp_legends_2', { url: urlPrivacy }) }}
              />,
            ]
          : user?.user_type === UserType.Guardian
          ? [
              t('signUpPersonalInfos_legends_guardian_0'),
              t('signUpPersonalInfos_legends_guardian_1'),
              t('signUp_legends_1'),
            ]
          : [
              t('signUpPersonalInfos_legends_guardian_0'),
              t('signUpPersonalInfos_legends_guardian_1'),
              t('signUp_legends_1'),
              <span
                key={2}
                dangerouslySetInnerHTML={{ __html: t('signUp_legends_2', { url: urlPrivacy }) }}
              />,
            ],
      /*...(sponsorName && {
        sponsorText: t('signUpPersonalInfos_sponsor'),
        sponsorName,
      }),*/
      step: t('signUpPersonalInfos_step'),
      fieldsProps,
      initialValues,
      errorTexts: {
        address: t('error_address'),
        phone: t('error_phone'),
        required: t('error_required'),
      },
      avatarTitle: t('signUpPersonalInfos_avatar_title'),
      avatarText:
        user?.user_type === UserType.Teacher
          ? t('signUpPersonalInfos_avatar_teacher_text')
          : t('signUpPersonalInfos_avatar_student_text'),
      mandatoryText: t('signUp_required_text'),
      submitButton: {
        text: t('signUpPersonalInfos_submit'),
        pendingText: t('signUpPersonalInfos_submit_pending'),
        isPending: submit.pending,
      },
      submitErrors: submit.errors,
      userType:
        user?.user_type === UserType.Teacher
          ? 'teacher'
          : user?.user_type === UserType.Guardian
          ? 'parent'
          : 'student',
      onSubmit: (values) => handleSubmit(values),
    }
  }, [
    t,
    fieldsProps,
    initialValues,
    submit.pending,
    submit.errors,
    user?.user_type,
    handleSubmit,
    urlPrivacy,
  ])

  const imageProps = {
    alt: 'LiberteClass - Inscription',
    src: '/static/assets/images/signup.png',
  }

  return (
    <SignUpPersonalInfosTemplate
      headerProps={headerProps}
      signupProps={signupProps}
      imageProps={imageProps}
    />
  )
}

export default SignUpPersonalInfosPage
