import React, { FC } from 'react'

import { ImageTextItemProps } from '../ImageTextItem'
import { LinkProps } from '../Link'

import * as SC from './styled'

export type SearchSubjectProps = {
  className?: string
  requestForLabel?: string
  requestFor?: ImageTextItemProps
  requestSubjectLabel: string
  requestSubject: { title: string; link?: LinkProps }[]
}

const SearchSubject: FC<SearchSubjectProps> = ({
  className,
  requestForLabel,
  requestFor,
  requestSubjectLabel,
  requestSubject,
}) => {
  return (
    <SC.SearchSubjectForm className={className}>
      <SC.Wrapper disableGutters>
        {requestForLabel && <SC.RequestForLabel>{requestForLabel}</SC.RequestForLabel>}
        {requestFor && <SC.RequestFor {...requestFor} />}
        <SC.Field>
          <SC.RequestSubjectLabel>{requestSubjectLabel}</SC.RequestSubjectLabel>
          {requestSubject.map((subject, i) => (
            <SC.Subject {...subject?.link} key={i}>
              <SC.SubjectWrapper>
                <SC.SubjectTitle>{subject.title}</SC.SubjectTitle>
              </SC.SubjectWrapper>
            </SC.Subject>
          ))}
        </SC.Field>
      </SC.Wrapper>
    </SC.SearchSubjectForm>
  )
}

export default SearchSubject
