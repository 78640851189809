import gql from 'graphql-tag'

import GuardianFragment from '../../../fragments/GuardianFragment'
import { User } from '../../../../generated/graphql'

const updateFamilyMember = {
  mutation: gql`
    mutation updateFamilyMember($student: ID!, $member: FamilyMemberInput!) {
      updateFamilyMember(student: $student, member: $member) {
        guardian {
          ...GuardianFragment
        }
      }
    }
    ${GuardianFragment}
  `,
  transformer: (response: any): User => {
    return response.updateFamilyMember
  },
}

export default updateFamilyMember
