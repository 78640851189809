import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { REHYDRATE } from 'redux-persist'

import i18n from '../../i18n'
import type { RootState } from '../store'
import {
  getPaginatedServiceReducers,
  getServiceProps,
  getServiceReducers,
  ServiceProps,
} from '../../helpers/ReduxHelpers'
import {
  AvailabilitySlot,
  ClassMarketSlot,
  Course,
  CourseType,
  Discipline,
  Lesson,
  MutationCreateLessonArgs,
  MutationPickCourseOfMarketArgs,
  PublicTeacher,
  QueryTeacherByIdArgs,
  Student,
  ThemesQueryVariables,
} from '../../generated/graphql'
import { courseTypeSlug } from '../../graphql/enums/CourseType'
import { MarketCourseFormValues } from '../../components/MarketCourseForm'
import { SearchCourseResult } from '../../graphql/services/course/queries/searchCourse'
import { StudentDisabledDatesResult } from '../../graphql/services/course/queries/studentDisabledDates'

import { actionTypes } from './types'
import {
  ApplyToCoursePayloadProps,
  CreateCoursePayloadProps,
  SearchMarketSlotPayload,
  SearchPayloadProps,
  TeacherClassRequestsData,
} from './types/actions'
import { SearchSteps } from './types/state'

export type SearchState = {
  step: SearchSteps | null
  type?: CourseType | null
  student?: Student | null
  discipline?: Discipline | null
  lesson?: Lesson | null
  size?: string | null
  availabilities?: {
    date: string
    slots: AvailabilitySlot[]
  }[]
  price?: string | null
  // services
  search: ServiceProps<SearchCourseResult, SearchPayloadProps>
  searchMarket: ServiceProps<ClassMarketSlot[], MarketCourseFormValues>
  searchMarketSlot: ServiceProps<SearchCourseResult, SearchMarketSlotPayload>
  acceptMarketCourse: ServiceProps<Course, MutationPickCourseOfMarketArgs>
  courseRequest: ServiceProps<any, SearchPayloadProps>
  applyToCourse: ServiceProps<any, ApplyToCoursePayloadProps>
  createCourse: ServiceProps<any, CreateCoursePayloadProps>
  themes: ServiceProps<any, ThemesQueryVariables>
  teacherClassRequests: ServiceProps<TeacherClassRequestsData, any>
  teacherAcceptCourse: ServiceProps<any, any>
  teacherDeclineCourse: ServiceProps<any, any>
  studentDisabledDates: ServiceProps<StudentDisabledDatesResult, { student?: string }>
  createLesson: ServiceProps<Lesson, MutationCreateLessonArgs>
  teacherById: ServiceProps<PublicTeacher, QueryTeacherByIdArgs>
}

//
// Initial state
//

const initialState: SearchState = {
  step: null,
  type: null,
  student: null,
  discipline: null,
  lesson: null,
  size: '4',
  availabilities: [],
  price: null,
  // Services
  search: getServiceProps(),
  searchMarket: getServiceProps(),
  searchMarketSlot: getServiceProps(),
  acceptMarketCourse: getServiceProps(),
  courseRequest: getServiceProps(),
  applyToCourse: getServiceProps(),
  createCourse: getServiceProps(),
  themes: getServiceProps(),
  teacherClassRequests: getServiceProps(),
  teacherAcceptCourse: getServiceProps(),
  teacherDeclineCourse: getServiceProps(),
  studentDisabledDates: getServiceProps(),
  createLesson: getServiceProps(),
  teacherById: getServiceProps(),
}

//
// Slice (Actions & Reducers)
//

const slice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    saveStep: (state, action: actionTypes.saveStep) => {
      const { payload } = action
      state.step = payload.step
      if (payload?.type !== undefined) state.type = payload?.type
      if (payload?.student !== undefined) state.student = payload?.student
      if (payload?.discipline !== undefined) state.discipline = payload?.discipline
      if (payload?.lesson !== undefined) state.lesson = payload?.lesson
      if (payload?.price !== undefined) state.price = payload?.price
      if (payload?.availabilities !== undefined) state.availabilities = payload?.availabilities
      if (payload?.size !== undefined) state.size = payload?.size
    },
    clearSearch: (state) => {
      state.step = null
      state.type = null
      state.student = null
      state.discipline = null
      state.lesson = null
      state.size = '4'
      state.availabilities = []
      state.price = null
    },
    reset: () => initialState,
    // Services
    ...getPaginatedServiceReducers('search'),
    ...getServiceReducers('searchMarket'),
    ...getServiceReducers('searchMarketSlot'),
    ...getServiceReducers('acceptMarketCourse'),
    ...getServiceReducers('courseRequest'),
    ...getServiceReducers('applyToCourse'),
    ...getServiceReducers('createCourse'),
    ...getServiceReducers('themes'),
    ...getServiceReducers('teacherClassRequests'),
    ...getServiceReducers('teacherAcceptCourse'),
    ...getServiceReducers('teacherDeclineCourse'),
    ...getServiceReducers('studentDisabledDates'),
    ...getServiceReducers('createLesson'),
    ...getServiceReducers('teacherById'),
  },
  extraReducers: {
    [REHYDRATE]: (state, action: PayloadAction<any>) => {
      return {
        ...initialState,
        ...action.payload?.search,
      }
    },
  },
})

export const { reducer, actions } = slice

//
// Selectors
//

const root = (state: RootState) => state[slice.name]
const step = (state: RootState) => root(state).step
const type = (state: RootState) => root(state).type
const student = (state: RootState) => root(state).student
const discipline = (state: RootState) => root(state).discipline
const lesson = (state: RootState) => root(state).lesson
const price = (state: RootState) => root(state).price
const size = (state: RootState) => root(state).size
const availabilities = (state: RootState) => root(state).availabilities
const searchParams = (state: RootState) => ({
  step: step(state),
  type: type(state),
  student: student(state),
  discipline: discipline(state),
  lesson: lesson(state),
  price: price(state),
  size: size(state),
  availabilities: availabilities(state),
})

const urlParams = createSelector(
  [type, student, discipline, lesson, price, size],
  (t, s, d, l, p, si): any => {
    return {
      type: courseTypeSlug((f: string) => i18n.t(f), t ?? CourseType.OneOff),
      ...(s && { child: s?.id }),
      discipline: d?.slug,
      theme: l?.slug,
      price: p,
      size: si,
    }
  }
)

// services
const search = (state: RootState) => root(state).search
const searchMarket = (state: RootState) => root(state).searchMarket
const searchMarketSlot = (state: RootState) => root(state).searchMarketSlot
const acceptMarketCourse = (state: RootState) => root(state).acceptMarketCourse
const courseRequest = (state: RootState) => root(state).courseRequest
const applyToCourse = (state: RootState) => root(state).applyToCourse
const createCourse = (state: RootState) => root(state).createCourse
const themes = (state: RootState) => root(state).themes
const teacherClassRequests = (state: RootState) => root(state).teacherClassRequests
const teacherAcceptCourse = (state: RootState) => root(state).teacherAcceptCourse
const teacherDeclineCourse = (state: RootState) => root(state).teacherDeclineCourse
const studentDisabledDates = (state: RootState) => root(state).studentDisabledDates
const createLesson = (state: RootState) => root(state).createLesson
const teacherById = (state: RootState) => root(state).teacherById

export const selectors = {
  step,
  type,
  student,
  discipline,
  lesson,
  price,
  size,
  availabilities,
  searchParams,
  urlParams,

  // services
  search,
  searchMarket,
  searchMarketSlot,
  acceptMarketCourse,
  courseRequest,
  applyToCourse,
  createCourse,
  themes,
  teacherClassRequests,
  teacherAcceptCourse,
  teacherDeclineCourse,
  studentDisabledDates,
  createLesson,
  teacherById,
}
