import React, { FC, useCallback, useMemo } from 'react'
import { Form, Formik, FormikHelpers, FormikProps } from 'formik'
import * as Yup from 'yup'

import { getValidationSchema, renderField } from '../../helpers/FormHelpers'
import { FormFieldConfig, FormFieldProps } from '../../types/form'
import { FormSubmitProps } from '../FormSubmit'
import FormSubmitErrors, { FormSubmitErrorsProps } from '../FormSubmitErrors'
import FormScrollToError from '../FormScrollToError'

import * as SC from './styled'

export enum SignUpAttachParentType {
  CODE = 'code',
  EMAIL = 'email',
}

export type SignUpAttachParentFormValues = {
  type?: SignUpAttachParentType
  code?: string
  mail?: string
}

export type SignUpAttachParentProps = {
  className?: string
  step?: string
  title?: string
  messageTitle?: string
  messageText?: string
  text?: string
  fieldsProps?: {
    code?: FormFieldProps
    mail?: FormFieldProps
  }
  errorTexts?: {
    required: string
    email: string
    code: string
  }
  codeTabLabel: string
  mailTabLabel: string
  initialValues: SignUpAttachParentFormValues
  submitErrors?: FormSubmitErrorsProps['errors']
  skipButton: FormSubmitProps
  submitButton: FormSubmitProps
  onSubmit?: (
    values: SignUpAttachParentFormValues,
    formikHelpers: FormikHelpers<SignUpAttachParentFormValues>
  ) => void
}

const SignUpAttachParent: FC<SignUpAttachParentProps> = (props) => {
  const {
    className,
    title,
    messageTitle,
    messageText,
    text,
    step,
    fieldsProps,
    errorTexts,
    initialValues,
    submitErrors,
    skipButton,
    submitButton,
    codeTabLabel,
    mailTabLabel,
    onSubmit = (_values, _formikHelpers) => null,
  } = props

  const codeField = useMemo(
    () => ({
      name: 'code',
      Component: SC.TextField,
      validation: Yup.string().when('type', {
        is: SignUpAttachParentType.CODE,
        then: Yup.string()
          .required(errorTexts?.required)
          .min(8, errorTexts?.code)
          .max(8, errorTexts?.code),
      }),
      required: false,
    }),
    [errorTexts]
  )

  const mailField = useMemo(
    () => ({
      name: 'mail',
      Component: SC.TextField,
      validation: Yup.string().when('type', {
        is: SignUpAttachParentType.EMAIL,
        then: Yup.string().required(errorTexts?.required).email(errorTexts?.email),
      }),
      required: false,
    }),
    [errorTexts]
  )

  const fields: FormFieldConfig[] = [
    {
      name: 'type',
      Component: undefined,
      validation: Yup.string(),
      required: false,
    },
    codeField,
    mailField,
  ]

  const validationSchema = getValidationSchema(fields)

  const renderTabs = useCallback(
    (formikProps: FormikProps<SignUpAttachParentFormValues>) => {
      const tabs = [
        {
          label: codeTabLabel,
          value: SignUpAttachParentType.CODE,
          children: renderField(codeField, formikProps, fieldsProps, 0),
        },
        {
          label: mailTabLabel,
          value: SignUpAttachParentType.EMAIL,
          children: renderField(mailField, formikProps, fieldsProps, 1),
        },
      ]

      return (
        <SC.Tab
          name="type"
          tabs={tabs}
          value={formikProps.values?.type ?? SignUpAttachParentType.CODE}
          onChange={formikProps.setFieldValue}
        />
      )
    },
    [fieldsProps, mailField, codeField, codeTabLabel, mailTabLabel]
  )

  return (
    <SC.Attach className={className}>
      {step && <SC.Step>{step}</SC.Step>}
      {title && <SC.Title>{title}</SC.Title>}
      {messageTitle && messageText && (
        <SC.MessageBox>
          <SC.MessageTitle>{messageTitle}</SC.MessageTitle>
          <SC.MessageText>{messageText}</SC.MessageText>
        </SC.MessageBox>
      )}
      {text && <SC.Text>{text}</SC.Text>}
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnChange={true}
        validateOnBlur={true}
        onSubmit={onSubmit}
      >
        {(formikProps) => (
          <Form noValidate>
            <FormScrollToError formikProps={formikProps} />
            <SC.Fields>{renderTabs(formikProps)}</SC.Fields>
            <FormSubmitErrors errors={submitErrors} />
            <SC.Buttons>
              <SC.SkipButton {...skipButton} type={'button'} autoIcon outlined />
              <SC.SubmitButton {...submitButton} autoIcon />
            </SC.Buttons>
          </Form>
        )}
      </Formik>
    </SC.Attach>
  )
}

export default SignUpAttachParent
