import styled from 'styled-components'

import PageTitle from '../../components/PageTitle'
import PageBackButton from '../../components/PageBackButton'
import TabContent from '../../components/TabContent'

export const Content = styled.div`
  width: 100%;
`

export const Title = styled(PageTitle)``
export const BackButton = styled(PageBackButton)``

export const MyTabContent = styled(TabContent)`
  padding: 0rem 1.6rem;
`
