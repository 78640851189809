import styled from 'styled-components'

import ActionButton from '../ActionButton'
import FinishedCourseMeta from '../FinishedCourseMeta'
import PageTitle from '../PageTitle'

export const CallEnded = styled.div`
  position: relative;
  overflow: hidden;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const Wrapper = styled.div`
  z-index: 5;
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`

export const Action = styled(ActionButton)`
  width: calc(100% - 3.2rem);
  margin: 0 1.6rem;
  ${(props) => props.theme.breakpoints.up('md')} {
    max-width: 52rem;
  }
`

export const Title = styled(PageTitle)`
  text-align: center;
  width: calc(100% - 3.2rem);
  margin: 0 1.6rem;
  ${(props) => props.theme.breakpoints.up('md')} {
    max-width: 52rem;
  }
`

export const Text = styled.p`
  ${(props) => ({ ...props.theme.typography.smallRegular })}
  color: ${(props) => props.theme.palette.colors.midnightBlue};
  width: calc(100% - 3.2rem);
  margin: 1.6rem;
  text-align: center;
  ${(props) => props.theme.breakpoints.up('md')} {
    max-width: 52rem;
  }
`

export const CourseMeta = styled(FinishedCourseMeta)`
  width: calc(100% - 3.2rem);
  margin: 0 1.6rem;
`
