import { Form, Formik, FormikHelpers } from 'formik'
import React, { FC, useCallback, useState } from 'react'
import * as Yup from 'yup'

import { ActionButtonProps } from '../ActionButton'
import { TextFieldProps } from '../form/TextField'
import { ToggleButtonGroupProps } from '../form/ToggleButtonGroup'
import FormScrollToError from '../FormScrollToError'
import { FormSubmitProps } from '../FormSubmit'
import FormSubmitErrors, { FormSubmitErrorsProps } from '../FormSubmitErrors'
import { RatingCustomProps } from '../RatingCustom'
import { SubjectWithThemeCardProps } from '../SubjectWithThemeCard'
import { FormFieldProps } from '../../types/form'
import { getValidationSchema, renderField } from '../../helpers/FormHelpers'
import { Icons } from '../Icon/types'
import { light } from '../../theme/palette'

import * as SC from './styled'

export enum SubmitType {
  SUBMIT = 'SUBMIT',
  AVAILABILITIES = 'AVAILABILITIES',
}

export type CourseFormValues = {
  size: string
  price: string
}

export type CourseFormProps = {
  className?: string
  themeCard: SubjectWithThemeCardProps
  forWhoField?: ToggleButtonGroupProps
  groupField?: TextFieldProps
  ratingLabel?: string
  ratingField?: RatingCustomProps
  priceLabel: string
  priceRecommandationLabel: string
  priceRecommandation: string
  fieldsProps?: {
    size: FormFieldProps
    price: FormFieldProps
  }
  errorTexts?: {
    required: string
  }
  defineDisponibilities?: ActionButtonProps
  mandatoryText?: string
  initialValues: CourseFormValues
  submitButton: FormSubmitProps
  submitErrors?: FormSubmitErrorsProps['errors']
  onSubmit: (values: CourseFormValues, type: SubmitType) => void
}

const CourseForm: FC<CourseFormProps> = (props) => {
  const {
    className,
    themeCard,
    forWhoField,
    groupField,
    ratingLabel,
    ratingField,
    priceLabel,
    priceRecommandationLabel,
    priceRecommandation,
    defineDisponibilities,
    mandatoryText,
    initialValues,
    submitButton,
    submitErrors,
    onSubmit,
    fieldsProps,
    errorTexts,
  } = props

  const [submitType, setSubmitType] = useState(SubmitType.SUBMIT)

  const handleSubmit = useCallback(
    (values: CourseFormValues, _helpers: FormikHelpers<CourseFormValues>) => {
      onSubmit?.(values, submitType)
    },
    [onSubmit, submitType]
  )

  const quantityField = {
    name: 'size',
    Component: SC.QuantityField,
    validation: Yup.string().required(errorTexts?.required),
    required: true,
    isRadioMode: true,
  }

  const priceField = {
    name: 'price',
    Component: SC.PriceField,
    validation: Yup.string().required(errorTexts?.required),
    required: true,
    icon: Icons.euro,
    iconColor: light.colors.cornflowerBlue,
  }

  const validationSchema = getValidationSchema([quantityField, priceField])

  return (
    <SC.ThemePicker className={className}>
      <SC.Wrapper maxWidth="xl" disableGutters>
        <SC.ThemeCard {...themeCard} />
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnChange={true}
          validateOnBlur={true}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {(formikProps) => (
            <Form noValidate>
              <FormScrollToError formikProps={formikProps} />
              {forWhoField && <SC.ForWhoField {...forWhoField} />}
              {groupField && <SC.GroupField {...groupField} />}
              {ratingLabel && <SC.RatingLabel>{ratingLabel}</SC.RatingLabel>}
              {ratingField && <SC.RatingField {...ratingField} />}
              {renderField(quantityField, formikProps, fieldsProps, 0)}
              <SC.PriceLabel>{priceLabel}</SC.PriceLabel>
              <SC.PriceRecommandationBox>
                <SC.PriceRecommandationLabel>
                  {priceRecommandationLabel}
                </SC.PriceRecommandationLabel>
                <SC.PriceRecommandation>{priceRecommandation}</SC.PriceRecommandation>
              </SC.PriceRecommandationBox>
              {renderField(priceField, formikProps, fieldsProps, 1)}
              {mandatoryText && <SC.MandatoryText>{mandatoryText}</SC.MandatoryText>}
              <SC.HiddenSubmitButton text={''} onClick={() => setSubmitType(SubmitType.SUBMIT)} />
              {defineDisponibilities && (
                <SC.DefineDisponibilities
                  {...defineDisponibilities}
                  outlined
                  onClick={() => setSubmitType(SubmitType.AVAILABILITIES)}
                />
              )}
              <FormSubmitErrors errors={submitErrors} />
              <SC.SubmitButton
                {...submitButton}
                autoIcon
                onClick={() => setSubmitType(SubmitType.SUBMIT)}
              />
            </Form>
          )}
        </Formik>
      </SC.Wrapper>
    </SC.ThemePicker>
  )
}

export default CourseForm
