import React, { FC } from 'react'

import { Icons } from '../Icon/types'

import * as SC from './styled'

export type CourseStatusProps = {
  className?: string
  backgroundColor?: string
  numberOfRegistration?: number
  registrationText?: string
  icon?: Icons
  statusText?: string
  statusColor?: string
  bottomBadgeContent?: string
  bottomBadgeLabel?: string
  price?: string
}

const CourseStatus: FC<CourseStatusProps> = (props) => {
  const {
    className,
    backgroundColor,
    numberOfRegistration,
    registrationText,
    icon,
    statusText,
    statusColor,
    bottomBadgeContent,
    bottomBadgeLabel,
    price,
  } = props

  return (
    <SC.CourseStatus className={className}>
      <SC.Wrapper backgroundColor={backgroundColor}>
        {numberOfRegistration && registrationText && (
          <SC.RegistrationWrapper>
            <SC.RegistrationNumber>{numberOfRegistration}</SC.RegistrationNumber>
            <SC.RegistrationText>{registrationText}</SC.RegistrationText>
          </SC.RegistrationWrapper>
        )}
        {bottomBadgeContent && bottomBadgeLabel && (
          <SC.BottomBadge>
            <SC.BottomBadgeContent>{bottomBadgeContent}</SC.BottomBadgeContent>
            <SC.BottomBadgeLabel>{bottomBadgeLabel}</SC.BottomBadgeLabel>
          </SC.BottomBadge>
        )}
        <SC.StatusWrapper>
          {icon && <SC.Icon icon={icon} color={statusColor} />}
          {statusText && <SC.StatusText $color={statusColor}>{statusText}</SC.StatusText>}
        </SC.StatusWrapper>
        {price && <SC.Price>{price}</SC.Price>}
      </SC.Wrapper>
    </SC.CourseStatus>
  )
}

export default CourseStatus
