import React, { useEffect, useState } from 'react'
import { FormikProps } from 'formik'

export type FormScrollToErrorProps = {
  formikProps: FormikProps<any>
}

const FormScrollToError: React.FC<FormScrollToErrorProps> = ({ formikProps }) => {
  const [submittingState, setSubmittingState] = useState(false)

  useEffect(() => {
    if (submittingState && !formikProps.isSubmitting && !formikProps.isValid) {
      const first = Object.keys(formikProps.errors)?.[0]
      const firstTag = document.getElementsByName(first)?.[0]

      if (firstTag && typeof firstTag.focus === 'function' && firstTag.nodeName !== 'DIV') {
        firstTag.focus()
      } else if (firstTag) {
        firstTag.scrollIntoView({ behavior: 'smooth' })
      } else {
        window.scrollTo({ top: 0, behavior: 'smooth' })
      }
    }

    setSubmittingState(formikProps.isSubmitting)
  }, [formikProps.errors, formikProps.isSubmitting, formikProps.isValid, submittingState])

  return null
}

export default FormScrollToError
