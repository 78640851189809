import gql from 'graphql-tag'

import { Course } from '../../../../generated/graphql'
import CourseFragment from '../../../fragments/CourseFragment'

const acceptCourse = {
  mutation: gql`
    mutation acceptCourse($course: ID!) {
      acceptCourse(course: $course) {
        ...CourseFragment
      }
    }
    ${CourseFragment}
  `,
  transformer: (response: any): Course => {
    return response.acceptCourse
  },
}

export default acceptCourse
