import styled from 'styled-components'

import TextFieldC from '../form/TextField'
import AddressC from '../form/Address'
import Icon from '../Icon'
import BasicTextAndImage from '../BasicTextAndImage'
import UploadImageC from '../form/UploadImage'
import FormSubmit from '../FormSubmit'

export const PersonalInfos = styled.div`
  padding: 0 1.6rem;
`

export const ParentIntro = styled(BasicTextAndImage)`
  margin-bottom: 1rem;
`

export const Sponsor = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${(props) => props.theme.palette.colors.pureWhite};
  padding: 0.5rem;
  border-radius: 0.4rem;
  ${(props) => props.theme.breakpoints.up('md')} {
    max-width: 26rem;
  }
`

export const SponsorIcon = styled(Icon)`
  width: 1.8rem;
  height: 1.8rem;
  margin: 0 0.5rem 0 0;
`

export const SponsorText = styled.p`
  ${(props) => ({ ...props.theme.typography.h6 })}
  color: ${(props) => props.theme.palette.colors.cornflowerBlue};
  margin: 0 0.5rem 0 0;
`

export const SponsorName = styled.p`
  ${(props) => ({ ...props.theme.typography.smallRegular })}
  color: ${(props) => props.theme.palette.colors.chambray};
  margin: 0;
`

export const Step = styled.p`
  ${(props) => ({ ...props.theme.typography.h6 })}
  color: ${(props) => props.theme.palette.colors.neonCarrot};
  text-transform: uppercase;
`

export const Title = styled.h1`
  ${(props) => ({ ...props.theme.typography.h1 })}
  color: ${(props) => props.theme.palette.colors.midnightBlue};
  font-size: 3.2rem;
  margin: 0 0 2rem 0;
  ${(props) => props.theme.breakpoints.up('md')} {
    font-size: 4rem;
  }
`

export const Text = styled.p`
  ${(props) => ({ ...props.theme.typography.h6 })}
  color: ${(props) => props.theme.palette.colors.midnightBlue};
  margin: 0 0 1rem 0;
`

export const Fields = styled.div`
  margin-top: 2rem;
`

export const TextField = styled(TextFieldC)`
  margin-bottom: 1rem;
  width: 100%;
  margin-right: 1rem;
`

export const Address = styled(AddressC)`
  margin-bottom: 1rem;
  width: 100%;
  margin-right: 1rem;
`

export const Avatar = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
`

export const AvatarTitle = styled.p`
  ${(props) => ({ ...props.theme.typography.h5 })}
  color: ${(props) => props.theme.palette.colors.freeSpeechBlue};
  margin: 1.4rem 0 1rem 0;
`

export const AvatarText = styled.p`
  ${(props) => ({ ...props.theme.typography.smallRegular })}
  color: ${(props) => props.theme.palette.colors.chambray};
  margin: 0 0 1rem 0;
`

export const UploadImage = styled(UploadImageC)``

export const SubmitButton = styled(FormSubmit)`
  margin-top: 2rem;
`

export const MandatoryText = styled.p`
  ${(props) => ({ ...props.theme.typography.smallRegular })}
  color: ${(props) => props.theme.palette.colors.grey};
  margin: 1rem 0;
`
