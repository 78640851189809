import React, { FC } from 'react'

import { ImageTextItemProps } from '../ImageTextItem'
import { ImageTextListProps } from '../ImageTextList'
import { RatingWithFieldsProps } from '../RatingWithFields'

import * as SC from './styled'

export type FinishedCourseMetaDescriptionProps = {
  title: string
  text: string
}

export type FinishedCourseMetaProps = {
  className?: string
  title: string
  subTitle: string
  person?: ImageTextItemProps
  subject: string
  description?: FinishedCourseMetaDescriptionProps[]
  itemsTitle: string
  items?: ImageTextListProps['items']
  ratings?: RatingWithFieldsProps[]
}

const FinishedCourseMeta: FC<FinishedCourseMetaProps> = (props) => {
  const {
    className = '',
    title,
    subTitle,
    person,
    subject,
    description,
    itemsTitle,
    items,
    ratings,
  } = props
  return (
    <SC.FinishedCourseMeta className={className}>
      <SC.Title>{title}</SC.Title>
      <SC.Subtitle>{subTitle}</SC.Subtitle>
      {person && <SC.Person {...person} />}
      <SC.Subject>{subject}</SC.Subject>
      {description && (
        <SC.Description>
          {description?.map((descriptionEntry, key) => (
            <React.Fragment key={key}>
              <h3>{descriptionEntry.title}</h3>
              <p>{descriptionEntry.text}</p>
            </React.Fragment>
          ))}
        </SC.Description>
      )}
      {items && (
        <>
          <SC.ItemsTitle>{itemsTitle}</SC.ItemsTitle>
          <SC.Items items={items} />
        </>
      )}
      {ratings && (
        <>
          <SC.Muidivider />
          {ratings.map((rating, index) => {
            return <SC.RatingWithFields {...rating} key={index} />
          })}
        </>
      )}
    </SC.FinishedCourseMeta>
  )
}

export default FinishedCourseMeta
