import styled from 'styled-components'

import PageTitle from '../../components/PageTitle'
import MarketCourseFormC from '../../components/MarketCourseForm'

export const Content = styled.div`
  width: 100%;
`

export const Title = styled(PageTitle)``

export const Text = styled.p`
  ${(props) => ({ ...props.theme.typography.tinySmall })}
  color: ${(props) => props.theme.palette.colors.midnightBlue};
  padding: 1.6rem 1.6rem 1.6rem 1.6rem;
  margin: 0;
  max-width: 47rem;
`

export const MarketCourseForm = styled(MarketCourseFormC)`
  margin: 0 1.6rem;
  max-width: 50rem;
`
