import styled from 'styled-components'

import CourseCard from '../CourseCard'
import ActionButton from '../ActionButton'
import BasicLink from '../BasicLink'

export const CourseCardInContext = styled.div``

export const Wrapper = styled.div``

export const Content = styled.div<{ backgroundColor?: string }>`
  display: flex;
  flex-direction: column;
  padding: 1rem 1.6rem 1.6rem;
  background-color: ${(props) => props.backgroundColor};
  ${(props) => props.theme.breakpoints.up('md')} {
    width: 100%;
    width: max-content;
  }
`

export const Title = styled.h4<{ backgroundColor?: string }>`
  ${(props) => ({ ...props.theme.typography.h3 })}
  color: ${(props) =>
    props.backgroundColor
      ? props.theme.palette.colors.pureWhite
      : props.theme.palette.colors.midnightBlue};
  margin: 1rem 0 1rem 0;
`

export const Text = styled.p`
  margin: 0 0 1rem 0;
  ${(props) => ({ ...props.theme.typography.smallRegular })}
  color: ${(props) => props.theme.palette.colors.chambray};
  ${(props) => props.theme.breakpoints.up('md')} {
    max-width: 60rem;
  }
`
export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${(props) => props.theme.breakpoints.up('md')} {
    flex-direction: row;
    align-items: flex-start;
  }
`

export const List = styled.div`
  display: flex;
  flex-direction: column;
  ${(props) => props.theme.breakpoints.up('md')} {
    margin: 0 1rem 0 0;
  }
`

export const Cards = styled.div`
  display: flex;
  flex-direction: column;
  ${(props) => props.theme.breakpoints.up('md')} {
    display: grid;
    grid-template-columns: repeat(auto-fit, max(40rem));
    gap: 0.8rem;
    & > a {
      width: 40rem;
      margin-bottom: 0;
    }
  }
`

export const Card = styled(CourseCard)`
  margin-bottom: 1rem;
`

export const VirtualClassButton = styled(ActionButton)`
  margin: 1rem 0 2.2rem 0;
  ${(props) => props.theme.breakpoints.up('md')} {
    width: 40rem;
  }
`

export const PlanningCta = styled(BasicLink)`
  margin-top: 1rem;
  display: flex;
  ${(props) => ({ ...props.theme.typography.callToAction })}
  color: ${(props) => props.theme.palette.colors.freeSpeechBlue};
  text-decoration: none;
  cursor: pointer;
`

export const HistoryCta = styled(PlanningCta)``
