import gql from 'graphql-tag'

import { TeacherAvailability } from '../../../../generated/graphql'
import TeacherAvailabilityFragment from '../../../fragments/TeacherAvailabilityFragment'
import { TeacherAvailabilityDay } from '../../../types/TeacherAvailabilityDay'
import { teacherAvailabilityTransform } from '../transformers/TeacherAvailabilityTransform'

const teacherAvailabilitiesOfMonth = {
  query: gql`
    query teacherAvailabilitiesOfMonth($month: Int!, $year: Int!) {
      teacherAvailabilitiesOfMonth(month: $month, year: $year) {
        ...TeacherAvailabilityFragment
      }
    }
    ${TeacherAvailabilityFragment}
  `,
  transformer: (response: {
    teacherAvailabilitiesOfMonth: TeacherAvailability[]
  }): TeacherAvailabilityDay[] => {
    return teacherAvailabilityTransform(response.teacherAvailabilitiesOfMonth)
  },
}

export default teacherAvailabilitiesOfMonth
