import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { HeaderProps } from '../../components/Header'
import useHeader from '../../hooks/useHeader'
import { actions, selectors } from '../../redux'
import { resolveRegisterStepURL } from '../../helpers/RegisterHelpers'
import { useFormSubmit } from '../../hooks/useFormSubmit'
import SignUpStudentAttachToParent from '../../templates/SignUpStudentAttachToParent'
import {
  SignUpAttachParentProps,
  SignUpAttachParentType,
} from '../../components/SignUpAttachParent'

const SignUpChildAddGuardianPage: React.FC = (_props) => {
  const headerProps: HeaderProps = useHeader()
  const { t } = useTranslation()
  const user = useSelector(selectors.auth.user)
  const history = useHistory()

  const onComplete = useCallback(() => {
    history.push(resolveRegisterStepURL(user))
  }, [user, history])

  const [submit, handleSubmit] = useFormSubmit(
    selectors.auth.signUpChildAddGuardian,
    actions.auth.signUpChildAddGuardianRequest,
    actions.auth.signUpChildAddGuardianReset,
    onComplete
  )

  const skip = useCallback(() => handleSubmit({}), [handleSubmit])

  const signupProps: SignUpAttachParentProps = {
    step: t('signUpChildAddGuardian_step'),
    title: t('signUpAttachParent_title'),
    messageTitle: t('signUpAttachParent_messageTitle'),
    messageText: t('signUpAttachParent_messageText'),
    text: t('signUpAttachParent_text'),
    codeTabLabel: t('signUpAttachParent_tabs_code'),
    mailTabLabel: t('signUpAttachParent_tabs_email'),
    fieldsProps: {
      code: {
        label: t('signUpAttachParent_fields_code_label'),
        isLabelExternal: true,
        required: false,
      },
      mail: {
        label: t('signUpAttachParent_fields_email_label'),
        isLabelExternal: true,
        required: false,
      },
    },
    initialValues: {
      type: SignUpAttachParentType.CODE,
      code: '',
      mail: '',
    },
    skipButton: {
      text: t('skip'),
      onClick: skip,
      isPending: !submit?.params?.type && submit?.pending,
    },
    submitButton: {
      text: t('next'),
      isPending: submit?.params?.type && submit?.pending,
    },
    errorTexts: {
      required: t('error_required'),
      email: t('error_email'),
      code: t('error_code'),
    },
    onSubmit: (values) => handleSubmit(values),
    submitErrors: submit.errors,
  }

  return <SignUpStudentAttachToParent headerProps={headerProps} signupProps={signupProps} />
}

export default SignUpChildAddGuardianPage
