import gql from 'graphql-tag'

import { ClassMarketSlot } from '../../../../generated/graphql'
import ClassMarketSlotFragment from '../../../fragments/ClassMarketSlotFragment'

const classMarketByDate = {
  query: gql`
    query classMarketByDate($type: CourseType!, $date: Date!, $minimum_price: Int!) {
      classMarketByDate(type: $type, date: $date, minimum_price: $minimum_price) {
        ...ClassMarketSlotFragment
      }
    }
    ${ClassMarketSlotFragment}
  `,
  transformer: (response: any): ClassMarketSlot[] => {
    return response.classMarketByDate
  },
}

export default classMarketByDate
