import React, { FC, memo, ReactNode, useCallback, useState } from 'react'

import Header, { HeaderProps } from '../../components/Header'
import Menu, { MenuProps } from '../../components/Menu'
import AuthLayout from '../../layouts/AuthLayout'
import { PageBackButtonProps } from '../../components/PageBackButton'
import { Icons } from '../../components/Icon/types'
import { CardListProps } from '../../components/CardList'
import { ActionButtonProps } from '../../components/ActionButton'
import { FormSubmitProps } from '../../components/FormSubmit'
import AutoRegisterModal, {
  AutoRegisterModalProps,
} from '../../components/modals/AutoRegisterModal'
import CourseDetailsModal, {
  CourseDetailsModalProps,
} from '../../components/modals/CourseDetailsModal'
import { NumberProps } from '../../components/form/Number'
import SimpleFeedbackModal, {
  SimpleFeedbackModalProps,
} from '../../components/modals/SimpleFeedbackModal'
import FormSubmitErrors, { FormSubmitErrorsProps } from '../../components/FormSubmitErrors'

import * as SC from './styled'

export type SearchResultsRequestFormValues = {
  delay: string
}

export type SearchResultsTemplateProps = {
  className?: string
  children?: ReactNode
  headerProps: HeaderProps
  menuProps: MenuProps
  backButton: PageBackButtonProps
  title: string
  subTitle: string
  text: string
  emptyText: string
  enlargeText?: string
  cards: CardListProps['cards']
  loadMoreProps?: FormSubmitProps
  editButtonProps?: ActionButtonProps
  autoRegisterAllowed?: boolean
  registerButtonProps?: FormSubmitProps
  registerText?: string
  delaySelectProps?: NumberProps
  initialState?: SearchResultsRequestFormValues
  onSubmit?: (values: SearchResultsRequestFormValues) => void
  newSearchButton?: ActionButtonProps
  requestModalProps?: AutoRegisterModalProps
  hasAvailabilities: boolean
  noAvailabilitiesText?: string
  noAvailabilitiesButton?: ActionButtonProps
  noAutoRegisterText?: string
  noAutoRegisterButton?: ActionButtonProps
  detailsModalProps: CourseDetailsModalProps
  feedbackModalProps?: SimpleFeedbackModalProps
  requestErrorModalProps?: SimpleFeedbackModalProps
  submitErrors?: FormSubmitErrorsProps['errors']
}

export const SearchResultsTemplate: FC<SearchResultsTemplateProps> = (props) => {
  const {
    headerProps,
    menuProps,
    title,
    backButton,
    subTitle,
    text,
    editButtonProps,
    emptyText,
    cards,
    enlargeText,
    registerButtonProps,
    registerText,
    delaySelectProps,
    initialState = { delay: '2' },
    onSubmit,
    newSearchButton,
    requestModalProps,
    hasAvailabilities,
    noAvailabilitiesText,
    noAvailabilitiesButton,
    detailsModalProps,
    autoRegisterAllowed,
    noAutoRegisterText,
    noAutoRegisterButton,
    feedbackModalProps,
    requestErrorModalProps,
    submitErrors,
    loadMoreProps,
  } = props

  const [state, setState] = useState(initialState)
  const handleRegister = useCallback(() => {
    onSubmit?.(state)
  }, [state, onSubmit])

  return (
    <AuthLayout header={<Header {...headerProps} withDivider />} menu={<Menu {...menuProps} />}>
      <SC.Content>
        <SC.BackButton {...backButton} />
        <SC.Subtitle>{title}</SC.Subtitle>
        {submitErrors && <FormSubmitErrors errors={submitErrors} />}
        {cards && cards?.length > 0 ? (
          <>
            <SC.Title>{subTitle}</SC.Title>
            <SC.Title2>{text}</SC.Title2>
            <SC.CardList cards={cards} buttonProps={loadMoreProps} />
            {enlargeText && <SC.Text5>{enlargeText}</SC.Text5>}
            {editButtonProps && (
              <SC.ButtonBox>
                <SC.Button {...editButtonProps} />
              </SC.ButtonBox>
            )}
            <CourseDetailsModal {...detailsModalProps} />
          </>
        ) : (
          <>
            <SC.EmptyText>{emptyText}</SC.EmptyText>
            {editButtonProps && (
              <SC.Cta icon={Icons.longArrowRight} iconSide={'right'} {...editButtonProps} />
            )}
            <SC.BoxImage>
              <SC.Image src={'/static/assets/images/noCourse.png'} alt={'Pas de cours'} />
            </SC.BoxImage>
            {hasAvailabilities ? (
              autoRegisterAllowed ? (
                <>
                  {registerText && <SC.Text6>{registerText}</SC.Text6>}
                  {delaySelectProps && (
                    <SC.Select
                      {...delaySelectProps}
                      isLabelExternal
                      inputProps={{
                        maxLength: 2,
                      }}
                      name={'delay'}
                      value={state?.delay}
                      onChange={(name: string, value: string) =>
                        setState((before) => ({ ...before, [name]: value }))
                      }
                    />
                  )}
                  <SC.ButtonBox>
                    {registerButtonProps && (
                      <SC.Submit {...registerButtonProps} onClick={handleRegister} />
                    )}
                    {newSearchButton && <SC.Button {...newSearchButton} outlined />}
                  </SC.ButtonBox>
                  {requestModalProps && <AutoRegisterModal {...requestModalProps} />}
                </>
              ) : (
                (noAutoRegisterText || noAutoRegisterButton) && (
                  <>
                    {noAutoRegisterText && <SC.Text6>{noAutoRegisterText}</SC.Text6>}
                    {noAutoRegisterButton && (
                      <SC.ButtonBox>
                        <SC.Button {...noAutoRegisterButton} outlined />
                      </SC.ButtonBox>
                    )}
                  </>
                )
              )
            ) : (
              (noAvailabilitiesButton || noAvailabilitiesText) && (
                <>
                  {noAvailabilitiesText && <SC.Text6>{noAvailabilitiesText}</SC.Text6>}
                  {noAvailabilitiesButton && (
                    <SC.ButtonBox>
                      <SC.Button {...noAvailabilitiesButton} outlined />
                    </SC.ButtonBox>
                  )}
                </>
              )
            )}
          </>
        )}
      </SC.Content>
      {requestErrorModalProps && <SimpleFeedbackModal {...requestErrorModalProps} />}
      {feedbackModalProps && <SimpleFeedbackModal {...feedbackModalProps} />}
    </AuthLayout>
  )
}

export default memo(SearchResultsTemplate)
