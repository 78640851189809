import React, { FC } from 'react'

import ModalContainer from '../ModalContainer'
import { SignUpAttachParentProps } from '../../SignUpAttachParent'

import * as SC from './styled'

export type AttachParentModalProps = {
  className?: string
  headerText: string
  open: boolean
  attachParentFormProps: SignUpAttachParentProps
  onClose?: () => void
}

const AttachParentModal: FC<AttachParentModalProps> = (props) => {
  const { className, headerText, open, onClose, attachParentFormProps } = props

  return (
    <ModalContainer open={open} onClose={onClose} className={className}>
      <SC.HeaderText>{headerText}</SC.HeaderText>
      <SC.Content>
        <SC.Form {...attachParentFormProps} />
      </SC.Content>
    </ModalContainer>
  )
}

export default AttachParentModal
