import React, { FC, useMemo } from 'react'
import { FieldArray, Form, Formik, FormikHelpers } from 'formik'
import * as Yup from 'yup'

import { ActionButtonProps } from '../ActionButton'
import { SignUpAddChildFieldArrayProps } from '../SignUpAddChildFieldArray'
import { FormFieldConfig, FormFieldsProps } from '../../types/form'
import { compileInitialState, getValidationSchema } from '../../helpers/FormHelpers'
import UploadImage from '../form/UploadImage'
import FormSubmitErrors, { FormSubmitErrorsProps } from '../FormSubmitErrors'
import LegalLegends, { LegalLegendsProps } from '../LegalLegends'

import * as SC from './styled'

export type AddChildrenValues = {
  children: {
    firstName: string
    lastName: string
    level: string
    file: string
    school: string
    email?: string
    password?: string
    avatar?: string
    signupChild?: boolean
  }[]
}

export type AddChildrenProps = {
  className?: string
  fieldsProps?: FormFieldsProps
  initialValues?: AddChildrenValues
  step: string
  title: string
  legends?: LegalLegendsProps['texts']
  addChildForm: SignUpAddChildFieldArrayProps
  addAnotherChildButton: ActionButtonProps
  linkCodeText: string
  linkCode: string
  submitButton: ActionButtonProps
  skipButton: ActionButtonProps
  errorTexts?: { [key: string]: string }
  onSubmit?: (values: any, formikHelpers: FormikHelpers<any>) => void
  submitErrors?: FormSubmitErrorsProps['errors']
}

const AddChildren: FC<AddChildrenProps> = (props) => {
  const {
    className,
    fieldsProps,
    initialValues = { children: [] },
    step,
    title,
    addChildForm,
    addAnotherChildButton,
    linkCodeText,
    linkCode,
    submitButton,
    skipButton,
    errorTexts,
    onSubmit = (_values, _formikHelpers) => null,
    submitErrors,
    legends,
  } = props

  const fieldArray = {
    name: 'children',
  }

  const fields: FormFieldConfig[] = useMemo(
    () => [
      {
        name: 'firstName',
        Component: SC.Field,
        validation: Yup.string().required(errorTexts?.required),
        required: true,
      },
      {
        name: 'lastName',
        Component: SC.Field,
        validation: Yup.string().required(errorTexts?.required),
        required: true,
      },
      {
        name: 'school',
        id: 'schoolName',
        Component: SC.Field,
        validation: Yup.string().required(errorTexts?.required),
        required: true,
      },
      {
        name: 'level',
        Component: SC.Field,
        validation: Yup.string().required(errorTexts?.required),
        required: true,
      },
      {
        name: 'file',
        Component: UploadImage,
        required: true,
      },
      {
        name: 'signupChild',
        Component: SC.Check,
        defaultValue: false,
      },
      {
        name: 'email',
        Component: SC.Field,
        validation: Yup.string().when('signupChild', {
          is: true,
          then: Yup.string().email(errorTexts?.email).required(errorTexts?.required),
        }),
        autoComplete: 'new-email',
        required: true,
        conditional: true,
      },
      {
        name: 'password',
        Component: SC.Field,
        validation: Yup.string().when('signupChild', {
          is: true,
          then: Yup.string().required(errorTexts?.required),
        }),
        autoComplete: 'new-password',
        type: 'password',
        conditional: true,
      },
    ],
    [errorTexts?.email, errorTexts?.required]
  )

  const initialState = compileInitialState(fields)
  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      [fieldArray.name]: Yup.array(getValidationSchema(fields)),
    })
  }, [fieldArray.name, fields])

  return (
    <SC.AddChildren className={className}>
      <SC.Step>{step}</SC.Step>
      <SC.Title>{title}</SC.Title>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnChange={true}
        validateOnBlur={true}
        onSubmit={onSubmit}
      >
        {(formikProps) => (
          <Form noValidate autoComplete={'off'}>
            <FieldArray
              name={fieldArray.name}
              render={(arrayHelpers) => (
                <div>
                  {formikProps.values?.children?.map((child, index) => {
                    return (
                      <SC.ChildAddForm
                        {...addChildForm}
                        fieldsProps={fieldsProps}
                        formikProps={formikProps}
                        key={`add-children-${index}`}
                        name={fieldArray.name}
                        index={index}
                        fields={fields}
                        {...(index > 0 && {
                          closeButtonProps: {
                            onClick: () => arrayHelpers.remove(index),
                          },
                        })}
                      />
                    )
                  })}
                  <SC.AddAnotherChildButton
                    {...addAnotherChildButton}
                    onClick={() => arrayHelpers.push(initialState)}
                    outlined
                  />
                </div>
              )}
            />

            {linkCodeText && linkCode && (
              <SC.LinkCodeBox>
                <SC.LinkCodeText>{linkCodeText}</SC.LinkCodeText>
                <SC.LinkCode>{linkCode}</SC.LinkCode>
              </SC.LinkCodeBox>
            )}
            <FormSubmitErrors errors={submitErrors} />
            <SC.Buttons>
              <SC.SkipButton {...skipButton} outlined />
              <SC.SubmitButton {...submitButton} autoIcon />
            </SC.Buttons>
            <LegalLegends texts={legends} />
          </Form>
        )}
      </Formik>
    </SC.AddChildren>
  )
}

export default AddChildren
