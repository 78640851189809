import React, { FC } from 'react'

import Header, { HeaderProps } from '../../components/Header'
import Menu, { MenuProps } from '../../components/Menu'
import AuthLayout from '../../layouts/AuthLayout'
import { CourseCardCarouselProps } from '../../components/CourseCardCarousel'
import { ImageTextListProps } from '../../components/ImageTextList'
import { CourseCardProps } from '../../components/CourseCard'
import { TabContentProps } from '../../components/TabContent'
import CardList from '../../components/CardList'
import { ImageTextItemProps } from '../../components/ImageTextItem'
import PageBackButton, { PageBackButtonProps } from '../../components/PageBackButton'

import * as SC from './styled'

export type PlanningTabsProps = {
  tabs: { label: string; total: number; cards: CourseCardProps[]; value: string }[]
  value: string
  onChange: (tab: string) => void
}

export type PlanningTemplateProps = {
  className?: string
  headerProps: HeaderProps
  menuProps: MenuProps
  title: string
  subtitle?: string
  carouselProps?: CourseCardCarouselProps
  imageTextListProps?: ImageTextListProps
  tabSetProps?: PlanningTabsProps
  requestForLabel?: string
  requestFor?: ImageTextItemProps
  backButton?: PageBackButtonProps
}

const PlanningTemplate: FC<PlanningTemplateProps> = (props) => {
  const {
    headerProps,
    menuProps,
    title,
    subtitle,
    carouselProps,
    imageTextListProps,
    tabSetProps,
    requestForLabel,
    requestFor,
    backButton,
  } = props

  const tabContentProps: TabContentProps = {
    name: '',
    tabs:
      tabSetProps?.tabs?.map((tab) => ({
        label: tab.label,
        children: <CardList cards={tab.cards} />,
        numberOfCards: tab.total + '',
        value: tab.value,
      })) ?? [],
    value: tabSetProps?.value ?? '',
    onChange: (_name, value) => tabSetProps?.onChange?.(value),
  }

  return (
    <AuthLayout header={<Header {...headerProps} withDivider />} menu={<Menu {...menuProps} />}>
      <SC.Content>
        {backButton && <PageBackButton {...backButton} />}
        <SC.Title>{title}</SC.Title>
        {subtitle && <SC.Subtitle>{subtitle}</SC.Subtitle>}
        {requestForLabel && <SC.RequestForLabel>{requestForLabel}</SC.RequestForLabel>}
        {requestFor && <SC.RequestFor {...requestFor} />}
        {tabSetProps && <SC.Tabs {...tabContentProps} />}
        {carouselProps && <SC.MyCourseCardCarousel {...carouselProps} />}
        {imageTextListProps && <SC.MyImageTextList {...imageTextListProps} />}
      </SC.Content>
    </AuthLayout>
  )
}

export default PlanningTemplate
