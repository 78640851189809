import React, { FC } from 'react'

import { ActionButtonProps } from '../ActionButton'
import { ImgProps } from '../../types/image'

import * as SC from './styled'

export type PotProps = {
  className?: string
  image: ImgProps
  text: string
  credits?: string
  otherCredits?: string
  availableLabel?: string
  transferringCredits?: string
  helperText?: string
  button?: ActionButtonProps
  subButton?: ActionButtonProps
  isDesktop?: boolean
  isBelow?: boolean
  parentsText?: string
  isRow?: boolean
}

const Pot: FC<PotProps> = (props) => {
  const {
    className,
    image,
    text,
    credits,
    otherCredits,
    transferringCredits,
    helperText,
    availableLabel,
    button,
    subButton,
    isDesktop,
    isBelow,
    parentsText,
    isRow,
  } = props

  return (
    <SC.Pot className={className}>
      <SC.Wrapper>
        <SC.Content isRow={isRow}>
          <SC.BoxImage>
            <SC.Image src={image.src} alt={image.alt} />
          </SC.BoxImage>
          <SC.RightSide>
            <SC.TextWrapper>
              <SC.Text>{text}</SC.Text>
              {credits !== undefined && (
                <SC.CreditsWrapper>
                  <SC.Credits>{credits}</SC.Credits>
                  {availableLabel && <SC.Available>{availableLabel}</SC.Available>}
                </SC.CreditsWrapper>
              )}
              {otherCredits && (
                <SC.OtherCredits isRed={!!parentsText}>{otherCredits}</SC.OtherCredits>
              )}
              {transferringCredits && <SC.OtherCredits>{transferringCredits}</SC.OtherCredits>}
              {parentsText && <SC.ParentsText>{parentsText}</SC.ParentsText>}
              {helperText && <SC.HelperText>{helperText}</SC.HelperText>}
            </SC.TextWrapper>
            {button && isDesktop && <SC.Button {...button} />}
            {button && !isDesktop && !isBelow && <SC.Button {...button} />}
            {subButton && !isDesktop && !isBelow && <SC.SubButton {...subButton} />}
          </SC.RightSide>
        </SC.Content>
        {button && isBelow && <SC.Button {...button} />}
        {subButton && isBelow && <SC.SubButton {...subButton} />}
      </SC.Wrapper>
    </SC.Pot>
  )
}

export default Pot
