import React, { useMemo } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { HeaderProps } from '../../components/Header'
import useHeader from '../../hooks/useHeader'
import { MenuProps } from '../../components/Menu'
import useMenu from '../../hooks/useMenu'
import { UserType } from '../../generated/graphql'
import { RestrictedReactFC } from '../../types/common'
import { router, routesPath } from '../../router'
import { actions, selectors } from '../../redux'
import { useFormSubmit } from '../../hooks/useFormSubmit'
import PreferencesPricingTemplate, {
  PreferencesPricingTemplateProps,
} from '../../templates/PreferencesPricing'
import { PricesChoiceFormValues, PricingType } from '../../components/PricesChoiceForm'
import useBackButton from '../../hooks/useBackButton'

const PreferencesPricingPage: RestrictedReactFC<RouteComponentProps> = (props) => {
  const headerProps: HeaderProps = useHeader()
  const menuProps: MenuProps = useMenu(props)
  const { t } = useTranslation()
  const user = useSelector(selectors.auth.user)
  const userDisciplines = useSelector(selectors.auth.disciplinesWithGrades)
  const backButton = useBackButton(router(routesPath.preferences))

  const [submit, handleSubmit] = useFormSubmit(
    selectors.preferences.updateTeacherPricing,
    actions.preferences.updateTeacherPricingRequest,
    actions.preferences.updateTeacherPricingReset
  )

  const type = useMemo(() => {
    let val = PricingType.UNIQUE
    userDisciplines?.forEach((discipline) =>
      discipline.grades?.forEach((g) => {
        if (g?.price && g?.price > 0) {
          val = PricingType.CUSTOM
        }
      })
    )
    return val
  }, [userDisciplines])

  const customValues = useMemo(
    () =>
      userDisciplines?.map((discipline) =>
        discipline.grades?.reduce(
          (arr, g) => ({
            ...arr,
            [g.id as string]: g?.price && g?.price > 0 ? (g.price / 100).toString() : '',
          }),
          {}
        )
      ),
    [userDisciplines]
  )

  const initialValues: PricesChoiceFormValues = useMemo(
    () => ({
      type,
      unique: (user?.teacher?.price && (user?.teacher?.price / 100)?.toString()) || '',
      custom: customValues || [],
    }),
    [customValues, user?.teacher?.price, type]
  )

  const groups = useMemo(
    () =>
      userDisciplines?.map((discipline) => ({
        label: discipline.name,
        levels: discipline.grades?.map((g) => ({
          name: g.id,
          label: g.name,
          placeholder: t('teacherPricingForm_custom_placeholder'),
        })),
      })),
    [t, userDisciplines]
  )

  const preferencesProps: PreferencesPricingTemplateProps = {
    menuProps,
    headerProps,
    title: t('preferences_pricing_title'),
    pricesChoiceFormProps: {
      uniqueLabel: t('teacherPricingForm_type_unique_label'),
      uniqueText: t('teacherPricingForm_type_unique_text'),
      customLabel: t('teacherPricingForm_type_custom_label'),
      customText: t('teacherPricingForm_type_custom_text'),
      fieldsProps: {
        type: {},
        unique: {
          label: t('teacherPricingForm_unique_label'),
        },
        custom: {
          groups,
        },
      },
      errorTexts: {
        required: t('error_required'),
      },
      initialValues,
      submitButton: {
        text: t('preferences_pricing_submit'),
        pendingText: t('preferences_pricing_submit_pending'),
        successText: t('preferences_pricing_submit_success'),
        isPending: submit.pending,
        isSuccess: submit.success,
      },
      submitErrors: submit.errors,
      onSubmit: (values) => {
        handleSubmit(values)
      },
    },
    backButton,
  }

  return <PreferencesPricingTemplate {...preferencesProps} />
}

PreferencesPricingPage.restrictedUserTypes = [UserType.Teacher]

export default PreferencesPricingPage
