import React, { useEffect } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { HeaderProps } from '../../components/Header'
import useHeader from '../../hooks/useHeader'
import { MenuProps } from '../../components/Menu'
import useMenu from '../../hooks/useMenu'
import { Course, UserType } from '../../generated/graphql'
import { RestrictedReactFC } from '../../types/common'
import { actions, selectors } from '../../redux'
import PlanningListTemplate, { PlanningListTemplateProps } from '../../templates/PlanningList'
import { courseToPastCardProps } from '../../transformers/courseTransformers'
import useBackButton from '../../hooks/useBackButton'

const PlanningReviewPage: RestrictedReactFC<RouteComponentProps> = (props) => {
  const headerProps: HeaderProps = useHeader()
  const menuProps: MenuProps = useMenu(props)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const user = useSelector(selectors.auth.user)
  const pendingReviewCourses = useSelector(selectors.planning.pendingReviewCourses)
  const backButton = useBackButton()

  const lists = []
  if (pendingReviewCourses?.data?.data && pendingReviewCourses?.data?.data?.length > 0) {
    lists.push({
      cards: pendingReviewCourses?.data?.data?.map((c: Course) =>
        courseToPastCardProps(c, t, user)
      ),
    })
  }

  const planningProps: PlanningListTemplateProps = {
    headerProps,
    menuProps,
    backButton,
    title: t('planningReview_title'),
    lists,
  }

  //
  // EFFECTS
  //
  useEffect(() => {
    dispatch(actions.planning.pendingReviewCoursesRequest(undefined))
    return () => {
      dispatch(actions.planning.pendingReviewCoursesReset(undefined))
    }
  }, [dispatch])

  return <PlanningListTemplate {...planningProps} />
}

PlanningReviewPage.restrictedUserTypes = [UserType.Teacher]

export default PlanningReviewPage
