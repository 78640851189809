import styled from 'styled-components'

import PotC from '../../components/Pot'
import BasicLink from '../../components/BasicLink'
import TabContentC from '../../components/TabContent'
import WithdrawMoneyFormC from '../../components/WithdrawMoneyForm'
import WalletConfiguration from '../../components/WalletConfiguration'

export const Content = styled.div`
  flex-grow: 1;
  ${(props) => props.theme.breakpoints.up('md')} {
    flex-grow: initial;
  }
`

export const Pot = styled(PotC)`
  margin-top: 2rem;
`

export const Configuration = styled(WalletConfiguration)`
  margin-top: 2rem;
`

export const ReturnCta = styled(BasicLink)`
  margin: 1rem 0 0 1.6rem;
  display: flex;
  ${(props) => ({ ...props.theme.typography.callToAction })}
  text-decoration: none;
  color: ${(props) => props.theme.palette.colors.freeSpeechBlue};
  cursor: pointer;
`

export const Title = styled.h4`
  ${(props) => ({ ...props.theme.typography.h3 })}
  color: ${(props) => props.theme.palette.colors.midnightBlue};
  padding: 3rem 1.6rem 0rem 1.6rem;
  margin: 0;
`

export const Title2 = styled.h4`
  ${(props) => ({ ...props.theme.typography.h3 })}
  color: ${(props) => props.theme.palette.colors.neonCarrot};
  margin: 2rem 0 0.8rem;
  padding: 0 1.6rem;
  ${(props) => props.theme.breakpoints.up('md')} {
    font-size: 4rem;
  }
`

export const TabContent = styled(TabContentC)`
  padding: 0 1.6rem;
`

export const WithdrawMoneyForm = styled(WithdrawMoneyFormC)`
  margin: 0 1.6rem;
  max-width: 50rem;
`
