import gql from 'graphql-tag'

import UserFragment from '../../../fragments/UserFragment'
import type { User } from '../../../../generated/graphql'

const updateTeacherDisciplines = {
  mutation: gql`
    mutation updateTeacherDisciplines($disciplines: [TeacherDisciplineInput!]) {
      updateTeacherDisciplines(disciplines: $disciplines) {
        ...UserFragment
      }
    }
    ${UserFragment}
  `,
  transformer: (response: any): User => {
    return response.updateTeacherDisciplines
  },
}

export default updateTeacherDisciplines
