import { DisputeReason, Maybe, UserType } from '../../generated/graphql'

const TeacherDisputeReasons = [
  DisputeReason.ForceMajeure,
  DisputeReason.StudentBehavior,
  DisputeReason.PlatformFailure,
  DisputeReason.Other,
]

const NonTeacherDisputeReasons = [
  DisputeReason.ForceMajeure,
  DisputeReason.TeacherMissingOrLate,
  DisputeReason.TeacherBehaviour,
  DisputeReason.TeacherWrongContent,
  DisputeReason.PlatformFailure,
  DisputeReason.Other,
]

export const disputeReasonOptions = (t: any, userType?: UserType | Maybe<UserType>) =>
  (userType === UserType.Teacher ? TeacherDisputeReasons : NonTeacherDisputeReasons).map(
    (reason) => ({
      value: reason,
      label: t(`enum_DisputeReason_${reason}`),
    })
  )
