import gql from 'graphql-tag'

import { Course } from '../../../../generated/graphql'
import CourseFragment from '../../../fragments/CourseFragment'

export type TeacherDashboardCoursesResult = {
  today?: Course
  future: { data: Course[]; total: number }
  past: { data: Course[]; total: number }
  pendingReview: { data: Course[]; total: number }
  teacherPending: { data: Course[]; total: number }
}

const teacherDashboardCourses = {
  query: gql`
    query teacherDashboardCourses {
      todayCourse {
        ...CourseFragment
      }
      futureCourses(first: 2) {
        data {
          ...CourseFragment
        }
        paginatorInfo {
          total
        }
      }
      pastCourses(first: 1, exclude_non_evaluated: true) {
        data {
          ...CourseFragment
        }
        paginatorInfo {
          total
        }
      }
      pendingReviewCourses(first: 2) {
        data {
          ...CourseFragment
        }
        paginatorInfo {
          total
        }
      }
      teacherPendingCourses(first: 1, type: ONE_OFF) {
        data {
          ...CourseFragment
        }
        paginatorInfo {
          total
        }
      }
    }
    ${CourseFragment}
  `,
  transformer: (response: any): TeacherDashboardCoursesResult => {
    return {
      today: response?.todayCourse,
      future: {
        data: response?.futureCourses?.data?.filter(
          (c: Course) => c.id !== response.todayCourse?.id
        ),
        total: response?.futureCourses?.paginatorInfo?.total,
      },
      past: {
        data: response?.pastCourses?.data,
        total: response?.pastCourses?.paginatorInfo?.total,
      },
      pendingReview: {
        data: response?.pendingReviewCourses?.data,
        total: response?.pendingReviewCourses?.paginatorInfo?.total,
      },
      teacherPending: {
        data: response?.teacherPendingCourses?.data,
        total: response?.teacherPendingCourses?.paginatorInfo?.total,
      },
    }
  },
}

export default teacherDashboardCourses
