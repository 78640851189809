import { Form, Formik, FormikHelpers, FormikProps } from 'formik'
import React, { FC, useRef } from 'react'

import FormScrollToError from '../FormScrollToError'
import { FormSubmitProps } from '../FormSubmit'
import FormSubmitErrors, { FormSubmitErrorsProps } from '../FormSubmitErrors'
import { ImageTextListProps } from '../ImageTextList'

import * as SC from './styled'

export type SearchRequestFormValues = {
  child?: string
  request: string
}

export type SearchRequestFormProps = {
  className?: string
  whichChildTitle?: string
  childList?: ImageTextListProps
  whichTypeOfRequest?: string
  typeOfRequestList: ImageTextListProps
  initialValues: SearchRequestFormValues
  submitButton: FormSubmitProps
  submitErrors?: FormSubmitErrorsProps['errors']
  onSubmit: (
    values: SearchRequestFormValues,
    formikHelpers: FormikHelpers<SearchRequestFormValues>
  ) => void
}

const validationSchema = undefined

const SearchRequestForm: FC<SearchRequestFormProps> = ({
  className,
  whichChildTitle,
  childList,
  whichTypeOfRequest,
  typeOfRequestList,
  initialValues,
  submitButton,
  submitErrors,
  onSubmit,
}) => {
  const formikRef = useRef<FormikProps<SearchRequestFormValues>>(null)

  return (
    <SC.SearchRequestForm className={className}>
      <SC.Wrapper disableGutters>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnChange={true}
          validateOnBlur={true}
          onSubmit={onSubmit}
          enableReinitialize
          innerRef={formikRef}
        >
          {(formikProps) => (
            <Form noValidate>
              <FormScrollToError formikProps={formikProps} />
              {whichChildTitle && childList && (
                <SC.Field>
                  <SC.List
                    {...childList}
                    label={whichChildTitle}
                    name={'child'}
                    value={formikProps.values.child}
                    onChange={(name, value) => formikProps.setFieldValue(name, value)}
                    hoverable
                  />
                </SC.Field>
              )}
              <SC.Field>
                <SC.List
                  {...typeOfRequestList}
                  label={whichTypeOfRequest}
                  name={'request'}
                  value={formikProps.values.request}
                  onChange={(name, value) => formikProps.setFieldValue(name, value)}
                  hoverable
                />
              </SC.Field>
              <FormSubmitErrors errors={submitErrors} />
              <SC.SubmitButton
                {...submitButton}
                isSuccess={submitButton.isSuccess && !formikProps.dirty}
                autoIcon
              />
            </Form>
          )}
        </Formik>
      </SC.Wrapper>
    </SC.SearchRequestForm>
  )
}

export default SearchRequestForm
