import { useCallback, useEffect, useState } from 'react'
import { Selector, useDispatch, useSelector } from 'react-redux'

import { ServiceProps } from '../helpers/ReduxHelpers'

export const useFormSubmit = (
  selector: Selector<any, any>,
  action: any,
  actionReset?: any,
  onComplete?: (s: ServiceProps<any, any>) => void | null
): [ServiceProps<any, any>, (values: any) => void] => {
  const [isPending, setIsPending] = useState(false)
  const dispatch = useDispatch()
  const submit: ServiceProps<any, any> = useSelector(selector)
  const handleSubmit = useCallback(
    (values) => {
      setIsPending(true)
      dispatch?.(action(values))
    },
    [dispatch, action]
  )

  useEffect(() => {
    if (submit.success && isPending) {
      onComplete?.(submit)
    }
    if ((submit.pending || submit.complete) && !isPending && actionReset) {
      dispatch?.(actionReset())
    }
  }, [
    dispatch,
    isPending,
    onComplete,
    actionReset,
    submit.success,
    submit.complete,
    submit.pending,
    submit,
  ])

  return [submit, handleSubmit]
}
