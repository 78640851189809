import { PayoutScheduleInterval } from '../../generated/graphql'

export const payoutScheduleIntervalArray = [
  PayoutScheduleInterval.Daily,
  PayoutScheduleInterval.Weekly,
  PayoutScheduleInterval.Monthly,
]

export const payoutScheduleIntervalTranslation = (t: any, val: PayoutScheduleInterval) =>
  t(`enum_PayoutScheduleInterval_${val}`)
export const payoutScheduleIntervalDescriptionTranslation = (t: any, val: PayoutScheduleInterval) =>
  t(`enum_PayoutScheduleInterval_${val}_description`)
export const payoutScheduleIntervalOptions = (t: any) =>
  payoutScheduleIntervalArray.map((val) => ({
    label: payoutScheduleIntervalTranslation(t, val),
    description: payoutScheduleIntervalDescriptionTranslation(t, val),
    value: val,
  }))
