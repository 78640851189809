import { routesPath } from '../../../router'

export enum SearchSteps {
  STEP_0_TYPE = 'STEP_0_TYPE',
  STEP_1_DISCIPLINE = 'STEP_1_DISCIPLINE',
  STEP_2_THEME = 'STEP_2_THEME',
  STEP_3_PRICE = 'STEP_3_PRICE',
  STEP_4_AVAILABILITIES = 'STEP_4_AVAILABILITIES',
}

export const searchStepsArray = [
  SearchSteps.STEP_0_TYPE,
  SearchSteps.STEP_1_DISCIPLINE,
  SearchSteps.STEP_2_THEME,
  SearchSteps.STEP_3_PRICE,
  SearchSteps.STEP_4_AVAILABILITIES,
]

export const searchStepsUrls = {
  [SearchSteps.STEP_0_TYPE]: routesPath.search,
  [SearchSteps.STEP_1_DISCIPLINE]: routesPath.searchDiscipline,
  [SearchSteps.STEP_2_THEME]: routesPath.searchTheme,
  [SearchSteps.STEP_3_PRICE]: routesPath.searchPrice,
  [SearchSteps.STEP_4_AVAILABILITIES]: routesPath.searchAvailabilities,
}
