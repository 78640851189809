import React, { FC } from 'react'
import { ToggleButtonGroupProps as MuiToggleButtonProps, ToggleButtonProps } from '@material-ui/lab'

import * as SC from './styled'

export type ToggleButtonGroupOption = {
  label?: string | null
  value?: string | null
  disabled?: boolean
}

// omit "variant" to fix TS error
export type ToggleButtonGroupProps = Omit<MuiToggleButtonProps, 'onChange'> & {
  className?: string
  id?: string
  name: string
  label?: string
  options?: ToggleButtonGroupOption[]
  fields?: ToggleButtonProps[]
  isRadioMode?: boolean
  value?: string | string[] | null
  onChange: (name: string, value: string[]) => void
  isFullWidth?: boolean
  nullable?: boolean
}

const ToggleButtonGroup: FC<ToggleButtonGroupProps> = ({
  className,
  name,
  label,
  fields,
  isRadioMode,
  value,
  onChange,
  isFullWidth,
  nullable,
  options,
  ...otherProps
}) => {
  return (
    <SC.Wrapper className={className}>
      {label && <SC.Label>{label}</SC.Label>}
      <SC.MuiToggleButtonGroup
        exclusive={isRadioMode || otherProps.exclusive}
        value={value}
        onChange={(e, newSelected: ToggleButtonGroupProps['value']) => {
          if (!(isRadioMode || otherProps.exclusive)) {
            if (newSelected?.includes('')) {
              return onChange?.(name, [])
            }
            if (value?.includes('')) {
              return onChange?.(
                name,
                (newSelected as string[])?.filter((v) => v != '')
              )
            }
          }
          if (!newSelected && !nullable) return
          onChange?.(name, newSelected)
        }}
      >
        {fields?.map((field, index) => (
          <SC.MuiToggleButton
            key={index}
            value={field.value}
            $isFullWidth={isFullWidth}
            disabled={field?.disabled}
          >
            {field.children}
          </SC.MuiToggleButton>
        ))}
        {options?.map((option, index) => (
          <SC.MuiToggleButton
            key={index}
            value={option.value}
            $isFullWidth={isFullWidth}
            disabled={option?.disabled}
          >
            {option.label}
          </SC.MuiToggleButton>
        ))}
      </SC.MuiToggleButtonGroup>
    </SC.Wrapper>
  )
}

export default ToggleButtonGroup
