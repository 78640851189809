import { createSlice } from '@reduxjs/toolkit'

import type { RootState } from '../store'
import { getServiceProps, getServiceReducers, ServiceProps } from '../../helpers/ReduxHelpers'
import { JoinRoomResult } from '../../graphql/services/room/queries/joinRoom'
import { QueryJoinRoomArgs } from '../../generated/graphql'

export type RoomState = {
  join: ServiceProps<JoinRoomResult, QueryJoinRoomArgs>
}

//
// Initial state
//

const initialState: RoomState = {
  join: getServiceProps(),
}

//
// Slice (Actions & Reducers)
//

const slice = createSlice({
  name: 'room',
  initialState,
  reducers: {
    ...getServiceReducers('join'),
  },
})

export const { reducer, actions } = slice

//
// Selectors
//

const root = (state: RootState) => state[slice.name]
const join = (state: RootState) => root(state).join

export const selectors = {
  join,
}
