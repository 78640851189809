import styled from 'styled-components'

export const Container = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
`

export const Jitsi = styled.div`
  flex-grow: 1;
`

export const Controls = styled.div`
  padding: 1rem;
  background: black;
  display: none;
  i {
    margin-right: 1rem;
    cursor: pointer;
    color: white;
  }
`
