import React, { FC, useState, useEffect } from 'react'
import { IconButton, Hidden } from '@material-ui/core'

import { Icons } from '../Icon/types'
import { ActionButtonProps } from '../ActionButton'
import { BasicLinkProps } from '../BasicLink'
import useWindowSize from '../../hooks/useWindowSize'
import { ImgProps } from '../../types/image'
import Link, { LinkProps } from '../Link'
import breakpoints from '../../theme/breakpoints'
import ImpersonateBanner, { ImpersonateBannerProps } from '../ImpersonateBanner'
import NotificationsModal, { NotificationsModalProps } from '../modals/NotificationsModal'

import * as SC from './styled'

export type MenuMobileProps = {
  signup?: ActionButtonProps[]
  others?: BasicLinkProps[]
}

export type HeaderProps = {
  className?: string
  logo: ImgProps
  menu?: (BasicLinkProps & { special?: boolean })[]
  menuMobile?: MenuMobileProps
  backgroundColor: string
  isConnected: boolean
  profilePicture?: ImgProps
  profileButtonProps?: LinkProps
  logoButtonProps?: LinkProps
  notificationCount: number
  impersonateProps?: ImpersonateBannerProps
  hideNotifications?: boolean
  withDivider?: boolean
  onNotificationClick?: () => void
  notificationsModalProps?: NotificationsModalProps
}

const Header: FC<HeaderProps> = (props) => {
  const {
    className,
    logo,
    logoButtonProps,
    menu,
    menuMobile,
    backgroundColor,
    isConnected,
    profilePicture,
    profileButtonProps,
    notificationCount,
    impersonateProps,
    hideNotifications,
    withDivider,
    onNotificationClick,
    notificationsModalProps,
  } = props
  const [isMobile, setIsMobile] = useState(false)
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)

  const { width } = useWindowSize()

  const onSidebarOpen = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen)
  }

  useEffect(() => {
    if (width < breakpoints.values.md) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }, [width])

  const connectedHeader = () => (
    <SC.MuiToolbar>
      <SC.Logo {...logoButtonProps}>
        <SC.LogoImage src={logo.src} alt={logo.alt} />
      </SC.Logo>
      <SC.RightSide>
        {!hideNotifications && (
          <>
            {notificationCount > 0 && (
              <SC.NotificationCount>{notificationCount}</SC.NotificationCount>
            )}
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={onNotificationClick}
            >
              <SC.Icons
                icon={notificationCount === 0 ? Icons.notificationOff : Icons.notificationOn}
              />
            </IconButton>
          </>
        )}

        {profilePicture ? (
          <SC.ProfilePictureBox {...profileButtonProps}>
            <SC.ProfilePicture src={profilePicture?.src} alt={profilePicture?.alt} />
          </SC.ProfilePictureBox>
        ) : (
          <SC.ProfilePictureBox {...profileButtonProps}>
            <IconButton edge="start" color="inherit" aria-label="menu">
              <SC.Icons icon={Icons.profile} />
            </IconButton>
          </SC.ProfilePictureBox>
        )}
      </SC.RightSide>
    </SC.MuiToolbar>
  )

  const notConnectedHeader = () =>
    !isMobile ? (
      <SC.MuiToolbar>
        <SC.Logo {...logoButtonProps}>
          <SC.LogoImage src={logo.src} alt={logo.alt} />
        </SC.Logo>
        <SC.MobileList>
          {menu &&
            menu?.map((item, index) => (
              <SC.MobileListItem key={index}>
                <SC.MobileListItemLink
                  className={item.active ? 'active' : item.special ? 'special' : ''}
                  {...item}
                />
              </SC.MobileListItem>
            ))}
        </SC.MobileList>
      </SC.MuiToolbar>
    ) : (
      <>
        <SC.MuiToolbar className={isMobileMenuOpen ? 'mobile-menu-open-toolbar' : ''}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={() => onSidebarOpen()}
          >
            <SC.Icons icon={Icons.menu} />
          </IconButton>
          <SC.Logo {...logoButtonProps}>
            <SC.LogoImage src={logo.src} alt={logo.alt} />
          </SC.Logo>
          <Link {...profileButtonProps}>
            <IconButton edge="start" color="inherit" aria-label="menu">
              <SC.Icons icon={Icons.profile} />
            </IconButton>
          </Link>
        </SC.MuiToolbar>
        <Hidden mdUp implementation="css">
          <SC.MuiDrawer variant="persistent" anchor="left" open={isMobileMenuOpen}>
            <SC.MobileMenu>
              <SC.MobileList>
                {menu &&
                  menu.map((item, index) => (
                    <SC.MobileListItem key={index}>
                      <SC.MobileListItemLink className={item.active ? 'active' : ''} {...item} />
                    </SC.MobileListItem>
                  ))}
              </SC.MobileList>
              <SC.MuiDivider />
              <SC.MobileButtons>
                {menuMobile &&
                  menuMobile.signup &&
                  menuMobile.signup.map((item, index) => (
                    <SC.MobileLinkButton key={index} {...item} outlined />
                  ))}
              </SC.MobileButtons>
              <SC.MuiDivider />
              <SC.MobileList>
                {menuMobile &&
                  menuMobile.others &&
                  menuMobile.others.map((item, index) => (
                    <SC.MobileListItem key={index}>
                      <SC.MobileListTinyItemLink {...item} />
                    </SC.MobileListItem>
                  ))}
              </SC.MobileList>
            </SC.MobileMenu>
            <SC.CloseIconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={() => onSidebarOpen()}
            >
              <SC.Icons icon={Icons.close} />
            </SC.CloseIconButton>
          </SC.MuiDrawer>
        </Hidden>
      </>
    )

  return (
    <SC.Header className={className} backgroundColor={backgroundColor}>
      {impersonateProps && <ImpersonateBanner {...impersonateProps} />}
      <SC.Wrapper maxWidth={withDivider ? false : 'xl'} disableGutters>
        {isConnected ? connectedHeader() : notConnectedHeader()}
      </SC.Wrapper>
      {withDivider && <SC.MenuDivider />}
      {notificationsModalProps && <NotificationsModal {...notificationsModalProps} />}
    </SC.Header>
  )
}

export default Header
