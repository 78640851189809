import gql from 'graphql-tag'

const stripeAccountLink = {
  query: gql`
    query stripeAccountLink($return_url: String, $refresh_url: String) {
      stripeAccountLink(return_url: $return_url, refresh_url: $refresh_url)
    }
  `,
  transformer: (response: any): string => {
    return response.stripeAccountLink
  },
}

export default stripeAccountLink
