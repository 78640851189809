import React, { FC } from 'react'

import { Icons } from '../Icon/types'
import { LinkProps } from '../Link'

import * as SC from './styled'

export type BasicLinkProps = {
  children?: React.ReactNode
  className?: string
  link?: LinkProps
  text?: string
  onClick?: (ev?: React.SyntheticEvent) => void
  noHref?: boolean
  active?: boolean
  special?: boolean
  color?: string
  icon?: Icons
  iconColor?: string
  iconSide?: 'left' | 'right'
  backgroundColor?: string
}

export type TextLinkProps = BasicLinkProps & { text?: string }

const BasicLink: FC<BasicLinkProps> = ({
  children,
  className,
  link,
  text,
  color,
  icon,
  iconColor,
  iconSide,
  onClick = () => undefined,
  backgroundColor,
}) => {
  const renderIcon = () =>
    icon && (
      <SC.IconIn
        icon={Icons[icon]}
        color={color ? color : iconColor ? iconColor : ''}
        iconSide={iconSide}
      />
    )

  const renderContent = () => {
    return (
      <SC.Box onClick={onClick}>
        {iconSide === 'left' && icon && renderIcon()}
        {children || text || ' '}
        {iconSide === 'right' && icon && renderIcon()}
        {!iconSide && icon && renderIcon()}
      </SC.Box>
    )
  }

  const renderDefault = () => (
    <SC.LinkA className={className} backgroundColor={backgroundColor} color={color} {...link}>
      {renderContent()}
    </SC.LinkA>
  )

  const renderAsLink = () => {
    if (link?.link || link?.href) {
      return (
        <SC.LinkA
          className={className}
          {...link}
          href={link?.link || link?.href}
          target={link?.target || '_self'}
          onClick={onClick}
          rel={link?.rel || 'noreferrer'}
          backgroundColor={backgroundColor}
          color={color}
        >
          {renderContent()}
        </SC.LinkA>
      )
    }

    return (
      <SC.MuiLink
        className={className}
        {...link}
        backgroundColor={backgroundColor}
        onClick={onClick}
      >
        {renderContent()}
      </SC.MuiLink>
    )
  }

  return <>{link?.link || link?.route ? renderAsLink() : renderDefault()}</>
}

export default BasicLink
