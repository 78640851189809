import dayjs from 'dayjs'

import { NotificationCardProps } from '../components/NotificationCard'
import { Notification, NotificationType, User, UserType } from '../generated/graphql'
import { Icons } from '../components/Icon/types'
import { router, routesPath } from '../router'

import { getCourseDate } from './courseTransformers'

export const notificationToCardProps = (
  notification: Notification,
  t: any,
  user?: User | null
): NotificationCardProps => {
  let payload: { amount?: number } = {}
  try {
    payload = JSON.parse(notification?.payload)
  } catch (e) {
    console.log('notificationToCardProps e', e)
  }

  // Parent / Student
  if (notification?.type === NotificationType.CourseConfirmed) {
    return {
      date: t('notification_date', {
        date: dayjs(notification?.created_at, 'YYYY-MM-DD HH:mm:ss').toDate(),
      }),
      title: t('notification_courseConfirmed'),
      ...(user?.user_type === UserType.Guardian && {
        title: t('notification_courseConfirmed_guardian', {
          name: notification?.student?.first_name,
        }),
      }),
      subtitle: t('notification_course_date', { date: getCourseDate(notification?.course) }),
      name: notification?.course?.teacher?.full_name ?? '',
      icon: Icons.checkBasic,
      legends: [
        notification?.course?.lesson?.theme?.discipline?.name ?? '',
        notification?.course?.lesson?.theme?.grade?.name ?? '',
      ],
      link: {
        href: router(routesPath.course, { id: notification?.course?.id ?? '1' }),
      },
      isRead: !!notification.read_at,
    }
  }
  if (notification?.type === NotificationType.CourseDeclined) {
    return {
      date: t('notification_date', {
        date: dayjs(notification?.created_at, 'YYYY-MM-DD HH:mm:ss').toDate(),
      }),
      title: t('notification_courseDeclined'),
      ...(user?.user_type === UserType.Guardian && {
        title: t('notification_courseDeclined_guardian', {
          name: notification?.student?.first_name,
        }),
      }),
      name: notification?.course?.teacher?.full_name ?? '',
      subtitle: t('notification_course_date', { date: getCourseDate(notification?.course) }),
      icon: Icons.warning,
      iconColor: '#FF5E4D',
      legends: [
        notification?.course?.lesson?.theme?.discipline?.name ?? '',
        notification?.course?.lesson?.theme?.grade?.name ?? '',
      ],
      link: {
        href: router(routesPath.course, { id: notification?.course?.id ?? '1' }),
      },
      isRead: !!notification.read_at,
      isImportant: true,
    }
  }
  if (notification?.type === NotificationType.CourseCancelled) {
    return {
      date: t('notification_date', {
        date: dayjs(notification?.created_at, 'YYYY-MM-DD HH:mm:ss').toDate(),
      }),
      title: t('notification_courseCancelled'),
      ...(user?.user_type === UserType.Guardian && {
        title: t('notification_courseCancelled_guardian', {
          name: notification?.student?.first_name,
        }),
      }),
      name: notification?.course?.teacher?.full_name ?? '',
      subtitle: t('notification_course_date', { date: getCourseDate(notification?.course) }),
      icon: Icons.warning,
      iconColor: '#FF5E4D',
      legends: [
        notification?.course?.lesson?.theme?.discipline?.name ?? '',
        notification?.course?.lesson?.theme?.grade?.name ?? '',
      ],
      link: {
        href: router(routesPath.course, { id: notification?.course?.id ?? '1' }),
      },
      isRead: !!notification.read_at,
      isImportant: true,
    }
  }
  if (notification?.type === NotificationType.CoursePaid) {
    return {
      date: t('notification_date', {
        date: dayjs(notification?.created_at, 'YYYY-MM-DD HH:mm:ss').toDate(),
      }),
      title: t('notification_coursePaid', {
        name: notification?.student?.first_name,
        amount: (payload?.amount ?? 0) / 100,
      }),
      subtitle: t('notification_course_date', { date: getCourseDate(notification?.course) }),
      icon: Icons.wallet,
      legends: [
        notification?.course?.lesson?.theme?.discipline?.name ?? '',
        notification?.course?.lesson?.theme?.grade?.name ?? '',
      ],
      link: {
        href: router(routesPath.pot),
      },
      isRead: !!notification.read_at,
    }
  }

  // Teacher
  if (notification?.type === NotificationType.PendingCourse) {
    return {
      date: t('notification_date', {
        date: dayjs(notification?.created_at, 'YYYY-MM-DD HH:mm:ss').toDate(),
      }),
      title: t('notification_courseRequest'),
      subtitle: t('notification_course_date', { date: getCourseDate(notification?.course) }),
      icon: Icons.calendar,
      legends: [
        notification?.course?.lesson?.theme?.discipline?.name ?? '',
        notification?.course?.lesson?.theme?.grade?.name ?? '',
      ],
      link: {
        href: router(routesPath.searchClassRequests),
      },
      isRead: !!notification.read_at,
    }
  }
  if (notification?.type === NotificationType.StudentReview) {
    return {
      date: t('notification_date', {
        date: dayjs(notification?.created_at, 'YYYY-MM-DD HH:mm:ss').toDate(),
      }),
      title: t('notification_courseEvaluateStudents', {
        date: getCourseDate(notification?.course),
      }),
      icon: Icons.star,
      legends: [
        notification?.course?.lesson?.theme?.discipline?.name ?? '',
        notification?.course?.lesson?.theme?.grade?.name ?? '',
      ],
      link: {
        href: router(routesPath.course, { id: notification?.course?.id ?? '1' }),
      },
      isRead: !!notification.read_at,
      isImportant: true,
    }
  }
  if (notification?.type === NotificationType.TransferExecuted) {
    return {
      date: t('notification_date', {
        date: dayjs(notification?.created_at, 'YYYY-MM-DD HH:mm:ss').toDate(),
      }),
      title: t('notification_transferExecuted', {
        amount: (payload?.amount ?? 0) / 100,
      }),
      subtitle: t('notification_transferExecuted_subtitle', {
        date: getCourseDate(notification?.course),
      }),
      icon: Icons.wallet,
      legends: [
        notification?.course?.lesson?.theme?.discipline?.name ?? '',
        notification?.course?.lesson?.theme?.grade?.name ?? '',
      ],
      link: {
        href: router(routesPath.pot),
      },
      isRead: !!notification.read_at,
      isImportant: true,
    }
  }

  // Common
  if (notification?.type === NotificationType.CourseReminder) {
    return {
      date: t('notification_date', {
        date: dayjs(notification?.created_at, 'YYYY-MM-DD HH:mm:ss').toDate(),
      }),
      title: t('notification_courseReminder', {
        date: getCourseDate(notification?.course),
      }),
      ...(user?.user_type === UserType.Guardian && {
        title: t('notification_courseReminder_guardian', {
          name: notification?.student?.first_name,
          date: getCourseDate(notification?.course),
        }),
      }),
      ...(user?.user_type !== UserType.Teacher && {
        name: notification?.course?.teacher?.full_name ?? '',
      }),
      icon: Icons.clock,
      legends: [
        notification?.course?.lesson?.theme?.discipline?.name ?? '',
        notification?.course?.lesson?.theme?.grade?.name ?? '',
      ],
      link: {
        href: router(routesPath.course, { id: notification?.course?.id ?? '1' }),
      },
      isRead: !!notification.read_at,
    }
  }

  return {
    date: '',
    title: '',
    icon: Icons.calendar,
  }
}
