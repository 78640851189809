import styled from 'styled-components'

import PageBackButton from '../../components/PageBackButton'

export const Content = styled.div`
  flex-grow: 1;
`
export const BackButton = styled(PageBackButton)``
export const Title = styled.h3`
  ${(props) => ({ ...props.theme.typography.h3 })}
  color: ${(props) => props.theme.palette.colors.midnightBlue};
  margin: 0rem 0 0.5rem 0;
`

export const Text = styled.p`
  ${(props) => ({ ...props.theme.typography.smallRegular })}
  color: ${(props) => props.theme.palette.colors.chambray};
  margin: 3.2rem 1.6rem;
  text-align: center;
`
