import styled from 'styled-components'

import LevelPicker from '../LevelPicker'
import FormSubmit from '../FormSubmit'

export const LevelPickersForm = styled.div``

export const Title = styled.h2`
  ${(props) => ({ ...props.theme.typography.h3 })}
  color: ${(props) => props.theme.palette.colors.midnightBlue};
`

export const Text = styled.p`
  ${(props) => ({ ...props.theme.typography.smallRegular })}
  color: ${(props) => props.theme.palette.colors.chambray};
`

export const Subjects = styled.div``

export const Subject = styled(LevelPicker)`
  margin-bottom: 1rem;
`

export const SubmitButton = styled(FormSubmit)`
  margin-top: 2rem;
`
