import React, { FC } from 'react'

import Header, { HeaderProps } from '../../components/Header'
import Menu, { MenuProps } from '../../components/Menu'
import AuthLayout from '../../layouts/AuthLayout'
import { PotProps } from '../../components/Pot'
import CardList from '../../components/CardList'
import { MoneyCardProps } from '../../components/MoneyCard'
import { FormSubmitProps } from '../../components/FormSubmit'
import PageBackButton, { PageBackButtonProps } from '../../components/PageBackButton'
import { WalletConfigurationProps } from '../../components/WalletConfiguration'

import * as SC from './styled'

export type PotTemplateProps = {
  className?: string
  headerProps: HeaderProps
  menuProps: MenuProps
  potProps: PotProps
  title: string
  filters: { label: string; value: string }[]
  filterValue: string
  onFilterChange: (value: string) => void
  cards?: MoneyCardProps[]
  buttonProps?: FormSubmitProps
  backButton?: PageBackButtonProps
  configurationProps?: WalletConfigurationProps
}

const PotTemplate: FC<PotTemplateProps> = (props) => {
  const {
    headerProps,
    menuProps,
    potProps,
    title,
    filters,
    onFilterChange,
    filterValue,
    cards,
    buttonProps,
    backButton,
    configurationProps,
  } = props

  const tabContent = {
    name: '',
    tabs: filters?.map((filter) => ({
      label: filter?.label,
      value: filter?.value,
      children: <CardList cards={cards} buttonProps={buttonProps} />,
    })),
    value: filterValue,
    onChange: (_name: string, val: string) => onFilterChange(val),
  }

  return (
    <AuthLayout header={<Header {...headerProps} withDivider />} menu={<Menu {...menuProps} />}>
      <SC.Content>
        {backButton && <PageBackButton {...backButton} />}
        <SC.Pot {...potProps} />
        {configurationProps && <SC.Configuration {...configurationProps} />}
        <SC.Title>{title}</SC.Title>
        <SC.TabContent {...tabContent} />
      </SC.Content>
    </AuthLayout>
  )
}

export default PotTemplate
