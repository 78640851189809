import styled from 'styled-components'

import PageTitle from '../../components/PageTitle'
import PageBackButton from '../../components/PageBackButton'
import { Text2 } from '../OldTemplates/SearchParent/stories/styled'
import CourseFormC from '../../components/CourseForm'

export const Content = styled.div`
  width: 100%;
`

export const Subtitle = styled.h6`
  ${(props) => ({ ...props.theme.typography.h6 })}
  color: ${(props) => props.theme.palette.colors.neonCarrot};
  padding: 0 1.6rem;
  margin: 2rem 0 0.4rem 0;
`

export const Title = styled(PageTitle)`
  color: ${(props) => props.theme.palette.colors.midnightBlue};
  padding-bottom: 0.4rem;
  margin-top: 0;
  padding-top: 0;
`

export const BackButton = styled(PageBackButton)``

export const Text3 = styled(Text2)`
  ${(props) => ({ ...props.theme.typography.big })}
  color: ${(props) => props.theme.palette.colors.chambray};
`

export const CourseForm = styled(CourseFormC)`
  margin: 2.4rem 1.6rem;
  max-width: 47rem;
`
