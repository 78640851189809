import React, { FC } from 'react'

import Header, { HeaderProps } from '../../components/Header'
import SignUpLayout from '../../layouts/SignupLayout'
import { AddChildrenProps } from '../../components/SignUpAddChildren'

import * as SC from './styled'

export type SignUpParentAddChildrenProps = {
  className?: string
  headerProps: HeaderProps
  addChildrenProps: AddChildrenProps
}

const SignUpParentAddChildren: FC<SignUpParentAddChildrenProps> = (props) => {
  const { headerProps, addChildrenProps } = props

  return (
    <SignUpLayout header={<Header {...headerProps} />}>
      <SC.AddChildrenForm {...addChildrenProps} />
    </SignUpLayout>
  )
}

export default SignUpParentAddChildren
