import dayjs from 'dayjs'

import { AvailabilitySlot } from '../../generated/graphql'

const slotHours: { [key: string]: number } = {
  [AvailabilitySlot.H8]: 8,
  [AvailabilitySlot.H9]: 9,
  [AvailabilitySlot.H10]: 10,
  [AvailabilitySlot.H11]: 11,
  [AvailabilitySlot.H12]: 12,
  [AvailabilitySlot.H13]: 13,
  [AvailabilitySlot.H14]: 14,
  [AvailabilitySlot.H15]: 15,
  [AvailabilitySlot.H16]: 16,
  [AvailabilitySlot.H17]: 17,
  [AvailabilitySlot.H18]: 18,
  [AvailabilitySlot.H19]: 19,
  [AvailabilitySlot.H20]: 20,
  [AvailabilitySlot.H21]: 21,
}

export const availabilitySlotOptions = (t: any) =>
  Object.values(AvailabilitySlot).map((slot) => ({
    value: slot,
    children: t(`availabilitySlot_${slot}`),
  }))

export const availabilitySlotJoin = (t: any, slots: AvailabilitySlot[]) =>
  slots.map((slot) => t(`availabilitySlot_${slot}`)).join(', ')

export const availabilitySlotTranslation = (t: any, slot: AvailabilitySlot) =>
  t(`availabilitySlot_${slot}`)

export const availabilitySlotTranslations = (t: any, slots?: AvailabilitySlot[]) =>
  slots?.map((slot) => availabilitySlotTranslation(t, slot))

export const availabilitySlotToHour = (slot?: AvailabilitySlot) => (slot ? slotHours[slot] : 0)
export const dateAndSlotToDate = (date: string, slot?: AvailabilitySlot) =>
  dayjs(date, 'YYYY-MM-DD').hour(availabilitySlotToHour(slot)).toDate()

export const hourToSlot = (hour: number): AvailabilitySlot | null =>
  (Object.keys(slotHours).find((v) => slotHours[v] === hour) as AvailabilitySlot) ?? null
