import styled from 'styled-components'

import PageTitle from '../../components/PageTitle'
import PageBackButton from '../../components/PageBackButton'
import ParentSideScoreCard from '../../components/ParentSideScoreCard'
import AddChildForm from '../../components/AddChildForm'

export const Content = styled.div``

export const Title = styled(PageTitle)`
  color: ${(props) => props.theme.palette.colors.midnightBlue};
`
export const BackButton = styled(PageBackButton)``
export const ScoreCard = styled(ParentSideScoreCard)`
  padding: 1.6rem 1.6rem 1.6rem 1.6rem;
  margin: 0.8rem;
  width: 100%;
  ${(props) => props.theme.breakpoints.up('md')} {
    max-width: 47rem;
  }
`
export const Form = styled(AddChildForm)`
  ${(props) => props.theme.breakpoints.up('md')} {
    max-width: 47rem;
  }
`
