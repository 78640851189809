import { Container, Tabs, Tab } from '@material-ui/core'
import styled from 'styled-components'

import ActionButtonC from '../ActionButton'

export const TabContent = styled.div``

export const Wrapper = styled(Container)``

export const TabsWrapper = styled(Tabs)`
  color: ${(props) => props.theme.palette.colors.cornflowerBlue};
  & [aria-selected='true'] {
    color: ${(props) => props.theme.palette.colors.freeSpeechBlue};
    border-color: ${(props) => props.theme.palette.colors.freeSpeechBlue};
  }
  & .MuiTabs-indicator {
    background-color: ${(props) => props.theme.palette.colors.freeSpeechBlue};
    bottom: 0.8rem;
  }
  & .MuiButtonBase-root {
    padding-left: 0;
    min-width: max-content;
    margin-right: 2.4rem;
    opacity: 1;
  }
`

export const TabWrapper = styled.div`
  position: relative;
`

export const MuiTab = styled(Tab)<{ count?: string }>`
  ${(props) => ({ ...props.theme.typography.h6 })};
  padding-right: ${(props) => (props.count === '' ? 0 : '0.8rem')};
  text-transform: capitalize;
  &::after {
    content: ${(props) => (props.count === '' ? 'none' : `"${props.count}"`)};
    position: absolute;
    top: 1rem;
    right: 0.1rem;
    font-size: 0.9rem;
  }
`

export const TabCount = styled.span``

export const TabPanels = styled.div`
  margin-top: 1rem;
`

export const Text = styled.p`
  ${(props) => ({ ...props.theme.typography.smallRegular })};
  color: ${(props) => props.theme.palette.colors.chambray};
  margin: 0 0 1rem 0;
`

export const ActionButton = styled(ActionButtonC)`
  margin-top: 2rem;
  width: 80%;
`
