import React, { FC } from 'react'

import { BasicLinkProps } from '../BasicLink'

import * as SC from './styled'

export type SubjectWithThemePickerProps = {
  className?: string
  title: string
  name: string
  isExpand?: boolean
  onHeaderClick: (name: string) => void
  themeTitle: string
  themeLink: BasicLinkProps
}

const SubjectWithThemePicker: FC<SubjectWithThemePickerProps> = (props) => {
  const { className, title, isExpand, onHeaderClick, themeTitle, themeLink, name } = props

  const handleHeaderClick = () => {
    onHeaderClick(name)
  }

  return (
    <SC.SubjectWithThemePicker className={className}>
      <SC.Wrapper maxWidth="xl" $isDeployed={isExpand}>
        <SC.Title onClick={handleHeaderClick} $isDeployed={isExpand}>
          {title}
        </SC.Title>
        {isExpand && (
          <SC.Content>
            <SC.ThemeTitle>{themeTitle}</SC.ThemeTitle>
            <SC.ThemeLink {...themeLink} />
          </SC.Content>
        )}
      </SC.Wrapper>
    </SC.SubjectWithThemePicker>
  )
}

export default SubjectWithThemePicker
