import styled from 'styled-components'

import BasicLink from '../../components/BasicLink'
import SearchRequestFormC from '../../components/SearchRequestForm'
import SearchSubjectFormC from '../../components/SearchSubjectForm'
import ThemePickerFormC from '../../components/ThemePickerForm'
import CourseFormC from '../../components/CourseForm'
import CardListC from '../../components/CardList'
import ActionButton from '../../components/ActionButton'
import TextField from '../../components/form/TextField'
import PageTitle from '../../components/PageTitle'
import ImageTextListC from '../../components/ImageTextList'

export const Content = styled.div`
  width: 100%;
`

export const Title = styled(PageTitle)`
  color: ${(props) => props.theme.palette.colors.midnightBlue};
`

export const ImageTextList = styled(ImageTextListC)`
  padding: 1.6rem;
  width: 100%;
  max-width: 1100px;
`

export const Title2 = styled.h3`
  ${(props) => ({ ...props.theme.typography.h3 })}
  color: ${(props) => props.theme.palette.colors.neonCarrot};
  padding: 3rem 1.6rem 0.2rem 1.6rem;
  margin: 0;
`

export const Title3 = styled.h4`
  ${(props) => ({ ...props.theme.typography.h4 })}
  color: ${(props) => props.theme.palette.colors.midnightBlue};
  padding: 1.6rem 1.6rem 2.1rem 1.6rem;
  margin: 0;
  ${(props) => props.theme.breakpoints.up('md')} {
    width: 50rem;
  }
`

export const Text = styled.p`
  ${(props) => ({ ...props.theme.typography.tinySmall })}
  color: ${(props) => props.theme.palette.colors.midnightBlue};
  padding: 1.6rem 1.6rem 0rem 1.6rem;
  margin: 0;
  max-width: 47rem;
`

export const Text2 = styled(Text)`
  padding: 0.4rem 1.6rem 0rem 1.6rem;
`

export const Text3 = styled(Text2)`
  ${(props) => ({ ...props.theme.typography.big })}
  color: ${(props) => props.theme.palette.colors.chambray};
`

export const Text4 = styled(Text)`
  ${(props) => ({ ...props.theme.typography.h4 })}
  color: ${(props) => props.theme.palette.colors.freeSpeechBlue};
`

export const Text5 = styled(Text)`
  color: ${(props) => props.theme.palette.colors.chambray};
  padding: 0 1.6rem;
`

export const Text6 = styled(Text)`
  ${(props) => ({ ...props.theme.typography.mediumRegular })}
  color: ${(props) => props.theme.palette.colors.grey};
  padding: 2.4rem 1.6rem;
`

export const ReturnCta = styled(BasicLink)`
  margin: 1rem 0 0rem 1.6rem;
  display: flex;
  ${(props) => ({ ...props.theme.typography.callToAction })}
  text-decoration: none;
  color: ${(props) => props.theme.palette.colors.freeSpeechBlue};
  cursor: pointer;
`

export const Subtitle = styled.h6`
  ${(props) => ({ ...props.theme.typography.h6 })}
  color: ${(props) => props.theme.palette.colors.neonCarrot};
  padding: 0 1.6rem;
  margin: 2rem 0 0.4rem 0;
`

export const SearchRequestForm = styled(SearchRequestFormC)`
  margin: 0 1.6rem;
`

export const SearchSubjectForm = styled(SearchSubjectFormC)`
  margin: 2.4rem 1.6rem;
`

export const SearchSubjectForm2 = styled(SearchSubjectFormC)`
  margin: 0rem 1.6rem;
  max-width: 47rem;
`

export const ThemePickerForm = styled(ThemePickerFormC)`
  margin: 2.4rem 1.6rem;
  max-width: 47rem;
`

export const CourseForm = styled(CourseFormC)`
  margin: 2.4rem 1.6rem;
  max-width: 47rem;
`

export const CardList = styled(CardListC)`
  margin: 1.6rem 1.6rem 0 1.6rem;
  max-width: 47rem;
`

export const ButtonBox = styled.div`
  margin: 3rem 1.6rem;
  max-width: 40rem;
`

export const Button = styled(ActionButton)`
  width: 100%;
`

export const Cta = styled(BasicLink)`
  margin: 0rem 0 3rem 1.6rem;
  display: flex;
  ${(props) => ({ ...props.theme.typography.smallBold })}
  text-decoration: none;
  color: ${(props) => props.theme.palette.colors.freeSpeechBlue};
  cursor: pointer;
`

export const BoxImage = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  ${(props) => props.theme.breakpoints.up('md')} {
    justify-content: flex-start;
  }
`

export const Image = styled.img`
  width: 30rem;
  height: auto;
  ${(props) => props.theme.breakpoints.up('md')} {
    width: 51rem;
  }
`

export const Select = styled(TextField)`
  padding: 0 1.6rem;
  ${(props) => props.theme.breakpoints.up('md')} {
    width: 43rem;
  }
`
