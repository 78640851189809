import React, { FC } from 'react'

import { ReportingFormProps } from '../../ReportingForm'

import * as SC from './styled'

export type ReportingModalProps = {
  className?: string
  headerText: string
  text?: string
  open: boolean
  formProps: ReportingFormProps
  onClose?: () => void
}

const ReportingModal: FC<ReportingModalProps> = (props) => {
  const { className, headerText, open, onClose, formProps, text } = props

  return (
    <SC.Modal open={open} onClose={onClose} className={className}>
      <SC.HeaderText>{headerText}</SC.HeaderText>
      <SC.Content>
        {text && <SC.Text>{text}</SC.Text>}
        <SC.Form {...formProps} />
      </SC.Content>
    </SC.Modal>
  )
}

export default ReportingModal
