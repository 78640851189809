import gql from 'graphql-tag'

import UserFragment from '../../../fragments/UserFragment'
import { User } from '../../../../generated/graphql'

const confirmSocialRegister = {
  mutation: gql`
    mutation confirmSocialRegister(
      $type: UserType!
      $firstName: String!
      $lastName: String!
      $email: String!
      $sponsor: String
    ) {
      confirmSocialRegister(
        user_type: $type
        first_name: $firstName
        last_name: $lastName
        email: $email
        sponsor_code: $sponsor
      ) {
        ...UserFragment
      }
    }
    ${UserFragment}
  `,
  transformer: (response: any): User => {
    return response.confirmSocialRegister
  },
}

export default confirmSocialRegister
