import dayjs from 'dayjs'

import { Course, CourseRequest, CourseStatus, Maybe, User, UserType } from '../generated/graphql'
import { courseTypeTranslation } from '../graphql/enums/CourseType'
import { CourseCardProps } from '../components/CourseCard'
import { dateAndSlotToDate } from '../graphql/enums/AvailabilitySlot'
import { router, routesPath } from '../router'
import { light } from '../theme/palette'
import { FinishedCourseMetaProps } from '../components/FinishedCourseMeta'
import { ImageTextItemProps } from '../components/ImageTextItem'
import { Icons } from '../components/Icon/types'
import { CourseCardInContextProps } from '../components/CourseCardInContext'
import { TeacherDashboardCoursesResult } from '../graphql/services/course/queries/teacherDashboardCourses'
import { DashboardCoursesResult } from '../graphql/services/course/queries/dashboardCourses'
import getTheme from '../theme'
import { CourseMaterialsCardProps } from '../components/CourseMaterialsCard'

export const getCourseDate = (course?: Course | null | Maybe<Course>) =>
  dateAndSlotToDate(course?.date, course?.slot ?? undefined)

export const getISODateString = (dateString?: string) => {
  return (dateString ? dayjs(dateString, 'YYYY-MM-DD') : dayjs())
    .hour(12)
    .minute(0)
    .second(0)
    .millisecond(0)
    .toISOString()
}

export const checkCourseEvaluationDoneByTeacher = (
  course?: Course | null | Maybe<Course>
): boolean =>
  (course?.studentReviews?.length !== undefined && course?.studentReviews?.length > 0) ?? false
export const checkCourseIsPast = (course?: Course | null | Maybe<Course>) => {
  const courseDate = getCourseDate(course)
  return courseDate && !dayjs(courseDate).add(1, 'hour').isAfter(dayjs())
}
export const checkCourseBegan = (course?: Course | null | Maybe<Course>) => {
  const courseDate = getCourseDate(course)
  return courseDate && dayjs(courseDate).isBefore(dayjs())
}
export const checkCourseReportable = (course?: Course | null | Maybe<Course>) => {
  return (
    checkCourseBegan(course) &&
    (course?.status === CourseStatus.Cancelled || course?.status === CourseStatus.Accepted)
  )
}
export const checkCourseReportableExpired = (course?: Course | null | Maybe<Course>) => {
  const courseDate = getCourseDate(course)
  return (
    checkCourseReportable(course) &&
    !(courseDate && dayjs(courseDate).add(48, 'hour').isAfter(dayjs()))
  )
}
export const checkCourseEvaluationDue = (
  course?: Course | null | Maybe<Course>,
  userType?: UserType | Maybe<UserType>
): boolean => {
  const isPast = checkCourseIsPast(course)
  const isEvaluationDone = checkCourseEvaluationDoneByTeacher(course)
  return (
    userType === UserType.Teacher &&
    isPast &&
    !isEvaluationDone &&
    course?.requests !== undefined &&
    course?.requests?.length !== undefined &&
    course?.requests?.length > 0
  )
}

export const getCourseStatusText = (course: Course | null | Maybe<Course>, t: any): string =>
  course?.status === CourseStatus.Accepted
    ? t('course_status_accepted')
    : course?.status === CourseStatus.Pending
    ? t('course_status_pending')
    : course?.status === CourseStatus.Cancelled
    ? t('course_status_cancelled')
    : ''
export const getCourseStatusColor = (course: Course | null | Maybe<Course>): string =>
  course?.status === CourseStatus.Accepted
    ? getTheme().palette.colors.cornflowerBlue
    : course?.status === CourseStatus.Pending
    ? getTheme().palette.colors.neonCarrot
    : course?.status === CourseStatus.Cancelled
    ? getTheme().palette.colors.tomato
    : ''
export const getCourseStatusIcon = (course: Course | null | Maybe<Course>): Icons | undefined =>
  course?.status === CourseStatus.Accepted
    ? Icons.check2
    : course?.status === CourseStatus.Pending
    ? Icons.pending
    : course?.status === CourseStatus.Cancelled
    ? Icons.warning
    : undefined

export const courseToCardProps = (course: Course, t: any, user?: User | null): CourseCardProps => {
  return {
    date: t('course_card_date', {
      date: dateAndSlotToDate(course?.date, course?.slot ?? undefined),
    }),
    subject: course?.lesson?.theme?.discipline?.name ?? '',
    level: course?.lesson?.theme?.grade?.name ?? '',
    description: course?.lesson?.name ?? '',
    type: courseTypeTranslation(t, course?.type),
    link: {
      href: router(routesPath.course, { id: course?.id ?? '' }),
    },
    ...(user?.user_type === UserType.Teacher && {
      price: t('course_card_price', { price: (course.teacher_price ?? 0) / 100 }),
    }),
    ...(user?.user_type !== UserType.Teacher && {
      teacherProfil: {
        name: course.lesson?.theme?.discipline?.name ?? '',
        level: course.teacher?.full_name ?? '',
        profilePicture: {
          alt: 'LiberteClasse - profil',
          src: course.teacher?.avatar ?? '/static/assets/images/frank.png',
        },
        nameColor: light.colors.midnightBlue,
        levelColor: light.colors.freeSpeechBlue,
      },
    }),
    ...(user?.user_type === UserType.Guardian &&
      user?.guardian?.children?.find((c) =>
        course.requests?.map((r) => r.student?.id).includes(c.id)
      ) && {
        forWho: t('course_card_for', {
          student: user?.guardian?.children?.find((c) =>
            course.requests?.map((r) => r.student?.id).includes(c.id)
          )?.first_name,
        }),
      }),
  }
}

export const courseToCarouselCardProps = (
  course: Course,
  t: any,
  user?: User | null
): CourseCardProps => {
  return {
    ...courseToCardProps(course, t, user),
    date: t('course_card_date', {
      date: dateAndSlotToDate(course.date, course?.slot ?? undefined),
    }),
    subject: course.lesson?.theme?.discipline?.name ?? '',
    level: course.lesson?.theme?.grade?.name,
    description: course.lesson?.name ?? '',
    type: undefined,
    bottomBadgeLabel: t('course_card_participants', { count: course?.participants ?? 0 }),
    bottomBadgeContent: `${course?.participants ?? 0} / ${course?.maximum_participants ?? 0}`,
  }
}

export const courseToFutureCardProps = (
  course: Course,
  t: any,
  user?: User | null,
  fixedPrice?: boolean
): CourseCardProps => {
  return {
    ...courseToCardProps(course, t, user),
    ...(user?.user_type !== UserType.Teacher && {
      price: t(
        fixedPrice || course?.maximum_participants === course?.participants + 1
          ? 'course_card_price'
          : 'course_card_max_price',
        {
          price: (course?.price_per_participant ?? course?.maximum_price ?? 0) / 100,
        }
      ),
    }),
    priceInFooter: true,
    date: t('course_card_date', {
      date: dateAndSlotToDate(course.date, course?.slot ?? undefined),
    }),
    subject: course.lesson?.theme?.discipline?.name ?? '',
    level: course.lesson?.theme?.grade?.name,
    description: course.lesson?.name ?? '',
    type: courseTypeTranslation(t, course.type),
    bottomBadgeLabel: t('course_card_participants', { count: course?.participants ?? 0 }),
    bottomBadgeContent: `${course?.participants ?? 0} / ${course?.maximum_participants ?? 0}`,
    ...(course?.status === CourseStatus.Accepted && {
      confirmedLabel: t('course_card_confirmed'),
    }),
  }
}

export const courseToPastCardProps = (
  course: Course,
  t: any,
  user?: User | null
): CourseCardProps => {
  const isEvaluationDue = checkCourseEvaluationDue(course, user?.user_type)

  return {
    ...courseToCardProps(course, t, user),
    priceInFooter: true,
    isPastCourse: true,
    numberOfStudentsText: t(
      (course?.participants ?? course?.requests?.length ?? 0) === 0
        ? 'course_card_party_empty'
        : 'course_card_party',
      {
        count: course?.participants ?? course?.requests?.length ?? 0,
      }
    ),
    ...(isEvaluationDue &&
      user?.user_type === UserType.Teacher && {
        evaluateCta: {
          text: t('course_card_evaluate_students_cta', {
            count: course?.participants ?? course?.requests?.length ?? 0,
          }),
        },
      }),
  }
}

export const courseToMetaProps = (course: Course, t: any): FinishedCourseMetaProps => {
  return {
    title: courseTypeTranslation(t, course?.type),
    //person: imageTextItemRatingArgs,
    //
    subTitle: t('course_card_date', {
      date: dateAndSlotToDate(course?.date, course?.slot ?? undefined),
    }),
    subject: course?.lesson?.theme?.discipline?.name ?? '',
    description: course
      ? [
          {
            title: t('course_details_lesson_title'),
            text: course?.lesson?.name ?? '',
          },
        ]
      : [],
    itemsTitle: t('course_details_files_title'),
    items:
      course?.lesson?.supports?.map(
        (support): ImageTextItemProps => ({
          text1: support.file_name,
          text3: support.human_readable_size ?? '',
          image: { src: '/static/assets/images/file.png', alt: 'Fichier' },
          isAvatar: true,
          largeIcon: Icons.download,
          link: {
            href: support.url ?? '',
            target: '_blank',
          },
        })
      ) ?? [],
  }
}

export const courseToTeacherRequestCardProps = (
  course: Course,
  t: any,
  user?: User | null
): CourseCardProps => ({
  date: t('course_card_date', {
    date: dateAndSlotToDate(course.date, course.slot ?? undefined),
  }),
  subject: course.lesson?.theme?.discipline?.name ?? '',
  level: course.lesson?.theme?.grade?.name,
  price: t('course_card_price', { price: (course?.teacher_price ?? 0) / 100 }),
  description: course.lesson?.name ?? '',
  type: courseTypeTranslation(t, course.type),
  priceInFooter: true,
  bottomBadgeLabel: t('course_card_participants', { count: course?.participants ?? 0 }),
  bottomBadgeContent: `${course?.participants ?? 0} / ${
    Math.min(user?.teacher?.maximum_participants ?? 4, course?.maximum_participants ?? 4) ?? 0
  }`,
  ...(course?.status === CourseStatus.Accepted && {
    confirmedLabel: t('course_card_confirmed'),
  }),
})

export const courseRequestToCardProps = (
  courseRequest: CourseRequest,
  t: any,
  user?: User | null
): CourseCardProps => ({
  subject: courseRequest.lesson?.theme?.discipline?.name ?? '',
  level: courseRequest.lesson?.theme?.grade?.name,
  description: courseRequest.lesson?.name ?? '',
  type: courseTypeTranslation(t, courseRequest.type),
  price: t('course_card_max_price', {
    price: courseRequest?.maximum_price / 100,
  }),
  priceInFooter: true,
  ...(user?.user_type === UserType.Guardian && {
    forWho: t('course_card_for', {
      student: user?.guardian?.children?.find((c) => courseRequest.student?.id === c.id)
        ?.first_name,
    }),
  }),
  link: {
    href: router(routesPath.courseRequest, { id: courseRequest.id }),
  },
})

export const dashboardCourseCards = (
  dashboard: TeacherDashboardCoursesResult | DashboardCoursesResult | null,
  t: any,
  user: User | null,
  child?: string
) => {
  const pendingCourseRequests = (dashboard as DashboardCoursesResult)?.pendingCourseRequests
  const pendingCourses = (dashboard as DashboardCoursesResult)?.pendingCourses
  const teacherPendingCourses = (dashboard as TeacherDashboardCoursesResult)?.teacherPending
  const pendingReviewCourses = (dashboard as TeacherDashboardCoursesResult)?.pendingReview

  const cards: CourseCardInContextProps[] = []
  if (dashboard?.today) {
    const isClassAvailable = dashboard?.today?.room?.available
    cards.push({
      cards: [courseToFutureCardProps(dashboard?.today, t, user)],
      title: t('dashboard_today_title'),
      backgroundColor: '#1D286A',
      ...(isClassAvailable && {
        virtualClassButton: {
          text: t('access_virtual_class'),
          link: {
            href: router(routesPath.room, {
              id: dashboard?.today?.id ?? 'room',
            }),
          },
        },
      }),
    })
  }

  if ((dashboard?.future?.total ?? 0) > 0 && (dashboard?.future?.data?.length ?? 0) > 0) {
    cards.push({
      cards:
        dashboard?.future?.data?.map(
          (c): CourseCardProps => ({ ...courseToFutureCardProps(c, t, user) })
        ) ?? [],
      title: t('dashboard_future_title'),
      planningCta: {
        text: t('see_all_count', { count: dashboard?.future?.total }),
        link: {
          link: router(routesPath.planning),
        },
        icon: Icons.longArrowRight,
        iconColor: light.colors.freeSpeechBlue,
        iconSide: 'right',
        backgroundColor: '#FFF',
      },
    })
  }

  // Student pending course (waiting for teacher approval)
  if ((pendingCourses?.total ?? 0) > 0 && (pendingCourses?.data?.length ?? 0) > 0) {
    cards.push({
      cards:
        pendingCourses?.data?.map(
          (c): CourseCardProps => ({ ...courseToFutureCardProps(c, t, user) })
        ) ?? [],
      title: t('dashboard_pending_title'),
      planningCta: {
        text: t('see_all_count', { count: pendingCourses?.total }),
        link: {
          link: router(routesPath.planningTab, { ...(child && { child }), tab: 'pending' }),
        },
        icon: Icons.longArrowRight,
        iconColor: light.colors.freeSpeechBlue,
        iconSide: 'right',
        backgroundColor: '#FFF',
      },
    })
  }

  // Student pending request
  if ((pendingCourseRequests?.total ?? 0) > 0 && (pendingCourseRequests?.data?.length ?? 0) > 0) {
    cards.push({
      cards:
        pendingCourseRequests?.data?.map(
          (c): CourseCardProps => ({ ...courseRequestToCardProps(c, t, user) })
        ) ?? [],
      title: t('dashboard_pendingRequests_title'),
      planningCta: {
        text: t('see_all_count', { count: pendingCourseRequests?.total }),
        link: {
          link: router(routesPath.planningTab, {
            ...(child && { child }),
            tab: 'requests',
          }),
        },
        icon: Icons.longArrowRight,
        iconColor: light.colors.freeSpeechBlue,
        iconSide: 'right',
        backgroundColor: '#FFF',
      },
    })
  }

  //
  if ((teacherPendingCourses?.total ?? 0) > 0 && (teacherPendingCourses?.data?.length ?? 0) > 0) {
    cards.push({
      cards:
        teacherPendingCourses?.data?.map(
          (c): CourseCardProps => ({
            ...courseToTeacherRequestCardProps(c, t, user),
            link: {
              href: router(routesPath.searchClassRequests),
            },
            isNew: true,
          })
        ) ?? [],
      title: t('dashboard_pendingRequests_title'),
      planningCta: {
        text: t('see_all_count', { count: teacherPendingCourses?.total }),
        link: {
          link: router(routesPath.searchClassRequests),
        },
        icon: Icons.longArrowRight,
        iconColor: light.colors.freeSpeechBlue,
        iconSide: 'right',
        backgroundColor: '#FFF',
      },
    })
  }

  if ((pendingReviewCourses?.total ?? 0) > 0) {
    cards.push({
      id: 'pending-review', // anchor
      cards:
        pendingReviewCourses?.data?.map(
          (c): CourseCardProps => courseToPastCardProps(c, t, user)
        ) ?? [],
      title: t('dashboard_pendingReview_title'),
      text: t('dashboard_pendingReview_text'),
      planningCta: {
        text: t('see_all_count', { count: pendingReviewCourses?.total }),
        link: {
          link: router(routesPath.planningReview),
        },
        icon: Icons.longArrowRight,
        iconColor: light.colors.freeSpeechBlue,
        iconSide: 'right',
        backgroundColor: '#FFF',
      },
    })
  }

  if ((dashboard?.past?.total ?? 0) > 0) {
    cards.push({
      cards:
        dashboard?.past?.data?.map((c): CourseCardProps => courseToPastCardProps(c, t, user)) ?? [],
      title: t('dashboard_past_title'),
      historyCta: {
        text: t('dashboard_history'),
        link: {
          link: router(routesPath.planningTab, { ...(child && { child }), tab: 'past' }),
        },
        icon: Icons.book2,
        iconSide: 'left',
        backgroundColor: '#FFF',
      },
      ...(user?.user_type === UserType.Teacher && {
        text: '',
        historyCta: {
          text: t('dashboard_history'),
          link: {
            link: router(routesPath.planningHistory),
          },
          icon: Icons.book2,
          iconSide: 'left',
          backgroundColor: '#FFF',
        },
      }),
    })
  }

  return cards
}

export const courseToMaterialCardProps = (course: Course, t: any): CourseMaterialsCardProps => {
  return {
    date: t('course_card_date', {
      date: dateAndSlotToDate(course?.date, course?.slot ?? undefined),
    }),
    description: course?.lesson?.name ?? '',
    teacherProfil: {
      name: course.lesson?.theme?.discipline?.name ?? '',
      level: course.teacher?.full_name ?? '',
      profilePicture: {
        alt: 'LiberteClasse - profil',
        src: course.teacher?.avatar ?? '/static/assets/images/frank.png',
      },
      nameColor: light.colors.midnightBlue,
      levelColor: light.colors.freeSpeechBlue,
    },
    documents: {
      items:
        course?.lesson?.supports?.map(
          (support): ImageTextItemProps => ({
            text1: support.file_name,
            text3: support.human_readable_size ?? '',
            image: { src: '/static/assets/images/file.png', alt: 'Fichier' },
            isAvatar: true,
            largeIcon: Icons.download,
            link: {
              href: support.url ?? '',
              target: '_blank',
            },
          })
        ) ?? [],
    },
  }
}
