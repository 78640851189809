import dayjs from 'dayjs'

import { AvailabilitySlot, TeacherAvailability } from '../../../../generated/graphql'
import { TeacherAvailabilityDay } from '../../../types/TeacherAvailabilityDay'

export const teacherAvailabilityDateToISOString = (date: string) =>
  dayjs(date, 'YYYY-MM-DD').hour(12).minute(0).second(0).millisecond(0).toISOString()

export const teacherAvailabilityTransform = (availabilities: TeacherAvailability[]) => {
  const reduced =
    availabilities?.reduce((arr, availability) => {
      const dateString = teacherAvailabilityDateToISOString(availability.date)
      if (!arr?.[dateString]) {
        arr[dateString] = {
          date: dateString,
          slots: [] as AvailabilitySlot[],
          isRecurring: !!availability.recurrence?.id,
        }
      }
      arr[dateString].slots.push(availability.slot)

      return arr
    }, {} as { [key: string]: TeacherAvailabilityDay }) ?? {}

  return Object.values(reduced)
}
