import styled from 'styled-components'
import { Container } from '@material-ui/core'

import ImageTextList from '../ImageTextList'
import FormSubmit from '../FormSubmit'
import ImageTextItem from '../ImageTextItem'
import SubjectWithThemePickerC from '../SubjectWithThemePicker'
import ToggleButtonGroup from '../form/ToggleButtonGroup'

export const SearchSubjectForm = styled.div`
  display: flex;
  justify-content: center;
  ${(props) => props.theme.breakpoints.up('md')} {
    justify-content: flex-start;
  }
`

export const Wrapper = styled(Container)`
  ${(props) => props.theme.breakpoints.up('md')} {
    margin: 0;
    width: 70%;
  }
`

export const List = styled(ImageTextList)``

export const Field = styled.div``

export const RequestForLabel = styled.p`
  color: ${(props) => props.theme.palette.colors.midnightBlue};
  ${(props) => ({ ...props.theme.typography.h4 })}
  margin: 0 0 0.3rem 0;
`

export const RequestFor = styled(ImageTextItem)`
  margin-bottom: 2.4rem;
`

export const RequestSubjectLabel = styled.p`
  color: ${(props) => props.theme.palette.colors.midnightBlue};
  ${(props) => ({ ...props.theme.typography.h4 })}
  margin: 0 0 1.6rem 0;
`

export const SubjectWithThemePicker = styled(SubjectWithThemePickerC)`
  margin: 0 0 1.6rem 0;
`

export const TypeOfRequest = styled(ToggleButtonGroup)`
  margin: 0 0 1rem 0;
  & .MuiToggleButton-root {
    padding: 1rem 1.2rem;
  }
`

export const SubmitButton = styled(FormSubmit)`
  margin: 0 0 2rem 0;
  max-width: 47rem;
`
