import gql from 'graphql-tag'

import { Course } from '../../../../generated/graphql'
import CourseFragment from '../../../fragments/CourseFragment'

const evaluateStudents = {
  mutation: gql`
    mutation evaluateStudents($course: ID!, $evaluations: [StudentEvaluationInput!]!) {
      evaluateStudents(course: $course, evaluations: $evaluations) {
        ...CourseFragment
      }
    }
    ${CourseFragment}
  `,
  transformer: (response: any): Course => {
    return response.evaluateStudents
  },
}

export default evaluateStudents
