import gql from 'graphql-tag'

import { TeacherAvailabilityRecurrence } from '../../../../generated/graphql'
import TeacherAvailabilityRecurrenceFragment from '../../../fragments/TeacherAvailabilityRecurrenceFragment'

const teacherAvailabilitiesOfMonth = {
  query: gql`
    query teacherRecurrences {
      teacherRecurrences(first: 500) {
        data {
          ...TeacherAvailabilityRecurrenceFragment
        }
      }
    }
    ${TeacherAvailabilityRecurrenceFragment}
  `,
  transformer: (response: {
    teacherRecurrences: { data: TeacherAvailabilityRecurrence[] }
  }): TeacherAvailabilityRecurrence[] => {
    return response.teacherRecurrences?.data
  },
}

export default teacherAvailabilitiesOfMonth
