import React, { FC } from 'react'
import { Formik, FormikHelpers } from 'formik'
import * as Yup from 'yup'

import { FormSubmitProps } from '../FormSubmit'
import { FormFieldConfig, FormFieldProps } from '../../types/form'
import FormSubmitErrors, { FormSubmitErrorsProps } from '../FormSubmitErrors'
import FormScrollToError from '../FormScrollToError'
import { getValidationSchema, renderField } from '../../helpers/FormHelpers'

import * as SC from './styled'

export type AvailabilityRecurrenceFormValues = {
  start: string
  end: string
  days: string[]
  hours: string[]
}

export type AvailabilityRecurrenceFormProps = {
  className?: string
  rangeTitle: string
  submitButton: FormSubmitProps
  fieldsProps?: {
    days: FormFieldProps
    start: FormFieldProps
    end: FormFieldProps
    hours: FormFieldProps
  }
  initialValues: AvailabilityRecurrenceFormValues
  errorTexts?: {
    required: string
    min: string
  }
  submitErrors?: FormSubmitErrorsProps['errors']
  onSubmit?: (
    values: AvailabilityRecurrenceFormValues,
    formikHelpers: FormikHelpers<AvailabilityRecurrenceFormValues>
  ) => void
}

const AvailabilityRecurrenceForm: FC<AvailabilityRecurrenceFormProps> = (props) => {
  const {
    className,
    rangeTitle,
    errorTexts,
    submitErrors,
    submitButton,
    initialValues,
    fieldsProps,
    onSubmit = (_values, _helpers) => null,
  } = props

  const start: FormFieldConfig = {
    name: 'start',
    Component: SC.RangeField,
    validation: Yup.string().required(errorTexts?.required),
    required: true,
    isLabelExternal: true,
  }
  const end: FormFieldConfig = {
    name: 'end',
    Component: SC.RangeField,
    validation: Yup.string().required(errorTexts?.required),
    required: true,
    isLabelExternal: true,
  }
  const days: FormFieldConfig = {
    name: 'days',
    Component: SC.ToggleButtonPickerDays,
    validation: Yup.array().min(1, errorTexts?.min).required(errorTexts?.required),
    required: true,
  }
  const hours: FormFieldConfig = {
    name: 'hours',
    Component: SC.ToggleButtonPickerHours,
    validation: Yup.array().min(1, errorTexts?.min).required(errorTexts?.required),
    required: true,
  }
  const fields = [start, end, days, hours]
  const validationSchema = getValidationSchema(fields)

  return (
    <SC.AvailabilityRecurrenceForm className={className}>
      <SC.Wrapper disableGutters maxWidth={false}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnChange={true}
          validateOnBlur={true}
          enableReinitialize
          onSubmit={onSubmit}
        >
          {(formikProps) => (
            <SC.FormikForm noValidate>
              <FormScrollToError formikProps={formikProps} />
              <SC.RangeTitle>{rangeTitle}</SC.RangeTitle>
              {renderField(start, formikProps, fieldsProps, 0, (Component, p) => (
                <Component {...p} maxDate={formikProps.values?.end || undefined} />
              ))}
              {renderField(end, formikProps, fieldsProps, 1, (Component, p) => (
                <Component {...p} minDate={formikProps.values?.start || undefined} />
              ))}
              {renderField(days, formikProps, fieldsProps, 2)}
              {renderField(hours, formikProps, fieldsProps, 3)}
              <FormSubmitErrors errors={submitErrors} />
              <SC.Button>
                <SC.SubmitButton {...submitButton} isDisabled={!formikProps.dirty} />
              </SC.Button>
            </SC.FormikForm>
          )}
        </Formik>
      </SC.Wrapper>
    </SC.AvailabilityRecurrenceForm>
  )
}

export default AvailabilityRecurrenceForm
