import { Container } from '@material-ui/core'
import styled from 'styled-components'

import TextFieldC from '../form/TextField'
import ToggleButtonGroupC from '../form/ToggleButtonGroup'
import FormSubmit from '../FormSubmit'

export const SignUpForm = styled.div`
  padding: 0 1.6rem;
`

export const Wrapper = styled(Container)`
  padding: 1rem;
  ${(props) => props.theme.breakpoints.up('md')} {
    padding: 5rem 15rem;
    display: flex;
    flex-direction: row;
  }
`

export const Content = styled.div`
  width: 100%;
  ${(props) => props.theme.breakpoints.up('md')} {
    max-width: 40rem;
  }
`

export const Title = styled.h1`
  ${(props) => ({ ...props.theme.typography.h1 })}
  color: ${(props) => props.theme.palette.colors.midnightBlue};
  font-size: 3.2rem;
  margin: 0 0 2rem 0;
  ${(props) => props.theme.breakpoints.up('md')} {
    font-size: 4rem;
  }
`

export const Text = styled.p`
  ${(props) => ({ ...props.theme.typography.h6 })}
  color: ${(props) => props.theme.palette.colors.midnightBlue};
  margin: 0 0 1rem 0;
`

export const MuiToggleButtonGroup = styled(ToggleButtonGroupC)``

export const Fields = styled.div`
  margin-top: 2rem;
`

export const MuiTextField = styled(TextFieldC)`
  margin-bottom: 1rem;
  & .MuiFormHelperText-contained {
    text-align: left;
  }
`

export const SubmitButton = styled(FormSubmit)`
  margin-top: 2rem;
`

export const MandatoryText = styled.p`
  ${(props) => ({ ...props.theme.typography.smallRegular })}
  color: ${(props) => props.theme.palette.colors.grey};
  margin: 1rem 0;
`
