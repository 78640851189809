import { Divider } from '@material-ui/core'
import styled from 'styled-components'

import BasicLink from '../../components/BasicLink'
import RatingModalities from '../../components/RatingModalities'

export const ParentSideScoreCard = styled.section`
  background: ${(props) => props.theme.palette.colors.pureWhite};
  border-radius: 8px;
`

export const ParentSideScoreCardContent = styled.div``

export const ScoreCard = styled(RatingModalities)`
  padding-bottom: 1.6rem;
`

export const Action = styled(BasicLink)`
  ${(props) => ({ ...props.theme.typography.smallStrong })}
  color: ${(props) => props.theme.palette.colors.freeSpeechBlue};
  text-transform: uppercase;
  display: flex;
  align-items: center;
  text-decoration: none;
`

export const Action2 = styled(Action)`
  color: ${(props) => props.theme.palette.colors.freeSpeechBlue};
`

export const Action3 = styled(Action)`
  color: ${(props) => props.theme.palette.colors.neonCarrot};
`

export const Muidivider = styled(Divider)`
  width: 100%;
  margin: 1.6rem 0;
`
