import { Container } from '@material-ui/core'
import styled from 'styled-components'

import BasicLink from '../BasicLink'

export const SubjectWithThemePicker = styled.div``

export const Wrapper = styled(Container)<{ $isDeployed?: boolean }>`
  cursor: pointer;
  border: 1px solid
    ${(props) =>
      props.$isDeployed
        ? props.theme.palette.colors.cornflowerBlue
        : props.theme.palette.colors.gainsboro};
  border-radius: 0.8rem;
`

export const Title = styled.h2<{ $isDeployed?: boolean }>`
  width: 100%;
  color: ${(props) =>
    props.$isDeployed
      ? props.theme.palette.colors.cornflowerBlue
      : props.theme.palette.colors.freeSpeechBlue};
  ${(props) =>
    props.$isDeployed ? { ...props.theme.typography.h3 } : { ...props.theme.typography.h4 }}
  transition: all 0.2s ease-in-out;
`

export const Content = styled.div``

export const ThemeTitle = styled.h5`
  ${(props) => ({ ...props.theme.typography.h5 })}
  color: ${(props) => props.theme.palette.colors.freeSpeechBlue};
  margin: 1.6rem 0 0.2rem 0;
`

export const ThemeLink = styled(BasicLink)`
  width: 100%;
  padding: 1.2rem 1.6rem;
  border: 1px solid ${(props) => props.theme.palette.colors.freeSpeechBlue};
  border-radius: 0.5rem;
  color: ${(props) => props.theme.palette.colors.freeSpeechBlue};
  ${(props) => ({ ...props.theme.typography.h5 })}
  margin: 0 0 1.6rem 0;
  &:hover {
    text-decoration: none;
  }
  & svg {
    margin-left: 15rem;
  }
  ${(props) => props.theme.breakpoints.up('md')} {
    & svg {
      margin-left: 13rem;
    }
  }
`
