import styled from 'styled-components'
import { IconButton } from '@material-ui/core'

import Icon from '../../Icon'
import NotificationCard from '../../NotificationCard'

export const Modal = styled.div`
  position: fixed;
  z-index: 1300;
  inset: 0px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
`

export const Background = styled.div`
  z-index: -1;
  position: fixed;
  inset: 0px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1.6rem;
  display: flex;
  width: 100%;
  flex-grow: 1;
  flex-direction: column;
  position: relative;
  background-color: ${(props) => props.theme.palette.colors.whiteSmoke};
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.12);
  border: 1px solid ${(props) => props.theme.palette.colors.ghostWhite};
  border-radius: 0.8rem;
  ${(props) => props.theme.breakpoints.up('md')} {
    margin: 5rem;
    width: 60rem;
  }
  outline: 0;
`

export const Head = styled.div`
  display: flex;
  align-items: flex-start;
  margin: 0 0 0;
  width: 100%;
`

export const Title = styled.h3`
  ${(props) => ({ ...props.theme.typography.h3 })}
  color: ${(props) => props.theme.palette.colors.neonCarrot};
  margin: 0;
  padding: 0;
  align-self: flex-start;
  flex-grow: 1;
  word-break: break-word;
`

export const CloseButton = styled(IconButton)`
  margin: -0.5rem -0.5rem 0 0;
`
export const Close = styled(Icon)`
  width: 3rem;
  height: 3rem;
  color: ${(props) => props.theme.palette.colors.freeSpeechBlue} !important;
`

export const Cards = styled.div`
  width: 100%;
`
export const Card = styled(NotificationCard)`
  &:not(:last-of-type) {
    padding-bottom: 0;
    &::after {
      content: '';
      display: flex;
      padding-top: 1.6rem;
      border-bottom: 1px solid ${(props) => props.theme.palette.colors.gainsboro};
    }
  }
`
