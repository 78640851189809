import React, { FC } from 'react'

import Header, { HeaderProps } from '../../components/Header'
import SignUpLayout from '../../layouts/SignupLayout'
import { SignUpAttachParentProps } from '../../components/SignUpAttachParent'

import * as SC from './styled'

export type SignUpStudentAttachToParentProps = {
  className?: string
  headerProps: HeaderProps
  signupProps: SignUpAttachParentProps
}

const SignUpStudentAttachToParent: FC<SignUpStudentAttachToParentProps> = (props) => {
  const { headerProps, signupProps } = props

  return (
    <SignUpLayout header={<Header {...headerProps} />}>
      <SC.SignUpAttachParentC {...signupProps} />
    </SignUpLayout>
  )
}

export default SignUpStudentAttachToParent
