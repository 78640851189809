import React from 'react'
import { Layout } from 'react-feather'

import AuthGuard from '../guards/AuthGuard'
import GuestGuard from '../guards/GuestGuard'
import RegisterGuard from '../guards/RegisterGuard'
import SignUpPage from '../pages/SignUp'
import SignInPage from '../pages/SignIn'
import SignInResetPage from '../pages/SignInReset'
import SignInForgotPage from '../pages/SignInForgot'
import SignUpPersonalInfosPage from '../pages/SignUpPersonalInfos'
import SignUpTeacherDisciplinesPage from '../pages/SignUpTeacherDisciplines'
import SignUpProfessionalInfosPage from '../pages/SignUpProfessionalInfos'
import SignUpConfirmPage from '../pages/SignUpConfirm'
import SignUpStandByPage from '../pages/SignUpStandBy'
import SignUpTeacherPricingPage from '../pages/SignUpTeacherPricing'
import SignUpTeacherPaymentsPage from '../pages/SignUpTeacherPayments'
import SignOutPage from '../pages/SignOut'
import SignUpSocialPage from '../pages/SignUpSocial'
import SignUpTeacherPendingPage from '../pages/SignUpTeacherPending'
import SignUpGuardianAddChildPage from '../pages/SignUpGuardianAddChild'
import RoomPage from '../pages/Room'
import { app } from '../configuration'
import DashboardPage from '../pages/Dashboard'
import AccountPage from '../pages/Account'
import PlanningPage from '../pages/Planning'
import PotPage from '../pages/Pot'
import PreferencesPage from '../pages/Preferences'
import PreferencesAvailabilitiesPage from '../pages/PreferencesAvailabilities'
import PreferencesAvailabilitiesRecurrenceFormPage from '../pages/PreferencesAvailabilitiesRecurrenceForm'
import PreferencesWalletPage from '../pages/PreferencesWallet'
import PreferencesWalletDepositPage from '../pages/PreferencesWalletDeposit'
import SearchPage from '../pages/Search'
import SearchDisciplinePage from '../pages/SearchDiscipline'
import SearchThemePage from '../pages/SearchTheme'
import SearchPricePage from '../pages/SearchPrice'
import SearchAvailabilitiesPage from '../pages/SearchAvailabilities'
import SearchResultsPage from '../pages/SearchResults'
import SearchClassRequestsPage from '../pages/SearchClassRequests'
import SignUpChildAddGuardianPage from '../pages/SignUpChildAddGuardian'
import CoursePage from '../pages/Course'
import PreferencesStudentsPage from '../pages/PreferencesStudents'
import PlanningFuturePage from '../pages/PlanningFuture'
import PreferencesDisciplinesPage from '../pages/PreferencesDisciplines'
import PreferencesPricingPage from '../pages/PreferencesPricing'
import PreferencesFamilyPage from '../pages/PreferencesFamily'
import PreferencesFamilyChildPage from '../pages/PreferencesFamilyChild'
import DashboardChildPage from '../pages/DashboardChild'
import PlanningReviewPage from '../pages/PlanningReview'
import PlanningHistoryPage from '../pages/PlanningHistory'
import SearchMarketPage from '../pages/SearchMarket'
import SearchMarketSlotsPage from '../pages/SearchMarketSlots'
import SearchMarketResultsPage from '../pages/SearchMarketResults'
import CourseRequestPage from '../pages/CourseRequest'
import PreferencesRatingPage from '../pages/PreferencesRating'
import PreferencesWalletWithdrawalPage from '../pages/PreferencesWalletWithdrawal'
import PreferencesCourseMaterialsPage from '../pages/PreferencesCourseMaterials'
import SearchTeacherDisciplinePage from '../pages/SearchTeacherDiscipline'
import SearchTeacherThemePage from '../pages/SearchTeacherTheme'
import SearchTeacherResultsPage from '../pages/SearchTeacherResults'
import TeacherPage from '../pages/Teacher'

import { routesPath } from './index'

const guestRoutes = {
  id: 'Guest',
  path: '',
  icon: <Layout />,
  component: null,
  guard: GuestGuard,
  children: [
    {
      path: routesPath.signUp,
      name: 'SignUp',
      component: SignUpPage,
    },
    {
      path: routesPath.signIn,
      name: 'SignIn',
      component: SignInPage,
    },
    {
      path: routesPath.signInForgot,
      name: 'SignInForgot',
      component: SignInForgotPage,
    },
    {
      path: routesPath.signInReset,
      name: 'SignInReset',
      component: SignInResetPage,
    },
  ],
}

export const guestLayoutRoutes = [guestRoutes]

const registerRoutes = {
  id: 'Register',
  path: '',
  icon: <Layout />,
  component: null,
  guard: RegisterGuard,
  children: [
    {
      path: routesPath.signUpStandby,
      name: 'SignUpStandBy',
      component: SignUpStandByPage,
    },
    {
      path: routesPath.signUpPersonalInfos,
      name: 'SignUpPersonalInfos',
      component: SignUpPersonalInfosPage,
    },
    {
      path: routesPath.signUpTeacherDisciplines,
      name: 'SignUpTeacherDisciplines',
      component: SignUpTeacherDisciplinesPage,
    },
    {
      path: routesPath.signUpTeacherPro,
      name: 'SignUpTeacherPro',
      component: SignUpProfessionalInfosPage,
    },
    {
      path: routesPath.signUpTeacherPending,
      name: 'SignUpTeacherPending',
      component: SignUpTeacherPendingPage,
    },
    {
      path: routesPath.signUpConfirm,
      name: 'SignUpConfirm',
      component: SignUpConfirmPage,
    },
    {
      path: routesPath.signUpTeacherPricing,
      name: 'SignUpTeacherPricing',
      component: SignUpTeacherPricingPage,
    },
    {
      path: routesPath.signUpTeacherPayments,
      name: 'SignUpTeacherPayments',
      component: SignUpTeacherPaymentsPage,
    },
    {
      path: routesPath.signUpSocial,
      name: 'SignUpSocial',
      component: SignUpSocialPage,
    },
    {
      path: routesPath.signUpGuardianAddChild,
      name: 'signUpGuardianAddChild',
      component: SignUpGuardianAddChildPage,
    },
    {
      path: routesPath.signUpChildAddGuardian,
      name: 'signUpChildAddGuardian',
      component: SignUpChildAddGuardianPage,
    },
  ],
}

export const registerLayoutRoutes = [registerRoutes]

const authRoutes = {
  id: 'Auth',
  path: '',
  icon: <Layout />,
  component: null,
  guard: AuthGuard,
  children: [
    {
      path: routesPath.account,
      name: 'Account',
      component: AccountPage,
    },
    {
      path: routesPath.course,
      name: 'Course',
      component: CoursePage,
    },
    {
      path: routesPath.courseRequest,
      name: 'CourseRequest',
      component: CourseRequestPage,
    },
    {
      path: routesPath.dashboard,
      name: 'Dashboard',
      component: app.PRE_REGISTER ? SignUpStandByPage : DashboardPage,
    },
    {
      path: routesPath.dashboardChild,
      name: 'DashboardChild',
      component: DashboardChildPage,
    },
    {
      path: routesPath.planningFuture,
      name: 'PlanningList',
      component: PlanningFuturePage,
    },
    {
      path: routesPath.planningHistory,
      name: 'PlanningHistory',
      component: PlanningHistoryPage,
    },
    {
      path: routesPath.planningReview,
      name: 'PlanningReview',
      component: PlanningReviewPage,
    },
    {
      path: routesPath.planning,
      name: 'Planning',
      component: PlanningPage,
    },
    {
      path: routesPath.planningTab,
      name: 'PlanningTab',
      component: PlanningPage,
    },
    {
      path: routesPath.pot,
      name: 'Pot',
      component: PotPage,
    },
    {
      path: routesPath.preferences,
      name: 'Preferences',
      component: PreferencesPage,
    },
    {
      path: routesPath.preferencesAvailabilities,
      name: 'PreferencesAvailabilities',
      component: PreferencesAvailabilitiesPage,
    },
    {
      path: routesPath.preferencesAvailabilitiesRecurrent,
      name: 'PreferencesAvailabilities',
      component: PreferencesAvailabilitiesPage,
    },
    {
      path: routesPath.preferencesAvailabilitiesRecurrentAdd,
      name: 'PreferencesAvailabilitiesRecurrentAdd',
      component: PreferencesAvailabilitiesRecurrenceFormPage,
    },
    {
      path: routesPath.preferencesAvailabilitiesRecurrentEdit,
      name: 'PreferencesAvailabilitiesRecurrentEdit',
      component: PreferencesAvailabilitiesRecurrenceFormPage,
    },
    {
      path: routesPath.preferencesCourseMaterials,
      name: 'PreferencesCourseMaterials',
      component: PreferencesCourseMaterialsPage,
    },
    {
      path: routesPath.preferencesDisciplines,
      name: 'PreferencesDisciplines',
      component: PreferencesDisciplinesPage,
    },
    {
      path: routesPath.preferencesFamily,
      name: 'PreferencesFamily',
      component: PreferencesFamilyPage,
    },
    {
      path: routesPath.preferencesFamilyAddChild,
      name: 'PreferencesFamilyChild',
      component: PreferencesFamilyChildPage,
    },
    {
      path: routesPath.preferencesFamilyChild,
      name: 'PreferencesFamilyChild',
      component: PreferencesFamilyChildPage,
    },
    {
      path: routesPath.preferencesRating,
      name: 'PreferencesRating',
      component: PreferencesRatingPage,
    },
    {
      path: routesPath.preferencesPricing,
      name: 'PreferencesPricing',
      component: PreferencesPricingPage,
    },
    {
      path: routesPath.preferencesWallet,
      name: 'PreferencesWallet',
      component: PreferencesWalletPage,
    },
    {
      path: routesPath.preferencesWalletDeposit,
      name: 'PreferencesWalletDeposit',
      component: PreferencesWalletDepositPage,
    },
    {
      path: routesPath.preferencesWalletWithdrawal,
      name: 'PreferencesWalletWithdrawal',
      component: PreferencesWalletWithdrawalPage,
    },
    {
      path: routesPath.preferencesStudents,
      name: 'PreferencesStudents',
      component: PreferencesStudentsPage,
    },
    {
      path: routesPath.search,
      name: 'Search',
      component: SearchPage,
    },
    {
      path: routesPath.searchDiscipline,
      name: 'SearchDiscipline',
      component: SearchDisciplinePage,
    },
    {
      path: routesPath.searchTheme,
      name: 'SearchTheme',
      component: SearchThemePage,
    },
    {
      path: routesPath.searchPrice,
      name: 'SearchPrice',
      component: SearchPricePage,
    },
    {
      path: routesPath.searchAvailabilities,
      name: 'SearchAvailabilities',
      component: SearchAvailabilitiesPage,
    },
    {
      path: routesPath.searchResults,
      name: 'SearchResults',
      component: SearchResultsPage,
    },
    {
      path: routesPath.searchClassRequests,
      name: 'SearchClassRequests',
      component: SearchClassRequestsPage,
    },
    {
      path: routesPath.searchMarket,
      name: 'SearchMarket',
      component: SearchMarketPage,
    },
    {
      path: routesPath.searchMarketSlots,
      name: 'SearchMarketSlots',
      component: SearchMarketSlotsPage,
    },
    {
      path: routesPath.searchMarketResults,
      name: 'SearchMarketResults',
      component: SearchMarketResultsPage,
    },
    {
      path: routesPath.searchTeacherDiscipline,
      name: 'SearchTeacherDiscipline',
      component: SearchTeacherDisciplinePage,
    },
    {
      path: routesPath.searchTeacherTheme,
      name: 'SearchTeacherTheme',
      component: SearchTeacherThemePage,
    },
    {
      path: routesPath.searchTeacherResults,
      name: 'SearchTeacherResults',
      component: SearchTeacherResultsPage,
    },
    {
      path: routesPath.room,
      name: 'Room',
      component: RoomPage,
    },
    {
      path: routesPath.teacher,
      name: 'Teacher',
      component: TeacherPage,
    },
    {
      path: routesPath.signOut,
      name: 'SignOut',
      component: SignOutPage,
    },
  ],
}

export const authLayoutRoutes = [authRoutes]

const publicRoutes = {
  id: 'Public',
  path: '',
  icon: <Layout />,
  component: null,
  children: [],
}

export const publicLayoutRoutes = [publicRoutes]
