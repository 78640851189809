import styled, { css } from 'styled-components'
import { Link as MuiLink } from '@material-ui/core'
import { Link as DomLinkC } from 'react-router-dom'

export const Link = styled(MuiLink)``

export const DomLink = styled(DomLinkC)``
export const Span = styled.span<{ $button?: boolean }>`
  ${({ $button }) =>
    $button &&
    css`
      cursor: pointer;
    `}
`
