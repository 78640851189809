import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { HeaderProps } from '../../components/Header'
import useHeader from '../../hooks/useHeader'
import { SignUpProfessionalInfosProps } from '../../components/SignUpProfessionalInfos'
import { actions, selectors } from '../../redux'
import { resolveRegisterStepURL } from '../../helpers/RegisterHelpers'
import { useFormSubmit } from '../../hooks/useFormSubmit'
import { api } from '../../configuration'
import { router, routesPath } from '../../router'
import SignUpProfessionalInfosTemplate from '../../templates/SignUpProfessionalInfos'

const SignUpProfessionalInfosPage: React.FC = (_props) => {
  const headerProps: HeaderProps = useHeader()

  const { t } = useTranslation()
  const token = useSelector(selectors.auth.token)
  const user = useSelector(selectors.auth.user)
  const history = useHistory()
  const setting = useSelector(selectors.app.setting)
  const urlPrivacy = setting?.links?.privacy

  const onComplete = useCallback(() => {
    history.push(resolveRegisterStepURL(user))
  }, [user, history])

  const [submit, handleSubmit] = useFormSubmit(
    selectors.auth.signUpProfessionalInfos,
    actions.auth.signUpProfessionalInfosRequest,
    actions.auth.signUpProfessionalInfosReset,
    onComplete
  )

  const signupProps: SignUpProfessionalInfosProps = useMemo(() => {
    const config: Resumable.ConfigurationHash = {
      target: api.UPLOAD_ENDPOINT,
      headers: { Authorization: `Bearer ${token}` },
      fileType: ['pdf', 'jpg', 'png', 'jpeg'],
    }

    return {
      step: t('signUpProfessionalInfos_step'),
      title: t('signUpProfessionalInfos_title'),
      legends: [
        t('signUpProfessionalInfos_legends_0'),
        t('signUpProfessionalInfos_legends_1'),
        t('signUp_legends_1'),
        <span
          key={2}
          dangerouslySetInnerHTML={{ __html: t('signUp_legends_2', { url: urlPrivacy }) }}
        />,
      ],
      siretText: t('signUpProfessionalInfos_siret_text'),
      fieldsProps: {
        siret: {
          label: t('signUpProfessionalInfos_form_siret_label'),
        },
        cv: {
          label: t('signUpProfessionalInfos_form_cv_label'),
          config,
        },
        diploma: {
          label: t('signUpProfessionalInfos_form_diploma_label'),
          config,
        },
        certificate: {
          label: t('signUpProfessionalInfos_form_certificate_label'),
          config,
        },
        introduction: {
          placeholder: t('signUpProfessionalInfos_form_introduction_placeholder'),
          maxCharLabelResolver: (maxChar: number, count: number) => {
            return t('remaining_chars', { count: maxChar - count })
          },
        },
      },
      noStatutTitle: t('signUpProfessionalInfos_registration_title'),
      noStatutText: t('signUpProfessionalInfos_registration_text'),
      noStatutCta: {
        text: t('signUpProfessionalInfos_registration_cta_label'),
        link: {
          link: t('signUpProfessionalInfos_registration_cta_url'),
          target: t('signUpProfessionalInfos_registration_cta_target'),
        },
      },
      noCertificateTitle: t('signUpProfessionalInfos_certificate_title'),
      noCertificateText: t('signUpProfessionalInfos_certificate_text'),
      noCertificateCta: {
        text: t('signUpProfessionalInfos_certificate_cta_label'),
        link: {
          link: t('signUpProfessionalInfos_certificate_cta_url'),
          target: t('signUpProfessionalInfos_certificate_cta_target'),
        },
      },
      documentTitle: t('signUpProfessionalInfos_documents_title'),
      documentText: t('signUpProfessionalInfos_documents_text'),
      presentationTitle: t('signUpProfessionalInfos_presentation_title'),
      returnButton: {
        text: t('back'),
        link: {
          link: router(routesPath.signUpTeacherDisciplines),
        },
        type: 'button',
      },
      initialValues: {
        siret: user?.teacher?.siret ?? '',
        cv: user?.teacher?.cv ?? { name: '', file: '' },
        diploma: user?.teacher?.diploma ?? { name: '', file: '' },
        certificate: user?.teacher?.company_certificate ?? { name: '', file: '' },
        introduction: user?.teacher?.introduction ?? '',
      },
      errorTexts: {
        required: t('error_required'),
        siret: t('error_siret'),
        maxLength: (props: any) => {
          return t('error_maxlength', { max: props?.max, count: props.value?.length })
        },
      },
      mandatoryText: t('signUp_required_text'),
      submitButton: {
        text: submit.pending
          ? t('signUpPersonalInfos_submit_pending')
          : t('signUpPersonalInfos_submit'),
        isPending: submit.pending,
      },
      submitErrors: submit.errors,
      onSubmit: handleSubmit,
    }
  }, [user, t, submit.pending, submit.errors, handleSubmit, token, urlPrivacy])

  const imageProps = {
    alt: 'LiberteClass - Inscription',
    src: '/static/assets/images/signup-teacher.png',
  }

  return (
    <SignUpProfessionalInfosTemplate
      headerProps={headerProps}
      signupProps={signupProps}
      imageProps={imageProps}
    />
  )
}

export default SignUpProfessionalInfosPage
