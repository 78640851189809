import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { HeaderProps } from '../../components/Header'
import useHeader from '../../hooks/useHeader'
import { SignInFormProps } from '../../components/SignInForm'
import SignInTemplate from '../../templates/SignIn'
import { useFormSubmit } from '../../hooks/useFormSubmit'
import { actions, selectors } from '../../redux'
import { router, routesPath } from '../../router'
import useSocialConnect from '../../hooks/useSocialConnect'

const SignInPage: React.FC = (pageProps) => {
  const headerProps: HeaderProps = useHeader(pageProps)
  const socialConnectProps = useSocialConnect()

  const { t } = useTranslation()

  const onComplete = useCallback(() => {
    console.log('loginComplete')
  }, [])

  const [submit, handleSubmit] = useFormSubmit(
    selectors.auth.login,
    actions.auth.loginRequest,
    actions.auth.loginReset,
    onComplete
  )

  const signupProps: SignInFormProps = useMemo(() => {
    return {
      title: t('signIn_title'),
      notAlreadySignedUpText: t('signIn_signUp_title'),
      notAlreadySignedUpCta: {
        text: t('signIn_signUp_cta'),
        link: {
          link: router(routesPath.signUp),
        },
      },
      fieldsProps: {
        email: {
          label: t('signIn_form_email_label'),
        },
        password: {
          label: t('signIn_form_password_label'),
        },
      },
      mandatoryText: t('signIn_required_text'),
      socialConnectProps,
      lostPasswordText: t('signIn_reset_title'),
      lostPasswordCta: {
        text: t('signIn_reset_cta'),
        link: {
          link: router(routesPath.signInForgot),
        },
      },
      initialValues: {
        email: '',
        password: '',
      },
      errorTexts: {
        required: t('error_required'),
        email: t('error_email'),
      },
      submitButton: {
        text: submit.pending ? t('signIn_submit_pending') : t('signIn_submit'),
        isPending: submit.pending,
      },
      submitErrors: submit.errors,
      onSubmit: (values) => handleSubmit(values),
    }
  }, [t, handleSubmit, submit, socialConnectProps])

  const imageProps = { alt: 'LiberteClass - Connexion', src: '/static/assets/images/signin.png' }

  return (
    <SignInTemplate headerProps={headerProps} signupProps={signupProps} imageProps={imageProps} />
  )
}

export default SignInPage
