import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import Resumable from 'resumablejs'

import { ImgProps } from '../../../types/image'
import { Icons } from '../../Icon/types'

import * as SC from './styled'

export enum UploadImageErrors {
  FILE_ERROR = 'FILE_ERROR',
}

export type UploadImageError = {
  type: string
  params?: {
    [key: string]: any
  }
}

export type UploadImageProps = {
  className?: string
  image?: ImgProps
  config: Resumable.ConfigurationHash
  name: string
  value?: string
  onChange?: (name: string, value: string) => void
  error?: string
  help?: string
  translateError?: (error: UploadImageError) => string
  hasValue?: boolean
}

const UploadImage: FC<UploadImageProps> = (props) => {
  const {
    className,
    name,
    image,
    value,
    onChange,
    config,
    error,
    help,
    translateError,
    hasValue,
  } = props

  const [progress, setProgress] = useState(0)
  const dropNode = useRef((null as unknown) as HTMLDivElement)
  const avatarNode = useRef((null as unknown) as HTMLDivElement)
  const [localError, setLocalError] = useState(null as UploadImageError | null)
  const [filePath, setFilePath] = useState(value)

  const handleChange = useCallback((val: string) => onChange?.(name, val), [name, onChange])

  const resumable = useMemo(() => new Resumable({}), [])

  useEffect(() => {
    const maxFilesErrorCallback = () => null
    resumable.opts = {
      uploadMethod: 'POST',
      testMethod: 'POST',
      method: 'multipart',
      chunkSize: 1024 * 512,
      testChunks: false,
      ...config,
      simultaneousUploads: 1,
      maxFiles: 1,
      maxFilesErrorCallback,
    }
  }, [config, resumable])

  // assign browse
  useEffect(() => {
    if (resumable && avatarNode.current) {
      resumable.assignBrowse(avatarNode.current, false)
    }
    if (resumable && dropNode.current) {
      resumable.assignDrop(dropNode.current)
    }
    return () => {
      if (resumable) {
        resumable.cancel()
      }
    }
  }, [resumable])

  useEffect(() => {
    let mounted = true
    resumable.on('fileAdded', (_f: Resumable.ResumableFile, _event: DragEvent) => {
      setProgress(0)
      resumable.upload()
    })

    resumable.on('fileSuccess', (f: Resumable.ResumableFile, event: any) => {
      let result

      try {
        result = JSON.parse(event)
      } catch (e) {
        result = {}
      }
      setLocalError(null)

      console.log('result', result, f, event)

      const path = URL.createObjectURL(f.file)

      if (mounted) {
        setFilePath(path)
        handleChange(result.file)
      }
    })

    resumable.on('fileError', (f, event) => {
      setLocalError({
        type: UploadImageErrors.FILE_ERROR,
        params: {
          file: f,
          event,
        },
      })
    })

    resumable.on('progress', () => {
      setProgress(resumable.progress())
    })

    return () => {
      mounted = false
    }
  }, [handleChange, resumable])

  const imagePath = filePath || image?.src

  return (
    <SC.UploadImage className={className} ref={dropNode}>
      <SC.Wrapper disableGutters>
        <SC.UploadBox ref={avatarNode}>
          <SC.AvatarImage src={imagePath} alt={image?.alt} />
          <SC.CircularProgress
            value={progress * 100}
            strokeWidth={4}
            $visible={progress !== 0 && progress !== 1}
          />
          <SC.AvatarIcon icon={value || hasValue ? Icons.pebbleUpdate : Icons.pebbleUpload} />
        </SC.UploadBox>
        {localError && translateError && (
          <SC.HelperText error>{translateError(localError)}</SC.HelperText>
        )}
        {(error || help) && <SC.HelperText error={!!error}>{error || help}</SC.HelperText>}
      </SC.Wrapper>
    </SC.UploadImage>
  )
}

export default UploadImage
