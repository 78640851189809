import { generatePath } from 'react-router-dom'

export const router = (route: string, options?: { [key: string]: string }): string => {
  return generatePath(route, options)
}

export const routesPath = {
  account: '/mon-compte',
  course: '/cours/:id',
  courseRequest: '/demande/:id',
  dashboard: '/',
  dashboardChild: '/mes-enfants/:child([0-9]+)?',
  planning: '/planning/:child([0-9]+)?',
  planningTab: '/planning/t-:tab/:child([0-9]+)?',
  planningFuture: '/planning/a-venir',
  planningHistory: '/planning/historique',
  planningReview: '/planning/evaluations-en-attente',
  pot: '/cagnotte',
  preferences: '/preferences',
  preferencesAvailabilities: '/preferences/mes-disponibilites',
  preferencesAvailabilitiesRecurrent: '/preferences/mes-disponibilites/recurrence',
  preferencesAvailabilitiesRecurrentAdd: '/preferences/mes-disponibilites/recurrence/ajouter',
  preferencesAvailabilitiesRecurrentEdit: '/preferences/mes-disponibilites/recurrence/modifier/:id',
  preferencesCourseMaterials: '/preferences/mes-supports-de-cours',
  preferencesStudents: '/preferences/eleves',
  preferencesDisciplines: '/preferences/matieres',
  preferencesPricing: '/preferences/mes-tarifs',
  preferencesWallet: '/preferences/ma-cagnotte',
  preferencesWalletDeposit: '/preferences/ma-cagnotte/depot',
  preferencesWalletWithdrawal: '/preferences/ma-cagnotte/retrait',
  preferencesFamily: '/preferences/ma-famille',
  preferencesFamilyAddChild: '/preferences/ma-famille/ajouter-un-enfant',
  preferencesFamilyChild: '/preferences/ma-famille/:child',
  preferencesRating: '/preferences/mon-evaluation',
  search: '/recherche/:child([0-9]+)?',
  searchDiscipline: '/recherche/t-:type/:child([0-9]+)?',
  searchTheme: '/recherche/t-:type/d-:discipline/:child([0-9]+)?',
  searchPrice: '/recherche/t-:type/d-:discipline/t-:theme/:child([0-9]+)?',
  searchAvailabilities:
    '/recherche/t-:type/d-:discipline/t-:theme/p-:price/q-:size/:child([0-9]+)?',
  searchResults: '/recherche/resultats',
  searchClassRequests: '/recherche/demandes-de-cours',
  searchMarket: '/recherche/marche-des-classes',
  searchMarketSlots: '/recherche/marche-des-classes/:type/:price/:date',
  searchMarketResults: '/recherche/marche-des-classes/:type/:price/:date/:slot',
  searchTeacherDiscipline: '/reprendre-un-cours/:teacher/:child([0-9]+)?',
  searchTeacherTheme: '/reprendre-un-cours/:teacher/d-:discipline/:child([0-9]+)?',
  searchTeacherResults: '/reprendre-un-cours/:teacher/d-:discipline/t-:theme/:child([0-9]+)?',
  signIn: '/connexion',
  signOut: '/deconnexion',
  signInForgot: '/connexion/mot-de-passe-oublie',
  signInReset: '/connexion/reinitialiser',
  signUp: '/inscription/:type(eleve|enseignant|parent)?',
  signUpConfirm: '/inscription/verification-email',
  signUpSocial: '/inscription/social',
  signUpPersonalInfos: '/inscription/informations-personnelles',
  signUpGuardianAddChild: '/inscription/ajouter-un-enfant',
  signUpChildAddGuardian: '/inscription/rattacher-a-un-parent',
  signUpTeacherDisciplines: '/inscription/matieres',
  signUpTeacherPro: '/inscription/informations-professionnelles',
  signUpTeacherPending: '/inscription/validation-en-cours',
  signUpTeacherPricing: '/inscription/tarifs',
  signUpTeacherPayments: '/inscription/paiements',
  signUpStandby: '/inscription/peaufinage',
  teacher: '/enseignant/:id',
  room: '/classe/:id',
}
