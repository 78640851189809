import React, { FC } from 'react'

import { BasicLinkProps } from '../BasicLink'
import { Icons } from '../Icon/types'
import { light } from '../../theme/palette'

import * as SC from './styled'

export type RecurrenceCardProps = {
  className?: string
  weekdays: string
  hours?: string[]
  startDate: string
  endDate: string
  cta: BasicLinkProps
  onDelete: () => void
}

const RecurrenceCard: FC<RecurrenceCardProps> = (props) => {
  const { className, weekdays, hours, startDate, endDate, cta, onDelete } = props

  return (
    <SC.RecurrenceCard className={className}>
      <SC.Wrapper>
        <SC.Content>
          <SC.Weekdays>{weekdays}</SC.Weekdays>
          <SC.Muidivider />
          <SC.Hours>
            {hours?.map((hour, index) => (
              <React.Fragment key={index}>
                <SC.Hour>{hour}</SC.Hour>
                {index !== hours.length - 1 && <SC.Point>.</SC.Point>}
              </React.Fragment>
            ))}
          </SC.Hours>
          <SC.Dates>
            <SC.Date>{startDate}</SC.Date>
            <SC.DateChrevron icon={Icons.chevronRight} color={light.colors.grey} />
            <SC.Date>{endDate}</SC.Date>
          </SC.Dates>
        </SC.Content>
        <SC.Cta {...cta} icon={Icons.longArrowRight} />
        <SC.TrashIcon icon={Icons.trash} color={light.colors.tomato} onClick={onDelete} />
      </SC.Wrapper>
    </SC.RecurrenceCard>
  )
}

export default RecurrenceCard
