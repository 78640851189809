import styled from 'styled-components'
import { CardContent, Container, List, ListItem } from '@material-ui/core'

import BasicLink from '../BasicLink'
import IconC from '../Icon'
import Link from '../Link'

export const Menu = styled.div`
  width: 100%;
  height: 8.4rem;
  ${(props) => props.theme.breakpoints.up('md')} {
    background-color: ${(props) => props.theme.palette.colors.pureWhite};
    height: 100%;
  }
`

export const MenuContent = styled(Container)`
  box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.15);
  border-radius: 20px 20px 0 0;
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  z-index: 1000;
  background-color: ${(props) => props.theme.palette.colors.pureWhite};
  ${(props) => props.theme.breakpoints.up('md')} {
    box-shadow: none;
    border-radius: 0;
    position: relative;
    padding: 2.6rem 1rem 2.6rem 2.6rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`

export const MenuTop = styled.div`
  display: none;
  ${(props) => props.theme.breakpoints.up('md')} {
    width: 100%;
    display: inline-flex;
    overflow: auto;
    flex-wrap: nowrap;
    width: 100%;
    display: flex;
    flex-direction: column;
    & > a {
      margin-bottom: 2rem;
    }
  }
`

export const MenuBottom = styled.div`
  display: none;
  ${(props) => props.theme.breakpoints.up('md')} {
    width: 100%;
    display: flex;
  }
`

export const MenuItem = styled(BasicLink)<{ selected?: boolean }>`
  width: 16rem;
  ${(props) => ({ ...props.theme.typography.smallRegular })}
  color: ${(props) =>
    props.selected ? props.theme.palette.colors.midnightBlue : props.theme.palette.colors.echoBlue};
  & :hover {
    text-decoration: none;
  }
  text-decoration: none;
`

export const MenuIcon = styled(IconC)<{ selected?: boolean }>`
  color: ${(props) =>
    props.selected ? props.theme.palette.colors.midnightBlue : props.theme.palette.colors.echoBlue};
  width: 1.8rem;
  height: 1.8rem;
  margin-right: 1rem;
`
export const MenuLabel = styled.span``

export const MenuItemDisconnect = styled(MenuItem)`
  color: ${(props) => props.theme.palette.colors.neonCarrot};
`

export const TabBarCardContent = styled(CardContent)`
  ${(props) => props.theme.breakpoints.up('md')} {
    display: none;
  }
`

export const Icon = styled(IconC)`
  width: 2.8rem;
  height: 2.8rem;
`
export const Items = styled(List)`
  display: inline-flex;
  overflow: auto;
  flex-wrap: nowrap;
  width: 100%;
`

export const ItemLink = styled(Link)`
  width: 100%;
`

export const Item = styled(ListItem)<{ selected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.palette.colors.midnightBlue};
  opacity: ${({ selected }) => (selected ? '1' : '0.4')};
  &.MuiListItem-root.Mui-selected,
  .MuiListItem-root.Mui-selected:hover {
    background: transparent;
  }
`
