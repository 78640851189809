import React, { FC } from 'react'

import * as SC from './styled'

export type SubjectWithThemeCardProps = {
  className?: string
  subject: string
  themeName: string
  lessonName: string
  lessonDescription?: string
}

const SubjectWithThemeCard: FC<SubjectWithThemeCardProps> = (props) => {
  const { className, subject, themeName, lessonName, lessonDescription } = props

  return (
    <SC.SubjectWithThemeCard className={className}>
      <SC.Wrapper>
        <SC.Subject>{subject}</SC.Subject>
        {themeName && <SC.Theme>{themeName}</SC.Theme>}
        <SC.LessonName>{lessonName}</SC.LessonName>
        {lessonDescription && <SC.LessonDescription>{lessonDescription}</SC.LessonDescription>}
      </SC.Wrapper>
    </SC.SubjectWithThemeCard>
  )
}

export default SubjectWithThemeCard
