import React from 'react'
import { Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { selectors } from '../redux'
import { router, routesPath } from '../router'
import { isAllowed, resolveRegisterStepURL } from '../helpers/RegisterHelpers'

interface RegisterGuardType {
  children: React.ReactNode
}

// For routes that can only be accessed by authenticated users
function RegisterGuard({ children }: RegisterGuardType) {
  const isConnected = useSelector(selectors.auth.isConnected)
  const isActive = useSelector(selectors.auth.isActive)
  const user = useSelector(selectors.auth.user)

  if (!isConnected) {
    return <Redirect to={router(routesPath.signIn)} />
  }
  if (isConnected && isActive) {
    return <Redirect to={router(routesPath.dashboard)} />
  }

  const url = (children as any)?.props?.match?.url
  const nextStepUrl = resolveRegisterStepURL(user)
  if (isConnected && !isAllowed(user, url) && nextStepUrl) {
    return <Redirect to={nextStepUrl} />
  }

  return children
}

export default RegisterGuard
