import gql from 'graphql-tag'

import { TeacherAvailabilityRecurrence } from '../../../../generated/graphql'

const deleteTeacherAvailabilityRecurrence = {
  mutation: gql`
    mutation deleteTeacherAvailabilityRecurrence($id: ID!) {
      deleteTeacherAvailabilityRecurrence(id: $id) {
        id
      }
    }
  `,
  transformer: (response: {
    deleteTeacherAvailabilityRecurrence: TeacherAvailabilityRecurrence
  }): any => {
    return response.deleteTeacherAvailabilityRecurrence
  },
}

export default deleteTeacherAvailabilityRecurrence
