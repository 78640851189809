import React, { FC } from 'react'

import Header, { HeaderProps } from '../../components/Header'
import Menu, { MenuProps } from '../../components/Menu'
import AuthLayout from '../../layouts/AuthLayout'
import { PotProps } from '../../components/Pot'
import { Icons } from '../../components/Icon/types'
import { light } from '../../theme/palette'
import { BasicLinkProps } from '../../components/BasicLink'
import { CourseCardInContextProps } from '../../components/CourseCardInContext'
import { ActionButtonProps } from '../../components/ActionButton'
import { ImageTextItemProps } from '../../components/ImageTextItem'
import { MessageProps } from '../../components/Message'
import AttachParentModal, {
  AttachParentModalProps,
} from '../../components/modals/AttachParentModal'

import * as SC from './styled'

export type FamilyProps = {
  title: string
  text?: string
  children?: ImageTextItemProps[]
  manageFamilyProps?: BasicLinkProps
  addChildProps?: ActionButtonProps
}

export type AvailabilitiesProps = {
  title: string
  text?: string
  cta?: ActionButtonProps
}

export type DashboardTemplateProps = {
  className?: string
  headerProps: HeaderProps
  menuProps: MenuProps
  potProps: PotProps
  courseCards?: CourseCardInContextProps[]
  familyProps?: FamilyProps
  availabilitiesProps?: AvailabilitiesProps
  missingEvalProps?: MessageProps
  courseAssetsProps?: ImageTextItemProps
  attachParentModalProps?: AttachParentModalProps
}

const DashboardTemplate: FC<DashboardTemplateProps> = (props) => {
  const {
    headerProps,
    menuProps,
    potProps,
    courseCards,
    familyProps,
    availabilitiesProps,
    missingEvalProps,
    courseAssetsProps,
    attachParentModalProps,
  } = props

  return (
    <AuthLayout header={<Header {...headerProps} withDivider />} menu={<Menu {...menuProps} />}>
      <SC.Content>
        <SC.Pot {...potProps} />
        {missingEvalProps && <SC.Message {...missingEvalProps} />}
        {courseCards &&
          courseCards?.map((courseCard, index) => (
            <SC.CourseCardInContext {...courseCard} key={index} />
          ))}
        {familyProps && (
          <SC.FamilyContainer>
            <SC.Title>{familyProps.title}</SC.Title>
            {familyProps.children && familyProps.children?.length > 0 ? (
              <>
                <SC.ImageTextList items={familyProps.children} />
                <SC.ManageFamilyCta
                  {...familyProps.manageFamilyProps}
                  icon={Icons.profileCheck}
                  iconSide="left"
                  iconColor={light.colors.freeSpeechBlue}
                />
              </>
            ) : (
              <>
                <SC.Text>{familyProps.text}</SC.Text>
                <SC.Button outlined {...familyProps.addChildProps} />
              </>
            )}
          </SC.FamilyContainer>
        )}
        {availabilitiesProps && (
          <SC.Course>
            <SC.Title>{availabilitiesProps.title}</SC.Title>
            <SC.Text>{availabilitiesProps.text}</SC.Text>
            <SC.Cta {...availabilitiesProps.cta} />
          </SC.Course>
        )}
        {courseAssetsProps && <SC.ImageTextItem {...courseAssetsProps} />}
      </SC.Content>
      {attachParentModalProps && <AttachParentModal {...attachParentModalProps} />}
    </AuthLayout>
  )
}

export default DashboardTemplate
