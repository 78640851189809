import { Container } from '@material-ui/core'
import styled, { css } from 'styled-components'

import Link from '../Link'

export const ThemeList = styled.div``

export const Wrapper = styled(Container)`
  ${(props) => props.theme.breakpoints.up('md')} {
    display: flex;
    flex-direction: column;
  }
`

export const Title = styled.h3`
  ${(props) => ({ ...props.theme.typography.h4 })}
  color: ${(props) => props.theme.palette.colors.grey};
  margin: 0rem 0 1.6rem 0;
`

export const Themes = styled.div`
  display: flex;
  flex-direction: column;
`

export const ThemeItem = styled(Link)<{ isClicked?: boolean; isDisabled?: boolean }>`
  display: flex;
  text-decoration: none;
  ${(props) => ({ ...props.theme.typography.tinySmall })}
  color: ${(props) =>
    props?.isDisabled
      ? props.theme.palette.colors.gainsboro
      : props?.isClicked
      ? props.theme.palette.colors.freeSpeechBlue
      : props.theme.palette.colors.midnightBlue};
  padding: 1rem 1.6rem;
  border: 1px solid
    ${(props) =>
      props?.isDisabled
        ? props.theme.palette.colors.gainsboro
        : props?.isClicked
        ? props.theme.palette.colors.cornflowerBlue
        : props.theme.palette.colors.gainsboro};
  border-radius: 0.6rem;
  margin: 0 0 0.8rem 0;
  cursor: ${(props) => (props.isDisabled ? 'not-allowed' : 'pointer')};
  &:hover {
    ${(props) =>
      !props?.isDisabled &&
      css`
        border-color: ${props.theme.palette.colors.cornflowerBlue};
      `}
  }
`
