import { Container } from '@material-ui/core'
import styled from 'styled-components'

import TextField from '../form/TextField'
import PaymentFieldC from '../form/PaymentField'
import FormSubmit from '../FormSubmit'
import Number from '../form/Number'

export const PotDepositForm = styled.div`
  ${(props) => props.theme.breakpoints.up('md')} {
    max-width: 40rem;
  }
`

export const Wrapper = styled(Container)``

export const Text = styled.p`
  ${(props) => ({ ...props.theme.typography.big })}
  color: ${(props) => props.theme.palette.colors.midnightBlue};
  margin: 0;
`

export const TextBelow = styled.p`
  ${(props) => ({ ...props.theme.typography.tinySmall })}
  color: ${(props) => props.theme.palette.colors.chambray};
  margin: 0;
`

export const Fields = styled.div`
  margin: 1.6rem 0 2.4rem 0;
`

export const PaymentField = styled(PaymentFieldC)``

export const Field = styled(TextField)`
  margin: 1.6rem 0;
`
export const Amount = styled(Number)`
  margin: 1.6rem 0;
`

export const SubmitButton = styled(FormSubmit)`
  margin: 4.8rem 0 0 0;
`
