import styled from 'styled-components'

import CourseCardCarousel from '../../components/CourseCardCarousel'
import ImageTextList from '../../components/ImageTextList'
import TabContent from '../../components/TabContent'
import ImageTextItem from '../../components/ImageTextItem'

export const Content = styled.div``

export const Title = styled.h1`
  ${(props) => ({ ...props.theme.typography.h3 })}
  color: ${(props) => props.theme.palette.colors.neonCarrot};
  padding: 3rem 1.6rem 0rem 1.6rem;
  margin: 0;
`

export const MyCourseCardCarousel = styled(CourseCardCarousel)`
  width: 100%;
  padding: 1.6rem 1.6rem 0rem 1.6rem;
`

export const MyImageTextList = styled(ImageTextList)`
  padding: 3rem 1.6rem 0rem 1.6rem;
`

export const Subtitle = styled.h2`
  ${(props) => ({ ...props.theme.typography.h3 })}
  color: ${(props) => props.theme.palette.colors.midnightBlue};
  padding: 1.6rem 1.6rem 0rem 1.6rem;
  margin: 0;
`

export const Tabs = styled(TabContent)`
  padding: 0 1.6rem;
`

export const RequestForLabel = styled.p`
  color: ${(props) => props.theme.palette.colors.midnightBlue};
  ${(props) => ({ ...props.theme.typography.h4 })}
  margin: 1.6rem;
`

export const RequestFor = styled(ImageTextItem)`
  cursor: default;
  margin: 1.6rem;
`
