import { Divider } from '@material-ui/core'
import styled from 'styled-components'

import BasicLink from '../BasicLink'
import ActionButton from '../ActionButton'
import TextFieldC from '../form/TextField'
import FormSubmit from '../FormSubmit'

export const SignInForm = styled.div`
  padding: 0 1.6rem;
`

export const Title = styled.h1`
  ${(props) => ({ ...props.theme.typography.h1 })}
  color: ${(props) => props.theme.palette.colors.midnightBlue};
  font-size: 3.2rem;
  margin: 0 0 2rem 0;
  ${(props) => props.theme.breakpoints.up('md')} {
    font-size: 4rem;
  }
`

export const NotAlreadySignedUpText = styled.p`
  ${(props) => ({ ...props.theme.typography.h6 })}
  color: ${(props) => props.theme.palette.colors.freeSpeechBlue};
  margin: 1rem 0 0.5rem 0;
`

export const NotAlreadySignedUpCta = styled(BasicLink)`
  align-items: center;
  display: flex;
  text-decoration: none;
  text-transform: uppercase;
  margin: 0 0 1rem 0;
  color: ${(props) => props.theme.palette.colors.cornflowerBlue};
  max-width: max-content;
  border-bottom: 2px solid transparent;
  ${(props) => ({ ...props.theme.typography.h6 })};
  &:hover {
    border-bottom: 2px solid ${(props) => props.theme.palette.colors.cornflowerBlue};
    text-decoration: none;
  }
`

export const Fields = styled.div`
  margin-top: 2rem;
`

export const TextField = styled(TextFieldC)`
  margin-bottom: 1rem;
  width: 100%;
  margin-right: 1rem;
`

export const Error = styled.p`
  color: ${(props) => props.theme.palette.colors.tomato};
  ${(props) => ({ ...props.theme.typography.smallRegular })}
  margin: -0.2rem 0 0.5rem 0;
`

export const SubmitButton = styled(FormSubmit)``

export const MandatoryText = styled.p`
  ${(props) => ({ ...props.theme.typography.smallRegular })}
  color: ${(props) => props.theme.palette.colors.grey};
  margin: 2rem 0;
`

export const MuiDivider = styled(Divider)`
  margin: 1rem 0rem;
`

export const GoogleCta = styled(ActionButton)`
  width: 100%;
  margin-top: 1rem;
  background-color: ${(props) => props.theme.palette.colors.pureWhite};
  border: 1px solid ${(props) => props.theme.palette.colors.gainsboro};
  border-radius: 1rem;
  padding: 2rem;
  & > span {
    ${(props) => ({ ...props.theme.typography.h5 })}
    color: ${(props) => props.theme.palette.colors.chambray};
  }
  &:hover {
    border: 1px solid ${(props) => props.theme.palette.colors.gainsboro};
    background-color: ${(props) => props.theme.palette.colors.gainsboro};
    opacity: 0.8;
  }
`

export const AppleCta = styled(ActionButton)`
  width: 100%;
  margin-top: 1rem;
  background-color: ${(props) => props.theme.palette.colors.pureBlack};
  border: 1px solid ${(props) => props.theme.palette.colors.pureWhite};
  border-radius: 1rem;
  padding: 2rem;
  & > span {
    ${(props) => ({ ...props.theme.typography.h5 })}
    color: ${(props) => props.theme.palette.colors.pureWhite};
  }
  &:hover {
    border: 1px solid ${(props) => props.theme.palette.colors.pureBlack};
    background-color: ${(props) => props.theme.palette.colors.pureBlack};
    opacity: 0.8;
  }
`

export const LostPasswordText = styled.p`
  ${(props) => ({ ...props.theme.typography.smallRegular })}
  color: ${(props) => props.theme.palette.colors.grey};
  margin: 1rem 0 0 0;
`

export const LostPasswordCta = styled(BasicLink)`
  align-items: center;
  display: flex;
  text-decoration: none;
  margin: 0;
  color: ${(props) => props.theme.palette.colors.cornflowerBlue};
  max-width: max-content;
  border-bottom: 2px solid transparent;
  ${(props) => ({ ...props.theme.typography.smallRegular })};
  &:hover {
    border-bottom: 2px solid ${(props) => props.theme.palette.colors.cornflowerBlue};
    text-decoration: none;
  }
`

export const BoxImage = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  ${(props) => props.theme.breakpoints.up('md')} {
    margin-top: -5rem;
  }
`

export const Image = styled.img`
  width: 25rem;
  height: auto;
  ${(props) => props.theme.breakpoints.up('md')} {
    width: 65rem;
  }
`
