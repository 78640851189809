import styled from 'styled-components'

import ReportingForm from '../../ReportingForm'
import ModalContainer from '../ModalContainer'

export const Modal = styled(ModalContainer)`
  ${(props) => props.theme.breakpoints.up('md')} {
    & .MuiContainer-root {
      max-width: 52rem;
      margin: 0;
    }
  }
`

export const HeaderText = styled.span`
  ${(props) => ({ ...props.theme.typography.h5 })}
  color: ${(props) => props.theme.palette.colors.tomato};
  margin: 1.6rem 0 0;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

export const Form = styled(ReportingForm)`
  ${(props) => ({ ...props.theme.typography.h4 })}
  color: ${(props) => props.theme.palette.colors.freeSpeechBlue};
  margin: 0 0 1.6rem;
  width: 100%;
`

export const Text = styled.p`
  ${(props) => ({ ...props.theme.typography.mediumRegular })}
  color: ${(props) => props.theme.palette.colors.chambray};
  margin: 0 0 1.6rem;
`
export const Options = styled.div`
  margin: 0 0 0.8rem;
  text-align: left;
  width: 100%;
`
