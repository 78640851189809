import { createSlice } from '@reduxjs/toolkit'

import type { RootState } from '../store'
import {
  getPaginatedServiceReducers,
  getServiceProps,
  getServiceReducers,
  ServiceProps,
} from '../../helpers/ReduxHelpers'
import {
  MutationUpdatePayoutScheduleIntervalArgs,
  MutationUpdateTeacherAvailabilityOfDayArgs,
  Payment,
  QueryBookingTransactionsArgs,
  QueryTeacherAvailabilitiesOfMonthArgs,
  QueryWalletTransactionsArgs,
  TeacherAvailabilityRecurrence,
  User,
  WalletTransaction,
} from '../../generated/graphql'
import { TeacherAvailabilityDay } from '../../graphql/types/TeacherAvailabilityDay'
import { AvailabilityRecurrenceFormValues } from '../../components/AvailabilityRecurrenceForm'
import { WalletTransactionsQueryResult } from '../../graphql/services/wallet/queries/walletTransactions'
import { MaxStudentsFormValues } from '../../components/MaxStudentsForm'
import { LevelPickersFormValues } from '../../components/LevelPickersForm'
import { PricesChoiceFormValues } from '../../components/PricesChoiceForm'
import { AddChildFormValues } from '../../components/AddChildForm'
import { WalletWithdrawalFormValues } from '../../components/WalletWithdrawalForm'
import { CoursesWithMaterialResult } from '../../graphql/services/course/queries/coursesWithMaterial'

import { actionTypes } from './types'

export type PreferencesState = {
  walletTransactionData: WalletTransaction[] | null
  bookingTransactionData: WalletTransaction[] | null
  // services
  teacherAvailabilitiesOfMonth: ServiceProps<
    TeacherAvailabilityDay[],
    QueryTeacherAvailabilitiesOfMonthArgs
  >
  updateTeacherAvailabilityOfDay: ServiceProps<
    TeacherAvailabilityDay[],
    MutationUpdateTeacherAvailabilityOfDayArgs
  >
  teacherAvailabilityRecurrences: ServiceProps<TeacherAvailabilityRecurrence[], undefined>
  teacherAvailabilityRecurrence: ServiceProps<TeacherAvailabilityRecurrence, string>
  createTeacherAvailabilityRecurrence: ServiceProps<
    TeacherAvailabilityRecurrence,
    AvailabilityRecurrenceFormValues
  >
  updateTeacherAvailabilityRecurrence: ServiceProps<
    TeacherAvailabilityRecurrence,
    AvailabilityRecurrenceFormValues
  >
  deleteTeacherAvailabilityRecurrence: ServiceProps<null, string>
  createCreditPaymentIntent: ServiceProps<Payment, string>
  withdrawal: ServiceProps<any, WalletWithdrawalFormValues>
  walletTransactions: ServiceProps<WalletTransactionsQueryResult, QueryWalletTransactionsArgs>
  bookingTransactions: ServiceProps<WalletTransactionsQueryResult, QueryBookingTransactionsArgs>
  updatePayoutSchedule: ServiceProps<Partial<User>, MutationUpdatePayoutScheduleIntervalArgs>
  updateTeacherParticipants: ServiceProps<null, MaxStudentsFormValues>
  updateTeacherDisciplines: ServiceProps<null, LevelPickersFormValues>
  updateTeacherPricing: ServiceProps<null, PricesChoiceFormValues>
  addChild: ServiceProps<null, AddChildFormValues>
  editChild: ServiceProps<null, AddChildFormValues & { student: string }>
  removeChild: ServiceProps<null, { student: string }>
  coursesWithMaterial: ServiceProps<CoursesWithMaterialResult, any>
}

//
// Initial state
//

const initialState: PreferencesState = {
  walletTransactionData: null,
  bookingTransactionData: null,
  // Services
  teacherAvailabilitiesOfMonth: getServiceProps(),
  updateTeacherAvailabilityOfDay: getServiceProps(),
  teacherAvailabilityRecurrences: getServiceProps(),
  teacherAvailabilityRecurrence: getServiceProps(),
  createTeacherAvailabilityRecurrence: getServiceProps(),
  updateTeacherAvailabilityRecurrence: getServiceProps(),
  deleteTeacherAvailabilityRecurrence: getServiceProps(),
  //
  createCreditPaymentIntent: getServiceProps(),
  withdrawal: getServiceProps(),
  walletTransactions: getServiceProps(),
  bookingTransactions: getServiceProps(),
  updatePayoutSchedule: getServiceProps(),
  //
  updateTeacherParticipants: getServiceProps(),
  updateTeacherDisciplines: getServiceProps(),
  updateTeacherPricing: getServiceProps(),
  addChild: getServiceProps(),
  editChild: getServiceProps(),
  removeChild: getServiceProps(),
  coursesWithMaterial: getServiceProps(),
}

//
// Slice (Actions & Reducers)
//

const slice = createSlice({
  name: 'preferences',
  initialState,
  reducers: {
    setWalletTransactionData: (state, action: actionTypes.setWalletTransaction) => {
      state.walletTransactionData = action.payload
    },
    setBookingTransactionData: (state, action: actionTypes.setWalletTransaction) => {
      state.bookingTransactionData = action.payload
    },
    // Services
    ...getServiceReducers('teacherAvailabilitiesOfMonth'),
    ...getServiceReducers('updateTeacherAvailabilityOfDay'),
    ...getServiceReducers('teacherAvailabilityRecurrences'),
    ...getServiceReducers('teacherAvailabilityRecurrence'),
    ...getServiceReducers('createTeacherAvailabilityRecurrence'),
    ...getServiceReducers('updateTeacherAvailabilityRecurrence'),
    ...getServiceReducers('deleteTeacherAvailabilityRecurrence'),
    ...getServiceReducers('createCreditPaymentIntent'),
    ...getServiceReducers('withdrawal'),
    ...getServiceReducers('walletTransactions'),
    ...getServiceReducers('bookingTransactions'),
    ...getServiceReducers('updatePayoutSchedule'),
    ...getServiceReducers('updateTeacherParticipants'),
    ...getServiceReducers('updateTeacherDisciplines'),
    ...getServiceReducers('updateTeacherPricing'),
    ...getServiceReducers('addChild'),
    ...getServiceReducers('editChild'),
    ...getServiceReducers('removeChild'),
    ...getPaginatedServiceReducers('coursesWithMaterial'),
  },
})

export const { reducer, actions } = slice

//
// Selectors
//

const root = (state: RootState) => state[slice.name]
const walletTransactionData = (state: RootState) => root(state).walletTransactionData
const bookingTransactionData = (state: RootState) => root(state).bookingTransactionData
// services
const teacherAvailabilitiesOfMonth = (state: RootState) => root(state).teacherAvailabilitiesOfMonth
const updateTeacherAvailabilityOfDay = (state: RootState) =>
  root(state).updateTeacherAvailabilityOfDay
const teacherAvailabilityRecurrences = (state: RootState) =>
  root(state).teacherAvailabilityRecurrences
const teacherAvailabilityRecurrence = (state: RootState) =>
  root(state).teacherAvailabilityRecurrence
const createTeacherAvailabilityRecurrence = (state: RootState) =>
  root(state).createTeacherAvailabilityRecurrence
const updateTeacherAvailabilityRecurrence = (state: RootState) =>
  root(state).updateTeacherAvailabilityRecurrence
const deleteTeacherAvailabilityRecurrence = (state: RootState) =>
  root(state).deleteTeacherAvailabilityRecurrence
const createCreditPaymentIntent = (state: RootState) => root(state).createCreditPaymentIntent
const withdrawal = (state: RootState) => root(state).withdrawal
const walletTransactions = (state: RootState) => root(state).walletTransactions
const bookingTransactions = (state: RootState) => root(state).bookingTransactions
const updatePayoutSchedule = (state: RootState) => root(state).updatePayoutSchedule
const updateTeacherParticipants = (state: RootState) => root(state).updateTeacherParticipants
const updateTeacherDisciplines = (state: RootState) => root(state).updateTeacherDisciplines
const updateTeacherPricing = (state: RootState) => root(state).updateTeacherPricing
const addChild = (state: RootState) => root(state).addChild
const editChild = (state: RootState) => root(state).editChild
const removeChild = (state: RootState) => root(state).removeChild
const coursesWithMaterial = (state: RootState) => root(state).coursesWithMaterial

export const selectors = {
  walletTransactionData,
  bookingTransactionData,
  // services
  teacherAvailabilitiesOfMonth,
  updateTeacherAvailabilityOfDay,
  teacherAvailabilityRecurrences,
  teacherAvailabilityRecurrence,
  createTeacherAvailabilityRecurrence,
  updateTeacherAvailabilityRecurrence,
  deleteTeacherAvailabilityRecurrence,
  createCreditPaymentIntent,
  withdrawal,
  walletTransactions,
  bookingTransactions,
  updatePayoutSchedule,
  updateTeacherParticipants,
  updateTeacherDisciplines,
  updateTeacherPricing,
  addChild,
  editChild,
  removeChild,
  coursesWithMaterial,
}
