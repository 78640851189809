import styled, { css } from 'styled-components'
import { TextField, MenuItem } from '@material-ui/core'

import InputLabel from '../InputLabel'
import Icon from '../../Icon'

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
`

export const Label = styled(InputLabel)`
  margin-bottom: 0.8rem;
`

export const MuiTextField = styled(TextField)<{
  $helperTextSide?: 'start' | 'end'
  $empty?: boolean
}>`
  width: 100%;

  // label

  & .MuiInputLabel-root {
    color: ${(props) => props.theme.palette.colors.chambray};
    ${(props) => ({ ...props.theme.typography.body1 })};
    line-height: 1;

    &.Mui-focused {
      color: ${(props) => props.theme.palette.colors.chambray};
    }

    &.Mui-error {
      color: ${(props) => props.theme.palette.error.main};
    }
  }

  & .MuiInputLabel-asterisk {
    color: ${(props) => props.theme.palette.colors.neonCarrot};
  }

  // input

  & .MuiOutlinedInput-root {
    border-width: 0.1rem;
    background-color: ${(props) => props.theme.palette.colors.pureWhite};
    border-radius: 0.8rem;
    color: ${(props) => props.theme.palette.colors.midnightBlue};

    &:hover .MuiOutlinedInput-notchedOutline {
      border-color: ${(props) => `${props.theme.palette.colors.gainsboro}`};
    }

    &.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-width: 0.1rem;
      border-color: ${(props) => `${props.theme.palette.colors.lightBlue}`};
    }

    &.Mui-error .MuiOutlinedInput-notchedOutline {
      border-color: ${(props) => `${props.theme.palette.error.main}`};
    }

    &.Mui-disabled {
      color: ${(props) => props.theme.palette.colors.grey};
    }
  }

  & .MuiOutlinedInput-notchedOutline {
    border-color: ${(props) => `${props.theme.palette.colors.gainsboro}`};
    border-radius: 0.8rem;
  }

  // adornment

  & .MuiInputAdornment-root {
    height: 1.6rem;
    width: 1.6rem;
  }

  // help text

  & .MuiFormHelperText-contained {
    text-align: ${(props) => `${props.$helperTextSide}`};
    ${(props) => ({ ...props.theme.typography.smallRegular })}
    color: ${(props) => props.theme.palette.colors.grey};
    background-color: ${(props) => props.theme.palette.colors.whiteSmoke};
    margin: 3px 0 0 0;
    &.Mui-error {
      color: ${(props) => props.theme.palette.error.main};
    }
  }

  // select

  & .MuiSelect-select {
    &:focus {
      background-color: transparent;
    }
    ${(props) =>
      props?.$empty &&
      css`
        color: #a6aac2;
      `}
  }

  & .MuiSelect-icon {
    width: 2rem;
    height: 2rem;
    top: calc(50% - 2rem / 2);
    right: 1rem;
    color: ${(props) => props.theme.palette.colors.cornflowerBlue} !important;
  }
`

export const AdornmentIcon = styled(Icon)`
  color: ${(props) => props.theme.palette.colors.cornflowerBlue};
  height: 100%;
`

export const SelectIcon = styled(Icon)`
  color: ${(props) => props.theme.palette.colors.cornflowerBlue};
`

export const MuiMenuItem = styled(MenuItem)``
