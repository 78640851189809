import React, { useCallback, useMemo } from 'react'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { HeaderProps } from '../../components/Header'
import useHeader from '../../hooks/useHeader'
import { MenuProps } from '../../components/Menu'
import useMenu from '../../hooks/useMenu'
import { Student, UserType } from '../../generated/graphql'
import { RestrictedReactFC } from '../../types/common'
import { router, routesPath } from '../../router'
import PreferencesFamilyChildTemplate, {
  PreferencesFamilyChildTemplateProps,
} from '../../templates/PreferencesFamilyAddChild'
import { useFormSubmit } from '../../hooks/useFormSubmit'
import { actions, selectors } from '../../redux'
import { api } from '../../configuration'
import useBackButton from '../../hooks/useBackButton'

const PreferencesFamilyChildPage: RestrictedReactFC<RouteComponentProps<{ child?: string }>> = (
  props
) => {
  const headerProps: HeaderProps = useHeader()
  const menuProps: MenuProps = useMenu(props)
  const { t } = useTranslation()
  const history = useHistory()
  const user = useSelector(selectors.auth.user)
  const token = useSelector(selectors.auth.token)
  const grades = useSelector(selectors.app.grades)
  const backButton = useBackButton(router(routesPath.preferencesFamily))

  const student = props.match?.params?.child ?? null
  const child: Student | undefined = useMemo(
    () => user?.guardian?.children?.find((c) => student === c?.id),
    [user, student]
  )

  const onComplete = useCallback(() => {
    history.push(router(routesPath.preferencesFamily))
  }, [history])

  const [add, handleAdd] = useFormSubmit(
    selectors.preferences.addChild,
    actions.preferences.addChildRequest,
    actions.preferences.addChildReset,
    onComplete
  )

  const [edit, handleEdit] = useFormSubmit(
    selectors.preferences.editChild,
    actions.preferences.editChildRequest,
    actions.preferences.editChildReset,
    onComplete
  )

  const submit = student ? edit : add

  const handleSubmit = useCallback(
    (values) => (student ? handleEdit({ student, ...values }) : handleAdd(values)),
    [handleAdd, handleEdit, student]
  )

  const preferencesProps: PreferencesFamilyChildTemplateProps = {
    menuProps,
    headerProps,
    title: student
      ? t('preferences_familyChild_edit_title')
      : t('preferences_familyChild_add_title'),
    backButton,
    addChildProps: {
      linkCodeText: t('account_family_link_text'),
      linkCode: user?.guardian?.code ?? '',
      initialValues: {
        firstName: child?.first_name ?? '',
        lastName: child?.last_name ?? '',
        level: child?.grade?.id ?? '',
        avatar: '',
        school: child?.school ?? '',
        signupChild: !!child?.email ?? false,
        email: child?.email ?? '',
        password: '',
      },
      forceSignupChild: !!child?.email,
      isEdit: !!student,
      errorTexts: {
        required: t('error_required'),
        email: t('error_email'),
        password: t('error_password'),
      },
      fieldsProps: {
        firstName: {
          label: t('preferences_familyChild_form_firstName'),
        },
        lastName: {
          label: t('preferences_familyChild_form_lastName'),
        },
        level: {
          label: t('preferences_familyChild_form_level'),
          select: true,
          selectOptions: grades.map((grade) => ({ label: grade.name, value: grade.id })),
        },
        school: {
          label: t('preferences_familyChild_form_school'),
        },
        signupChild: {
          label: t('preferences_familyChild_form_account'),
        },
        avatar: {
          image: {
            alt: 'LiberteClass - Upload',
            src: child?.avatar ?? '/static/assets/images/upload.png',
          },
          config: {
            target: api.UPLOAD_ENDPOINT,
            headers: { Authorization: `Bearer ${token}` },
            fileType: ['jpg', 'png', 'jpeg', 'gif'],
          },
        },
        email: {
          label: t('preferences_familyChild_form_email'),
        },
        password: {
          label: t('preferences_familyChild_form_password'),
        },
      },
      submitButton: {
        text: student
          ? t('preferences_familyChild_edit_label')
          : t('preferences_familyChild_add_label'),
        successText: student
          ? t('preferences_familyChild_add_label_success')
          : t('preferences_familyChild_edit_label_success'),
        isPending: submit.pending,
        isSuccess: submit.success,
      },
      onSubmit: handleSubmit,
    },
  }

  return <PreferencesFamilyChildTemplate {...preferencesProps} />
}

PreferencesFamilyChildPage.restrictedUserTypes = [UserType.Guardian]

export default PreferencesFamilyChildPage
