import React, { useCallback, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { HeaderProps } from '../../components/Header'
import useHeader from '../../hooks/useHeader'
import { router, routesPath } from '../../router'
import AccountTemplate, { AccountTemplateProps } from '../../templates/Account'
import { MenuProps } from '../../components/Menu'
import useMenu from '../../hooks/useMenu'
import { actions, selectors } from '../../redux'
import { ProfileFormValues } from '../../components/ProfilForm'
import { TeacherDocumentStatus, UserType } from '../../generated/graphql'
import { api, app, google } from '../../configuration'
import { useFormSubmit } from '../../hooks/useFormSubmit'
import { Icons } from '../../components/Icon/types'
import { ChangePasswordModalProps } from '../../components/modals/ChangePasswordModal'
import { ImageTextItemProps } from '../../components/ImageTextItem'
import useBackButton from '../../hooks/useBackButton'

const getIcon = (status?: TeacherDocumentStatus | null): Icons =>
  status === TeacherDocumentStatus.Accepted
    ? Icons.pebbleValidate
    : status === TeacherDocumentStatus.Pending
    ? Icons.pebbleClock
    : status === TeacherDocumentStatus.Refused
    ? Icons.pebbleWarning
    : Icons.pebbleUpload

const AccountPage: React.FC<RouteComponentProps> = (props) => {
  const headerProps: HeaderProps = useHeader()
  const menuProps: MenuProps = useMenu(props)
  const user = useSelector(selectors.auth.user)
  const token = useSelector(selectors.auth.token)
  const grades = useSelector(selectors.app.grades)
  const { t } = useTranslation()
  const backButton = useBackButton()

  const [passwordModalOpen, setPasswordModalOpen] = useState(false)
  const [deleteAccountOpen, setDeleteAccountOpen] = useState(false)

  const [submit, handleSubmit] = useFormSubmit(
    selectors.auth.updateProfile,
    actions.auth.updateProfileRequest,
    actions.auth.updateProfileReset
  )

  const onUpdatePasswordComplete = useCallback(() => setPasswordModalOpen(false), [])

  const [updatePassword, handleUpdatePassword] = useFormSubmit(
    selectors.auth.updatePassword,
    actions.auth.updatePasswordRequest,
    actions.auth.updatePasswordReset,
    onUpdatePasswordComplete
  )

  const passwordModal: ChangePasswordModalProps = {
    open: passwordModalOpen,
    title: t('account_passwordModal_title'),
    text: t('account_passwordModal_text'),
    cancelButton: {
      text: t('cancel'),
      onClick: () => {
        setPasswordModalOpen(false)
      },
    },
    submitButton: {
      text: t('submit_label'),
      isPending: updatePassword.pending,
      isSuccess: updatePassword.success,
    },
    handleClose: () => {
      setPasswordModalOpen(false)
    },
    onClose: () => {
      setPasswordModalOpen(false)
    },
    fieldsProps: {
      current: {
        label: t('account_passwordModal_form_current_label'),
      },
      password: {
        label: t('account_passwordModal_form_password_label'),
      },
      passwordConfirmation: {
        label: t('account_passwordModal_form_passwordConfirmation_label'),
      },
    },
    initialValues: {
      current: '',
      password: '',
      passwordConfirmation: '',
    },
    submitErrors: updatePassword.errors,
    errorTexts: {
      required: t('error_required'),
      password: t('error_password'),
      confirmation: t('error_password_confirm'),
    },
    onSubmit: handleUpdatePassword,
  }

  const fieldsProps = {
    avatar: {
      label: '',
      image: { alt: 'LiberteClass - Upload', src: user?.avatar },
      hasValue: true,
      config: {
        target: api.UPLOAD_ENDPOINT,
        headers: { Authorization: `Bearer ${token}` },
        fileType: ['jpg', 'png', 'jpeg', 'gif'],
      },
    },
    first_name: {
      label: t('account_form_first_name_label'),
    },
    last_name: {
      label: t('account_form_last_name_label'),
    },
    email: {
      label: t('account_form_email_label'),
    },
    address: {
      label: t('account_form_address_label'),
      ...google.addressConfig,
    },
    phone: {
      label: t('account_form_phone_label'),
    },
    school: {
      label: t('account_form_school_label'),
    },
    grade: {
      label: t('account_form_grade_label'),
      selectOptions: grades?.map((grade) => ({ label: grade.name, value: grade.id })),
    },
    cv: {
      label: t('account_form_cv_label'),
      icon: getIcon(user?.teacher?.cv?.status ?? null),
      config: {
        target: api.UPLOAD_ENDPOINT,
        headers: { Authorization: `Bearer ${token}` },
      },
    },
    diploma: {
      label: t('account_form_diploma_label'),
      icon: getIcon(user?.teacher?.diploma?.status ?? null),
      config: {
        target: api.UPLOAD_ENDPOINT,
        headers: { Authorization: `Bearer ${token}` },
      },
    },
    certificate: {
      label: t('account_form_certificate_label'),
      icon: getIcon(user?.teacher?.company_certificate?.status ?? null),
      config: {
        target: api.UPLOAD_ENDPOINT,
        headers: { Authorization: `Bearer ${token}` },
      },
    },
    introduction: {
      placeholder: t('account_form_introduction_placeholder'),
    },
  }

  const initialValues: ProfileFormValues = {
    avatar: '', // keep empty
    first_name: user?.first_name ?? '',
    last_name: user?.last_name ?? '',
    email: user?.email ?? '',
    phone: user?.phone ?? '',
    address: {
      address: user?.display_address ?? '',
      components: {
        city: user?.city ?? '',
      },
    },
    school: user?.student?.school ?? '',
    grade: user?.student?.grade?.id ?? '',
    cv: user?.teacher?.cv ?? { name: '', file: '' },
    diploma: user?.teacher?.diploma ?? { name: '', file: '' },
    certificate: user?.teacher?.company_certificate ?? { name: '', file: '' },
    introduction: user?.teacher?.introduction ?? '',
  }

  const accountProps: AccountTemplateProps = {
    menuProps,
    headerProps,
    backButton,
    passwordModal,
    profileForm: {
      title: t('account_hello'),
      name: user?.first_name ?? '',
      account: {
        title: t('account_form_title'),
        cta: {
          text: t('account_form_password'),
          link: {
            onClick: () => setPasswordModalOpen(true),
          },
        },
      },
      ...(user?.user_type === UserType.Student
        ? {
            studentInfos: {
              title: t('account_form_school'),
            },
          }
        : user?.user_type === UserType.Guardian
        ? {
            personalInfos: {
              title: t('account_form_personal'),
            },
          }
        : {
            personalInfos: {
              title: t('account_form_personal'),
            },
            professionalInfos: {
              title: t('account_form_professional'),
            },
          }),
      fieldsProps,
      initialValues,
      submitErrors: submit.errors,
      submitButton: {
        text: t('account_form_submit'),
        pendingText: t('account_form_submit_pending'),
        successText: t('account_form_submit_success'),
        isPending: submit.pending,
        isSuccess: submit.success,
      },
      onSubmit: handleSubmit,
    },
    ...(user?.user_type === UserType.Guardian && {
      familyProps: {
        title: t('account_family_title'),
        addChildProps: {
          text: t('account_family_add'),
          link: {
            href: router(routesPath.preferencesFamilyAddChild),
          },
        },
        children: user?.guardian?.children?.map(
          (child): ImageTextItemProps => ({
            image: { alt: child.first_name ?? '', src: child.avatar ?? '' },
            isAvatar: true,
            text1: child?.first_name ?? '',
            text2: child?.grade?.name ?? '',
            link: {
              href: router(routesPath.preferencesFamilyChild, { child: child.id }),
            },
          })
        ),
        linkCodeTitle: t('account_family_link_title'),
        linkCodeText: t('account_family_link_text'),
        linkCode: user?.guardian?.code ?? '',
      },
    }),
    disconnectCta: {
      text: t('account_logout'),
      link: {
        link: router(routesPath.signOut),
      },
    },
    deleteCta: {
      text: t('account_delete'),
      onClick: () => setDeleteAccountOpen(true),
    },
    actionSubmitModal: {
      title: t('account_deleteModal_title'),
      subtitle: t('account_deleteModal_subtitle'),
      description:
        user?.user_type === UserType.Teacher
          ? t('account_deleteModal_teacher_text')
          : t('account_deleteModal_text'),
      text: t('account_deleteModal_procedure'),
      open: deleteAccountOpen,
      onClose: () => setDeleteAccountOpen(false),
      cancelButton: {
        text: t('cancel'),
        onClick: () => t('account_deleteModal_subtitle'),
      },
      confirmButton: {
        text: t('account_deleteModal_submit'),
        onClick: () => (window.location.href = `${app.PUBLIC_URL}/aide#contact`),
      },
    },
  }

  return <AccountTemplate {...accountProps} />
}

export default AccountPage
