import React, { FC, MouseEventHandler, useRef } from 'react'

import * as SC from './styled'

export type ModalContainerProps = {
  className?: string
  open: boolean
  onClose?: () => void
}

const ModalContainer: FC<ModalContainerProps> = (props) => {
  const { className = '', open, onClose, children } = props

  const ref = useRef<HTMLDivElement | null>(null)

  const handleClick: MouseEventHandler<HTMLDivElement> = (event) => {
    if (ref?.current && !ref?.current?.contains(event?.target as Node)) {
      onClose?.()
    }
  }

  return (
    <SC.ModalContainer className={className} open={open} onClose={onClose}>
      <SC.Wrapper onClick={handleClick}>
        <SC.ModalContainerContent ref={ref}>
          <>{children}</>
        </SC.ModalContainerContent>
      </SC.Wrapper>
    </SC.ModalContainer>
  )
}

export default ModalContainer
