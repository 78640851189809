import React, { useEffect, useMemo, useState } from 'react'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import { useDispatch, useSelector } from 'react-redux'

import { HeaderProps } from '../../components/Header'
import useHeader from '../../hooks/useHeader'
import { MenuProps } from '../../components/Menu'
import useMenu from '../../hooks/useMenu'
import { UserType } from '../../generated/graphql'
import PreferencesAvailabilitiesTemplate, {
  PreferencesAvailabilitiesTabs,
  PreferencesAvailabilitiesTemplateProps,
} from '../../templates/PreferencesAvailabilities'
import { RestrictedReactFC } from '../../types/common'
import { router, routesPath } from '../../router'
import { AvailabilityPerDayFormProps } from '../../components/AvailabilityPerDayForm'
import { actions, selectors } from '../../redux'
import {
  availabilitySlotOptions,
  availabilitySlotTranslations,
  hourToSlot,
} from '../../graphql/enums/AvailabilitySlot'
import { recurrenceDayJoin } from '../../graphql/enums/RecurrenceDay'
import useBackButton from '../../hooks/useBackButton'
import { getISODateString } from '../../transformers/courseTransformers'

const PreferencesAvailabilitiesPage: RestrictedReactFC<RouteComponentProps> = (props) => {
  const headerProps: HeaderProps = useHeader()
  const menuProps: MenuProps = useMenu(props)
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation()
  const history = useHistory()
  const currentTab = useMemo(
    () =>
      props?.match?.url === router(routesPath.preferencesAvailabilities)
        ? PreferencesAvailabilitiesTabs.PER_DAY
        : PreferencesAvailabilitiesTabs.RECURRING,
    [props?.match?.url]
  )
  const today = useMemo(() => new Date(), [])

  const [currentMonth, setCurrentMonth] = useState(
    dayjs().date(1).hour(12).minute(0).second(0).millisecond(0).toDate()
  )
  const [day, setDay] = useState<Date | undefined>(
    dayjs().hour(12).minute(0).second(0).millisecond(0).toDate()
  )
  const availabilitiesOfMonth = useSelector(selectors.preferences.teacherAvailabilitiesOfMonth)
  const update = useSelector(selectors.preferences.updateTeacherAvailabilityOfDay)
  const recurrences = useSelector(selectors.preferences.teacherAvailabilityRecurrences)

  const backButton = useBackButton(router(routesPath.preferences))

  const dates = useMemo(
    () =>
      availabilitiesOfMonth?.data?.map((day) => ({
        date: new Date(day.date),
        slots: day.slots,
        isRecurring: day?.isRecurring,
      })) || [],
    [availabilitiesOfMonth?.data]
  )

  const byDay = useMemo(
    () =>
      dates?.reduce((arr, date) => {
        arr[date.date.toISOString()] = date.slots
        return arr
      }, {} as any),
    [dates]
  )

  const weekDays = useMemo(
    () =>
      dayjs
        .localeData()
        .weekdays()
        ?.map((d) => d.substr(0, 1).toUpperCase()),
    []
  )

  const months = useMemo(
    () =>
      dayjs
        .localeData()
        .months()
        ?.map((d) => d.substr(0, 1).toUpperCase().concat(d.substr(1))),
    []
  )

  const disabledSlots = useMemo(() => {
    const slots: { [key: string]: string[] } = {}

    // remove passed slots

    const today = dayjs()
    const passedSlots = []
    let hour = today.hour()
    let slot
    while ((slot = hourToSlot(hour--))) {
      passedSlots.unshift(slot)
    }
    const todayIsoString = getISODateString()
    if (!slots?.[todayIsoString]) {
      slots[todayIsoString] = passedSlots
    } else {
      slots[todayIsoString] = slots[todayIsoString].concat(passedSlots)
    }

    return slots
  }, [])

  const perDayForm: AvailabilityPerDayFormProps = useMemo(() => {
    return {
      onSubmit: (values) => {
        dispatch(
          actions.preferences.updateTeacherAvailabilityOfDayRequest({
            date: dayjs(values?.date).format('YYYY-MM-DD'),
            slots: values?.slots,
          })
        )
      },
      initialValues: {
        date: day,
        slots: (day && byDay[day?.toISOString()]) ?? [],
      },
      calendar: {
        today,
        recurringDays: dates
          ?.filter((d) => d?.isRecurring && d?.slots?.length)
          ?.map((date) => date.date),
        dateDisableBefore: today,
        alreadyDays: dates
          ?.filter((d) => !d?.isRecurring && d?.slots?.length)
          ?.map((date) => date.date),
        onMonthChange: (month) => {
          setCurrentMonth(month)
        },
        locale: {
          lang: i18n.language,
          months: months,
          weekdaysS: weekDays,
          weekdaysL: weekDays,
        },
        onDayChange: (d) => setDay(d),
      },
      slot: {
        name: '',
        fields: availabilitySlotOptions(t),
      },
      disabledSlots,
      submitButton: {
        text: t('preferences_availabilities_perDay_submit_label'),
        isPending: update?.pending,
        autoIcon: true,
      },
    }
  }, [
    day,
    byDay,
    today,
    dates,
    i18n.language,
    months,
    weekDays,
    t,
    update?.pending,
    dispatch,
    disabledSlots,
  ])

  const preferencesProps: PreferencesAvailabilitiesTemplateProps = {
    menuProps,
    headerProps,
    title: t('preferences_availabilities_title'),
    perDayTitle: t('preferences_availabilities_perDay_label'),
    recurringTitle: t('preferences_availabilities_recurring_label'),
    perDayForm,
    recurrences: recurrences?.data?.map((recurrence) => ({
      weekdays: recurrenceDayJoin(t, recurrence.days),
      hours: recurrence?.slots ? availabilitySlotTranslations(t, recurrence.slots) : undefined,
      startDate: dayjs(recurrence.start_at, 'YYYY-MM-DD').format('DD.MM.YYYY'),
      endDate: dayjs(recurrence.end_at, 'YYYY-MM-DD').format('DD.MM.YYYY'),
      cta: {
        text: t('edit_label'),
        link: {
          link: router(routesPath.preferencesAvailabilitiesRecurrentEdit, { id: recurrence.id }),
        },
      },
      onDelete: () =>
        dispatch(actions.preferences.deleteTeacherAvailabilityRecurrenceRequest(recurrence.id)),
    })),
    addRecurrenceProps: {
      text: t('preferences_availabilities_recurring_add_label'),
      link: {
        href: router(routesPath.preferencesAvailabilitiesRecurrentAdd),
      },
    },
    backButton,
    currentTab,
    onTabChange: (tab) => {
      history.push(
        router(
          tab === PreferencesAvailabilitiesTabs.PER_DAY
            ? routesPath.preferencesAvailabilities
            : routesPath.preferencesAvailabilitiesRecurrent
        )
      )
    },
  }

  //
  // EFFECTS
  //
  useEffect(() => {
    if (currentTab === PreferencesAvailabilitiesTabs.PER_DAY) {
      const date = dayjs(currentMonth)
      const year = date.year()
      const month = date.month() + 1
      dispatch(actions.preferences.teacherAvailabilitiesOfMonthRequest({ year, month }))
    } else {
      dispatch(actions.preferences.teacherAvailabilityRecurrencesRequest(undefined))
    }
  }, [dispatch, currentMonth, currentTab])

  return <PreferencesAvailabilitiesTemplate {...preferencesProps} />
}

PreferencesAvailabilitiesPage.restrictedUserTypes = [UserType.Teacher]

export default PreferencesAvailabilitiesPage
