import { Container } from '@material-ui/core'
import styled, { keyframes } from 'styled-components'

import TextField from '../form/TextField'
import PaymentFieldC from '../form/PaymentField'
import ActionButtonC from '../ActionButton'
import FormSubmit from '../FormSubmit'
import Number from '../form/Number'
import Icon from '../Icon'

export const StripePaymentForm = styled.div`
  ${(props) => props.theme.breakpoints.up('md')} {
    max-width: 40rem;
  }
`

export const Wrapper = styled(Container)``

export const Text = styled.p`
  ${(props) => ({ ...props.theme.typography.big })}
  color: ${(props) => props.theme.palette.colors.midnightBlue};
  margin: 0;
`

export const Fields = styled.div`
  margin: 1.6rem 0 2.4rem 0;
`

export const PaymentField = styled(PaymentFieldC)``

export const Field = styled(TextField)`
  margin: 1.6rem 0;
`

export const Amount = styled(Number)`
  margin: 1.6rem 0;
`

export const Actions = styled.div`
  display: flex;
  margin: 4rem -0.8rem 0.8rem;
`

export const CancelButton = styled(ActionButtonC)`
  margin-top: 2rem;
  width: auto;
  flex: 1;
  margin: 0.8rem;
`

export const SubmitButton = styled(FormSubmit)`
  margin: 0.8rem;
  flex: 1;
`

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`
export const Loading = styled(Icon)`
  animation: ${rotate} 2s linear infinite;
  width: 3rem;
  margin-left: 1.6rem;
  color: ${(props) => props.theme.palette.colors.neonCarrot} !important;
`
