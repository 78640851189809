import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { HeaderProps } from '../../components/Header'
import useHeader from '../../hooks/useHeader'
import { MenuProps } from '../../components/Menu'
import useMenu from '../../hooks/useMenu'
import { PotTemplate } from '../../templates'
import { PayoutScheduleInterval, TransactionLimitFilter, UserType } from '../../generated/graphql'
import { RestrictedReactFC } from '../../types/common'
import { PotTemplateProps } from '../../templates/Pot'
import { actions, selectors } from '../../redux'
import { walletTransactionToCardProps } from '../../transformers/walletTransformers'
import useBackButton from '../../hooks/useBackButton'
import { WalletConfigurationProps } from '../../components/WalletConfiguration'
import { PotProps } from '../../components/Pot'
import { WalletPeriodicityFormValues } from '../../components/WalletPeriodicityForm'
import {
  payoutScheduleIntervalArray,
  payoutScheduleIntervalDescriptionTranslation,
  payoutScheduleIntervalTranslation,
} from '../../graphql/enums/PayoutScheduleInterval'
import { app } from '../../configuration'
import { router, routesPath } from '../../router'
import { useFormSubmit } from '../../hooks/useFormSubmit'

const PotPage: RestrictedReactFC<RouteComponentProps> = (props) => {
  const headerProps: HeaderProps = useHeader()
  const menuProps: MenuProps = useMenu(props)
  const { t } = useTranslation()
  const user = useSelector(selectors.auth.user)
  const dispatch = useDispatch()
  const [page, setPage] = useState(1)
  const walletTransactions = useSelector(selectors.preferences.walletTransactions)
  const backButton = useBackButton()

  const loadStripeAccountLink = useSelector(selectors.auth.loadStripeLoginLink)

  const [value, setValue] = useState(TransactionLimitFilter.Month as string)
  const [periodicityModalOpen, setPeriodicityModalOpen] = useState(false)

  const defaultPeriodicity =
    user?.teacher?.payouts_schedule_interval ?? PayoutScheduleInterval.Weekly

  useEffect(() => {
    dispatch(actions.auth.loadStripeLoginLinkRequest(app.APP_URL + router(routesPath.pot)))
  }, [dispatch])

  const onComplete = useCallback(() => {
    setPeriodicityModalOpen(false)
  }, [])

  const [updatePeriodicity, handleUpdatePeriodicity] = useFormSubmit(
    selectors.preferences.updatePayoutSchedule,
    actions.preferences.updatePayoutScheduleRequest,
    actions.preferences.updatePayoutScheduleReset,
    onComplete
  )

  const configurationProps: WalletConfigurationProps = useMemo(
    () => ({
      title: t('wallet_configuration_title'),
      periodicityLabel: t('wallet_configuration_periodicity_label'),
      periodicityValue: payoutScheduleIntervalTranslation(t, defaultPeriodicity),
      periodicityButton: {
        text: t('edit_label'),
        onClick: () => setPeriodicityModalOpen(true),
      },
      dashboardButton: {
        text: t('wallet_configuration_stripe_cta'),
        isPending: loadStripeAccountLink?.pending,
        type: 'button',
        onClick: () => {
          loadStripeAccountLink?.data && window.open(loadStripeAccountLink?.data)
        },
      },
      modalProps: {
        headerText: t('wallet_configuration_periodicity_modal_title'),
        text: t('wallet_configuration_periodicity_modal_text'),
        options: payoutScheduleIntervalArray?.map((periodicity) => ({
          label: t('wallet_configuration_periodicity_option_title', {
            option: payoutScheduleIntervalTranslation(t, periodicity),
          }),
          description: payoutScheduleIntervalDescriptionTranslation(t, periodicity),
        })),
        open: periodicityModalOpen,
        onClose: () => setPeriodicityModalOpen(false),
        formProps: {
          fieldsProps: {
            periodicity: {
              label: '',
              select: true,
              selectOptions: payoutScheduleIntervalArray.map((periodicity) => ({
                label: payoutScheduleIntervalTranslation(t, periodicity),
                value: periodicity,
              })),
            },
          },
          initialValues: {
            periodicity: defaultPeriodicity ?? PayoutScheduleInterval.Weekly,
          },
          errorTexts: {
            required: t('error_required'),
          },
          skipButton: {
            text: t('cancel'),
            onClick: () => setPeriodicityModalOpen(false),
            outlined: true,
          },
          submitButton: {
            text: t('submit_label'),
            isPending: updatePeriodicity.pending,
          },
          onSubmit: (v: WalletPeriodicityFormValues) =>
            handleUpdatePeriodicity({
              interval: v.periodicity,
            }),
          submitErrors: updatePeriodicity?.errors,
        },
      },
    }),
    [
      t,
      defaultPeriodicity,
      loadStripeAccountLink?.pending,
      loadStripeAccountLink?.data,
      periodicityModalOpen,
      updatePeriodicity.pending,
      updatePeriodicity?.errors,
      handleUpdatePeriodicity,
    ]
  )

  const potProps: PotProps = useMemo(
    () => ({
      image: { alt: 'LiberteClass - Cagnotte', src: '/static/assets/images/pot.png' },
      text: t('wallet_title'),
      credits: t('wallet_amount_value', { amount: (user?.wallet?.amount ?? 0) / 100 }),
      otherCredits:
        (user?.wallet?.reserved ?? 0) > 0
          ? t('wallet_teacher_locked_value', {
              amount: (user?.wallet?.reserved ?? 0) / 100,
            })
          : '',
    }),
    [t, user?.wallet?.amount, user?.wallet?.reserved]
  )

  const templateProps: PotTemplateProps = {
    headerProps,
    menuProps,
    backButton,
    configurationProps,
    potProps,
    title: t('wallet_history_title'),
    filters: [
      { label: t('wallet_history_filters_month'), value: TransactionLimitFilter.Month },
      { label: t('wallet_history_filters_trimester'), value: TransactionLimitFilter.Quarter },
      { label: t('wallet_history_filters_all'), value: 'all' },
    ],
    filterValue: value,
    onFilterChange: setValue,
    cards:
      walletTransactions?.data?.data?.map((transaction) =>
        walletTransactionToCardProps(transaction, t, user ?? undefined)
      ) ?? [],
    ...((walletTransactions?.pending || walletTransactions?.data?.paginatorInfo?.hasMorePages) && {
      buttonProps: {
        text: t('load_more'),
        pendingText: t('load_more_pending'),
        autoIcon: true,
        outlined: true,
        isPending: walletTransactions?.pending,
        onClick: () => setPage((walletTransactions?.data?.paginatorInfo?.currentPage ?? 0) + 1),
      },
    }),
  }

  useEffect(() => {
    dispatch(
      actions.preferences.walletTransactionsRequest({
        first: 50,
        page,
        ...(value === 'all' ? {} : { limit: value }),
      })
    )
  }, [dispatch, page, value, user?.user_type])

  return <PotTemplate {...templateProps} />
}

PotPage.restrictedUserTypes = [UserType.Teacher]

export default PotPage
