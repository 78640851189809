import gql from 'graphql-tag'

import { CourseType } from '../../../../generated/graphql'
import CourseFragment from '../../../fragments/CourseFragment'
import PaginatorInfoFragment from '../../../fragments/PaginatorInfoFragment'
import { TeacherClassRequestsData } from '../../../../redux/search/types/actions'

const teacherPendingCourses = {
  query: gql`
    query teacherPendingCourses($type: CourseType!) {
      ONE_OFF: teacherPendingCourses(first: 1, type: ONE_OFF) {
        paginatorInfo {
          total
        }
      }
      RECURRENT: teacherPendingCourses(first: 1, type: RECURRENT) {
        paginatorInfo {
          total
        }
      }
      EMERGENCY: teacherPendingCourses(first: 1, type: EMERGENCY) {
        paginatorInfo {
          total
        }
      }
      teacherPendingCourses(first: 100, type: $type) {
        data {
          ...CourseFragment
        }
        paginatorInfo {
          ...PaginatorInfoFragment
        }
      }
    }
    ${CourseFragment}
    ${PaginatorInfoFragment}
  `,
  transformer: (response: any): TeacherClassRequestsData => {
    return {
      data: response.teacherPendingCourses?.data,
      paginatorInfo: response.teacherPendingCourses?.paginatorInfo,
      types: Object.values(CourseType).reduce(
        (arr, key) =>
          ({
            ...arr,
            [key]: response?.[key]?.paginatorInfo?.total,
          } as { [key: string]: number }),
        {}
      ),
    }
  },
}

export default teacherPendingCourses
