import { useEffect, useState } from 'react'
import { loadStripe } from '@stripe/stripe-js'

export enum StripeLoadStatus {
  INITIAL = 'initial',
  LOADING = 'loading',
  LOADED = 'loaded',
}

const stripePromise: { [key: string]: Promise<any> | null } = {}
const stripePromiseStatus: { [key: string]: StripeLoadStatus } = {}

function useLoadStripe(key: string): [Promise<any> | null, StripeLoadStatus] {
  const [status, setStatus] = useState<StripeLoadStatus>(
    stripePromiseStatus[key] || StripeLoadStatus.INITIAL
  )

  useEffect(() => {
    if (!stripePromise[key]) {
      setStatus(StripeLoadStatus.LOADING)
      stripePromise[key] = loadStripe(key)
    }
    stripePromise[key]?.then(() => {
      setStatus(StripeLoadStatus.LOADED)
    })
  }, [key])

  useEffect(() => {
    stripePromiseStatus[key] = status
  }, [status, key])

  return [stripePromise[key], status]
}

export default useLoadStripe
