import { Container } from '@material-ui/core'
import styled from 'styled-components'

import RatingCustom from '../RatingCustom'
import Link from '../Link'

export const BasicProfil = styled(Link)`
  text-decoration: none;
  display: flex;
  &:hover {
    text-decoration: none;
  }
`

export const Wrapper = styled(Container)``

export const Profile = styled.div`
  display: flex;
  align-items: center;
`

export const Image = styled.img`
  width: 6.4rem;
  height: 6.4rem;
  object-fit: cover;
  border-radius: 50%;
`

export const ProfileInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 2rem;
`

export const Name = styled.span<{ color?: string }>`
  ${(props) => ({ ...props.theme.typography.h4 })}
  color: ${(props) => (props.color ? props.color : props.theme.palette.colors.freeSpeechBlue)};
`

export const Level = styled.span<{ color?: string }>`
  ${(props) => ({ ...props.theme.typography.h7 })}
  color: ${(props) => (props.color ? props.color : props.theme.palette.colors.cornflowerBlue)};
`

export const Commentary = styled.p`
  ${(props) => ({ ...props.theme.typography.smallRegular })}
  color: ${(props) => props.theme.palette.colors.chambray};
`

export const Rating = styled.div`
  display: grid;
  grid-template-columns: 1fr 5rem;
  align-items: center;
  width: 100%;
  grid-column: 1 / 3;
`

export const RatingText = styled.div`
  ${({ theme }) => ({ ...theme.typography.h4 })}
  color: ${({ theme }) => theme.palette.colors.neonCarrot};
`

export const Stars = styled(RatingCustom)``
