import gql from 'graphql-tag'

import { Course } from '../../../../generated/graphql'
import CourseRequestFragment from '../../../fragments/CourseRequestFragment'

const courseRequestById = {
  query: gql`
    query courseRequestById($id: ID!) {
      courseRequestById(id: $id) {
        ...CourseRequestFragment
      }
    }
    ${CourseRequestFragment}
  `,
  transformer: (response: any): Course => {
    return response.courseRequestById
  },
}

export default courseRequestById
