import React, { useCallback, useEffect, useMemo } from 'react'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { HeaderProps } from '../../components/Header'
import useHeader from '../../hooks/useHeader'
import { MenuProps } from '../../components/Menu'
import useMenu from '../../hooks/useMenu'
import {
  CourseType,
  Lesson,
  LessonType,
  MutationCreateLessonArgs,
  Theme,
  UserType,
} from '../../generated/graphql'
import { RestrictedReactFC } from '../../types/common'
import { actions, selectors } from '../../redux'
import { router, routesPath } from '../../router'
import { SearchThemeTemplate, SearchThemeTemplateProps } from '../../templates/SearchTheme'
import { SearchSteps } from '../../redux/search/types/state'
import { courseTypeBySlug } from '../../graphql/enums/CourseType'
import useBackButton from '../../hooks/useBackButton'
import { CustomThemeFormProps } from '../../components/CustomThemeForm'
import { api } from '../../configuration'
import { useFormSubmit } from '../../hooks/useFormSubmit'
import { ThemeListProps } from '../../components/ThemeList'

const SearchThemePage: RestrictedReactFC<
  RouteComponentProps<{ type: string; discipline: string; child?: string }>
> = (props) => {
  const headerProps: HeaderProps = useHeader()
  const menuProps: MenuProps = useMenu(props)
  const { t } = useTranslation()
  const history = useHistory()
  const user = useSelector(selectors.auth.user)
  const token = useSelector(selectors.auth.token)
  const typeSlug = props?.match?.params?.type
  const type: CourseType = courseTypeBySlug(t, typeSlug)
  const child = props?.match?.params?.child
  const disciplines = useSelector(selectors.app.disciplines)
  const discipline = useMemo(
    () => disciplines?.find((discipline) => discipline.slug === props?.match?.params?.discipline),
    [disciplines, props?.match?.params?.discipline]
  )
  const student =
    user?.user_type === UserType.Student
      ? user?.student
      : user?.guardian?.children?.find((c) => c.id === child) ?? user?.guardian?.children?.[0]

  const lesson = useSelector(selectors.search.lesson)
  const themes = useSelector(selectors.search.themes)
  const dispatch = useDispatch()

  const backButton = useBackButton(
    router(routesPath.searchDiscipline, { type: typeSlug, ...(child && { child }) })
  )

  const handleSelect = useCallback(
    (value?: Lesson) => {
      console.log('add lesson', value)
      dispatch(
        actions.search.saveStep({
          step: SearchSteps.STEP_2_THEME,
          type,
          lesson: value,
          student,
          discipline,
        })
      )
      history.push(
        router(routesPath.searchPrice, {
          type: typeSlug,
          discipline: discipline?.slug ?? '',
          theme: value?.slug ?? '',
          ...(child && { child }),
        })
      )
    },
    [child, discipline, dispatch, history, student, type, typeSlug]
  )

  const onCreateComplete = useCallback(() => {
    handleSelect(lesson as Lesson)
  }, [lesson, handleSelect])

  const [createLesson, handleCreateLesson] = useFormSubmit(
    selectors.search.createLesson,
    actions.search.createLessonRequest,
    actions.search.createLessonReset,
    onCreateComplete
  )

  const customThemeFormProps: CustomThemeFormProps = {
    fieldsProps: {
      name: {
        label: t('searchTheme_custom_title'),
        placeholder: t('searchTheme_custom_name_placeholder'),
        isLabelExternal: true,
      },
      file: {
        placeholder: t('searchTheme_custom_file_placeholder'),
        removable: true,
        config: {
          target: api.UPLOAD_ENDPOINT,
          headers: { Authorization: `Bearer ${token}` },
          fileType: ['pdf', 'jpg', 'png', 'jpeg'],
        },
      },
    },
    submitButton: {
      text: t('submit_label'),
      isPending: createLesson.pending,
    },
    onSubmit: (values) => {
      const input: MutationCreateLessonArgs = {
        discipline: discipline?.id ?? '',
        name: values.name,
        ...(child && { student: child }),
        ...(values.file && { file: values.file }),
      }
      handleCreateLesson(input)
    },
    errorTexts: {
      required: t('error_required'),
    },
    submitErrors: createLesson.errors,
    initialValues: {
      name: '',
      file: undefined,
    },
  }

  const themeList: Theme[] = useMemo(() => {
    const tmp: Theme[] = Array.from(themes?.data ?? [])
    if (lesson?.type === LessonType.Custom) {
      tmp.unshift({
        name: t('searchTheme_custom_last'),
        lessons: [lesson] as Lesson[],
      } as Theme)
    }
    return tmp
  }, [lesson, themes?.data, t])

  const searchProps: SearchThemeTemplateProps = {
    headerProps: headerProps,
    menuProps: menuProps,
    title: t('search_title'),
    subTitle: t('searchTheme_subtitle'),
    discipline: discipline?.name ?? '',
    customThemeFormProps,
    themeListsProps: themeList?.map((theme: Theme) => ({
      title: theme?.name,
      themes: theme?.lessons?.map((lesson) => ({ value: lesson?.id, title: lesson?.name })),
      onChange: (_name: string, value: string) =>
        handleSelect(theme?.lessons?.find((l) => l?.id === value)),
      value: lesson?.id,
    })) as ThemeListProps[],
    backButton,
  }

  useEffect(() => {
    if (discipline?.id && student?.grade?.id) {
      if (
        themes?.params?.grade !== student?.grade?.id ||
        themes?.params?.discipline !== discipline?.id
      ) {
        dispatch(
          actions.search.themesRequest({
            discipline: discipline?.id,
            grade: student?.grade?.id,
          })
        )
      }
    }
  }, [discipline?.id, dispatch, student?.grade?.id, themes?.params])

  return <SearchThemeTemplate {...searchProps} />
}

SearchThemePage.restrictedUserTypes = [UserType.Guardian, UserType.Student]

export default SearchThemePage
