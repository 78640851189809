import { Container } from '@material-ui/core'
import styled from 'styled-components'

import CourseCardC from '../CourseCard'
import MoneyCardC from '../MoneyCard'
import RecurrenceCardC from '../RecurrenceCard'
import ScheduleResultCardC from '../ScheduleResultCard'
import FormSubmit from '../FormSubmit'
import CourseMaterialsCardC from '../CourseMaterialsCard'

export const CardList = styled.div``

export const Wrapper = styled(Container)`
  padding: 0;
`

export const Cards = styled.div`
  margin: -0.8rem -0.8rem 1.6rem;
  display: flex;
  flex-direction: column;
  & > * {
    margin: 0.8rem;
  }
  ${(props) => props.theme.breakpoints.up('md')} {
    flex-wrap: wrap;
    flex-direction: row;
  }
`

export const CourseCard = styled(CourseCardC)``
export const CourseMaterialsCard = styled(CourseMaterialsCardC)``

export const MoneyCard = styled(MoneyCardC)``

export const RecurrenceCard = styled(RecurrenceCardC)``

export const ScheduleResultCard = styled(ScheduleResultCardC)``

export const Actions = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  ${(props) => props.theme.breakpoints.up('md')} {
    max-width: 40rem;
  }
`

export const Button = styled(FormSubmit)`
  margin: 3rem auto 1.6rem;
  justify-self: center;
  max-width: 45rem;
`
