import gql from 'graphql-tag'

import LessonFragment from './LessonFragment'
import PublicTeacherFragment from './PublicTeacherFragment'
import CourseRequestFragment from './CourseRequestFragment'
import StudentReviewFragment from './StudentReviewFragment'

export default gql`
  fragment CourseFragment on Course {
    id
    type
    status
    date
    slot
    maximum_price
    maximum_participants
    price_per_participant
    participants
    teacher_price
    room {
      available_at
      available
      expired
      name
      hash
    }
    lesson {
      ...LessonFragment
    }
    teacher {
      ...PublicTeacherFragment
    }
    requests {
      ...CourseRequestFragment
    }
    studentReviews {
      ...StudentReviewFragment
    }
  }
  ${LessonFragment}
  ${PublicTeacherFragment}
  ${CourseRequestFragment}
  ${StudentReviewFragment}
`
