export default {
  domain: '8x8.vc',
  url: 'https://8x8.vc/external_api.js',
  appID: process.env.REACT_APP_JITSI_APP_ID,
  config: (isModerator: boolean) => ({
    // Disables moderator indicators.
    // disableModeratorIndicator: false,
    // Disables the reactions feature.
    // disableReactions: true,
    // Disables polls feature.
    disablePolls: true,
    // Enabling this will show a "Save Logs" link in the GSM popover that can be
    // used to collect debug information (XMPP IQs, SDP offer/answer cycles)
    // about the call.
    enableSaveLogs: false,
    // Enabling this will hide the "Show More" link in the GSM popover that can be
    // used to display more statistics about the connection (IP, Port, protocol, etc).
    disableShowMoreStats: true,
    // Disables responsive tiles.
    // disableResponsiveTiles: false,
    // Hides lobby button
    // hideLobbyButton: false,
    // Require users to always specify a display name.
    // requireDisplayName: true,
    // Whether to use a welcome page or not. In case it's false a random room
    // will be joined when no room is specified.
    enableWelcomePage: true,
    // Disable app shortcuts that are registered upon joining a conference
    // disableShortcuts: false,

    // Disable initial browser getUserMedia requests.
    // This is useful for scenarios where users might want to start a conference for screensharing only
    // disableInitialGUM: false,

    // Enabling the close page will ignore the welcome page redirection when
    // a call is hangup.
    // enableClosePage: false,
    defaultLanguage: 'fr',
    // Disables profile and the edit of all fields from the profile settings (display name and email)
    disableProfile: true,
    // Whether or not some features are checked based on token.
    enableFeaturesBasedOnToken: false,
    // When 'true', it shows an intermediate page before joining, where the user can configure their devices.
    prejoinPageEnabled: true,
    // When 'true', the user cannot edit the display name.
    // (Mainly useful when used in conjuction with the JWT so the JWT name becomes read only.)
    readOnlyName: true,
    toolbarButtons: [
      'camera',
      'chat',
      //'closedcaptions',
      'desktop',
      'download',
      //'embedmeeting',
      //'etherpad',
      //'feedback',
      'filmstrip',
      'fullscreen',
      'hangup',
      'help',
      //'invite',
      //'livestreaming',
      'microphone',
      'mute-everyone',
      'mute-video-everyone',
      'participants-pane',
      //'profile',
      'raisehand',
      'recording',
      //'security',
      'select-background',
      'settings',
      //'shareaudio',
      //'sharedvideo',
      isModerator ? 'shortcuts' : '',
      isModerator ? 'stats' : '',
      //'tileview',
      'toggle-camera',
      //'videoquality',
      //'__end',
      //'localrecording',
      'whiteboard',
    ],
    whiteboard: {
      enabled: true,
    },
    hiddenPremeetingButtons: ['invite'],
    // If third party requests are disabled, no other server will be contacted.
    // This means avatars will be locally generated and callstats integration
    // will not function.
    // disableThirdPartyRequests: false,
    //localRecording: {
    //  enabled: true,
    //}
    disableInviteFunctions: true,
    remoteVideoMenu: {
      // If set to true the 'Kick out' button will be disabled.
      //     disableKick: true,
      // If set to true the 'Grant moderator' button will be disabled.
      disableGrantModerator: true,
    },
    // If set to true all muting operations of remote participants will be disabled.
    // disableRemoteMute: true,
    // When true the user cannot add more images to be used as virtual background.
    // Only the default ones from will be available.
    // disableAddingBackgroundImages: false,
    // Hides the conference subject
    hideConferenceSubject: true,
    // Hides the recording label
    // hideRecordingLabel: false,
    /**
     Use this array to configure which notifications will be shown to the user
     The items correspond to the title or description key of that notification
     Some of these notifications also depend on some other internal logic to be displayed or not,
     so adding them here will not ensure they will always be displayed
     A falsy value for this prop will result in having all notifications enabled (e.g null, undefined, false)
     */
    // notifications: [
    //     'connection.CONNFAIL', // shown when the connection fails,
    //     'dialog.cameraNotSendingData', // shown when there's no feed from user's camera
    //     'dialog.kickTitle', // shown when user has been kicked
    //     'dialog.liveStreaming', // livestreaming notifications (pending, on, off, limits)
    //     'dialog.lockTitle', // shown when setting conference password fails
    //     'dialog.maxUsersLimitReached', // shown when maximmum users limit has been reached
    //     'dialog.micNotSendingData', // shown when user's mic is not sending any audio
    //     'dialog.passwordNotSupportedTitle', // shown when setting conference password fails due to password format
    //     'dialog.recording', // recording notifications (pending, on, off, limits)
    //     'dialog.remoteControlTitle', // remote control notifications (allowed, denied, start, stop, error)
    //     'dialog.reservationError',
    //     'dialog.serviceUnavailable', // shown when server is not reachable
    //     'dialog.sessTerminated', // shown when there is a failed conference session
    //     'dialog.sessionRestarted', // show when a client reload is initiated because of bridge migration
    //     'dialog.tokenAuthFailed', // show when an invalid jwt is used
    //     'dialog.transcribing', // transcribing notifications (pending, off)
    //     'dialOut.statusMessage', // shown when dial out status is updated.
    //     'liveStreaming.busy', // shown when livestreaming service is busy
    //     'liveStreaming.failedToStart', // shown when livestreaming fails to start
    //     'liveStreaming.unavailableTitle', // shown when livestreaming service is not reachable
    //     'lobby.joinRejectedMessage', // shown when while in a lobby, user's request to join is rejected
    //     'lobby.notificationTitle', // shown when lobby is toggled and when join requests are allowed / denied
    //     'localRecording.localRecording', // shown when a local recording is started
    //     'notify.disconnected', // shown when a participant has left
    //     'notify.connectedOneMember', // show when a participant joined
    //     'notify.connectedTwoMembers', // show when two participants joined simultaneously
    //     'notify.connectedThreePlusMembers', // show when more than 2 participants joined simultaneously
    //     'notify.grantedTo', // shown when moderator rights were granted to a participant
    //     'notify.invitedOneMember', // shown when 1 participant has been invited
    //     'notify.invitedThreePlusMembers', // shown when 3+ participants have been invited
    //     'notify.invitedTwoMembers', // shown when 2 participants have been invited
    //     'notify.kickParticipant', // shown when a participant is kicked
    //     'notify.moderationStartedTitle', // shown when AV moderation is activated
    //     'notify.moderationStoppedTitle', // shown when AV moderation is deactivated
    //     'notify.moderationInEffectTitle', // shown when user attempts to unmute audio during AV moderation
    //     'notify.moderationInEffectVideoTitle', // shown when user attempts to enable video during AV moderation
    //     'notify.moderationInEffectCSTitle', // shown when user attempts to share content during AV moderation
    //     'notify.mutedRemotelyTitle', // shown when user is muted by a remote party
    //     'notify.mutedTitle', // shown when user has been muted upon joining,
    //     'notify.newDeviceAudioTitle', // prompts the user to use a newly detected audio device
    //     'notify.newDeviceCameraTitle', // prompts the user to use a newly detected camera
    //     'notify.passwordRemovedRemotely', // shown when a password has been removed remotely
    //     'notify.passwordSetRemotely', // shown when a password has been set remotely
    //     'notify.raisedHand', // shown when a partcipant used raise hand,
    //     'notify.startSilentTitle', // shown when user joined with no audio
    //     'notify.unmute', // shown to moderator when user raises hand during AV moderation
    //     'prejoin.errorDialOut',
    //     'prejoin.errorDialOutDisconnected',
    //     'prejoin.errorDialOutFailed',
    //     'prejoin.errorDialOutStatus',
    //     'prejoin.errorStatusCode',
    //     'prejoin.errorValidation',
    //     'recording.busy', // shown when recording service is busy
    //     'recording.failedToStart', // shown when recording fails to start
    //     'recording.unavailableTitle', // shown when recording service is not reachable
    //     'toolbar.noAudioSignalTitle', // shown when a broken mic is detected
    //     'toolbar.noisyAudioInputTitle', // shown when noise is detected for the current microphone
    //     'toolbar.talkWhileMutedPopup', // shown when user tries to speak while muted
    //     'transcribing.failedToStart' // shown when transcribing fails to start
    // ],

    /**
     External API url used to receive branding specific information.
     If there is no url set or there are missing fields, the defaults are applied.
     The config file should be in JSON.
     None of the fields are mandatory and the response must have the shape:
     {
        // The domain url to apply (will replace the domain in the sharing conference link/embed section)
        inviteDomain: 'example-company.org,
        // The hex value for the colour used as background
        backgroundColor: '#fff',
        // The url for the image used as background
        backgroundImageUrl: 'https://example.com/background-img.png',
        // The anchor url used when clicking the logo image
        logoClickUrl: 'https://example-company.org',
        // The url used for the image used as logo
        logoImageUrl: 'https://example.com/logo-img.png',
        // Overwrite for pool of background images for avatars
        avatarBackgrounds: ['url(https://example.com/avatar-background-1.png)', '#FFF'],
        // The lobby/prejoin screen background
        premeetingBackground: 'url(https://example.com/premeeting-background.png)',
        // A list of images that can be used as video backgrounds.
        // When this field is present, the default images will be replaced with those provided.
        virtualBackgrounds: ['https://example.com/img.jpg'],
        // Object containing a theme's properties. It also supports partial overwrites of the main theme.
        // For a list of all possible theme tokens and their current defaults, please check:
        // https://github.com/jitsi/jitsi-meet/tree/master/resources/custom-theme/custom-theme.json
        // For a short explanations on each of the tokens, please check:
        // https://github.com/jitsi/jitsi-meet/blob/master/react/features/base/ui/Tokens.js
        // IMPORTANT!: This is work in progress so many of the various tokens are not yet applied in code
        // or they are partially applied.
        customTheme: {
            palette: {
                ui01: "orange !important",
                ui02: "maroon",
                surface02: 'darkgreen',
                ui03: "violet",
                ui04: "magenta",
                ui05: "blueviolet",
                field02Hover: 'red',
                action01: 'green',
                action01Hover: 'lightgreen',
                action02Disabled: 'beige',
                success02: 'cadetblue',
                action02Hover: 'aliceblue'
            },
            typography: {
                labelRegular: {
                    fontSize: 25,
                    lineHeight: 30,
                    fontWeight: 500
                }
            }
        }
    }
     */
    // dynamicBrandingUrl: '',

    // When true the user cannot add more images to be used as virtual background.
    // Only the default ones from will be available.
    disableAddingBackgroundImages: false,

    // Disables using screensharing as virtual background.
    // disableScreensharingVirtualBackground: false,

    // Sets the background transparency level. '0' is fully transparent, '1' is opaque.
    // backgroundAlpha: 1,
  }),
  interfaceConfig: {},
}
