import React, { FC } from 'react'

import { ActionButtonProps } from '../../ActionButton'
import ModalContainer from '../ModalContainer'
import { FormSubmitProps } from '../../FormSubmit'
import FormSubmitErrors, { FormSubmitErrorsProps } from '../../FormSubmitErrors'
import { BasicProfilProps } from '../../BasicProfil'

import * as SC from './styled'

export type CourseDetailsModalProps = {
  className?: string
  title: string
  date: string
  teacherProfile: BasicProfilProps
  subjectLabel?: string
  subject?: string
  gradeLabel?: string
  grade?: string
  priceLabel?: string
  price?: string
  descriptionLabel?: string
  description?: string
  cancelButton?: ActionButtonProps
  confirmButton?: FormSubmitProps
  open: boolean
  handleClose: () => void
  onClose?: () => void
  lessonLabel?: string
  lesson?: string
  submitErrors?: FormSubmitErrorsProps['errors']
}

const CourseDetailsModal: FC<CourseDetailsModalProps> = (props) => {
  const {
    className,
    title,
    subjectLabel,
    subject,
    priceLabel,
    price,
    gradeLabel,
    grade,
    descriptionLabel,
    description,
    cancelButton,
    confirmButton,
    open,
    handleClose,
    onClose,
    lessonLabel,
    lesson,
    submitErrors,
    teacherProfile,
    date,
  } = props

  return (
    <ModalContainer open={open} onClose={onClose} className={className}>
      <SC.Content>
        {title && <SC.Title>{title}</SC.Title>}
        <SC.Date>{date}</SC.Date>
        {teacherProfile && <SC.BasicProfil {...teacherProfile} />}
        <SC.Request>
          <SC.Label>{subjectLabel}</SC.Label>
          <SC.Value>{subject}</SC.Value>
        </SC.Request>
        <SC.Request>
          <SC.Label>{gradeLabel}</SC.Label>
          <SC.Value>{grade}</SC.Value>
        </SC.Request>
        <SC.Request>
          <SC.Label>{lessonLabel}</SC.Label>
          <SC.Value>{lesson}</SC.Value>
        </SC.Request>
        <SC.Request>
          <SC.Label>{descriptionLabel}</SC.Label>
          {description && <SC.DetailDescription>{description}</SC.DetailDescription>}
        </SC.Request>
        <SC.Request>
          <SC.Label>{priceLabel}</SC.Label>
          <SC.Value>{price}</SC.Value>
        </SC.Request>
      </SC.Content>
      <FormSubmitErrors errors={submitErrors} />
      <SC.Buttons>
        {cancelButton && confirmButton && (
          <>
            <SC.Button {...cancelButton} outlined onClick={handleClose} />
            <SC.Submit {...confirmButton} autoIcon />
          </>
        )}
      </SC.Buttons>
    </ModalContainer>
  )
}

export default CourseDetailsModal
