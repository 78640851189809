import React, { FC, useCallback, useMemo, useState } from 'react'
import { fr } from 'date-fns/locale'
import { format } from 'date-fns'
import dayjs from 'dayjs'

import { CalendarProps } from '../Calendar'
import { ToggleButtonPickerProps } from '../ToggleButtonPicker'

import * as SC from './styled'

export type AvailabilityValue = { date: string; slots: string[] }
export type AvailabilitiesObject = {
  [key: string]: string[]
}

export type AvailabilitiesPickerProps = {
  className?: string
  label: string
  name: string
  required?: boolean
  error?: string
  help?: string
  onChange: (name: string, value: AvailabilityValue[]) => void
  isLabelExternal?: boolean
  calendar: CalendarProps
  slot: ToggleButtonPickerProps
  value: AvailabilityValue[]
  disabledSlots?: AvailabilitiesObject
}

const fromArray = (initial: AvailabilityValue[]): AvailabilitiesObject =>
  initial?.reduce((arr, val) => ({ ...arr, [val.date]: val.slots }), {})

const toArray = (obj: AvailabilitiesObject): AvailabilityValue[] =>
  Object.keys(obj)
    ?.filter((d) => obj[d]?.length)
    .map((key) => ({ date: key, slots: obj[key] }))

const AvailabilitiesPicker: FC<AvailabilitiesPickerProps> = ({
  className,
  label,
  value,
  onChange,
  isLabelExternal,
  name,
  calendar,
  slot,
  required,
  error,
  help,
  disabledSlots,
}) => {
  const [selectedDate, setSelectedDate] = useState<Date>(
    dayjs().hour(12).minute(0).second(0).millisecond(0).toDate()
  )

  const availabilities = useMemo(() => fromArray(value), [value])

  const selectedDays = useMemo(() => {
    return Object.keys(availabilities)
      ?.filter((d) => availabilities[d]?.length)
      ?.map((d) => new Date(d))
  }, [availabilities])

  const handleChange = useCallback(
    (date: Date, slots: string[]) => {
      const tmp: AvailabilitiesObject = { ...availabilities, [date.toISOString()]: slots }
      onChange?.(name ?? '', toArray(tmp))
    },
    [availabilities, name, onChange]
  )

  return (
    <SC.DatePicker className={className}>
      {isLabelExternal && label && (
        <SC.Label required={required} error={!!error}>
          {label}
        </SC.Label>
      )}
      <SC.Wrapper>
        <SC.Calendar
          {...calendar}
          alreadyDays={selectedDays}
          selectedDate={selectedDate}
          onDayChange={(day: Date, modifiers, e) => {
            setSelectedDate(day)
            calendar.onDayChange?.(day, modifiers, e)
          }}
        />
        {selectedDate && (
          <>
            <SC.SlotPicker
              {...slot}
              fields={
                slot.fields?.map((field) => ({
                  ...field,
                  disabled:
                    field.value &&
                    disabledSlots?.[selectedDate.toISOString()]?.includes(field.value),
                })) as ToggleButtonPickerProps['fields']
              }
              label={format(selectedDate, 'eeee d LLLL y', {
                locale: fr,
              })}
              value={availabilities?.[selectedDate.toISOString()] || []}
              onChange={(_name: string, val: string[]) => {
                handleChange(selectedDate, val)
              }}
            />
          </>
        )}
      </SC.Wrapper>
      {(error || help) && <SC.HelperText error={!!error}>{error || help}</SC.HelperText>}
    </SC.DatePicker>
  )
}

export default AvailabilitiesPicker
