import React, { FC } from 'react'

import Header, { HeaderProps } from '../../components/Header'
import Menu, { MenuProps } from '../../components/Menu'
import AuthLayout from '../../layouts/AuthLayout'
import { PageBackButtonProps } from '../../components/PageBackButton'
import { RatingModalitiesProps } from '../../components/RatingModalities'

import * as SC from './styled'

export type PreferencesRatingTemplateProps = {
  className?: string
  headerProps: HeaderProps
  menuProps: MenuProps
  title: string
  backButton: PageBackButtonProps
  scoreCardProps?: RatingModalitiesProps
}

const PreferencesRatingTemplate: FC<PreferencesRatingTemplateProps> = (props) => {
  const { headerProps, menuProps, title, backButton, scoreCardProps } = props

  return (
    <AuthLayout header={<Header {...headerProps} withDivider />} menu={<Menu {...menuProps} />}>
      <SC.Content>
        <SC.BackButton {...backButton} />
        <SC.Title>{title}</SC.Title>
        {scoreCardProps && <SC.ScoreCard {...scoreCardProps} />}
      </SC.Content>
    </AuthLayout>
  )
}

export default PreferencesRatingTemplate
