import gql from 'graphql-tag'

import { Course, CourseRequest } from '../../../../generated/graphql'
import CourseFragment from '../../../fragments/CourseFragment'

export type PlanningCoursesResult = {
  future: { data: Course[]; total: number }
  past: { data: Course[]; total: number }
  pending: { data: Course[]; total: number }
  requests: { data: CourseRequest[]; total: number }
}

const teacherPlanningCourses = {
  query: gql`
    query teacherPlanningCourses {
      futureCourses(first: 10) {
        data {
          ...CourseFragment
        }
        paginatorInfo {
          total
        }
      }
    }
    ${CourseFragment}
  `,
  transformer: (response: any): PlanningCoursesResult => {
    return {
      future: {
        data: response?.futureCourses?.data,
        total: response?.futureCourses?.paginatorInfo?.total,
      },
      past: {
        data: [],
        total: 0,
      },
      pending: {
        data: [],
        total: 0,
      },
      requests: {
        data: [],
        total: 0,
      },
    }
  },
}

export default teacherPlanningCourses
