import styled from 'styled-components'

import PageTitle from '../../components/PageTitle'
import DateCarouselC from '../../components/DateCarousel'
import CardList from '../../components/CardList'

export const Content = styled.div`
  width: 100%;
`

export const Title = styled(PageTitle)``

export const Text = styled.p`
  ${(props) => ({ ...props.theme.typography.tinySmall })}
  color: ${(props) => props.theme.palette.colors.midnightBlue};
  padding: 0rem 1.6rem 1.6rem 1.6rem;
  margin: 0;
  max-width: 47rem;
`

export const DateCarousel = styled(DateCarouselC)`
  padding: 0rem 1.6rem;
  margin-top: 2.4rem;
  margin-bottom: 1.6rem;
`

export const MyCourseCardList = styled(CardList)`
  padding: 0rem 1.6rem;
`

export const Subtitle2 = styled.h3`
  ${(props) => ({ ...props.theme.typography.h3 })}
  color: ${(props) => props.theme.palette.colors.tomato};
  padding: 0 1.6rem 0rem 1.6rem;
  margin: 0;
`

export const NumberOfResults = styled.div`
  ${(props) => ({ ...props.theme.typography.big })}
  color: ${(props) => props.theme.palette.colors.grey};
  padding: 0 1.6rem 0rem 1.6rem;
  margin: 2.4rem 0 1.6rem 0;
`
