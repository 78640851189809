import { Divider } from '@material-ui/core'
import styled from 'styled-components'

import TextFieldC from '../form/TextField'
import FormSubmit from '../FormSubmit'
import UploadFileTiny from '../form/UploadFileTiny'

export const CustomThemeForm = styled.div``

export const Wrapper = styled.div``

export const Fields = styled.div`
  margin-top: 2rem;
`

export const TextField = styled(TextFieldC)`
  & label {
    color: ${(props) => props.theme.palette.colors.midnightBlue};
    ${(props) => ({ ...props.theme.typography.h4 })}
  }
`

export const Upload = styled(UploadFileTiny)`
  margin: 1.3rem 0 0 0;
`

export const MuiDivider = styled(Divider)`
  margin: 2rem 0 3.1rem 0;
`

export const SubmitButton = styled(FormSubmit)`
  margin: 1.6rem 0 0 0;
`
