import { Container, Divider } from '@material-ui/core'
import styled from 'styled-components'

export const MoneyCard = styled.div`
  ${(props) => props.theme.breakpoints.up('md')} {
    width: 45rem;
  }
`

export const Wrapper = styled(Container)`
  padding: 1.6rem;
  background-color: ${(props) => props.theme.palette.colors.pureWhite};
  border-radius: 0.8rem;
  border: 1px solid ${(props) => props.theme.palette.colors.ghostWhite};
  height: 100%;
`

export const Date = styled.p`
  margin: 0rem;
  ${(props) => ({ ...props.theme.typography.callToAction })}
  color: ${(props) => props.theme.palette.colors.freeSpeechBlue};
`

export const Muidivider = styled(Divider)`
  width: 100%;
  margin: 1.2rem 0rem;
`

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0 0 0;
`

export const Title = styled.h3`
  ${(props) => ({ ...props.theme.typography.h4 })}
  color: ${(props) => props.theme.palette.colors.midnightBlue};
  margin: 0;
`

export const Money = styled.span`
  ${(props) => ({ ...props.theme.typography.h5 })}
  color: ${(props) => props.theme.palette.colors.neonCarrot};
`

export const Level = styled.p`
  display: flex;
  margin: 0;
  ${(props) => ({ ...props.theme.typography.h7 })}
  color: ${(props) => props.theme.palette.colors.freeSpeechBlue};
`

export const Point = styled.p`
  margin: 0;
  ${(props) => ({ ...props.theme.typography.h2 })}
  color: ${(props) => props.theme.palette.colors.grey};
  line-height: 0.3;
  margin: 0 0.5rem;
`

export const Type = styled.div`
  display: flex;
  margin: 1rem 0 0 0;
  ${(props) => ({ ...props.theme.typography.smallRegular })}
  color: ${(props) => props.theme.palette.colors.grey};
`

export const Iban = styled.span`
  display: flex;
  ${(props) => ({ ...props.theme.typography.tinyStrong })}
  color: ${(props) => props.theme.palette.colors.grey};
  margin-top: 1rem;
`

export const Profile = styled.div`
  display: flex;
  align-items: center;
  margin: 1rem 0 0 0;
`

export const Image = styled.img`
  width: 3.2rem;
  height: auto;
  border-radius: 50%;
`

export const Name = styled.p`
  margin: 0 0 0 0.5rem;
  ${(props) => ({ ...props.theme.typography.smallRegular })}
  color: ${(props) => props.theme.palette.colors.grey};
`
