import React, { FC } from 'react'

import Header, { HeaderProps } from '../../components/Header'
import Menu, { MenuProps } from '../../components/Menu'
import AuthLayout from '../../layouts/AuthLayout'
import { CourseDetailsProps } from '../../components/CourseDetails'
import { CourseStatusProps } from '../../components/CourseStatus'
import { PageBackButtonProps } from '../../components/PageBackButton'
import { BasicProfilProps } from '../../components/BasicProfil'
import RatingsForm, { RatingsFormProps } from '../../components/RatingsForm'
import { RatingWithFieldsProps } from '../../components/RatingWithFields'

import * as SC from './styled'

export type RatingGroup = {
  title?: string
  text?: string
  ratings?: RatingWithFieldsProps[]
}

export type CourseTemplateProps = {
  className?: string
  headerProps: HeaderProps
  menuProps: MenuProps
  title: string
  date?: string
  courseStatus?: CourseStatusProps
  courseDetails?: CourseDetailsProps
  backButton: PageBackButtonProps
  basicProfile?: BasicProfilProps
  evaluateStudentsProps?: RatingsFormProps
  ratingGroups?: RatingGroup[]
}

const CourseTemplate: FC<CourseTemplateProps> = (props) => {
  const {
    headerProps,
    menuProps,
    title,
    date,
    courseStatus,
    courseDetails,
    backButton,
    basicProfile,
    evaluateStudentsProps,
    ratingGroups,
  } = props

  return (
    <AuthLayout header={<Header {...headerProps} withDivider />} menu={<Menu {...menuProps} />}>
      <SC.Content>
        <SC.BackButton {...backButton} />
        <SC.Title>{title}</SC.Title>
        {date && <SC.Date>{date}</SC.Date>}
        {courseStatus && <SC.CourseStatus {...courseStatus} />}
        <SC.Hr />
        {basicProfile && <SC.BasicProfile {...basicProfile} />}
        {courseDetails && <SC.CourseDetails {...courseDetails} />}
        {evaluateStudentsProps && (
          <>
            <SC.Hr />
            <RatingsForm {...evaluateStudentsProps} />
          </>
        )}
        {ratingGroups && (
          <>
            {ratingGroups?.map((group, groupKey) => (
              <SC.RatingGroup key={groupKey}>
                {group?.title && <SC.RatingGroupTitle>{group.title}</SC.RatingGroupTitle>}
                {group?.text && <SC.RatingGroupText>{group.text}</SC.RatingGroupText>}
                {group?.ratings && (
                  <SC.RatingGroupRatings>
                    {group?.ratings?.map((rating, ratingKey) => (
                      <SC.RatingWithFields {...rating} key={ratingKey} />
                    ))}
                  </SC.RatingGroupRatings>
                )}
              </SC.RatingGroup>
            ))}
          </>
        )}
      </SC.Content>
    </AuthLayout>
  )
}

export default CourseTemplate
