import gql from 'graphql-tag'

export default gql`
  fragment StudentFragment on Student {
    avatar
    email
    first_name
    grade {
      id
      name
    }
    id
    last_name
    school
    status
    guardian {
      id
      full_name
      avatar
      email
    }
    behaviour
    interactivity
    punctuality
    score
    understanding
    working_conditions
    deletable
  }
`
