import { RecurrenceDay } from '../../generated/graphql'

export const recurrenceDayOptions = (t: any) =>
  Object.values(RecurrenceDay).map((day) => ({
    value: day,
    children: t(`recurrenceDay_${day}`),
  }))

export const recurrenceDayJoin = (t: any, days: RecurrenceDay[]) =>
  days.map((day) => t(`recurrenceDay_${day}`)).join(', ')
