import gql from 'graphql-tag'

import { Course, PaginatorInfo } from '../../../../generated/graphql'
import CourseFragment from '../../../fragments/CourseFragment'
import PaginatorInfoFragment from '../../../fragments/PaginatorInfoFragment'

export type PendingReviewCoursesResult = {
  data: Course[]
  paginatorInfo: PaginatorInfo
}

const pendingReviewCourses = {
  query: gql`
    query pendingReviewCourses {
      pendingReviewCourses(first: 100) {
        data {
          ...CourseFragment
        }
        paginatorInfo {
          ...PaginatorInfoFragment
        }
      }
    }
    ${CourseFragment}
    ${PaginatorInfoFragment}
  `,
  transformer: (response: any): PendingReviewCoursesResult => {
    return {
      data: response?.pendingReviewCourses?.data,
      paginatorInfo: response?.pendingReviewCourses?.paginatorInfo,
    }
  },
}

export default pendingReviewCourses
