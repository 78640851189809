import React, { useCallback, useEffect, useMemo } from 'react'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'

import { HeaderProps } from '../../components/Header'
import useHeader from '../../hooks/useHeader'
import { MenuProps } from '../../components/Menu'
import useMenu from '../../hooks/useMenu'
import { UserType } from '../../generated/graphql'
import { RestrictedReactFC } from '../../types/common'
import { router, routesPath } from '../../router'
import PreferencesAvailabilitiesRecurrenceFormTemplate, {
  PreferencesAvailabilitiesRecurrenceFormTemplateProps,
} from '../../templates/PreferencesAvailabilitiesRecurrenceForm'
import { availabilitySlotOptions } from '../../graphql/enums/AvailabilitySlot'
import { recurrenceDayOptions } from '../../graphql/enums/RecurrenceDay'
import { actions, selectors } from '../../redux'
import { useFormSubmit } from '../../hooks/useFormSubmit'
import useBackButton from '../../hooks/useBackButton'

const PreferencesAvailabilitiesRecurrenceFormPage: RestrictedReactFC<
  RouteComponentProps<{ id?: string }>
> = (props) => {
  const headerProps: HeaderProps = useHeader()
  const menuProps: MenuProps = useMenu(props)
  const { t } = useTranslation()
  const id = useMemo(() => props.match?.params?.id, [props.match?.params?.id])
  const history = useHistory()
  const dispatch = useDispatch()
  const teacherAvailabilityRecurrence = useSelector(
    selectors.preferences.teacherAvailabilityRecurrence
  )
  const backButton = useBackButton(router(routesPath.preferencesAvailabilitiesRecurrent))

  const onComplete = useCallback(() => {
    history.push(router(routesPath.preferencesAvailabilitiesRecurrent))
  }, [history])

  const [submit, handleSubmit] = useFormSubmit(
    id
      ? selectors.preferences.updateTeacherAvailabilityRecurrence
      : selectors.preferences.createTeacherAvailabilityRecurrence,
    id
      ? actions.preferences.updateTeacherAvailabilityRecurrenceRequest
      : actions.preferences.createTeacherAvailabilityRecurrenceRequest,
    id
      ? actions.preferences.updateTeacherAvailabilityRecurrenceReset
      : actions.preferences.createTeacherAvailabilityRecurrenceReset,
    onComplete
  )

  useEffect(() => {
    if (id) {
      dispatch(actions.preferences.teacherAvailabilityRecurrenceRequest(id))
    } else {
      dispatch(actions.preferences.teacherAvailabilityRecurrenceReset(null))
    }
  }, [id, dispatch])

  const preferencesProps: PreferencesAvailabilitiesRecurrenceFormTemplateProps = {
    menuProps,
    headerProps,
    title: t(`preferences_availabilities_recurrenceForm_${id ? 'edit' : 'add'}_title`),
    formProps: {
      rangeTitle: t('preferences_availabilities_recurrenceForm_range_title'),
      fieldsProps: {
        start: {
          label: t('preferences_availabilities_recurrenceForm_start_label'),
          placeholder: t('preferences_availabilities_recurrenceForm_start_placeholder'),
        },
        end: {
          label: t('preferences_availabilities_recurrenceForm_end_label'),
          placeholder: t('preferences_availabilities_recurrenceForm_end_placeholder'),
        },
        days: {
          label: t('preferences_availabilities_recurrenceForm_days_label'),
          fields: recurrenceDayOptions(t),
        },
        hours: {
          label: t('preferences_availabilities_recurrenceForm_hours_label'),
          fields: availabilitySlotOptions(t),
        },
      },
      initialValues: teacherAvailabilityRecurrence?.data
        ? {
            start: dayjs(teacherAvailabilityRecurrence.data?.start_at, 'YYYY-MM-DD').toISOString(),
            end: dayjs(teacherAvailabilityRecurrence.data?.end_at, 'YYYY-MM-DD').toISOString(),
            days: teacherAvailabilityRecurrence.data?.days,
            hours: teacherAvailabilityRecurrence.data?.slots ?? [],
          }
        : {
            start: '',
            end: '',
            days: [],
            hours: [],
          },
      errorTexts: {
        required: t('error_required'),
        min: t('error_min_1'),
      },
      onSubmit: (values) => {
        handleSubmit({ ...values, id })
      },
      submitButton: {
        text: t('save'),
        isPending: submit?.pending,
      },
    },
    backButton,
  }

  return <PreferencesAvailabilitiesRecurrenceFormTemplate {...preferencesProps} />
}

PreferencesAvailabilitiesRecurrenceFormPage.restrictedUserTypes = [UserType.Teacher]

export default PreferencesAvailabilitiesRecurrenceFormPage
