import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { isEqual } from 'lodash'
import dayjs from 'dayjs'

import { HeaderProps } from '../../components/Header'
import useHeader from '../../hooks/useHeader'
import { MenuProps } from '../../components/Menu'
import useMenu from '../../hooks/useMenu'
import {
  AvailabilitySlot,
  Course,
  CourseStatus,
  CourseType,
  UserType,
} from '../../generated/graphql'
import { RestrictedReactFC } from '../../types/common'
import { actions, selectors } from '../../redux'
import { router, routesPath } from '../../router'
import {
  SearchResultsRequestFormValues,
  SearchResultsTemplate,
  SearchResultsTemplateProps,
} from '../../templates/SearchResults'
import { searchStepsUrls } from '../../redux/search/types/state'
import { CourseCardProps } from '../../components/CourseCard'
import { useFormSubmit } from '../../hooks/useFormSubmit'
import { AutoRegisterModalProps } from '../../components/modals/AutoRegisterModal'
import { availabilitySlotJoin, dateAndSlotToDate } from '../../graphql/enums/AvailabilitySlot'
import { light } from '../../theme/palette'
import { CourseDetailsModalProps } from '../../components/modals/CourseDetailsModal'
import {
  ApplyToCoursePayloadProps,
  CreateCoursePayloadProps,
} from '../../redux/search/types/actions'
import useBackButton from '../../hooks/useBackButton'
import { courseToFutureCardProps } from '../../transformers/courseTransformers'
import { courseTypeTranslation } from '../../graphql/enums/CourseType'
import useFeedbackModal from '../../hooks/useFeedbackModal'
import PreloadTemplate, { PreloadTemplateProps } from '../../templates/Preload'
import { Icons } from '../../components/Icon/types'

const SearchResultsPage: RestrictedReactFC<
  RouteComponentProps<{
    type: string
    discipline: string
    theme: string
    price: string
    size: string
    child?: string
  }>
> = (props) => {
  const headerProps: HeaderProps = useHeader()
  const menuProps: MenuProps = useMenu(props)
  const { t } = useTranslation()
  const step = useSelector(selectors.search.step)
  const params = useSelector(selectors.search.urlParams)
  const availabilities = useSelector(selectors.search.availabilities)
  const price = useSelector(selectors.search.price)
  const discipline = useSelector(selectors.search.discipline)
  const lesson = useSelector(selectors.search.lesson)
  const student = useSelector(selectors.search.student)
  const type = useSelector(selectors.search.type)
  const size = useSelector(selectors.search.size)
  const user = useSelector(selectors.auth.user)
  const history = useHistory()

  const [requestModalOpen, setRequestModalOpen] = useState(false)
  const [values, setValues] = useState<SearchResultsRequestFormValues>({ delay: '2' })
  const [detailsModalState, setDetailsModalState] = useState<{ open: boolean; data?: Course }>({
    open: false,
  })

  let previousLink = router(routesPath.search)
  let availabilitiesLink = router(routesPath.search)
  let priceLink = router(routesPath.search)
  try {
    previousLink = router(step ? searchStepsUrls[step] : routesPath.search, params)
    availabilitiesLink = router(routesPath.searchAvailabilities, params)
    priceLink = router(routesPath.searchPrice, params)
  } catch (e) {
    console.log('error', e)
  }

  const [page, setPage] = useState(1)

  const [search, handleSearch] = useFormSubmit(
    selectors.search.search,
    actions.search.searchRequest
  )

  const backButton = useBackButton(previousLink)

  const dispatch = useDispatch()
  const onComplete = useCallback(() => {
    history.push(router(routesPath.dashboard))
    dispatch(actions.search.clearSearch())
  }, [history, dispatch])

  const [setFeedbackModalOpen, feedbackModalProps] = useFeedbackModal({
    title: t('searchResults_applyFeedback_title'),
    subtitle: t('searchResults_applyFeedback_subtitle'),
    onClose: onComplete,
  })

  const [setRequestErrorModalOpen, requestErrorModalProps] = useFeedbackModal({
    title: t('searchResults_delayError_title'),
    subtitle: t('searchResults_delayError_subtitle'),
    icon: Icons.pebbleWarning,
  })

  const handleComplete = useCallback(() => {
    setFeedbackModalOpen(true)
    setRequestModalOpen(false)
    setDetailsModalState({ open: false })
  }, [setFeedbackModalOpen])

  const [courseRequest, handleCourseRequest] = useFormSubmit(
    selectors.search.courseRequest,
    actions.search.courseRequestRequest,
    actions.search.courseRequestReset,
    handleComplete
  )

  const [applyToCourse, handleApplyToCourse] = useFormSubmit(
    selectors.search.applyToCourse,
    actions.search.applyToCourseRequest,
    actions.search.applyToCourseReset,
    handleComplete
  )

  const [createCourse, handleCreateCourse] = useFormSubmit(
    selectors.search.createCourse,
    actions.search.createCourseRequest,
    actions.search.createCourseReset,
    handleComplete
  )

  const handleAutoRegisterSubmit = (v: SearchResultsRequestFormValues) => {
    setValues(v)
    const delayedDate = dayjs().add(parseInt(v.delay, 10), 'hour')
    const isValid = !!availabilities?.find((a) =>
      a.slots?.find((slot) => dayjs(dateAndSlotToDate(a.date, slot)).isAfter(delayedDate))
    )
    if (isValid) {
      setRequestModalOpen(true)
    } else {
      setRequestErrorModalOpen(true)
    }
  }

  const detailsModalProps: CourseDetailsModalProps = useMemo(() => {
    const course = detailsModalState?.data
    return {
      title: courseTypeTranslation(t, course?.type ?? CourseType.OneOff),
      date: t('course_card_date', {
        date: dateAndSlotToDate(course?.date, course?.slot ?? undefined),
      }),
      teacherProfile: {
        name: course?.lesson?.theme?.discipline?.name ?? '',
        level: course?.teacher?.full_name ?? '',
        profilePicture: {
          alt: 'LiberteClass - profil',
          src: course?.teacher?.avatar ?? '/static/assets/images/frank.png',
        },
        nameColor: light.colors.midnightBlue,
        levelColor: light.colors.freeSpeechBlue,
        ...(course?.teacher?.id && {
          linkProps: {
            href: router(routesPath.teacher, { id: course?.teacher?.id }),
          },
        }),
      },
      subjectLabel: t('searchResults_detailsModal_discipline'),
      subject: course?.lesson?.theme?.discipline?.name ?? '',
      gradeLabel: t('searchResults_detailsModal_grade'),
      grade: course?.lesson?.theme?.grade?.name ?? '',
      lessonLabel: t('searchResults_detailsModal_lesson'),
      lesson: course?.lesson?.name ?? '',
      descriptionLabel: t('searchResults_detailsModal_description'),
      description: course?.lesson?.description ?? '',
      priceLabel: t('searchResults_detailsModal_price'),
      price: t(
        (course?.participants ?? 0) + 1 === course?.maximum_participants
          ? 'course_card_price'
          : 'course_card_max_price',
        {
          price: (course?.price_per_participant ?? course?.maximum_price ?? 0) / 100,
        }
      ),
      cancelButton: {
        text: t('back'),
        onClick: () => setDetailsModalState({ open: false }),
      },
      confirmButton: {
        text: t('searchResults_detailsModal_apply'),
        isPending: applyToCourse?.pending || createCourse?.pending,
        onClick: () => {
          if (course?.status !== CourseStatus.Probability) {
            const input: ApplyToCoursePayloadProps = {
              id: course?.id ?? '',
              date: course?.date,
              slot: course?.slot ?? undefined,
              student: student?.id,
              teacher: course?.teacher?.id,
              size: size ?? '0',
            }
            handleApplyToCourse(input)
          } else {
            const input: CreateCoursePayloadProps = {
              type: type ?? CourseType.OneOff,
              lesson: lesson?.id ?? '',
              date: course?.date,
              slot: course?.slot ?? AvailabilitySlot.H12,
              student: student?.id,
              teacher: course?.teacher?.id ?? '',
              size: size ?? '0',
            }
            handleCreateCourse(input)
          }
        },
      },
      submitErrors: applyToCourse?.errors || createCourse?.errors,
      open: detailsModalState?.open,
      handleClose: () => setDetailsModalState({ open: false }),
      onClose: () => setDetailsModalState({ open: false }),
    }
  }, [
    detailsModalState?.data,
    detailsModalState?.open,
    t,
    applyToCourse?.pending,
    applyToCourse?.errors,
    createCourse?.pending,
    createCourse?.errors,
    student?.id,
    size,
    handleApplyToCourse,
    type,
    lesson?.id,
    handleCreateCourse,
  ])

  const requestModalProps: AutoRegisterModalProps = {
    title: t('searchResults_requestModal_title'),
    text: t('searchResults_requestModal_text'),
    description: t('searchResults_requestModal_description'),
    subjectLabel: t('searchResults_requestModal_discipline'),
    subject: discipline?.name,
    gradeLabel: t('searchResults_requestModal_grade'),
    grade: student?.grade?.name ?? '',
    participantsLabel: t('searchResults_requestModal_participants'),
    participants: size ?? '',
    lessonLabel: t('searchResults_requestModal_lesson'),
    lesson: lesson?.name,
    priceLabel: t('searchResults_requestModal_price'),
    price: t('course_card_max_price', { price }),
    dayLabel: t('searchResults_requestModal_days'),
    days:
      availabilities && availabilities?.length > 0
        ? availabilities?.map((availability) =>
            t('course_request_availability', {
              date: new Date(availability.date),
              slots: availabilitySlotJoin(t, availability.slots),
            })
          ) ?? []
        : [t('course_request_availabilities_all')],
    cancelButton: {
      text: t('cancel'),
      onClick: () => setRequestModalOpen(false),
    },
    handleClose: () => setRequestModalOpen(false),
    onClose: () => setRequestModalOpen(false),
    submitErrors: courseRequest.errors,
    confirmButton: {
      text: t('searchResults_requestModal_submit'),
      isPending: courseRequest.pending,
      onClick: () =>
        handleCourseRequest({
          type,
          student: student?.id,
          theme: lesson?.id,
          price,
          availabilities: availabilities,
          delay: values?.delay,
          size,
        }),
    },
    open: requestModalOpen,
  }

  const searchProps: SearchResultsTemplateProps = {
    title: t('search_title'),
    subTitle: t('searchResults_subtitle'),
    text: t('searchResults_text'),
    emptyText: t('searchResults_empty'),
    enlargeText: t('searchResults_enlarge'),
    cards: search?.data?.data?.map(
      (course: Course): CourseCardProps => ({
        ...courseToFutureCardProps(course, t, user),
        link: {
          onClick: () => setDetailsModalState({ open: true, data: course }),
        },
      })
    ),
    ...(search?.data?.paginatorInfo?.hasMorePages && {
      loadMoreProps: {
        text: t('load_more'),
        pendingText: t('load_more_pending'),
        isPending: search.pending,
        onClick: () => setPage((before) => before + 1),
        autoIcon: true,
      },
    }),
    submitErrors: search.errors,
    editButtonProps: {
      text: t('searchResults_edit_label'),
      link: {
        href: priceLink,
      },
    },
    autoRegisterAllowed: true,
    registerButtonProps: {
      text: t('searchResults_register_label'),
      isPending: courseRequest.pending,
    },
    registerText: t('searchResults_register_text', { price }),
    delaySelectProps: {
      name: 'delay',
      label: t('searchResults_delay_label'),
      placeholder: t('searchResults_delay_placeholder'),
      helperText: t('searchResults_delay_help'),
    },
    newSearchButton: {
      text: t('searchResults_new_label'),
      link: {
        href: router(routesPath.search),
      },
    },
    hasAvailabilities: (availabilities && availabilities?.length > 0) ?? false,
    noAvailabilitiesText: t('searchResults_noAvailabilities_text'),
    noAvailabilitiesButton: {
      text: t('searchResults_noAvailabilities_label'),
      link: {
        href: availabilitiesLink,
      },
    },
    noAutoRegisterText: t('searchResults_noRegister_text'),
    noAutoRegisterButton: {
      text: t('searchResults_noRegister_label'),
      link: {
        href: priceLink,
      },
    },
    initialState: {
      delay: t('searchResults_delay_placeholder'),
    },
    onSubmit: handleAutoRegisterSubmit,
    requestModalProps,
    detailsModalProps,
    headerProps: headerProps,
    menuProps: menuProps,
    backButton,
    feedbackModalProps,
    requestErrorModalProps,
  }

  useEffect(() => {
    return () => {
      dispatch(actions.search.searchReset(null))
    }
  }, [dispatch])

  useEffect(() => {
    if (!lesson?.id || !student?.id) {
      history.push(router(routesPath.search))
    }
  }, [lesson, student, history])

  useEffect(() => {
    //
    const input = {
      first: 25,
      page,
      type,
      theme: lesson?.id,
      student: student?.id,
      availabilities,
      price,
      size,
    }

    if (lesson?.id && student?.id && !isEqual(search?.params, input)) {
      handleSearch(input)
    }
  }, [type, student, lesson, search?.params, page, price, size, availabilities, handleSearch])

  const preloadProps: PreloadTemplateProps = useMemo(
    () => ({
      headerProps,
      menuProps,
      backButton,
      text: t('preload_text'),
    }),
    [headerProps, menuProps, backButton, t]
  )

  // first load
  if (search.pending && !search.data) {
    return <PreloadTemplate {...preloadProps} />
  }

  return <SearchResultsTemplate {...searchProps} />
}

SearchResultsPage.restrictedUserTypes = [UserType.Guardian, UserType.Student]

export default SearchResultsPage
