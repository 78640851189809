import React, { useEffect, useMemo } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { HeaderProps } from '../../components/Header'
import useHeader from '../../hooks/useHeader'
import { MenuProps } from '../../components/Menu'
import useMenu from '../../hooks/useMenu'
import { actions, selectors } from '../../redux'
import NotFoundTemplate from '../../templates/NotFound'
import PreloadTemplate from '../../templates/Preload'
import { PublicTeacher } from '../../generated/graphql'
import useBackButton from '../../hooks/useBackButton'
import useNotFound from '../../hooks/useNotFound'
import TeacherTemplate, { TeacherTemplateProps } from '../../templates/Teacher'
import { router, routesPath } from '../../router'

const TeacherPage: React.FC<RouteComponentProps<{ id: string }>> = (props) => {
  const headerProps: HeaderProps = useHeader()
  const menuProps: MenuProps = useMenu(props)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const id = props?.match?.params?.id
  const notFoundProps = useNotFound(headerProps)
  const teacherQuery = useSelector(selectors.search.teacherById)
  const teacher: PublicTeacher | null = teacherQuery?.data
  const backButton = useBackButton()

  const templateProps: TeacherTemplateProps = {
    headerProps,
    menuProps,
    backButton,
    basicProfile: {
      name: teacher?.full_name ?? '',
      profilePicture: {
        alt: 'LiberteClass - profil',
        src: teacher?.avatar ?? '',
      },
    },
    teacherInfo: {
      disciplinesLabel: t('teacher_disciplines_label'),
      disciplines:
        teacher?.disciplines?.reduce(
          (arr, discipline) => [
            ...arr,
            ...(!arr.includes(discipline.discipline.name) ? [discipline.discipline.name] : []),
          ],
          [] as string[]
        ) ?? [],
      descriptionLabel: t('teacher_description_label'),
      description: teacher?.introduction ?? '',
    },
    ...(teacher?.id && {
      buttonProps: {
        text: t('teacher_bookClass_label'),
        link: {
          href: router(routesPath.searchTeacherDiscipline, {
            teacher: teacher?.id,
          }),
        },
      },
    }),
  }

  const preloadProps = useMemo(
    () => ({
      headerProps,
      menuProps,
      text: t('preload_text'),
      backButton,
    }),
    [headerProps, menuProps, t, backButton]
  )

  useEffect(
    () => () => {
      dispatch(actions.search.teacherByIdReset(null))
    },
    [dispatch]
  )

  useEffect(() => {
    dispatch(actions.search.teacherByIdRequest({ id }))
  }, [dispatch, id])

  if (!teacherQuery.complete) {
    return <PreloadTemplate {...preloadProps} />
  }

  if (teacherQuery.errors || (teacherQuery.complete && !teacherQuery.data)) {
    return <NotFoundTemplate {...notFoundProps} />
  }

  return <TeacherTemplate {...templateProps} />
}

export default TeacherPage
