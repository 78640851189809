import { Container } from '@material-ui/core'
import styled from 'styled-components'

import TextField from '../form/TextField'
import ActionButton from '../ActionButton'
import CreditCartCard from '../CreditCartCard'

export const WithdrawMoneyForm = styled.div``

export const Wrapper = styled(Container)`
  width: 100%;
`

export const Card = styled(CreditCartCard)``

export const Field = styled(TextField)`
  margin: 3rem 0;
  width: 20rem;
  border-radius: 0.4rem;
`

export const Text = styled.p`
  ${(props) => ({ ...props.theme.typography.smallRegular })}
  color: ${(props) => props.theme.palette.colors.chambray};
  margin: -1rem 0 0 0;
  max-width: 40rem;
`

export const SubmitButton = styled(ActionButton)`
  margin-top: 4rem;
  max-width: 40rem;
`
