import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import { useDispatch, useSelector } from 'react-redux'

import { HeaderProps } from '../../components/Header'
import useHeader from '../../hooks/useHeader'
import { MenuProps } from '../../components/Menu'
import useMenu from '../../hooks/useMenu'
import {
  AvailabilitySlot,
  Course,
  LessonType,
  MutationPickCourseOfMarketArgs,
  UserType,
} from '../../generated/graphql'
import { RestrictedReactFC } from '../../types/common'
import { router, routesPath } from '../../router'
import useBackButton from '../../hooks/useBackButton'
import { CardListProps } from '../../components/CardList'
import { useFormSubmit } from '../../hooks/useFormSubmit'
import { actions, selectors } from '../../redux'
import {
  SearchMarketResultsTemplate,
  SearchMarketResultsTemplateProps,
} from '../../templates/SearchMarketResults'
import PreloadTemplate, { PreloadTemplateProps } from '../../templates/Preload'
import { availabilitySlotTranslation } from '../../graphql/enums/AvailabilitySlot'
import { courseToTeacherRequestCardProps } from '../../transformers/courseTransformers'
import { RequestCourseModalProps } from '../../components/modals/RequestCourseModal'
import useFeedbackModal from '../../hooks/useFeedbackModal'
import { BasicLinkProps } from '../../components/BasicLink'
import { Icons } from '../../components/Icon/types'

const SearchMarketSlotsPage: RestrictedReactFC<
  RouteComponentProps<{ type: string; price: string; date: string; slot: string }>
> = (props) => {
  const headerProps: HeaderProps = useHeader()
  const menuProps: MenuProps = useMenu(props)
  const { type, price, date } = props.match?.params
  const backButton = useBackButton(router(routesPath.searchMarketSlots, { type, price, date }))
  const slot = props.match?.params?.slot as AvailabilitySlot
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const user = useSelector(selectors.auth.user)

  const [search, handleSearch] = useFormSubmit(
    selectors.search.searchMarketSlot,
    actions.search.searchMarketSlotRequest,
    actions.search.searchMarketSlotReset
  )

  const [selectedCourse, setSelectedCourse] = useState<Course | null>(null)

  const [setFeedbackModalOpen, feedbackModalProps] = useFeedbackModal({
    title: t('searchMarketResults_feedbackModal_title'),
    subtitle: t('searchMarketResults_feedbackModal_subtitle'),
    onClose: () => history.push(router(routesPath.dashboard)),
  })

  const onComplete = useCallback(() => {
    setSelectedCourse(null)
    setFeedbackModalOpen(true)
  }, [setFeedbackModalOpen])

  const [teacherAcceptCourse, handleTeacherAcceptCourse] = useFormSubmit(
    selectors.search.acceptMarketCourse,
    actions.search.acceptMarketCourseRequest,
    actions.search.acceptMarketCourseReset,
    onComplete
  )

  const requestCourseModal: RequestCourseModalProps | undefined = useMemo(
    () =>
      selectedCourse
        ? {
            headerText: t('courseRequestModal_title'),
            title: t('courseRequestModal_subtitle'),
            card: courseToTeacherRequestCardProps(selectedCourse, t, user),
            cancelButton: {
              text: t('cancel'),
              onClick: () => {
                setSelectedCourse(null)
              },
            },
            submitErrors: teacherAcceptCourse?.errors,
            confirmButton: {
              text: t('courseRequestModal_confirm'),
              isPending: teacherAcceptCourse?.pending,
              onClick: () => {
                const input: MutationPickCourseOfMarketArgs = {
                  course: selectedCourse.id,
                  date,
                  slot,
                }
                handleTeacherAcceptCourse(input)
              },
            },
            open: !!selectedCourse,
            handleClose: () => {
              setSelectedCourse(null)
            },
          }
        : undefined,
    [
      date,
      handleTeacherAcceptCourse,
      selectedCourse,
      slot,
      t,
      teacherAcceptCourse?.errors,
      teacherAcceptCourse?.pending,
      user,
    ]
  )

  const cardListProps: CardListProps = useMemo(
    () => ({
      cards: search?.data?.data?.map((course: Course) => ({
        ...courseToTeacherRequestCardProps(course, t, user),
        price: t('course_card_min_price', { price: (course?.teacher_price ?? 0) / 100 }),
        ...(course?.lesson?.type === LessonType.Custom && {
          files: course?.lesson?.supports?.map(
            (support): BasicLinkProps => ({
              text: support.file_name,
              icon: Icons.download,
              iconSide: 'left',
              link: {
                href: support.url ?? '',
                target: '_blank',
              },
            })
          ),
        }),
        acceptButton: {
          text: t('course_card_accept'),
          onClick: () => {
            setSelectedCourse(course)
          },
        },
      })),
    }),
    [search, t, user]
  )

  const searchProps: SearchMarketResultsTemplateProps = useMemo(
    () => ({
      headerProps: headerProps,
      menuProps: menuProps,
      title: t('searchMarketResults_date', { date: dayjs(date, 'YYYY-MM-DD').toDate() }),
      subtitle: availabilitySlotTranslation(t, slot),
      text: t('searchMarketResults_text'),
      numberOfResults: t('searchMarketResults_count', {
        count: search?.data?.paginatorInfo?.total ?? 0,
      }),
      cardListProps,
      backButton,
      requestCourseModal,
      feedbackModalProps,
    }),
    [
      headerProps,
      menuProps,
      t,
      date,
      slot,
      search?.data?.paginatorInfo?.total,
      cardListProps,
      backButton,
      requestCourseModal,
      feedbackModalProps,
    ]
  )

  const preloadProps: PreloadTemplateProps = useMemo(
    () => ({
      headerProps,
      menuProps,
      backButton,
      text: t('preload_text'),
    }),
    [headerProps, menuProps, backButton, t]
  )

  useEffect(
    () => () => {
      dispatch(actions.search.searchMarketSlotReset(undefined))
    },
    [dispatch]
  )

  useEffect(() => {
    handleSearch({ type, date, price, slot })
  }, [slot, date, price, type, handleSearch])

  if (!search.complete) {
    return <PreloadTemplate {...preloadProps} />
  }

  return <SearchMarketResultsTemplate {...searchProps} />
}

SearchMarketSlotsPage.restrictedUserTypes = [UserType.Teacher]

export default SearchMarketSlotsPage
