import React, { FC } from 'react'
import { useTheme } from 'styled-components'

import Header, { HeaderProps } from '../../components/Header'
import Menu, { MenuProps } from '../../components/Menu'
import AuthLayout from '../../layouts/AuthLayout'
import { PageBackButtonProps } from '../../components/PageBackButton'
import { BasicProfilProps } from '../../components/BasicProfil'
import { RatingsFormProps } from '../../components/RatingsForm'
import { ActionButtonProps } from '../../components/ActionButton'

import * as SC from './styled'

export type TeacherInfo = {
  descriptionLabel: string
  description: string
  disciplinesLabel: string
  disciplines: string[]
}

export type TeacherTemplateProps = {
  className?: string
  headerProps: HeaderProps
  menuProps: MenuProps
  teacherInfo: TeacherInfo
  backButton: PageBackButtonProps
  basicProfile?: BasicProfilProps
  evaluateStudentsProps?: RatingsFormProps
  buttonProps?: ActionButtonProps
}

const TeacherTemplate: FC<TeacherTemplateProps> = (props) => {
  const { headerProps, menuProps, backButton, basicProfile, teacherInfo, buttonProps } = props

  const theme = useTheme()

  return (
    <AuthLayout header={<Header {...headerProps} withDivider />} menu={<Menu {...menuProps} />}>
      <SC.Content>
        <SC.BackButton {...backButton} />
        {basicProfile && (
          <SC.BasicProfile {...basicProfile} nameColor={theme.palette.colors.midnightBlue} />
        )}
        <SC.DetailTitle>{teacherInfo.disciplinesLabel}</SC.DetailTitle>
        <SC.Disciplines>
          {teacherInfo.disciplines?.map((d, key) => (
            <SC.Discipline key={key} text={d} />
          ))}
        </SC.Disciplines>
        <SC.DetailTitle>{teacherInfo.descriptionLabel}</SC.DetailTitle>
        <SC.DetailDescription>{teacherInfo.description}</SC.DetailDescription>
        {buttonProps && <SC.Button {...buttonProps} />}
      </SC.Content>
    </AuthLayout>
  )
}

export default TeacherTemplate
