import React, { FC, useMemo } from 'react'
import { Form, Formik, FormikHelpers } from 'formik'
import * as Yup from 'yup'

import { AddressValue } from '../form/Address'
import { getValidationSchema, renderField } from '../../helpers/FormHelpers'
import { FormFieldConfig, FormFieldProps } from '../../types/form'
import { BasicLinkProps } from '../BasicLink'
import { UploadFileProps, UploadFileValue } from '../form/UploadFile'
import { TextFieldLimitedCharsProps } from '../form/TextFieldLimitedChars'
import { FormSubmitProps } from '../FormSubmit'
import { Icons } from '../Icon/types'
import FormSubmitErrors, { FormSubmitErrorsProps } from '../FormSubmitErrors'

import * as SC from './styled'

export type ProfileFormValues = {
  avatar: string
  first_name: string
  last_name: string
  email: string
  address?: AddressValue
  phone?: string
  school?: string
  grade?: string
  cv?: UploadFileValue
  diploma?: UploadFileValue
  certificate?: UploadFileValue
  introduction?: string
}

export type ProfileFormProps = {
  className?: string
  title: string
  name: string
  account?: {
    title: string
    cta: BasicLinkProps
  }
  studentInfos?: {
    title: string
  }
  personalInfos?: {
    title: string
  }
  professionalInfos?: {
    title: string
  }
  fieldsProps?: {
    [key: string]: FormFieldProps
  }
  errorTexts?: {
    required: string
    email: string
    maxLength: string
    phone: string
    address: string
  }
  initialValues: ProfileFormValues
  submitErrors?: FormSubmitErrorsProps['errors']
  onSubmit?: (values: ProfileFormValues, helpers: FormikHelpers<ProfileFormValues>) => void
  submitButton: FormSubmitProps
}

const ProfileForm: FC<ProfileFormProps> = (props) => {
  const {
    className,
    title,
    name,
    account,
    personalInfos,
    professionalInfos,
    studentInfos,
    submitButton,
    initialValues,
    fieldsProps,
    errorTexts,
    submitErrors,
    onSubmit = (_v, _h) => null,
  } = props

  const accountFields = useMemo(
    () => [
      {
        name: 'first_name',
        Component: SC.TextField,
        validation: Yup.string().required(errorTexts?.required),
        required: true,
      },
      {
        name: 'last_name',
        Component: SC.TextField,
        validation: Yup.string().required(errorTexts?.required),
        required: true,
      },
      {
        name: 'email',
        Component: SC.TextField,
        validation: Yup.string().email(errorTexts?.email).required(errorTexts?.required),
        required: true,
      },
    ],
    [errorTexts?.email, errorTexts?.required]
  )

  const studentFields = useMemo(
    () => [
      {
        name: 'school',
        Component: SC.TextField,
        validation: Yup.string().required(errorTexts?.required),
        required: true,
      },
      {
        name: 'grade',
        Component: SC.TextField,
        validation: Yup.string().required(errorTexts?.required),
        required: true,
        select: true,
      },
    ],
    [errorTexts?.required]
  )

  const personalInfosFields = useMemo(
    () => [
      {
        name: 'phone',
        Component: SC.TextField,
        validation: Yup.string()
          .required(errorTexts?.required)
          .matches(/^((\+)33|0|0033)[1-9](\d{2}){4}$/g, errorTexts?.phone),
        required: true,
      },
      {
        name: 'address',
        Component: SC.Address,
        validation: Yup.mixed().test('address', errorTexts?.address ?? '', function (value) {
          return !!value.address
        }),
        required: true,
      },
    ],
    [errorTexts?.address, errorTexts?.phone, errorTexts?.required]
  )

  const professionalFields = useMemo(
    () => [
      /*{
        name: 'siret',
        Component: SC.TextField,
        validation: Yup.string().siret(errorTexts?.siret).required(errorTexts?.required),
        required: true,
        helperTextSide: 'start',
      } as Partial<TextFieldProps> & FormFieldConfig,*/
      {
        name: 'cv',
        Component: SC.UploadFile,
        validation: Yup.mixed().test('file', errorTexts?.required ?? '', function (value) {
          return !!value.file
        }),
        required: true,
      } as Partial<UploadFileProps> & FormFieldConfig,
      {
        name: 'diploma',
        Component: SC.UploadFile,
        validation: Yup.mixed().test('file', errorTexts?.required ?? '', function (value) {
          return !!value.file
        }),
        required: true,
      } as Partial<UploadFileProps> & FormFieldConfig,
      {
        name: 'certificate',
        Component: SC.UploadFile,
        validation: Yup.mixed().test('file', errorTexts?.required ?? '', function (value) {
          return !!value.file
        }),
        required: true,
      } as Partial<UploadFileProps> & FormFieldConfig,
      {
        name: 'introduction',
        Component: SC.TextFieldLimited,
        validation: Yup.string().max(320, errorTexts?.maxLength),
        required: true,
        multiline: true,
        rows: 4,
        maxChar: 320,
      } as Partial<TextFieldLimitedCharsProps> & FormFieldConfig,
    ],
    [errorTexts?.maxLength, errorTexts?.required]
  )

  const avatarField = useMemo(
    () => ({
      name: 'avatar',
      Component: SC.Avatar,
      validation: Yup.string(),
    }),
    []
  )

  const fields = useMemo(
    () => [
      avatarField,
      ...accountFields,
      ...(studentInfos ? studentFields : personalInfosFields),
      ...(professionalInfos ? professionalFields : []),
    ],
    [
      avatarField,
      accountFields,
      personalInfosFields,
      professionalFields,
      professionalInfos,
      studentFields,
      studentInfos,
    ]
  )

  const validationSchema = useMemo(() => getValidationSchema(fields), [fields])

  return (
    <SC.ProfileForm className={className}>
      <SC.Wrapper>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnChange
          validateOnBlur
          enableReinitialize
          onSubmit={onSubmit}
        >
          {(formikProps) => (
            <Form noValidate>
              <SC.Header>
                {renderField(avatarField, formikProps, fieldsProps, `avatar`)}
                <SC.HeaderContent>
                  <SC.HeaderTitle>{title}</SC.HeaderTitle>
                  <SC.HeaderName>{name}</SC.HeaderName>
                </SC.HeaderContent>
              </SC.Header>
              <SC.Rows>
                <SC.FormGroup>
                  {account && (
                    <SC.Section>
                      <SC.SectionTitle>{account.title}</SC.SectionTitle>
                      <SC.SectionFields>
                        {accountFields.map((f, i) =>
                          renderField(f, formikProps, fieldsProps, `account_${i}`)
                        )}
                      </SC.SectionFields>
                      {account?.cta && (
                        <SC.PasswordCta icon={Icons.longArrowRight} {...account?.cta} />
                      )}
                    </SC.Section>
                  )}
                </SC.FormGroup>
                {studentInfos && (
                  <SC.Section>
                    <SC.SectionTitle>{studentInfos.title}</SC.SectionTitle>
                    <SC.SectionFields>
                      {studentFields.map((f, i) =>
                        renderField(f, formikProps, fieldsProps, `student_${i}`)
                      )}
                    </SC.SectionFields>
                  </SC.Section>
                )}
                {personalInfos && (
                  <SC.Section>
                    <SC.SectionTitle>{personalInfos.title}</SC.SectionTitle>
                    <SC.SectionFields>
                      {personalInfosFields.map((f, i) =>
                        renderField(f, formikProps, fieldsProps, `perso_${i}`)
                      )}
                    </SC.SectionFields>
                  </SC.Section>
                )}
              </SC.Rows>
              {professionalInfos && (
                <SC.Section>
                  <SC.SectionTitle>{professionalInfos.title}</SC.SectionTitle>
                  <SC.SectionFields>
                    {professionalFields.map((f, i) =>
                      renderField(f, formikProps, fieldsProps, `pro_${i}`)
                    )}
                  </SC.SectionFields>
                </SC.Section>
              )}
              <FormSubmitErrors errors={submitErrors} />
              <SC.SubmitButtonWrapper>
                <SC.SubmitButton
                  {...submitButton}
                  autoIcon
                  isDisabled={!formikProps.dirty}
                  isSuccess={submitButton.isSuccess && !formikProps.dirty}
                />
              </SC.SubmitButtonWrapper>
            </Form>
          )}
        </Formik>
      </SC.Wrapper>
    </SC.ProfileForm>
  )
}

export default ProfileForm
