import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { actions } from '../../redux'

const SignOutPage: React.FC = (_pageProps) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(actions.auth.logOut())
  }, [dispatch])

  return null
}

export default SignOutPage
