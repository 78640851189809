import styled from 'styled-components'

import PageTitle from '../../components/PageTitle'
import PageBackButton from '../../components/PageBackButton'
import ThemePickerFormC from '../../components/ThemePickerForm'
import CustomThemeFormC from '../../components/CustomThemeForm'
import ThemeListC from '../../components/ThemeList'
import { SearchRecurrenceTeacherProfile } from '../../components/SearchRecurrenceTeacherProfile'

export const Content = styled.div`
  width: 100%;
`

export const Subtitle = styled.h6`
  ${(props) => ({ ...props.theme.typography.h6 })}
  color: ${(props) => props.theme.palette.colors.neonCarrot};
  padding: 0 1.6rem;
  margin: 2rem 0 0.4rem 0;
`

export const Title = styled(PageTitle)`
  color: ${(props) => props.theme.palette.colors.midnightBlue};
  padding-bottom: 0.4rem;
  padding-top: 0;
`
export const Discipline = styled(PageTitle)`
  color: ${(props) => props.theme.palette.colors.cornflowerBlue};
  padding-top: 0;
  margin-top: 0;
`

export const BackButton = styled(PageBackButton)``

export const ThemePickerForm = styled(ThemePickerFormC)`
  margin: 2.4rem 1.6rem;
  max-width: 47rem;
`

export const CustomThemeForm = styled(CustomThemeFormC)`
  margin: 2.4rem 1.6rem;
  max-width: 47rem;
`

export const ThemeLists = styled.div`
  margin: 2.4rem 1.6rem;
  max-width: 47rem;
`

export const ThemeList = styled(ThemeListC)`
  margin-bottom: 1.6rem;
`

export const TeacherProfile = styled(SearchRecurrenceTeacherProfile)``
