import React, { FC, ReactNode, useState } from 'react'

import { TextFieldProps } from '../form/TextField'

import * as SC from './styled'

export type SearchProps = {
  className?: string
  title: string
  searchField: TextFieldProps
  results?: ReactNode
  subTitle?: string
  levelSelector?: TextFieldProps
}

const Search: FC<SearchProps> = ({
  className,
  title,
  searchField,
  results,
  subTitle,
  levelSelector,
}) => {
  // State just for the sake of the example
  const [searchInput, setSearchInput] = useState(searchField.value)
  const [selectInput, setSelectInput] = useState('')

  return (
    <SC.Search className={className}>
      <SC.Wrapper disableGutters>
        <SC.Content>
          <SC.Title>{title}</SC.Title>
          <SC.SearchInput
            {...searchField}
            value={searchInput}
            onChange={(name, value) => setSearchInput(value)}
          />
        </SC.Content>
        {results && levelSelector && (
          <>
            <SC.Subtitle>{subTitle}</SC.Subtitle>
            <SC.SelectLevel
              {...levelSelector}
              value={selectInput}
              onChange={(name, value) => setSelectInput(value)}
            />
            <SC.Results>{results}</SC.Results>
          </>
        )}
      </SC.Wrapper>
    </SC.Search>
  )
}

export default Search
