import gql from 'graphql-tag'

import LessonFragment from './LessonFragment'
import AvailabilityFragment from './AvailabilityFragment'

export default gql`
  fragment CourseRequestFragment on CourseRequest {
    id
    type
    maximum_price
    maximum_participants
    minimum_score
    notice_period
    lesson {
      ...LessonFragment
    }
    availabilities {
      ...AvailabilityFragment
    }
    student {
      id
      full_name
      avatar
    }
    created_at
    updated_at
  }
  ${LessonFragment}
  ${AvailabilityFragment}
`
