import styled from 'styled-components'

import ActionButton from '../../components/ActionButton'
import BasicTextAndImageC from '../../components/BasicTextAndImage'
import PricesChoiceFormC from '../../components/PricesChoiceForm'

export const Wrapper = styled.div``

export const BasicTextAndImage = styled(BasicTextAndImageC)``

export const Action = styled(ActionButton)`
  max-width: calc(100% - 3.2rem);
  margin: 5rem auto;
`

export const PricesChoiceForm = styled(PricesChoiceFormC)`
  padding: 0 1.6rem;
`
