import gql from 'graphql-tag'

import { TeacherAvailabilityRecurrence } from '../../../../generated/graphql'
import TeacherAvailabilityRecurrenceFragment from '../../../fragments/TeacherAvailabilityRecurrenceFragment'

const createTeacherAvailabilityRecurrence = {
  mutation: gql`
    mutation createTeacherAvailabilityRecurrence(
      $start_at: Date!
      $end_at: Date!
      $days: [RecurrenceDay!]!
      $slots: [AvailabilitySlot!]
    ) {
      createTeacherAvailabilityRecurrence(
        start_at: $start_at
        end_at: $end_at
        days: $days
        slots: $slots
      ) {
        ...TeacherAvailabilityRecurrenceFragment
      }
    }
    ${TeacherAvailabilityRecurrenceFragment}
  `,
  transformer: (response: {
    createTeacherAvailabilityRecurrence: TeacherAvailabilityRecurrence
  }): any => {
    return response.createTeacherAvailabilityRecurrence
  },
}

export default createTeacherAvailabilityRecurrence
