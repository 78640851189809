import styled from 'styled-components'

import CourseDetailsC from '../../components/CourseDetails'
import CourseStatusC from '../../components/CourseStatus'
import PageTitle from '../../components/PageTitle'
import PageBackButton from '../../components/PageBackButton'
import BasicProfil from '../../components/BasicProfil'
import RatingWithFieldsC from '../../components/RatingWithFields'

export const Content = styled.div`
  flex-grow: 1;
  ${(props) => props.theme.breakpoints.up('md')} {
    flex-grow: initial;
  }
`

export const BackButton = styled(PageBackButton)``
export const Title = styled(PageTitle)`
  padding-bottom: 0.4rem;
`

export const Date = styled.p`
  margin: 0rem;
  padding: 0 1.6rem;
  ${(props) => ({ ...props.theme.typography.callToAction })}
  color: ${(props) => props.theme.palette.colors.tomato};
`

export const CourseStatus = styled(CourseStatusC)`
  margin: 1.6rem 1.6rem 0;
  max-width: 48.2rem;
`

export const Hr = styled.hr`
  margin: 1.6rem 1.6rem 0;
  border: 0;
  border-top: 1px solid ${(props) => props.theme.palette.colors.gainsboro};
  max-width: 48.2rem;
`

export const BasicProfile = styled(BasicProfil)`
  margin-top: 2rem;
  padding: 1.6rem;
  background-color: ${(props) => props.theme.palette.colors.pureWhite};
  border-radius: 0.8rem;
  border: 2px solid ${(props) => props.theme.palette.colors.ghostWhite};
  width: auto;
  margin: 2rem 1.6rem 1.6rem;
  max-width: 48.2rem;
`

export const CourseDetails = styled(CourseDetailsC)`
  margin-top: 2rem;
  padding: 0 1.6rem;
  max-width: 52rem;
`

export const RatingGroup = styled.div`
  overflow: hidden;
`
export const RatingGroupTitle = styled.h2`
  ${(props) => ({ ...props.theme.typography.h3 })}
  color: ${(props) => props.theme.palette.colors.midnightBlue};
  padding: 1.6rem;
  margin: 0;
`
export const RatingGroupText = styled.p`
  ${(props) => ({ ...props.theme.typography.mediumRegular })}
  // color: ${(props) => props.theme.palette.colors.midnightBlue};
  padding: 1.6rem;
  margin: 0;
`
export const RatingGroupRatings = styled.div`
  margin: -0.8rem;
  ${(props) => props.theme.breakpoints.up('md')} {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
`
export const RatingWithFields = styled(RatingWithFieldsC)`
  padding: 0 1.6rem;
  margin: 0.8rem;
`
