import React, { FC } from 'react'

import { ActionButtonProps } from '../../components/ActionButton'
import BasicTextAndImage, { BasicTextAndImageProps } from '../../components/BasicTextAndImage'
import Header, { HeaderProps } from '../../components/Header'
import SignUpLayout from '../../layouts/SignupLayout'
import { Icons } from '../../components/Icon/types'
import { FormSubmitProps } from '../../components/FormSubmit'
import FormSubmitErrors, { FormSubmitErrorsProps } from '../../components/FormSubmitErrors'

import * as SC from './styled'

export type SignUpConfirmTemplateProps = {
  headerProps: HeaderProps
  textProps: BasicTextAndImageProps
  cta: ActionButtonProps
  logoutButtonProps: ActionButtonProps
  text2: string
  text3?: string
  submitProps?: FormSubmitProps
  submitErrors?: FormSubmitErrorsProps['errors']
}

const SignUpConfirmTemplate: FC<SignUpConfirmTemplateProps> = (props) => {
  const {
    headerProps,
    textProps,
    cta,
    logoutButtonProps,
    text2,
    text3,
    submitProps,
    submitErrors,
  } = props

  return (
    <SignUpLayout header={<Header {...headerProps} />}>
      <SC.Content>
        <BasicTextAndImage {...textProps} />
        <SC.Text>{text2}</SC.Text>
        <SC.Icon icon={Icons.mailOn} />
        {text3 && <SC.Text>{text3}</SC.Text>}
        {submitProps && <SC.ResendCta {...submitProps} icon={Icons.paperPlane} autoIcon />}
        {submitErrors && <FormSubmitErrors errors={submitErrors} />}
      </SC.Content>
      <SC.Actions>
        <SC.ReturnButton {...cta} outlined />
        <SC.Action {...logoutButtonProps} outlined />
      </SC.Actions>
    </SignUpLayout>
  )
}

export default SignUpConfirmTemplate
