import React, { FC, useMemo } from 'react'
import { DayPickerProps, Modifier, Modifiers } from 'react-day-picker'
import 'react-day-picker/lib/style.css'

import chevron from './chevron.svg'
import * as SC from './styled'

export type CalendarProps = {
  className?: string
  selectedDate?: Date
  dateDisableBefore?: Date
  today?: Date
  onDayChange?: DayPickerProps['onDayClick']
  locale: {
    lang: string
    months: string[]
    weekdaysL: string[]
    weekdaysS: string[]
  }
  onMonthChange?: DayPickerProps['onMonthChange']
  recurringDays?: Modifier[]
  alreadyDays?: Modifier[]
}

const Calendar: FC<CalendarProps> = ({
  className,
  selectedDate,
  dateDisableBefore,
  locale,
  onDayChange,
  onMonthChange,
  recurringDays,
  alreadyDays,
  today,
}) => {
  const modifiers = useMemo(
    () =>
      ({
        recurringDays: recurringDays,
        alreadyDays: alreadyDays,
        today: today,
      } as Partial<Modifiers>),
    [recurringDays, alreadyDays, today]
  )

  const disabledDays: Modifier[] | null = useMemo(
    () => [...(dateDisableBefore ? [{ before: dateDisableBefore }] : [])] as Modifier[],
    [dateDisableBefore]
  )

  function renderDay(day: any) {
    const date = day.getDate()
    return <div className="value">{date}</div>
  }

  return (
    <SC.Wrapper className={className}>
      <SC.DayPicker
        onMonthChange={onMonthChange}
        selectedDays={selectedDate ? selectedDate : []}
        onDayClick={onDayChange}
        chevron={chevron}
        disabledDays={disabledDays}
        locale={locale.lang}
        modifiers={modifiers}
        months={locale.months}
        weekdaysLong={locale.weekdaysL}
        weekdaysShort={locale.weekdaysS}
        firstDayOfWeek={1}
        renderDay={renderDay}
      />
    </SC.Wrapper>
  )
}

export default Calendar
