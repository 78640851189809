import React, { FC, memo, ReactNode } from 'react'

import Header, { HeaderProps } from '../../components/Header'
import Menu, { MenuProps } from '../../components/Menu'
import AuthLayout from '../../layouts/AuthLayout'
import { PageBackButtonProps } from '../../components/PageBackButton'
import { AvailabilityFormProps } from '../../components/AvailabilityForm'

import * as SC from './styled'

export type SearchAvailabilitiesTemplateProps = {
  className?: string
  children?: ReactNode
  headerProps: HeaderProps
  menuProps: MenuProps
  backButton: PageBackButtonProps
  title: string
  subTitle: string
  text: string
  availabilityFormProps: AvailabilityFormProps
}

export const SearchAvailabilitiesTemplate: FC<SearchAvailabilitiesTemplateProps> = (props) => {
  const { headerProps, menuProps, title, backButton, subTitle, text, availabilityFormProps } = props

  return (
    <AuthLayout header={<Header {...headerProps} withDivider />} menu={<Menu {...menuProps} />}>
      <SC.Content>
        <SC.BackButton {...backButton} />
        <SC.Subtitle>{title}</SC.Subtitle>
        <SC.Title>{subTitle}</SC.Title>
        <SC.Title2>{text}</SC.Title2>
        <SC.Form {...availabilityFormProps} />
      </SC.Content>
    </AuthLayout>
  )
}

export default memo(SearchAvailabilitiesTemplate)
