import React, { FC } from 'react'

import { BasicLinkProps } from '../BasicLink'

import * as SC from './styled'

export type ScheduleResultCardProps = {
  className?: string
  hour: string
  cta?: BasicLinkProps
  alreadyBooked?: boolean
  alreadyBookedText?: string
}

const ScheduleResultCard: FC<ScheduleResultCardProps> = (props) => {
  const { className, hour, cta, alreadyBooked, alreadyBookedText } = props

  return (
    <SC.ScheduleResultCard className={className}>
      <SC.Wrapper disableGutters>
        <SC.Hour isDisabled={alreadyBooked}>{hour}</SC.Hour>
        {!alreadyBooked && cta && <SC.Cta {...cta} />}
        {alreadyBooked && alreadyBookedText && (
          <SC.AlreadyBookedText>{alreadyBookedText}</SC.AlreadyBookedText>
        )}
      </SC.Wrapper>
    </SC.ScheduleResultCard>
  )
}

export default ScheduleResultCard
