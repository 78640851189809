import React, { FC, useMemo } from 'react'
import { Form, Formik, FormikHelpers } from 'formik'
import * as Yup from 'yup'

import { BasicLinkProps } from '../BasicLink'
import { light } from '../../theme/palette'
import { Icons } from '../Icon/types'
import { FormSubmitProps } from '../FormSubmit'
import FormSubmitErrors, { FormSubmitErrorsProps } from '../FormSubmitErrors'
import type { FormFieldConfig, FormFieldProps } from '../../types/form'
import { getValidationSchema, renderField } from '../../helpers/FormHelpers'
import { UserType } from '../../generated/graphql'
import FormScrollToError from '../FormScrollToError'
import Checkbox from '../form/Checkbox'
import SocialConnect, { SocialConnectProps } from '../SocialConnect'
import LegalLegends, { LegalLegendsProps } from '../LegalLegends'

import * as SC from './styled'

export type SignUpFormValues = {
  type: string
  firstName: string
  lastName: string
  email: string
  password: string
  passwordConfirmation: string
  cgu: boolean
  sponsor?: string
  age?: boolean
  promo?: string
}

export type SignUpFormProps = {
  className?: string
  title: string
  text?: string
  legends?: LegalLegendsProps['texts']
  sponsorText: string
  promoText: string
  submitButton: FormSubmitProps
  mandatoryText: string
  socialConnectProps: SocialConnectProps
  alreadySignedUpText: string
  alreadySignedUpCta: BasicLinkProps
  initialValues: SignUpFormValues
  fieldsProps?: {
    type: FormFieldProps
    firstName: FormFieldProps
    lastName: FormFieldProps
    email: FormFieldProps
    password: FormFieldProps
    passwordConfirmation: FormFieldProps
    sponsor: FormFieldProps
    cgu: FormFieldProps
    age: FormFieldProps
    promo: FormFieldProps
  }
  errorTexts?: {
    required: string
    email: string
    profile: string
    password: string
    passwordConfirmation: string
    cgu: string
    sponsorNotFound: string
    sponsorAlreadyAssociated: string
  }
  submitErrors?: FormSubmitErrorsProps['errors']
  onSubmit?: (values: SignUpFormValues, formikHelpers: FormikHelpers<SignUpFormValues>) => void
  isTeacher?: boolean
  isStudent?: boolean
  onTypeChange?: (type: UserType) => void
}

const SignUpForm: FC<SignUpFormProps> = (props) => {
  const {
    className,
    title,
    text,
    sponsorText,
    promoText,
    fieldsProps,
    submitButton,
    mandatoryText,
    socialConnectProps,
    alreadySignedUpText,
    alreadySignedUpCta,
    initialValues,
    errorTexts,
    submitErrors,
    onSubmit = (_v: SignUpFormValues) => null,
    isTeacher = false,
    isStudent = false,
    onTypeChange = (_v: string) => null,
    legends,
  } = props

  const typeField = useMemo(
    () => ({
      name: 'type',
      Component: SC.MuiToggleButtonGroup,
      validation: Yup.string().required(errorTexts?.required),
      required: true,
      isRadioMode: true,
    }),
    [errorTexts?.required]
  )

  const sponsorField = useMemo(
    () => ({
      name: 'sponsor',
      Component: SC.MuiTextField,
      validation: Yup.string(),
    }),
    []
  )

  const ageField = useMemo(
    () => ({
      name: 'age',
      Component: SC.Checkbox,
      validation: Yup.bool().required().oneOf([true], errorTexts?.required),
    }),
    [errorTexts?.required]
  )

  const promoField = useMemo(
    () => ({
      name: 'promo',
      Component: SC.MuiTextField,
      validation: Yup.string(),
    }),
    []
  )

  const fields: FormFieldConfig[] = [
    typeField,
    {
      name: 'firstName',
      Component: SC.MuiTextField,
      validation: Yup.string().required(errorTexts?.required),
      required: true,
    },
    {
      name: 'lastName',
      Component: SC.MuiTextField,
      validation: Yup.string().required(errorTexts?.required),
      required: true,
    },
    {
      name: 'email',
      Component: SC.MuiTextField,
      validation: Yup.string().email(errorTexts?.email).required(errorTexts?.required),
      required: true,
    },
    {
      name: 'password',
      Component: SC.MuiTextField,
      validation: Yup.string()
        .required(errorTexts?.required)
        .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*)[^ ]{8,}$/, errorTexts?.password),
      required: true,
      type: 'password',
    },
    {
      name: 'passwordConfirmation',
      Component: SC.MuiTextField,
      validation: Yup.string()
        .required(errorTexts?.required)
        .oneOf([Yup.ref('password'), null], errorTexts?.passwordConfirmation),
      required: true,
      type: 'password',
    },
    ...(isStudent ? [promoField] : []),
    {
      name: 'cgu',
      Component: Checkbox,
      validation: Yup.bool().required().oneOf([true], errorTexts?.cgu),
      required: true,
    },
    sponsorField,
    ...(isStudent ? [ageField] : []),
  ]

  const validationSchema = getValidationSchema(fields)

  return (
    <SC.SignUpForm className={className}>
      <SC.Title>{title}</SC.Title>
      {text && <SC.Text>{text}</SC.Text>}
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnChange={true}
        validateOnBlur={true}
        onSubmit={onSubmit}
      >
        {(formikProps) => (
          <Form noValidate>
            <FormScrollToError formikProps={formikProps} />
            {renderField(typeField, formikProps, fieldsProps, 0, (Component, p) => (
              <Component
                {...p}
                onChange={(n: string, v: UserType) => {
                  onTypeChange?.(v)
                  p.onChange(n, v)
                }}
              />
            ))}
            <SC.Fields>
              {fields
                .filter((f) => f.name !== 'type' && f.name !== 'sponsor')
                .map((field, index) => {
                  if (field.name === 'promo') {
                    return (
                      <>
                        <SC.PromoText>{promoText}</SC.PromoText>
                        {renderField(field, formikProps, fieldsProps, index)}
                      </>
                    )
                  } else return renderField(field, formikProps, fieldsProps, index)
                })}

              {isTeacher && sponsorField && (
                <>
                  <SC.SponsorText>{sponsorText}</SC.SponsorText>
                  {renderField(sponsorField, formikProps, fieldsProps)}
                </>
              )}
            </SC.Fields>
            <FormSubmitErrors errors={submitErrors} />
            <SC.SubmitButton {...submitButton} autoIcon />
            <SC.MandatoryText>{mandatoryText}</SC.MandatoryText>
            <LegalLegends texts={legends} />
          </Form>
        )}
      </Formik>
      <SC.MuiDivider />
      <SocialConnect {...socialConnectProps} />
      <SC.AlreadySignedUpText>{alreadySignedUpText}</SC.AlreadySignedUpText>
      <SC.AlreadySignedUpCta
        {...alreadySignedUpCta}
        icon={Icons.longArrowRight}
        iconColor={light.colors.freeSpeechBlue}
      />
    </SC.SignUpForm>
  )
}

export default SignUpForm
