import { createSlice } from '@reduxjs/toolkit'

import type { RootState } from '../store'
import {
  getPaginatedServiceReducers,
  getServiceProps,
  getServiceReducers,
  ServiceProps,
} from '../../helpers/ReduxHelpers'
import {
  Course,
  CourseRequest,
  MutationCancelCourseRequestArgs,
  MutationCancelPendingCourseArgs,
  MutationEvaluateStudentsArgs,
} from '../../generated/graphql'
import { DashboardCoursesResult } from '../../graphql/services/course/queries/dashboardCourses'
import { PlanningCoursesResult } from '../../graphql/services/course/queries/planningCourses'
import { TeacherFutureCoursesResult } from '../../graphql/services/course/queries/teacherFutureCourses'
import { TeacherDashboardCoursesResult } from '../../graphql/services/course/queries/teacherDashboardCourses'
import { PendingReviewCoursesResult } from '../../graphql/services/course/queries/pendingReviewCourses'
import { TeacherPastCoursesResult } from '../../graphql/services/course/queries/teacherPastCourses'

export type SearchState = {
  // services
  dashboardCourses: ServiceProps<DashboardCoursesResult, { student?: string }>
  teacherDashboardCourses: ServiceProps<TeacherDashboardCoursesResult, undefined>
  todayCourse: ServiceProps<Course, undefined>
  course: ServiceProps<Course, string>
  courseRequest: ServiceProps<CourseRequest, string>
  planningCourses: ServiceProps<PlanningCoursesResult, undefined>
  teacherFutureCourses: ServiceProps<TeacherFutureCoursesResult, undefined>
  teacherPastCourses: ServiceProps<TeacherPastCoursesResult, any>
  pendingReviewCourses: ServiceProps<PendingReviewCoursesResult, undefined>
  evaluateStudents: ServiceProps<Course, MutationEvaluateStudentsArgs>
  cancelCourseRequest: ServiceProps<undefined, MutationCancelCourseRequestArgs>
  cancelPendingCourse: ServiceProps<undefined, MutationCancelPendingCourseArgs>
  cancelConfirmedCourse: ServiceProps<undefined, MutationCancelPendingCourseArgs>
  report: ServiceProps<undefined, any>
}

//
// Initial state
//

const initialState: SearchState = {
  // Services
  dashboardCourses: getServiceProps(),
  teacherDashboardCourses: getServiceProps(),
  todayCourse: getServiceProps(),
  course: getServiceProps(),
  courseRequest: getServiceProps(),
  planningCourses: getServiceProps(),
  teacherFutureCourses: getServiceProps(),
  teacherPastCourses: getServiceProps(),
  pendingReviewCourses: getServiceProps(),
  evaluateStudents: getServiceProps(),
  cancelCourseRequest: getServiceProps(),
  cancelPendingCourse: getServiceProps(),
  cancelConfirmedCourse: getServiceProps(),
  report: getServiceProps(),
}

//
// Slice (Actions & Reducers)
//

const slice = createSlice({
  name: 'planning',
  initialState,
  reducers: {
    // Services
    ...getServiceReducers('dashboardCourses'),
    ...getServiceReducers('teacherDashboardCourses'),
    ...getServiceReducers('todayCourse'),
    ...getServiceReducers('course'),
    ...getServiceReducers('courseRequest'),
    ...getServiceReducers('planningCourses'),
    ...getServiceReducers('teacherFutureCourses'),
    ...getPaginatedServiceReducers('teacherPastCourses'),
    ...getServiceReducers('pendingReviewCourses'),
    ...getServiceReducers('evaluateStudents'),
    ...getServiceReducers('cancelCourseRequest'),
    ...getServiceReducers('cancelPendingCourse'),
    ...getServiceReducers('cancelConfirmedCourse'),
    ...getServiceReducers('report'),
  },
})

export const { reducer, actions } = slice

//
// Selectors
//

const root = (state: RootState) => state[slice.name]

// services
const dashboardCourses = (state: RootState) => root(state).dashboardCourses
const teacherDashboardCourses = (state: RootState) => root(state).teacherDashboardCourses
const todayCourse = (state: RootState) => root(state).todayCourse
const course = (state: RootState) => root(state).course
const courseRequest = (state: RootState) => root(state).courseRequest
const planningCourses = (state: RootState) => root(state).planningCourses
const teacherFutureCourses = (state: RootState) => root(state).teacherFutureCourses
const teacherPastCourses = (state: RootState) => root(state).teacherPastCourses
const pendingReviewCourses = (state: RootState) => root(state).pendingReviewCourses
const evaluateStudents = (state: RootState) => root(state).evaluateStudents
const cancelCourseRequest = (state: RootState) => root(state).cancelCourseRequest
const cancelPendingCourse = (state: RootState) => root(state).cancelPendingCourse
const cancelConfirmedCourse = (state: RootState) => root(state).cancelConfirmedCourse
const report = (state: RootState) => root(state).report

export const selectors = {
  // services
  dashboardCourses,
  teacherDashboardCourses,
  todayCourse,
  course,
  courseRequest,
  planningCourses,
  teacherFutureCourses,
  teacherPastCourses,
  pendingReviewCourses,
  evaluateStudents,
  cancelCourseRequest,
  cancelPendingCourse,
  cancelConfirmedCourse,
  report,
}
