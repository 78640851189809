import { Container, Divider } from '@material-ui/core'
import styled, { css } from 'styled-components'

import RatingCustom from '../RatingCustom'
import IconC from '../Icon'

type minimalStyles = { $hasMinimalStyles?: boolean }

export const RatingModalities = styled.div``

export const Wrapper = styled(Container)`
  position: relative;
`

export const Profile = styled.div<minimalStyles>`
  display: flex;
  align-items: center;
  ${({ $hasMinimalStyles }) =>
    $hasMinimalStyles &&
    css`
      margin-bottom: 1.6rem;
    `};
`

export const ExpandBox = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1.6rem;
  cursor: pointer;
  user-select: none;
  color: ${(props) => props.theme.palette.colors.freeSpeechBlue};
`

export const ExpandText = styled.p`
  ${(props) => ({ ...props.theme.typography.smallBold })}
  color: ${(props) => props.theme.palette.colors.freeSpeechBlue};
  margin: 0;
`

export const Icon = styled(IconC)<{ isExpanded?: boolean }>`
  width: 2.4rem;
  height: 2.4rem;
  transform: ${(props) => (props.isExpanded ? 'rotate(180deg)' : 'rotate(0deg)')};
  margin-left: 0.5rem;
  color: ${(props) => props.theme.palette.colors.freeSpeechBlue};
`

export const Image = styled.img`
  width: 6.4rem;
  height: 6.4rem;
  object-fit: cover;
  border-radius: 50%;
`

export const ProfileInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 2rem;
`

export const Name = styled.span`
  ${(props) => ({ ...props.theme.typography.h4 })}
  color: ${(props) => props.theme.palette.colors.freeSpeechBlue};
`

export const Level = styled.span`
  ${(props) => ({ ...props.theme.typography.h7 })}
  color: ${(props) => props.theme.palette.colors.cornflowerBlue};
`

export const GlobalRate = styled.div``

export const Title = styled.span`
  ${(props) => ({ ...props.theme.typography.h4 })}
  color: ${(props) => props.theme.palette.colors.midnightBlue};
  margin-bottom: 1rem;
`

export const Rate = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

export const Rating = styled(RatingCustom)`
  font-size: 4.5rem;
`

export const Value = styled.span`
  ${(props) => ({ ...props.theme.typography.h4 })}
  color: ${(props) => props.theme.palette.colors.neonCarrot};
`

export const Muidivider = styled(Divider)<minimalStyles>`
  margin-top: 2rem;
  width: 100%;
  margin-bottom: 1.8rem;
  ${({ $hasMinimalStyles }) => $hasMinimalStyles && 'opacity: 0'};
`

export const Modality = styled.div`
  display: flex;
  flex-direction: column;
`

export const ModalityItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  &:last-of-type {
    ${Muidivider} {
      margin-bottom: 0;
    }
  }
`

export const CommentaryBox = styled.div`
  display: flex;
  flex-direction: column;
`

export const CommentaryTitle = styled.span`
  ${(props) => ({ ...props.theme.typography.h5 })}
  color: ${(props) => props.theme.palette.colors.freeSpeechBlue};
`

export const CommentaryText = styled.span`
  ${(props) => ({ ...props.theme.typography.smallRegular })}
  color: ${(props) => props.theme.palette.colors.chambray};
`
