import React, { useCallback, useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { HeaderProps } from '../../components/Header'
import useHeader from '../../hooks/useHeader'
import { MenuProps } from '../../components/Menu'
import useMenu from '../../hooks/useMenu'
import { Course, CourseType, LessonType, UserType } from '../../generated/graphql'
import { RestrictedReactFC } from '../../types/common'
import { actions, selectors } from '../../redux'
import SearchClassRequestsTemplate, {
  SearchClassRequestsTemplateProps,
} from '../../templates/SearchClassRequests'
import { courseTypeTranslation } from '../../graphql/enums/CourseType'
import { RequestCourseModalProps } from '../../components/modals/RequestCourseModal'
import { useFormSubmit } from '../../hooks/useFormSubmit'
import useBackButton from '../../hooks/useBackButton'
import { courseToTeacherRequestCardProps } from '../../transformers/courseTransformers'
import useFeedbackModal from '../../hooks/useFeedbackModal'
import { BasicLinkProps } from '../../components/BasicLink'
import { Icons } from '../../components/Icon/types'

const SearchClassRequestsPage: RestrictedReactFC<RouteComponentProps> = (props) => {
  const headerProps: HeaderProps = useHeader()
  const menuProps: MenuProps = useMenu(props)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const backButton = useBackButton()
  const [currentTab, setCurrentTab] = useState(CourseType.OneOff)
  const user = useSelector(selectors.auth.user)

  const requests = useSelector(selectors.search.teacherClassRequests)

  const [modalState, setModalState] = useState<{
    open: boolean
    accept?: boolean
    data?: Course
  }>({
    open: false,
  })

  const [setFeedbackModalOpen, feedbackModalProps] = useFeedbackModal({
    title: t('searchCourseRequests_feedbackModal_title'),
    subtitle: t('searchCourseRequests_feedbackModal_subtitle'),
    text: t('searchCourseRequests_feedbackModal_text'),
  })

  const onComplete = useCallback(() => {
    setModalState({ open: false })
    setFeedbackModalOpen(true)
    dispatch(actions.search.teacherClassRequestsRequest({ type: currentTab }))
  }, [setFeedbackModalOpen, dispatch, currentTab])

  const [teacherAcceptCourse, handleTeacherAcceptCourse] = useFormSubmit(
    selectors.search.teacherAcceptCourse,
    actions.search.teacherAcceptCourseRequest,
    actions.search.teacherAcceptCourseReset,
    onComplete
  )

  const [teacherDeclineCourse, handleTeacherDeclineCourse] = useFormSubmit(
    selectors.search.teacherDeclineCourse,
    actions.search.teacherDeclineCourseRequest,
    actions.search.teacherDeclineCourseReset,
    onComplete
  )

  const requestCourseModal: RequestCourseModalProps = {
    headerText: t('courseRequestModal_title'),
    title: modalState?.accept
      ? t('courseRequestModal_subtitle')
      : t('courseRequestModal_decline_subtitle'),
    ...(!modalState?.accept && {
      text: t('courseRequestModal_decline_text'),
    }),
    card: modalState?.data ? courseToTeacherRequestCardProps(modalState?.data, t, user) : undefined,
    cancelButton: {
      text: t('cancel'),
      onClick: () => {
        setModalState({ open: false })
      },
    },
    submitErrors: modalState?.accept ? teacherAcceptCourse?.errors : teacherDeclineCourse?.errors,
    confirmButton: {
      text: t('courseRequestModal_confirm'),
      isPending: modalState?.accept ? teacherAcceptCourse?.pending : teacherDeclineCourse?.pending,
      onClick: () => {
        //
        if (modalState?.accept) {
          handleTeacherAcceptCourse(modalState?.data?.id)
        } else {
          handleTeacherDeclineCourse(modalState?.data?.id)
        }
      },
    },
    open: modalState?.open,
    handleClose: () => {
      setModalState({ open: false })
    },
  }

  const searchProps: SearchClassRequestsTemplateProps = {
    headerProps,
    menuProps,
    title: t('searchCourseRequests_title'),
    tabs: [
      {
        label: courseTypeTranslation(t, CourseType.OneOff),
        value: CourseType.OneOff,
        total: requests?.data?.types?.[CourseType.OneOff] ?? 0,
        cards:
          requests?.data?.data?.map((course) => ({
            ...courseToTeacherRequestCardProps(course, t, user),
            ...(course?.lesson?.type === LessonType.Custom && {
              files: course?.lesson?.supports?.map(
                (support): BasicLinkProps => ({
                  text: support.file_name,
                  icon: Icons.download,
                  iconSide: 'left',
                  link: {
                    href: support.url ?? '',
                    target: '_blank',
                  },
                })
              ),
            }),
            declineButton: {
              text: t('course_card_decline'),
              onClick: () => {
                setModalState({
                  open: true,
                  accept: false,
                  data: course,
                })
              },
            },
            acceptButton: {
              text: t('course_card_accept'),
              onClick: () => {
                setModalState({
                  open: true,
                  accept: true,
                  data: course,
                })
              },
            },
          })) ?? [],
      },
      /*{
        label: courseTypeTranslation(t, CourseType.Emergency),
        value: CourseType.Emergency,
        total: requests?.data?.types?.[CourseType.Emergency] ?? 0,
        cards:
          requests?.data?.data?.map((course) => ({
            ...courseToTeacherRequestCardProps(course, t),
            declineButton: {
              text: t('course_card_decline'),
              onClick: () => {
                setModalState({
                  open: true,
                  accept: false,
                  data: course,
                })
              },
            },
            acceptButton: {
              text: t('course_card_accept'),
              onClick: () => {
                setModalState({
                  open: true,
                  accept: true,
                  data: course,
                })
              },
            },
          })) ?? [],
      },*/
    ],
    currentTab,
    onTabChange: (tab) => setCurrentTab(tab as CourseType),
    requestCourseModal,
    backButton,
    feedbackModalProps,
  }

  //
  // EFFECTS
  //
  useEffect(() => {
    dispatch(actions.search.teacherClassRequestsRequest({ type: currentTab }))
  }, [dispatch, currentTab])

  return <SearchClassRequestsTemplate {...searchProps} />
}

SearchClassRequestsPage.restrictedUserTypes = [UserType.Teacher]

export default SearchClassRequestsPage
