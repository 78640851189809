import { Divider } from '@material-ui/core'
import styled from 'styled-components'

import DatePickerC from '../form/DatePicker'
import CollapseC from '../Collapse'

export const Filters = styled.div`
  ${(props) => props.theme.breakpoints.up('md')} {
    max-width: 84.7rem;
  }
`

export const Collapse = styled(CollapseC)`
  margin: 1.6rem 0;
  padding: 0rem 1.6rem;
`

export const Fields = styled.div`
  & > * {
    margin-bottom: 1.6rem;
  }
`

export const DatePicker = styled(DatePickerC)`
  margin-bottom: 1.6rem;
`

export const Muidivider = styled(Divider)`
  width: 100%;
  margin-bottom: 1.8rem;
  padding: 0rem 1.6rem;
`
