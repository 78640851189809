import styled from 'styled-components'

import MultipleDifferentField from '../MultipleDifferentField'
import BasicLink from '../BasicLink'
import DocumentUploadC from '../form/UploadFile'
import TextFieldLimitedChars from '../form/TextFieldLimitedChars'
import FormSubmit from '../FormSubmit'
import TextFieldC from '../form/TextField'
import AddressC from '../form/Address'
import UploadImage from '../form/UploadImage'

export const ProfileForm = styled.div``

export const Wrapper = styled.div`
  width: 100%;
`

export const Rows = styled.div`
  display: flex;
  flex-direction: column;
  ${(props) => props.theme.breakpoints.up('md')} {
    flex-direction: row;
    margin: -0.8rem;
    & > * {
      margin: 0.8rem;
      width: calc(50% - 1.6rem);
    }
  }
`

export const FormGroup = styled.div``

export const Field = styled(MultipleDifferentField)`
  margin-bottom: 4rem;
  width: 100%;
`

export const ProfessionnalField = styled(MultipleDifferentField)`
  ${(props) => props.theme.breakpoints.up('md')} {
    width: 100%;
    & > div > div > div:first-child {
      width: 50%;
    }
  }
`

export const SubmitButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

export const SubmitButton = styled(FormSubmit)`
  margin: 1.6rem 0 3.2rem;
  ${(props) => props.theme.breakpoints.up('md')} {
    width: auto;
  }
`

export const Section = styled.div`
  padding: 0 0 1.6rem;
`

export const SectionTitle = styled.h3`
  ${(props) => ({ ...props.theme.typography.h3 })}
  color: ${(props) => props.theme.palette.colors.midnightBlue};
  margin: 0 0 1rem 0;
`

export const SectionFields = styled.div``

export const PasswordCta = styled(BasicLink)`
  display: flex;
  ${(props) => ({ ...props.theme.typography.smallStrong })}
  text-decoration: none;
  color: ${(props) => props.theme.palette.colors.freeSpeechBlue};
  cursor: pointer;
  margin-bottom: 1.6rem;
  background-color: ${(props) => props.theme.palette.colors.pureWhite};
  border-radius: 0.4rem;
  padding: 1rem;
  transition: color 0.3s, background-color 0.3s;
  & svg {
    color: ${(props) => props.theme.palette.colors.freeSpeechBlue};
    transition: color 0.3s;
  }
  &:hover {
    background-color: ${(props) => props.theme.palette.colors.freeSpeechBlue};
    color: ${(props) => props.theme.palette.colors.pureWhite};
    & svg {
      color: ${(props) => props.theme.palette.colors.pureWhite};
    }
  }
`

export const UploadFile = styled(DocumentUploadC)`
  width: 100%;
  margin-bottom: 1.6rem;
`

export const TextFieldLimited = styled(TextFieldLimitedChars)`
  margin-bottom: 1rem;
  width: 100%;
  margin-right: 1rem;
`

export const TextField = styled(TextFieldC)`
  margin-bottom: 1.6rem;
`
export const Address = styled(AddressC)`
  margin-bottom: 1.6rem;
`

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 1.6rem;
`

export const Avatar = styled(UploadImage)`
  margin-right: 1.6rem;
  & div {
    width: 8rem;
    height: 8rem;
  }
  & img {
    height: 8rem;
    width: 8rem;
  }
  & svg {
    height: 2.2rem;
    width: 2.2rem;
  }
`

export const HeaderContent = styled.div`
  display: flex;
  flex-direction: column;
`

export const HeaderTitle = styled.h3`
  ${(props) => ({ ...props.theme.typography.h3 })}
  color: ${(props) => props.theme.palette.colors.neonCarrot};
  margin: 0 0 -0.5rem 0;
`

export const HeaderName = styled.h3`
  ${(props) => ({ ...props.theme.typography.h3 })}
  color: ${(props) => props.theme.palette.colors.midnightBlue};
  margin: 0;
`
