import React, { FC } from 'react'
import { Elements } from '@stripe/react-stripe-js'

import useLoadStripe, { StripeLoadStatus } from '../../../hooks/useLoadStripe'

import StripePaymentConfirmModal, { StripePaymentConfirmModalProps } from './index'

const StripePaymentConfirmModalWrapper: FC<StripePaymentConfirmModalProps> = (props) => {
  const { stripeKey } = props

  const [stripePromise, stripeLoadStatus] = useLoadStripe(stripeKey)

  return (
    <Elements stripe={stripePromise || null}>
      {stripeLoadStatus === StripeLoadStatus.LOADED && <StripePaymentConfirmModal {...props} />}
    </Elements>
  )
}

export default StripePaymentConfirmModalWrapper
