import React, { FC } from 'react'

import Header, { HeaderProps } from '../../components/Header'
import Menu, { MenuProps } from '../../components/Menu'
import AuthLayout from '../../layouts/AuthLayout'
import { ImageTextListProps } from '../../components/ImageTextList'
import PageBackButton, { PageBackButtonProps } from '../../components/PageBackButton'

import * as SC from './styled'

export type PreferencesTemplateProps = {
  className?: string
  headerProps: HeaderProps
  menuProps: MenuProps
  title: string
  list: ImageTextListProps
  backButton?: PageBackButtonProps
}

const PreferencesTemplate: FC<PreferencesTemplateProps> = (props) => {
  const { headerProps, menuProps, title, list, backButton } = props

  return (
    <AuthLayout header={<Header {...headerProps} withDivider />} menu={<Menu {...menuProps} />}>
      <SC.Content>
        {backButton && <PageBackButton {...backButton} />}
        <SC.Title>{title}</SC.Title>
        <SC.ImageTextList {...list} />
      </SC.Content>
    </AuthLayout>
  )
}

export default PreferencesTemplate
