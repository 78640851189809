import React, { FC } from 'react'
import { Form, Formik, FormikHelpers } from 'formik'
import * as Yup from 'yup'

import { BasicLinkProps } from '../BasicLink'
import { Icons } from '../Icon/types'
import FormSubmitErrors, { FormSubmitErrorsProps } from '../FormSubmitErrors'
import type { FormFieldConfig, FormFieldProps } from '../../types/form'
import { getValidationSchema, renderField } from '../../helpers/FormHelpers'
import { FormSubmitProps } from '../FormSubmit'
import FormScrollToError from '../FormScrollToError'

import * as SC from './styled'

export type SignInForgotFormValues = {
  email: string
}

export type SignInForgotFormProps = {
  className?: string
  title: string
  text: string
  backButtonProps: BasicLinkProps
  mandatoryText: string
  initialValues: SignInForgotFormValues
  fieldsProps?: {
    email: FormFieldProps
  }
  errorTexts?: {
    required: string
    email: string
  }
  submitErrors?: FormSubmitErrorsProps['errors']
  submitButton: FormSubmitProps
  onSubmit?: (
    values: SignInForgotFormValues,
    formikHelpers: FormikHelpers<SignInForgotFormValues>
  ) => void
}

const SignInForgotForm: FC<SignInForgotFormProps> = (props) => {
  const {
    className,
    title,
    text,
    backButtonProps,
    mandatoryText,
    fieldsProps,
    initialValues,
    errorTexts,
    submitButton,
    submitErrors,
    onSubmit = (_v: SignInForgotFormValues) => null,
  } = props

  const fields: FormFieldConfig[] = [
    {
      name: 'email',
      Component: SC.TextField,
      validation: Yup.string().email(errorTexts?.email).required(errorTexts?.required),
      required: true,
    },
  ]

  const validationSchema = getValidationSchema(fields)

  return (
    <SC.SignInForm className={className}>
      <SC.Title>{title}</SC.Title>
      <SC.Text>{text}</SC.Text>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnChange={true}
        validateOnBlur={true}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {(formikProps) => (
          <Form noValidate>
            <FormScrollToError formikProps={formikProps} />
            <SC.Fields>
              {fields
                .filter((f) => f.name !== 'type' && f.name !== 'sponsor')
                .map((field, index) => renderField(field, formikProps, fieldsProps, index))}
            </SC.Fields>
            <FormSubmitErrors errors={submitErrors} />
            <SC.SubmitButton
              {...submitButton}
              isSuccess={submitButton.isSuccess && !formikProps.dirty}
              autoIcon
            />
            <SC.MandatoryText>{mandatoryText}</SC.MandatoryText>
          </Form>
        )}
      </Formik>
      <SC.BackButton {...backButtonProps} icon={Icons.longArrowLeft} iconSide={'left'} />
    </SC.SignInForm>
  )
}

export default SignInForgotForm
