import { Container } from '@material-ui/core'
import styled from 'styled-components'
import { Form } from 'formik'

import TabContentC from '../TabContent'
import TextFieldC from '../form/TextField'
import FormSubmit from '../FormSubmit'

import PricePickerC from './PricePicker'

export const PricesChoiceForm = styled.div``

export const Wrapper = styled(Container)`
  width: 100%;
`

export const Title = styled.h2`
  ${(props) => ({ ...props.theme.typography.h3 })}
  color: ${(props) => props.theme.palette.colors.neonCarrot};
  margin: 0;
`

export const TabContent = styled(TabContentC)``

export const Error = styled.p`
  color: ${(props) => props.theme.palette.colors.tomato};
  ${(props) => ({ ...props.theme.typography.smallRegular })}
  margin: -0.2rem 0 0.5rem 0;
`

export const MyForm = styled(Form)`
  display: flex;
  flex-direction: column;
`

export const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 2rem 0;
  width: 100%;
`

export const Text = styled.p`
  ${(props) => ({ ...props.theme.typography.smallRegular })}
  color: ${(props) => props.theme.palette.colors.chambray};
`

export const TextField = styled(TextFieldC)``

export const SubmitButton = styled(FormSubmit)``

export const PricesWrapper = styled.div`
  & > div {
    margin-bottom: 1.6rem;
  }
`

export const PricePicker = styled(PricePickerC)``
