import gql from 'graphql-tag'

export default gql`
  fragment TeacherAvailabilityFragment on TeacherAvailability {
    date
    slot
    recurrence {
      id
    }
  }
`
