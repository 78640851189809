import gql from 'graphql-tag'

import UserFragment from '../../../fragments/UserFragment'
import { User } from '../../../../generated/graphql'

const register = {
  mutation: gql`
    mutation register(
      $type: UserType!
      $firstName: String!
      $lastName: String!
      $email: String!
      $password: String!
      $passwordConfirmation: String!
      $sponsor: String
      $promo: String
    ) {
      register(
        user_type: $type
        first_name: $firstName
        last_name: $lastName
        email: $email
        password: $password
        password_confirmation: $passwordConfirmation
        sponsor_code: $sponsor
        promo_code: $promo
      ) {
        ...UserFragment
      }
    }
    ${UserFragment}
  `,
  transformer: (response: any): User => {
    return response.register
  },
}

export default register
