import React, { FC, memo, ReactNode } from 'react'

import Header, { HeaderProps } from '../../components/Header'
import Menu, { MenuProps } from '../../components/Menu'
import AuthLayout from '../../layouts/AuthLayout'
import { PageBackButtonProps } from '../../components/PageBackButton'
import { ThemePickerFormProps } from '../../components/ThemePickerForm'
import { ThemeListProps } from '../../components/ThemeList'
import { CustomThemeFormProps } from '../../components/CustomThemeForm'
import { SearchRecurrenceTeacherProfileProps } from '../../components/SearchRecurrenceTeacherProfile'

import * as SC from './styled'

export type SearchThemeTemplateProps = {
  className?: string
  children?: ReactNode
  headerProps: HeaderProps
  menuProps: MenuProps
  backButton: PageBackButtonProps
  title: string
  subTitle: string
  discipline: string
  customThemeFormProps?: CustomThemeFormProps
  themeListsProps?: ThemeListProps[]
  themePickerProps?: ThemePickerFormProps
  teacherProfileProps?: SearchRecurrenceTeacherProfileProps
}

export const SearchThemeTemplate: FC<SearchThemeTemplateProps> = (props) => {
  const {
    headerProps,
    menuProps,
    title,
    backButton,
    subTitle,
    discipline,
    customThemeFormProps,
    themePickerProps,
    themeListsProps,
    teacherProfileProps,
  } = props

  return (
    <AuthLayout header={<Header {...headerProps} withDivider />} menu={<Menu {...menuProps} />}>
      <SC.Content>
        <SC.BackButton {...backButton} />
        <SC.Subtitle>{title}</SC.Subtitle>
        <SC.Title>{subTitle}</SC.Title>
        <SC.Discipline>{discipline}</SC.Discipline>
        {teacherProfileProps && <SC.TeacherProfile {...teacherProfileProps} />}
        {themePickerProps && <SC.ThemePickerForm {...themePickerProps} />}
        {customThemeFormProps && <SC.CustomThemeForm {...customThemeFormProps} />}
        {themeListsProps && (
          <SC.ThemeLists>
            {themeListsProps?.map((list, key) => (
              <SC.ThemeList {...list} key={key} />
            ))}
          </SC.ThemeLists>
        )}
      </SC.Content>
    </AuthLayout>
  )
}

export default memo(SearchThemeTemplate)
