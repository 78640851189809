import gql from 'graphql-tag'

export type JoinRoomResult = {
  name?: string
  token?: string
  hash?: string
  config: any
  interfaceConfig: any
}

const decodeJson = (json: string) => {
  if (json) {
    try {
      return JSON.parse(json)
    } catch (e) {
      console.error(e)
    }
  }
  return null
}

const joinRoom = {
  query: gql`
    query joinRoom($room: String!, $student: ID) {
      joinRoom(room: $room, student: $student) {
        name
        token
        hash
        settings {
          config
          interface_config
        }
      }
    }
  `,
  transformer: (response: any): JoinRoomResult => {
    return {
      name: response.joinRoom?.name,
      hash: response.joinRoom?.hash,
      token: response.joinRoom?.token,
      config: decodeJson(response.joinRoom?.settings?.config),
      interfaceConfig: decodeJson(response.joinRoom?.settings?.interface_config),
    }
  },
}

export default joinRoom
