import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { HeaderProps } from '../../components/Header'
import useHeader from '../../hooks/useHeader'
import { MenuProps } from '../../components/Menu'
import useMenu from '../../hooks/useMenu'
import { UserType } from '../../generated/graphql'
import { RestrictedReactFC } from '../../types/common'
import { router, routesPath } from '../../router'
import { selectors } from '../../redux'
import {
  globalRateTransformer,
  ratingModalityTransformer,
} from '../../transformers/ratingTransformers'
import { studentProfileTransformer } from '../../transformers/profileTransformers'
import useBackButton from '../../hooks/useBackButton'
import PreferencesRatingTemplate, {
  PreferencesRatingTemplateProps,
} from '../../templates/PreferencesRating'

const PreferencesRatingPage: RestrictedReactFC<RouteComponentProps> = (props) => {
  const headerProps: HeaderProps = useHeader()
  const menuProps: MenuProps = useMenu(props)
  const { t } = useTranslation()
  const user = useSelector(selectors.auth.user)
  const backButton = useBackButton(router(routesPath.preferences))

  const rates = {
    punctuality: user?.student?.punctuality ?? 0,
    behaviour: user?.student?.behaviour ?? 0,
    interactivity: user?.student?.interactivity ?? 0,
    understanding: user?.student?.understanding ?? 0,
    working_conditions: user?.student?.working_conditions ?? 0,
  }

  const preferencesProps: PreferencesRatingTemplateProps = {
    menuProps,
    headerProps,
    title: t('preferences_rating_title'),
    backButton,
    scoreCardProps: {
      id: user?.student?.id ?? '',
      profile: studentProfileTransformer(user?.student),
      modality: ratingModalityTransformer(t, rates),
      rate: globalRateTransformer(t, user?.student?.score ?? 0),
      hasMinimalStyles: true,
      isExpanded: true,
    },
  }

  return <PreferencesRatingTemplate {...preferencesProps} />
}

PreferencesRatingPage.restrictedUserTypes = [UserType.Student]

export default PreferencesRatingPage
