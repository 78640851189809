import gql from 'graphql-tag'

import { Course, PaginatorInfo } from '../../../../generated/graphql'
import CourseFragment from '../../../fragments/CourseFragment'
import PaginatorInfoFragment from '../../../fragments/PaginatorInfoFragment'

export type CoursesWithMaterialResult = {
  data: Course[]
  paginatorInfo: PaginatorInfo
}

const coursesWithMaterial = {
  query: gql`
    query coursesWithMaterial(
      $first: Int!
      $page: Int
      $student: ID
      $discipline: ID
      $grade: ID
      $orderBy: [QueryCoursesWithMaterialOrderByOrderByClause!]
    ) {
      coursesWithMaterial(
        first: $first
        page: $page
        student: $student
        discipline: $discipline
        grade: $grade
        orderBy: $orderBy
      ) {
        data {
          ...CourseFragment
        }
        paginatorInfo {
          ...PaginatorInfoFragment
        }
      }
    }
    ${CourseFragment}
    ${PaginatorInfoFragment}
  `,
  transformer: (response: any): CoursesWithMaterialResult => {
    return {
      data: response?.coursesWithMaterial?.data,
      paginatorInfo: response?.coursesWithMaterial?.paginatorInfo,
    }
  },
}

export default coursesWithMaterial
