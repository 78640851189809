import gql from 'graphql-tag'

import TeacherAvailabilityFragment from './TeacherAvailabilityFragment'

export default gql`
  fragment TeacherAvailabilityRecurrenceFragment on TeacherAvailabilityRecurrence {
    id
    availabilities {
      ...TeacherAvailabilityFragment
    }
    slots
    start_at
    end_at
    days
  }
  ${TeacherAvailabilityFragment}
`
