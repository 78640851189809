import { all, call, put, takeLatest, takeLeading } from 'redux-saga/effects'
import dayjs from 'dayjs'

import { ServiceRequestAction } from '../../helpers/ReduxHelpers'
import { ApiResponse } from '../api/types/state'
import ApiSagas from '../api/sagas'
import { actions } from '../index'
import {
  AvailabilitySlot,
  CourseType,
  MutationAcceptCourseArgs,
  MutationApplyToCourseArgs,
  MutationCreateCourseArgs,
  MutationCreateLessonArgs,
  MutationDeclineCourseArgs,
  MutationPickCourseOfMarketArgs,
  MutationRequestCourseArgs,
  QueryClassMarketByDateAndSlotArgs,
  QueryClassMarketByDateArgs,
  QuerySearchCourseArgs,
  QueryTeacherByIdArgs,
  ThemesQueryVariables,
} from '../../generated/graphql'
import themes from '../../graphql/services/theme/queries/themes'
import searchCourse from '../../graphql/services/course/queries/searchCourse'
import requestCourse from '../../graphql/services/course/mutations/requestCourse'
import acceptCourse from '../../graphql/services/course/mutations/acceptCourse'
import applyToCourse from '../../graphql/services/course/mutations/applyToCourse'
import teacherPendingCourses from '../../graphql/services/course/queries/teacherPendingCourses'
import createCourse from '../../graphql/services/course/mutations/createCourse'
import { MarketCourseFormValues } from '../../components/MarketCourseForm'
import classMarketByDate from '../../graphql/services/course/queries/classMarketByDate'
import classMarketByDateAndSlot from '../../graphql/services/course/queries/classMarketByDateAndSlot'
import pickCourseOfMarket from '../../graphql/services/course/mutations/pickCourseOfMarket'
import studentDisabledDates from '../../graphql/services/course/queries/studentDisabledDates'
import declineCourse from '../../graphql/services/course/mutations/declineCourse'
import createLesson from '../../graphql/services/lesson/mutations/createLesson'
import teacherById from '../../graphql/services/teacher/queries/teacherById'

import {
  ApplyToCoursePayloadProps,
  CourseRequestPayloadProps,
  CreateCoursePayloadProps,
  SearchMarketSlotPayload,
  SearchPayloadProps,
} from './types/actions'
import { SearchSteps } from './types/state'

export default class SearchSagas {
  static *onThemesRequest({ payload }: ServiceRequestAction<ThemesQueryVariables>) {
    const rs: ApiResponse<typeof themes> = yield call(ApiSagas.persistQuery, themes, payload)

    if (!rs?.errors) {
      yield put(actions.search.themesSuccess(rs?.data))
    } else {
      yield put(actions.search.themesError(rs.errors))
    }
  }

  static *onTeacherClassRequests({ payload }: ServiceRequestAction<{ type: CourseType }>) {
    const rs: ApiResponse<typeof teacherPendingCourses> = yield call(
      ApiSagas.query,
      teacherPendingCourses,
      payload
    )

    if (!rs?.errors) {
      yield put(actions.search.teacherClassRequestsSuccess(rs?.data))
    } else {
      yield put(actions.search.teacherClassRequestsError(rs.errors))
    }
  }

  static *onSearchRequest({ payload }: ServiceRequestAction<SearchPayloadProps>) {
    const input: QuerySearchCourseArgs = {
      first: payload?.first ?? 10,
      page: payload?.page,
      ...(payload?.student && { student: payload?.student }),
      ...(payload?.teacher && { teacher: payload?.teacher }),
      type: payload?.type ?? CourseType.OneOff,
      lesson: payload?.theme ?? '',
      maximum_price: Math.round(((payload?.price ? parseFloat(payload?.price) : 0) ?? 0) * 100),
      maximum_participants: (payload?.size ? parseInt(payload?.size, 10) : 0) ?? 0,
      ...(payload?.availabilities && {
        availabilities: payload?.availabilities?.map((avail) => ({
          date: dayjs(avail.date).format('YYYY-MM-DD'),
          slots: avail.slots,
        })),
      }),
    }
    const rs: ApiResponse<typeof searchCourse> = yield call(ApiSagas.query, searchCourse, input)

    if (!rs?.errors) {
      yield put(actions.search.searchSuccess(rs?.data))
    } else {
      yield put(actions.search.searchError(rs.errors))
    }
  }

  static *onRequestCourseRequest({ payload }: ServiceRequestAction<CourseRequestPayloadProps>) {
    const input: MutationRequestCourseArgs = {
      ...(payload?.student && { student: payload?.student }),
      type: payload?.type ?? CourseType.OneOff,
      lesson: payload?.theme ?? '',
      maximum_price: Math.round(((payload?.price ? parseFloat(payload?.price) : 0) ?? 0) * 100),
      maximum_participants: (payload?.size ? parseInt(payload?.size, 10) : 0) ?? 0,
      availabilities:
        payload?.availabilities.map((avail) => ({
          date: dayjs(avail.date).format('YYYY-MM-DD'),
          slots: avail.slots,
        })) ?? [],
      notice_period: payload?.delay ? parseInt(payload?.delay, 10) : 2 ?? 2,
    }
    const rs: ApiResponse<typeof requestCourse> = yield call(ApiSagas.mutate, requestCourse, input)

    if (!rs?.errors) {
      yield put(actions.search.courseRequestSuccess(rs?.data))
      yield put(actions.auth.reloadWalletRequest(null))
    } else {
      yield put(actions.search.courseRequestError(rs.errors))
    }
  }

  static *onApplyToCourseRequest({ payload }: ServiceRequestAction<ApplyToCoursePayloadProps>) {
    const input: MutationApplyToCourseArgs = {
      course: payload?.id ?? '',
      maximum_participants: (payload?.size ? parseInt(payload?.size, 10) : 0) ?? 0,
      ...(payload?.student && { student: payload?.student }),
      ...(payload?.teacher && { teacher: payload?.teacher }),
      ...(payload?.date && { date: dayjs(payload.date).format('YYYY-MM-DD') }),
      ...(payload?.slot && { slot: payload?.slot }),
    }
    const rs: ApiResponse<typeof applyToCourse> = yield call(ApiSagas.mutate, applyToCourse, input)

    if (!rs?.errors) {
      yield put(actions.search.applyToCourseSuccess(rs?.data))
      yield put(actions.auth.reloadWalletRequest(null))
    } else {
      yield put(actions.search.applyToCourseError(rs.errors))
    }
  }

  static *onCreateCourseRequest({ payload }: ServiceRequestAction<CreateCoursePayloadProps>) {
    const input: MutationCreateCourseArgs = {
      lesson: payload?.lesson ?? '',
      type: payload?.type ?? CourseType.OneOff,
      maximum_participants: (payload?.size ? parseInt(payload?.size, 10) : 0) ?? 0,
      teacher: payload?.teacher ?? '',
      date: dayjs(payload?.date).format('YYYY-MM-DD'),
      slot: payload?.slot ?? AvailabilitySlot.H12,
      ...(payload?.student && { student: payload?.student }),
    }
    const rs: ApiResponse<typeof createCourse> = yield call(ApiSagas.mutate, createCourse, input)

    if (!rs?.errors) {
      yield put(actions.search.createCourseSuccess(rs?.data))
      yield put(actions.auth.reloadWalletRequest(null))
    } else {
      yield put(actions.search.createCourseError(rs.errors))
    }
  }

  static *onTeacherAcceptCourse({ payload }: ServiceRequestAction<string>) {
    const input: MutationAcceptCourseArgs = {
      course: payload ?? '',
    }
    const rs: ApiResponse<typeof acceptCourse> = yield call(ApiSagas.mutate, acceptCourse, input)

    if (!rs?.errors) {
      yield put(actions.auth.reloadWalletRequest(null))
      yield put(actions.search.teacherAcceptCourseSuccess(rs?.data))
    } else {
      yield put(actions.search.teacherAcceptCourseError(rs.errors))
    }
  }

  static *onTeacherDeclineCourse({ payload }: ServiceRequestAction<string>) {
    const input: MutationDeclineCourseArgs = {
      course: payload ?? '',
    }
    const rs: ApiResponse<typeof declineCourse> = yield call(ApiSagas.mutate, declineCourse, input)

    if (!rs?.errors) {
      yield put(actions.search.teacherDeclineCourseSuccess(rs?.data))
    } else {
      yield put(actions.search.teacherDeclineCourseError(rs.errors))
    }
  }

  static *onSearchMarketRequest({ payload }: ServiceRequestAction<MarketCourseFormValues>) {
    const input: QueryClassMarketByDateArgs = {
      type: (payload?.type as CourseType) ?? CourseType.OneOff,
      date: payload?.date ?? '',
      minimum_price: parseFloat(payload?.price ?? '0') * 100 ?? 0,
    }
    const rs: ApiResponse<typeof classMarketByDate> = yield call(
      ApiSagas.query,
      classMarketByDate,
      input
    )

    if (!rs?.errors) {
      yield put(actions.search.searchMarketSuccess(rs?.data))
    } else {
      yield put(actions.search.searchMarketError(rs.errors))
    }
  }

  static *onSearchMarketSlotRequest({ payload }: ServiceRequestAction<SearchMarketSlotPayload>) {
    const input: QueryClassMarketByDateAndSlotArgs = {
      first: 100,
      type: (payload?.type as CourseType) ?? CourseType.OneOff,
      date: payload?.date ?? '',
      minimum_price: parseFloat(payload?.price ?? '0') * 100 ?? 0,
      slot: payload?.slot ?? AvailabilitySlot.H8,
    }
    const rs: ApiResponse<typeof classMarketByDate> = yield call(
      ApiSagas.query,
      classMarketByDateAndSlot,
      input
    )

    if (!rs?.errors) {
      yield put(actions.search.searchMarketSlotSuccess(rs?.data))
    } else {
      yield put(actions.search.searchMarketSlotError(rs.errors))
    }
  }

  static *onAcceptMarketCourse({ payload }: ServiceRequestAction<MutationPickCourseOfMarketArgs>) {
    const rs: ApiResponse<typeof pickCourseOfMarket> = yield call(
      ApiSagas.mutate,
      pickCourseOfMarket,
      payload
    )

    if (!rs?.errors) {
      yield put(actions.auth.reloadWalletRequest(null))
      yield put(actions.search.acceptMarketCourseSuccess(rs?.data))
    } else {
      yield put(actions.search.acceptMarketCourseError(rs.errors))
    }
  }

  static *onStudentDisabledDates({ payload }: ServiceRequestAction<{ student?: string }>) {
    const rs: ApiResponse<typeof studentDisabledDates> = yield call(
      ApiSagas.query,
      studentDisabledDates,
      payload
    )

    if (!rs?.errors) {
      yield put(actions.search.studentDisabledDatesSuccess(rs?.data))
    } else {
      yield put(actions.search.studentDisabledDatesError(rs.errors))
    }
  }

  static *onCreateLessonRequest({ payload }: ServiceRequestAction<MutationCreateLessonArgs>) {
    const rs: ApiResponse<typeof createLesson> = yield call(ApiSagas.mutate, createLesson, payload)

    if (!rs?.errors) {
      yield put(actions.search.saveStep({ step: SearchSteps.STEP_2_THEME, lesson: rs?.data }))
      yield put(actions.search.createLessonSuccess(rs?.data))
    } else {
      yield put(actions.search.createLessonError(rs.errors))
    }
  }

  static *onTeacherByIdRequest({ payload }: ServiceRequestAction<QueryTeacherByIdArgs>) {
    const rs: ApiResponse<typeof teacherById> = yield call(ApiSagas.query, teacherById, payload)

    if (!rs?.errors) {
      yield put(actions.search.teacherByIdSuccess(rs?.data))
    } else {
      yield put(actions.search.teacherByIdError(rs.errors))
    }
  }

  static *loop() {
    yield all([
      takeLatest(actions.search.themesRequest, this.onThemesRequest),
      takeLatest(actions.search.searchRequest, this.onSearchRequest),
      takeLeading(actions.search.courseRequestRequest, this.onRequestCourseRequest),
      takeLeading(actions.search.applyToCourseRequest, this.onApplyToCourseRequest),
      takeLeading(actions.search.createCourseRequest, this.onCreateCourseRequest),
      takeLeading(actions.search.teacherClassRequestsRequest, this.onTeacherClassRequests),
      takeLeading(actions.search.teacherAcceptCourseRequest, this.onTeacherAcceptCourse),
      takeLeading(actions.search.teacherDeclineCourseRequest, this.onTeacherDeclineCourse),
      takeLatest(actions.search.searchMarketRequest, this.onSearchMarketRequest),
      takeLatest(actions.search.searchMarketSlotRequest, this.onSearchMarketSlotRequest),
      takeLeading(actions.search.acceptMarketCourseRequest, this.onAcceptMarketCourse),
      takeLeading(actions.search.studentDisabledDatesRequest, this.onStudentDisabledDates),
      takeLeading(actions.search.createLessonRequest, this.onCreateLessonRequest),
      takeLatest(actions.search.teacherByIdRequest, this.onTeacherByIdRequest),
    ])
  }
}
