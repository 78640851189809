import styled from 'styled-components'

import IconC from '../Icon'

export const CourseStatus = styled.div``

export const Wrapper = styled.div<{ backgroundColor?: string }>`
  display: flex;
  background-color: ${({ theme, backgroundColor }) =>
    backgroundColor || theme.palette.colors.pureWhite};
  border-radius: 0.8rem;
  padding: 0 0.8rem;
  align-items: center;
  flex-wrap: wrap;
`

export const RegistrationWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 0.8rem;
`

export const RegistrationNumber = styled.div`
  ${(props) => ({ ...props.theme.typography.h6 })}
  color: ${(props) => props.theme.palette.colors.chambray};
  padding: 0.4rem 0.8rem;
  background-color: ${(props) => props.theme.palette.colors.chambrayLight};
  border-radius: 0.8rem;
  margin-right: 0.8rem;
  width: 2.4rem;
  height: 2.4rem;
  text-align: center;
`

export const RegistrationText = styled.p`
  ${(props) => ({ ...props.theme.typography.h6 })}
  color: ${(props) => props.theme.palette.colors.chambray};
  margin: 0;
`

export const StatusWrapper = styled(RegistrationWrapper)``

export const Icon = styled(IconC)`
  width: 2.4rem;
  height: 2.4rem;
  color: ${(props) => props.theme.palette.colors.pureWhite};
  margin-right: 0.8rem;
`

export const StatusText = styled.p<{ $color?: string }>`
  ${(props) => ({ ...props.theme.typography.h6 })}
  color: ${(props) => props?.$color ?? props.theme.palette.colors.cornflowerBlue};
  margin: 0;
`

export const BottomBadge = styled(StatusWrapper)``

export const BottomBadgeContent = styled.div`
  ${(props) => ({ ...props.theme.typography.h6 })}
  background: rgba(255, 154, 46, 0.2);
  padding: 0.2rem 0.7rem;
  border-radius: 0.8rem;
  height: 2.4rem;
  align-items: center;
  display: flex;
  color: ${(props) => props.theme.palette.colors.neonCarrot};
`

export const BottomBadgeLabel = styled.div`
  ${(props) => ({ ...props.theme.typography.h6 })}
  color: ${(props) => props.theme.palette.colors.neonCarrot};
  margin: 0 0 0 0.8rem;
`

export const Price = styled.p<{ $color?: string }>`
  ${(props) => ({ ...props.theme.typography.h6 })}
  color: ${(props) => props?.$color ?? props.theme.palette.colors.cornflowerBlue};
  margin: 0.8rem;
`
