import styled from 'styled-components'

import PageTitle from '../../components/PageTitle'
import PageBackButton from '../../components/PageBackButton'
import TabContentC from '../../components/TabContent'
import ManageMoneyFormC from '../../components/PotDepositForm'
import StripePaymentFormWrapper from '../../components/StripePaymentForm/wrapper'

export const Content = styled.div``

export const Title = styled(PageTitle)``
export const BackButton = styled(PageBackButton)``

export const TabContent = styled(TabContentC)`
  padding: 0 1.6rem;
`

export const PotDeposit = styled(ManageMoneyFormC)`
  padding: 0 1.6rem;
`

export const PaymentForm = styled(StripePaymentFormWrapper)`
  padding: 0 1.6rem;
`
