import React, { useCallback, useEffect, useMemo } from 'react'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'

import { HeaderProps } from '../../components/Header'
import useHeader from '../../hooks/useHeader'
import { MenuProps } from '../../components/Menu'
import useMenu from '../../hooks/useMenu'
import { ClassMarketSlot, UserType } from '../../generated/graphql'
import { RestrictedReactFC } from '../../types/common'
import { router, routesPath } from '../../router'
import useBackButton from '../../hooks/useBackButton'
import {
  SearchMarketSlotsTemplate,
  SearchMarketSlotsTemplateProps,
} from '../../templates/SearchMarketSlots'
import { DateCarouselProps } from '../../components/DateCarousel'
import { CardListProps } from '../../components/CardList'
import { useFormSubmit } from '../../hooks/useFormSubmit'
import { actions, selectors } from '../../redux'
import { Icons } from '../../components/Icon/types'
import { availabilitySlotTranslation } from '../../graphql/enums/AvailabilitySlot'

const SearchMarketSlotsPage: RestrictedReactFC<
  RouteComponentProps<{ type: string; price: string; date: string }>
> = (props) => {
  const headerProps: HeaderProps = useHeader()
  const menuProps: MenuProps = useMenu(props)
  const backButton = useBackButton(router(routesPath.searchMarket), true)
  const { type, price, date } = props.match?.params
  const history = useHistory()
  const { t } = useTranslation()

  const [search, handleSearch] = useFormSubmit(
    selectors.search.searchMarket,
    actions.search.searchMarketRequest
  )

  const selectedDayCount = useCallback(
    (day): number =>
      search?.params?.type === type &&
      search?.params?.date === day &&
      search?.params?.price === price &&
      search.complete
        ? search?.data?.reduce((arr: number, entry: ClassMarketSlot) => arr + entry?.count, 0)
        : -1,
    [
      price,
      search.complete,
      search?.data,
      search?.params?.date,
      search?.params?.price,
      search?.params?.type,
      type,
    ]
  )

  const dateCarouselProps: DateCarouselProps = useMemo(
    () => ({
      value: date,
      transformer: (d: string) => {
        const count = selectedDayCount(d)
        return {
          label: dayjs(d, 'YYYY-MM-DD').format('DD-MM-YYYY'),
          count:
            count > -1
              ? t('searchMarketSlots_count', { count: count })
              : t('searchMarketSlots_loading'),
        }
      },
      onChange: (newDate) => {
        history?.push(router(routesPath.searchMarketSlots, { date: newDate, type, price }))
      },
    }),
    [date, selectedDayCount, t, history, type, price]
  )

  const cardListProps: CardListProps = useMemo(
    () => ({
      cards: search?.data?.map((slot: ClassMarketSlot) => ({
        hour: availabilitySlotTranslation(t, slot.slot),
        ...(slot.available
          ? {
              cta: {
                text: t('searchMarketSlots_count', { count: slot.count }),
                link: {
                  link: router(routesPath.searchMarketResults, {
                    date,
                    type,
                    price,
                    slot: slot.slot,
                  }),
                },
                icon: Icons.longArrowRight,
              },
            }
          : {
              alreadyBooked: true,
              alreadyBookedText: t('searchMarketSlots_alreadyBooked'),
            }),
      })),
    }),
    [search, t, date, type, price]
  )

  const searchProps: SearchMarketSlotsTemplateProps = useMemo(
    () => ({
      headerProps: headerProps,
      menuProps: menuProps,
      title: t('searchMarketSlots_title'),
      dateCarouselProps,
      cardListProps,
      backButton,
    }),
    [headerProps, menuProps, t, dateCarouselProps, cardListProps, backButton]
  )

  useEffect(() => {
    if (
      search?.params?.type !== type ||
      search?.params?.date !== date ||
      search?.params?.price !== price
    ) {
      handleSearch({ type, date, price })
    }
  }, [search, date, price, type, handleSearch])

  return <SearchMarketSlotsTemplate {...searchProps} />
}

SearchMarketSlotsPage.restrictedUserTypes = [UserType.Teacher]

export default SearchMarketSlotsPage
