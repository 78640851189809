import React, { FC, memo, ReactNode } from 'react'

import Header, { HeaderProps } from '../../components/Header'
import Menu, { MenuProps } from '../../components/Menu'
import AuthLayout from '../../layouts/AuthLayout'
import PageBackButton, { PageBackButtonProps } from '../../components/PageBackButton'
import { DateCarouselProps } from '../../components/DateCarousel'
import { CardListProps } from '../../components/CardList'

import * as SC from './styled'

export type SearchMarketSlotsTemplateProps = {
  className?: string
  children?: ReactNode
  headerProps: HeaderProps
  menuProps: MenuProps
  backButton?: PageBackButtonProps
  title: string
  text?: string
  dateCarouselProps: DateCarouselProps
  cardListProps: CardListProps
}

export const SearchMarketSlotsTemplate: FC<SearchMarketSlotsTemplateProps> = (props) => {
  const {
    headerProps,
    menuProps,
    title,
    text,
    backButton,
    dateCarouselProps,
    cardListProps,
  } = props

  return (
    <AuthLayout header={<Header {...headerProps} withDivider />} menu={<Menu {...menuProps} />}>
      <SC.Content>
        {backButton && <PageBackButton {...backButton} />}
        <SC.Title>{title}</SC.Title>
        {text && <SC.Text>{text}</SC.Text>}
        <SC.DateCarousel {...dateCarouselProps} />
        <SC.MyCourseCardList {...cardListProps} />
      </SC.Content>
    </AuthLayout>
  )
}

export default memo(SearchMarketSlotsTemplate)
