import React, { FC } from 'react'

import { CourseCardProps } from '../CourseCard'
import { ActionButtonProps } from '../ActionButton'
import { BasicLinkProps } from '../BasicLink'

import * as SC from './styled'

export type CourseCardInContextProps = {
  className?: string
  cards: CourseCardProps[]
  title: string
  text?: string
  virtualClassButton?: ActionButtonProps
  planningCta?: BasicLinkProps
  historyCta?: BasicLinkProps
  backgroundColor?: string
  id?: string
}

const CourseCardInContext: FC<CourseCardInContextProps> = (props) => {
  const {
    className,
    cards,
    title,
    text,
    virtualClassButton,
    planningCta,
    historyCta,
    backgroundColor,
    id,
  } = props

  return (
    <SC.CourseCardInContext className={className} id={id}>
      <SC.Wrapper>
        <SC.Content backgroundColor={backgroundColor}>
          <SC.Title backgroundColor={backgroundColor}>{title}</SC.Title>
          {text && <SC.Text>{text}</SC.Text>}
          <SC.Cards>
            {cards.map((card, index) => (
              <SC.Card key={index} {...card} />
            ))}
          </SC.Cards>
          {virtualClassButton && <SC.VirtualClassButton {...virtualClassButton} />}
          {planningCta && <SC.PlanningCta {...planningCta} />}
          {historyCta && <SC.HistoryCta {...historyCta} />}
        </SC.Content>
      </SC.Wrapper>
    </SC.CourseCardInContext>
  )
}

export default CourseCardInContext
