import gql from 'graphql-tag'

import { Course } from '../../../../generated/graphql'
import CourseFragment from '../../../fragments/CourseFragment'

export type TeacherFutureCoursesResult = {
  today?: Course[]
  future: { data: Course[]; total: number }
}

const teacherFutureCourses = {
  query: gql`
    query teacherFutureCourses {
      todayCourses {
        ...CourseFragment
      }
      futureCourses(first: 100) {
        data {
          ...CourseFragment
        }
        paginatorInfo {
          total
        }
      }
    }
    ${CourseFragment}
  `,
  transformer: (response: any): TeacherFutureCoursesResult => {
    const todayIds = response?.todayCourses?.map((c: Course) => c?.id)
    return {
      today: response?.todayCourses,
      future: {
        data: response?.futureCourses?.data?.filter((c: Course) => !todayIds?.includes(c.id)),
        total: response?.futureCourses?.paginatorInfo?.total,
      },
    }
  },
}

export default teacherFutureCourses
