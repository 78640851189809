import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { MenuProps } from '../components/Menu'
import { Icons } from '../components/Icon/types'
import { router, routesPath } from '../router'
import { selectors } from '../redux'
import { UserType } from '../generated/graphql'

const useMenu = (pageProps: RouteComponentProps): MenuProps => {
  const { t } = useTranslation()
  const path = pageProps?.match?.path
  const user = useSelector(selectors.auth.user)
  const isTeacher = user?.user_type == UserType.Teacher

  return useMemo(() => {
    return {
      sections: [
        {
          link: {
            href: router(routesPath.dashboard),
          },
          icon: Icons.home,
          label: t('menu_dashboard'),
          selected: [routesPath.dashboard, routesPath.dashboardChild].includes(path),
        },
        {
          link: {
            href: router(routesPath.planning),
          },
          label: t('menu_planning'),
          icon: Icons.calendar,
          selected: [
            routesPath.planning,
            routesPath.planningTab,
            routesPath.planningFuture,
            routesPath.planningHistory,
          ].includes(path),
        },
        {
          link: {
            href: router(routesPath.search),
          },
          label: t('menu_class'),
          icon: Icons.search2,
          selected: [
            routesPath.search,
            routesPath.searchClassRequests,
            routesPath.searchAvailabilities,
            routesPath.searchDiscipline,
            routesPath.searchPrice,
            routesPath.searchResults,
            routesPath.searchTheme,
            routesPath.searchMarket,
            routesPath.searchMarketSlots,
            routesPath.searchMarketResults,
            routesPath.searchTeacherDiscipline,
            routesPath.searchTeacherResults,
            routesPath.searchTeacherTheme,
          ].includes(path),
        },
        ...(isTeacher
          ? [
              {
                link: {
                  href: router(routesPath.pot),
                },
                label: t('menu_pot'),
                icon: Icons.pot,
                selected: [routesPath.pot].includes(path),
              },
            ]
          : []),
        {
          link: {
            href: router(routesPath.preferences),
          },
          label: t('menu_preferences'),
          icon: Icons.filter,
          selected: [
            routesPath.preferences,
            routesPath.preferencesAvailabilities,
            routesPath.preferencesDisciplines,
            routesPath.preferencesPricing,
            routesPath.preferencesStudents,
            routesPath.preferencesWallet,
            routesPath.preferencesWalletDeposit,
            routesPath.preferencesWalletWithdrawal,
            routesPath.preferencesFamilyAddChild,
            routesPath.preferencesFamily,
            routesPath.preferencesFamilyChild,
            routesPath.preferencesAvailabilitiesRecurrent,
            routesPath.preferencesAvailabilitiesRecurrentAdd,
            routesPath.preferencesAvailabilitiesRecurrentEdit,
            routesPath.preferencesCourseMaterials,
            routesPath.preferencesRating,
          ].includes(path),
        },
      ],
      disconnect: {
        link: {
          href: router(routesPath.signOut),
        },
        label: t('menu_logout'),
        icon: Icons.signOut,
      },
    }
  }, [isTeacher, path, t])
}

export default useMenu
