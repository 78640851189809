import { Container } from '@material-ui/core'
import styled from 'styled-components'

import TextField from '../form/TextField'
import BasicLink from '../BasicLink'
import DocumentUploadWrapper from '../DocumentUploadWrapper'
import AddressC from '../form/Address'

export const MultipleDifferentField = styled.div`
  ${(props) => props.theme.breakpoints.up('md')} {
    width: 100%;
  }
`

export const Wrapper = styled(Container)``

export const Title = styled.h3`
  ${(props) => ({ ...props.theme.typography.h3 })}
  color: ${(props) => props.theme.palette.colors.midnightBlue};
  margin: 0 0 1rem 0;
`

export const Text = styled.p`
  ${(props) => ({ ...props.theme.typography.smallRegular })}
  color: ${(props) => props.theme.palette.colors.chambray};
  margin: 0 0 1rem 0;
`

export const Fields = styled.div``

export const Field = styled(TextField)`
  margin-bottom: 1rem;
`

export const Address = styled(AddressC)``

export const DocumentField = styled(DocumentUploadWrapper)`
  margin: 2rem 0 3rem 0;
`

export const Cta = styled(BasicLink)`
  margin-top: 2rem;
  display: flex;
  ${(props) => ({ ...props.theme.typography.smallStrong })}
  text-decoration: none;
  color: ${(props) => props.theme.palette.colors.freeSpeechBlue};
  cursor: pointer;
`
