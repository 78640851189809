import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { HeaderProps } from '../../components/Header'
import useHeader from '../../hooks/useHeader'
import { MenuProps } from '../../components/Menu'
import useMenu from '../../hooks/useMenu'
import { UserType } from '../../generated/graphql'
import { RestrictedReactFC } from '../../types/common'
import { router, routesPath } from '../../router'
import PreferencesStudentsTemplate, {
  PreferencesStudentsTemplateProps,
} from '../../templates/PreferencesStudents'
import { MaxStudentsFormProps } from '../../components/MaxStudentsForm'
import { actions, selectors } from '../../redux'
import { useFormSubmit } from '../../hooks/useFormSubmit'
import useBackButton from '../../hooks/useBackButton'

const PreferencesStudentsPage: RestrictedReactFC<RouteComponentProps> = (props) => {
  const headerProps: HeaderProps = useHeader()
  const menuProps: MenuProps = useMenu(props)
  const { t } = useTranslation()
  const user = useSelector(selectors.auth.user)
  const backButton = useBackButton(router(routesPath.preferences))

  const [updateTeacherParticipants, handleUpdateTeacherParticipants] = useFormSubmit(
    selectors.preferences.updateTeacherParticipants,
    actions.preferences.updateTeacherParticipantsRequest,
    actions.preferences.updateTeacherParticipantsReset
  )

  const maxStudentsFormArgs: MaxStudentsFormProps = {
    fieldsProps: {
      size: {
        label: t('preferences_students_count_label'),
        options: Array.from({ length: 4 }).map((_v, i) => ({
          label: i + 1 + '',
          value: i + 1 + '',
        })),
      },
    },
    initialValues: {
      size: user?.teacher?.maximum_participants + '' ?? '4',
    },
    submitButton: {
      text: t('save'),
      isPending: updateTeacherParticipants?.pending,
    },
    submitErrors: updateTeacherParticipants.errors,
    onSubmit: handleUpdateTeacherParticipants,
  }

  const preferencesProps: PreferencesStudentsTemplateProps = {
    menuProps,
    headerProps,
    title: t('preferences_students_title'),
    backButton,
    maxStudentsProps: maxStudentsFormArgs,
  }

  return <PreferencesStudentsTemplate {...preferencesProps} />
}

PreferencesStudentsPage.restrictedUserTypes = [UserType.Teacher]

export default PreferencesStudentsPage
