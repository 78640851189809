import React, { useCallback, useEffect, useRef, useState } from 'react'

import useScript from '../../hooks/useScript'

import * as SC from './styled'

export type JitsiProps = {
  url: string
  domain: string
  room: string
  token: string
  config?: any
  interfaceConfig?: any
  isModerator?: boolean
  onClose?: () => void
}

const Jitsi: React.FC<JitsiProps> = ({
  url,
  domain,
  room,
  token,
  config,
  interfaceConfig,
  onClose,
}) => {
  const script = useScript(url)
  const [api, setApi] = useState<any>(null)
  const jitsiRef = useRef<HTMLDivElement>(null)
  const [isRecording, setIsRecording] = useState(false)

  const getParticipants = useCallback(() => {
    if (api) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(api.getParticipantsInfo()) // get all participants
        }, 500)
      })
    }
    return 0
  }, [api])

  // custom events
  const executeCommand = useCallback(
    (command: string, params?: any) => {
      if (api) {
        api.executeCommand(command, params)
        if (command == 'hangup') {
          console.log('Hangup -> thank you')
        }
      }
    },
    [api]
  )

  const handleClose = useCallback(() => {
    console.log('handleClose')
    onClose?.()
  }, [onClose])

  const handleParticipantLeft = useCallback(
    async (participant) => {
      const data = await getParticipants()
      console.log('handleParticipantLeft', participant, data) // { id: "2baa184e" }
    },
    [getParticipants]
  )

  const handleParticipantJoined = useCallback(
    async (participant) => {
      const data = await getParticipants()
      console.log('handleParticipantJoined', participant, data) // { id: "2baa184e", displayName: "Shanu Verma", formattedDisplayName: "Shanu Verma" }
    },
    [getParticipants]
  )

  const handleVideoConferenceJoined = useCallback(
    async (participant) => {
      const data = await getParticipants()
      console.log('handleVideoConferenceJoined', participant, data) // { roomName: "bwb-bfqi-vmh", id: "8c35a951", displayName: "Akash Verma", formattedDisplayName: "Akash Verma (me)"}
    },
    [getParticipants]
  )

  const handleVideoConferenceLeft = useCallback(() => {
    console.log('handleVideoConferenceLeft')
    onClose?.()
  }, [onClose])

  const handleMuteStatus = useCallback((audio) => {
    console.log('handleMuteStatus', audio) // { muted: true }
  }, [])

  const handleVideoStatus = useCallback((video) => {
    console.log('handleVideoStatus', video) // { muted: true }
  }, [])

  const startRecording = useCallback(() => {
    setIsRecording(true)
    executeCommand('startRecording', {
      mode: 'file', //recording mode, either `file` or `stream`.
      dropboxToken: '', //dropbox oauth2 token.
      shouldShare: false, //whether the recording should be shared with the participants or not. Only applies to certain jitsi meet deploys.
      rtmpStreamKey: '', //the RTMP stream key.
      rtmpBroadcastID: '', //the RTMP broadcast ID.
      youtubeStreamKey: '', //the youtube stream key.
      youtubeBroadcastID: '', //the youtube broacast ID.
    })
  }, [executeCommand])

  const stopRecording = useCallback(() => {
    setIsRecording(false)
    executeCommand('stopRecording', {
      mode: 'file', //recording mode, either `file` or `stream`.
    })
  }, [executeCommand])

  const startMeet = useCallback(() => {
    const options = {
      roomName: room,
      jwt: token,
      width: '100%',
      height: '100%',
      configOverwrite: {
        ...config,
      },
      interfaceConfigOverwrite: {
        ...interfaceConfig,
      },
      parentNode: jitsiRef?.current,
    }

    const tmpApi = new (window as any)['JitsiMeetExternalAPI'](domain, options)

    tmpApi.addEventListeners({
      readyToClose: handleClose,
      participantLeft: handleParticipantLeft,
      participantJoined: handleParticipantJoined,
      videoConferenceJoined: handleVideoConferenceJoined,
      videoConferenceLeft: handleVideoConferenceLeft,
      audioMuteStatusChanged: handleMuteStatus,
      videoMuteStatusChanged: handleVideoStatus,
    })

    setApi(tmpApi)
  }, [
    domain,
    config,
    handleClose,
    handleMuteStatus,
    handleParticipantJoined,
    handleParticipantLeft,
    handleVideoConferenceJoined,
    handleVideoConferenceLeft,
    handleVideoStatus,
    interfaceConfig,
    room,
    token,
  ])

  useEffect(() => {
    if (script === 'ready' && !api) {
      if ((window as any)?.['JitsiMeetExternalAPI']) {
        console.log('start meet')
        startMeet()
      } else {
        console.log('JitsiMeetExternalAPI not loaded')
      }
    }
  }, [script, startMeet, api])

  return (
    <SC.Container>
      <SC.Jitsi ref={jitsiRef} />
      <SC.Controls>
        <i onClick={() => (!isRecording ? startRecording() : stopRecording())}>
          {!isRecording ? 'Start recording' : 'Stop recording'}
        </i>
      </SC.Controls>
    </SC.Container>
  )
}

export default Jitsi
