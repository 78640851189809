import styled, { css } from 'styled-components'

import Link from '../Link'
import IconC from '../Icon'

export const CourseCard = styled(Link)<{
  $isImportant?: boolean
  $isRead?: boolean
  $iconColor?: string
}>`
  text-decoration: none;
  display: flex;
  flex-direction: column;
  padding: 1.6rem 0;
  &:hover {
    text-decoration: none;
  }

  ${(props) =>
    props.$isImportant &&
    css`
      ${Icon} {
        color: ${props?.$iconColor ?? props.theme.palette.colors.kournikova} !important;
      }
      ${Title} {
        color: ${props.theme.palette.colors.tomato};
      }
    `}

  ${(props) =>
    props.$isRead &&
    css`
      ${Icon} {
        color: ${props.theme.palette.colors.chambray} !important;
      }
      ${Title}, ${Subtitle}, ${Legend} {
        color: ${props.theme.palette.colors.chambray};
      }
      opacity: 0.5;
    `};
`

export const Wrapper = styled.div`
  border-radius: 0.8rem;
  display: flex;
  flex-direction: column;
`

export const Date = styled.p`
  margin: 0 0 0.8rem;
  ${(props) => ({ ...props.theme.typography.smallRegular })}
  color: ${(props) => props.theme.palette.colors.grey};
`

export const Columns = styled.div`
  display: flex;
`

export const Icon = styled(IconC)`
  width: 2rem;
  height: 2rem;
  margin-right: 1rem;
  margin-top: 0.2rem;
  flex-shrink: 0;
  color: ${(props) => props?.color ?? props.theme.palette.colors.cornflowerBlue} !important;
`

export const Content = styled.div``

export const Title = styled.div`
  ${(props) => ({ ...props.theme.typography.h5 })}
  color: ${(props) => props.theme.palette.colors.midnightBlue};
  padding-bottom: 0.4rem;
`
export const Subtitle = styled.div`
  ${(props) => ({ ...props.theme.typography.h5 })}
  color: ${(props) => props.theme.palette.colors.midnightBlue};
  padding-bottom: 0.4rem;
`
export const Name = styled.div`
  ${(props) => ({ ...props.theme.typography.h6 })}
  color: ${(props) => props.theme.palette.colors.midnightBlue};
  padding-bottom: 0.4rem;
`
export const Legends = styled.div`
  display: flex;
`
export const Legend = styled.div`
  display: flex;
  ${(props) => ({ ...props.theme.typography.h7 })}
  color: ${(props) => props.theme.palette.colors.freeSpeechBlue};
  &:not(:last-of-type) {
    &::after {
      content: '.';
      display: flex;
      ${(props) => ({ ...props.theme.typography.h2 })}
      color: ${(props) => props.theme.palette.colors.gainsboro};
      line-height: 0.1;
      margin: 0 0.3rem;
    }
  }
`
