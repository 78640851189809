import gql from 'graphql-tag'

import { Course } from '../../../../generated/graphql'
import CourseFragment from '../../../fragments/CourseFragment'

const todayCourse = {
  query: gql`
    query todayCourse {
      todayCourse {
        ...CourseFragment
      }
    }
    ${CourseFragment}
  `,
  transformer: (response: any): Course => {
    return response.todayCourse
  },
}

export default todayCourse
