import styled from 'styled-components'

import ActionButton from '../../components/ActionButton'

export const SignUpStandBy = styled.div``

export const Actions = styled.div`
  padding: 0 1.6rem;
  ${(props) => props.theme.breakpoints.up('md')} {
    width: 60%;
    margin: auto;
  }
`

export const ReturnButton = styled(ActionButton)`
  margin-top: 5rem;
`

export const Action = styled(ActionButton)`
  margin: 1rem 0 1rem;
  width: 100%;
  display: block;
`

export const Content = styled.div`
  flex-grow: 1;
  ${(props) => props.theme.breakpoints.up('md')} {
    flex-grow: initial;
  }
`
