import styled from 'styled-components'

import ActionButton from '../../components/ActionButton'
import PotC from '../../components/Pot'
import MessageC from '../../components/Message'
import CourseCardInContextC from '../../components/CourseCardInContext'
import ImageTextListC from '../../components/ImageTextList'
import BasicLink from '../../components/BasicLink'
import ImageTextItemC from '../../components/ImageTextItem'

export const Content = styled.div`
  flex-grow: 1;
  ${(props) => props.theme.breakpoints.up('md')} {
    flex-grow: initial;
  }
`

export const Pot = styled(PotC)`
  margin-top: 2rem;
`

export const Course = styled.div`
  margin: 2rem 1.6rem;
`

export const Title = styled.h3`
  ${(props) => ({ ...props.theme.typography.h3 })}
  color: ${(props) => props.theme.palette.colors.midnightBlue};
  margin: 0rem 0 0.5rem 0;
`

export const Text = styled.p`
  ${(props) => ({ ...props.theme.typography.smallRegular })}
  color: ${(props) => props.theme.palette.colors.chambray};
  margin: 0rem 0 1rem 0;
`

export const Message = styled(MessageC)`
  margin-top: 2rem;
  padding: 0 1.6rem;
`

export const CourseCardInContext = styled(CourseCardInContextC)`
  margin-top: 1rem;
  margin-bottom: 1rem;
`

export const Cta = styled(ActionButton)`
  margin: 1.6rem 0;
  width: 24rem;
  display: block;
`

export const ImageTextList = styled(ImageTextListC)`
  width: 100%;
  ${(props) => props.theme.breakpoints.up('md')} {
    font-size: 4rem;
    & > div > div {
      max-width: 35rem;
    }
  }
`

export const Button = styled(ActionButton)`
  width: 20rem;
  margin: 1.6rem 0;
`

export const ManageFamilyCta = styled(BasicLink)`
  ${(props) => ({ ...props.theme.typography.smallBold })}
  color: ${(props) => props.theme.palette.colors.freeSpeechBlue};
  background-color: ${(props) => props.theme.palette.colors.pureWhite};
  text-transform: uppercase;
  padding: 1rem;
  border-radius: 0.5rem;
`

export const FamilyContainer = styled.div`
  padding: 1.6rem;
`
export const ImageTextItem = styled(ImageTextItemC)`
  width: calc(100% - 3.2rem);
  margin: 1.6rem;
  ${(props) => props.theme.breakpoints.up('md')} {
    width: 100%;
    font-size: 4rem;
    display: flex;
    justify-content: center;
    & > div {
      max-width: 40rem;
    }
  }
`
