import React, { FC } from 'react'
import { FormikErrors, FormikTouched } from 'formik'

import { TextFieldProps } from '../../form/TextField'

import * as SC from './styled'

export type PricePickerProps = {
  className?: string
  label: string
  name: string
  options: TextFieldProps[]
  values: {
    [key: string]: string
  }
  errors?: string | FormikErrors<{ [key: string]: string }>
  touched?: string | FormikTouched<{ [key: string]: boolean }>
  onBlur?: (name: string) => void
  onChange: (name: string, value: string) => void
  initialValues?: { [key: string]: string }
}

const PricePicker: FC<PricePickerProps> = (props) => {
  const { className, name, label, options, values, onChange, onBlur, errors, touched } = props

  return (
    <SC.PricePicker className={className}>
      <SC.Wrapper maxWidth="xl">
        <SC.Title>{label}</SC.Title>
        <SC.PriceLevels>
          {options?.map((level, index: number) => (
            <SC.PriceLevel key={index}>
              <SC.PriceLevelLabel>{level?.label} *</SC.PriceLevelLabel>
              <SC.PriceLevelField
                name={name + '.' + level?.name}
                placeholder={level?.placeholder}
                value={values[level?.name]}
                error={
                  (touched as any)?.[level?.name] ? ((errors as any)?.[level?.name] as string) : ''
                }
                onBlur={() => onBlur?.(name + '.' + level?.name)}
                onChange={onChange}
              />
            </SC.PriceLevel>
          ))}
        </SC.PriceLevels>
      </SC.Wrapper>
    </SC.PricePicker>
  )
}

export default PricePicker
