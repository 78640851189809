import gql from 'graphql-tag'

import UserFragment from '../../../fragments/UserFragment'
import { User } from '../../../../generated/graphql'

const me = {
  query: gql`
    query me {
      me {
        ...UserFragment
      }
    }
    ${UserFragment}
  `,
  transformer: (response: any): User => {
    return response.me
  },
}

export default me
