import { Container, Divider } from '@material-ui/core'
import styled from 'styled-components'

import BasicProfilC from '../BasicProfil'
import Link from '../Link'
import ImageTextList from '../ImageTextList'

export const CourseMaterialsCard = styled(Link)`
  text-decoration: none;
  display: flex;
  margin-bottom: 1.6rem;
  ${(props) => props.theme.breakpoints.up('md')} {
    max-width: 40rem;
    width: 100%;
  }
  &:hover {
    text-decoration: none;
  }
`

export const Wrapper = styled(Container)<{ $isNew?: boolean }>`
  padding: 1.6rem;
  background-color: ${(props) => props.theme.palette.colors.pureWhite};
  border-radius: 0.8rem;
  border: 2px solid
    ${(props) =>
      props.$isNew ? props.theme.palette.colors.neonCarrot : props.theme.palette.colors.ghostWhite};
  display: flex;
  flex-direction: column;
`

export const Date = styled.p`
  margin: 0rem;
  ${(props) => ({ ...props.theme.typography.callToAction })}
  color: ${(props) => props.theme.palette.colors.tomato};
`

export const Muidivider = styled(Divider)`
  width: 100%;
  margin: 1.2rem 0rem;
`
export const BasicProfil = styled(BasicProfilC)``

export const Description = styled.p`
  ${(props) => ({ ...props.theme.typography.smallRegular })}
  color: ${(props) => props.theme.palette.colors.chambray};
  display: flex;
  align-items: flex-end;
  margin-bottom: 0.5rem;
`
export const DetailDocuments = styled(ImageTextList)`
  margin-top: 1.6rem;
  margin-bottom: 0;
  & > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    ${(props) => props.theme.breakpoints.up('md')} {
      flex-direction: column;
      width: 100%;
      & > * {
        max-width: 100%;
        width: 100%;
        &:nth-child(2n + 1) {
          margin-right: 0;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
`
