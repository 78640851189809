import React, { useEffect, useMemo, useState } from 'react'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { HeaderProps } from '../../components/Header'
import useHeader from '../../hooks/useHeader'
import { MenuProps } from '../../components/Menu'
import useMenu from '../../hooks/useMenu'
import {
  CourseType,
  Discipline,
  PublicTeacherDiscipline,
  Student,
  UserType,
} from '../../generated/graphql'
import { RestrictedReactFC } from '../../types/common'
import { actions, selectors } from '../../redux'
import {
  SearchDisciplineTemplate,
  SearchDisciplineTemplateProps,
} from '../../templates/SearchDiscipline'
import { router, routesPath } from '../../router'
import { courseTypeTranslation } from '../../graphql/enums/CourseType'
import { SearchSteps } from '../../redux/search/types/state'
import useBackButton from '../../hooks/useBackButton'
import { light } from '../../theme/palette'

const SearchTeacherDisciplinePage: RestrictedReactFC<
  RouteComponentProps<{ teacher: string; child?: string }>
> = (props) => {
  const headerProps: HeaderProps = useHeader()
  const menuProps: MenuProps = useMenu(props)
  const { t } = useTranslation()
  const user = useSelector(selectors.auth.user)
  const teacherId = props?.match?.params?.teacher
  const child = props?.match?.params?.child
  const type: CourseType = CourseType.OneOff
  const allDisciplines = useSelector(selectors.app.disciplines)
  const history = useHistory()
  const dispatch = useDispatch()

  const teacherByIdQuery = useSelector(selectors.search.teacherById)
  const teacher = teacherByIdQuery?.data

  const [studentId, setStudentId] = useState<string>(
    child ?? user?.guardian?.children?.[0]?.id ?? ''
  )

  const student: Student | null = useMemo(
    () =>
      (user?.user_type === UserType.Student
        ? user?.student
        : user?.guardian?.children?.find((c) => c.id === studentId) ??
          user?.guardian?.children?.[0]) ?? null,
    [user, studentId]
  )

  const disciplines = useMemo(() => {
    const ids: string[] = []
    teacher?.disciplines?.forEach((teacherDiscipline: PublicTeacherDiscipline) => {
      const id = teacherDiscipline.discipline.id
      if (teacherDiscipline.grade.id === student?.grade?.id) ids.push(id)
    })
    return allDisciplines?.filter((d) => ids.includes(d.id))
  }, [allDisciplines, student?.grade?.id, teacher])

  const backButton = useBackButton()

  const handleSubmit = (discipline: Discipline) => {
    dispatch(
      actions.search.saveStep({
        step: SearchSteps.STEP_2_THEME,
        discipline,
        type,
        student,
      })
    )
    history.push(
      router(routesPath.searchTeacherTheme, {
        discipline: discipline?.slug,
        teacher: teacherId,
        ...(studentId && { child: studentId }),
      })
    )
  }

  const searchTypeProps: SearchDisciplineTemplateProps = {
    headerProps: headerProps,
    menuProps: menuProps,
    title: t('searchRecurrence_title'),
    typeTitle: courseTypeTranslation(t, type),
    typeText: t('searchDiscipline_text'),
    ...(teacher && {
      teacherProfileProps: {
        label: t('searchRecurrence_teacher'),
        profileProps: {
          name: teacher?.full_name ?? '',
          profilePicture: {
            alt: 'LiberteClasse - profil',
            src: teacher?.avatar ?? '',
          },
          nameColor: light.colors.freeSpeechBlue,
          levelColor: light.colors.freeSpeechBlue,
          ...(teacher?.id && {
            linkProps: {
              href: router(routesPath.teacher, { id: teacher?.id }),
            },
          }),
        },
      },
    }),
    ...((user?.guardian?.children?.length as number) > 1 && {
      usersSliderProps: {
        label: t('reporting_form_student_label'),
        options:
          user?.guardian?.children?.map((st) => ({
            avatar: {
              alt: '',
              src: st?.avatar ?? '',
            },
            label: st?.first_name ?? '',
            value: st?.id ?? '',
          })) ?? [],
        value: studentId,
        onChange: (_name, value) => setStudentId(value ?? ''),
      },
    }),
    searchSubjectProps: {
      requestSubjectLabel: t('searchDiscipline_discipline_label'),
      requestSubject: disciplines?.map((discipline) => ({
        title: discipline.name,
        link: {
          onClick: () => handleSubmit(discipline),
        },
      })),
    },
    backButton,
  }

  useEffect(() => {
    if (teacherByIdQuery?.params?.id !== teacherId) {
      dispatch(actions.search.teacherByIdRequest({ id: teacherId }))
    }
  }, [teacherByIdQuery?.params?.id, teacherId, dispatch])

  return <SearchDisciplineTemplate {...searchTypeProps} />
}

SearchTeacherDisciplinePage.restrictedUserTypes = [UserType.Guardian, UserType.Student]

export default SearchTeacherDisciplinePage
