import type { User } from '../generated/graphql'
import { UserStatus, UserType } from '../generated/graphql'
import { router, routesPath } from '../router'

const commonStepsURL: { [key: string]: string } = {
  [UserStatus.RegisteredSocial]: router(routesPath.signUpSocial),
}

const stepsURL: { [key: string]: { [key: string]: string } } = {
  [UserType.Teacher]: {
    [UserStatus.Registered]: router(routesPath.signUpPersonalInfos),
    [UserStatus.RegisteredStep_1]: router(routesPath.signUpTeacherDisciplines),
    [UserStatus.RegisteredStep_2]: router(routesPath.signUpTeacherPro),
    [UserStatus.RegisteredTeacherPending]: router(routesPath.signUpTeacherPending),
    [UserStatus.RegisteredTeacherConfirmed]: router(routesPath.signUpConfirm),
    [UserStatus.RegisteredTeacherRefused]: router(routesPath.signUpTeacherPro),
    [UserStatus.Verified]: router(routesPath.signUpTeacherPricing),
    [UserStatus.VerifiedTeacherPrice]: router(routesPath.signUpTeacherPayments),
    ...commonStepsURL,
  },
  [UserType.Guardian]: {
    [UserStatus.PreRegistered]: router(routesPath.signUpStandby),
    [UserStatus.Registered]: router(routesPath.signUpPersonalInfos),
    [UserStatus.RegisteredStep_1]: router(routesPath.signUpGuardianAddChild),
    [UserStatus.RegisteredStep_2]: router(routesPath.signUpConfirm),
    ...commonStepsURL,
  },
  [UserType.Student]: {
    [UserStatus.PreRegistered]: router(routesPath.signUpStandby),
    [UserStatus.Registered]: router(routesPath.signUpPersonalInfos),
    [UserStatus.RegisteredStep_1]: router(routesPath.signUpChildAddGuardian),
    [UserStatus.RegisteredStep_2]: router(routesPath.signUpConfirm),
    ...commonStepsURL,
  },
  common: commonStepsURL,
}

export function resolveRegisterStepURL(user?: User | null): string {
  if (user && user?.status) {
    if (user?.status === UserStatus.Active) {
      return ''
    }
    return stepsURL?.[user.user_type ?? 'common']?.[user?.status] ?? ''
  }

  return ''
}

export function isAllowed(user?: User | null, url?: string): boolean {
  if (user && user?.status && url) {
    const status = user.status
    const type = user.user_type ?? 'common'
    const userStepsURLS = stepsURL?.[type]
    const stepUrlIndex = Object.values(userStepsURLS)?.findIndex((v) => v === url)
    const askingStep = Object.keys(userStepsURLS)?.[stepUrlIndex]
    console.log('user is asking for', askingStep, 'is allowed to', status)
    if (type === UserType.Teacher) {
      switch (askingStep) {
        case UserStatus.RegisteredStep_1:
          return [
            UserStatus.RegisteredStep_1,
            UserStatus.RegisteredStep_2,
            UserStatus.RegisteredTeacherRefused,
          ].includes(status)
        case UserStatus.RegisteredStep_2:
          return [UserStatus.RegisteredStep_2, UserStatus.RegisteredTeacherRefused].includes(status)
      }
    }
    if (askingStep === status) {
      return true
    }
  }
  return false
}
