import styled, { css, keyframes } from 'styled-components'

import Icon from '../Icon'
import ActionButton from '../ActionButton'

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

export const StyledIcon = styled(Icon)`
  height: 2.2rem;
  width: 2.2rem;
  z-index: 1;
  transform: translateY(-0.1rem);
  &:first-child {
    margin-right: 1rem;
  }
  &:last-child {
    margin-left: 1rem;
  }
  &:only-child {
    margin: 0;
  }
`
export const Label = styled.span``

export const Placeholder = styled.div`
  height: 6.7rem;
  margin-top: 2rem;
`

export const StyledFormSubmit = styled(ActionButton)<{
  $isPending?: boolean
  $isSuccess?: boolean
  $isFullSize?: boolean
}>`
  ${({ $isFullSize, theme }) =>
    $isFullSize &&
    css`
      height: 6.7rem;
      position: fixed;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      max-width: ${theme.breakpoints.values.sm}px;
      align-self: center;
      margin-left: auto;
      margin-right: auto;
      border-radius: 0;
    `}

  ${(props) =>
    (props?.$isPending || props?.$isSuccess) &&
    css`
      opacity: 0.5;
      cursor: no-drop;
    `}
  ${(props) =>
    props?.$isPending &&
    css`
      svg {
        animation: ${rotate} 2s linear infinite;
      }
    `}
`
