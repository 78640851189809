import React, { FC } from 'react'

import { Icons } from '../../Icon/types'
import ModalContainer from '../ModalContainer'

import * as SC from './styled'

export type InfoModalProps = {
  className?: string
  title?: string
  text?: string
  open: boolean
  onClose?: () => void
}

const InfoModal: FC<InfoModalProps> = (props) => {
  const { className, title, text, open, onClose } = props

  return (
    <ModalContainer open={open} onClose={onClose} className={className}>
      <SC.Content>
        <SC.Head>
          {title && <SC.Title>{title}</SC.Title>}
          <SC.CloseButton onClick={onClose} size={'small'}>
            <SC.Close icon={Icons.close} />
          </SC.CloseButton>
        </SC.Head>
        {text && <SC.Text>{text}</SC.Text>}
      </SC.Content>
    </ModalContainer>
  )
}

export default InfoModal
