import { Container, FormHelperText } from '@material-ui/core'
import styled from 'styled-components'

import ToggleButtonGroupC from '../ToggleButtonGroup'

export const ToggleButtonPicker = styled.div``

export const Wrapper = styled(Container)``

export const Title = styled.h2`
  width: 100%;
  color: ${(props) => props.theme.palette.colors.midnightBlue};
  ${(props) => ({ ...props.theme.typography.h4 })}
`

export const Text = styled.p`
  margin: 0 0 2rem 0;
  color: ${(props) => props.theme.palette.colors.chambray};
  ${(props) => ({ ...props.theme.typography.smallRegular })}
`

export const ToggleButtonGroup = styled(ToggleButtonGroupC)``

export const HelperText = styled(FormHelperText)`
  text-align: left;
  ${(props) => ({ ...props.theme.typography.smallRegular })}
  color: ${(props) => props.theme.palette.colors.grey};
  background-color: ${(props) => props.theme.palette.colors.whiteSmoke};
  margin: 3px 0 0 0;
  &.Mui-error {
    color: ${(props) => props.theme.palette.error.main};
  }
`
