import gql from 'graphql-tag'

import UserFragment from '../../../fragments/UserFragment'
import type { User } from '../../../../generated/graphql'

const registerProfessionalInformation = {
  mutation: gql`
    mutation registerProfessionalInformation(
      $siret: String!
      $cv: Upload
      $diploma: Upload
      $company_certificate: Upload
      $introduction: String
    ) {
      registerProfessionalInformation(
        siret: $siret
        cv: $cv
        diploma: $diploma
        company_certificate: $company_certificate
        introduction: $introduction
      ) {
        ...UserFragment
      }
    }
    ${UserFragment}
  `,
  transformer: (response: any): User => {
    return response.registerProfessionalInformation
  },
}

export default registerProfessionalInformation
