import gql from 'graphql-tag'

import { Course } from '../../../../generated/graphql'
import CourseFragment from '../../../fragments/CourseFragment'

const courseById = {
  query: gql`
    query courseById($id: ID!) {
      courseById(id: $id) {
        ...CourseFragment
      }
    }
    ${CourseFragment}
  `,
  transformer: (response: any): Course => {
    return response.courseById
  },
}

export default courseById
